export const datos={
    
    "database": "triplefrontera",
    "version": 1,
    "encrypted": false,
    "mode": "full",
    "tables": [
        {
            "name": "estados",
            "schema": [
                {
                    "column": "id_estado",
                    "value": "INT PRIMARY KEY NOT NULL"
                },
                {
                    "column": "nombre",
                    "value": "VARCHAR(150)  NOT NULL  COLLATE NOCASE"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                }
            ],
            "triggers": [
                {
                    "name": "estados_trigger_last_modified",
                    "logic": "BEGIN      UPDATE estados SET last_modified= (strftime('%s', 'now')) WHERE id_estado=OLD.id_estado;  END",
                    "condition": "FOR EACH ROW WHEN NEW.last_modified < OLD.last_modified",
                    "timeevent": "AFTER UPDATE ON"
                }
            ],
            "values": [
                [
                    1,
                    "EMBARAZADA",
                    0,
                    1681778734
                ],
                [
                    2,
                    "PUÉRPERA",
                    0,
                    1681778734
                ],
                [
                    3,
                    "RECIÉN NACIDO",
                    0,
                    1681778734
                ]
            ]
        },
        {
            "name": "personas",
            "schema": [
                {
                    "column": "id_persona",
                    "value": "INT PRIMARY KEY NOT NULL"
                },
                {
                    "column": "apellido",
                    "value": "VARCHAR(200)  NOT NULL  COLLATE NOCASE"
                },
                {
                    "column": "nombre",
                    "value": "VARCHAR(200)  NOT NULL  COLLATE NOCASE"
                },
                {
                    "column": "documento",
                    "value": "VARCHAR(40)  NULL DEFAULT NULL COLLATE NOCASE"
                },
                {
                    "column": "fecha_nacimiento",
                    "value": "DATE  NULL DEFAULT NULL"
                },
                {
                    "column": "id_origen",
                    "value": "INT  NULL DEFAULT NULL"
                },
                {
                    "column": "nacionalidad",
                    "value": "INT  NULL DEFAULT NULL"
                },
                {
                    "column": "sexo",
                    "value": "CHAR(1)  NULL DEFAULT NULL COLLATE NOCASE"
                },
                {
                    "column": "madre",
                    "value": "INT  NULL DEFAULT NULL"
                },
                {
                    "column": "alta",
                    "value": "SMALLINT  NULL DEFAULT 0"
                },
                {
                    "column": "nacido_vivo",
                    "value": "SMALLINT  NULL DEFAULT NULL"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                }
            ],
            "indexes": [
                {
                    "name": "fk_origenes_idx",
                    "value": "`id_origen` DESC"
                },
                {
                    "name": "fk_personas_paises",
                    "value": "`nacionalidad` DESC"
                },
                {
                    "name": "madre",
                    "value": "`madre` DESC"
                }
            ],
            
        },
        {
            "name": "apps",
            "schema": [
                {
                    "column": "id_app",
                    "value": "INT PRIMARY KEY NOT NULL"
                },
                {
                    "column": "nombre",
                    "value": "VARCHAR(45)  NULL DEFAULT NULL COLLATE NOCASE"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                }
            ],
            "indexes": [
                {
                    "name": "nombre_apps_UNIQUE",
                    "value": "`nombre` DESC",
                    "mode": "UNIQUE"
                }
            ],
            "triggers": [
                {
                    "name": "apps_trigger_last_modified",
                    "logic": "BEGIN      UPDATE apps SET last_modified= (strftime('%s', 'now')) WHERE id_app=OLD.id_app;  END",
                    "condition": "FOR EACH ROW WHEN NEW.last_modified < OLD.last_modified",
                    "timeevent": "AFTER UPDATE ON"
                }
            ],
            "values": [
                [
                    8,
                    "CHAGAS",
                    0,
                    1681778571
                ],
                [
                    3,
                    "DBT",
                    0,
                    1681778571
                ],
                [
                    5,
                    "ECLAMPSIA",
                    0,
                    1681778571
                ],
                [
                    1,
                    "HTA GESTACIONAL",
                    0,
                    1681778571
                ],
                [
                    2,
                    "HTA-DBT GESTACIONAL",
                    0,
                    1681778571
                ],
                [
                    10,
                    "NINGUNO",
                    0,
                    1681778571
                ],
                [
                    4,
                    "PREECLAMPSIA",
                    0,
                    1681778571
                ],
                [
                    7,
                    "SIFILIS",
                    0,
                    1681778571
                ],
                [
                    6,
                    "TBC",
                    0,
                    1681778571
                ],
                [
                    9,
                    "TOXOPLASMOSIS",
                    0,
                    1681778571
                ]
            ]
        },
        {
            "name": "seguimiento_chagas",
            "schema": [
                {
                    "column": "id_seguimiento_chagas",
                    "value": "INT PRIMARY KEY NOT NULL"
                },
                {
                    "column": "examen_clinico",
                    "value": "CHAR(1)  NOT NULL  COLLATE NOCASE"
                },
                {
                    "column": "detalle_examen",
                    "value": "TEXT  NULL  COLLATE NOCASE"
                },
                {
                    "column": "ecg",
                    "value": "CHAR(1)  NOT NULL  COLLATE NOCASE"
                },
                {
                    "column": "detalle_ecg",
                    "value": "TEXT  NOT NULL  COLLATE NOCASE"
                },
                {
                    "column": "hepatograma",
                    "value": "CHAR(1)  NULL DEFAULT NULL COLLATE NOCASE"
                },
                {
                    "column": "ecocardiograma",
                    "value": "CHAR(1)  NULL DEFAULT NULL COLLATE NOCASE"
                },
                {
                    "column": "tele_rx_torax",
                    "value": "CHAR(1)  NULL DEFAULT NULL COLLATE NOCASE"
                },
                {
                    "column": "got",
                    "value": "CHAR(1)  NULL DEFAULT NULL COLLATE NOCASE"
                },
                {
                    "column": "gpt",
                    "value": "CHAR(1)  NULL DEFAULT NULL COLLATE NOCASE"
                },
                {
                    "column": "fal",
                    "value": "CHAR(1)  NULL DEFAULT NULL COLLATE NOCASE"
                },
                {
                    "column": "parasitemia",
                    "value": "CHAR(1)  NULL DEFAULT NULL COLLATE NOCASE"
                },
                {
                    "column": "serologia_10",
                    "value": "CHAR(1)  NULL DEFAULT NULL COLLATE NOCASE"
                },
                {
                    "column": "serologia_12",
                    "value": "CHAR(1)  NULL DEFAULT NULL COLLATE NOCASE"
                },
                {
                    "column": "serologia_24",
                    "value": "CHAR(1)  NULL DEFAULT NULL COLLATE NOCASE"
                },
                {
                    "column": "detalle_got",
                    "value": "TEXT  NULL  COLLATE NOCASE"
                },
                {
                    "column": "detalle_gpt",
                    "value": "TEXT  NULL  COLLATE NOCASE"
                },
                {
                    "column": "detalle_hepatograma",
                    "value": "TEXT  NULL  COLLATE NOCASE"
                },
                {
                    "column": "detalle_ecocardiograma",
                    "value": "TEXT  NULL  COLLATE NOCASE"
                },
                {
                    "column": "detalle_fal",
                    "value": "TEXT  NULL  COLLATE NOCASE"
                },
                {
                    "column": "detalle_rx_torax",
                    "value": "TEXT  NULL  COLLATE NOCASE"
                },
                {
                    "column": "hemograma",
                    "value": "CHAR(1)  NULL DEFAULT NULL COLLATE NOCASE"
                },
                {
                    "column": "detalle_hemograma",
                    "value": "TEXT  NULL  COLLATE NOCASE"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                }
            ]
        },
        {
            "name": "paises",
            "schema": [
                {
                    "column": "id_pais",
                    "value": "INT PRIMARY KEY NOT NULL"
                },
                {
                    "column": "codigo",
                    "value": "VARCHAR(3)  NOT NULL  COLLATE NOCASE"
                },
                {
                    "column": "nombre",
                    "value": "VARCHAR(150)  NOT NULL  COLLATE NOCASE"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                }
            ],
            "indexes": [
                {
                    "name": "codigo",
                    "value": "`codigo` DESC",
                    "mode": "UNIQUE"
                },
                {
                    "name": "nombre",
                    "value": "`nombre` DESC",
                    "mode": "UNIQUE"
                }
            ],
            "triggers": [
                {
                    "name": "paises_derivacion_trigger_last_modified",
                    "logic": "BEGIN      UPDATE paises SET last_modified= (strftime('%s', 'now')) WHERE id_pais=OLD.id_pais;  END",
                    "condition": "FOR EACH ROW WHEN NEW.last_modified < OLD.last_modified",
                    "timeevent": "AFTER UPDATE ON"
                }
            ],
            "values": [
                [
                    12,
                    "AR",
                    "Argentina",
                    0,
                    1681778904
                ],
                [
                    27,
                    "BO",
                    "Bolivia",
                    0,
                    1681778904
                ],
                [
                    31,
                    "BR",
                    "Brasil",
                    0,
                    1681778904
                ],
                [
                    43,
                    "CL",
                    "Chile",
                    0,
                    1681778904
                ],
                [
                    46,
                    "CO",
                    "Colombia",
                    0,
                    1681778904
                ],
                [
                    57,
                    "EC",
                    "Ecuador",
                    0,
                    1681778904
                ],
                [
                    177,
                    "PY",
                    "Paraguay",
                    0,
                    1681778904
                ],
                [
                    178,
                    "PE",
                    "Perú",
                    0,
                    1681778904
                ],
                [
                    243,
                    "VE",
                    "Venezuela",
                    0,
                    1681778904
                ]
            ]
        },
        {
            "name": "provincias",
            "schema": [
                {
                    "column": "id_provincia",
                    "value": "INT PRIMARY KEY NOT NULL"
                },
                {
                    "column": "nombre",
                    "value": "VARCHAR(30)  NOT NULL  COLLATE BINARY"
                },
                {
                    "column": "id_pais",
                    "value": "INT  NOT NULL DEFAULT 1"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                },
                {
                    "constraint": "`fk_provincias_paises`",
                    "value": "FOREIGN KEY (`id_pais`) REFERENCES paises (`id_pais`) ON DELETE RESTRICT ON UPDATE RESTRICT"
                }
            ],
            "indexes": [
                {
                    "name": "id_pais_provincias",
                    "value": "`id_pais` DESC"
                }
            ],
            "values": [
                [
                    1,
                    "Buenos Aires",
                    12,
                    0,
                    1681778962
                ],
                [
                    2,
                    "Catamarca",
                    12,
                    0,
                    1681778962
                ],
                [
                    3,
                    "Chaco",
                    12,
                    0,
                    1681778962
                ],
                [
                    4,
                    "Chubut",
                    12,
                    0,
                    1681778962
                ],
                [
                    5,
                    "Cordoba",
                    12,
                    0,
                    1681778962
                ],
                [
                    6,
                    "Corrientes",
                    12,
                    0,
                    1681778962
                ],
                [
                    7,
                    "Capital Federal",
                    12,
                    0,
                    1681778962
                ],
                [
                    8,
                    "Entre Rios",
                    12,
                    0,
                    1681778962
                ],
                [
                    9,
                    "Formosa",
                    12,
                    0,
                    1681778962
                ],
                [
                    10,
                    "Jujuy",
                    12,
                    0,
                    1681778962
                ],
                [
                    11,
                    "La Pampa",
                    12,
                    0,
                    1681778962
                ],
                [
                    12,
                    "La Rioja",
                    12,
                    0,
                    1681778962
                ],
                [
                    13,
                    "Mendoza",
                    12,
                    0,
                    1681778962
                ],
                [
                    14,
                    "Misiones",
                    12,
                    0,
                    1681778962
                ],
                [
                    15,
                    "Neuquen",
                    12,
                    0,
                    1681778962
                ],
                [
                    16,
                    "Rio Negro",
                    12,
                    0,
                    1681778962
                ],
                [
                    17,
                    "Salta",
                    12,
                    0,
                    1681778962
                ],
                [
                    18,
                    "San Juan",
                    12,
                    0,
                    1681778962
                ],
                [
                    19,
                    "San Luis",
                    12,
                    0,
                    1681778962
                ],
                [
                    20,
                    "Santa Cruz",
                    12,
                    0,
                    1681778962
                ],
                [
                    21,
                    "Santa Fe",
                    12,
                    0,
                    1681778962
                ],
                [
                    22,
                    "Santiago del Estero",
                    12,
                    0,
                    1681778962
                ],
                [
                    23,
                    "Tierra del Fuego",
                    12,
                    0,
                    1681778962
                ],
                [
                    24,
                    "Tucuman",
                    12,
                    0,
                    1681778962
                ],
                [
                    25,
                    "Chuquisaca",
                    27,
                    0,
                    1681778962
                ],
                [
                    26,
                    "Cochabamba",
                    27,
                    0,
                    1681778962
                ],
                [
                    27,
                    "El Beni",
                    27,
                    0,
                    1681778962
                ],
                [
                    28,
                    "La Paz",
                    27,
                    0,
                    1681778962
                ],
                [
                    29,
                    "Oruro",
                    27,
                    0,
                    1681778962
                ],
                [
                    30,
                    "Pando",
                    27,
                    0,
                    1681778962
                ],
                [
                    31,
                    "Potosi",
                    27,
                    0,
                    1681778962
                ],
                [
                    32,
                    "Santa Cruz",
                    27,
                    0,
                    1681778962
                ],
                [
                    33,
                    "Tarija",
                    27,
                    0,
                    1681778962
                ],
                [
                    34,
                    "Alto Parana",
                    177,
                    0,
                    1681778962
                ],
                [
                    35,
                    "Amambay",
                    177,
                    0,
                    1681778962
                ],
                [
                    36,
                    "Boqueron",
                    177,
                    0,
                    1681778962
                ],
                [
                    37,
                    "Caaguazu",
                    177,
                    0,
                    1681778962
                ],
                [
                    38,
                    "Caazapa",
                    177,
                    0,
                    1681778962
                ],
                [
                    39,
                    "Central",
                    177,
                    0,
                    1681778962
                ],
                [
                    40,
                    "Concepcion",
                    177,
                    0,
                    1681778962
                ],
                [
                    41,
                    "Cordillera",
                    177,
                    0,
                    1681778962
                ],
                [
                    42,
                    "Guaira",
                    177,
                    0,
                    1681778962
                ],
                [
                    43,
                    "Itapua",
                    177,
                    0,
                    1681778962
                ],
                [
                    44,
                    "Misiones",
                    177,
                    0,
                    1681778962
                ],
                [
                    45,
                    "Neembucu",
                    177,
                    0,
                    1681778962
                ],
                [
                    46,
                    "Paraguari",
                    177,
                    0,
                    1681778962
                ],
                [
                    47,
                    "Presidente Hayes",
                    177,
                    0,
                    1681778962
                ],
                [
                    48,
                    "San Pedro",
                    177,
                    0,
                    1681778962
                ],
                [
                    49,
                    "Canindeyu",
                    177,
                    0,
                    1681778962
                ],
                [
                    50,
                    "Chaco",
                    177,
                    0,
                    1681778962
                ],
                [
                    51,
                    "Nueva Asuncion",
                    177,
                    0,
                    1681778962
                ],
                [
                    52,
                    "Alto Paraguay",
                    177,
                    0,
                    1681778962
                ]
            ]
        },
        {
            "name": "motivos_derivacion",
            "schema": [
                {
                    "column": "id_motivo",
                    "value": "INT PRIMARY KEY NOT NULL"
                },
                {
                    "column": "nombre",
                    "value": "VARCHAR(25)  NOT NULL  COLLATE NOCASE"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                }
            ],
            "triggers": [
                {
                    "name": "motivos_derivacion_trigger_last_modified",
                    "logic": "BEGIN      UPDATE motivos_derivacion SET last_modified= (strftime('%s', 'now')) WHERE id_motivo=OLD.id_motivo;  END",
                    "condition": "FOR EACH ROW WHEN NEW.last_modified < OLD.last_modified",
                    "timeevent": "AFTER UPDATE ON"
                }
            ],
            "values": [
                [
                    1,
                    "HTA",
                    0,
                    1681778863
                ],
                [
                    2,
                    "DBT",
                    0,
                    1681778863
                ],
                [
                    3,
                    "PREECLAMPSIA",
                    0,
                    1681778863
                ],
                [
                    4,
                    "ANEMIA",
                    0,
                    1681778863
                ],
                [
                    5,
                    "SEVERA",
                    0,
                    1681778863
                ],
                [
                    6,
                    "RCIU",
                    0,
                    1681778863
                ],
                [
                    7,
                    "AMENAZA DE PARTO",
                    0,
                    1681778863
                ],
                [
                    8,
                    "FECHA DE PARTO",
                    0,
                    1681778863
                ],
                [
                    9,
                    "OTRO",
                    0,
                    1681778863
                ]
            ]
        },
        {
            "name": "seguimiento_hiv",
            "schema": [
                {
                    "column": "id_seguimiento_hiv",
                    "value": "INT PRIMARY KEY NOT NULL"
                },
                {
                    "column": "antecedente",
                    "value": "CHAR(1)  NULL DEFAULT NULL COLLATE NOCASE"
                },
                {
                    "column": "carga_viral",
                    "value": "CHAR(1)  NULL DEFAULT NULL COLLATE NOCASE"
                },
                {
                    "column": "medico_cargo",
                    "value": "TEXT  NULL  COLLATE NOCASE"
                },
                {
                    "column": "derivacion_hospital",
                    "value": "TEXT  NULL  COLLATE NOCASE"
                },
                {
                    "column": "test_rapido_pareja",
                    "value": "CHAR(1)  NULL DEFAULT NULL COLLATE NOCASE"
                },
                {
                    "column": "derivacion_hospital_pareja",
                    "value": "TEXT  NULL  COLLATE NOCASE"
                },
                {
                    "column": "medico_cargo_pareja",
                    "value": "TEXT  NULL  COLLATE NOCASE"
                },
                {
                    "column": "proviral_cargaviral",
                    "value": "CHAR(1)  NULL DEFAULT NULL COLLATE NOCASE"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                }
            ]
        },
        {
            "name": "seguimiento_sifilis",
            "schema": [
                {
                    "column": "id_seguimiento_sifilis",
                    "value": "INT PRIMARY KEY NOT NULL"
                },
                {
                    "column": "pareja_fecha_realizado",
                    "value": "TIMESTAMP  NULL DEFAULT NULL"
                },
                {
                    "column": "pareja_fecha_resultados",
                    "value": "TIMESTAMP  NULL DEFAULT NULL"
                },
                {
                    "column": "pareja_resultado",
                    "value": "VARCHAR(45)  NULL DEFAULT NULL COLLATE NOCASE"
                },
                {
                    "column": "pareja_tratamiento",
                    "value": "CHAR(1)  NULL DEFAULT NULL COLLATE NOCASE"
                },
                {
                    "column": "rn_mes_seguimiento",
                    "value": "SMALLINT  NULL DEFAULT NULL"
                },
                {
                    "column": "rn_examen_medico",
                    "value": "CHAR(1)  NULL DEFAULT NULL COLLATE NOCASE"
                },
                {
                    "column": "rn_vdrl",
                    "value": "VARCHAR(45)  NULL DEFAULT NULL COLLATE NOCASE"
                },
                {
                    "column": "rn_rx_osea",
                    "value": "CHAR(1)  NULL DEFAULT NULL COLLATE NOCASE"
                },
                {
                    "column": "rn_sedimento_orina",
                    "value": "CHAR(1)  NULL DEFAULT NULL COLLATE NOCASE"
                },
                {
                    "column": "rn_got",
                    "value": "CHAR(1)  NULL DEFAULT NULL COLLATE NOCASE"
                },
                {
                    "column": "rn_gpt",
                    "value": "CHAR(1)  NULL DEFAULT NULL COLLATE NOCASE"
                },
                {
                    "column": "rn_lcr",
                    "value": "CHAR(1)  NULL DEFAULT NULL COLLATE NOCASE"
                },
                {
                    "column": "rn_oftalmologico",
                    "value": "CHAR(1)  NULL DEFAULT NULL COLLATE NOCASE"
                },
                {
                    "column": "rn_auditivo",
                    "value": "CHAR(1)  NULL DEFAULT NULL COLLATE NOCASE"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                }
            ]
        },
        {
            "name": "seguimiento_vhb",
            "schema": [
                {
                    "column": "id_seguimiento_vhb",
                    "value": "INT PRIMARY KEY NOT NULL"
                },
                {
                    "column": "antihbc",
                    "value": "CHAR(1)  NOT NULL  COLLATE NOCASE"
                },
                {
                    "column": "derivacion_hospital",
                    "value": "TEXT  NULL  COLLATE NOCASE"
                },
                {
                    "column": "antihbs",
                    "value": "INT  NULL DEFAULT NULL"
                },
                {
                    "column": "vacuna12hs",
                    "value": "SMALLINT  NULL DEFAULT NULL"
                },
                {
                    "column": "gammaglobulina_1248",
                    "value": "SMALLINT  NULL DEFAULT NULL"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                }
            ]
        },
        {
            "name": "tipos_fin_embarazos",
            "schema": [
                {
                    "column": "id_tipos_fin_embarazos",
                    "value": "INT PRIMARY KEY NOT NULL"
                },
                {
                    "column": "nombre",
                    "value": "VARCHAR(45)  NOT NULL  COLLATE NOCASE"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                }
            ],
            "values": [
                [
                    1,
                    "PARTO",
                    0,
                    1681779100
                ],
                [
                    2,
                    "CESAREA",
                    0,
                    1681779100
                ],
                [
                    3,
                    "ABORTO",
                    0,
                    1681779100
                ]
            ]
        },
        {
            "name": "db_log",
            "schema": [
                {
                    "column": "id_db_log",
                    "value": "INTEGER PRIMARY KEY AUTOINCREMENT NOT NULL"
                },
                {
                    "column": "timestamp",
                    "value": "TIMESTAMP  NOT NULL"
                },
                {
                    "column": "query",
                    "value": "TEXT  NOT NULL  COLLATE NOCASE"
                },
                {
                    "column": "sync",
                    "value": "SMALLINT  NOT NULL DEFAULT 0"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                }
            ],
            "triggers": [
                {
                    "name": "db_log_trigger_last_modified",
                    "logic": "BEGIN      UPDATE db_log SET last_modified= (strftime('%s', 'now')) WHERE id_db_log=OLD.id_db_log;  END",
                    "condition": "FOR EACH ROW WHEN NEW.last_modified < OLD.last_modified",
                    "timeevent": "AFTER UPDATE ON"
                }
            ],
            "values": [
                [
                    1,
                    "2022-04-18 12:05:25",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=360",
                    1,
                    0,
                    1681778701
                ],
                [
                    2,
                    "2022-04-18 12:05:25",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-11', resultado='N' WHERE laboratorios_realizados.id_persona=279 AND laboratorios_realizados.id_control=455 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    3,
                    "2022-04-18 12:05:25",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-11', resultado='N' WHERE laboratorios_realizados.id_persona=279 AND laboratorios_realizados.id_control=455 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    4,
                    "2022-04-18 12:05:25",
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (279, 455, 19, 2, '2022-04-11 17:25:52.000000', 1)",
                    1,
                    0,
                    1681778701
                ],
                [
                    5,
                    "2022-04-18 12:05:25",
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (279, 455, 20, 2, '2022-04-11 17:25:52.000000', 1)",
                    1,
                    0,
                    1681778701
                ],
                [
                    6,
                    "2022-04-18 12:05:25",
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (279, 455, 23, 2, '2022-04-11 17:25:52.000000', 1)",
                    1,
                    0,
                    1681778701
                ],
                [
                    7,
                    "2022-04-18 12:05:25",
                    "INSERT INTO etmis_personas (id_persona, id_etmi, id_control) VALUES (279, 1, 455)",
                    1,
                    0,
                    1681778701
                ],
                [
                    8,
                    "2022-04-18 12:05:25",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-11', resultado='P' WHERE laboratorios_realizados.id_persona=279 AND laboratorios_realizados.id_control=455 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    9,
                    "2022-04-18 12:05:25",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-11', resultado='N' WHERE laboratorios_realizados.id_persona=279 AND laboratorios_realizados.id_control=455 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    10,
                    "2022-04-18 12:05:25",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-11', resultado='9' WHERE laboratorios_realizados.id_persona=279 AND laboratorios_realizados.id_control=455 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    11,
                    "2022-04-18 12:05:25",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-11', resultado='0+' WHERE laboratorios_realizados.id_persona=279 AND laboratorios_realizados.id_control=455 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    12,
                    "2022-04-18 19:04:48",
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (400088, 'SAMBO', 'JOSEFA', '44565545', '1993-09-24 00:00:00.000000', 1, 12, 'F')",
                    1,
                    0,
                    1681778701
                ],
                [
                    13,
                    "2022-04-18 19:04:48",
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, georeferencia) VALUES (400245, '2022-04-18 18:56:24.000000', 400088, 1, 1, '-22.2725026,-62.7125106')",
                    1,
                    0,
                    1681778701
                ],
                [
                    14,
                    "2022-04-18 19:04:48",
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (400088, 400088, 17, 3, '00', '2022-04-18 18:56:24.000000', '-22.2725026,-62.7125106', 12)",
                    1,
                    0,
                    1681778701
                ],
                [
                    15,
                    "2022-04-24 13:12:26",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-19', resultado='-' WHERE laboratorios_realizados.id_persona=400004 AND laboratorios_realizados.id_control=400034 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    16,
                    "2022-04-24 13:12:26",
                    "DELETE FROM etmis_personas WHERE etmis_personas.id_persona=300069 AND etmis_personas.id_etmi=2 AND etmis_personas.id_control=300080",
                    1,
                    0,
                    1681778701
                ],
                [
                    17,
                    "2022-04-24 13:12:26",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-19', resultado='N' WHERE laboratorios_realizados.id_persona=300069 AND laboratorios_realizados.id_control=300080 AND laboratorios_realizados.id_laboratorio=20 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    18,
                    "2022-04-24 13:12:26",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-19', resultado='N' WHERE laboratorios_realizados.id_persona=300069 AND laboratorios_realizados.id_control=300080 AND laboratorios_realizados.id_laboratorio=21 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    19,
                    "2022-04-24 13:12:26",
                    "DELETE FROM etmis_personas WHERE etmis_personas.id_persona=200008 AND etmis_personas.id_etmi=2 AND etmis_personas.id_control=200018",
                    1,
                    0,
                    1681778701
                ],
                [
                    20,
                    "2022-04-24 13:12:26",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-19', resultado='N' WHERE laboratorios_realizados.id_persona=200008 AND laboratorios_realizados.id_control=200018 AND laboratorios_realizados.id_laboratorio=20 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    21,
                    "2022-04-24 13:12:26",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-19', resultado='N' WHERE laboratorios_realizados.id_persona=200008 AND laboratorios_realizados.id_control=200018 AND laboratorios_realizados.id_laboratorio=21 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    22,
                    "2022-04-24 13:12:26",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200053",
                    1,
                    0,
                    1681778701
                ],
                [
                    23,
                    "2022-04-24 13:12:26",
                    "DELETE FROM etmis_personas WHERE etmis_personas.id_persona=149 AND etmis_personas.id_etmi=4 AND etmis_personas.id_control=197",
                    1,
                    0,
                    1681778701
                ],
                [
                    24,
                    "2022-04-24 13:12:26",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-19', resultado='N' WHERE laboratorios_realizados.id_persona=149 AND laboratorios_realizados.id_control=197 AND laboratorios_realizados.id_laboratorio=15 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    25,
                    "2022-04-24 13:12:26",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-19', resultado='N' WHERE laboratorios_realizados.id_persona=149 AND laboratorios_realizados.id_control=197 AND laboratorios_realizados.id_laboratorio=22 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    26,
                    "2022-04-24 13:12:26",
                    "DELETE FROM etmis_personas WHERE etmis_personas.id_persona=100050 AND etmis_personas.id_etmi=3 AND etmis_personas.id_control=100050",
                    1,
                    0,
                    1681778701
                ],
                [
                    27,
                    "2022-04-24 13:12:26",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-19', resultado='N;' WHERE laboratorios_realizados.id_persona=100050 AND laboratorios_realizados.id_control=100050 AND laboratorios_realizados.id_laboratorio=13 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    28,
                    "2022-04-24 13:12:26",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-19', resultado='N' WHERE laboratorios_realizados.id_persona=100050 AND laboratorios_realizados.id_control=100050 AND laboratorios_realizados.id_laboratorio=18 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    29,
                    "2022-04-24 13:12:26",
                    "DELETE FROM etmis_personas WHERE etmis_personas.id_persona=100052 AND etmis_personas.id_etmi=3 AND etmis_personas.id_control=100052",
                    1,
                    0,
                    1681778701
                ],
                [
                    30,
                    "2022-04-24 13:12:26",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-19', resultado='N;' WHERE laboratorios_realizados.id_persona=100052 AND laboratorios_realizados.id_control=100052 AND laboratorios_realizados.id_laboratorio=13 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    31,
                    "2022-04-24 13:12:26",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-19', resultado='N' WHERE laboratorios_realizados.id_persona=100052 AND laboratorios_realizados.id_control=100052 AND laboratorios_realizados.id_laboratorio=18 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    32,
                    "2022-04-24 13:12:26",
                    "DELETE FROM etmis_personas WHERE etmis_personas.id_persona=241 AND etmis_personas.id_etmi=1 AND etmis_personas.id_control=400244",
                    1,
                    0,
                    1681778701
                ],
                [
                    33,
                    "2022-04-24 13:12:26",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-19', resultado='P' WHERE laboratorios_realizados.id_persona=241 AND laboratorios_realizados.id_control=400244 AND laboratorios_realizados.id_laboratorio=19 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    34,
                    "2022-04-24 13:12:26",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-19', resultado='P' WHERE laboratorios_realizados.id_persona=241 AND laboratorios_realizados.id_control=400244 AND laboratorios_realizados.id_laboratorio=20 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    35,
                    "2022-04-24 13:12:26",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-19', resultado='P' WHERE laboratorios_realizados.id_persona=241 AND laboratorios_realizados.id_control=400244 AND laboratorios_realizados.id_laboratorio=23 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    36,
                    "2022-04-24 13:12:26",
                    "DELETE FROM etmis_personas WHERE etmis_personas.id_persona=100095 AND etmis_personas.id_etmi=1 AND etmis_personas.id_control=100117",
                    1,
                    0,
                    1681778701
                ],
                [
                    37,
                    "2022-04-24 13:12:26",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-19', resultado='P' WHERE laboratorios_realizados.id_persona=100095 AND laboratorios_realizados.id_control=100117 AND laboratorios_realizados.id_laboratorio=19 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    38,
                    "2022-04-24 13:12:26",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-19', resultado='P' WHERE laboratorios_realizados.id_persona=100095 AND laboratorios_realizados.id_control=100117 AND laboratorios_realizados.id_laboratorio=20 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    39,
                    "2022-04-24 13:12:26",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-19', resultado='P' WHERE laboratorios_realizados.id_persona=100095 AND laboratorios_realizados.id_control=100117 AND laboratorios_realizados.id_laboratorio=23 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    40,
                    "2022-04-24 13:12:26",
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (400246, '2022-04-19 00:50:42.000000', 200025, 3, 2, '2021-12-08 00:00:00.000000', 1)",
                    1,
                    0,
                    1681778701
                ],
                [
                    41,
                    "2022-04-24 13:12:26",
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (400088, 400246)",
                    1,
                    0,
                    1681778701
                ],
                [
                    42,
                    "2022-04-24 13:12:26",
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=200025",
                    1,
                    0,
                    1681778701
                ],
                [
                    43,
                    "2022-04-24 13:12:26",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=386",
                    1,
                    0,
                    1681778701
                ],
                [
                    44,
                    "2022-04-24 13:12:26",
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (400247, '2022-04-22 16:02:13.000000', 99, 3, 1)",
                    1,
                    0,
                    1681778701
                ],
                [
                    45,
                    "2022-04-24 13:12:26",
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (99, 400247, 2, 'S')",
                    1,
                    0,
                    1681778701
                ],
                [
                    46,
                    "2022-04-24 13:12:26",
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (99, 400247, 3, 'C')",
                    1,
                    0,
                    1681778701
                ],
                [
                    47,
                    "2022-04-24 13:12:26",
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (99, 400247, 1, 'N')",
                    1,
                    0,
                    1681778701
                ],
                [
                    48,
                    "2022-04-24 13:12:26",
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (99, 400247, 4, 'P')",
                    1,
                    0,
                    1681778701
                ],
                [
                    49,
                    "2022-04-24 13:12:26",
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (400068, 400247, 35, 'R', ' ', 'N', 'N', 110, 70, 'N', 'ESTE CONTROL CORRESPONDE AL 28/3/2022')",
                    1,
                    0,
                    1681778701
                ],
                [
                    50,
                    "2022-04-24 13:12:26",
                    "UPDATE controles SET georeferencia='-34.195598,-60.7364317' WHERE controles.id_control=400247",
                    1,
                    0,
                    1681778701
                ],
                [
                    51,
                    "2022-04-24 13:12:26",
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (400248, '2022-04-22 16:05:15.000000', 215, 3, 1)",
                    1,
                    0,
                    1681778701
                ],
                [
                    52,
                    "2022-04-24 13:12:26",
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (215, 400248, 2, 'S')",
                    1,
                    0,
                    1681778701
                ],
                [
                    53,
                    "2022-04-24 13:12:26",
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (215, 400248, 3, 'C')",
                    1,
                    0,
                    1681778701
                ],
                [
                    54,
                    "2022-04-24 13:12:26",
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (215, 400248, 1, 'N')",
                    1,
                    0,
                    1681778701
                ],
                [
                    55,
                    "2022-04-24 13:12:26",
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (215, 400248, 4, 'P')",
                    1,
                    0,
                    1681778701
                ],
                [
                    56,
                    "2022-04-24 13:12:27",
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (400069, 400248, 38, 'R', ' ', 'N', 'N', 100, 70, 'N', 'ESTE CONTROL CORRESPONDE AL 28/3/2022')",
                    1,
                    0,
                    1681778701
                ],
                [
                    57,
                    "2022-04-24 13:12:27",
                    "UPDATE controles SET georeferencia='-34.1955872,-60.7364111' WHERE controles.id_control=400248",
                    1,
                    0,
                    1681778701
                ],
                [
                    58,
                    "2022-04-24 13:12:27",
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos, georeferencia) VALUES (400249, '2022-04-22 16:16:25.000000', 100028, 3, 2, '2022-03-08 00:00:00.000000', 1, '-34.390016,-60.5487104')",
                    1,
                    0,
                    1681778701
                ],
                [
                    59,
                    "2022-04-24 13:12:27",
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (400089, 400249)",
                    1,
                    0,
                    1681778701
                ],
                [
                    60,
                    "2022-04-24 13:12:27",
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=100028",
                    1,
                    0,
                    1681778701
                ],
                [
                    61,
                    "2022-04-24 13:12:27",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-22', resultado='N' WHERE laboratorios_realizados.id_persona=100076 AND laboratorios_realizados.id_control=100259 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    62,
                    "2022-04-24 13:12:27",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-22', resultado='N' WHERE laboratorios_realizados.id_persona=100076 AND laboratorios_realizados.id_control=100259 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    63,
                    "2022-04-24 13:12:27",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-22', resultado='N' WHERE laboratorios_realizados.id_persona=100076 AND laboratorios_realizados.id_control=100259 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    64,
                    "2022-04-24 13:12:27",
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (400250, '2022-04-22 16:20:13.000000', 100076, 5, 1)",
                    1,
                    0,
                    1681778701
                ],
                [
                    65,
                    "2022-04-24 13:12:27",
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100076, 400250, 2, 'S')",
                    1,
                    0,
                    1681778701
                ],
                [
                    66,
                    "2022-04-24 13:12:27",
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100076, 400250, 3, 'P')",
                    1,
                    0,
                    1681778701
                ],
                [
                    67,
                    "2022-04-24 13:12:27",
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100076, 400250, 1, 'N')",
                    1,
                    0,
                    1681778701
                ],
                [
                    68,
                    "2022-04-24 13:12:27",
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100076, 400250, 4, 'P')",
                    1,
                    0,
                    1681778701
                ],
                [
                    69,
                    "2022-04-24 13:12:27",
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100076, 400250, 1, 3, '2022-04-22 16:22:01.000000', '2022-04-22 16:22:01.000000', 'N', 3)",
                    1,
                    0,
                    1681778701
                ],
                [
                    70,
                    "2022-04-24 13:12:27",
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100076, 400250, 2, 3, '2022-04-22 16:22:01.000000', '2022-04-22 16:22:01.000000', 'N', 2)",
                    1,
                    0,
                    1681778701
                ],
                [
                    71,
                    "2022-04-24 13:12:27",
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100076, 400250, 5, 3, '2022-04-22 16:22:01.000000', '2022-04-22 16:22:01.000000', 'N', 4)",
                    1,
                    0,
                    1681778701
                ],
                [
                    72,
                    "2022-04-24 13:12:27",
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (100076, 400250, 6, 3, '2022-04-22 16:22:01.000000', '2022-04-22 16:22:01.000000', '7')",
                    1,
                    0,
                    1681778701
                ],
                [
                    73,
                    "2022-04-24 13:12:27",
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (400070, 400250, 41, 'R', ' ', 'N', 'N', 100, 60, 'N', 'ESTE CONTROL CORRESPONDE AL 31/3/2022 EG 38 SEM')",
                    1,
                    0,
                    1681778701
                ],
                [
                    74,
                    "2022-04-24 13:12:27",
                    "UPDATE controles SET georeferencia='-34.1955873,-60.7364111' WHERE controles.id_control=400250",
                    1,
                    0,
                    1681778701
                ],
                [
                    75,
                    "2022-04-24 13:12:27",
                    "UPDATE personas SET fecha_nacimiento='2003-02-03' WHERE personas.id_persona=100130",
                    1,
                    0,
                    1681778701
                ],
                [
                    76,
                    "2022-04-24 13:12:27",
                    "SELECT id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais FROM ubicaciones WHERE id_ubicacion = '100130'",
                    1,
                    0,
                    1681778701
                ],
                [
                    77,
                    "2022-04-24 13:12:27",
                    "UPDATE antecedentes SET fum='2021-07-21', fpp='2022-04-20' WHERE antecedentes.id_antecedente=100126",
                    1,
                    0,
                    1681778701
                ],
                [
                    78,
                    "2022-04-24 13:12:27",
                    "UPDATE personas SET documento='46231140' WHERE personas.id_persona=100173",
                    1,
                    0,
                    1681778701
                ],
                [
                    79,
                    "2022-04-24 13:12:27",
                    "SELECT id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais FROM ubicaciones WHERE id_ubicacion = '100173'",
                    1,
                    0,
                    1681778701
                ],
                [
                    80,
                    "2022-04-24 13:12:27",
                    "UPDATE personas SET documento='46055796' WHERE personas.id_persona=230",
                    1,
                    0,
                    1681778701
                ],
                [
                    81,
                    "2022-04-24 13:12:27",
                    "SELECT id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais FROM ubicaciones WHERE id_ubicacion = '230'",
                    1,
                    0,
                    1681778701
                ],
                [
                    82,
                    "2022-04-24 13:12:27",
                    "UPDATE personas SET apellido='ALEJO' WHERE personas.id_persona=231",
                    1,
                    0,
                    1681778701
                ],
                [
                    83,
                    "2022-04-24 13:12:27",
                    "SELECT id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais FROM ubicaciones WHERE id_ubicacion = '231'",
                    1,
                    0,
                    1681778701
                ],
                [
                    84,
                    "2022-04-24 13:12:27",
                    "UPDATE personas SET nombre='MARIA EMILIANA' WHERE personas.id_persona=100137",
                    1,
                    0,
                    1681778701
                ],
                [
                    85,
                    "2022-04-24 13:12:27",
                    "SELECT id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais FROM ubicaciones WHERE id_ubicacion = '100137'",
                    1,
                    0,
                    1681778701
                ],
                [
                    86,
                    "2022-04-24 13:12:27",
                    "UPDATE personas SET nombre='NORMA' WHERE personas.id_persona=296",
                    1,
                    0,
                    1681778701
                ],
                [
                    87,
                    "2022-04-24 13:12:27",
                    "SELECT id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais FROM ubicaciones WHERE id_ubicacion = '296'",
                    1,
                    0,
                    1681778701
                ],
                [
                    88,
                    "2022-04-24 13:12:27",
                    "UPDATE personas SET fecha_nacimiento='2003-05-05' WHERE personas.id_persona=290",
                    1,
                    0,
                    1681778701
                ],
                [
                    89,
                    "2022-04-24 13:12:27",
                    "SELECT id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais FROM ubicaciones WHERE id_ubicacion = '290'",
                    1,
                    0,
                    1681778701
                ],
                [
                    90,
                    "2022-05-14 20:14:29",
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (400251, '2022-05-02 11:40:07.000000', 5, 5, 1)",
                    1,
                    0,
                    1681778701
                ],
                [
                    91,
                    "2022-06-22 18:48:38",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=388",
                    1,
                    0,
                    1681778701
                ],
                [
                    92,
                    "2022-06-22 18:48:38",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='N' WHERE laboratorios_realizados.id_persona=248 AND laboratorios_realizados.id_control=586 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    93,
                    "2022-06-22 18:48:38",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='103' WHERE laboratorios_realizados.id_persona=248 AND laboratorios_realizados.id_control=586 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    94,
                    "2022-06-22 18:48:38",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='79' WHERE laboratorios_realizados.id_persona=248 AND laboratorios_realizados.id_control=586 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    95,
                    "2022-06-24 23:30:24",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=394",
                    1,
                    0,
                    1681778701
                ],
                [
                    96,
                    "2022-06-24 23:30:24",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='N' WHERE laboratorios_realizados.id_persona=154 AND laboratorios_realizados.id_control=592 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    97,
                    "2022-06-24 23:30:24",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='12.3' WHERE laboratorios_realizados.id_persona=154 AND laboratorios_realizados.id_control=592 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    98,
                    "2022-06-24 23:30:24",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='103' WHERE laboratorios_realizados.id_persona=154 AND laboratorios_realizados.id_control=592 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    99,
                    "2022-08-17 19:43:30",
                    "DELETE FROM etmis_personas WHERE etmis_personas.id_persona=279 AND etmis_personas.id_etmi=1 AND etmis_personas.id_control=455",
                    1,
                    0,
                    1681778701
                ],
                [
                    100,
                    "2022-08-17 19:43:30",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-16', resultado='N' WHERE laboratorios_realizados.id_persona=279 AND laboratorios_realizados.id_control=455 AND laboratorios_realizados.id_laboratorio=19 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    101,
                    "2022-08-17 19:43:30",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-16', resultado='N' WHERE laboratorios_realizados.id_persona=279 AND laboratorios_realizados.id_control=455 AND laboratorios_realizados.id_laboratorio=20 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    102,
                    "2022-08-17 19:43:30",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-16', resultado='N' WHERE laboratorios_realizados.id_persona=279 AND laboratorios_realizados.id_control=455 AND laboratorios_realizados.id_laboratorio=23 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    103,
                    "2022-08-17 19:43:30",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-16', resultado='N' WHERE laboratorios_realizados.id_persona=343 AND laboratorios_realizados.id_control=587 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    104,
                    "2022-08-17 19:43:30",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-16', resultado='N' WHERE laboratorios_realizados.id_persona=343 AND laboratorios_realizados.id_control=587 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    105,
                    "2022-08-17 19:43:30",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-16', resultado='N' WHERE laboratorios_realizados.id_persona=343 AND laboratorios_realizados.id_control=587 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    106,
                    "2022-08-17 19:43:30",
                    "DELETE FROM etmis_personas WHERE etmis_personas.id_persona=343 AND etmis_personas.id_etmi=1 AND etmis_personas.id_control=587",
                    1,
                    0,
                    1681778701
                ],
                [
                    107,
                    "2022-08-17 19:43:30",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-16', resultado='P' WHERE laboratorios_realizados.id_persona=343 AND laboratorios_realizados.id_control=587 AND laboratorios_realizados.id_laboratorio=19 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    108,
                    "2022-08-17 19:43:30",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-16', resultado='P' WHERE laboratorios_realizados.id_persona=343 AND laboratorios_realizados.id_control=587 AND laboratorios_realizados.id_laboratorio=20 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    109,
                    "2022-08-17 19:43:30",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-16', resultado='P' WHERE laboratorios_realizados.id_persona=343 AND laboratorios_realizados.id_control=587 AND laboratorios_realizados.id_laboratorio=23 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    110,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=448",
                    1,
                    0,
                    1681778701
                ],
                [
                    111,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=344 AND laboratorios_realizados.id_control=659 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    112,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='10.3' WHERE laboratorios_realizados.id_persona=344 AND laboratorios_realizados.id_control=659 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    113,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='65' WHERE laboratorios_realizados.id_persona=344 AND laboratorios_realizados.id_control=659 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    114,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=449",
                    1,
                    0,
                    1681778701
                ],
                [
                    115,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=386 AND laboratorios_realizados.id_control=660 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    116,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=386 AND laboratorios_realizados.id_control=660 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    117,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=386 AND laboratorios_realizados.id_control=660 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    118,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=386 AND laboratorios_realizados.id_control=660 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    119,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='8.6' WHERE laboratorios_realizados.id_persona=386 AND laboratorios_realizados.id_control=660 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    120,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='109' WHERE laboratorios_realizados.id_persona=386 AND laboratorios_realizados.id_control=660 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    121,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='0+' WHERE laboratorios_realizados.id_persona=386 AND laboratorios_realizados.id_control=660 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    122,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='12' WHERE laboratorios_realizados.id_persona=351 AND laboratorios_realizados.id_control=661 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    123,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=451",
                    1,
                    0,
                    1681778701
                ],
                [
                    124,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=387 AND laboratorios_realizados.id_control=662 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    125,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=387 AND laboratorios_realizados.id_control=662 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    126,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=387 AND laboratorios_realizados.id_control=662 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    127,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=387 AND laboratorios_realizados.id_control=662 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    128,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='9.6' WHERE laboratorios_realizados.id_persona=387 AND laboratorios_realizados.id_control=662 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    129,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='91' WHERE laboratorios_realizados.id_persona=387 AND laboratorios_realizados.id_control=662 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    130,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='0+' WHERE laboratorios_realizados.id_persona=387 AND laboratorios_realizados.id_control=662 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    131,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=452",
                    1,
                    0,
                    1681778701
                ],
                [
                    132,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=388 AND laboratorios_realizados.id_control=663 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    133,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=388 AND laboratorios_realizados.id_control=663 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    134,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=388 AND laboratorios_realizados.id_control=663 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    135,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=388 AND laboratorios_realizados.id_control=663 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    136,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='11.3' WHERE laboratorios_realizados.id_persona=388 AND laboratorios_realizados.id_control=663 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    137,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='88' WHERE laboratorios_realizados.id_persona=388 AND laboratorios_realizados.id_control=663 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    138,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=453",
                    1,
                    0,
                    1681778701
                ],
                [
                    139,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=389 AND laboratorios_realizados.id_control=664 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    140,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=389 AND laboratorios_realizados.id_control=664 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    141,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=389 AND laboratorios_realizados.id_control=664 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    142,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=389 AND laboratorios_realizados.id_control=664 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    143,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='9.6' WHERE laboratorios_realizados.id_persona=389 AND laboratorios_realizados.id_control=664 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    144,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='70' WHERE laboratorios_realizados.id_persona=389 AND laboratorios_realizados.id_control=664 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    145,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='0+' WHERE laboratorios_realizados.id_persona=389 AND laboratorios_realizados.id_control=664 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    146,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=454",
                    1,
                    0,
                    1681778701
                ],
                [
                    147,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=390 AND laboratorios_realizados.id_control=665 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    148,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=390 AND laboratorios_realizados.id_control=665 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    149,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=390 AND laboratorios_realizados.id_control=665 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    150,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=390 AND laboratorios_realizados.id_control=665 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    151,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='10' WHERE laboratorios_realizados.id_persona=390 AND laboratorios_realizados.id_control=665 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    152,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='98' WHERE laboratorios_realizados.id_persona=390 AND laboratorios_realizados.id_control=665 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    153,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='0+' WHERE laboratorios_realizados.id_persona=390 AND laboratorios_realizados.id_control=665 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    154,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=455",
                    1,
                    0,
                    1681778701
                ],
                [
                    155,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=391 AND laboratorios_realizados.id_control=666 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    156,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=391 AND laboratorios_realizados.id_control=666 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    157,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=391 AND laboratorios_realizados.id_control=666 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    158,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=391 AND laboratorios_realizados.id_control=666 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    159,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='11.6' WHERE laboratorios_realizados.id_persona=391 AND laboratorios_realizados.id_control=666 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    160,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='86' WHERE laboratorios_realizados.id_persona=391 AND laboratorios_realizados.id_control=666 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    161,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='0+' WHERE laboratorios_realizados.id_persona=391 AND laboratorios_realizados.id_control=666 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    162,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=456",
                    1,
                    0,
                    1681778701
                ],
                [
                    163,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=392 AND laboratorios_realizados.id_control=667 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    164,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=392 AND laboratorios_realizados.id_control=667 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    165,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=392 AND laboratorios_realizados.id_control=667 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    166,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=392 AND laboratorios_realizados.id_control=667 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    167,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='11.6' WHERE laboratorios_realizados.id_persona=392 AND laboratorios_realizados.id_control=667 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    168,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='87' WHERE laboratorios_realizados.id_persona=392 AND laboratorios_realizados.id_control=667 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    169,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='0+' WHERE laboratorios_realizados.id_persona=392 AND laboratorios_realizados.id_control=667 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    170,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=457",
                    1,
                    0,
                    1681778701
                ],
                [
                    171,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=355 AND laboratorios_realizados.id_control=668 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    172,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=355 AND laboratorios_realizados.id_control=668 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    173,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=355 AND laboratorios_realizados.id_control=668 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    174,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='9.6' WHERE laboratorios_realizados.id_persona=355 AND laboratorios_realizados.id_control=668 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    175,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='108' WHERE laboratorios_realizados.id_persona=355 AND laboratorios_realizados.id_control=668 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    176,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=347 AND laboratorios_realizados.id_control=594 AND laboratorios_realizados.id_laboratorio=8 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    177,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=458",
                    1,
                    0,
                    1681778701
                ],
                [
                    178,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=347 AND laboratorios_realizados.id_control=669 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    179,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='10.6' WHERE laboratorios_realizados.id_persona=347 AND laboratorios_realizados.id_control=669 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    180,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='95' WHERE laboratorios_realizados.id_persona=347 AND laboratorios_realizados.id_control=669 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    181,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=459",
                    1,
                    0,
                    1681778701
                ],
                [
                    182,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=393 AND laboratorios_realizados.id_control=670 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    183,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=393 AND laboratorios_realizados.id_control=670 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    184,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=393 AND laboratorios_realizados.id_control=670 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    185,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=393 AND laboratorios_realizados.id_control=670 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    186,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='10.6' WHERE laboratorios_realizados.id_persona=393 AND laboratorios_realizados.id_control=670 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    187,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='65' WHERE laboratorios_realizados.id_persona=393 AND laboratorios_realizados.id_control=670 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    188,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=460",
                    1,
                    0,
                    1681778701
                ],
                [
                    189,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=357 AND laboratorios_realizados.id_control=671 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    190,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=357 AND laboratorios_realizados.id_control=671 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    191,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=357 AND laboratorios_realizados.id_control=671 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    192,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='10.3' WHERE laboratorios_realizados.id_persona=357 AND laboratorios_realizados.id_control=671 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    193,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='87' WHERE laboratorios_realizados.id_persona=357 AND laboratorios_realizados.id_control=671 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    194,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=461",
                    1,
                    0,
                    1681778701
                ],
                [
                    195,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='12' WHERE laboratorios_realizados.id_persona=352 AND laboratorios_realizados.id_control=672 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    196,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='94' WHERE laboratorios_realizados.id_persona=352 AND laboratorios_realizados.id_control=672 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    197,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=462",
                    1,
                    0,
                    1681778701
                ],
                [
                    198,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=394 AND laboratorios_realizados.id_control=673 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    199,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=394 AND laboratorios_realizados.id_control=673 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    200,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=394 AND laboratorios_realizados.id_control=673 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    201,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=394 AND laboratorios_realizados.id_control=673 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    202,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='11' WHERE laboratorios_realizados.id_persona=394 AND laboratorios_realizados.id_control=673 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    203,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='76' WHERE laboratorios_realizados.id_persona=394 AND laboratorios_realizados.id_control=673 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    204,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='0+' WHERE laboratorios_realizados.id_persona=394 AND laboratorios_realizados.id_control=673 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    205,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=463",
                    1,
                    0,
                    1681778701
                ],
                [
                    206,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=395 AND laboratorios_realizados.id_control=674 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    207,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=395 AND laboratorios_realizados.id_control=674 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    208,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=395 AND laboratorios_realizados.id_control=674 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    209,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=395 AND laboratorios_realizados.id_control=674 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    210,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='11' WHERE laboratorios_realizados.id_persona=395 AND laboratorios_realizados.id_control=674 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    211,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='114' WHERE laboratorios_realizados.id_persona=395 AND laboratorios_realizados.id_control=674 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    212,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='0+' WHERE laboratorios_realizados.id_persona=395 AND laboratorios_realizados.id_control=674 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    213,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=464",
                    1,
                    0,
                    1681778701
                ],
                [
                    214,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=348 AND laboratorios_realizados.id_control=675 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    215,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=348 AND laboratorios_realizados.id_control=675 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    216,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=348 AND laboratorios_realizados.id_control=675 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    217,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='10' WHERE laboratorios_realizados.id_persona=348 AND laboratorios_realizados.id_control=675 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    218,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='70' WHERE laboratorios_realizados.id_persona=348 AND laboratorios_realizados.id_control=675 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    219,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=465",
                    1,
                    0,
                    1681778701
                ],
                [
                    220,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=396 AND laboratorios_realizados.id_control=676 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    221,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=396 AND laboratorios_realizados.id_control=676 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    222,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=396 AND laboratorios_realizados.id_control=676 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    223,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=396 AND laboratorios_realizados.id_control=676 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    224,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='12.3' WHERE laboratorios_realizados.id_persona=396 AND laboratorios_realizados.id_control=676 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    225,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='99' WHERE laboratorios_realizados.id_persona=396 AND laboratorios_realizados.id_control=676 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    226,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='0+' WHERE laboratorios_realizados.id_persona=396 AND laboratorios_realizados.id_control=676 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    227,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=466",
                    1,
                    0,
                    1681778701
                ],
                [
                    228,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=397 AND laboratorios_realizados.id_control=677 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    229,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=397 AND laboratorios_realizados.id_control=677 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    230,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=397 AND laboratorios_realizados.id_control=677 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    231,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=397 AND laboratorios_realizados.id_control=677 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    232,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='11' WHERE laboratorios_realizados.id_persona=397 AND laboratorios_realizados.id_control=677 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    233,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='90' WHERE laboratorios_realizados.id_persona=397 AND laboratorios_realizados.id_control=677 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    234,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='0+' WHERE laboratorios_realizados.id_persona=397 AND laboratorios_realizados.id_control=677 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    235,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=467",
                    1,
                    0,
                    1681778701
                ],
                [
                    236,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=248 AND laboratorios_realizados.id_control=678 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    237,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=248 AND laboratorios_realizados.id_control=678 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    238,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=248 AND laboratorios_realizados.id_control=678 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    239,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='10' WHERE laboratorios_realizados.id_persona=248 AND laboratorios_realizados.id_control=678 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    240,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='73' WHERE laboratorios_realizados.id_persona=248 AND laboratorios_realizados.id_control=678 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    241,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=468",
                    1,
                    0,
                    1681778701
                ],
                [
                    242,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=398 AND laboratorios_realizados.id_control=679 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    243,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=398 AND laboratorios_realizados.id_control=679 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    244,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=398 AND laboratorios_realizados.id_control=679 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    245,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=398 AND laboratorios_realizados.id_control=679 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    246,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='11' WHERE laboratorios_realizados.id_persona=398 AND laboratorios_realizados.id_control=679 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    247,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='74' WHERE laboratorios_realizados.id_persona=398 AND laboratorios_realizados.id_control=679 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    248,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='0+' WHERE laboratorios_realizados.id_persona=398 AND laboratorios_realizados.id_control=679 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    249,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=469",
                    1,
                    0,
                    1681778701
                ],
                [
                    250,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=399 AND laboratorios_realizados.id_control=680 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    251,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=399 AND laboratorios_realizados.id_control=680 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    252,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=399 AND laboratorios_realizados.id_control=680 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    253,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=399 AND laboratorios_realizados.id_control=680 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    254,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='12.3' WHERE laboratorios_realizados.id_persona=399 AND laboratorios_realizados.id_control=680 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    255,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='81' WHERE laboratorios_realizados.id_persona=399 AND laboratorios_realizados.id_control=680 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    256,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='0+' WHERE laboratorios_realizados.id_persona=399 AND laboratorios_realizados.id_control=680 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    257,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=470",
                    1,
                    0,
                    1681778701
                ],
                [
                    258,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=400 AND laboratorios_realizados.id_control=681 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    259,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=400 AND laboratorios_realizados.id_control=681 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    260,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=400 AND laboratorios_realizados.id_control=681 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    261,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=400 AND laboratorios_realizados.id_control=681 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    262,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='11' WHERE laboratorios_realizados.id_persona=400 AND laboratorios_realizados.id_control=681 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    263,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='86' WHERE laboratorios_realizados.id_persona=400 AND laboratorios_realizados.id_control=681 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    264,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=400 AND laboratorios_realizados.id_control=681 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    265,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=471",
                    1,
                    0,
                    1681778701
                ],
                [
                    266,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=360 AND laboratorios_realizados.id_control=682 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    267,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=360 AND laboratorios_realizados.id_control=682 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    268,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=360 AND laboratorios_realizados.id_control=682 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    269,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='11.3' WHERE laboratorios_realizados.id_persona=360 AND laboratorios_realizados.id_control=682 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    270,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='87' WHERE laboratorios_realizados.id_persona=360 AND laboratorios_realizados.id_control=682 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    271,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=472",
                    1,
                    0,
                    1681778701
                ],
                [
                    272,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=361 AND laboratorios_realizados.id_control=683 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    273,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='9' WHERE laboratorios_realizados.id_persona=361 AND laboratorios_realizados.id_control=683 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    274,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='88' WHERE laboratorios_realizados.id_persona=361 AND laboratorios_realizados.id_control=683 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    275,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=473",
                    1,
                    0,
                    1681778701
                ],
                [
                    276,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=401 AND laboratorios_realizados.id_control=684 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    277,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=401 AND laboratorios_realizados.id_control=684 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    278,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=401 AND laboratorios_realizados.id_control=684 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    279,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=401 AND laboratorios_realizados.id_control=684 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    280,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='11' WHERE laboratorios_realizados.id_persona=401 AND laboratorios_realizados.id_control=684 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    281,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='79' WHERE laboratorios_realizados.id_persona=401 AND laboratorios_realizados.id_control=684 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    282,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=401 AND laboratorios_realizados.id_control=684 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    283,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100321",
                    1,
                    0,
                    1681778701
                ],
                [
                    284,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100209 AND laboratorios_realizados.id_control=100353 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    285,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100209 AND laboratorios_realizados.id_control=100353 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    286,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100209 AND laboratorios_realizados.id_control=100353 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    287,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100209 AND laboratorios_realizados.id_control=100353 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    288,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='10.3' WHERE laboratorios_realizados.id_persona=100209 AND laboratorios_realizados.id_control=100353 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    289,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='89' WHERE laboratorios_realizados.id_persona=100209 AND laboratorios_realizados.id_control=100353 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    290,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100209 AND laboratorios_realizados.id_control=100353 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    291,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100300",
                    1,
                    0,
                    1681778701
                ],
                [
                    292,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100211 AND laboratorios_realizados.id_control=100355 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    293,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100211 AND laboratorios_realizados.id_control=100355 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    294,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100211 AND laboratorios_realizados.id_control=100355 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    295,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100211 AND laboratorios_realizados.id_control=100355 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    296,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='11.6' WHERE laboratorios_realizados.id_persona=100211 AND laboratorios_realizados.id_control=100355 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    297,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='105' WHERE laboratorios_realizados.id_persona=100211 AND laboratorios_realizados.id_control=100355 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    298,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100211 AND laboratorios_realizados.id_control=100355 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    299,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100298",
                    1,
                    0,
                    1681778701
                ],
                [
                    300,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100210 AND laboratorios_realizados.id_control=100354 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    301,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100210 AND laboratorios_realizados.id_control=100354 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    302,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100210 AND laboratorios_realizados.id_control=100354 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    303,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100210 AND laboratorios_realizados.id_control=100354 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    304,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='10' WHERE laboratorios_realizados.id_persona=100210 AND laboratorios_realizados.id_control=100354 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    305,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='70' WHERE laboratorios_realizados.id_persona=100210 AND laboratorios_realizados.id_control=100354 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    306,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100210 AND laboratorios_realizados.id_control=100354 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    307,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100299",
                    1,
                    0,
                    1681778701
                ],
                [
                    308,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100212 AND laboratorios_realizados.id_control=100356 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    309,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100212 AND laboratorios_realizados.id_control=100356 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    310,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100212 AND laboratorios_realizados.id_control=100356 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    311,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100212 AND laboratorios_realizados.id_control=100356 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    312,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='13' WHERE laboratorios_realizados.id_persona=100212 AND laboratorios_realizados.id_control=100356 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    313,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='84' WHERE laboratorios_realizados.id_persona=100212 AND laboratorios_realizados.id_control=100356 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    314,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100212 AND laboratorios_realizados.id_control=100356 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    315,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100301",
                    1,
                    0,
                    1681778701
                ],
                [
                    316,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100214 AND laboratorios_realizados.id_control=100358 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    317,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100214 AND laboratorios_realizados.id_control=100358 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    318,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100214 AND laboratorios_realizados.id_control=100358 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    319,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100214 AND laboratorios_realizados.id_control=100358 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    320,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='10' WHERE laboratorios_realizados.id_persona=100214 AND laboratorios_realizados.id_control=100358 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    321,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='79' WHERE laboratorios_realizados.id_persona=100214 AND laboratorios_realizados.id_control=100358 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    322,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0-' WHERE laboratorios_realizados.id_persona=100214 AND laboratorios_realizados.id_control=100358 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    323,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100302",
                    1,
                    0,
                    1681778701
                ],
                [
                    324,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100215 AND laboratorios_realizados.id_control=100359 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    325,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100215 AND laboratorios_realizados.id_control=100359 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    326,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100215 AND laboratorios_realizados.id_control=100359 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    327,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100215 AND laboratorios_realizados.id_control=100359 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    328,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='10' WHERE laboratorios_realizados.id_persona=100215 AND laboratorios_realizados.id_control=100359 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    329,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='70' WHERE laboratorios_realizados.id_persona=100215 AND laboratorios_realizados.id_control=100359 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    330,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='A+' WHERE laboratorios_realizados.id_persona=100215 AND laboratorios_realizados.id_control=100359 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    331,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100303",
                    1,
                    0,
                    1681778701
                ],
                [
                    332,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100216 AND laboratorios_realizados.id_control=100360 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    333,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100216 AND laboratorios_realizados.id_control=100360 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    334,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100216 AND laboratorios_realizados.id_control=100360 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    335,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100216 AND laboratorios_realizados.id_control=100360 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    336,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='10.6' WHERE laboratorios_realizados.id_persona=100216 AND laboratorios_realizados.id_control=100360 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    337,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='75' WHERE laboratorios_realizados.id_persona=100216 AND laboratorios_realizados.id_control=100360 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    338,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100216 AND laboratorios_realizados.id_control=100360 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    339,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100304",
                    1,
                    0,
                    1681778701
                ],
                [
                    340,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100217 AND laboratorios_realizados.id_control=100361 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    341,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100217 AND laboratorios_realizados.id_control=100361 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    342,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100217 AND laboratorios_realizados.id_control=100361 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    343,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100217 AND laboratorios_realizados.id_control=100361 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    344,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='10.6' WHERE laboratorios_realizados.id_persona=100217 AND laboratorios_realizados.id_control=100361 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    345,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='75' WHERE laboratorios_realizados.id_persona=100217 AND laboratorios_realizados.id_control=100361 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    346,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100217 AND laboratorios_realizados.id_control=100361 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    347,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100305",
                    1,
                    0,
                    1681778701
                ],
                [
                    348,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100218 AND laboratorios_realizados.id_control=100362 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    349,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100218 AND laboratorios_realizados.id_control=100362 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    350,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100218 AND laboratorios_realizados.id_control=100362 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    351,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100218 AND laboratorios_realizados.id_control=100362 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    352,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='8.3' WHERE laboratorios_realizados.id_persona=100218 AND laboratorios_realizados.id_control=100362 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    353,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='85' WHERE laboratorios_realizados.id_persona=100218 AND laboratorios_realizados.id_control=100362 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    354,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='A+' WHERE laboratorios_realizados.id_persona=100218 AND laboratorios_realizados.id_control=100362 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    355,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100306",
                    1,
                    0,
                    1681778701
                ],
                [
                    356,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100219 AND laboratorios_realizados.id_control=100363 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    357,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100219 AND laboratorios_realizados.id_control=100363 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    358,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100219 AND laboratorios_realizados.id_control=100363 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    359,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100219 AND laboratorios_realizados.id_control=100363 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    360,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='10' WHERE laboratorios_realizados.id_persona=100219 AND laboratorios_realizados.id_control=100363 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    361,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='85' WHERE laboratorios_realizados.id_persona=100219 AND laboratorios_realizados.id_control=100363 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    362,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100219 AND laboratorios_realizados.id_control=100363 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    363,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100309",
                    1,
                    0,
                    1681778701
                ],
                [
                    364,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100222 AND laboratorios_realizados.id_control=100366 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    365,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100222 AND laboratorios_realizados.id_control=100366 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    366,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100222 AND laboratorios_realizados.id_control=100366 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    367,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100222 AND laboratorios_realizados.id_control=100366 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    368,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='12.3' WHERE laboratorios_realizados.id_persona=100222 AND laboratorios_realizados.id_control=100366 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    369,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='93' WHERE laboratorios_realizados.id_persona=100222 AND laboratorios_realizados.id_control=100366 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    370,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100222 AND laboratorios_realizados.id_control=100366 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    371,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100308",
                    1,
                    0,
                    1681778701
                ],
                [
                    372,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100221 AND laboratorios_realizados.id_control=100365 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    373,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100221 AND laboratorios_realizados.id_control=100365 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    374,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100221 AND laboratorios_realizados.id_control=100365 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    375,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100221 AND laboratorios_realizados.id_control=100365 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    376,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='9' WHERE laboratorios_realizados.id_persona=100221 AND laboratorios_realizados.id_control=100365 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    377,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='84' WHERE laboratorios_realizados.id_persona=100221 AND laboratorios_realizados.id_control=100365 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    378,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100221 AND laboratorios_realizados.id_control=100365 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    379,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100307",
                    1,
                    0,
                    1681778701
                ],
                [
                    380,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100220 AND laboratorios_realizados.id_control=100364 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    381,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100220 AND laboratorios_realizados.id_control=100364 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    382,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100220 AND laboratorios_realizados.id_control=100364 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    383,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100220 AND laboratorios_realizados.id_control=100364 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    384,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='10' WHERE laboratorios_realizados.id_persona=100220 AND laboratorios_realizados.id_control=100364 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    385,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='72' WHERE laboratorios_realizados.id_persona=100220 AND laboratorios_realizados.id_control=100364 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    386,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100220 AND laboratorios_realizados.id_control=100364 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    387,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100311",
                    1,
                    0,
                    1681778701
                ],
                [
                    388,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100224 AND laboratorios_realizados.id_control=100368 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    389,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100224 AND laboratorios_realizados.id_control=100368 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    390,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100224 AND laboratorios_realizados.id_control=100368 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    391,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100224 AND laboratorios_realizados.id_control=100368 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    392,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='10.6' WHERE laboratorios_realizados.id_persona=100224 AND laboratorios_realizados.id_control=100368 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    393,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='106' WHERE laboratorios_realizados.id_persona=100224 AND laboratorios_realizados.id_control=100368 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    394,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100224 AND laboratorios_realizados.id_control=100368 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    395,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100310",
                    1,
                    0,
                    1681778701
                ],
                [
                    396,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100223 AND laboratorios_realizados.id_control=100367 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    397,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100223 AND laboratorios_realizados.id_control=100367 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    398,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100223 AND laboratorios_realizados.id_control=100367 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    399,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100223 AND laboratorios_realizados.id_control=100367 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    400,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='10.3' WHERE laboratorios_realizados.id_persona=100223 AND laboratorios_realizados.id_control=100367 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    401,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='105' WHERE laboratorios_realizados.id_persona=100223 AND laboratorios_realizados.id_control=100367 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    402,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100223 AND laboratorios_realizados.id_control=100367 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    403,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100312",
                    1,
                    0,
                    1681778701
                ],
                [
                    404,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100225 AND laboratorios_realizados.id_control=100369 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    405,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100225 AND laboratorios_realizados.id_control=100369 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    406,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100225 AND laboratorios_realizados.id_control=100369 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    407,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100225 AND laboratorios_realizados.id_control=100369 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    408,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='13' WHERE laboratorios_realizados.id_persona=100225 AND laboratorios_realizados.id_control=100369 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    409,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='75' WHERE laboratorios_realizados.id_persona=100225 AND laboratorios_realizados.id_control=100369 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    410,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100225 AND laboratorios_realizados.id_control=100369 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    411,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100313",
                    1,
                    0,
                    1681778701
                ],
                [
                    412,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100226 AND laboratorios_realizados.id_control=100370 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    413,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100226 AND laboratorios_realizados.id_control=100370 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    414,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100226 AND laboratorios_realizados.id_control=100370 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    415,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100226 AND laboratorios_realizados.id_control=100370 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    416,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='8.6' WHERE laboratorios_realizados.id_persona=100226 AND laboratorios_realizados.id_control=100370 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    417,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='83' WHERE laboratorios_realizados.id_persona=100226 AND laboratorios_realizados.id_control=100370 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    418,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100226 AND laboratorios_realizados.id_control=100370 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    419,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100314",
                    1,
                    0,
                    1681778701
                ],
                [
                    420,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100227 AND laboratorios_realizados.id_control=100371 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    421,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100227 AND laboratorios_realizados.id_control=100371 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    422,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100227 AND laboratorios_realizados.id_control=100371 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    423,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100227 AND laboratorios_realizados.id_control=100371 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    424,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='9.3' WHERE laboratorios_realizados.id_persona=100227 AND laboratorios_realizados.id_control=100371 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    425,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='70' WHERE laboratorios_realizados.id_persona=100227 AND laboratorios_realizados.id_control=100371 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    426,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100227 AND laboratorios_realizados.id_control=100371 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    427,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100315",
                    1,
                    0,
                    1681778701
                ],
                [
                    428,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100228 AND laboratorios_realizados.id_control=100372 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    429,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100228 AND laboratorios_realizados.id_control=100372 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    430,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100228 AND laboratorios_realizados.id_control=100372 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    431,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100228 AND laboratorios_realizados.id_control=100372 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    432,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='11' WHERE laboratorios_realizados.id_persona=100228 AND laboratorios_realizados.id_control=100372 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    433,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='72' WHERE laboratorios_realizados.id_persona=100228 AND laboratorios_realizados.id_control=100372 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    434,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100228 AND laboratorios_realizados.id_control=100372 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    435,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100317",
                    1,
                    0,
                    1681778701
                ],
                [
                    436,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=264 AND laboratorios_realizados.id_control=100374 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    437,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=264 AND laboratorios_realizados.id_control=100374 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    438,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=264 AND laboratorios_realizados.id_control=100374 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    439,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=264 AND laboratorios_realizados.id_control=100374 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    440,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='10.6' WHERE laboratorios_realizados.id_persona=264 AND laboratorios_realizados.id_control=100374 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    441,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='76' WHERE laboratorios_realizados.id_persona=264 AND laboratorios_realizados.id_control=100374 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    442,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=264 AND laboratorios_realizados.id_control=100374 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    443,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100316",
                    1,
                    0,
                    1681778701
                ],
                [
                    444,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100229 AND laboratorios_realizados.id_control=100373 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    445,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100229 AND laboratorios_realizados.id_control=100373 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    446,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100229 AND laboratorios_realizados.id_control=100373 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    447,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100229 AND laboratorios_realizados.id_control=100373 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    448,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='10' WHERE laboratorios_realizados.id_persona=100229 AND laboratorios_realizados.id_control=100373 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    449,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='77' WHERE laboratorios_realizados.id_persona=100229 AND laboratorios_realizados.id_control=100373 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    450,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100229 AND laboratorios_realizados.id_control=100373 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    451,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100318",
                    1,
                    0,
                    1681778701
                ],
                [
                    452,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100230 AND laboratorios_realizados.id_control=100375 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    453,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100230 AND laboratorios_realizados.id_control=100375 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    454,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100230 AND laboratorios_realizados.id_control=100375 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    455,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100230 AND laboratorios_realizados.id_control=100375 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    456,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='9.6' WHERE laboratorios_realizados.id_persona=100230 AND laboratorios_realizados.id_control=100375 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    457,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='79' WHERE laboratorios_realizados.id_persona=100230 AND laboratorios_realizados.id_control=100375 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    458,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100230 AND laboratorios_realizados.id_control=100375 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    459,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100319",
                    1,
                    0,
                    1681778701
                ],
                [
                    460,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='11.3' WHERE laboratorios_realizados.id_persona=100231 AND laboratorios_realizados.id_control=100376 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    461,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='69' WHERE laboratorios_realizados.id_persona=100231 AND laboratorios_realizados.id_control=100376 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    462,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100326",
                    1,
                    0,
                    1681778701
                ],
                [
                    463,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100232 AND laboratorios_realizados.id_control=100377 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    464,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100232 AND laboratorios_realizados.id_control=100377 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    465,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100232 AND laboratorios_realizados.id_control=100377 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    466,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100232 AND laboratorios_realizados.id_control=100377 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    467,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='10' WHERE laboratorios_realizados.id_persona=100232 AND laboratorios_realizados.id_control=100377 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    468,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='77' WHERE laboratorios_realizados.id_persona=100232 AND laboratorios_realizados.id_control=100377 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    469,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100232 AND laboratorios_realizados.id_control=100377 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    470,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100322",
                    1,
                    0,
                    1681778701
                ],
                [
                    471,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100234 AND laboratorios_realizados.id_control=100379 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    472,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100234 AND laboratorios_realizados.id_control=100379 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    473,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100234 AND laboratorios_realizados.id_control=100379 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    474,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100234 AND laboratorios_realizados.id_control=100379 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    475,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='10' WHERE laboratorios_realizados.id_persona=100234 AND laboratorios_realizados.id_control=100379 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    476,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='78' WHERE laboratorios_realizados.id_persona=100234 AND laboratorios_realizados.id_control=100379 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    477,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100234 AND laboratorios_realizados.id_control=100379 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    478,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100323",
                    1,
                    0,
                    1681778701
                ],
                [
                    479,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100235 AND laboratorios_realizados.id_control=100380 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    480,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100235 AND laboratorios_realizados.id_control=100380 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    481,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100235 AND laboratorios_realizados.id_control=100380 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    482,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100235 AND laboratorios_realizados.id_control=100380 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    483,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='10.6' WHERE laboratorios_realizados.id_persona=100235 AND laboratorios_realizados.id_control=100380 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    484,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='70' WHERE laboratorios_realizados.id_persona=100235 AND laboratorios_realizados.id_control=100380 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    485,
                    "2022-09-28 10:34:03",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100235 AND laboratorios_realizados.id_control=100380 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    486,
                    "2022-09-28 10:34:03",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100320",
                    1,
                    0,
                    1681778701
                ],
                [
                    487,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='9.3' WHERE laboratorios_realizados.id_persona=100233 AND laboratorios_realizados.id_control=100378 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    488,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='74' WHERE laboratorios_realizados.id_persona=100233 AND laboratorios_realizados.id_control=100378 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    489,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100324",
                    1,
                    0,
                    1681778701
                ],
                [
                    490,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100236 AND laboratorios_realizados.id_control=100381 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    491,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100236 AND laboratorios_realizados.id_control=100381 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    492,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100236 AND laboratorios_realizados.id_control=100381 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    493,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100236 AND laboratorios_realizados.id_control=100381 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    494,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='9' WHERE laboratorios_realizados.id_persona=100236 AND laboratorios_realizados.id_control=100381 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    495,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='82' WHERE laboratorios_realizados.id_persona=100236 AND laboratorios_realizados.id_control=100381 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    496,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100236 AND laboratorios_realizados.id_control=100381 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    497,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100325",
                    1,
                    0,
                    1681778701
                ],
                [
                    498,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100237 AND laboratorios_realizados.id_control=100382 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    499,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100237 AND laboratorios_realizados.id_control=100382 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    500,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100237 AND laboratorios_realizados.id_control=100382 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    501,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100237 AND laboratorios_realizados.id_control=100382 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    502,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='9' WHERE laboratorios_realizados.id_persona=100237 AND laboratorios_realizados.id_control=100382 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    503,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='81' WHERE laboratorios_realizados.id_persona=100237 AND laboratorios_realizados.id_control=100382 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    504,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100237 AND laboratorios_realizados.id_control=100382 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    505,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100327",
                    1,
                    0,
                    1681778701
                ],
                [
                    506,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100238 AND laboratorios_realizados.id_control=100383 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    507,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100238 AND laboratorios_realizados.id_control=100383 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    508,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100238 AND laboratorios_realizados.id_control=100383 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    509,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100238 AND laboratorios_realizados.id_control=100383 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    510,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='11' WHERE laboratorios_realizados.id_persona=100238 AND laboratorios_realizados.id_control=100383 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    511,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='79' WHERE laboratorios_realizados.id_persona=100238 AND laboratorios_realizados.id_control=100383 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    512,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100238 AND laboratorios_realizados.id_control=100383 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    513,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100328",
                    1,
                    0,
                    1681778701
                ],
                [
                    514,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100239 AND laboratorios_realizados.id_control=100384 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    515,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100239 AND laboratorios_realizados.id_control=100384 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    516,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100239 AND laboratorios_realizados.id_control=100384 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    517,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100239 AND laboratorios_realizados.id_control=100384 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    518,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='10.6' WHERE laboratorios_realizados.id_persona=100239 AND laboratorios_realizados.id_control=100384 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    519,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='76' WHERE laboratorios_realizados.id_persona=100239 AND laboratorios_realizados.id_control=100384 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    520,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100239 AND laboratorios_realizados.id_control=100384 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    521,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100330",
                    1,
                    0,
                    1681778701
                ],
                [
                    522,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100240 AND laboratorios_realizados.id_control=100386 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    523,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100240 AND laboratorios_realizados.id_control=100386 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    524,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100240 AND laboratorios_realizados.id_control=100386 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    525,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100240 AND laboratorios_realizados.id_control=100386 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    526,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='11.3' WHERE laboratorios_realizados.id_persona=100240 AND laboratorios_realizados.id_control=100386 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    527,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='99' WHERE laboratorios_realizados.id_persona=100240 AND laboratorios_realizados.id_control=100386 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    528,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='0+' WHERE laboratorios_realizados.id_persona=100240 AND laboratorios_realizados.id_control=100386 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    529,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100347",
                    1,
                    0,
                    1681778701
                ],
                [
                    530,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100255 AND laboratorios_realizados.id_control=100408 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    531,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100255 AND laboratorios_realizados.id_control=100408 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    532,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100255 AND laboratorios_realizados.id_control=100408 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    533,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100255 AND laboratorios_realizados.id_control=100408 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    534,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='12.6' WHERE laboratorios_realizados.id_persona=100255 AND laboratorios_realizados.id_control=100408 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    535,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='76' WHERE laboratorios_realizados.id_persona=100255 AND laboratorios_realizados.id_control=100408 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    536,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='0+' WHERE laboratorios_realizados.id_persona=100255 AND laboratorios_realizados.id_control=100408 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    537,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100331",
                    1,
                    0,
                    1681778701
                ],
                [
                    538,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100242 AND laboratorios_realizados.id_control=100388 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    539,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100242 AND laboratorios_realizados.id_control=100388 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    540,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100242 AND laboratorios_realizados.id_control=100388 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    541,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='12' WHERE laboratorios_realizados.id_persona=100242 AND laboratorios_realizados.id_control=100388 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    542,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='90' WHERE laboratorios_realizados.id_persona=100242 AND laboratorios_realizados.id_control=100388 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    543,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='B+' WHERE laboratorios_realizados.id_persona=100242 AND laboratorios_realizados.id_control=100388 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    544,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100332",
                    1,
                    0,
                    1681778701
                ],
                [
                    545,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100243 AND laboratorios_realizados.id_control=100389 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    546,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100243 AND laboratorios_realizados.id_control=100389 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    547,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100243 AND laboratorios_realizados.id_control=100389 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    548,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100243 AND laboratorios_realizados.id_control=100389 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    549,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='11' WHERE laboratorios_realizados.id_persona=100243 AND laboratorios_realizados.id_control=100389 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    550,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='74' WHERE laboratorios_realizados.id_persona=100243 AND laboratorios_realizados.id_control=100389 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    551,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='A+' WHERE laboratorios_realizados.id_persona=100243 AND laboratorios_realizados.id_control=100389 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    552,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100333",
                    1,
                    0,
                    1681778701
                ],
                [
                    553,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100244 AND laboratorios_realizados.id_control=100390 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    554,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100244 AND laboratorios_realizados.id_control=100390 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    555,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100244 AND laboratorios_realizados.id_control=100390 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    556,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100244 AND laboratorios_realizados.id_control=100390 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    557,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='11' WHERE laboratorios_realizados.id_persona=100244 AND laboratorios_realizados.id_control=100390 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    558,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='88' WHERE laboratorios_realizados.id_persona=100244 AND laboratorios_realizados.id_control=100390 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    559,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='0+' WHERE laboratorios_realizados.id_persona=100244 AND laboratorios_realizados.id_control=100390 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    560,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100334",
                    1,
                    0,
                    1681778701
                ],
                [
                    561,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100245 AND laboratorios_realizados.id_control=100391 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    562,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100245 AND laboratorios_realizados.id_control=100391 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    563,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100245 AND laboratorios_realizados.id_control=100391 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    564,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100245 AND laboratorios_realizados.id_control=100391 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    565,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='11.6' WHERE laboratorios_realizados.id_persona=100245 AND laboratorios_realizados.id_control=100391 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    566,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='70' WHERE laboratorios_realizados.id_persona=100245 AND laboratorios_realizados.id_control=100391 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    567,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200116",
                    1,
                    0,
                    1681778701
                ],
                [
                    568,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200065 AND laboratorios_realizados.id_control=200126 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    569,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='11.3' WHERE laboratorios_realizados.id_persona=200065 AND laboratorios_realizados.id_control=200126 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    570,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='99' WHERE laboratorios_realizados.id_persona=200065 AND laboratorios_realizados.id_control=200126 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    571,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200065 AND laboratorios_realizados.id_control=200126 AND laboratorios_realizados.id_laboratorio=8 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    572,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='0+' WHERE laboratorios_realizados.id_persona=200065 AND laboratorios_realizados.id_control=200126 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    573,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100335",
                    1,
                    0,
                    1681778701
                ],
                [
                    574,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='12' WHERE laboratorios_realizados.id_persona=200065 AND laboratorios_realizados.id_control=100392 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    575,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='81' WHERE laboratorios_realizados.id_persona=200065 AND laboratorios_realizados.id_control=100392 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    576,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100336",
                    1,
                    0,
                    1681778701
                ],
                [
                    577,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='12' WHERE laboratorios_realizados.id_persona=100246 AND laboratorios_realizados.id_control=100393 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    578,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='88' WHERE laboratorios_realizados.id_persona=100246 AND laboratorios_realizados.id_control=100393 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    579,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='0+' WHERE laboratorios_realizados.id_persona=100246 AND laboratorios_realizados.id_control=100393 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    580,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200120",
                    1,
                    0,
                    1681778701
                ],
                [
                    581,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200069 AND laboratorios_realizados.id_control=200130 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    582,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200069 AND laboratorios_realizados.id_control=200130 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    583,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200069 AND laboratorios_realizados.id_control=200130 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    584,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200069 AND laboratorios_realizados.id_control=200130 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    585,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='11.6' WHERE laboratorios_realizados.id_persona=200069 AND laboratorios_realizados.id_control=200130 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    586,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='104' WHERE laboratorios_realizados.id_persona=200069 AND laboratorios_realizados.id_control=200130 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    587,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200069 AND laboratorios_realizados.id_control=200130 AND laboratorios_realizados.id_laboratorio=8 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    588,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='A+' WHERE laboratorios_realizados.id_persona=200069 AND laboratorios_realizados.id_control=200130 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    589,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100337",
                    1,
                    0,
                    1681778701
                ],
                [
                    590,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200069 AND laboratorios_realizados.id_control=100394 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    591,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200069 AND laboratorios_realizados.id_control=100394 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    592,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200069 AND laboratorios_realizados.id_control=100394 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    593,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200069 AND laboratorios_realizados.id_control=100394 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    594,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='12' WHERE laboratorios_realizados.id_persona=200069 AND laboratorios_realizados.id_control=100394 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    595,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='107' WHERE laboratorios_realizados.id_persona=200069 AND laboratorios_realizados.id_control=100394 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    596,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200123",
                    1,
                    0,
                    1681778701
                ],
                [
                    597,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200072 AND laboratorios_realizados.id_control=200133 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    598,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200072 AND laboratorios_realizados.id_control=200133 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    599,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200072 AND laboratorios_realizados.id_control=200133 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    600,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200072 AND laboratorios_realizados.id_control=200133 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    601,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='11.3' WHERE laboratorios_realizados.id_persona=200072 AND laboratorios_realizados.id_control=200133 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    602,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='96' WHERE laboratorios_realizados.id_persona=200072 AND laboratorios_realizados.id_control=200133 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    603,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200072 AND laboratorios_realizados.id_control=200133 AND laboratorios_realizados.id_laboratorio=8 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    604,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='0+' WHERE laboratorios_realizados.id_persona=200072 AND laboratorios_realizados.id_control=200133 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    605,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100338",
                    1,
                    0,
                    1681778701
                ],
                [
                    606,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200072 AND laboratorios_realizados.id_control=100395 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    607,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200072 AND laboratorios_realizados.id_control=100395 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    608,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200072 AND laboratorios_realizados.id_control=100395 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    609,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200072 AND laboratorios_realizados.id_control=100395 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    610,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='12' WHERE laboratorios_realizados.id_persona=200072 AND laboratorios_realizados.id_control=100395 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    611,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='96' WHERE laboratorios_realizados.id_persona=200072 AND laboratorios_realizados.id_control=100395 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    612,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='0+' WHERE laboratorios_realizados.id_persona=200072 AND laboratorios_realizados.id_control=100395 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    613,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100339",
                    1,
                    0,
                    1681778701
                ],
                [
                    614,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100248 AND laboratorios_realizados.id_control=100397 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    615,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100248 AND laboratorios_realizados.id_control=100397 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    616,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100248 AND laboratorios_realizados.id_control=100397 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    617,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100248 AND laboratorios_realizados.id_control=100397 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    618,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='12.3' WHERE laboratorios_realizados.id_persona=100248 AND laboratorios_realizados.id_control=100397 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    619,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='119' WHERE laboratorios_realizados.id_persona=100248 AND laboratorios_realizados.id_control=100397 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    620,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='0+' WHERE laboratorios_realizados.id_persona=100248 AND laboratorios_realizados.id_control=100397 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    621,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200118",
                    1,
                    0,
                    1681778701
                ],
                [
                    622,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200067 AND laboratorios_realizados.id_control=200128 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    623,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='13.6' WHERE laboratorios_realizados.id_persona=200067 AND laboratorios_realizados.id_control=200128 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    624,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='344' WHERE laboratorios_realizados.id_persona=200067 AND laboratorios_realizados.id_control=200128 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    625,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200067 AND laboratorios_realizados.id_control=200128 AND laboratorios_realizados.id_laboratorio=8 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    626,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='A+' WHERE laboratorios_realizados.id_persona=200067 AND laboratorios_realizados.id_control=200128 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    627,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100340",
                    1,
                    0,
                    1681778701
                ],
                [
                    628,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='12.6' WHERE laboratorios_realizados.id_persona=200067 AND laboratorios_realizados.id_control=100398 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    629,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='170' WHERE laboratorios_realizados.id_persona=200067 AND laboratorios_realizados.id_control=100398 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    630,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200125",
                    1,
                    0,
                    1681778701
                ],
                [
                    631,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200074 AND laboratorios_realizados.id_control=200135 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    632,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='11' WHERE laboratorios_realizados.id_persona=200074 AND laboratorios_realizados.id_control=200135 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    633,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='121' WHERE laboratorios_realizados.id_persona=200074 AND laboratorios_realizados.id_control=200135 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    634,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200074 AND laboratorios_realizados.id_control=200135 AND laboratorios_realizados.id_laboratorio=8 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    635,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='A+' WHERE laboratorios_realizados.id_persona=200074 AND laboratorios_realizados.id_control=200135 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    636,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100341",
                    1,
                    0,
                    1681778701
                ],
                [
                    637,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200074 AND laboratorios_realizados.id_control=100400 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    638,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200074 AND laboratorios_realizados.id_control=100400 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    639,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200074 AND laboratorios_realizados.id_control=100400 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    640,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200074 AND laboratorios_realizados.id_control=100400 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    641,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='11' WHERE laboratorios_realizados.id_persona=200074 AND laboratorios_realizados.id_control=100400 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    642,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='97' WHERE laboratorios_realizados.id_persona=200074 AND laboratorios_realizados.id_control=100400 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    643,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100348",
                    1,
                    0,
                    1681778701
                ],
                [
                    644,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100256 AND laboratorios_realizados.id_control=100409 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    645,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100256 AND laboratorios_realizados.id_control=100409 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    646,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100256 AND laboratorios_realizados.id_control=100409 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    647,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100256 AND laboratorios_realizados.id_control=100409 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    648,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='11.6' WHERE laboratorios_realizados.id_persona=100256 AND laboratorios_realizados.id_control=100409 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    649,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='119' WHERE laboratorios_realizados.id_persona=100256 AND laboratorios_realizados.id_control=100409 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    650,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='0+' WHERE laboratorios_realizados.id_persona=100256 AND laboratorios_realizados.id_control=100409 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    651,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100342",
                    1,
                    0,
                    1681778701
                ],
                [
                    652,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100250 AND laboratorios_realizados.id_control=100402 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    653,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='13' WHERE laboratorios_realizados.id_persona=100250 AND laboratorios_realizados.id_control=100402 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    654,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='136' WHERE laboratorios_realizados.id_persona=100250 AND laboratorios_realizados.id_control=100402 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    655,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='0+' WHERE laboratorios_realizados.id_persona=100250 AND laboratorios_realizados.id_control=100402 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    656,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200129",
                    1,
                    0,
                    1681778701
                ],
                [
                    657,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200078 AND laboratorios_realizados.id_control=200139 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    658,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='13.6' WHERE laboratorios_realizados.id_persona=200078 AND laboratorios_realizados.id_control=200139 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    659,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='102' WHERE laboratorios_realizados.id_persona=200078 AND laboratorios_realizados.id_control=200139 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    660,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200078 AND laboratorios_realizados.id_control=200139 AND laboratorios_realizados.id_laboratorio=8 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    661,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='0+' WHERE laboratorios_realizados.id_persona=200078 AND laboratorios_realizados.id_control=200139 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    662,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100343",
                    1,
                    0,
                    1681778701
                ],
                [
                    663,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200078 AND laboratorios_realizados.id_control=100403 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    664,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200078 AND laboratorios_realizados.id_control=100403 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    665,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200078 AND laboratorios_realizados.id_control=100403 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    666,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200078 AND laboratorios_realizados.id_control=100403 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    667,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='12.6' WHERE laboratorios_realizados.id_persona=200078 AND laboratorios_realizados.id_control=100403 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    668,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='94' WHERE laboratorios_realizados.id_persona=200078 AND laboratorios_realizados.id_control=100403 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    669,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100345",
                    1,
                    0,
                    1681778701
                ],
                [
                    670,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100253 AND laboratorios_realizados.id_control=100406 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    671,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100253 AND laboratorios_realizados.id_control=100406 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    672,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100253 AND laboratorios_realizados.id_control=100406 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    673,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100253 AND laboratorios_realizados.id_control=100406 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    674,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='12.6' WHERE laboratorios_realizados.id_persona=100253 AND laboratorios_realizados.id_control=100406 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    675,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='89' WHERE laboratorios_realizados.id_persona=100253 AND laboratorios_realizados.id_control=100406 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    676,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='0+' WHERE laboratorios_realizados.id_persona=100253 AND laboratorios_realizados.id_control=100406 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    677,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100346",
                    1,
                    0,
                    1681778701
                ],
                [
                    678,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100254 AND laboratorios_realizados.id_control=100407 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    679,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100254 AND laboratorios_realizados.id_control=100407 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    680,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100254 AND laboratorios_realizados.id_control=100407 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    681,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100254 AND laboratorios_realizados.id_control=100407 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    682,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='13' WHERE laboratorios_realizados.id_persona=100254 AND laboratorios_realizados.id_control=100407 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    683,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='86' WHERE laboratorios_realizados.id_persona=100254 AND laboratorios_realizados.id_control=100407 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    684,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='0+' WHERE laboratorios_realizados.id_persona=100254 AND laboratorios_realizados.id_control=100407 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    685,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100349",
                    1,
                    0,
                    1681778701
                ],
                [
                    686,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100257 AND laboratorios_realizados.id_control=100410 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    687,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100257 AND laboratorios_realizados.id_control=100410 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    688,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100257 AND laboratorios_realizados.id_control=100410 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    689,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100257 AND laboratorios_realizados.id_control=100410 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    690,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='10.3' WHERE laboratorios_realizados.id_persona=100257 AND laboratorios_realizados.id_control=100410 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    691,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='125' WHERE laboratorios_realizados.id_persona=100257 AND laboratorios_realizados.id_control=100410 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    692,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='0+' WHERE laboratorios_realizados.id_persona=100257 AND laboratorios_realizados.id_control=100410 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    693,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100350",
                    1,
                    0,
                    1681778701
                ],
                [
                    694,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='13' WHERE laboratorios_realizados.id_persona=100258 AND laboratorios_realizados.id_control=100411 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    695,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='94' WHERE laboratorios_realizados.id_persona=100258 AND laboratorios_realizados.id_control=100411 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    696,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100351",
                    1,
                    0,
                    1681778701
                ],
                [
                    697,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='10.3' WHERE laboratorios_realizados.id_persona=379 AND laboratorios_realizados.id_control=100412 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    698,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='107' WHERE laboratorios_realizados.id_persona=379 AND laboratorios_realizados.id_control=100412 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    699,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100352",
                    1,
                    0,
                    1681778701
                ],
                [
                    700,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=375 AND laboratorios_realizados.id_control=100413 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    701,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=375 AND laboratorios_realizados.id_control=100413 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    702,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=375 AND laboratorios_realizados.id_control=100413 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    703,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=375 AND laboratorios_realizados.id_control=100413 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    704,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='11' WHERE laboratorios_realizados.id_persona=375 AND laboratorios_realizados.id_control=100413 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    705,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='99' WHERE laboratorios_realizados.id_persona=375 AND laboratorios_realizados.id_control=100413 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    706,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100353",
                    1,
                    0,
                    1681778701
                ],
                [
                    707,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100259 AND laboratorios_realizados.id_control=100414 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    708,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100259 AND laboratorios_realizados.id_control=100414 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    709,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100259 AND laboratorios_realizados.id_control=100414 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    710,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100259 AND laboratorios_realizados.id_control=100414 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    711,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='12.3' WHERE laboratorios_realizados.id_persona=100259 AND laboratorios_realizados.id_control=100414 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    712,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='95' WHERE laboratorios_realizados.id_persona=100259 AND laboratorios_realizados.id_control=100414 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    713,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='0+' WHERE laboratorios_realizados.id_persona=100259 AND laboratorios_realizados.id_control=100414 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    714,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100356",
                    1,
                    0,
                    1681778701
                ],
                [
                    715,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100260 AND laboratorios_realizados.id_control=100415 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    716,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100260 AND laboratorios_realizados.id_control=100415 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    717,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100260 AND laboratorios_realizados.id_control=100415 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    718,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100260 AND laboratorios_realizados.id_control=100415 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    719,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='12' WHERE laboratorios_realizados.id_persona=100260 AND laboratorios_realizados.id_control=100415 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    720,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='85' WHERE laboratorios_realizados.id_persona=100260 AND laboratorios_realizados.id_control=100415 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    721,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='0+' WHERE laboratorios_realizados.id_persona=100260 AND laboratorios_realizados.id_control=100415 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    722,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100357",
                    1,
                    0,
                    1681778701
                ],
                [
                    723,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100261 AND laboratorios_realizados.id_control=100416 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    724,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100261 AND laboratorios_realizados.id_control=100416 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    725,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100261 AND laboratorios_realizados.id_control=100416 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    726,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100261 AND laboratorios_realizados.id_control=100416 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    727,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='10.6' WHERE laboratorios_realizados.id_persona=100261 AND laboratorios_realizados.id_control=100416 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    728,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='85' WHERE laboratorios_realizados.id_persona=100261 AND laboratorios_realizados.id_control=100416 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    729,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='0+' WHERE laboratorios_realizados.id_persona=100261 AND laboratorios_realizados.id_control=100416 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    730,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100358",
                    1,
                    0,
                    1681778701
                ],
                [
                    731,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='10.6' WHERE laboratorios_realizados.id_persona=368 AND laboratorios_realizados.id_control=100417 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    732,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='81' WHERE laboratorios_realizados.id_persona=368 AND laboratorios_realizados.id_control=100417 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    733,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100359",
                    1,
                    0,
                    1681778701
                ],
                [
                    734,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='11.3' WHERE laboratorios_realizados.id_persona=373 AND laboratorios_realizados.id_control=100418 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    735,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='89' WHERE laboratorios_realizados.id_persona=373 AND laboratorios_realizados.id_control=100418 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    736,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100360",
                    1,
                    0,
                    1681778701
                ],
                [
                    737,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=372 AND laboratorios_realizados.id_control=100419 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    738,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=372 AND laboratorios_realizados.id_control=100419 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    739,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=372 AND laboratorios_realizados.id_control=100419 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    740,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=372 AND laboratorios_realizados.id_control=100419 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    741,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='13' WHERE laboratorios_realizados.id_persona=372 AND laboratorios_realizados.id_control=100419 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    742,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='79' WHERE laboratorios_realizados.id_persona=372 AND laboratorios_realizados.id_control=100419 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    743,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100354",
                    1,
                    0,
                    1681778701
                ],
                [
                    744,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='11.3' WHERE laboratorios_realizados.id_persona=380 AND laboratorios_realizados.id_control=100420 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    745,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='70' WHERE laboratorios_realizados.id_persona=380 AND laboratorios_realizados.id_control=100420 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    746,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100355",
                    1,
                    0,
                    1681778701
                ],
                [
                    747,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='12' WHERE laboratorios_realizados.id_persona=369 AND laboratorios_realizados.id_control=100421 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    748,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='80' WHERE laboratorios_realizados.id_persona=369 AND laboratorios_realizados.id_control=100421 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    749,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100362",
                    1,
                    0,
                    1681778701
                ],
                [
                    750,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='10.3' WHERE laboratorios_realizados.id_persona=378 AND laboratorios_realizados.id_control=100423 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    751,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='85' WHERE laboratorios_realizados.id_persona=378 AND laboratorios_realizados.id_control=100423 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    752,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100363",
                    1,
                    0,
                    1681778701
                ],
                [
                    753,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100263 AND laboratorios_realizados.id_control=100424 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    754,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100263 AND laboratorios_realizados.id_control=100424 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    755,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100263 AND laboratorios_realizados.id_control=100424 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    756,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100263 AND laboratorios_realizados.id_control=100424 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    757,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='11' WHERE laboratorios_realizados.id_persona=100263 AND laboratorios_realizados.id_control=100424 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    758,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='70' WHERE laboratorios_realizados.id_persona=100263 AND laboratorios_realizados.id_control=100424 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    759,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='A+' WHERE laboratorios_realizados.id_persona=100263 AND laboratorios_realizados.id_control=100424 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    760,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=428",
                    1,
                    0,
                    1681778701
                ],
                [
                    761,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=371 AND laboratorios_realizados.id_control=638 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    762,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=371 AND laboratorios_realizados.id_control=638 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    763,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=371 AND laboratorios_realizados.id_control=638 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    764,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='11' WHERE laboratorios_realizados.id_persona=371 AND laboratorios_realizados.id_control=638 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    765,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='80' WHERE laboratorios_realizados.id_persona=371 AND laboratorios_realizados.id_control=638 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    766,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100364",
                    1,
                    0,
                    1681778701
                ],
                [
                    767,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=371 AND laboratorios_realizados.id_control=100425 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    768,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=371 AND laboratorios_realizados.id_control=100425 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    769,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=371 AND laboratorios_realizados.id_control=100425 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    770,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=371 AND laboratorios_realizados.id_control=100425 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    771,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='10' WHERE laboratorios_realizados.id_persona=371 AND laboratorios_realizados.id_control=100425 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    772,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='86' WHERE laboratorios_realizados.id_persona=371 AND laboratorios_realizados.id_control=100425 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    773,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100367",
                    1,
                    0,
                    1681778701
                ],
                [
                    774,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100266 AND laboratorios_realizados.id_control=100428 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    775,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100266 AND laboratorios_realizados.id_control=100428 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    776,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100266 AND laboratorios_realizados.id_control=100428 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    777,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100266 AND laboratorios_realizados.id_control=100428 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    778,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='10.3' WHERE laboratorios_realizados.id_persona=100266 AND laboratorios_realizados.id_control=100428 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    779,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='101' WHERE laboratorios_realizados.id_persona=100266 AND laboratorios_realizados.id_control=100428 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    780,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='0+' WHERE laboratorios_realizados.id_persona=100266 AND laboratorios_realizados.id_control=100428 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    781,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100368",
                    1,
                    0,
                    1681778701
                ],
                [
                    782,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100267 AND laboratorios_realizados.id_control=100429 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    783,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100267 AND laboratorios_realizados.id_control=100429 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    784,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100267 AND laboratorios_realizados.id_control=100429 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    785,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100267 AND laboratorios_realizados.id_control=100429 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    786,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='10.3' WHERE laboratorios_realizados.id_persona=100267 AND laboratorios_realizados.id_control=100429 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    787,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='108' WHERE laboratorios_realizados.id_persona=100267 AND laboratorios_realizados.id_control=100429 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    788,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='0+' WHERE laboratorios_realizados.id_persona=100267 AND laboratorios_realizados.id_control=100429 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    789,
                    "2022-09-28 10:34:04",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100384",
                    1,
                    0,
                    1681778701
                ],
                [
                    790,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='9' WHERE laboratorios_realizados.id_persona=100281 AND laboratorios_realizados.id_control=100446 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    791,
                    "2022-09-28 10:34:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='89' WHERE laboratorios_realizados.id_persona=100281 AND laboratorios_realizados.id_control=100446 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    792,
                    "2022-10-10 22:34:29",
                    "UPDATE antecedentes SET fecha_ultimo_embarazo='2022-10-04', planificado=0, fum='2022-02-20', fpp='2022-11-27' WHERE antecedentes.id_antecedente=100229",
                    1,
                    0,
                    1681778701
                ],
                [
                    793,
                    "2022-10-10 22:34:29",
                    "SELECT id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo, madre, alta, nacido_vivo FROM personas WHERE id_persona = '100236'",
                    1,
                    0,
                    1681778701
                ],
                [
                    794,
                    "2022-10-10 22:34:29",
                    "SELECT id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais FROM ubicaciones WHERE id_ubicacion = '100236'",
                    1,
                    0,
                    1681778701
                ],
                [
                    795,
                    "2022-11-28 16:20:30",
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (400252, '2022-11-23 00:00:00.000000', 26, 3, 2)",
                    1,
                    0,
                    1681778701
                ],
                [
                    796,
                    "2022-11-28 16:20:30",
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (400253, '2022-11-23 00:00:07.000000', 26, 4, 2)",
                    1,
                    0,
                    1681778701
                ],
                [
                    797,
                    "2022-12-27 09:58:31",
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (400254, '2022-12-05 11:43:54.000000', 4, 3, 2)",
                    1,
                    0,
                    1681778701
                ],
                [
                    798,
                    "2022-12-27 09:58:31",
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (400255, '2022-12-05 11:43:59.000000', 4, 4, 2)",
                    1,
                    0,
                    1681778701
                ],
                [
                    799,
                    "2022-12-27 09:58:31",
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (400256, '2022-12-05 19:23:28.000000', 5, 6, 1)",
                    1,
                    0,
                    1681778701
                ],
                [
                    800,
                    "2022-12-27 09:58:31",
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (400088, 'PRUEBA', 'PRUEBA', '333333333', '2009-11-24 00:00:00.000000', 1, 12, 'F')",
                    1,
                    0,
                    1681778701
                ],
                [
                    801,
                    "2022-12-27 09:58:31",
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, georeferencia) VALUES (400257, '2022-12-12 11:42:50.000000', 400088, 1, 1, '-34.4457216,-59.441152')",
                    1,
                    0,
                    1681778701
                ],
                [
                    802,
                    "2022-12-27 09:58:31",
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (400088, 400088, 15, 4, '12', '2022-12-12 11:42:50.000000', '-34.4457216,-59.441152', 12)",
                    1,
                    0,
                    1681778701
                ],
                [
                    803,
                    "2022-12-27 09:58:31",
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (400089, 'JACINTA', 'SUSANA', '44555888', '2022-12-20 00:00:00.000000', 2, 243, 'F')",
                    1,
                    0,
                    1681778701
                ],
                [
                    804,
                    "2022-12-27 09:58:31",
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, georeferencia) VALUES (400258, '2022-12-15 19:06:10.000000', 400089, 1, 1, '-34.2618767,-59.4750746')",
                    1,
                    0,
                    1681778701
                ],
                [
                    805,
                    "2022-12-27 09:58:31",
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (400089, 400089, 33, 3, '12', '2022-12-15 19:06:10.000000', '-34.2618767,-59.4750746', 12)",
                    1,
                    0,
                    1681778701
                ],
                [
                    806,
                    "2022-12-27 09:58:31",
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (400090, 'PRUEBA', 'PRUEBA', '22666999', '2009-11-24 00:00:00.000000', 2, 12, 'F')",
                    1,
                    0,
                    1681778701
                ],
                [
                    807,
                    "2022-12-27 09:58:31",
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, georeferencia) VALUES (400259, '2022-12-16 10:10:24.000000', 400090, 1, 1, '-34.2619016,-59.4750161')",
                    1,
                    0,
                    1681778701
                ],
                [
                    808,
                    "2022-12-27 09:58:31",
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (400090, 400090, 36, 3, '12', '2022-12-16 10:10:24.000000', '-34.2619016,-59.4750161', 12)",
                    1,
                    0,
                    1681778701
                ],
                [
                    809,
                    "2022-12-27 09:58:31",
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (400091, 'PRUEBA', 'PRUEBA', '55666333', '2009-11-25 00:00:00.000000', 1, 12, 'F')",
                    1,
                    0,
                    1681778701
                ],
                [
                    810,
                    "2022-12-27 09:58:31",
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, georeferencia) VALUES (400257, '2022-12-16 11:53:25.000000', 400091, 1, 1, '-34.2618941,-59.4749918')",
                    1,
                    0,
                    1681778701
                ],
                [
                    811,
                    "2022-12-27 09:58:31",
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (400091, 400091, 31, 3, '12', '2022-12-16 11:53:25.000000', '-34.2618941,-59.4749918', 12)",
                    1,
                    0,
                    1681778701
                ],
                [
                    812,
                    "2022-12-27 09:58:31",
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (400092, 'PRUEBA', 'PRUEBA', '22666555', '2009-01-06 00:00:00.000000', 2, 12, 'F')",
                    1,
                    0,
                    1681778701
                ],
                [
                    813,
                    "2022-12-27 09:58:31",
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, georeferencia) VALUES (400258, '2022-12-16 18:09:47.000000', 400092, 1, 1, '-34.439168,-59.4378752')",
                    1,
                    0,
                    1681778701
                ],
                [
                    814,
                    "2022-12-27 09:58:31",
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (400092, 400092, 17, 3, '12', '2022-12-16 18:09:47.000000', '-34.439168,-59.4378752', 12)",
                    1,
                    0,
                    1681778701
                ],
                [
                    815,
                    "2022-12-27 09:58:31",
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (400007, 400092, 400258, 0, 1, 1, '2022-12-06 00:00:00.000000', '2023-09-12 00:00:00.000000')",
                    1,
                    0,
                    1681778701
                ],
                [
                    816,
                    "2022-12-27 09:58:31",
                    "INSERT INTO antecedentes_apps (id_antecedente, id_app) VALUES (400007, 1)",
                    1,
                    0,
                    1681778701
                ],
                [
                    817,
                    "2022-12-27 09:58:31",
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (400007, 2)",
                    1,
                    0,
                    1681778701
                ],
                [
                    818,
                    "2022-12-27 09:58:31",
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (400093, 'IGLESIAS', 'GUSTAVO', '44555888', '2022-12-13 00:00:00.000000', 1, 12, 'F')",
                    1,
                    0,
                    1681778701
                ],
                [
                    819,
                    "2022-12-27 09:58:31",
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, georeferencia) VALUES (400259, '2022-12-19 10:17:33.000000', 400093, 1, 1, '-34.2555428,-59.4625749')",
                    1,
                    0,
                    1681778701
                ],
                [
                    820,
                    "2022-12-27 09:58:31",
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (400093, 400093, 29, 3, '12', '2022-12-19 10:17:33.000000', '-34.2555428,-59.4625749', 12)",
                    1,
                    0,
                    1681778701
                ],
                [
                    821,
                    "2022-12-27 09:58:31",
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (400008, 400093, 400259, 0, 1, 0, '2022-12-02 00:00:00.000000', '2023-09-08 00:00:00.000000')",
                    1,
                    0,
                    1681778701
                ],
                [
                    822,
                    "2022-12-27 09:58:31",
                    "INSERT INTO antecedentes_apps (id_antecedente, id_app) VALUES (400008, 8)",
                    1,
                    0,
                    1681778701
                ],
                [
                    823,
                    "2022-12-27 09:58:31",
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (400008, 4)",
                    1,
                    0,
                    1681778701
                ],
                [
                    824,
                    "2022-12-27 09:58:31",
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (400093, 400259, 2, 'S')",
                    1,
                    0,
                    1681778701
                ],
                [
                    825,
                    "2022-12-27 09:58:31",
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (400093, 400259, 3, 'P')",
                    1,
                    0,
                    1681778701
                ],
                [
                    826,
                    "2022-12-27 09:58:31",
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (400093, 400259, 1, 'S')",
                    1,
                    0,
                    1681778701
                ],
                [
                    827,
                    "2022-12-27 09:58:31",
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (400093, 400259, 4, 'P')",
                    1,
                    0,
                    1681778701
                ],
                [
                    828,
                    "2022-12-27 09:58:31",
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (400093, 400259, 19, 1, '2022-12-19 10:21:35.000000', 1)",
                    1,
                    0,
                    1681778701
                ],
                [
                    829,
                    "2022-12-27 09:58:31",
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (400093, 400259, 20, 1, '2022-12-19 10:21:35.000000', 1)",
                    1,
                    0,
                    1681778701
                ],
                [
                    830,
                    "2022-12-27 09:58:31",
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (400093, 400259, 23, 1, '2022-12-19 10:21:35.000000', 1)",
                    1,
                    0,
                    1681778701
                ],
                [
                    831,
                    "2022-12-27 09:58:31",
                    "INSERT INTO etmis_personas (id_persona, id_etmi, id_control) VALUES (400093, 1, 400259)",
                    1,
                    0,
                    1681778701
                ],
                [
                    832,
                    "2022-12-27 09:58:31",
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (400093, 400259, 4, 1, '2022-12-19 10:21:35.000000', '2022-12-19 10:21:35.000000', 'P', 1)",
                    1,
                    0,
                    1681778701
                ],
                [
                    833,
                    "2022-12-27 09:58:31",
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (400071, 400259, 2, 'R', ' ', 'N', 'N', 120, 90, 'N', '')",
                    1,
                    0,
                    1681778701
                ],
                [
                    834,
                    "2022-12-27 09:58:31",
                    "DELETE FROM etmis_personas WHERE etmis_personas.id_persona=400093 AND etmis_personas.id_etmi=1 AND etmis_personas.id_control=400259",
                    1,
                    0,
                    1681778701
                ],
                [
                    835,
                    "2022-12-27 09:58:31",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-12-19', resultado='P' WHERE laboratorios_realizados.id_persona=400093 AND laboratorios_realizados.id_control=400259 AND laboratorios_realizados.id_laboratorio=19 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    836,
                    "2022-12-27 09:58:31",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-12-19', resultado='P' WHERE laboratorios_realizados.id_persona=400093 AND laboratorios_realizados.id_control=400259 AND laboratorios_realizados.id_laboratorio=20 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    837,
                    "2022-12-27 09:58:31",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-12-19', resultado='P' WHERE laboratorios_realizados.id_persona=400093 AND laboratorios_realizados.id_control=400259 AND laboratorios_realizados.id_laboratorio=23 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    838,
                    "2022-12-27 09:58:31",
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (400260, '2022-12-19 10:29:21.000000', 38, 6, 2)",
                    1,
                    0,
                    1681778701
                ],
                [
                    839,
                    "2022-12-27 09:58:31",
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (400261, '2022-12-19 10:29:26.000000', 38, 7, 2)",
                    1,
                    0,
                    1681778701
                ],
                [
                    840,
                    "2023-01-04 11:13:55",
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=535",
                    1,
                    0,
                    1681778701
                ],
                [
                    841,
                    "2023-01-04 11:13:55",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-04', resultado='N' WHERE laboratorios_realizados.id_persona=438 AND laboratorios_realizados.id_control=755 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    1,
                    0,
                    1681778701
                ],
                [
                    842,
                    "2023-01-04 11:13:55",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-04', resultado='N' WHERE laboratorios_realizados.id_persona=438 AND laboratorios_realizados.id_control=755 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    1,
                    0,
                    1681778701
                ],
                [
                    843,
                    "2023-01-04 11:13:55",
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (438, 755, 19, 2, '2023-01-04 10:55:56.000000', 1)",
                    1,
                    0,
                    1681778701
                ],
                [
                    844,
                    "2023-01-04 11:13:55",
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (438, 755, 20, 2, '2023-01-04 10:55:56.000000', 1)",
                    1,
                    0,
                    1681778701
                ],
                [
                    845,
                    "2023-01-04 11:13:55",
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (438, 755, 23, 2, '2023-01-04 10:55:56.000000', 1)",
                    1,
                    0,
                    1681778701
                ],
                [
                    846,
                    "2023-01-04 11:13:55",
                    "INSERT INTO etmis_personas (id_persona, id_etmi, id_control) VALUES (438, 1, 755)",
                    1,
                    0,
                    1681778701
                ],
                [
                    847,
                    "2023-01-04 11:13:55",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-04', resultado='P' WHERE laboratorios_realizados.id_persona=438 AND laboratorios_realizados.id_control=755 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    848,
                    "2023-01-04 11:13:55",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-04', resultado='N' WHERE laboratorios_realizados.id_persona=438 AND laboratorios_realizados.id_control=755 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    1,
                    0,
                    1681778701
                ],
                [
                    849,
                    "2023-01-04 11:13:55",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-04', resultado='0+' WHERE laboratorios_realizados.id_persona=438 AND laboratorios_realizados.id_control=755 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    1,
                    0,
                    1681778701
                ],
                [
                    850,
                    "2023-01-04 11:13:55",
                    "DELETE FROM etmis_personas WHERE etmis_personas.id_persona=438 AND etmis_personas.id_etmi=1 AND etmis_personas.id_control=755",
                    1,
                    0,
                    1681778701
                ],
                [
                    851,
                    "2023-01-04 11:13:55",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-04', resultado='P' WHERE laboratorios_realizados.id_persona=438 AND laboratorios_realizados.id_control=755 AND laboratorios_realizados.id_laboratorio=19 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    852,
                    "2023-01-04 11:13:55",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-04', resultado='P' WHERE laboratorios_realizados.id_persona=438 AND laboratorios_realizados.id_control=755 AND laboratorios_realizados.id_laboratorio=20 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    853,
                    "2023-01-04 11:13:55",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-04', resultado='P' WHERE laboratorios_realizados.id_persona=438 AND laboratorios_realizados.id_control=755 AND laboratorios_realizados.id_laboratorio=23 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    1,
                    0,
                    1681778701
                ],
                [
                    854,
                    "2023-01-04 11:13:55",
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (400262, '2023-01-04 10:59:31.000000', 100236, 2, 1)",
                    1,
                    0,
                    1681778701
                ],
                [
                    855,
                    "2023-03-02 11:17:31",
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (400088, 'IGLESIAS', 'GUSTAVA', '22666999', '2006-02-01 00:00:00.000000', 1, 12, 'F')",
                    1,
                    0,
                    1681778701
                ],
                [
                    856,
                    "2023-03-02 11:17:31",
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, georeferencia) VALUES (400263, '2023-01-25 08:38:24.000000', 400088, 1, 1, '-34.2428212,-59.4742354')",
                    1,
                    0,
                    1681778701
                ],
                [
                    857,
                    "2023-03-02 11:17:31",
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (400088, 400088, 24, 3, '12', '2023-01-25 08:38:24.000000', '-34.2428212,-59.4742354', 12)",
                    1,
                    0,
                    1681778701
                ],
                [
                    858,
                    "2023-03-02 11:17:31",
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, planificado, fum, fpp) VALUES (400007, 400088, 400263, 23, '2022-04-27 00:00:00.000000', 1, 1, '2023-01-04 00:00:00.000000', '2023-10-11 00:00:00.000000')",
                    1,
                    0,
                    1681778701
                ],
                [
                    859,
                    "2023-03-02 11:17:31",
                    "INSERT INTO antecedentes_apps (id_antecedente, id_app) VALUES (400007, 2)",
                    1,
                    0,
                    1681778701
                ],
                [
                    860,
                    "2023-03-02 11:17:31",
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (400007, 1)",
                    1,
                    0,
                    1681778701
                ],
                [
                    861,
                    "2023-03-02 11:17:31",
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (400089, 'MOLINA', 'JUANA', '44555888', '2000-01-01 00:00:00.000000', 2, 12, 'F')",
                    1,
                    0,
                    1681778701
                ],
                [
                    862,
                    "2023-03-02 11:17:31",
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, georeferencia) VALUES (400264, '2023-01-31 18:24:11.000000', 400089, 1, 1, '-34.439168,-59.4444288')",
                    1,
                    0,
                    1681778701
                ],
                [
                    863,
                    "2023-03-02 11:17:31",
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (400089, 400089, 25, 3, '00', '2023-01-31 18:24:11.000000', '-34.439168,-59.4444288', 12)",
                    1,
                    0,
                    1681778701
                ],
                [
                    864,
                    "2023-03-02 11:17:31",
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (400008, 400089, 400264, 0, 1, 0, '2022-12-05 00:00:00.000000', '2023-09-11 00:00:00.000000')",
                    1,
                    0,
                    1681778701
                ],
                [
                    865,
                    "2023-03-02 11:17:31",
                    "INSERT INTO antecedentes_apps (id_antecedente, id_app) VALUES (400008, 7)",
                    1,
                    0,
                    1681778701
                ],
                [
                    866,
                    "2023-03-02 11:17:31",
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (400089, 400264, 2, 'N')",
                    1,
                    0,
                    1681778701
                ],
                [
                    867,
                    "2023-03-02 11:17:31",
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (400089, 400264, 3, 'N')",
                    1,
                    0,
                    1681778701
                ],
                [
                    868,
                    "2023-03-02 11:17:31",
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (400089, 400264, 1, 'N')",
                    1,
                    0,
                    1681778701
                ],
                [
                    869,
                    "2023-03-02 11:17:31",
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (400089, 400264, 4, 'N')",
                    1,
                    0,
                    1681778701
                ],
                [
                    870,
                    "2023-03-02 11:17:31",
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (400071, 400264, 8, 'P', 'PRUEBA', 'N', 'N', 130, 60, 'N', '')",
                    1,
                    0,
                    1681778701
                ],
                [
                    871,
                    "2023-03-02 11:17:31",
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (400265, '2023-01-31 18:36:29.000000', 400088, 2, 1)",
                    1,
                    0,
                    1681778701
                ],
                [
                    872,
                    "2023-03-02 11:17:31",
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (400266, '2023-02-01 15:12:51.000000', 4, 4, 2)",
                    1,
                    0,
                    1681778701
                ],
                [
                    873,
                    "2023-03-02 11:17:31",
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (400267, '2023-02-01 15:12:55.000000', 4, 5, 2)",
                    1,
                    0,
                    1681778701
                ],
                [
                    874,
                    "2023-03-02 11:17:31",
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (400268, '2023-02-01 20:30:28.000000', 18, 2, 1)",
                    1,
                    0,
                    1681778701
                ],
                [
                    875,
                    "2023-03-02 11:17:31",
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (400269, '2023-02-02 09:19:13.000000', 18, 3, 1)",
                    1,
                    0,
                    1681778701
                ],
                [
                    876,
                    "2023-03-02 11:17:31",
                    "UPDATE antecedentes SET fpp='2021-08-22' WHERE antecedentes.id_antecedente=11",
                    1,
                    0,
                    1681778701
                ],
                [
                    877,
                    "2023-03-09 10:03:43",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='15' WHERE laboratorios_realizados.id_persona=462 AND laboratorios_realizados.id_control=100598 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    878,
                    "2023-03-09 10:03:43",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='97' WHERE laboratorios_realizados.id_persona=462 AND laboratorios_realizados.id_control=100598 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    879,
                    "2023-03-09 10:11:02",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100303 AND laboratorios_realizados.id_control=100597 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    0,
                    0,
                    1681778701
                ],
                [
                    880,
                    "2023-03-09 10:11:02",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100303 AND laboratorios_realizados.id_control=100597 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    0,
                    0,
                    1681778701
                ],
                [
                    881,
                    "2023-03-09 10:11:02",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100303 AND laboratorios_realizados.id_control=100597 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    0,
                    0,
                    1681778701
                ],
                [
                    882,
                    "2023-03-09 10:11:02",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100303 AND laboratorios_realizados.id_control=100597 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    0,
                    0,
                    1681778701
                ],
                [
                    883,
                    "2023-03-09 10:11:02",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='105' WHERE laboratorios_realizados.id_persona=100303 AND laboratorios_realizados.id_control=100597 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    884,
                    "2023-03-09 10:13:56",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=375 AND laboratorios_realizados.id_control=100572 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    0,
                    0,
                    1681778701
                ],
                [
                    885,
                    "2023-03-09 10:13:56",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=375 AND laboratorios_realizados.id_control=100572 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    0,
                    0,
                    1681778701
                ],
                [
                    886,
                    "2023-03-09 10:13:56",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=375 AND laboratorios_realizados.id_control=100572 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    0,
                    0,
                    1681778701
                ],
                [
                    887,
                    "2023-03-09 10:13:56",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='12' WHERE laboratorios_realizados.id_persona=375 AND laboratorios_realizados.id_control=100572 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    888,
                    "2023-03-09 10:13:56",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='125' WHERE laboratorios_realizados.id_persona=375 AND laboratorios_realizados.id_control=100572 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    889,
                    "2023-03-09 10:13:56",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='0+' WHERE laboratorios_realizados.id_persona=375 AND laboratorios_realizados.id_control=100572 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    890,
                    "2023-03-09 10:16:56",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100260 AND laboratorios_realizados.id_control=100571 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    0,
                    0,
                    1681778701
                ],
                [
                    891,
                    "2023-03-09 10:16:56",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100260 AND laboratorios_realizados.id_control=100571 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    0,
                    0,
                    1681778701
                ],
                [
                    892,
                    "2023-03-09 10:16:56",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100260 AND laboratorios_realizados.id_control=100571 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    0,
                    0,
                    1681778701
                ],
                [
                    893,
                    "2023-03-09 10:16:56",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100260 AND laboratorios_realizados.id_control=100571 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    0,
                    0,
                    1681778701
                ],
                [
                    894,
                    "2023-03-09 10:16:56",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='10' WHERE laboratorios_realizados.id_persona=100260 AND laboratorios_realizados.id_control=100571 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    895,
                    "2023-03-09 10:16:56",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='103' WHERE laboratorios_realizados.id_persona=100260 AND laboratorios_realizados.id_control=100571 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    896,
                    "2023-03-09 10:21:23",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100305 AND laboratorios_realizados.id_control=100570 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    0,
                    0,
                    1681778701
                ],
                [
                    897,
                    "2023-03-09 10:21:23",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100305 AND laboratorios_realizados.id_control=100570 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    0,
                    0,
                    1681778701
                ],
                [
                    898,
                    "2023-03-09 10:21:23",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100305 AND laboratorios_realizados.id_control=100570 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    0,
                    0,
                    1681778701
                ],
                [
                    899,
                    "2023-03-09 10:21:23",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100305 AND laboratorios_realizados.id_control=100570 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    0,
                    0,
                    1681778701
                ],
                [
                    900,
                    "2023-03-09 10:21:23",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='9' WHERE laboratorios_realizados.id_persona=100305 AND laboratorios_realizados.id_control=100570 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    901,
                    "2023-03-09 10:21:23",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='95' WHERE laboratorios_realizados.id_persona=100305 AND laboratorios_realizados.id_control=100570 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    902,
                    "2023-03-09 10:21:23",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='0+' WHERE laboratorios_realizados.id_persona=100305 AND laboratorios_realizados.id_control=100570 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    903,
                    "2023-03-09 10:23:23",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100306 AND laboratorios_realizados.id_control=100576 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    0,
                    0,
                    1681778701
                ],
                [
                    904,
                    "2023-03-09 10:23:23",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100306 AND laboratorios_realizados.id_control=100576 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    0,
                    0,
                    1681778701
                ],
                [
                    905,
                    "2023-03-09 10:23:23",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100306 AND laboratorios_realizados.id_control=100576 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    0,
                    0,
                    1681778701
                ],
                [
                    906,
                    "2023-03-09 10:23:23",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='10' WHERE laboratorios_realizados.id_persona=100306 AND laboratorios_realizados.id_control=100576 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    907,
                    "2023-03-09 10:23:23",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='73' WHERE laboratorios_realizados.id_persona=100306 AND laboratorios_realizados.id_control=100576 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    908,
                    "2023-03-09 10:27:21",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=436 AND laboratorios_realizados.id_control=100539 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    0,
                    0,
                    1681778701
                ],
                [
                    909,
                    "2023-03-09 10:27:21",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=436 AND laboratorios_realizados.id_control=100539 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    0,
                    0,
                    1681778701
                ],
                [
                    910,
                    "2023-03-09 10:27:21",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=436 AND laboratorios_realizados.id_control=100539 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    0,
                    0,
                    1681778701
                ],
                [
                    911,
                    "2023-03-09 10:27:21",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='12' WHERE laboratorios_realizados.id_persona=436 AND laboratorios_realizados.id_control=100539 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    912,
                    "2023-03-09 10:27:21",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='0+' WHERE laboratorios_realizados.id_persona=436 AND laboratorios_realizados.id_control=100539 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    913,
                    "2023-03-09 10:33:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=440 AND laboratorios_realizados.id_control=100546 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    0,
                    0,
                    1681778701
                ],
                [
                    914,
                    "2023-03-09 10:33:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=440 AND laboratorios_realizados.id_control=100546 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    0,
                    0,
                    1681778701
                ],
                [
                    915,
                    "2023-03-09 10:33:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=440 AND laboratorios_realizados.id_control=100546 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    0,
                    0,
                    1681778701
                ],
                [
                    916,
                    "2023-03-09 10:33:04",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='13' WHERE laboratorios_realizados.id_persona=440 AND laboratorios_realizados.id_control=100546 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    917,
                    "2023-03-09 10:34:33",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=441 AND laboratorios_realizados.id_control=100547 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    0,
                    0,
                    1681778701
                ],
                [
                    918,
                    "2023-03-09 10:34:33",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=441 AND laboratorios_realizados.id_control=100547 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    0,
                    0,
                    1681778701
                ],
                [
                    919,
                    "2023-03-09 10:34:33",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=441 AND laboratorios_realizados.id_control=100547 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    0,
                    0,
                    1681778701
                ],
                [
                    920,
                    "2023-03-09 10:34:33",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='13' WHERE laboratorios_realizados.id_persona=441 AND laboratorios_realizados.id_control=100547 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    921,
                    "2023-03-09 10:36:09",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=443 AND laboratorios_realizados.id_control=100549 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    0,
                    0,
                    1681778701
                ],
                [
                    922,
                    "2023-03-09 10:36:09",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=443 AND laboratorios_realizados.id_control=100549 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    0,
                    0,
                    1681778701
                ],
                [
                    923,
                    "2023-03-09 10:36:09",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=443 AND laboratorios_realizados.id_control=100549 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    0,
                    0,
                    1681778701
                ],
                [
                    924,
                    "2023-03-09 10:36:09",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='12' WHERE laboratorios_realizados.id_persona=443 AND laboratorios_realizados.id_control=100549 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    925,
                    "2023-03-09 10:38:12",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100209 AND laboratorios_realizados.id_control=100531 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    0,
                    0,
                    1681778701
                ],
                [
                    926,
                    "2023-03-09 10:38:12",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100209 AND laboratorios_realizados.id_control=100531 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    0,
                    0,
                    1681778701
                ],
                [
                    927,
                    "2023-03-09 10:38:12",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100209 AND laboratorios_realizados.id_control=100531 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    0,
                    0,
                    1681778701
                ],
                [
                    928,
                    "2023-03-09 10:38:12",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100209 AND laboratorios_realizados.id_control=100531 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    0,
                    0,
                    1681778701
                ],
                [
                    929,
                    "2023-03-09 10:38:12",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='11' WHERE laboratorios_realizados.id_persona=100209 AND laboratorios_realizados.id_control=100531 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    930,
                    "2023-03-09 10:38:12",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='0+' WHERE laboratorios_realizados.id_persona=100209 AND laboratorios_realizados.id_control=100531 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    931,
                    "2023-03-09 10:54:52",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100221 AND laboratorios_realizados.id_control=100544 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    0,
                    0,
                    1681778701
                ],
                [
                    932,
                    "2023-03-09 10:54:52",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100221 AND laboratorios_realizados.id_control=100544 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    0,
                    0,
                    1681778701
                ],
                [
                    933,
                    "2023-03-09 10:54:52",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100221 AND laboratorios_realizados.id_control=100544 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    0,
                    0,
                    1681778701
                ],
                [
                    934,
                    "2023-03-09 10:54:52",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='13' WHERE laboratorios_realizados.id_persona=100221 AND laboratorios_realizados.id_control=100544 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    935,
                    "2023-03-09 10:56:16",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100223 AND laboratorios_realizados.id_control=100530 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    0,
                    0,
                    1681778701
                ],
                [
                    936,
                    "2023-03-09 10:56:16",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100223 AND laboratorios_realizados.id_control=100530 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    0,
                    0,
                    1681778701
                ],
                [
                    937,
                    "2023-03-09 10:56:16",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100223 AND laboratorios_realizados.id_control=100530 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    0,
                    0,
                    1681778701
                ],
                [
                    938,
                    "2023-03-09 10:56:16",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100223 AND laboratorios_realizados.id_control=100530 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    0,
                    0,
                    1681778701
                ],
                [
                    939,
                    "2023-03-09 10:56:16",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='13' WHERE laboratorios_realizados.id_persona=100223 AND laboratorios_realizados.id_control=100530 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    940,
                    "2023-03-09 10:56:16",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='96' WHERE laboratorios_realizados.id_persona=100223 AND laboratorios_realizados.id_control=100530 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    941,
                    "2023-03-09 10:56:16",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='0+' WHERE laboratorios_realizados.id_persona=100223 AND laboratorios_realizados.id_control=100530 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    942,
                    "2023-03-09 10:57:31",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100225 AND laboratorios_realizados.id_control=100535 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    0,
                    0,
                    1681778701
                ],
                [
                    943,
                    "2023-03-09 10:57:31",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100225 AND laboratorios_realizados.id_control=100535 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    0,
                    0,
                    1681778701
                ],
                [
                    944,
                    "2023-03-09 10:57:31",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='13' WHERE laboratorios_realizados.id_persona=100225 AND laboratorios_realizados.id_control=100535 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    945,
                    "2023-03-09 10:59:22",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100228 AND laboratorios_realizados.id_control=100553 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    0,
                    0,
                    1681778701
                ],
                [
                    946,
                    "2023-03-09 10:59:22",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100228 AND laboratorios_realizados.id_control=100553 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    0,
                    0,
                    1681778701
                ],
                [
                    947,
                    "2023-03-09 10:59:22",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100228 AND laboratorios_realizados.id_control=100553 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    0,
                    0,
                    1681778701
                ],
                [
                    948,
                    "2023-03-09 10:59:22",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='12' WHERE laboratorios_realizados.id_persona=100228 AND laboratorios_realizados.id_control=100553 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    949,
                    "2023-03-09 11:00:25",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100229 AND laboratorios_realizados.id_control=100536 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    0,
                    0,
                    1681778701
                ],
                [
                    950,
                    "2023-03-09 11:00:25",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100229 AND laboratorios_realizados.id_control=100536 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    0,
                    0,
                    1681778701
                ],
                [
                    951,
                    "2023-03-09 11:00:25",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100229 AND laboratorios_realizados.id_control=100536 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    0,
                    0,
                    1681778701
                ],
                [
                    952,
                    "2023-03-09 11:00:25",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='14' WHERE laboratorios_realizados.id_persona=100229 AND laboratorios_realizados.id_control=100536 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    953,
                    "2023-03-09 11:02:40",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100230 AND laboratorios_realizados.id_control=100538 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    0,
                    0,
                    1681778701
                ],
                [
                    954,
                    "2023-03-09 11:02:40",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100230 AND laboratorios_realizados.id_control=100538 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    0,
                    0,
                    1681778701
                ],
                [
                    955,
                    "2023-03-09 11:02:40",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='36' WHERE laboratorios_realizados.id_persona=100230 AND laboratorios_realizados.id_control=100538 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    956,
                    "2023-03-09 11:02:40",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='0+' WHERE laboratorios_realizados.id_persona=100230 AND laboratorios_realizados.id_control=100538 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    957,
                    "2023-03-09 11:05:24",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100235 AND laboratorios_realizados.id_control=100542 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    0,
                    0,
                    1681778701
                ],
                [
                    958,
                    "2023-03-09 11:05:24",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100235 AND laboratorios_realizados.id_control=100542 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    0,
                    0,
                    1681778701
                ],
                [
                    959,
                    "2023-03-09 11:05:24",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100235 AND laboratorios_realizados.id_control=100542 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    0,
                    0,
                    1681778701
                ],
                [
                    960,
                    "2023-03-09 11:05:24",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='10' WHERE laboratorios_realizados.id_persona=100235 AND laboratorios_realizados.id_control=100542 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    961,
                    "2023-03-09 11:12:28",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=390 AND laboratorios_realizados.id_control=100523 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    0,
                    0,
                    1681778701
                ],
                [
                    962,
                    "2023-03-09 11:12:28",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=390 AND laboratorios_realizados.id_control=100523 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    0,
                    0,
                    1681778701
                ],
                [
                    963,
                    "2023-03-09 11:12:28",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=390 AND laboratorios_realizados.id_control=100523 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    0,
                    0,
                    1681778701
                ],
                [
                    964,
                    "2023-03-09 11:12:28",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=390 AND laboratorios_realizados.id_control=100523 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    0,
                    0,
                    1681778701
                ],
                [
                    965,
                    "2023-03-09 11:12:28",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='10' WHERE laboratorios_realizados.id_persona=390 AND laboratorios_realizados.id_control=100523 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    966,
                    "2023-03-09 11:12:28",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='89' WHERE laboratorios_realizados.id_persona=390 AND laboratorios_realizados.id_control=100523 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    967,
                    "2023-03-09 11:12:28",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='0+' WHERE laboratorios_realizados.id_persona=390 AND laboratorios_realizados.id_control=100523 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    968,
                    "2023-03-09 11:14:34",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=392 AND laboratorios_realizados.id_control=100507 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    0,
                    0,
                    1681778701
                ],
                [
                    969,
                    "2023-03-09 11:14:34",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=392 AND laboratorios_realizados.id_control=100507 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    0,
                    0,
                    1681778701
                ],
                [
                    970,
                    "2023-03-09 11:14:34",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=392 AND laboratorios_realizados.id_control=100507 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    0,
                    0,
                    1681778701
                ],
                [
                    971,
                    "2023-03-09 11:14:34",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='12' WHERE laboratorios_realizados.id_persona=392 AND laboratorios_realizados.id_control=100507 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    972,
                    "2023-03-09 11:14:34",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='82' WHERE laboratorios_realizados.id_persona=392 AND laboratorios_realizados.id_control=100507 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    973,
                    "2023-03-09 11:16:29",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=398 AND laboratorios_realizados.id_control=100518 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    0,
                    0,
                    1681778701
                ],
                [
                    974,
                    "2023-03-09 11:16:29",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=398 AND laboratorios_realizados.id_control=100518 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    0,
                    0,
                    1681778701
                ],
                [
                    975,
                    "2023-03-09 11:16:29",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=398 AND laboratorios_realizados.id_control=100518 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    0,
                    0,
                    1681778701
                ],
                [
                    976,
                    "2023-03-09 11:16:29",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=398 AND laboratorios_realizados.id_control=100518 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    0,
                    0,
                    1681778701
                ],
                [
                    977,
                    "2023-03-09 11:16:29",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='10' WHERE laboratorios_realizados.id_persona=398 AND laboratorios_realizados.id_control=100518 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    978,
                    "2023-03-09 11:16:29",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='89' WHERE laboratorios_realizados.id_persona=398 AND laboratorios_realizados.id_control=100518 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    979,
                    "2023-03-09 11:17:48",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='10' WHERE laboratorios_realizados.id_persona=416 AND laboratorios_realizados.id_control=100519 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    980,
                    "2023-03-09 11:17:48",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='89' WHERE laboratorios_realizados.id_persona=416 AND laboratorios_realizados.id_control=100519 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    981,
                    "2023-03-09 11:19:08",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=417 AND laboratorios_realizados.id_control=100517 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    0,
                    0,
                    1681778701
                ],
                [
                    982,
                    "2023-03-09 11:19:08",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=417 AND laboratorios_realizados.id_control=100517 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    0,
                    0,
                    1681778701
                ],
                [
                    983,
                    "2023-03-09 11:19:08",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=417 AND laboratorios_realizados.id_control=100517 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    0,
                    0,
                    1681778701
                ],
                [
                    984,
                    "2023-03-09 11:19:08",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='12' WHERE laboratorios_realizados.id_persona=417 AND laboratorios_realizados.id_control=100517 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    985,
                    "2023-03-09 11:19:08",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='105' WHERE laboratorios_realizados.id_persona=417 AND laboratorios_realizados.id_control=100517 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    986,
                    "2023-03-09 11:20:34",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=418 AND laboratorios_realizados.id_control=100506 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    0,
                    0,
                    1681778701
                ],
                [
                    987,
                    "2023-03-09 11:20:34",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=418 AND laboratorios_realizados.id_control=100506 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    0,
                    0,
                    1681778701
                ],
                [
                    988,
                    "2023-03-09 11:20:34",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=418 AND laboratorios_realizados.id_control=100506 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    0,
                    0,
                    1681778701
                ],
                [
                    989,
                    "2023-03-09 11:20:34",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='12' WHERE laboratorios_realizados.id_persona=418 AND laboratorios_realizados.id_control=100506 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    990,
                    "2023-03-09 11:20:34",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='81' WHERE laboratorios_realizados.id_persona=418 AND laboratorios_realizados.id_control=100506 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    991,
                    "2023-03-09 11:23:14",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=434 AND laboratorios_realizados.id_control=200166 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    0,
                    0,
                    1681778701
                ],
                [
                    992,
                    "2023-03-09 11:23:14",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=434 AND laboratorios_realizados.id_control=200166 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    0,
                    0,
                    1681778701
                ],
                [
                    993,
                    "2023-03-09 11:23:14",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=434 AND laboratorios_realizados.id_control=200166 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    0,
                    0,
                    1681778701
                ],
                [
                    994,
                    "2023-03-09 11:23:14",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=434 AND laboratorios_realizados.id_control=200166 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    0,
                    0,
                    1681778701
                ],
                [
                    995,
                    "2023-03-09 11:23:14",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='11' WHERE laboratorios_realizados.id_persona=434 AND laboratorios_realizados.id_control=200166 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    996,
                    "2023-03-09 11:23:14",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='101' WHERE laboratorios_realizados.id_persona=434 AND laboratorios_realizados.id_control=200166 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    997,
                    "2023-03-09 11:23:14",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='0+' WHERE laboratorios_realizados.id_persona=434 AND laboratorios_realizados.id_control=200166 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    998,
                    "2023-03-09 11:26:59",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100193 AND laboratorios_realizados.id_control=200167 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    0,
                    0,
                    1681778701
                ],
                [
                    999,
                    "2023-03-09 11:26:59",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100193 AND laboratorios_realizados.id_control=200167 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    0,
                    0,
                    1681778701
                ],
                [
                    1000,
                    "2023-03-09 11:26:59",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100193 AND laboratorios_realizados.id_control=200167 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    0,
                    0,
                    1681778701
                ],
                [
                    1001,
                    "2023-03-09 11:26:59",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100193 AND laboratorios_realizados.id_control=200167 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    0,
                    0,
                    1681778701
                ],
                [
                    1002,
                    "2023-03-09 11:26:59",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='13' WHERE laboratorios_realizados.id_persona=100193 AND laboratorios_realizados.id_control=200167 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    1003,
                    "2023-03-09 11:26:59",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='107' WHERE laboratorios_realizados.id_persona=100193 AND laboratorios_realizados.id_control=200167 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    1004,
                    "2023-03-09 11:26:59",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='0+' WHERE laboratorios_realizados.id_persona=100193 AND laboratorios_realizados.id_control=200167 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    1005,
                    "2023-03-09 11:31:14",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100203 AND laboratorios_realizados.id_control=100529 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    0,
                    0,
                    1681778701
                ],
                [
                    1006,
                    "2023-03-09 11:31:14",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100203 AND laboratorios_realizados.id_control=100529 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    0,
                    0,
                    1681778701
                ],
                [
                    1007,
                    "2023-03-09 11:31:14",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=100203 AND laboratorios_realizados.id_control=100529 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    0,
                    0,
                    1681778701
                ],
                [
                    1008,
                    "2023-03-09 11:31:14",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='11' WHERE laboratorios_realizados.id_persona=100203 AND laboratorios_realizados.id_control=100529 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    1009,
                    "2023-03-09 11:34:01",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='13' WHERE laboratorios_realizados.id_persona=100205 AND laboratorios_realizados.id_control=300088 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ],
                [
                    1010,
                    "2023-03-09 11:38:48",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=300025 AND laboratorios_realizados.id_control=100526 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    0,
                    0,
                    1681778701
                ],
                [
                    1011,
                    "2023-03-09 11:38:48",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=300025 AND laboratorios_realizados.id_control=100526 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    0,
                    0,
                    1681778701
                ],
                [
                    1012,
                    "2023-03-09 11:38:48",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=300025 AND laboratorios_realizados.id_control=100526 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    0,
                    0,
                    1681778701
                ],
                [
                    1013,
                    "2023-03-09 11:38:48",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='N' WHERE laboratorios_realizados.id_persona=300025 AND laboratorios_realizados.id_control=100526 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    0,
                    0,
                    1681778701
                ],
                [
                    1014,
                    "2023-03-09 11:38:48",
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-03-09', resultado='10' WHERE laboratorios_realizados.id_persona=300025 AND laboratorios_realizados.id_control=100526 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    0,
                    0,
                    1681778701
                ]
            ]
        },
        {
            "name": "tratamiento_chagas",
            "schema": [
                {
                    "column": "id_tratamiento_chagas",
                    "value": "INT PRIMARY KEY NOT NULL"
                },
                {
                    "column": "droga",
                    "value": "VARCHAR(45)  NULL DEFAULT NULL COLLATE NOCASE"
                },
                {
                    "column": "dosis_diaria",
                    "value": "VARCHAR(45)  NULL DEFAULT NULL COLLATE NOCASE"
                },
                {
                    "column": "fecha_inicio",
                    "value": "DATE  NULL DEFAULT NULL"
                },
                {
                    "column": "peso_inicial",
                    "value": "FLOAT(0,0)  NULL DEFAULT NULL"
                },
                {
                    "column": "fecha_finalizacion",
                    "value": "DATE  NULL DEFAULT NULL"
                },
                {
                    "column": "peso_final",
                    "value": "FLOAT(0,0)  NULL DEFAULT NULL"
                },
                {
                    "column": "id_motivo_finalizacion",
                    "value": "INT  NULL DEFAULT NULL"
                },
                {
                    "column": "otros_eventos_adversos",
                    "value": "TEXT  NULL  COLLATE NOCASE"
                },
                {
                    "column": "observaciones",
                    "value": "TEXT  NULL  COLLATE NOCASE"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                }
            ],
            "indexes": [
                {
                    "name": "id_motivo_finalizacion",
                    "value": "`id_motivo_finalizacion` DESC"
                }
            ]
        },
        {
            "name": "etmis",
            "schema": [
                {
                    "column": "id_etmi",
                    "value": "INT PRIMARY KEY NOT NULL"
                },
                {
                    "column": "nombre",
                    "value": "VARCHAR(45)  NULL DEFAULT NULL COLLATE NOCASE"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                }
            ],
            "indexes": [
                {
                    "name": "nombre_etmis_UNIQUE",
                    "value": "`nombre` DESC",
                    "mode": "UNIQUE"
                }
            ],
            "triggers": [
                {
                    "name": "etmis_trigger_last_modified",
                    "logic": "BEGIN      UPDATE etmis SET last_modified= (strftime('%s', 'now')) WHERE id_etmi=OLD.id_etmi;  END",
                    "condition": "FOR EACH ROW WHEN NEW.last_modified < OLD.last_modified",
                    "timeevent": "AFTER UPDATE ON"
                }
            ],
            "values": [
                [
                    1,
                    "CHAGAS",
                    0,
                    1681778745
                ],
                [
                    2,
                    "HIV",
                    0,
                    1681778745
                ],
                [
                    3,
                    "SIFILIS",
                    0,
                    1681778745
                ],
                [
                    4,
                    "VHB",
                    0,
                    1681778745
                ]
            ]
        },
        {
            "name": "inmunizaciones",
            "schema": [
                {
                    "column": "id_inmunizacion",
                    "value": "INT PRIMARY KEY NOT NULL"
                },
                {
                    "column": "nombre",
                    "value": "VARCHAR(45)  NOT NULL  COLLATE NOCASE"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                }
            ],
            "indexes": [
                {
                    "name": "nombre_inmi_UNIQUE",
                    "value": "`nombre` DESC",
                    "mode": "UNIQUE"
                }
            ],
            "triggers": [
                {
                    "name": "inmunizaciones_trigger_last_modified",
                    "logic": "BEGIN      UPDATE inmunizaciones SET last_modified= (strftime('%s', 'now')) WHERE id_inmunizacion=OLD.id_inmunizacion;  END",
                    "condition": "FOR EACH ROW WHEN NEW.last_modified < OLD.last_modified",
                    "timeevent": "AFTER UPDATE ON"
                }
            ],
            "values": [
                [
                    2,
                    "A GRIPAL",
                    0,
                    1681778781
                ],
                [
                    3,
                    "DB",
                    0,
                    1681778781
                ],
                [
                    1,
                    "TBA",
                    0,
                    1681778781
                ],
                [
                    4,
                    "VHB",
                    0,
                    1681778781
                ]
            ]
        },
        {
            "name": "tratamiento_hiv",
            "schema": [
                {
                    "column": "id_tratamiento_hiv",
                    "value": "INT PRIMARY KEY NOT NULL"
                },
                {
                    "column": "droga",
                    "value": "VARCHAR(45)  NOT NULL DEFAULT 'AZT DURANTE 1 MES' COLLATE NOCASE"
                },
                {
                    "column": "medico_tratante",
                    "value": "VARCHAR(45)  NOT NULL  COLLATE NOCASE"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                }
            ]
        },
        {
            "name": "laboratorios",
            "schema": [
                {
                    "column": "id_laboratorio",
                    "value": "INT PRIMARY KEY NOT NULL"
                },
                {
                    "column": "nombre",
                    "value": "VARCHAR(45)  NOT NULL  COLLATE NOCASE"
                },
                {
                    "column": "resultado",
                    "value": "VARCHAR(1)  NULL DEFAULT NULL COLLATE NOCASE"
                },
                {
                    "column": "confirmacion",
                    "value": "SMALLINT  NOT NULL"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                }
            ],
            "indexes": [
                {
                    "name": "nombre_labo_UNIQUE",
                    "value": "`nombre` DESC,`confirmacion` DESC",
                    "mode": "UNIQUE"
                }
            ],
            "triggers": [
                {
                    "name": "laboratorios_trigger_last_modified",
                    "logic": "BEGIN      UPDATE laboratorios SET last_modified= (strftime('%s', 'now')) WHERE id_laboratorio=OLD.id_laboratorio;  END",
                    "condition": "FOR EACH ROW WHEN NEW.last_modified < OLD.last_modified",
                    "timeevent": "AFTER UPDATE ON"
                }
            ],
            "values": [
                [
                    1,
                    "SÍFILIS",
                    "B",
                    0,
                    0,
                    1681778803
                ],
                [
                    2,
                    "HIV",
                    "B",
                    0,
                    0,
                    1681778803
                ],
                [
                    4,
                    "CHAGAS",
                    "B",
                    0,
                    0,
                    1681778803
                ],
                [
                    5,
                    "VHB",
                    "B",
                    0,
                    0,
                    1681778803
                ],
                [
                    6,
                    "Hb",
                    "N",
                    0,
                    0,
                    1681778803
                ],
                [
                    7,
                    "GLUCEMIA",
                    "N",
                    0,
                    0,
                    1681778803
                ],
                [
                    8,
                    "ESTREPTOCOCO BETA HEMOLÍTICO",
                    "B",
                    0,
                    0,
                    1681778803
                ],
                [
                    9,
                    "GRUPO Y FACTOR",
                    "L",
                    0,
                    0,
                    1681778803
                ],
                [
                    11,
                    "MICROMÉTODO",
                    "B",
                    1,
                    0,
                    1681778803
                ],
                [
                    12,
                    "PARASITEMIA",
                    "B",
                    1,
                    0,
                    1681778803
                ],
                [
                    13,
                    "VDRL CUANTITATIVO",
                    "M",
                    1,
                    0,
                    1681778803
                ],
                [
                    14,
                    "PCR/CV",
                    "B",
                    1,
                    0,
                    1681778803
                ],
                [
                    15,
                    "antiHBc",
                    "B",
                    1,
                    0,
                    1681778803
                ],
                [
                    16,
                    "antiHBs",
                    "N",
                    1,
                    0,
                    1681778803
                ],
                [
                    17,
                    "HBs",
                    "N",
                    1,
                    0,
                    1681778803
                ],
                [
                    18,
                    "FTA-ABS",
                    "B",
                    1,
                    0,
                    1681778803
                ],
                [
                    19,
                    "HAI",
                    "B",
                    1,
                    0,
                    1681778803
                ],
                [
                    20,
                    "ELISA",
                    "B",
                    1,
                    0,
                    1681778803
                ],
                [
                    21,
                    "WB",
                    "B",
                    1,
                    0,
                    1681778803
                ],
                [
                    22,
                    "HBsAg",
                    "B",
                    1,
                    0,
                    1681778803
                ],
                [
                    23,
                    "IFI",
                    "B",
                    1,
                    0,
                    1681778803
                ]
            ]
        },
        {
            "name": "eventos_adversos",
            "schema": [
                {
                    "column": "id_evento_adverso",
                    "value": "INT PRIMARY KEY NOT NULL"
                },
                {
                    "column": "nombre",
                    "value": "TEXT  NOT NULL  COLLATE NOCASE"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                }
            ],
            "triggers": [
                {
                    "name": "eventos_adversos_trigger_last_modified",
                    "logic": "BEGIN      UPDATE eventos_adversos SET last_modified= (strftime('%s', 'now')) WHERE id_evento_adverso=OLD.id_evento_adverso;  END",
                    "condition": "FOR EACH ROW WHEN NEW.last_modified < OLD.last_modified",
                    "timeevent": "AFTER UPDATE ON"
                }
            ],
            "values": [
                [
                    1,
                    "CEFALEAS",
                    0,
                    1681778771
                ],
                [
                    2,
                    "EPIGASTRALGIA",
                    0,
                    1681778771
                ],
                [
                    3,
                    "CÓLICOS",
                    0,
                    1681778771
                ],
                [
                    4,
                    "DIARREA",
                    0,
                    1681778771
                ],
                [
                    5,
                    "NÁUSEAS",
                    0,
                    1681778771
                ],
                [
                    6,
                    "VÓMITOS",
                    0,
                    1681778771
                ],
                [
                    7,
                    "ANOREXIA",
                    0,
                    1681778771
                ],
                [
                    8,
                    "IRRITABILIDAD",
                    0,
                    1681778771
                ],
                [
                    9,
                    "TEMBLOR",
                    0,
                    1681778771
                ],
                [
                    10,
                    "PARESTESIA",
                    0,
                    1681778771
                ],
                [
                    11,
                    "MAREO",
                    0,
                    1681778771
                ],
                [
                    12,
                    "DISTENCIÓN ABDOMINAL",
                    0,
                    1681778771
                ],
                [
                    13,
                    "FIEBRE",
                    0,
                    1681778771
                ],
                [
                    14,
                    "ELEVACIÓN DE TRANSAMINASAS",
                    0,
                    1681778771
                ],
                [
                    15,
                    "EOSINOFILIA",
                    0,
                    1681778771
                ],
                [
                    16,
                    "PLAQUETOPENIA",
                    0,
                    1681778771
                ],
                [
                    17,
                    "LEUCOPENIA",
                    0,
                    1681778771
                ],
                [
                    18,
                    "SÍNDROME DE STEVENS-JOHNSON",
                    0,
                    1681778771
                ],
                [
                    19,
                    "OTROS",
                    0,
                    1681778771
                ]
            ]
        },
        {
            "name": "tratamiento_sifilis",
            "schema": [
                {
                    "column": "id_tratamiento_sifilis",
                    "value": "INT PRIMARY KEY NOT NULL"
                },
                {
                    "column": "droga",
                    "value": "VARCHAR(100)  NOT NULL DEFAULT 'PENICILINA BENZATINICA 2400000' COLLATE NOCASE"
                },
                {
                    "column": "fecha_dosis",
                    "value": "DATE  NOT NULL"
                },
                {
                    "column": "fecha_fin_tratamiento",
                    "value": "DATE  NULL DEFAULT NULL"
                },
                {
                    "column": "dosis_numero",
                    "value": "INT  NULL DEFAULT NULL"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                }
            ]
        },
        {
            "name": "macs",
            "schema": [
                {
                    "column": "id_mac",
                    "value": "INT PRIMARY KEY NOT NULL"
                },
                {
                    "column": "nombre",
                    "value": "VARCHAR(45)  NOT NULL  COLLATE NOCASE"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                }
            ],
            "triggers": [
                {
                    "name": "macs_trigger_last_modified",
                    "logic": "BEGIN      UPDATE macs SET last_modified= (strftime('%s', 'now')) WHERE id_mac=OLD.id_mac;  END",
                    "condition": "FOR EACH ROW WHEN NEW.last_modified < OLD.last_modified",
                    "timeevent": "AFTER UPDATE ON"
                }
            ],
            "values": [
                [
                    1,
                    "IMPLANTE",
                    0,
                    1681778838
                ],
                [
                    2,
                    "DIU",
                    0,
                    1681778838
                ],
                [
                    3,
                    "ORAL",
                    0,
                    1681778838
                ],
                [
                    4,
                    "INYECTABLE",
                    0,
                    1681778838
                ],
                [
                    5,
                    "BARRERA",
                    0,
                    1681778838
                ],
                [
                    6,
                    "NINGUNO",
                    0,
                    1681778838
                ]
            ]
        },
        {
            "name": "tratamiento_vhb",
            "schema": [
                {
                    "column": "id_tratamiento_vhb",
                    "value": "INT PRIMARY KEY NOT NULL"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                }
            ]
        },
        {
            "name": "controles",
            "schema": [
                {
                    "column": "id_control",
                    "value": "INT PRIMARY KEY NOT NULL"
                },
                {
                    "column": "fecha",
                    "value": "DATE  NOT NULL"
                },
                {
                    "column": "id_persona",
                    "value": "INT  NOT NULL"
                },
                {
                    "column": "control_numero",
                    "value": "INT  NOT NULL"
                },
                {
                    "column": "id_estado",
                    "value": "INT  NULL DEFAULT NULL"
                },
                {
                    "column": "id_seguimiento_chagas",
                    "value": "INT  NULL DEFAULT NULL"
                },
                {
                    "column": "id_tratamiento_chagas",
                    "value": "INT  NULL DEFAULT NULL"
                },
                {
                    "column": "id_seguimiento_hiv",
                    "value": "INT  NULL DEFAULT NULL"
                },
                {
                    "column": "id_tratamiento_hiv",
                    "value": "INT  NULL DEFAULT NULL"
                },
                {
                    "column": "id_seguimiento_sifilis",
                    "value": "INT  NULL DEFAULT NULL"
                },
                {
                    "column": "id_tratamiento_sifilis",
                    "value": "INT  NULL DEFAULT NULL"
                },
                {
                    "column": "id_seguimiento_vhb",
                    "value": "INT  NULL DEFAULT NULL"
                },
                {
                    "column": "id_tratamiento_vhb",
                    "value": "INT  NULL DEFAULT NULL"
                },
                {
                    "column": "fecha_fin_embarazo",
                    "value": "DATE  NULL DEFAULT NULL"
                },
                {
                    "column": "id_tipos_fin_embarazos",
                    "value": "INT  NULL DEFAULT NULL"
                },
                {
                    "column": "georeferencia",
                    "value": "VARCHAR(30)  NULL DEFAULT NULL COLLATE NOCASE"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                },
                {
                    "constraint": "`controles_ibfk_1`",
                    "value": "FOREIGN KEY (`id_seguimiento_chagas`) REFERENCES seguimiento_chagas (`id_seguimiento_chagas`) ON DELETE RESTRICT ON UPDATE RESTRICT"
                },
                {
                    "constraint": "`controles_ibfk_2`",
                    "value": "FOREIGN KEY (`id_seguimiento_hiv`) REFERENCES seguimiento_hiv (`id_seguimiento_hiv`) ON DELETE RESTRICT ON UPDATE RESTRICT"
                },
                {
                    "constraint": "`controles_ibfk_3`",
                    "value": "FOREIGN KEY (`id_seguimiento_sifilis`) REFERENCES seguimiento_sifilis (`id_seguimiento_sifilis`) ON DELETE RESTRICT ON UPDATE RESTRICT"
                },
                {
                    "constraint": "`controles_ibfk_4`",
                    "value": "FOREIGN KEY (`id_seguimiento_vhb`) REFERENCES seguimiento_vhb (`id_seguimiento_vhb`) ON DELETE RESTRICT ON UPDATE RESTRICT"
                },
                {
                    "constraint": "`controles_ibfk_5`",
                    "value": "FOREIGN KEY (`id_tratamiento_chagas`) REFERENCES tratamiento_chagas (`id_tratamiento_chagas`) ON DELETE RESTRICT ON UPDATE RESTRICT"
                },
                {
                    "constraint": "`controles_ibfk_6`",
                    "value": "FOREIGN KEY (`id_tratamiento_hiv`) REFERENCES tratamiento_hiv (`id_tratamiento_hiv`) ON DELETE RESTRICT ON UPDATE RESTRICT"
                },
                {
                    "constraint": "`controles_ibfk_7`",
                    "value": "FOREIGN KEY (`id_tratamiento_sifilis`) REFERENCES tratamiento_sifilis (`id_tratamiento_sifilis`) ON DELETE RESTRICT ON UPDATE RESTRICT"
                },
                {
                    "constraint": "`controles_ibfk_9`",
                    "value": "FOREIGN KEY (`id_tratamiento_vhb`) REFERENCES tratamiento_vhb (`id_tratamiento_vhb`) ON DELETE RESTRICT ON UPDATE RESTRICT"
                },
                {
                    "constraint": "`fk_controles_estado`",
                    "value": "FOREIGN KEY (`id_estado`) REFERENCES estados (`id_estado`) ON DELETE NO ACTION ON UPDATE NO ACTION"
                },
                {
                    "constraint": "`fk_controles_personas`",
                    "value": "FOREIGN KEY (`id_persona`) REFERENCES personas (`id_persona`) ON DELETE RESTRICT ON UPDATE RESTRICT"
                },
                {
                    "constraint": "`fk_tipo_fin_emb`",
                    "value": "FOREIGN KEY (`id_tipos_fin_embarazos`) REFERENCES tipos_fin_embarazos (`id_tipos_fin_embarazos`) ON DELETE RESTRICT ON UPDATE RESTRICT"
                }
            ],
            "indexes": [
                {
                    "name": "fecha",
                    "value": "`fecha` DESC,`id_persona` DESC,`control_numero` DESC",
                    "mode": "UNIQUE"
                },
                {
                    "name": "fk_Estado_idx",
                    "value": "`id_estado` DESC"
                },
                {
                    "name": "id_seguimiento_chagas",
                    "value": "`id_seguimiento_chagas` DESC"
                },
                {
                    "name": "id_seguimiento_hiv",
                    "value": "`id_seguimiento_hiv` DESC"
                },
                {
                    "name": "id_seguimiento_sifilis",
                    "value": "`id_seguimiento_sifilis` DESC"
                },
                {
                    "name": "id_tratamiento_chagas_controles",
                    "value": "`id_tratamiento_chagas` DESC"
                },
                {
                    "name": "id_tratamiento_hiv",
                    "value": "`id_tratamiento_hiv` DESC"
                },
                {
                    "name": "id_tratamiento_sifilis",
                    "value": "`id_tratamiento_sifilis` DESC"
                },
                {
                    "name": "id_seguimiento_vhb",
                    "value": "`id_seguimiento_vhb` DESC"
                },
                {
                    "name": "id_tratamiento_vhb",
                    "value": "`id_tratamiento_vhb` DESC"
                },
                {
                    "name": "id_persona",
                    "value": "`id_persona` DESC"
                },
                {
                    "name": "id_tipos_fin_embarazos",
                    "value": "`id_tipos_fin_embarazos` DESC"
                }
            ],
            "triggers": [
                {
                    "name": "controles_trigger_last_modified",
                    "logic": "BEGIN  UPDATE controles SET last_modified= strftime('%s', 'now') WHERE id_control=OLD.id_control;END",
                    "condition": "WHEN 1",
                    "timeevent": "AFTER UPDATE ON"
                }
            ],
           
        },
        {
            "name": "motivo_finalizacion_tratamiento",
            "schema": [
                {
                    "column": "id_motivo_finalizacion_tratamiento",
                    "value": "INT PRIMARY KEY NOT NULL"
                },
                {
                    "column": "nombre",
                    "value": "VARCHAR(45)  NOT NULL  COLLATE NOCASE"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                }
            ],
            "triggers": [
                {
                    "name": "motivo_finalizacion_tratamiento_trigger_last_modified",
                    "logic": "BEGIN      UPDATE motivo_finalizacion_tratamiento SET last_modified= (strftime('%s', 'now')) WHERE id_motivo_finalizacion_tratamiento=OLD.id_motivo_finalizacion_tratamiento;  END",
                    "condition": "FOR EACH ROW WHEN NEW.last_modified < OLD.last_modified",
                    "timeevent": "AFTER UPDATE ON"
                }
            ],
            "values": [
                [
                    1,
                    "TRATAMIENTO COMPLETO",
                    0,
                    1681778853
                ],
                [
                    2,
                    "SUSPENDIDO POR EVENTOS ADVERSOS",
                    0,
                    1681778853
                ],
                [
                    3,
                    "SUSPENDIDO POR OTRAS RAZONES",
                    0,
                    1681778853
                ],
                [
                    4,
                    "SUSPENDIDO POR EMBARAZO",
                    0,
                    1681778853
                ],
                [
                    5,
                    "ABANDONADO",
                    0,
                    1681778853
                ]
            ]
        },
        {
            "name": "patologias_embarazos",
            "schema": [
                {
                    "column": "id_patologia_embarazo",
                    "value": "INT PRIMARY KEY NOT NULL"
                },
                {
                    "column": "nombre",
                    "value": "VARCHAR(80)  NOT NULL  COLLATE NOCASE"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                }
            ],
            "indexes": [
                {
                    "name": "nombre_pe_UNIQUE",
                    "value": "`nombre` DESC",
                    "mode": "UNIQUE"
                }
            ],
            "values": [
                [
                    14,
                    "ALTERACIONES ENCONTRADAS EN LA VITALIDAD FETAL (MF - LCF)",
                    0,
                    1681778934
                ],
                [
                    1,
                    "ANEMIA",
                    0,
                    1681778934
                ],
                [
                    15,
                    "APP",
                    0,
                    1681778934
                ],
                [
                    8,
                    "CARDIOPATÍA",
                    0,
                    1681778934
                ],
                [
                    6,
                    "DBT GESTACIONAL",
                    0,
                    1681778934
                ],
                [
                    10,
                    "ECLAMPSIA",
                    0,
                    1681778934
                ],
                [
                    7,
                    "EMBARAZO MÚLTIPLE",
                    0,
                    1681778934
                ],
                [
                    11,
                    "HEMORRAGIAS 1° TRIM",
                    0,
                    1681778934
                ],
                [
                    12,
                    "HEMORRAGIAS 2° TRIM",
                    0,
                    1681778934
                ],
                [
                    13,
                    "HEMORRAGIAS 3° TRIM",
                    0,
                    1681778934
                ],
                [
                    5,
                    "HTA GESTACIONAL",
                    0,
                    1681778934
                ],
                [
                    2,
                    "ITU",
                    0,
                    1681778934
                ],
                [
                    17,
                    "OTRAS",
                    0,
                    1681778934
                ],
                [
                    3,
                    "OTRAS INFECCIONES",
                    0,
                    1681778934
                ],
                [
                    9,
                    "PREECLAMPSIA",
                    0,
                    1681778934
                ],
                [
                    16,
                    "RCIU",
                    0,
                    1681778934
                ],
                [
                    4,
                    "TBC",
                    0,
                    1681778934
                ]
            ]
        },
        {
            "name": "niveles_acceso",
            "schema": [
                {
                    "column": "id_nivel_acceso",
                    "value": "INT PRIMARY KEY NOT NULL"
                },
                {
                    "column": "acceso",
                    "value": "VARCHAR(50)  NOT NULL  COLLATE NOCASE"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                }
            ],
            "triggers": [
                {
                    "name": "niveles_acceso_derivacion_trigger_last_modified",
                    "logic": "BEGIN      UPDATE niveles_acceso SET last_modified= (strftime('%s', 'now')) WHERE id_nivel_acceso=OLD.id_nivel_acceso;  END",
                    "condition": "FOR EACH ROW WHEN NEW.last_modified < OLD.last_modified",
                    "timeevent": "AFTER UPDATE ON"
                }
            ],
            "values": [
                [
                    1,
                    "FULL",
                    0,
                    1681778875
                ],
                [
                    2,
                    "CONSULTA",
                    0,
                    1681778875
                ]
            ]
        },
        {
            "name": "origenes",
            "schema": [
                {
                    "column": "id_origen",
                    "value": "INT PRIMARY KEY NOT NULL"
                },
                {
                    "column": "nombre",
                    "value": "VARCHAR(100)  NOT NULL  COLLATE NOCASE"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                }
            ],
            "indexes": [
                {
                    "name": "nombre_origenes",
                    "value": "`nombre` DESC",
                    "mode": "UNIQUE"
                }
            ],
            "triggers": [
                {
                    "name": "origenes_derivacion_trigger_last_modified",
                    "logic": "BEGIN      UPDATE origenes SET last_modified= (strftime('%s', 'now')) WHERE id_origen=OLD.id_origen;  END",
                    "condition": "FOR EACH ROW WHEN NEW.last_modified < OLD.last_modified",
                    "timeevent": "AFTER UPDATE ON"
                }
            ],
            "values": [
                [
                    2,
                    "CRIOLLA",
                    0,
                    1681778890
                ],
                [
                    1,
                    "ORIGINARIA",
                    0,
                    1681778890
                ]
            ]
        },
        {
            "name": "areas",
            "schema": [
                {
                    "column": "id_area",
                    "value": "INT PRIMARY KEY NOT NULL"
                },
                {
                    "column": "id_pais",
                    "value": "INT  NOT NULL"
                },
                {
                    "column": "nombre",
                    "value": "VARCHAR(100)  NOT NULL  COLLATE NOCASE"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                },
                {
                    "constraint": "`fk_paises`",
                    "value": "FOREIGN KEY (`id_pais`) REFERENCES paises (`id_pais`) ON DELETE RESTRICT ON UPDATE RESTRICT"
                }
            ],
            "indexes": [
                {
                    "name": "id_pais",
                    "value": "`id_pais` DESC"
                }
            ],
            "triggers": [
                {
                    "name": "areas_trigger_last_modified",
                    "logic": "BEGIN      UPDATE areas SET last_modified= (strftime('%s', 'now')) WHERE id_area=OLD.id_area;  END",
                    "condition": "FOR EACH ROW WHEN NEW.last_modified < OLD.last_modified",
                    "timeevent": "AFTER UPDATE ON"
                }
            ],
            "values": [
                [
                    1,
                    27,
                    "BOLIVIA",
                    0,
                    1681778592
                ],
                [
                    2,
                    177,
                    "PARAGUAY",
                    0,
                    1681778592
                ],
                [
                    3,
                    12,
                    "SANTA VICTORIA ESTE",
                    0,
                    1681778592
                ],
                [
                    4,
                    12,
                    "ALTO LA SIERRA",
                    0,
                    1681778592
                ]
            ]
        },
        {
            "name": "ciudades",
            "schema": [
                {
                    "column": "id_ciudad",
                    "value": "INT PRIMARY KEY NOT NULL"
                },
                {
                    "column": "nombre",
                    "value": "VARCHAR(30)  NOT NULL  COLLATE BINARY"
                },
                {
                    "column": "id_provincia",
                    "value": "INT  NOT NULL"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                },
                {
                    "constraint": "`fk_ciudad_provincias`",
                    "value": "FOREIGN KEY (`id_provincia`) REFERENCES provincias (`id_provincia`) ON DELETE RESTRICT ON UPDATE RESTRICT"
                }
            ],
            "indexes": [
                {
                    "name": "id_provincia",
                    "value": "`id_provincia` DESC"
                }
            ],
            "triggers": [
                {
                    "name": "ciudades_trigger_last_modified",
                    "logic": "BEGIN      UPDATE ciudades SET last_modified= (strftime('%s', 'now')) WHERE id_ciudad=OLD.id_ciudad;  END",
                    "condition": "FOR EACH ROW WHEN NEW.last_modified < OLD.last_modified",
                    "timeevent": "AFTER UPDATE ON"
                }
            ],
            "values": [
                [
                    1,
                    "Aroma",
                    33,
                    0,
                    1681778613
                ],
                [
                    2,
                    "Caracas",
                    32,
                    0,
                    1681778613
                ],
                [
                    3,
                    "Cochabamba",
                    26,
                    0,
                    1681778613
                ],
                [
                    4,
                    "Cruz",
                    32,
                    0,
                    1681778613
                ],
                [
                    5,
                    "La Paz",
                    28,
                    0,
                    1681778613
                ],
                [
                    6,
                    "Oruro",
                    30,
                    0,
                    1681778613
                ],
                [
                    7,
                    "Quillacollo",
                    26,
                    0,
                    1681778613
                ],
                [
                    8,
                    "Riberalta",
                    27,
                    0,
                    1681778613
                ],
                [
                    9,
                    "Santa Cruz",
                    27,
                    0,
                    1681778613
                ],
                [
                    10,
                    "Santa Cruz De La Sierra",
                    33,
                    0,
                    1681778613
                ],
                [
                    11,
                    "Sucre",
                    25,
                    0,
                    1681778613
                ],
                [
                    12,
                    "Tarija",
                    29,
                    0,
                    1681778613
                ],
                [
                    13,
                    "Trinidad",
                    27,
                    0,
                    1681778613
                ],
                [
                    14,
                    "Ulupicas",
                    29,
                    0,
                    1681778613
                ],
                [
                    15,
                    "Ciudad Del Este",
                    34,
                    0,
                    1681778613
                ],
                [
                    16,
                    "Fernando De La Mora",
                    39,
                    0,
                    1681778613
                ],
                [
                    17,
                    "Hernandarias",
                    34,
                    0,
                    1681778613
                ],
                [
                    18,
                    "Hohenau",
                    44,
                    0,
                    1681778613
                ],
                [
                    19,
                    "Loma Plata",
                    36,
                    0,
                    1681778613
                ],
                [
                    20,
                    "Los Cedrales",
                    34,
                    0,
                    1681778613
                ],
                [
                    21,
                    "Luque",
                    39,
                    0,
                    1681778613
                ],
                [
                    22,
                    "Mariano Roque Alonso",
                    39,
                    0,
                    1681778613
                ],
                [
                    23,
                    "Obligado",
                    44,
                    0,
                    1681778613
                ],
                [
                    24,
                    "Pilar",
                    46,
                    0,
                    1681778613
                ],
                [
                    25,
                    "Presidente Franco",
                    34,
                    0,
                    1681778613
                ],
                [
                    26,
                    "San Lorenzo",
                    39,
                    0,
                    1681778613
                ],
                [
                    27,
                    "Santa Rita",
                    34,
                    0,
                    1681778613
                ],
                [
                    28,
                    "28 De Septiembre",
                    1,
                    0,
                    1681778613
                ],
                [
                    29,
                    "6 De Septiembre",
                    1,
                    0,
                    1681778613
                ],
                [
                    30,
                    "A. Brown",
                    1,
                    0,
                    1681778613
                ],
                [
                    31,
                    "Abasto",
                    1,
                    0,
                    1681778613
                ],
                [
                    32,
                    "Abra Rica",
                    24,
                    0,
                    1681778613
                ],
                [
                    33,
                    "Acassuso",
                    1,
                    0,
                    1681778613
                ],
                [
                    34,
                    "Aguilar",
                    10,
                    0,
                    1681778613
                ],
                [
                    35,
                    "Aldo Bonzi",
                    1,
                    0,
                    1681778613
                ],
                [
                    36,
                    "Alem",
                    1,
                    0,
                    1681778613
                ],
                [
                    37,
                    "Allen",
                    16,
                    0,
                    1681778613
                ],
                [
                    38,
                    "Almirante Brown",
                    7,
                    0,
                    1681778613
                ],
                [
                    39,
                    "Alsina",
                    1,
                    0,
                    1681778613
                ],
                [
                    40,
                    "Alta Gracia",
                    5,
                    0,
                    1681778613
                ],
                [
                    41,
                    "Alvear",
                    21,
                    0,
                    1681778613
                ],
                [
                    42,
                    "Arrecifes",
                    1,
                    0,
                    1681778613
                ],
                [
                    43,
                    "Arroyo Dulce",
                    1,
                    0,
                    1681778613
                ],
                [
                    44,
                    "Asturias",
                    1,
                    0,
                    1681778613
                ],
                [
                    45,
                    "Avellaneda",
                    1,
                    0,
                    1681778613
                ],
                [
                    46,
                    "Avellaneda",
                    21,
                    0,
                    1681778613
                ],
                [
                    47,
                    "Ayacucho",
                    1,
                    0,
                    1681778613
                ],
                [
                    48,
                    "Azul",
                    1,
                    0,
                    1681778613
                ],
                [
                    49,
                    "Balbastro",
                    7,
                    0,
                    1681778613
                ],
                [
                    50,
                    "Balcarce",
                    1,
                    0,
                    1681778613
                ],
                [
                    51,
                    "Ballester",
                    1,
                    0,
                    1681778613
                ],
                [
                    52,
                    "Balnearia",
                    5,
                    0,
                    1681778613
                ],
                [
                    53,
                    "Bancalari",
                    1,
                    0,
                    1681778613
                ],
                [
                    54,
                    "Banfield",
                    1,
                    0,
                    1681778613
                ],
                [
                    55,
                    "Bariloche",
                    1,
                    0,
                    1681778613
                ],
                [
                    56,
                    "Barrancas Viejo",
                    21,
                    0,
                    1681778613
                ],
                [
                    57,
                    "Barrio San Patricio",
                    1,
                    0,
                    1681778613
                ],
                [
                    58,
                    "Beccar",
                    1,
                    0,
                    1681778613
                ],
                [
                    59,
                    "Belgrano",
                    22,
                    0,
                    1681778613
                ],
                [
                    60,
                    "Bell Ville",
                    5,
                    0,
                    1681778613
                ],
                [
                    61,
                    "Bella Vista",
                    1,
                    0,
                    1681778613
                ],
                [
                    62,
                    "Benavides",
                    1,
                    0,
                    1681778613
                ],
                [
                    63,
                    "Berazategui",
                    1,
                    0,
                    1681778613
                ],
                [
                    64,
                    "Berisso",
                    1,
                    0,
                    1681778613
                ],
                [
                    65,
                    "Bernal",
                    1,
                    0,
                    1681778613
                ],
                [
                    66,
                    "Billinghurst",
                    1,
                    0,
                    1681778613
                ],
                [
                    67,
                    "Bordenave",
                    1,
                    0,
                    1681778613
                ],
                [
                    68,
                    "Bosch",
                    1,
                    0,
                    1681778613
                ],
                [
                    69,
                    "Bosques",
                    1,
                    0,
                    1681778613
                ],
                [
                    70,
                    "Boulogne",
                    1,
                    0,
                    1681778613
                ],
                [
                    71,
                    "Bowen",
                    13,
                    0,
                    1681778613
                ],
                [
                    72,
                    "Bragado",
                    1,
                    0,
                    1681778613
                ],
                [
                    73,
                    "Brandsen",
                    1,
                    0,
                    1681778613
                ],
                [
                    74,
                    "Buenos Aires",
                    7,
                    0,
                    1681778613
                ],
                [
                    75,
                    "Burzaco",
                    1,
                    0,
                    1681778613
                ],
                [
                    76,
                    "C. De Mayo",
                    1,
                    0,
                    1681778613
                ],
                [
                    77,
                    "Caleta Olivia",
                    20,
                    0,
                    1681778613
                ],
                [
                    78,
                    "Campana",
                    1,
                    0,
                    1681778613
                ],
                [
                    79,
                    "Canning",
                    1,
                    0,
                    1681778613
                ],
                [
                    80,
                    "Caraza",
                    1,
                    0,
                    1681778613
                ],
                [
                    81,
                    "Carlos Casares",
                    1,
                    0,
                    1681778613
                ],
                [
                    82,
                    "Carlos Pellegrini",
                    21,
                    0,
                    1681778613
                ],
                [
                    83,
                    "Carlos Tejedor",
                    1,
                    0,
                    1681778613
                ],
                [
                    84,
                    "Carmen",
                    23,
                    0,
                    1681778613
                ],
                [
                    85,
                    "Carreras",
                    21,
                    0,
                    1681778613
                ],
                [
                    86,
                    "Caseros",
                    1,
                    0,
                    1681778613
                ],
                [
                    87,
                    "Casilda",
                    21,
                    0,
                    1681778613
                ],
                [
                    88,
                    "Castelar",
                    1,
                    0,
                    1681778613
                ],
                [
                    89,
                    "Catamarca",
                    2,
                    0,
                    1681778613
                ],
                [
                    90,
                    "Catriel",
                    16,
                    0,
                    1681778613
                ],
                [
                    91,
                    "Centenario",
                    1,
                    0,
                    1681778613
                ],
                [
                    92,
                    "Cevallos",
                    11,
                    0,
                    1681778613
                ],
                [
                    93,
                    "Chacabuco",
                    1,
                    0,
                    1681778613
                ],
                [
                    94,
                    "Chacarita",
                    7,
                    0,
                    1681778613
                ],
                [
                    95,
                    "Chivilcoy",
                    1,
                    0,
                    1681778613
                ],
                [
                    96,
                    "Choele Choel",
                    16,
                    0,
                    1681778613
                ],
                [
                    97,
                    "Cipolleti",
                    16,
                    0,
                    1681778613
                ],
                [
                    98,
                    "Cipolletti",
                    16,
                    0,
                    1681778613
                ],
                [
                    99,
                    "City Bell",
                    1,
                    0,
                    1681778613
                ],
                [
                    100,
                    "Ciudad Evita",
                    1,
                    0,
                    1681778613
                ],
                [
                    101,
                    "Ciudad Universitaria",
                    24,
                    0,
                    1681778613
                ],
                [
                    102,
                    "Ciudadela",
                    1,
                    0,
                    1681778613
                ],
                [
                    103,
                    "Claypole",
                    1,
                    0,
                    1681778613
                ],
                [
                    104,
                    "Coillun-Co",
                    15,
                    0,
                    1681778613
                ],
                [
                    105,
                    "Colonia Caroya",
                    5,
                    0,
                    1681778613
                ],
                [
                    106,
                    "Comandante L. Piedrabuena",
                    7,
                    0,
                    1681778613
                ],
                [
                    107,
                    "Comandante Nicanor Otamendi",
                    1,
                    0,
                    1681778613
                ],
                [
                    108,
                    "Comodoro Rivadavia",
                    7,
                    0,
                    1681778613
                ],
                [
                    109,
                    "Conchitas",
                    1,
                    0,
                    1681778613
                ],
                [
                    110,
                    "Concordia",
                    8,
                    0,
                    1681778613
                ],
                [
                    111,
                    "Corral De Bustos",
                    5,
                    0,
                    1681778613
                ],
                [
                    112,
                    "Corrientes",
                    6,
                    0,
                    1681778613
                ],
                [
                    113,
                    "Crespo",
                    8,
                    0,
                    1681778613
                ],
                [
                    114,
                    "Cruz Del Eje",
                    5,
                    0,
                    1681778613
                ],
                [
                    115,
                    "Darregueira",
                    1,
                    0,
                    1681778613
                ],
                [
                    116,
                    "De Mayo",
                    14,
                    0,
                    1681778613
                ],
                [
                    117,
                    "Del Viso",
                    1,
                    0,
                    1681778613
                ],
                [
                    118,
                    "Derechos De La Ancianidad",
                    1,
                    0,
                    1681778613
                ],
                [
                    119,
                    "Derqui",
                    1,
                    0,
                    1681778613
                ],
                [
                    120,
                    "Diamante",
                    1,
                    0,
                    1681778613
                ],
                [
                    121,
                    "Diamante",
                    8,
                    0,
                    1681778613
                ],
                [
                    122,
                    "Dolores",
                    1,
                    0,
                    1681778613
                ],
                [
                    123,
                    "Domselaar",
                    1,
                    0,
                    1681778613
                ],
                [
                    124,
                    "Don Torcuato",
                    1,
                    0,
                    1681778613
                ],
                [
                    125,
                    "Drabble",
                    1,
                    0,
                    1681778613
                ],
                [
                    126,
                    "El Calafate",
                    20,
                    0,
                    1681778613
                ],
                [
                    127,
                    "El Chorrillo",
                    19,
                    0,
                    1681778613
                ],
                [
                    128,
                    "El Dorado",
                    1,
                    0,
                    1681778613
                ],
                [
                    129,
                    "El Hoyo",
                    22,
                    0,
                    1681778613
                ],
                [
                    130,
                    "El Palomar",
                    1,
                    0,
                    1681778613
                ],
                [
                    131,
                    "El Retiro",
                    5,
                    0,
                    1681778613
                ],
                [
                    132,
                    "El Talar",
                    1,
                    0,
                    1681778613
                ],
                [
                    133,
                    "El Verano",
                    1,
                    0,
                    1681778613
                ],
                [
                    134,
                    "Eldorado",
                    14,
                    0,
                    1681778613
                ],
                [
                    135,
                    "Embalse",
                    5,
                    0,
                    1681778613
                ],
                [
                    136,
                    "Ensenada",
                    1,
                    0,
                    1681778613
                ],
                [
                    137,
                    "Escobar",
                    1,
                    0,
                    1681778613
                ],
                [
                    138,
                    "Esmeralda",
                    21,
                    0,
                    1681778613
                ],
                [
                    139,
                    "Espeleta",
                    1,
                    0,
                    1681778613
                ],
                [
                    140,
                    "Esperanza",
                    21,
                    0,
                    1681778613
                ],
                [
                    141,
                    "Esquel",
                    7,
                    0,
                    1681778613
                ],
                [
                    142,
                    "Ezeiza",
                    1,
                    0,
                    1681778613
                ],
                [
                    143,
                    "Ezpeleta",
                    1,
                    0,
                    1681778613
                ],
                [
                    144,
                    "F. Alvarez",
                    1,
                    0,
                    1681778613
                ],
                [
                    145,
                    "F. Paz",
                    21,
                    0,
                    1681778613
                ],
                [
                    146,
                    "Federal",
                    8,
                    0,
                    1681778613
                ],
                [
                    147,
                    "Fiorito",
                    1,
                    0,
                    1681778613
                ],
                [
                    148,
                    "Firmat",
                    21,
                    0,
                    1681778613
                ],
                [
                    149,
                    "Florencio Varela",
                    1,
                    0,
                    1681778613
                ],
                [
                    150,
                    "Flores",
                    7,
                    0,
                    1681778613
                ],
                [
                    151,
                    "Floresta",
                    7,
                    0,
                    1681778613
                ],
                [
                    152,
                    "Florida",
                    1,
                    0,
                    1681778613
                ],
                [
                    153,
                    "Formosa",
                    9,
                    0,
                    1681778613
                ],
                [
                    154,
                    "Fuentes",
                    21,
                    0,
                    1681778613
                ],
                [
                    155,
                    "Fulton",
                    1,
                    0,
                    1681778613
                ],
                [
                    156,
                    "Funes",
                    21,
                    0,
                    1681778613
                ],
                [
                    157,
                    "General Alvear",
                    1,
                    0,
                    1681778613
                ],
                [
                    158,
                    "General Belgrano",
                    1,
                    0,
                    1681778613
                ],
                [
                    159,
                    "General E. Godoy",
                    16,
                    0,
                    1681778613
                ],
                [
                    160,
                    "General Pacheco",
                    1,
                    0,
                    1681778613
                ],
                [
                    161,
                    "General Pico",
                    11,
                    0,
                    1681778613
                ],
                [
                    162,
                    "General Roca",
                    16,
                    0,
                    1681778613
                ],
                [
                    163,
                    "General Villegas",
                    1,
                    0,
                    1681778613
                ],
                [
                    164,
                    "Gerli",
                    1,
                    0,
                    1681778613
                ],
                [
                    165,
                    "Germania",
                    1,
                    0,
                    1681778613
                ],
                [
                    166,
                    "Girondo",
                    1,
                    0,
                    1681778613
                ],
                [
                    167,
                    "Glew",
                    1,
                    0,
                    1681778613
                ],
                [
                    168,
                    "Gobernador Castro",
                    1,
                    0,
                    1681778613
                ],
                [
                    169,
                    "Gobernador Monteverde",
                    1,
                    0,
                    1681778613
                ],
                [
                    170,
                    "Godoy Cruz",
                    13,
                    0,
                    1681778613
                ],
                [
                    171,
                    "Gonnet",
                    1,
                    0,
                    1681778613
                ],
                [
                    172,
                    "Goya",
                    6,
                    0,
                    1681778613
                ],
                [
                    173,
                    "Granadero Baigorria",
                    21,
                    0,
                    1681778613
                ],
                [
                    174,
                    "Grand Bourg",
                    1,
                    0,
                    1681778613
                ],
                [
                    175,
                    "Gualeguay",
                    8,
                    0,
                    1681778613
                ],
                [
                    176,
                    "Guernica",
                    1,
                    0,
                    1681778613
                ],
                [
                    177,
                    "Haedo",
                    1,
                    0,
                    1681778613
                ],
                [
                    178,
                    "Herrera Vegas",
                    1,
                    0,
                    1681778613
                ],
                [
                    179,
                    "Hilario",
                    18,
                    0,
                    1681778613
                ],
                [
                    180,
                    "Hilario Ascasubi",
                    1,
                    0,
                    1681778613
                ],
                [
                    181,
                    "Hughes",
                    21,
                    0,
                    1681778613
                ],
                [
                    182,
                    "Hurlingham",
                    1,
                    0,
                    1681778613
                ],
                [
                    183,
                    "I. Casanova",
                    1,
                    0,
                    1681778613
                ],
                [
                    184,
                    "Ingeniero Budge",
                    1,
                    0,
                    1681778613
                ],
                [
                    185,
                    "Ingeniero Maschwitz",
                    1,
                    0,
                    1681778613
                ],
                [
                    186,
                    "Iraizoz",
                    1,
                    0,
                    1681778613
                ],
                [
                    187,
                    "Isidro Casanova",
                    1,
                    0,
                    1681778613
                ],
                [
                    188,
                    "J.F. Ibarra",
                    1,
                    0,
                    1681778613
                ],
                [
                    189,
                    "J.M. Bosch",
                    1,
                    0,
                    1681778613
                ],
                [
                    190,
                    "J.M. Gutierrez",
                    1,
                    0,
                    1681778613
                ],
                [
                    191,
                    "Juan Vucetich",
                    1,
                    0,
                    1681778613
                ],
                [
                    192,
                    "Jujuy",
                    10,
                    0,
                    1681778613
                ],
                [
                    193,
                    "Justiniano Posse",
                    5,
                    0,
                    1681778613
                ],
                [
                    194,
                    "L. De Zamora",
                    1,
                    0,
                    1681778613
                ],
                [
                    195,
                    "La Cesira",
                    5,
                    0,
                    1681778613
                ],
                [
                    196,
                    "La Invencible",
                    1,
                    0,
                    1681778613
                ],
                [
                    197,
                    "La Pampa",
                    5,
                    0,
                    1681778613
                ],
                [
                    198,
                    "La Paternal",
                    7,
                    0,
                    1681778613
                ],
                [
                    199,
                    "La Paz",
                    1,
                    0,
                    1681778613
                ],
                [
                    200,
                    "La Plata",
                    1,
                    0,
                    1681778613
                ],
                [
                    201,
                    "La Reja",
                    1,
                    0,
                    1681778613
                ],
                [
                    202,
                    "La Rioja",
                    12,
                    0,
                    1681778613
                ],
                [
                    203,
                    "Laferrere",
                    1,
                    0,
                    1681778613
                ],
                [
                    204,
                    "Laguna Larga",
                    5,
                    0,
                    1681778613
                ],
                [
                    205,
                    "Lamarque",
                    16,
                    0,
                    1681778613
                ],
                [
                    206,
                    "Las Calles",
                    5,
                    0,
                    1681778613
                ],
                [
                    207,
                    "Las Delicias",
                    22,
                    0,
                    1681778613
                ],
                [
                    208,
                    "Las Flores",
                    22,
                    0,
                    1681778613
                ],
                [
                    209,
                    "Las Heras",
                    5,
                    0,
                    1681778613
                ],
                [
                    210,
                    "Las Parejas",
                    21,
                    0,
                    1681778613
                ],
                [
                    211,
                    "Las Perdices",
                    5,
                    0,
                    1681778613
                ],
                [
                    212,
                    "Las Varillas",
                    5,
                    0,
                    1681778613
                ],
                [
                    213,
                    "Lavalle",
                    22,
                    0,
                    1681778613
                ],
                [
                    214,
                    "Libertad",
                    1,
                    0,
                    1681778613
                ],
                [
                    215,
                    "Lima",
                    1,
                    0,
                    1681778613
                ],
                [
                    216,
                    "Linch",
                    1,
                    0,
                    1681778613
                ],
                [
                    217,
                    "Lincoln",
                    1,
                    0,
                    1681778613
                ],
                [
                    218,
                    "Liniers",
                    7,
                    0,
                    1681778613
                ],
                [
                    219,
                    "Llavallol",
                    1,
                    0,
                    1681778613
                ],
                [
                    220,
                    "Lobos",
                    1,
                    0,
                    1681778613
                ],
                [
                    221,
                    "Lomas De Zamora",
                    1,
                    0,
                    1681778613
                ],
                [
                    222,
                    "Lomas Del Mirador",
                    1,
                    0,
                    1681778613
                ],
                [
                    223,
                    "Longchamps",
                    1,
                    0,
                    1681778613
                ],
                [
                    224,
                    "Los Antiguos",
                    20,
                    0,
                    1681778613
                ],
                [
                    225,
                    "Los Cardales",
                    1,
                    0,
                    1681778613
                ],
                [
                    226,
                    "Los Perales",
                    7,
                    0,
                    1681778613
                ],
                [
                    227,
                    "Los Polvorines",
                    1,
                    0,
                    1681778613
                ],
                [
                    228,
                    "Los Surgentes",
                    5,
                    0,
                    1681778613
                ],
                [
                    229,
                    "Lugones",
                    22,
                    0,
                    1681778613
                ],
                [
                    230,
                    "Luro",
                    1,
                    0,
                    1681778613
                ],
                [
                    231,
                    "M. Acosta",
                    1,
                    0,
                    1681778613
                ],
                [
                    232,
                    "Madryn",
                    4,
                    0,
                    1681778613
                ],
                [
                    233,
                    "Manfredi",
                    5,
                    0,
                    1681778613
                ],
                [
                    234,
                    "Manzanares",
                    1,
                    0,
                    1681778613
                ],
                [
                    235,
                    "Maquinista Savio",
                    1,
                    0,
                    1681778613
                ],
                [
                    236,
                    "Mar Del Plata",
                    1,
                    0,
                    1681778613
                ],
                [
                    237,
                    "Marcelo Torcuato De Alvear",
                    7,
                    0,
                    1681778613
                ],
                [
                    238,
                    "Marcos Paz",
                    1,
                    0,
                    1681778613
                ],
                [
                    239,
                    "Mariano H. Alfonzo",
                    1,
                    0,
                    1681778613
                ],
                [
                    240,
                    "Mataderos",
                    7,
                    0,
                    1681778613
                ],
                [
                    241,
                    "Mauricio Hirch",
                    1,
                    0,
                    1681778613
                ],
                [
                    242,
                    "Mendiolaza",
                    5,
                    0,
                    1681778613
                ],
                [
                    243,
                    "Mendoza",
                    13,
                    0,
                    1681778613
                ],
                [
                    244,
                    "Mercedes",
                    19,
                    0,
                    1681778613
                ],
                [
                    245,
                    "Merlo",
                    1,
                    0,
                    1681778613
                ],
                [
                    246,
                    "Miramar",
                    1,
                    0,
                    1681778613
                ],
                [
                    247,
                    "Moguehua",
                    1,
                    0,
                    1681778613
                ],
                [
                    248,
                    "Monte Castro",
                    7,
                    0,
                    1681778613
                ],
                [
                    249,
                    "Monte Chingolo",
                    1,
                    0,
                    1681778613
                ],
                [
                    250,
                    "Monte Grande",
                    1,
                    0,
                    1681778613
                ],
                [
                    251,
                    "Munro",
                    1,
                    0,
                    1681778613
                ],
                [
                    252,
                    "Naranjito",
                    24,
                    0,
                    1681778613
                ],
                [
                    253,
                    "Navarro",
                    22,
                    0,
                    1681778613
                ],
                [
                    254,
                    "Necochea",
                    1,
                    0,
                    1681778613
                ],
                [
                    255,
                    "Neild",
                    1,
                    0,
                    1681778613
                ],
                [
                    256,
                    "Nueve De Julio",
                    1,
                    0,
                    1681778613
                ],
                [
                    257,
                    "Oliva",
                    5,
                    0,
                    1681778613
                ],
                [
                    258,
                    "Oliveros",
                    21,
                    0,
                    1681778613
                ],
                [
                    259,
                    "Olivia",
                    5,
                    0,
                    1681778613
                ],
                [
                    260,
                    "Olivos",
                    1,
                    0,
                    1681778613
                ],
                [
                    261,
                    "Ostende",
                    1,
                    0,
                    1681778613
                ],
                [
                    262,
                    "Paso De Los Libres",
                    6,
                    0,
                    1681778613
                ],
                [
                    263,
                    "Paso Del Rey",
                    1,
                    0,
                    1681778613
                ],
                [
                    264,
                    "Pedro Luro",
                    1,
                    0,
                    1681778613
                ],
                [
                    265,
                    "Pellegrini",
                    1,
                    0,
                    1681778613
                ],
                [
                    266,
                    "Pergamino",
                    1,
                    0,
                    1681778613
                ],
                [
                    267,
                    "Pico Truncado",
                    20,
                    0,
                    1681778613
                ],
                [
                    268,
                    "Piedra Pintada",
                    5,
                    0,
                    1681778613
                ],
                [
                    269,
                    "Pilar",
                    21,
                    0,
                    1681778613
                ],
                [
                    270,
                    "Pinamar",
                    1,
                    0,
                    1681778613
                ],
                [
                    271,
                    "Piquete Cabado",
                    17,
                    0,
                    1681778613
                ],
                [
                    272,
                    "Plottier",
                    15,
                    0,
                    1681778613
                ],
                [
                    273,
                    "Polvorines",
                    1,
                    0,
                    1681778613
                ],
                [
                    274,
                    "Pontevedra",
                    1,
                    0,
                    1681778613
                ],
                [
                    275,
                    "Posadas",
                    14,
                    0,
                    1681778613
                ],
                [
                    276,
                    "Presidente Derqui",
                    1,
                    0,
                    1681778613
                ],
                [
                    277,
                    "Puan",
                    1,
                    0,
                    1681778613
                ],
                [
                    278,
                    "Puerto Madero",
                    7,
                    0,
                    1681778613
                ],
                [
                    279,
                    "Puerto Madryn",
                    4,
                    0,
                    1681778613
                ],
                [
                    280,
                    "Pujol",
                    1,
                    0,
                    1681778613
                ],
                [
                    281,
                    "Punta Alta",
                    1,
                    0,
                    1681778613
                ],
                [
                    282,
                    "Punta Indio",
                    1,
                    0,
                    1681778613
                ],
                [
                    283,
                    "Quilmes",
                    1,
                    0,
                    1681778613
                ],
                [
                    284,
                    "R. De Escalada",
                    1,
                    0,
                    1681778613
                ],
                [
                    285,
                    "Rafael Calzada",
                    1,
                    0,
                    1681778613
                ],
                [
                    286,
                    "Rafael Castillo",
                    1,
                    0,
                    1681778613
                ],
                [
                    287,
                    "Rafaela",
                    21,
                    0,
                    1681778613
                ],
                [
                    288,
                    "Ramos Otero",
                    1,
                    0,
                    1681778613
                ],
                [
                    289,
                    "Ranelagh",
                    1,
                    0,
                    1681778613
                ],
                [
                    290,
                    "Rawson",
                    4,
                    0,
                    1681778613
                ],
                [
                    291,
                    "Reconquista",
                    21,
                    0,
                    1681778613
                ],
                [
                    292,
                    "Remedios De Escalada",
                    1,
                    0,
                    1681778613
                ],
                [
                    293,
                    "Resistencia",
                    3,
                    0,
                    1681778613
                ],
                [
                    294,
                    "Rivadavia",
                    13,
                    0,
                    1681778613
                ],
                [
                    295,
                    "Rodriguez",
                    21,
                    0,
                    1681778613
                ],
                [
                    296,
                    "Rojas",
                    1,
                    0,
                    1681778613
                ],
                [
                    297,
                    "Rosa",
                    1,
                    0,
                    1681778613
                ],
                [
                    298,
                    "Rosario",
                    21,
                    0,
                    1681778613
                ],
                [
                    299,
                    "Rosario Del Tala",
                    8,
                    0,
                    1681778613
                ],
                [
                    300,
                    "Rufino",
                    21,
                    0,
                    1681778613
                ],
                [
                    301,
                    "Saladillo",
                    5,
                    0,
                    1681778613
                ],
                [
                    302,
                    "Saladillo",
                    21,
                    0,
                    1681778613
                ],
                [
                    303,
                    "Salsipuedes",
                    5,
                    0,
                    1681778613
                ],
                [
                    304,
                    "Salta",
                    17,
                    0,
                    1681778613
                ],
                [
                    305,
                    "Salto",
                    1,
                    0,
                    1681778613
                ],
                [
                    306,
                    "San Antonio",
                    21,
                    0,
                    1681778613
                ],
                [
                    307,
                    "San Antonio De Areco",
                    1,
                    0,
                    1681778613
                ],
                [
                    308,
                    "San Antonio De Padua",
                    1,
                    0,
                    1681778613
                ],
                [
                    309,
                    "San Antonio Oeste",
                    16,
                    0,
                    1681778613
                ],
                [
                    310,
                    "San Bernardo",
                    22,
                    0,
                    1681778613
                ],
                [
                    311,
                    "San Carlos",
                    22,
                    0,
                    1681778613
                ],
                [
                    312,
                    "San Carlos De Bariloche",
                    16,
                    0,
                    1681778613
                ],
                [
                    313,
                    "San Cayetano",
                    1,
                    0,
                    1681778613
                ],
                [
                    314,
                    "San Clemente",
                    5,
                    0,
                    1681778613
                ],
                [
                    315,
                    "San Fernando",
                    1,
                    0,
                    1681778613
                ],
                [
                    316,
                    "San Francisco Solano",
                    1,
                    0,
                    1681778613
                ],
                [
                    317,
                    "San Genaro",
                    21,
                    0,
                    1681778613
                ],
                [
                    318,
                    "San Guillermo",
                    21,
                    0,
                    1681778613
                ],
                [
                    319,
                    "San Isidro",
                    1,
                    0,
                    1681778613
                ],
                [
                    320,
                    "San Jorge",
                    21,
                    0,
                    1681778613
                ],
                [
                    321,
                    "San Juan",
                    18,
                    0,
                    1681778613
                ],
                [
                    322,
                    "San Justo",
                    1,
                    0,
                    1681778613
                ],
                [
                    323,
                    "San Justo",
                    22,
                    0,
                    1681778613
                ],
                [
                    324,
                    "San Luis",
                    19,
                    0,
                    1681778613
                ],
                [
                    325,
                    "San Manuel",
                    1,
                    0,
                    1681778613
                ],
                [
                    326,
                    "San Manuel",
                    22,
                    0,
                    1681778613
                ],
                [
                    327,
                    "San Miguel",
                    11,
                    0,
                    1681778613
                ],
                [
                    328,
                    "San Miguel Del Monte",
                    1,
                    0,
                    1681778613
                ],
                [
                    329,
                    "San Pedro",
                    1,
                    0,
                    1681778613
                ],
                [
                    330,
                    "San Rafael",
                    13,
                    0,
                    1681778613
                ],
                [
                    331,
                    "San Ricardo",
                    21,
                    0,
                    1681778613
                ],
                [
                    332,
                    "San Telmo",
                    7,
                    0,
                    1681778613
                ],
                [
                    333,
                    "San Vicente",
                    1,
                    0,
                    1681778613
                ],
                [
                    334,
                    "Sanford",
                    21,
                    0,
                    1681778613
                ],
                [
                    335,
                    "Santa Cruz",
                    22,
                    0,
                    1681778613
                ],
                [
                    336,
                    "Santa Fe",
                    21,
                    0,
                    1681778613
                ],
                [
                    337,
                    "Santa Rosa",
                    11,
                    0,
                    1681778613
                ],
                [
                    338,
                    "Santa Rosa De Calamuchita",
                    5,
                    0,
                    1681778613
                ],
                [
                    339,
                    "Santa Teresita",
                    1,
                    0,
                    1681778613
                ],
                [
                    340,
                    "Santiago Del Estero",
                    22,
                    0,
                    1681778613
                ],
                [
                    341,
                    "Santos Lugares",
                    22,
                    0,
                    1681778613
                ],
                [
                    342,
                    "Sarmiento",
                    21,
                    0,
                    1681778613
                ],
                [
                    343,
                    "Suipacha",
                    1,
                    0,
                    1681778613
                ],
                [
                    344,
                    "Sunchales",
                    21,
                    0,
                    1681778613
                ],
                [
                    345,
                    "Tablada",
                    1,
                    0,
                    1681778613
                ],
                [
                    346,
                    "Tandil",
                    1,
                    0,
                    1681778613
                ],
                [
                    347,
                    "Temperley",
                    1,
                    0,
                    1681778613
                ],
                [
                    348,
                    "Tigre",
                    1,
                    0,
                    1681778613
                ],
                [
                    349,
                    "Todd",
                    1,
                    0,
                    1681778613
                ],
                [
                    350,
                    "Tornquist",
                    1,
                    0,
                    1681778613
                ],
                [
                    351,
                    "Tortuguitas",
                    1,
                    0,
                    1681778613
                ],
                [
                    352,
                    "Trelew",
                    4,
                    0,
                    1681778613
                ],
                [
                    353,
                    "Trenque Lauquen",
                    1,
                    0,
                    1681778613
                ],
                [
                    354,
                    "Tres Arroyos",
                    1,
                    0,
                    1681778613
                ],
                [
                    355,
                    "Turdera",
                    1,
                    0,
                    1681778613
                ],
                [
                    356,
                    "Ucacha",
                    5,
                    0,
                    1681778613
                ],
                [
                    357,
                    "Ushuaia",
                    23,
                    0,
                    1681778613
                ],
                [
                    358,
                    "Valle Hermoso",
                    5,
                    0,
                    1681778613
                ],
                [
                    359,
                    "Varela",
                    7,
                    0,
                    1681778613
                ],
                [
                    360,
                    "Venado Tuerto",
                    21,
                    0,
                    1681778613
                ],
                [
                    361,
                    "Victoria",
                    8,
                    0,
                    1681778613
                ],
                [
                    362,
                    "Viedma",
                    16,
                    0,
                    1681778613
                ],
                [
                    363,
                    "Villa Aberastain",
                    18,
                    0,
                    1681778613
                ],
                [
                    364,
                    "Villa Adelina",
                    1,
                    0,
                    1681778613
                ],
                [
                    365,
                    "Villa Alianza",
                    1,
                    0,
                    1681778613
                ],
                [
                    366,
                    "Villa Ansaldi",
                    1,
                    0,
                    1681778613
                ],
                [
                    367,
                    "Villa Augusta",
                    1,
                    0,
                    1681778613
                ],
                [
                    368,
                    "Villa Ballester",
                    1,
                    0,
                    1681778613
                ],
                [
                    369,
                    "Villa Barilari",
                    1,
                    0,
                    1681778613
                ],
                [
                    370,
                    "Villa Basso",
                    1,
                    0,
                    1681778613
                ],
                [
                    371,
                    "Villa Carlos Paz",
                    5,
                    0,
                    1681778613
                ],
                [
                    372,
                    "Villa D. Sobral",
                    1,
                    0,
                    1681778613
                ],
                [
                    373,
                    "Villa De Los Patricios",
                    1,
                    0,
                    1681778613
                ],
                [
                    374,
                    "Villa De Mayo",
                    1,
                    0,
                    1681778613
                ],
                [
                    375,
                    "Villa Diamante",
                    1,
                    0,
                    1681778613
                ],
                [
                    376,
                    "Villa Dolores",
                    5,
                    0,
                    1681778613
                ],
                [
                    377,
                    "Villa Elisa",
                    1,
                    0,
                    1681778613
                ],
                [
                    378,
                    "Villa Gesell",
                    1,
                    0,
                    1681778613
                ],
                [
                    379,
                    "Villa Giambruno",
                    1,
                    0,
                    1681778613
                ],
                [
                    380,
                    "Villa Iglesias",
                    1,
                    0,
                    1681778613
                ],
                [
                    381,
                    "Villa La Angostura",
                    15,
                    0,
                    1681778613
                ],
                [
                    382,
                    "Villa Las Rosas",
                    5,
                    0,
                    1681778613
                ],
                [
                    383,
                    "Villa Luro",
                    7,
                    0,
                    1681778613
                ],
                [
                    384,
                    "Villa Luzuriaga",
                    1,
                    0,
                    1681778613
                ],
                [
                    385,
                    "Villa Madero",
                    1,
                    0,
                    1681778613
                ],
                [
                    386,
                    "Villa Nueva",
                    22,
                    0,
                    1681778613
                ],
                [
                    387,
                    "Villa Ocampo",
                    21,
                    0,
                    1681778613
                ],
                [
                    388,
                    "Villa Progreso",
                    1,
                    0,
                    1681778613
                ],
                [
                    389,
                    "Villa Recondo",
                    1,
                    0,
                    1681778613
                ],
                [
                    390,
                    "Villa Reichembach",
                    1,
                    0,
                    1681778613
                ],
                [
                    391,
                    "Villa Residencial Laguna Brava",
                    1,
                    0,
                    1681778613
                ],
                [
                    392,
                    "Villa Sarmiento",
                    1,
                    0,
                    1681778613
                ],
                [
                    393,
                    "Villaguay",
                    8,
                    0,
                    1681778613
                ],
                [
                    394,
                    "Virreyes",
                    1,
                    0,
                    1681778613
                ],
                [
                    395,
                    "Wilde",
                    1,
                    0,
                    1681778613
                ],
                [
                    396,
                    "Yerba Buena",
                    24,
                    0,
                    1681778613
                ],
                [
                    397,
                    "Yocsina",
                    5,
                    0,
                    1681778613
                ],
                [
                    398,
                    "Zapala",
                    15,
                    0,
                    1681778613
                ]
            ]
        },
        {
            "name": "control_embarazo",
            "schema": [
                {
                    "column": "id_control_embarazo",
                    "value": "INT PRIMARY KEY NOT NULL"
                },
                {
                    "column": "id_control",
                    "value": "INT  NOT NULL"
                },
                {
                    "column": "edad_gestacional",
                    "value": "INT  NOT NULL"
                },
                {
                    "column": "eco",
                    "value": "CHAR(1)  NOT NULL  COLLATE NOCASE"
                },
                {
                    "column": "detalle_eco",
                    "value": "TEXT  NOT NULL  COLLATE NOCASE"
                },
                {
                    "column": "hpv",
                    "value": "CHAR(1)  NOT NULL  COLLATE NOCASE"
                },
                {
                    "column": "pap",
                    "value": "CHAR(1)  NOT NULL  COLLATE NOCASE"
                },
                {
                    "column": "sistolica",
                    "value": "INT  NOT NULL"
                },
                {
                    "column": "diastolica",
                    "value": "INT  NOT NULL"
                },
                {
                    "column": "clinico",
                    "value": "CHAR(1)  NOT NULL  COLLATE NOCASE"
                },
                {
                    "column": "observaciones",
                    "value": "TEXT  NOT NULL  COLLATE NOCASE"
                },
                {
                    "column": "motivo",
                    "value": "INT  NULL DEFAULT NULL"
                },
                {
                    "column": "derivada",
                    "value": "SMALLINT  NOT NULL"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                },
                {
                    "constraint": "`control_embarazo_ibfk_1`",
                    "value": "FOREIGN KEY (`id_control`) REFERENCES controles (`id_control`) ON DELETE RESTRICT ON UPDATE RESTRICT"
                },
                {
                    "constraint": "`fk_motivo`",
                    "value": "FOREIGN KEY (`motivo`) REFERENCES motivos_derivacion (`id_motivo`) ON DELETE RESTRICT ON UPDATE RESTRICT"
                }
            ],
            "indexes": [
                {
                    "name": "id_control",
                    "value": "`id_control` DESC"
                },
                {
                    "name": "fk_motivo",
                    "value": "`motivo` DESC"
                }
            ],
            "triggers": [
                {
                    "name": "control_embarazo_trigger_last_modified",
                    "logic": "BEGIN      UPDATE control_embarazo SET last_modified= (strftime('%s', 'now')) WHERE id_control_embarazo=OLD.id_control_embarazo;  END",
                    "condition": " WHEN 1",
                    "timeevent": "AFTER UPDATE ON"
                }
            ],
           
        },
        {
            "name": "patologias_rn",
            "schema": [
                {
                    "column": "id_patologias_Rn",
                    "value": "INT PRIMARY KEY NOT NULL"
                },
                {
                    "column": "nombre",
                    "value": "VARCHAR(45)  NOT NULL  COLLATE NOCASE"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                }
            ],
            "values": [
                [
                    1,
                    "PREMATURO",
                    0,
                    1681778944
                ],
                [
                    2,
                    "RCIU",
                    0,
                    1681778944
                ],
                [
                    3,
                    "HIJO DE MADRE DBT",
                    0,
                    1681778944
                ],
                [
                    4,
                    "DEPRIMIDO NEONATAL",
                    0,
                    1681778944
                ],
                [
                    5,
                    "SOSPECHA DE SEPSIS",
                    0,
                    1681778944
                ],
                [
                    6,
                    "HIPERBILIRRUBINEMIA",
                    0,
                    1681778944
                ],
                [
                    7,
                    "BAJO PESO",
                    0,
                    1681778944
                ],
                [
                    8,
                    "HIPOTIROIDISMO CONGÉNITO",
                    0,
                    1681778944
                ],
                [
                    9,
                    "SÍNDROME GENÉTICO / MALFORMACIONES",
                    0,
                    1681778944
                ],
                [
                    10,
                    "LABIO LEPORINO",
                    0,
                    1681778944
                ]
            ]
        },
        {
            "name": "parajes",
            "schema": [
                {
                    "column": "id_paraje",
                    "value": "INT PRIMARY KEY NOT NULL"
                },
                {
                    "column": "id_area",
                    "value": "INT  NOT NULL"
                },
                {
                    "column": "nombre",
                    "value": "VARCHAR(100)  NOT NULL  COLLATE NOCASE"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                },
                {
                    "constraint": "`fk_areas`",
                    "value": "FOREIGN KEY (`id_area`) REFERENCES areas (`id_area`) ON DELETE RESTRICT ON UPDATE RESTRICT"
                }
            ],
            "indexes": [
                {
                    "name": "id_area",
                    "value": "`id_area` DESC"
                }
            ],
            "triggers": [
                {
                    "name": "parajes_derivacion_trigger_last_modified",
                    "logic": "BEGIN      UPDATE parajes SET last_modified= (strftime('%s', 'now')) WHERE id_paraje=OLD.id_paraje;  END",
                    "condition": "FOR EACH ROW WHEN NEW.last_modified < OLD.last_modified",
                    "timeevent": "AFTER UPDATE ON"
                }
            ],
            "values": [
                [
                    1,
                    1,
                    "CREVAUX",
                    0,
                    1681778922
                ],
                [
                    2,
                    1,
                    "D'ORBIGNI",
                    0,
                    1681778922
                ],
                [
                    3,
                    2,
                    "POZO HONDO",
                    0,
                    1681778922
                ],
                [
                    4,
                    2,
                    "SAN AGUSTÍN",
                    0,
                    1681778922
                ],
                [
                    7,
                    4,
                    "ALTO LA SIERRA",
                    0,
                    1681778922
                ],
                [
                    8,
                    4,
                    "PUESTO EL PANCHO",
                    0,
                    1681778922
                ],
                [
                    9,
                    4,
                    "EL TRAMPEADERO",
                    0,
                    1681778922
                ],
                [
                    10,
                    4,
                    "LA ESPERANZA",
                    0,
                    1681778922
                ],
                [
                    11,
                    4,
                    "POZO EL MULATO",
                    0,
                    1681778922
                ],
                [
                    12,
                    4,
                    "EL DESEMBOQUE",
                    0,
                    1681778922
                ],
                [
                    13,
                    4,
                    "POZO EL BRAVO",
                    0,
                    1681778922
                ],
                [
                    14,
                    4,
                    "LA JUNTA",
                    0,
                    1681778922
                ],
                [
                    15,
                    4,
                    "BAJO GRANDE",
                    0,
                    1681778922
                ],
                [
                    16,
                    4,
                    "AGUAS VERDES",
                    0,
                    1681778922
                ],
                [
                    17,
                    3,
                    "MAGDALENA",
                    0,
                    1681778922
                ],
                [
                    18,
                    3,
                    "NUEVA ESPERANZA LA PUNTANA",
                    0,
                    1681778922
                ],
                [
                    19,
                    3,
                    "TRES",
                    0,
                    1681778922
                ],
                [
                    20,
                    3,
                    "EL BORDO",
                    0,
                    1681778922
                ],
                [
                    21,
                    3,
                    "MONTE CARMELO",
                    0,
                    1681778922
                ],
                [
                    22,
                    3,
                    "LA CURVITA",
                    0,
                    1681778922
                ],
                [
                    23,
                    3,
                    "PADRE COLL",
                    0,
                    1681778922
                ],
                [
                    24,
                    3,
                    "SANTA MARÍA",
                    0,
                    1681778922
                ],
                [
                    25,
                    3,
                    "EL CRUCE",
                    0,
                    1681778922
                ],
                [
                    26,
                    3,
                    "MISIÓN AMBRICANA",
                    0,
                    1681778922
                ],
                [
                    27,
                    3,
                    "LA MERCED",
                    0,
                    1681778922
                ],
                [
                    28,
                    3,
                    "SAN LUIS",
                    0,
                    1681778922
                ],
                [
                    29,
                    3,
                    "POZO EL TIGRE",
                    0,
                    1681778922
                ],
                [
                    30,
                    3,
                    "SANTA VICTORIA",
                    0,
                    1681778922
                ],
                [
                    31,
                    3,
                    "EL CAÑAVERAL",
                    0,
                    1681778922
                ],
                [
                    32,
                    3,
                    "CAÑAVERAL B",
                    0,
                    1681778922
                ],
                [
                    33,
                    3,
                    "LA PISTA",
                    0,
                    1681778922
                ],
                [
                    34,
                    3,
                    "LA GRACIA",
                    0,
                    1681778922
                ],
                [
                    35,
                    3,
                    "LA ESTRELLA",
                    0,
                    1681778922
                ],
                [
                    36,
                    3,
                    "KILÓMETRO 2",
                    0,
                    1681778922
                ],
                [
                    37,
                    3,
                    "MISIÓN LA PAZ",
                    0,
                    1681778922
                ],
                [
                    38,
                    3,
                    "LA BOLSA",
                    0,
                    1681778922
                ],
                [
                    39,
                    3,
                    "VERTIENTE",
                    0,
                    1681778922
                ],
                [
                    40,
                    3,
                    "POZO EL TORO",
                    0,
                    1681778922
                ],
                [
                    41,
                    3,
                    "LA CHINA",
                    0,
                    1681778922
                ],
                [
                    42,
                    3,
                    "RANCHO ÑATO",
                    0,
                    1681778922
                ],
                [
                    43,
                    3,
                    "NUEVA ESPERANZA",
                    0,
                    1681778922
                ],
                [
                    44,
                    3,
                    "25 DE AGOSTO",
                    0,
                    1681778922
                ],
                [
                    45,
                    3,
                    "URUKUPIÑA",
                    0,
                    1681778922
                ]
            ]
        },
        {
            "name": "control_emb_patologico",
            "schema": [
                {
                    "column": "id_control_emb_patologico",
                    "value": "INT PRIMARY KEY NOT NULL"
                },
                {
                    "column": "id_control_embarazo",
                    "value": "INT  NOT NULL"
                },
                {
                    "column": "observaciones",
                    "value": "TEXT  NULL  COLLATE NOCASE"
                },
                {
                    "column": "derivacion",
                    "value": "SMALLINT  NULL DEFAULT NULL"
                },
                {
                    "column": "hospital",
                    "value": "TEXT  NULL  COLLATE NOCASE"
                },
                {
                    "column": "tratamientos",
                    "value": "SMALLINT  NULL DEFAULT NULL"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                },
                {
                    "constraint": "`fk_control_emb`",
                    "value": "FOREIGN KEY (`id_control_embarazo`) REFERENCES control_embarazo (`id_control_embarazo`) ON DELETE RESTRICT ON UPDATE RESTRICT"
                }
            ],
            "indexes": [
                {
                    "name": "id_control_embarazo",
                    "value": "`id_control_embarazo` DESC",
                    "mode": "UNIQUE"
                }
            ],
            "triggers": [
                {
                    "name": "control_emb_patologico_trigger_last_modified",
                    "logic": "BEGIN      UPDATE control_emb_patologico SET last_modified= (strftime('%s', 'now')) WHERE id_control_emb_patologico=OLD.id_control_emb_patologico;  END",
                    "condition": "FOR EACH ROW WHEN NEW.last_modified < OLD.last_modified",
                    "timeevent": "AFTER UPDATE ON"
                }
            ]
        },
        {
            "name": "control_puerperio",
            "schema": [
                {
                    "column": "id_control_puerperio",
                    "value": "INT PRIMARY KEY NOT NULL"
                },
                {
                    "column": "id_control",
                    "value": "INT  NOT NULL"
                },
                {
                    "column": "patologico",
                    "value": "SMALLINT  NOT NULL DEFAULT 0"
                },
                {
                    "column": "observaciones",
                    "value": "TEXT  NULL  COLLATE NOCASE"
                },
                {
                    "column": "derivacion",
                    "value": "TEXT  NULL  COLLATE NOCASE"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                },
                {
                    "constraint": "`fk_control_puer_control`",
                    "value": "FOREIGN KEY (`id_control`) REFERENCES controles (`id_control`) ON DELETE RESTRICT ON UPDATE RESTRICT"
                }
            ],
            "indexes": [
                {
                    "name": "id_control_puerperio",
                    "value": "`id_control_puerperio` DESC,`id_control` DESC",
                    "mode": "UNIQUE"
                },
                {
                    "name": "fk_control_puer_control",
                    "value": "`id_control` DESC"
                }
            ],
            "triggers": [
                {
                    "name": "control_puerperio_trigger_last_modified",
                    "logic": "BEGIN      UPDATE control_puerperio SET last_modified= (strftime('%s', 'now')) WHERE id_control_puerperio=OLD.id_control_puerperio;  END",
                    "condition": "FOR EACH ROW WHEN NEW.last_modified < OLD.last_modified",
                    "timeevent": "AFTER UPDATE ON"
                }
            ],
           
        },
        {
            "name": "control_rn",
            "schema": [
                {
                    "column": "id_control_rn",
                    "value": "INT PRIMARY KEY NOT NULL"
                },
                {
                    "column": "id_control",
                    "value": "INT  NOT NULL"
                },
                {
                    "column": "edad_gestacional",
                    "value": "INT  NOT NULL"
                },
                {
                    "column": "peso",
                    "value": "FLOAT(0,0)  NOT NULL"
                },
                {
                    "column": "labio_leporino",
                    "value": "SMALLINT  NOT NULL DEFAULT 0"
                },
                {
                    "column": "s_genetico_malformaciones",
                    "value": "SMALLINT  NOT NULL DEFAULT 0"
                },
                {
                    "column": "hipotiroidismo_congenito",
                    "value": "SMALLINT  NOT NULL DEFAULT 0"
                },
                {
                    "column": "bajo_peso",
                    "value": "SMALLINT  NOT NULL DEFAULT 0"
                },
                {
                    "column": "hiperbilirrubinemia",
                    "value": "SMALLINT  NOT NULL DEFAULT 0"
                },
                {
                    "column": "sospecha_de_sepsis",
                    "value": "SMALLINT  NOT NULL DEFAULT 0"
                },
                {
                    "column": "deprimido_neonatal",
                    "value": "SMALLINT  NOT NULL DEFAULT 0"
                },
                {
                    "column": "madre_dbt",
                    "value": "SMALLINT  NOT NULL DEFAULT 0"
                },
                {
                    "column": "prematuro",
                    "value": "SMALLINT  NOT NULL DEFAULT 0"
                },
                {
                    "column": "rciu",
                    "value": "SMALLINT  NOT NULL DEFAULT 0"
                },
                {
                    "column": "hijo_de_madre_dbt",
                    "value": "SMALLINT  NOT NULL DEFAULT 0"
                },
                {
                    "column": "derivacion",
                    "value": "TEXT  NULL  COLLATE NOCASE"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                },
                {
                    "constraint": "`fk_controlrn_control`",
                    "value": "FOREIGN KEY (`id_control`) REFERENCES controles (`id_control`) ON DELETE RESTRICT ON UPDATE RESTRICT"
                }
            ],
            "indexes": [
                {
                    "name": "id_control_rn",
                    "value": "`id_control_rn` DESC,`id_control` DESC",
                    "mode": "UNIQUE"
                },
                {
                    "name": "fk_controlrn_control",
                    "value": "`id_control` DESC"
                }
            ],
            "triggers": [
                {
                    "name": "control_rn_trigger_last_modified",
                    "logic": "BEGIN      UPDATE control_rn SET last_modified= (strftime('%s', 'now')) WHERE id_control_rn=OLD.id_control_rn;  END",
                    "condition": "FOR EACH ROW WHEN NEW.last_modified < OLD.last_modified",
                    "timeevent": "AFTER UPDATE ON"
                }
            ],
           
        },
        {
            "name": "tipos_embarazos",
            "schema": [
                {
                    "column": "id_tipo_embarazo",
                    "value": "INT PRIMARY KEY NOT NULL"
                },
                {
                    "column": "nombre",
                    "value": "VARCHAR(45)  NULL DEFAULT NULL COLLATE NOCASE"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                }
            ],
            "indexes": [
                {
                    "name": "nombre_UNIQUE",
                    "value": "`nombre` DESC",
                    "mode": "UNIQUE"
                }
            ],
            "values": [
                [
                    3,
                    "ETMI",
                    0,
                    1681779065
                ],
                [
                    1,
                    "NORMAL",
                    0,
                    1681779065
                ],
                [
                    2,
                    "PATOLÓGICO",
                    0,
                    1681779065
                ]
            ]
        },
        {
            "name": "sync",
            "schema": [
                {
                    "column": "id_sync",
                    "value": "INTEGER PRIMARY KEY AUTOINCREMENT NOT NULL"
                },
                {
                    "column": "query",
                    "value": "TEXT  NOT NULL  COLLATE NOCASE"
                },
                {
                    "column": "timestamp",
                    "value": "TIMESTAMP  NOT NULL"
                },
                {
                    "column": "disp1",
                    "value": "SMALLINT  NOT NULL DEFAULT 0"
                },
                {
                    "column": "disp2",
                    "value": "SMALLINT  NOT NULL DEFAULT 0"
                },
                {
                    "column": "disp3",
                    "value": "SMALLINT  NOT NULL DEFAULT 0"
                },
                {
                    "column": "disp4",
                    "value": "SMALLINT  NOT NULL DEFAULT 0"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                }
            ],
            "values": [
                [
                    6,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (247, 'DIAZ', 'CARINA GISELA', '42752261', '2000-08-28 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    7,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (389, '2022-03-28 10:20:03.000000', 247, 1, 1)",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    8,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (247, 247, 18, 3, '00', '2022-03-28 10:20:03.000000', '', 12)",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    9,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, planificado, fum, fpp) VALUES (207, 247, 389, 21, '2030-10-31 00:00:00.000000', 1, 1, '2021-12-27 00:00:00.000000', '2022-10-03 00:00:00.000000')",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    10,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (207, 4)",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    11,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (247, 389, 2, 'N')",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    12,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (247, 389, 3, 'C')",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    13,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (247, 389, 1, 'N')",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    14,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (247, 389, 4, 'N')",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    15,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (247, 389, 1, 1, '2022-03-28 10:25:21.000000', 3)",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    16,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (247, 389, 2, 1, '2022-03-28 10:25:21.000000', 2)",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    17,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (247, 389, 4, 1, '2022-03-28 10:25:21.000000', 1)",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    18,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (247, 389, 5, 1, '2022-03-28 10:25:21.000000', 4)",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (247, 389, 6, 1, '2022-03-28 10:25:21.000000')",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    20,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (247, 389, 9, 1, '2022-03-28 10:25:21.000000')",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    21,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (297, 389, 13, 'S', ' ', 'N', 'N', 100, 70, 'N', '')",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    22,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (248, 'VEGA', 'ROSELIA', '44017585', '1998-05-09 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    23,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (390, '2022-03-28 10:34:04.000000', 248, 1, 1)",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    24,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (248, 248, 18, 3, '00', '2022-03-28 10:34:04.000000', '', 12)",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    25,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (208, 248, 390, 22, '2021-01-31 00:00:00.000000', 2, 1, 1, '2021-12-24 00:00:00.000000', '2022-09-30 00:00:00.000000')",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    26,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (248, 390, 2, 'N')",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    27,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (248, 390, 3, 'N')",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    28,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (248, 390, 1, 'N')",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    29,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (248, 390, 4, 'N')",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    30,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (248, 390, 1, 1, '2022-03-28 10:43:05.000000', 3)",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    31,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (248, 390, 2, 1, '2022-03-28 10:43:05.000000', 2)",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    32,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (248, 390, 4, 1, '2022-03-28 10:43:05.000000', 1)",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (248, 390, 5, 1, '2022-03-28 10:43:05.000000', 4)",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    34,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (248, 390, 6, 1, '2022-03-28 10:43:05.000000')",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (248, 390, 9, 1, '2022-03-28 10:43:05.000000')",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    36,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (298, 390, 13, 'S', ' ', 'N', 'N', 90, 60, 'N', '')",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    37,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (391, '2022-03-28 10:54:25.000000', 215, 2, 1)",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    38,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (215, 391, 2, 'S')",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    39,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (215, 391, 3, 'C')",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (215, 391, 4, 'N')",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (215, 391, 1, 3, '2022-03-28 11:02:22.000000', 3)",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    42,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (215, 391, 2, 3, '2022-03-28 11:02:22.000000', 2)",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (215, 391, 5, 3, '2022-03-28 11:02:22.000000', 4)",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (215, 391, 6, 3, '2022-03-28 11:02:22.000000')",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (215, 391, 9, 3, '2022-03-28 11:02:22.000000')",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (299, 391, 35, 'N', ' ', 'N', 'N', 90, 70, 'N', '')",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    47,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (392, '2022-03-28 11:08:17.000000', 102, 3, 1)",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    48,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (102, 392, 2, 'S')",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    49,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (102, 392, 3, 'C')",
                    "2022-04-01 07:09:20",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    50,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (102, 392, 4, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    51,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (102, 392, 1, 3, '2022-03-28 11:12:23.000000', 3)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    52,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (102, 392, 2, 3, '2022-03-28 11:12:23.000000', 2)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    53,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (102, 392, 5, 3, '2022-03-28 11:12:23.000000', 4)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    54,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (102, 392, 6, 3, '2022-03-28 11:12:23.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    55,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (300, 392, 36, 'S', ' ', 'N', 'N', 100, 70, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    56,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (249, 'SEGUNDO', 'LIDIA KERI', '48690748', '2006-03-19 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    57,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (393, '2022-03-28 11:24:58.000000', 249, 1, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    58,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (249, 249, 18, 3, '00', '2022-03-28 11:24:58.000000', '', 12)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    59,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, cesareas, planificado, fum, fpp) VALUES (209, 249, 393, 13, '2020-09-20 00:00:00.000000', 2, 1, 0, '2021-08-26 00:00:00.000000', '2022-06-02 00:00:00.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    60,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (249, 393, 2, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    61,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (249, 393, 3, 'P')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    62,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (249, 393, 1, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    63,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (249, 393, 4, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    64,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (249, 393, 1, 3, '2022-03-28 11:33:35.000000', 3)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    65,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (249, 393, 2, 3, '2022-03-28 11:33:35.000000', 2)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    66,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (249, 393, 4, 3, '2022-03-28 11:33:35.000000', 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    67,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (249, 393, 5, 3, '2022-03-28 11:33:35.000000', 4)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    68,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (249, 393, 6, 3, '2022-03-28 11:33:35.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    69,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (249, 393, 9, 3, '2022-03-28 11:33:35.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    70,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (301, 393, 30, 'S', ' ', 'N', 'N', 90, 60, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    71,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (394, '2022-03-28 11:42:01.000000', 157, 2, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    72,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (157, 394, 2, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    73,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (157, 394, 3, 'C')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    74,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (157, 394, 4, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    75,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (302, 394, 25, 'S', ' ', 'N', 'N', 100, 70, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    76,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (250, 'SANDOBAL', 'LUISA', '44565541', '1988-11-28 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    77,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (395, '2022-03-28 11:49:39.000000', 250, 1, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    78,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (250, 250, 18, 3, '00', '2022-03-28 11:49:39.000000', '', 12)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    79,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, abortos, planificado, fum, fpp) VALUES (210, 250, 395, 0, 11, 7, 3, 0, '2021-09-16 00:00:00.000000', '2022-06-23 00:00:00.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    80,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (250, 395, 2, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    81,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (250, 395, 3, 'C')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    82,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (250, 395, 1, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    83,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (250, 395, 4, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    84,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (250, 395, 1, 2, '2022-03-28 11:56:34.000000', 3)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    85,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (250, 395, 2, 2, '2022-03-28 11:56:34.000000', 2)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    86,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (250, 395, 4, 2, '2022-03-28 11:56:34.000000', 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    87,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (250, 395, 5, 2, '2022-03-28 11:56:34.000000', 4)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    88,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (250, 395, 6, 2, '2022-03-28 11:56:34.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    89,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (250, 395, 7, 2, '2022-03-28 11:56:34.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    90,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (250, 395, 9, 2, '2022-03-28 11:56:34.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    91,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (303, 395, 27, 'S', ' ', 'N', 'N', 110, 70, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    92,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (251, 'SEGUNDO', 'CAMILA', '42079365', '1999-09-07 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    93,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (396, '2022-03-28 11:59:05.000000', 251, 1, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    94,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (251, 251, 18, 3, '00', '2022-03-28 11:59:05.000000', '', 12)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    95,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, planificado, fum, fpp) VALUES (211, 251, 396, 22, '2030-10-31 00:00:00.000000', 1, 0, '2021-12-26 00:00:00.000000', '2022-10-02 00:00:00.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    96,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (211, 3)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    97,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (251, 396, 2, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    98,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (251, 396, 3, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    99,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (251, 396, 1, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    100,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (251, 396, 4, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    101,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (251, 396, 1, 1, '2022-03-28 12:03:06.000000', 3)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    102,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (251, 396, 2, 1, '2022-03-28 12:03:06.000000', 2)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    103,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (251, 396, 4, 1, '2022-03-28 12:03:06.000000', 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    104,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (251, 396, 5, 1, '2022-03-28 12:03:06.000000', 4)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    105,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (251, 396, 6, 1, '2022-03-28 12:03:06.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    106,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (251, 396, 9, 1, '2022-03-28 12:03:06.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    107,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (304, 396, 13, 'S', ' ', 'N', 'N', 110, 70, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    108,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (252, 'PEREZ', 'MIGUELINA', '38651064', '1995-02-16 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    109,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (397, '2022-03-28 12:06:46.000000', 252, 1, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    110,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (252, 252, 18, 3, '00', '2022-03-28 12:06:46.000000', '', 12)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    111,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (212, 252, 397, 22, '2017-12-23 00:00:00.000000', 2, 1, 1, '2021-12-28 00:00:00.000000', '2022-10-04 00:00:00.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    112,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (252, 397, 2, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    113,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (252, 397, 3, 'P')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    114,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (252, 397, 1, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    115,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (252, 397, 4, 'P')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    116,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (252, 397, 1, 1, '2022-03-28 12:10:21.000000', 3)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    117,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (252, 397, 2, 1, '2022-03-28 12:10:21.000000', 2)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    118,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (252, 397, 4, 1, '2022-03-28 12:10:21.000000', 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    119,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (252, 397, 5, 1, '2022-03-28 12:10:21.000000', 4)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    120,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (252, 397, 6, 1, '2022-03-28 12:10:21.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    121,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (252, 397, 7, 1, '2022-03-28 12:10:21.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    122,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (252, 397, 9, 1, '2022-03-28 12:10:21.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    123,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (305, 397, 12, 'S', ' ', 'N', 'N', 120, 80, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    124,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (398, '2022-03-28 12:17:07.000000', 154, 2, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    125,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (154, 398, 2, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    126,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (154, 398, 3, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    127,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (154, 398, 4, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    128,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (306, 398, 26, 'S', ' ', 'N', 'N', 120, 80, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    129,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (399, '2022-03-28 12:22:39.000000', 103, 3, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    130,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (103, 399, 2, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    131,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (103, 399, 3, 'P')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    132,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (103, 399, 4, 'P')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    133,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (103, 399, 1, 3, '2022-03-28 12:27:03.000000', 3)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    134,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (103, 399, 2, 3, '2022-03-28 12:27:03.000000', 2)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    135,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (103, 399, 5, 3, '2022-03-28 12:27:03.000000', 4)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    136,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (103, 399, 6, 3, '2022-03-28 12:27:03.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    137,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (307, 399, 36, 'S', ' ', 'N', 'N', 150, 100, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    138,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (253, 'TORREZ', 'SIMONA SIXTA', '45114326', '3003-09-22 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    139,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (400, '2022-03-28 19:00:29.000000', 253, 1, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    140,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (253, 253, 24, 3, '00', '2022-03-28 19:00:29.000000', '', 12)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    141,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (213, 253, 400, 17, '2017-04-15 00:00:00.000000', 2, 1, 1, '2021-10-18 00:00:00.000000', '2022-07-25 00:00:00.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    142,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (253, 400, 2, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    143,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (253, 400, 3, 'C')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    144,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (253, 400, 1, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    145,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (253, 400, 4, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    146,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (253, 400, 1, 2, '2022-03-28 19:03:36.000000', 3)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    147,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (253, 400, 2, 2, '2022-03-28 19:03:36.000000', 2)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    148,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (253, 400, 4, 2, '2022-03-28 19:03:36.000000', 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    149,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (253, 400, 5, 2, '2022-03-28 19:03:36.000000', 4)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    150,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (253, 400, 6, 2, '2022-03-28 19:03:36.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    151,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (253, 400, 7, 2, '2022-03-28 19:03:36.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    152,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (253, 400, 9, 2, '2022-03-28 19:03:36.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    153,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (308, 400, 23, 'S', ' ', 'N', 'N', 110, 60, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    154,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (254, 'VILLAGRAN', 'CELSA VIRGINIA', '32570266', '1987-08-14 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    155,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (401, '2022-03-28 19:09:24.000000', 254, 1, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    156,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (254, 254, 24, 3, '00', '2022-03-28 19:09:24.000000', '', 12)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    157,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, planificado, fum, fpp) VALUES (214, 254, 401, 18, 6, 5, 1, '2022-11-20 00:00:00.000000', '2023-08-27 00:00:00.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    158,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (214, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    159,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (254, 401, 2, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    160,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (254, 401, 3, 'P')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    161,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (254, 401, 1, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    162,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (254, 401, 4, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    163,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (254, 401, 1, 3, '2022-03-28 19:12:07.000000', 3)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    164,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (254, 401, 2, 3, '2022-03-28 19:12:07.000000', 2)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    165,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (254, 401, 4, 3, '2022-03-28 19:12:07.000000', 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    166,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (254, 401, 5, 3, '2022-03-28 19:12:07.000000', 4)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    167,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (254, 401, 6, 3, '2022-03-28 19:12:07.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    168,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (254, 401, 7, 3, '2022-03-28 19:12:07.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    169,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (254, 401, 9, 3, '2022-03-28 19:12:07.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    170,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (309, 401, 33, 'S', ' ', 'N', 'N', 110, 70, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    171,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (402, '2022-03-28 19:15:03.000000', 100160, 2, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    172,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100160, 402, 2, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    173,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100160, 402, 3, 'P')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    174,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100160, 402, 4, 'C')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    175,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100160, 402, 1, 3, '2022-03-28 19:16:02.000000', 3)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    176,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100160, 402, 2, 3, '2022-03-28 19:16:02.000000', 2)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    177,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100160, 402, 5, 3, '2022-03-28 19:16:02.000000', 4)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    178,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (310, 402, 5252, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    179,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (255, 'GONZALEZ', 'GUADALUPE', '45852045', '2004-11-29 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    180,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (403, '2022-03-28 19:18:37.000000', 255, 1, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    181,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (255, 255, 24, 3, '00', '2022-03-28 19:18:37.000000', '', 12)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    182,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (404, '2022-03-28 19:26:31.000000', 100162, 3, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    183,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (256, 'DIAZ', 'JUANA', '32570201', '1986-10-10 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    184,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (405, '2022-03-28 19:37:18.000000', 256, 1, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    185,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (256, 256, 24, 3, '00', '2022-03-28 19:37:18.000000', '', 12)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    186,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, abortos, planificado, fum, fpp) VALUES (215, 256, 405, 0, '2019-09-23 00:00:00.000000', 9, 7, 1, 0, '2022-07-29 00:00:00.000000', '2023-05-05 00:00:00.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    187,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (256, 405, 2, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    188,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (256, 405, 3, 'C')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    189,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (256, 405, 1, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    190,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (256, 405, 4, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    191,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (256, 405, 1, 2, '2022-03-28 19:39:50.000000', 3)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    192,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (256, 405, 2, 2, '2022-03-28 19:39:50.000000', 2)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    193,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (256, 405, 4, 2, '2022-03-28 19:39:50.000000', 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    194,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (256, 405, 5, 2, '2022-03-28 19:39:50.000000', 4)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    195,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (256, 405, 6, 2, '2022-03-28 19:39:50.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    196,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (256, 405, 7, 2, '2022-03-28 19:39:50.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    197,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (256, 405, 9, 2, '2022-03-28 19:39:50.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    198,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (311, 405, 17, 'S', ' ', 'N', 'N', 110, 60, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    199,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (406, '2022-03-28 19:49:27.000000', 100163, 3, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    200,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100163, 406, 2, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    201,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100163, 406, 3, 'C')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    202,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100163, 406, 4, 'C')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    203,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (312, 406, 28, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    204,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (257, 'SUAREZ', 'MARISA MIRTA', '43836444', '2001-11-05 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    205,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (407, '2022-03-28 20:02:49.000000', 257, 1, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    206,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (257, 257, 24, 3, '00', '2022-03-28 20:02:49.000000', '', 12)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    207,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, planificado, fum, fpp) VALUES (216, 257, 407, 20, '2030-10-31 00:00:00.000000', 1, 1, '2021-07-31 00:00:00.000000', '2022-05-07 00:00:00.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    208,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (257, 407, 2, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    209,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (257, 407, 3, 'P')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    210,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (257, 407, 1, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    211,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (257, 407, 4, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    212,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (257, 407, 1, 3, '2022-03-28 20:06:54.000000', 3)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    213,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (257, 407, 2, 3, '2022-03-28 20:06:54.000000', 2)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    214,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (257, 407, 4, 3, '2022-03-28 20:06:54.000000', 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    215,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (257, 407, 5, 3, '2022-03-28 20:06:54.000000', 4)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    216,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (257, 407, 6, 3, '2022-03-28 20:06:54.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    217,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (257, 407, 7, 3, '2022-03-28 20:06:54.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    218,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (257, 407, 9, 3, '2022-03-28 20:06:54.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    219,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (313, 407, 34, 'S', ' ', 'N', 'N', 120, 60, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    220,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (408, '2022-03-28 20:09:38.000000', 100119, 4, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    221,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100119, 408, 2, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    222,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100119, 408, 3, 'P')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    223,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100119, 408, 4, 'P')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    224,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100119, 408, 1, 3, '2022-03-28 20:10:33.000000', 3)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    225,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100119, 408, 2, 3, '2022-03-28 20:10:33.000000', 2)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    226,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100119, 408, 5, 3, '2022-03-28 20:10:33.000000', 4)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    227,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (314, 408, 32, 'S', ' ', 'N', 'N', 120, 80, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    228,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (409, '2022-03-28 20:15:27.000000', 400006, 2, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    229,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (400006, 409, 2, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    230,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (400006, 409, 3, 'P')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    231,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (400006, 409, 4, 'C')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    232,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (315, 409, 29, 'S', ' ', 'N', 'N', 100, 70, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    233,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (410, '2022-03-28 20:26:14.000000', 100120, 4, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    234,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100120, 410, 2, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    235,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100120, 410, 3, 'P')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    236,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100120, 410, 4, 'C')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    237,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100120, 410, 1, 3, '2022-03-28 20:27:48.000000', 3)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    238,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100120, 410, 2, 3, '2022-03-28 20:27:48.000000', 2)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    239,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100120, 410, 5, 3, '2022-03-28 20:27:48.000000', 4)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    240,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100120, 410, 6, 3, '2022-03-28 20:27:48.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    241,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (316, 410, 35, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    242,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (258, 'MIRANDA', 'ANTONIA', '37418544', '1993-06-22 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    243,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (411, '2022-03-28 20:33:38.000000', 258, 1, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    244,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (258, 258, 24, 3, '000', '2022-03-28 20:33:38.000000', '', 12)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    245,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, abortos, planificado, fum, fpp) VALUES (217, 258, 411, 0, 7, 3, 3, 0, '2021-09-18 00:00:00.000000', '2022-06-25 00:00:00.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    246,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (258, 411, 2, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    247,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (258, 411, 3, 'C')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    248,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (258, 411, 1, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    249,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (258, 411, 4, 'C')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    250,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (258, 411, 1, 2, '2022-03-28 20:37:17.000000', 3)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    251,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (258, 411, 2, 2, '2022-03-28 20:37:17.000000', 2)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    252,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (258, 411, 4, 2, '2022-03-28 20:37:17.000000', 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    253,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (258, 411, 5, 2, '2022-03-28 20:37:17.000000', 4)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    254,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (258, 411, 6, 2, '2022-03-28 20:37:17.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    255,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (258, 411, 7, 2, '2022-03-28 20:37:17.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    256,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (317, 411, 27, 'S', ' ', 'N', 'N', 120, 80, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    257,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (412, '2022-03-28 20:43:02.000000', 100079, 2, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    258,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100079, 412, 2, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    259,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100079, 412, 3, 'P')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    260,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100079, 412, 4, 'P')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    261,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100079, 412, 1, 3, '2022-03-28 20:43:51.000000', 3)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    262,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100079, 412, 2, 3, '2022-03-28 20:43:51.000000', 2)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    263,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100079, 412, 5, 3, '2022-03-28 20:43:51.000000', 4)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    264,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100079, 412, 6, 3, '2022-03-28 20:43:51.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    265,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100079, 412, 7, 3, '2022-03-28 20:43:51.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    266,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (318, 412, 39, 'S', ' ', 'N', 'N', 120, 80, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    267,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (259, 'CELESTINO', 'LAURENCIA', '18899623', '1990-08-16 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    268,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (413, '2022-03-28 20:49:42.000000', 259, 1, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    269,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (259, 259, 24, 3, '00', '2022-03-28 20:49:42.000000', '', 12)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    270,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, abortos, planificado, fum, fpp) VALUES (218, 259, 413, 14, '2018-01-01 00:00:00.000000', 9, 7, 1, 0, '2021-10-22 00:00:00.000000', '2022-07-29 00:00:00.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    271,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (259, 413, 2, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    272,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (259, 413, 3, 'P')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    273,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (259, 413, 1, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    274,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (259, 413, 4, 'P')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    275,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (259, 413, 1, 2, '2022-03-28 20:57:15.000000', 3)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    276,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (259, 413, 2, 2, '2022-03-28 20:57:15.000000', 2)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    277,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (259, 413, 4, 2, '2022-03-28 20:57:15.000000', 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    278,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (259, 413, 5, 2, '2022-03-28 20:57:15.000000', 4)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    279,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (259, 413, 6, 2, '2022-03-28 20:57:15.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    280,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (259, 413, 7, 2, '2022-03-28 20:57:15.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    281,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (259, 413, 9, 2, '2022-03-28 20:57:15.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    282,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (319, 413, 22, 'S', ' ', 'N', 'N', 90, 60, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    283,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (414, '2022-03-29 09:38:32.000000', 100151, 3, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    284,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100151, 414, 2, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    285,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100151, 414, 3, 'P')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    286,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100151, 414, 4, 'P')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    287,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (320, 414, 21, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    288,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (260, 'PEREZ', 'DALMA ROSILDA', '38651029', '1995-01-01 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    289,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (415, '2022-03-29 09:44:49.000000', 260, 1, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    290,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (260, 260, 37, 3, '0', '2022-03-29 09:44:49.000000', '', 12)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    291,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (219, 260, 415, 18, '2020-12-24 00:00:00.000000', 4, 3, 0, '2021-11-14 00:00:00.000000', '2022-08-21 00:00:00.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    292,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (260, 415, 2, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    293,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (260, 415, 3, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    294,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (260, 415, 1, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    295,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (260, 415, 4, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    296,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (260, 415, 1, 2, '2022-03-29 09:49:31.000000', 3)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    297,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (260, 415, 2, 2, '2022-03-29 09:49:31.000000', 2)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    298,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (260, 415, 4, 2, '2022-03-29 09:49:31.000000', 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    299,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (260, 415, 5, 2, '2022-03-29 09:49:31.000000', 4)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    300,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (260, 415, 6, 2, '2022-03-29 09:49:31.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    301,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (260, 415, 7, 2, '2022-03-29 09:49:31.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    302,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (260, 415, 9, 2, '2022-03-29 09:49:31.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    303,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (321, 415, 19, 'S', ' ', 'N', 'N', 110, 70, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    304,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (416, '2022-03-29 09:51:23.000000', 100142, 3, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    305,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100142, 416, 2, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    306,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100142, 416, 3, 'C')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    307,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100142, 416, 4, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    308,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100142, 416, 1, 3, '2022-03-29 09:54:20.000000', 3)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    309,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100142, 416, 2, 3, '2022-03-29 09:54:20.000000', 2)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    310,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100142, 416, 5, 3, '2022-03-29 09:54:20.000000', 4)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    311,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100142, 416, 6, 3, '2022-03-29 09:54:20.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    312,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (322, 416, 33, 'S', ' ', 'N', 'N', 90, 60, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    313,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (261, 'CARRIZO', 'GEORGINA ESMERALDA', '39892177', '1997-10-08 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    314,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (417, '2022-03-29 10:06:13.000000', 261, 1, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    315,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (261, 261, 37, 3, '0', '2022-03-29 10:06:13.000000', '', 12)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    316,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (220, 261, 417, 15, '2018-06-04 00:00:00.000000', 3, 2, 0, '2021-11-10 00:00:00.000000', '2022-08-17 00:00:00.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    317,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (220, 4)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    318,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (261, 417, 2, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    319,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (261, 417, 3, 'C')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    320,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (261, 417, 1, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    321,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (261, 417, 4, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    322,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (261, 417, 1, 2, '2022-03-29 10:08:36.000000', 3)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    323,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (261, 417, 2, 2, '2022-03-29 10:08:36.000000', 2)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    324,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (261, 417, 4, 2, '2022-03-29 10:08:36.000000', 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    325,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (261, 417, 5, 2, '2022-03-29 10:08:36.000000', 4)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    326,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (261, 417, 6, 2, '2022-03-29 10:08:36.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    327,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (261, 417, 7, 2, '2022-03-29 10:08:36.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    328,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (261, 417, 9, 2, '2022-03-29 10:08:36.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    329,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (323, 417, 19, 'S', ' ', 'N', 'N', 80, 50, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    330,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (418, '2022-03-29 10:10:24.000000', 100141, 3, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    331,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100141, 418, 2, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    332,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100141, 418, 3, 'C')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    333,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100141, 418, 4, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    334,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100141, 418, 1, 3, '2022-03-29 10:11:40.000000', 3)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    335,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100141, 418, 2, 3, '2022-03-29 10:11:40.000000', 2)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    336,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (324, 418, 38, 'S', ' ', 'N', 'N', 100, 50, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    337,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (419, '2022-03-29 10:14:57.000000', 230, 2, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    338,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (230, 419, 2, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    339,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (230, 419, 3, 'C')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    340,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (230, 419, 4, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    341,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (230, 419, 1, 2, '2022-03-29 10:18:57.000000', 3)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    342,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (230, 419, 2, 2, '2022-03-29 10:18:57.000000', 2)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    343,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (230, 419, 6, 2, '2022-03-29 10:18:57.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    344,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (325, 419, 27, 'S', ' ', 'N', 'N', 90, 50, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    345,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (420, '2022-03-29 10:20:32.000000', 111, 4, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    346,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (111, 420, 2, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    347,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (111, 420, 3, 'P')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    348,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (111, 420, 4, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    349,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (111, 420, 1, 3, '2022-03-29 10:23:33.000000', 3)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    350,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (111, 420, 2, 3, '2022-03-29 10:23:33.000000', 2)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    351,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (111, 420, 5, 3, '2022-03-29 10:23:33.000000', 4)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    352,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (111, 420, 6, 3, '2022-03-29 10:23:33.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    353,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (326, 420, 39, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    354,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (262, 'MONTES', 'CARLA YASMIN', '43687193', '2001-09-24 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    355,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (421, '2022-03-29 10:26:38.000000', 262, 1, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    356,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (262, 262, 37, 3, '0', '2022-03-29 10:26:38.000000', '', 12)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    357,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, abortos, planificado, fum, fpp) VALUES (221, 262, 421, 14, '2019-08-28 00:00:00.000000', 4, 2, 1, 0, '2021-11-28 00:00:00.000000', '2022-09-04 00:00:00.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    358,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (262, 421, 2, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    359,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (262, 421, 3, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    360,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (262, 421, 1, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    361,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (262, 421, 4, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    362,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (262, 421, 1, 2, '2022-03-29 10:31:27.000000', 3)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    363,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (262, 421, 2, 2, '2022-03-29 10:31:27.000000', 2)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    364,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (262, 421, 4, 2, '2022-03-29 10:31:27.000000', 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    365,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (262, 421, 5, 2, '2022-03-29 10:31:27.000000', 4)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    366,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (262, 421, 6, 2, '2022-03-29 10:31:27.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    367,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (262, 421, 7, 2, '2022-03-29 10:31:27.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    368,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (262, 421, 9, 2, '2022-03-29 10:31:27.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    369,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (327, 421, 17, 'S', ' ', 'N', 'N', 80, 40, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    370,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (422, '2022-03-29 10:32:11.000000', 233, 2, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    371,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (233, 422, 2, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    372,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (233, 422, 3, 'C')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    373,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (233, 422, 4, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    374,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (233, 422, 1, 3, '2022-03-29 10:34:46.000000', 3)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    375,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (233, 422, 2, 3, '2022-03-29 10:34:46.000000', 2)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    376,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (233, 422, 5, 3, '2022-03-29 10:34:46.000000', 4)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    377,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (233, 422, 6, 3, '2022-03-29 10:34:46.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    378,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (328, 422, 36, 'S', ' ', 'N', 'N', 90, 60, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    379,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (423, '2022-03-29 10:35:48.000000', 232, 2, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    380,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (232, 423, 2, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    381,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (232, 423, 3, 'C')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    382,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (232, 423, 4, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    383,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (232, 423, 6, 2, '2022-03-29 10:39:07.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    384,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (329, 423, 23, 'S', ' ', 'N', 'N', 80, 40, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    385,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (424, '2022-03-29 10:40:41.000000', 100150, 3, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    386,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100150, 424, 2, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    387,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100150, 424, 3, 'C')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    388,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100150, 424, 4, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    389,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100150, 424, 1, 3, '2022-03-29 10:44:31.000000', 3)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    390,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100150, 424, 2, 3, '2022-03-29 10:44:31.000000', 2)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    391,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100150, 424, 5, 3, '2022-03-29 10:44:31.000000', 4)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    392,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100150, 424, 6, 3, '2022-03-29 10:44:31.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    393,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (330, 424, 31, 'S', ' ', 'N', 'N', 90, 50, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    394,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (425, '2022-03-29 10:48:35.000000', 231, 2, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    395,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (231, 425, 2, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    396,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (231, 425, 3, 'C')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    397,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (231, 425, 4, 'C')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    398,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (231, 425, 6, 2, '2022-03-29 10:50:14.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    399,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (331, 425, 23, 'S', ' ', 'N', 'N', 90, 50, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    400,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (263, 'MOLINA', 'LARISA', '47451650', '2006-10-07 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    401,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (426, '2022-03-29 10:55:07.000000', 263, 1, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    402,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (263, 263, 37, 3, '0', '2022-03-29 10:55:07.000000', '', 12)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    403,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (222, 263, 426, 0, 1, 0, '2021-11-05 00:00:00.000000', '2022-08-12 00:00:00.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    404,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (263, 426, 2, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    405,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (263, 426, 3, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    406,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (263, 426, 1, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    407,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (263, 426, 4, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    408,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (263, 426, 1, 2, '2022-03-29 10:57:37.000000', 3)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    409,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (263, 426, 2, 2, '2022-03-29 10:57:37.000000', 2)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    410,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (263, 426, 4, 2, '2022-03-29 10:57:37.000000', 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    411,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (263, 426, 5, 2, '2022-03-29 10:57:37.000000', 4)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    412,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (263, 426, 6, 2, '2022-03-29 10:57:37.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    413,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (263, 426, 7, 2, '2022-03-29 10:57:37.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    414,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (263, 426, 9, 2, '2022-03-29 10:57:37.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    415,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (332, 426, 20, 'S', ' ', 'N', 'N', 90, 50, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    416,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (427, '2022-03-29 10:59:50.000000', 235, 2, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    417,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (235, 427, 2, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    418,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (235, 427, 3, 'C')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    419,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (235, 427, 4, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    420,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (235, 427, 5, 2, '2022-03-29 11:02:26.000000', 4)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    421,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (235, 427, 6, 2, '2022-03-29 11:02:26.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    422,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (333, 427, 22, 'S', ' ', 'N', 'N', 90, 50, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    423,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (428, '2022-03-29 11:08:11.000000', 113, 4, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    424,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (113, 428, 2, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    425,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (113, 428, 3, 'C')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    426,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (113, 428, 4, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    427,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (113, 428, 1, 3, '2022-03-29 11:10:24.000000', 3)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    428,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (113, 428, 2, 3, '2022-03-29 11:10:24.000000', 2)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    429,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (113, 428, 4, 3, '2022-03-29 11:10:24.000000', 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    430,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (113, 428, 5, 3, '2022-03-29 11:10:24.000000', 4)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    431,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (113, 428, 6, 3, '2022-03-29 11:10:24.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    432,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (113, 428, 9, 3, '2022-03-29 11:10:24.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    433,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (334, 428, 32, 'S', ' ', 'N', 'N', 110, 60, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    434,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (264, 'GOMEZ', 'ROMINA', '37418525', '1993-04-04 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    435,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (429, '2022-03-29 11:25:40.000000', 264, 1, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    436,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (264, 264, 37, 3, '00', '2022-03-29 11:25:40.000000', '', 12)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    437,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (223, 264, 429, 14, '2013-12-26 00:00:00.000000', 3, 2, 1, '2021-12-08 00:00:00.000000', '2022-09-14 00:00:00.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    438,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (264, 429, 2, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    439,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (264, 429, 3, 'C')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    440,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (264, 429, 1, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    441,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (264, 429, 4, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    442,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (264, 429, 1, 2, '2022-03-29 11:31:16.000000', 3)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    443,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (264, 429, 2, 2, '2022-03-29 11:31:16.000000', 2)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    444,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (264, 429, 4, 2, '2022-03-29 11:31:16.000000', 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    445,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (264, 429, 5, 2, '2022-03-29 11:31:16.000000', 4)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    446,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (264, 429, 6, 2, '2022-03-29 11:31:16.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    447,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (264, 429, 9, 2, '2022-03-29 11:31:16.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    448,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (335, 429, 15, 'S', ' ', 'N', 'N', 100, 50, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    449,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (430, '2022-03-29 11:37:26.000000', 100170, 2, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    450,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100170, 430, 2, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    451,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100170, 430, 3, 'P')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    452,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100170, 430, 4, 'C')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    453,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100170, 430, 1, 3, '2022-03-29 11:38:32.000000', 3)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    454,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100170, 430, 2, 3, '2022-03-29 11:38:32.000000', 2)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    455,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100170, 430, 4, 3, '2022-03-29 11:38:32.000000', 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    456,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100170, 430, 5, 3, '2022-03-29 11:38:32.000000', 4)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    457,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100170, 430, 6, 3, '2022-03-29 11:38:32.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    458,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100170, 430, 9, 3, '2022-03-29 11:38:32.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    459,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (336, 430, 30, 'S', ' ', 'N', 'N', 120, 60, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    460,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (265, 'MARTINEZ', 'ALCIRA', '31393853', '1985-03-26 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    461,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (431, '2022-03-29 14:38:44.000000', 265, 1, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    462,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (265, 265, 29, 3, '00', '2022-03-29 14:38:44.000000', '', 12)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    463,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (224, 265, 431, 0, '2021-01-01 00:00:00.000000', 10, 9, 0, '2021-11-03 00:00:00.000000', '2022-08-10 00:00:00.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    464,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (265, 431, 2, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    465,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (265, 431, 3, 'P')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    466,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (265, 431, 1, 'N')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    467,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (265, 431, 4, 'C')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    468,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (265, 431, 1, 2, '2022-03-29 14:52:47.000000', 3)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    469,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (265, 431, 2, 2, '2022-03-29 14:52:47.000000', 2)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    470,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (265, 431, 4, 2, '2022-03-29 14:52:47.000000', 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    471,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (265, 431, 5, 2, '2022-03-29 14:52:47.000000', 4)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    472,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (265, 431, 6, 2, '2022-03-29 14:52:47.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    473,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (265, 431, 7, 2, '2022-03-29 14:52:47.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    474,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (265, 431, 9, 2, '2022-03-29 14:52:47.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    475,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (337, 431, 20, 'S', ' ', 'N', 'N', 90, 60, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    476,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (432, '2022-03-29 14:57:54.000000', 138, 3, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    477,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (138, 432, 2, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    478,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (138, 432, 3, 'P')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    479,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (138, 432, 4, 'P')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    480,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (138, 432, 1, 3, '2022-03-29 14:58:48.000000', 3)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    481,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (138, 432, 2, 3, '2022-03-29 14:58:48.000000', 2)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    482,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (138, 432, 4, 3, '2022-03-29 14:58:48.000000', 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    483,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (138, 432, 5, 3, '2022-03-29 14:58:48.000000', 4)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    484,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (138, 432, 6, 3, '2022-03-29 14:58:48.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    485,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (338, 432, 33, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    486,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (266, 'FRIAS', 'OLGA MARIA', '45262330', '2004-02-09 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    487,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (433, '2022-03-29 15:11:13.000000', 266, 1, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    488,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (266, 266, 29, 3, '00', '2022-03-29 15:11:13.000000', '', 12)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    489,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, planificado, fum, fpp) VALUES (225, 266, 433, 18, '2030-10-31 00:00:00.000000', 1, 0, '2021-08-25 00:00:00.000000', '2022-06-01 00:00:00.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    490,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (266, 433, 2, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    491,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (266, 433, 3, 'C')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    492,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (266, 433, 1, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    493,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (266, 433, 4, 'P')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    494,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (266, 433, 1, 3, '2022-03-29 15:16:07.000000', 3)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    495,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (266, 433, 2, 3, '2022-03-29 15:16:07.000000', 2)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    496,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (266, 433, 4, 3, '2022-03-29 15:16:07.000000', 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    497,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (266, 433, 5, 3, '2022-03-29 15:16:07.000000', 4)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    498,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (266, 433, 6, 3, '2022-03-29 15:16:07.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    499,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (266, 433, 9, 3, '2022-03-29 15:16:07.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    500,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (339, 433, 30, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    501,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (434, '2022-03-29 15:23:00.000000', 139, 4, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    502,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (139, 434, 2, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    503,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (139, 434, 3, 'P')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    504,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (139, 434, 4, 'P')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    505,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (139, 434, 1, 3, '2022-03-29 15:25:37.000000', 3)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    506,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (139, 434, 2, 3, '2022-03-29 15:25:37.000000', 2)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    507,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (139, 434, 4, 3, '2022-03-29 15:25:37.000000', 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    508,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (139, 434, 5, 3, '2022-03-29 15:25:37.000000', 4)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    509,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (139, 434, 6, 3, '2022-03-29 15:25:37.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    510,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (340, 434, 33, 'S', ' ', 'N', 'N', 120, 70, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    511,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (267, 'FERNANDEZ', 'EMILCE', '40709423', '1984-08-06 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    512,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (435, '2022-03-30 10:06:02.000000', 267, 1, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    513,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (267, 267, 22, 3, '00', '2022-03-30 10:06:02.000000', '', 12)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    514,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, abortos, planificado, fum, fpp) VALUES (226, 267, 435, 12, '2015-01-01 00:00:00.000000', 6, 3, 2, 0, '2021-10-12 00:00:00.000000', '2022-07-19 00:00:00.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    515,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (267, 435, 2, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    516,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (267, 435, 3, 'P')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    517,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (267, 435, 1, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    518,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (267, 435, 4, 'P')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    519,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (267, 435, 6, 2, '2022-03-30 10:12:53.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    520,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (267, 435, 9, 2, '2022-03-30 10:12:53.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    521,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (341, 435, 24, 'S', ' ', 'N', 'N', 140, 90, 'N', '')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    522,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (268, 'SEGUNDO', 'JOHANA', '38272903', '2021-06-17 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    523,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (436, '2022-03-30 10:20:54.000000', 268, 1, 1)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    524,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (268, 268, 22, 3, '00', '2022-03-30 10:20:54.000000', '', 12)",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    525,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, planificado, fum, fpp) VALUES (227, 268, 436, 0, 3, 2, 1, '2021-06-17 00:00:00.000000', '2022-03-24 00:00:00.000000')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    526,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (268, 436, 2, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    527,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (268, 436, 3, 'P')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    528,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (268, 436, 1, 'S')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    529,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (268, 436, 4, 'P')",
                    "2022-04-01 07:09:21",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    530,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (268, 436, 1, 3, '2022-03-30 10:27:08.000000', 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    531,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (268, 436, 2, 3, '2022-03-30 10:27:08.000000', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    532,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (268, 436, 5, 3, '2022-03-30 10:27:08.000000', 4)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    533,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (268, 436, 6, 3, '2022-03-30 10:27:08.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    534,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (268, 436, 9, 3, '2022-03-30 10:27:08.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    535,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (342, 436, 40, 'S', ' ', 'N', 'N', 120, 70, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    536,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (269, 'FERNANDEZ', 'MARTINA', '40709425', '1994-07-11 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    537,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (437, '2022-03-30 10:32:56.000000', 269, 1, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    538,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (269, 269, 22, 3, '00', '2022-03-30 10:32:56.000000', '', 12)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    539,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, planificado, fum, fpp) VALUES (228, 269, 437, 19, 4, 3, 1, '2021-12-31 00:00:00.000000', '2022-10-07 00:00:00.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    540,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (269, 437, 2, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    541,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (269, 437, 3, 'P')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    542,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (269, 437, 1, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    543,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (269, 437, 4, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    544,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (269, 437, 1, 1, '2022-03-30 10:43:40.000000', 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    545,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (269, 437, 2, 1, '2022-03-30 10:43:40.000000', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    546,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (269, 437, 4, 1, '2022-03-30 10:43:40.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    547,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (269, 437, 5, 1, '2022-03-30 10:43:40.000000', 4)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    548,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (269, 437, 6, 1, '2022-03-30 10:43:40.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    549,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (269, 437, 9, 1, '2022-03-30 10:43:40.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    550,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (343, 437, 12, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    551,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (270, 'OLGA', 'SEGUNDO', '46666304', '2005-12-19 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    552,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (438, '2022-03-30 10:50:37.000000', 270, 1, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    553,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (270, 270, 22, 3, '00', '2022-03-30 10:50:37.000000', '', 12)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    554,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, planificado, fum, fpp) VALUES (229, 270, 438, 16, '2030-10-31 00:00:00.000000', 1, 1, '2021-12-18 00:00:00.000000', '2022-09-24 00:00:00.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    555,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (270, 438, 2, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    556,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (270, 438, 3, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    557,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (270, 438, 1, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    558,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (270, 438, 4, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    559,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (270, 438, 1, 2, '2022-03-30 10:54:49.000000', 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    560,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (270, 438, 2, 2, '2022-03-30 10:54:49.000000', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    561,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (270, 438, 4, 2, '2022-03-30 10:54:49.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    562,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (270, 438, 5, 2, '2022-03-30 10:54:49.000000', 4)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    563,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (270, 438, 6, 2, '2022-03-30 10:54:49.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    564,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (270, 438, 9, 2, '2022-03-30 10:54:49.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    565,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (344, 438, 14, 'S', ' ', 'N', 'N', 110, 60, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    566,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (439, '2022-03-30 11:20:41.000000', 400002, 2, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    567,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (400002, 439, 2, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    568,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (400002, 439, 3, 'P')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    569,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (400002, 439, 4, 'P')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    570,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (400002, 439, 1, 3, '2022-03-30 11:23:54.000000', 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    571,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (400002, 439, 2, 3, '2022-03-30 11:23:54.000000', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    572,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (400002, 439, 5, 3, '2022-03-30 11:23:54.000000', 4)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    573,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (400002, 439, 6, 3, '2022-03-30 11:23:54.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    574,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (345, 439, 39, 'S', ' ', 'N', 'N', 90, 50, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    575,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (271, 'CONSTANTINO', 'ELENA', '39781899', '1994-06-20 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    576,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (440, '2022-03-30 11:45:51.000000', 271, 1, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    577,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (271, 271, 22, 3, '00', '2022-03-30 11:45:51.000000', '', 12)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    578,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, planificado, fum, fpp) VALUES (230, 271, 440, 11, 6, 5, 1, '2021-12-24 00:00:00.000000', '2022-09-30 00:00:00.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    579,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (271, 440, 2, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    580,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (271, 440, 3, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    581,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (271, 440, 1, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    582,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (271, 440, 4, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    583,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (271, 440, 1, 1, '2022-03-30 11:53:10.000000', 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    584,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (271, 440, 2, 1, '2022-03-30 11:53:10.000000', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    585,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (271, 440, 4, 1, '2022-03-30 11:53:10.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    586,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (271, 440, 5, 1, '2022-03-30 11:53:10.000000', 4)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    587,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (271, 440, 6, 1, '2022-03-30 11:53:10.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    588,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (271, 440, 9, 1, '2022-03-30 11:53:10.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    589,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (346, 440, 13, 'S', ' ', 'N', 'N', 90, 50, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    590,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (272, 'PEREZ', 'SOLEDAD CANDELARIA', '45551817', '2004-08-28 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    591,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (441, '2022-03-30 12:01:30.000000', 272, 1, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    592,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (272, 272, 22, 3, '00', '2022-03-30 12:01:30.000000', '', 12)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    593,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, planificado, fum, fpp) VALUES (231, 272, 441, 17, '2030-10-31 00:00:00.000000', 1, 1, '2021-11-05 00:00:00.000000', '2022-08-12 00:00:00.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    594,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (272, 441, 2, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    595,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (272, 441, 3, 'C')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    596,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (272, 441, 1, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    597,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (272, 441, 4, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    598,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (272, 441, 1, 2, '2022-03-30 12:07:41.000000', '2022-03-30 12:07:41.000000', 'N', 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    599,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (272, 441, 2, 2, '2022-03-30 12:07:41.000000', '2022-03-30 12:07:41.000000', 'N', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    600,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (272, 441, 4, 2, '2022-03-30 12:07:41.000000', '2022-03-30 12:07:41.000000', 'N', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    601,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (272, 441, 5, 2, '2022-03-30 12:07:41.000000', '2022-03-30 12:07:41.000000', 'N', 4)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    602,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (272, 441, 6, 2, '2022-03-30 12:07:41.000000', '2022-03-30 12:07:41.000000', '10')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    603,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (272, 441, 7, 2, '2022-03-30 12:07:41.000000', '2022-03-30 12:07:41.000000', '67')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    604,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (272, 441, 9, 2, '2022-03-30 12:07:41.000000', '2022-03-30 12:07:41.000000', '0+')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    605,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (347, 441, 20, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    606,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (273, 'SEGUNDO', 'CATHERIN', '45262361', '2004-03-07 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    607,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (442, '2022-03-30 12:16:11.000000', 273, 1, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    608,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (273, 273, 22, 3, '00', '2022-03-30 12:16:11.000000', '', 12)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    609,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (232, 273, 442, 17, '2021-01-26 00:00:00.000000', 2, 1, 1, '2021-08-06 00:00:00.000000', '2022-05-13 00:00:00.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    610,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (273, 442, 2, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    611,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (273, 442, 3, 'P')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    612,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (273, 442, 1, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    613,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (273, 442, 4, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    614,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (273, 442, 1, 3, '2022-03-30 12:30:23.000000', 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    615,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (273, 442, 2, 3, '2022-03-30 12:30:23.000000', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    616,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (273, 442, 4, 3, '2022-03-30 12:30:23.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    617,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (273, 442, 5, 3, '2022-03-30 12:30:23.000000', 4)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    618,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (273, 442, 6, 3, '2022-03-30 12:30:23.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    619,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (273, 442, 9, 3, '2022-03-30 12:30:23.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    620,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (348, 442, 33, 'S', ' ', 'N', 'N', 90, 50, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    621,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=297",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    622,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=247 AND laboratorios_realizados.id_control=389 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    623,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=247 AND laboratorios_realizados.id_control=389 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    624,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (247, 389, 19, 1, '2022-03-30 15:53:05.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    625,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (247, 389, 20, 1, '2022-03-30 15:53:05.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    626,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (247, 389, 23, 1, '2022-03-30 15:53:05.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    627,
                    "INSERT INTO etmis_personas (id_persona, id_etmi, id_control) VALUES (247, 1, 389)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    628,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='P' WHERE laboratorios_realizados.id_persona=247 AND laboratorios_realizados.id_control=389 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    629,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=247 AND laboratorios_realizados.id_control=389 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    630,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='12' WHERE laboratorios_realizados.id_persona=247 AND laboratorios_realizados.id_control=389 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    631,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='0+' WHERE laboratorios_realizados.id_persona=247 AND laboratorios_realizados.id_control=389 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    632,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=298",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    633,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=248 AND laboratorios_realizados.id_control=390 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    634,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=248 AND laboratorios_realizados.id_control=390 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    635,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=248 AND laboratorios_realizados.id_control=390 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    636,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=248 AND laboratorios_realizados.id_control=390 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    637,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='11' WHERE laboratorios_realizados.id_persona=248 AND laboratorios_realizados.id_control=390 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    638,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='0+' WHERE laboratorios_realizados.id_persona=248 AND laboratorios_realizados.id_control=390 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    639,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=215 AND laboratorios_realizados.id_control=391 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    640,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=215 AND laboratorios_realizados.id_control=391 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    641,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=215 AND laboratorios_realizados.id_control=391 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    642,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='9' WHERE laboratorios_realizados.id_persona=215 AND laboratorios_realizados.id_control=391 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    643,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='0+' WHERE laboratorios_realizados.id_persona=215 AND laboratorios_realizados.id_control=391 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    644,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=301",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    645,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=249 AND laboratorios_realizados.id_control=393 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    646,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=249 AND laboratorios_realizados.id_control=393 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    647,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=249 AND laboratorios_realizados.id_control=393 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    648,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=249 AND laboratorios_realizados.id_control=393 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    649,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='11' WHERE laboratorios_realizados.id_persona=249 AND laboratorios_realizados.id_control=393 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    650,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='0+' WHERE laboratorios_realizados.id_persona=249 AND laboratorios_realizados.id_control=393 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    651,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=300",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    652,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=102 AND laboratorios_realizados.id_control=392 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    653,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=102 AND laboratorios_realizados.id_control=392 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    654,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=102 AND laboratorios_realizados.id_control=392 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    655,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='8' WHERE laboratorios_realizados.id_persona=102 AND laboratorios_realizados.id_control=392 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    656,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=303",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    657,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=250 AND laboratorios_realizados.id_control=395 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    658,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=250 AND laboratorios_realizados.id_control=395 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    659,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=250 AND laboratorios_realizados.id_control=395 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    660,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=250 AND laboratorios_realizados.id_control=395 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    661,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='11' WHERE laboratorios_realizados.id_persona=250 AND laboratorios_realizados.id_control=395 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    662,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='79' WHERE laboratorios_realizados.id_persona=250 AND laboratorios_realizados.id_control=395 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    663,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=306",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    664,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=304",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    665,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=251 AND laboratorios_realizados.id_control=396 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    666,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=251 AND laboratorios_realizados.id_control=396 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    667,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=251 AND laboratorios_realizados.id_control=396 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    668,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=251 AND laboratorios_realizados.id_control=396 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    669,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='10' WHERE laboratorios_realizados.id_persona=251 AND laboratorios_realizados.id_control=396 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    670,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='0+' WHERE laboratorios_realizados.id_persona=251 AND laboratorios_realizados.id_control=396 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    671,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=307",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    672,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=103 AND laboratorios_realizados.id_control=399 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    673,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=103 AND laboratorios_realizados.id_control=399 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    674,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=103 AND laboratorios_realizados.id_control=399 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    675,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='12' WHERE laboratorios_realizados.id_persona=103 AND laboratorios_realizados.id_control=399 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    676,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=305",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    677,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=252 AND laboratorios_realizados.id_control=397 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    678,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=252 AND laboratorios_realizados.id_control=397 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    679,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=252 AND laboratorios_realizados.id_control=397 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    680,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='10' WHERE laboratorios_realizados.id_persona=252 AND laboratorios_realizados.id_control=397 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    681,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='81' WHERE laboratorios_realizados.id_persona=252 AND laboratorios_realizados.id_control=397 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    682,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='0+' WHERE laboratorios_realizados.id_persona=252 AND laboratorios_realizados.id_control=397 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    683,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=252 AND laboratorios_realizados.id_control=397 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    684,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=302",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    685,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='9.3' WHERE laboratorios_realizados.id_persona=200029 AND laboratorios_realizados.id_control=200042 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    686,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (274, 'AYLAN', 'JUANA PASTORA', '39215718', '1995-10-24 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    687,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (443, '2022-03-30 16:34:24.000000', 274, 1, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    688,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (274, 274, 42, 3, '00', '2022-03-30 16:34:24.000000', '', 12)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    689,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, planificado, fum, fpp) VALUES (233, 274, 443, 0, 3, 2, 1, '2021-09-25 00:00:00.000000', '2022-07-02 00:00:00.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    690,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (274, 443, 2, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    691,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (274, 443, 3, 'P')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    692,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (274, 443, 1, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    693,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (274, 443, 4, 'P')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    694,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (274, 443, 1, 2, '2022-03-30 16:43:09.000000', 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    695,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (274, 443, 2, 2, '2022-03-30 16:43:09.000000', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    696,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (274, 443, 4, 2, '2022-03-30 16:43:09.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    697,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (274, 443, 5, 2, '2022-03-30 16:43:09.000000', 4)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    698,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (274, 443, 6, 2, '2022-03-30 16:43:09.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    699,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (274, 443, 9, 2, '2022-03-30 16:43:09.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    700,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (349, 443, 26, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    701,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (275, 'CHICO', 'RAQUEL', '45114335', '2003-11-03 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    702,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (444, '2022-03-30 17:03:26.000000', 275, 1, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    703,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (275, 275, 30, 3, '0', '2022-03-30 17:03:26.000000', '', 12)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    704,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (234, 275, 444, 0, 1, 0, '2022-01-30 00:00:00.000000', '2022-11-06 00:00:00.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    705,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (275, 444, 2, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    706,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (275, 444, 3, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    707,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (275, 444, 1, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    708,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (275, 444, 4, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    709,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (275, 444, 1, 1, '2022-03-30 17:06:18.000000', 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    710,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (275, 444, 2, 1, '2022-03-30 17:06:18.000000', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    711,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (275, 444, 4, 1, '2022-03-30 17:06:18.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    712,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (275, 444, 5, 1, '2022-03-30 17:06:18.000000', 4)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    713,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (275, 444, 6, 1, '2022-03-30 17:06:18.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    714,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (275, 444, 9, 1, '2022-03-30 17:06:18.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    715,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (350, 444, 8, 'S', ' ', 'N', 'N', 90, 60, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    716,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=342",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    717,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=268 AND laboratorios_realizados.id_control=436 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    718,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=268 AND laboratorios_realizados.id_control=436 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    719,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=268 AND laboratorios_realizados.id_control=436 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    720,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='8.6' WHERE laboratorios_realizados.id_persona=268 AND laboratorios_realizados.id_control=436 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    721,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='0+' WHERE laboratorios_realizados.id_persona=268 AND laboratorios_realizados.id_control=436 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    722,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=341",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    723,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='6' WHERE laboratorios_realizados.id_persona=267 AND laboratorios_realizados.id_control=435 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    724,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='0+' WHERE laboratorios_realizados.id_persona=267 AND laboratorios_realizados.id_control=435 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    725,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=343",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    726,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=269 AND laboratorios_realizados.id_control=437 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    727,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=269 AND laboratorios_realizados.id_control=437 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    728,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=269 AND laboratorios_realizados.id_control=437 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    729,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='8.6' WHERE laboratorios_realizados.id_persona=269 AND laboratorios_realizados.id_control=437 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    730,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='0+' WHERE laboratorios_realizados.id_persona=269 AND laboratorios_realizados.id_control=437 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    731,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=344",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    732,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=270 AND laboratorios_realizados.id_control=438 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    733,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=270 AND laboratorios_realizados.id_control=438 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    734,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=270 AND laboratorios_realizados.id_control=438 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    735,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=270 AND laboratorios_realizados.id_control=438 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    736,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='10.6' WHERE laboratorios_realizados.id_persona=270 AND laboratorios_realizados.id_control=438 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    737,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='0+' WHERE laboratorios_realizados.id_persona=270 AND laboratorios_realizados.id_control=438 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    738,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=350",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    739,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=275 AND laboratorios_realizados.id_control=444 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    740,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=275 AND laboratorios_realizados.id_control=444 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    741,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=275 AND laboratorios_realizados.id_control=444 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    742,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=275 AND laboratorios_realizados.id_control=444 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    743,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='0+' WHERE laboratorios_realizados.id_persona=275 AND laboratorios_realizados.id_control=444 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    744,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (276, 'SERRANO', 'KAREN', '49784149', '2006-01-06 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    745,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (445, '2022-03-30 17:31:38.000000', 276, 1, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    746,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (276, 276, 31, 3, '00', '2022-03-30 17:31:38.000000', '', 12)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    747,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (235, 276, 445, 14, '2020-12-04 00:00:00.000000', 2, 1, 0, '2021-12-01 00:00:00.000000', '2022-09-07 00:00:00.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    748,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (235, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    749,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (276, 445, 2, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    750,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (276, 445, 3, 'C')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    751,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (276, 445, 1, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    752,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (276, 445, 4, 'P')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    753,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (276, 445, 1, 2, '2022-03-30 17:36:27.000000', 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    754,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (276, 445, 2, 2, '2022-03-30 17:36:27.000000', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    755,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (276, 445, 4, 2, '2022-03-30 17:36:27.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    756,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (276, 445, 5, 2, '2022-03-30 17:36:27.000000', 4)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    757,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (276, 445, 6, 2, '2022-03-30 17:36:27.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    758,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (276, 445, 9, 2, '2022-03-30 17:36:27.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    759,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (351, 445, 17, 'S', ' ', 'N', 'N', 90, 60, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    760,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (277, 'MARCIA', 'LUNA', '39215738', '1995-12-01 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    761,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (446, '2022-03-30 17:44:04.000000', 277, 1, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    762,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (277, 277, 31, 3, '00', '2022-03-30 17:44:04.000000', '', 12)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    763,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, abortos, planificado, fum, fpp) VALUES (236, 277, 446, 16, '2020-03-17 00:00:00.000000', 5, 3, 1, 0, '2021-11-26 00:00:00.000000', '2022-09-02 00:00:00.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    764,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (236, 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    765,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (277, 446, 2, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    766,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (277, 446, 3, 'C')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    767,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (277, 446, 1, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    768,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (277, 446, 4, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    769,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (277, 446, 1, 2, '2022-03-30 17:49:04.000000', 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    770,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (277, 446, 2, 2, '2022-03-30 17:49:04.000000', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    771,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (277, 446, 4, 2, '2022-03-30 17:49:04.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    772,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (277, 446, 5, 2, '2022-03-30 17:49:04.000000', 4)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    773,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (277, 446, 6, 2, '2022-03-30 17:49:04.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    774,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (277, 446, 9, 2, '2022-03-30 17:49:04.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    775,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (352, 446, 17, 'S', ' ', 'N', 'N', 110, 70, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    776,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (447, '2022-03-30 17:52:54.000000', 100137, 2, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    777,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100137, 447, 2, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    778,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100137, 447, 3, 'P')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    779,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100137, 447, 4, 'C')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    780,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100137, 447, 1, 3, '2022-03-30 17:55:52.000000', 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    781,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100137, 447, 2, 3, '2022-03-30 17:55:52.000000', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    782,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100137, 447, 6, 3, '2022-03-30 17:55:52.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    783,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (353, 447, 38, 'S', ' ', 'N', 'N', 110, 70, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    784,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=276 AND laboratorios_realizados.id_control=445 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    785,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=276 AND laboratorios_realizados.id_control=445 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    786,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=276 AND laboratorios_realizados.id_control=445 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    787,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=276 AND laboratorios_realizados.id_control=445 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    788,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='9' WHERE laboratorios_realizados.id_persona=276 AND laboratorios_realizados.id_control=445 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    789,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='0+' WHERE laboratorios_realizados.id_persona=276 AND laboratorios_realizados.id_control=445 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    790,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='10.3' WHERE laboratorios_realizados.id_persona=275 AND laboratorios_realizados.id_control=444 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    791,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=349",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    792,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=274 AND laboratorios_realizados.id_control=443 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    793,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=274 AND laboratorios_realizados.id_control=443 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    794,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=274 AND laboratorios_realizados.id_control=443 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    795,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=274 AND laboratorios_realizados.id_control=443 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    796,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='9' WHERE laboratorios_realizados.id_persona=274 AND laboratorios_realizados.id_control=443 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    797,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='0+' WHERE laboratorios_realizados.id_persona=274 AND laboratorios_realizados.id_control=443 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    798,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=352",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    799,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=277 AND laboratorios_realizados.id_control=446 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    800,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=277 AND laboratorios_realizados.id_control=446 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    801,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=277 AND laboratorios_realizados.id_control=446 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    802,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=277 AND laboratorios_realizados.id_control=446 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    803,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='9.3' WHERE laboratorios_realizados.id_persona=277 AND laboratorios_realizados.id_control=446 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    804,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='0+' WHERE laboratorios_realizados.id_persona=277 AND laboratorios_realizados.id_control=446 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    805,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=353",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    806,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=100137 AND laboratorios_realizados.id_control=447 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    807,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=100137 AND laboratorios_realizados.id_control=447 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    808,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='11.3' WHERE laboratorios_realizados.id_persona=100137 AND laboratorios_realizados.id_control=447 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    809,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=345",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    810,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='-' WHERE laboratorios_realizados.id_persona=400002 AND laboratorios_realizados.id_control=439 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    811,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=400002 AND laboratorios_realizados.id_control=439 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    812,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=400002 AND laboratorios_realizados.id_control=439 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    813,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=400002 AND laboratorios_realizados.id_control=439 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    814,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=346",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    815,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=271 AND laboratorios_realizados.id_control=440 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    816,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=271 AND laboratorios_realizados.id_control=440 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    817,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=271 AND laboratorios_realizados.id_control=440 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    818,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=271 AND laboratorios_realizados.id_control=440 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    819,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='10.3' WHERE laboratorios_realizados.id_persona=271 AND laboratorios_realizados.id_control=440 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    820,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='0+' WHERE laboratorios_realizados.id_persona=271 AND laboratorios_realizados.id_control=440 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    821,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=348",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    822,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=273 AND laboratorios_realizados.id_control=442 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    823,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=273 AND laboratorios_realizados.id_control=442 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    824,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=273 AND laboratorios_realizados.id_control=442 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    825,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=273 AND laboratorios_realizados.id_control=442 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    826,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='10' WHERE laboratorios_realizados.id_persona=273 AND laboratorios_realizados.id_control=442 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    827,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='0+' WHERE laboratorios_realizados.id_persona=273 AND laboratorios_realizados.id_control=442 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    828,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=320",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    829,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=321",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    830,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=260 AND laboratorios_realizados.id_control=415 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    831,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=260 AND laboratorios_realizados.id_control=415 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    832,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=260 AND laboratorios_realizados.id_control=415 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    833,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=260 AND laboratorios_realizados.id_control=415 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    834,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='10' WHERE laboratorios_realizados.id_persona=260 AND laboratorios_realizados.id_control=415 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    835,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='-' WHERE laboratorios_realizados.id_persona=260 AND laboratorios_realizados.id_control=415 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    836,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='A+' WHERE laboratorios_realizados.id_persona=260 AND laboratorios_realizados.id_control=415 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    837,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=322",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    838,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=100142 AND laboratorios_realizados.id_control=416 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    839,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=100142 AND laboratorios_realizados.id_control=416 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    840,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=100142 AND laboratorios_realizados.id_control=416 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    841,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='9' WHERE laboratorios_realizados.id_persona=100142 AND laboratorios_realizados.id_control=416 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    842,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=323",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    843,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=261 AND laboratorios_realizados.id_control=417 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    844,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=261 AND laboratorios_realizados.id_control=417 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    845,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=261 AND laboratorios_realizados.id_control=417 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    846,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=261 AND laboratorios_realizados.id_control=417 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    847,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='8.3' WHERE laboratorios_realizados.id_persona=261 AND laboratorios_realizados.id_control=417 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    848,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='-' WHERE laboratorios_realizados.id_persona=261 AND laboratorios_realizados.id_control=417 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    849,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='0+' WHERE laboratorios_realizados.id_persona=261 AND laboratorios_realizados.id_control=417 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    850,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=324",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    851,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=100141 AND laboratorios_realizados.id_control=418 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    852,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=100141 AND laboratorios_realizados.id_control=418 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    853,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (448, '2022-03-30 21:04:09.000000', 100141, 4, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    854,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100141, 448, 2, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    855,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100141, 448, 3, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    856,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100141, 448, 4, 'C')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    857,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (100141, 448, 6, 3, '2022-03-30 21:05:49.000000', '2022-03-30 21:05:49.000000', '7')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    858,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (354, 448, 38, 'N', ' ', 'N', 'N', 100, 70, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    859,
                    "UPDATE controles SET georeferencia='-22.2728809,-62.7170027' WHERE controles.id_control=448",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    860,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=325",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    861,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=230 AND laboratorios_realizados.id_control=419 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    862,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=230 AND laboratorios_realizados.id_control=419 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    863,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='11.3' WHERE laboratorios_realizados.id_persona=230 AND laboratorios_realizados.id_control=419 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    864,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=263",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    865,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=230 AND laboratorios_realizados.id_control=355 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    866,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=230 AND laboratorios_realizados.id_control=355 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    867,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=230 AND laboratorios_realizados.id_control=355 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    868,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=230 AND laboratorios_realizados.id_control=355 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    869,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='11.3' WHERE laboratorios_realizados.id_persona=230 AND laboratorios_realizados.id_control=355 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    870,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='104' WHERE laboratorios_realizados.id_persona=230 AND laboratorios_realizados.id_control=355 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    871,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='0+' WHERE laboratorios_realizados.id_persona=230 AND laboratorios_realizados.id_control=355 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    872,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (449, '2022-03-30 21:10:58.000000', 230, 3, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    873,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (230, 449, 2, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    874,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (230, 449, 3, 'C')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    875,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (230, 449, 4, 'C')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    876,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (230, 449, 6, 2, '2022-03-30 21:12:43.000000', '2022-03-30 21:12:43.000000', '9')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    877,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (355, 449, 27, 'N', ' ', 'N', 'N', 90, 50, 'N', 'SE CORRIGE VALOR DE Hb: 9.3 ANEMIA')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    878,
                    "UPDATE controles SET georeferencia='-22.2725026,-62.7125106' WHERE controles.id_control=449",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    879,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=326",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    880,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=111 AND laboratorios_realizados.id_control=420 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    881,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=111 AND laboratorios_realizados.id_control=420 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    882,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=111 AND laboratorios_realizados.id_control=420 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    883,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='10' WHERE laboratorios_realizados.id_persona=111 AND laboratorios_realizados.id_control=420 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    884,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=327",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    885,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=262 AND laboratorios_realizados.id_control=421 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    886,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=262 AND laboratorios_realizados.id_control=421 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    887,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=262 AND laboratorios_realizados.id_control=421 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    888,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=262 AND laboratorios_realizados.id_control=421 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    889,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='10.7' WHERE laboratorios_realizados.id_persona=262 AND laboratorios_realizados.id_control=421 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    890,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='-' WHERE laboratorios_realizados.id_persona=262 AND laboratorios_realizados.id_control=421 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    891,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='0+' WHERE laboratorios_realizados.id_persona=262 AND laboratorios_realizados.id_control=421 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    892,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=328",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    893,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=233 AND laboratorios_realizados.id_control=422 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    894,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=233 AND laboratorios_realizados.id_control=422 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    895,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=233 AND laboratorios_realizados.id_control=422 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    896,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='8.7' WHERE laboratorios_realizados.id_persona=233 AND laboratorios_realizados.id_control=422 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    897,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=267",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    898,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=233 AND laboratorios_realizados.id_control=360 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    899,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=233 AND laboratorios_realizados.id_control=360 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    900,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=233 AND laboratorios_realizados.id_control=360 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    901,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=233 AND laboratorios_realizados.id_control=360 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    902,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='11' WHERE laboratorios_realizados.id_persona=233 AND laboratorios_realizados.id_control=360 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    903,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='97' WHERE laboratorios_realizados.id_persona=233 AND laboratorios_realizados.id_control=360 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    904,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='0+' WHERE laboratorios_realizados.id_persona=233 AND laboratorios_realizados.id_control=360 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    905,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=329",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    906,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='10' WHERE laboratorios_realizados.id_persona=232 AND laboratorios_realizados.id_control=423 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    907,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=232 AND laboratorios_realizados.id_control=358 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    908,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=232 AND laboratorios_realizados.id_control=358 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    909,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=232 AND laboratorios_realizados.id_control=358 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    910,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=232 AND laboratorios_realizados.id_control=358 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    911,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='82' WHERE laboratorios_realizados.id_persona=232 AND laboratorios_realizados.id_control=358 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    912,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='0+' WHERE laboratorios_realizados.id_persona=232 AND laboratorios_realizados.id_control=358 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    913,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=330",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    914,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=100150 AND laboratorios_realizados.id_control=424 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    915,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=100150 AND laboratorios_realizados.id_control=424 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    916,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=100150 AND laboratorios_realizados.id_control=424 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    917,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='10' WHERE laboratorios_realizados.id_persona=100150 AND laboratorios_realizados.id_control=424 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    918,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='0+' WHERE laboratorios_realizados.id_persona=100150 AND laboratorios_realizados.id_control=100232 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    919,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=331",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    920,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='9' WHERE laboratorios_realizados.id_persona=231 AND laboratorios_realizados.id_control=425 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    921,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=265",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    922,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=231 AND laboratorios_realizados.id_control=357 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    923,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=231 AND laboratorios_realizados.id_control=357 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    924,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=231 AND laboratorios_realizados.id_control=357 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    925,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=231 AND laboratorios_realizados.id_control=357 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    926,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='12' WHERE laboratorios_realizados.id_persona=231 AND laboratorios_realizados.id_control=357 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    927,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='107' WHERE laboratorios_realizados.id_persona=231 AND laboratorios_realizados.id_control=357 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    928,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='0+' WHERE laboratorios_realizados.id_persona=231 AND laboratorios_realizados.id_control=357 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    929,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=332",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    930,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='' WHERE laboratorios_realizados.id_persona=263 AND laboratorios_realizados.id_control=426 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    931,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=263 AND laboratorios_realizados.id_control=426 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    932,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=263 AND laboratorios_realizados.id_control=426 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    933,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=263 AND laboratorios_realizados.id_control=426 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    934,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=263 AND laboratorios_realizados.id_control=426 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    935,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='-' WHERE laboratorios_realizados.id_persona=263 AND laboratorios_realizados.id_control=426 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    936,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=333",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    937,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='10' WHERE laboratorios_realizados.id_persona=235 AND laboratorios_realizados.id_control=427 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    938,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=235 AND laboratorios_realizados.id_control=427 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    939,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=235 AND laboratorios_realizados.id_control=362 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    940,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=235 AND laboratorios_realizados.id_control=362 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    941,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=235 AND laboratorios_realizados.id_control=362 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    942,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=235 AND laboratorios_realizados.id_control=362 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    943,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='12' WHERE laboratorios_realizados.id_persona=235 AND laboratorios_realizados.id_control=362 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    944,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='95' WHERE laboratorios_realizados.id_persona=235 AND laboratorios_realizados.id_control=362 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    945,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='0+' WHERE laboratorios_realizados.id_persona=235 AND laboratorios_realizados.id_control=362 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    946,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=334",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    947,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=113 AND laboratorios_realizados.id_control=428 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    948,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=113 AND laboratorios_realizados.id_control=428 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    949,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=113 AND laboratorios_realizados.id_control=428 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    950,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=113 AND laboratorios_realizados.id_control=428 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    951,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='10' WHERE laboratorios_realizados.id_persona=113 AND laboratorios_realizados.id_control=428 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    952,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='0+' WHERE laboratorios_realizados.id_persona=113 AND laboratorios_realizados.id_control=428 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    953,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=335",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    954,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=264 AND laboratorios_realizados.id_control=429 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    955,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=264 AND laboratorios_realizados.id_control=429 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    956,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=264 AND laboratorios_realizados.id_control=429 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    957,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=264 AND laboratorios_realizados.id_control=429 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    958,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='11' WHERE laboratorios_realizados.id_persona=264 AND laboratorios_realizados.id_control=429 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    959,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='0+' WHERE laboratorios_realizados.id_persona=264 AND laboratorios_realizados.id_control=429 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    960,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=336",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    961,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=100170 AND laboratorios_realizados.id_control=430 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    962,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=100170 AND laboratorios_realizados.id_control=430 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    963,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=100170 AND laboratorios_realizados.id_control=430 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    964,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=100170 AND laboratorios_realizados.id_control=430 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    965,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='10' WHERE laboratorios_realizados.id_persona=100170 AND laboratorios_realizados.id_control=430 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    966,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='' WHERE laboratorios_realizados.id_persona=100170 AND laboratorios_realizados.id_control=430 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    967,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=269 AND laboratorios_realizados.id_control=437 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    968,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=337",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    969,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=265 AND laboratorios_realizados.id_control=431 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    970,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=265 AND laboratorios_realizados.id_control=431 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    971,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=265 AND laboratorios_realizados.id_control=431 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    972,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=265 AND laboratorios_realizados.id_control=431 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    973,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='8.7' WHERE laboratorios_realizados.id_persona=265 AND laboratorios_realizados.id_control=431 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    974,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='-' WHERE laboratorios_realizados.id_persona=265 AND laboratorios_realizados.id_control=431 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    975,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='0+' WHERE laboratorios_realizados.id_persona=265 AND laboratorios_realizados.id_control=431 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    976,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=338",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    977,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=138 AND laboratorios_realizados.id_control=432 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    978,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=138 AND laboratorios_realizados.id_control=432 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    979,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=138 AND laboratorios_realizados.id_control=432 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    980,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=138 AND laboratorios_realizados.id_control=432 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    981,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='8.3' WHERE laboratorios_realizados.id_persona=138 AND laboratorios_realizados.id_control=432 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    982,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=339",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    983,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=266 AND laboratorios_realizados.id_control=433 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    984,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=266 AND laboratorios_realizados.id_control=433 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    985,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=266 AND laboratorios_realizados.id_control=433 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    986,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=266 AND laboratorios_realizados.id_control=433 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    987,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='10' WHERE laboratorios_realizados.id_persona=266 AND laboratorios_realizados.id_control=433 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    988,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='0+' WHERE laboratorios_realizados.id_persona=266 AND laboratorios_realizados.id_control=433 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    989,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=139 AND laboratorios_realizados.id_control=434 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    990,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=139 AND laboratorios_realizados.id_control=434 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    991,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=139 AND laboratorios_realizados.id_control=434 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    992,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=139 AND laboratorios_realizados.id_control=434 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    993,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='9.7' WHERE laboratorios_realizados.id_persona=139 AND laboratorios_realizados.id_control=434 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    994,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=340",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    995,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=314",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    996,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=100119 AND laboratorios_realizados.id_control=408 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    997,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=100119 AND laboratorios_realizados.id_control=408 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    998,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=100119 AND laboratorios_realizados.id_control=408 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    999,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (450, '2022-03-30 23:46:52.000000', 100119, 5, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1000,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100119, 450, 2, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1001,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100119, 450, 3, 'C')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1002,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100119, 450, 4, 'C')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1003,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (100119, 450, 6, 3, '2022-03-30 23:47:34.000000', '2022-03-30 23:47:34.000000', '10')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1004,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (356, 450, 32, 'N', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1005,
                    "UPDATE controles SET georeferencia='-22.2725026,-62.7125106' WHERE controles.id_control=450",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1006,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=100079 AND laboratorios_realizados.id_control=412 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1007,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=100079 AND laboratorios_realizados.id_control=412 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1008,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=100079 AND laboratorios_realizados.id_control=412 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1009,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='6' WHERE laboratorios_realizados.id_persona=100079 AND laboratorios_realizados.id_control=412 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1010,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='90' WHERE laboratorios_realizados.id_persona=100079 AND laboratorios_realizados.id_control=412 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1011,
                    "UPDATE control_embarazo SET eco='P', detalle_eco='SOSPECHA DE RCIU. DERIVACIÒN A HSVE.' WHERE control_embarazo.id_control_embarazo=318",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1012,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=310",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1013,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=100160 AND laboratorios_realizados.id_control=402 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1014,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=100160 AND laboratorios_realizados.id_control=402 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1015,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=100160 AND laboratorios_realizados.id_control=402 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1016,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (451, '2022-03-30 23:52:35.000000', 100160, 3, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1017,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (452, '2022-03-30 23:54:33.000000', 100161, 2, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1018,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100161, 452, 2, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1019,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100161, 452, 3, 'P')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1020,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100161, 452, 4, 'C')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1021,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100161, 452, 1, 3, '2022-03-30 23:55:51.000000', '2022-03-30 23:55:51.000000', 'N', 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1022,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100161, 452, 2, 3, '2022-03-30 23:55:51.000000', '2022-03-30 23:55:51.000000', 'N', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1023,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (100161, 452, 6, 3, '2022-03-30 23:55:51.000000', '2022-03-30 23:55:51.000000', '9')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1024,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (357, 452, 34, 'N', ' ', 'N', 'N', 90, 60, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1025,
                    "UPDATE controles SET georeferencia='-22.2728783,-62.7169976' WHERE controles.id_control=452",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1026,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100269",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1027,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=308",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1028,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=253 AND laboratorios_realizados.id_control=400 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1029,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=253 AND laboratorios_realizados.id_control=400 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1030,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=253 AND laboratorios_realizados.id_control=400 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1031,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=253 AND laboratorios_realizados.id_control=400 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1032,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='7.6' WHERE laboratorios_realizados.id_persona=253 AND laboratorios_realizados.id_control=400 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1033,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='76' WHERE laboratorios_realizados.id_persona=253 AND laboratorios_realizados.id_control=400 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1034,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='0+' WHERE laboratorios_realizados.id_persona=253 AND laboratorios_realizados.id_control=400 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1035,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=312",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1036,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=311",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1037,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=256 AND laboratorios_realizados.id_control=405 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1038,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=256 AND laboratorios_realizados.id_control=405 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1039,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=256 AND laboratorios_realizados.id_control=405 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1040,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=256 AND laboratorios_realizados.id_control=405 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1041,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='6.7' WHERE laboratorios_realizados.id_persona=256 AND laboratorios_realizados.id_control=405 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1042,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='97' WHERE laboratorios_realizados.id_persona=256 AND laboratorios_realizados.id_control=405 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1043,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='0+' WHERE laboratorios_realizados.id_persona=256 AND laboratorios_realizados.id_control=405 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1044,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=313",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1045,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=257 AND laboratorios_realizados.id_control=407 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1046,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=257 AND laboratorios_realizados.id_control=407 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1047,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=257 AND laboratorios_realizados.id_control=407 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1048,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=257 AND laboratorios_realizados.id_control=407 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1049,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='9' WHERE laboratorios_realizados.id_persona=257 AND laboratorios_realizados.id_control=407 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1050,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='92' WHERE laboratorios_realizados.id_persona=257 AND laboratorios_realizados.id_control=407 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1051,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='0+' WHERE laboratorios_realizados.id_persona=257 AND laboratorios_realizados.id_control=407 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1052,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=317",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1053,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=258 AND laboratorios_realizados.id_control=411 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1054,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=258 AND laboratorios_realizados.id_control=411 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1055,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=258 AND laboratorios_realizados.id_control=411 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1056,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=258 AND laboratorios_realizados.id_control=411 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1057,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='11.7' WHERE laboratorios_realizados.id_persona=258 AND laboratorios_realizados.id_control=411 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1058,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='103' WHERE laboratorios_realizados.id_persona=258 AND laboratorios_realizados.id_control=411 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1059,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=319",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1060,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=259 AND laboratorios_realizados.id_control=413 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1061,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=259 AND laboratorios_realizados.id_control=413 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1062,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=259 AND laboratorios_realizados.id_control=413 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1063,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=259 AND laboratorios_realizados.id_control=413 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1064,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='8.3' WHERE laboratorios_realizados.id_persona=259 AND laboratorios_realizados.id_control=413 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1065,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='93' WHERE laboratorios_realizados.id_persona=259 AND laboratorios_realizados.id_control=413 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1066,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='0+' WHERE laboratorios_realizados.id_persona=259 AND laboratorios_realizados.id_control=413 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1067,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=316",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1068,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=100120 AND laboratorios_realizados.id_control=410 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1069,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=100120 AND laboratorios_realizados.id_control=410 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1070,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=100120 AND laboratorios_realizados.id_control=410 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1071,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='9.7' WHERE laboratorios_realizados.id_persona=100120 AND laboratorios_realizados.id_control=410 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1072,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=315",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1073,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=225 AND laboratorios_realizados.id_control=347 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1074,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=225 AND laboratorios_realizados.id_control=347 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1075,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=225 AND laboratorios_realizados.id_control=347 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1076,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=225 AND laboratorios_realizados.id_control=347 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1077,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='100' WHERE laboratorios_realizados.id_persona=225 AND laboratorios_realizados.id_control=347 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1078,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='0+' WHERE laboratorios_realizados.id_persona=225 AND laboratorios_realizados.id_control=347 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1079,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (453, '2022-03-31 00:25:28.000000', 225, 2, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1080,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (225, 453, 2, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1081,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (225, 453, 3, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1082,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (225, 453, 4, 'C')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1083,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (358, 453, 22, 'R', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1084,
                    "UPDATE controles SET georeferencia='-22.2728783,-62.7169976' WHERE controles.id_control=453",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1085,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=100185 AND laboratorios_realizados.id_control=100318 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1086,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=100185 AND laboratorios_realizados.id_control=100318 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1087,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=100185 AND laboratorios_realizados.id_control=100318 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1088,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=100185 AND laboratorios_realizados.id_control=100318 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1089,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='12.3' WHERE laboratorios_realizados.id_persona=100185 AND laboratorios_realizados.id_control=100318 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1090,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='0+' WHERE laboratorios_realizados.id_persona=100185 AND laboratorios_realizados.id_control=100318 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1091,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (278, 'PEREZ', 'MARISEL CLAUDIA', '41372447', '2001-01-08 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1092,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, georeferencia) VALUES (454, '2022-03-31 00:37:30.000000', 278, 1, 1, '-22.2728809,-62.7170027')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1093,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (278, 278, 24, 3, '0', '2022-03-31 00:37:30.000000', '-22.2728809,-62.7170027', 12)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1094,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (237, 278, 454, 0, 1, 0, '2021-07-03 00:00:00.000000', '2022-04-09 00:00:00.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1095,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (278, 454, 2, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1096,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (278, 454, 3, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1097,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (278, 454, 1, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1098,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (278, 454, 4, 'P')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1099,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (278, 454, 1, 3, '2022-03-31 00:46:03.000000', '2022-03-31 00:46:03.000000', 'N', 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1100,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (278, 454, 2, 3, '2022-03-31 00:46:03.000000', '2022-03-31 00:46:03.000000', 'N', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1101,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (278, 454, 5, 3, '2022-03-31 00:46:03.000000', '2022-03-31 00:46:03.000000', 'N', 4)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1102,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (278, 454, 6, 3, '2022-03-31 00:46:03.000000', '2022-03-31 00:46:03.000000', '7')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1103,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (359, 454, 38, 'R', ' ', 'N', 'N', 100, 60, 'N', 'VER CONTROL SEPTIEMBRE 2021: CHAGAS Y GF.')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1104,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=309",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1105,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (254, 401, 13, 3, '2022-03-31 00:48:39.000000', 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1106,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (254, 401, 18, 3, '2022-03-31 00:48:39.000000', 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1107,
                    "INSERT INTO etmis_personas (id_persona, id_etmi, id_control) VALUES (254, 3, 401)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1108,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='P' WHERE laboratorios_realizados.id_persona=254 AND laboratorios_realizados.id_control=401 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1109,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=254 AND laboratorios_realizados.id_control=401 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1110,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=254 AND laboratorios_realizados.id_control=401 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1111,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=254 AND laboratorios_realizados.id_control=401 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1112,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='5.3' WHERE laboratorios_realizados.id_persona=254 AND laboratorios_realizados.id_control=401 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1113,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='108' WHERE laboratorios_realizados.id_persona=254 AND laboratorios_realizados.id_control=401 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1114,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='0+' WHERE laboratorios_realizados.id_persona=254 AND laboratorios_realizados.id_control=401 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1115,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (279, 'MIRANDA', 'BEATRIZ', '36492115', '1991-09-04 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1116,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (455, '2022-03-31 09:47:58.000000', 279, 1, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1117,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (279, 279, 7, 4, '00', '2022-03-31 09:47:58.000000', '', 12)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1118,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (238, 279, 455, 16, '2020-08-03 00:00:00.000000', 10, 9, 0, '2021-11-28 00:00:00.000000', '2022-09-04 00:00:00.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1119,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (279, 455, 2, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1120,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (279, 455, 3, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1121,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (279, 455, 1, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1122,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (279, 455, 4, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1123,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (279, 455, 1, 2, '2022-03-31 09:51:58.000000', 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1124,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (279, 455, 2, 2, '2022-03-31 09:51:58.000000', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1125,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (279, 455, 4, 2, '2022-03-31 09:51:58.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1126,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (279, 455, 5, 2, '2022-03-31 09:51:58.000000', 4)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1127,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (279, 455, 6, 2, '2022-03-31 09:51:58.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1128,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (279, 455, 9, 2, '2022-03-31 09:51:58.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1129,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (360, 455, 17, 'S', ' ', 'N', 'N', 90, 60, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1130,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (280, 'PEREZ', 'DELFINA', '34142143', '1988-10-06 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1131,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (456, '2022-03-31 09:57:14.000000', 280, 1, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1132,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (280, 280, 7, 4, '00', '2022-03-31 09:57:14.000000', '', 12)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1133,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (239, 280, 456, 15, '2014-10-30 00:00:00.000000', 5, 4, 1, '2021-10-10 00:00:00.000000', '2022-07-17 00:00:00.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1134,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (239, 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1135,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (280, 456, 2, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1136,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (280, 456, 3, 'P')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1137,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (280, 456, 1, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1138,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (280, 456, 4, 'P')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1139,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (280, 456, 1, 2, '2022-03-31 10:01:50.000000', 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1140,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (280, 456, 2, 2, '2022-03-31 10:01:50.000000', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1141,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (280, 456, 4, 2, '2022-03-31 10:01:50.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1142,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (280, 456, 5, 2, '2022-03-31 10:01:50.000000', 4)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1143,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (280, 456, 6, 2, '2022-03-31 10:01:50.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1144,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (280, 456, 9, 2, '2022-03-31 10:01:50.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1145,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (361, 456, 24, 'S', ' ', 'N', 'N', 110, 60, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1146,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (281, 'VIDAL', 'ANTONINA', '39891186', '2022-03-31 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1147,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (457, '2022-03-31 10:05:13.000000', 281, 1, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1148,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (281, 281, 7, 4, '00', '2022-03-31 10:05:13.000000', '', 12)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1149,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (282, 'TORRES', 'CARINA', '19073266', '2989-12-25 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1150,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (458, '2022-03-31 10:15:10.000000', 282, 1, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1151,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (282, 282, 7, 4, '00', '2022-03-31 10:15:10.000000', '', 12)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1152,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, abortos, planificado, fum, fpp) VALUES (240, 282, 458, 15, 4, 1, 2, 1, '2021-10-07 00:00:00.000000', '2022-07-14 00:00:00.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1153,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (282, 458, 2, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1154,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (282, 458, 3, 'C')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1155,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (282, 458, 1, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1156,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (282, 458, 4, 'C')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1157,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (282, 458, 1, 2, '2022-03-31 10:20:52.000000', 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1158,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (282, 458, 2, 2, '2022-03-31 10:20:52.000000', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1159,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (282, 458, 4, 2, '2022-03-31 10:20:52.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1160,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (282, 458, 5, 2, '2022-03-31 10:20:52.000000', 4)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1161,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (282, 458, 6, 2, '2022-03-31 10:20:52.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1162,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (282, 458, 9, 2, '2022-03-31 10:20:52.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1163,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (362, 458, 25, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1164,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (283, 'MARTINEZ', 'VANESA', '42752475', '2001-08-08 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1165,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (459, '2022-03-31 10:25:29.000000', 283, 1, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1166,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (283, 283, 7, 4, '00', '2022-03-31 10:25:29.000000', '', 12)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1167,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, planificado, fum, fpp) VALUES (241, 283, 459, 20, '2030-10-31 00:00:00.000000', 1, 1, '2021-12-01 00:00:00.000000', '2022-09-07 00:00:00.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1168,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (242, 281, 457, 21, '2019-09-21 00:00:00.000000', 3, 2, 1, '2022-01-06 00:00:00.000000', '2022-10-13 00:00:00.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1169,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (281, 457, 2, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1170,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (281, 457, 3, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1171,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (281, 457, 1, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1172,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (281, 457, 4, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1173,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (281, 457, 1, 1, '2022-03-31 10:34:39.000000', 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1174,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (281, 457, 2, 1, '2022-03-31 10:34:39.000000', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1175,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (281, 457, 4, 1, '2022-03-31 10:34:39.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1176,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (281, 457, 5, 1, '2022-03-31 10:34:39.000000', 4)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1177,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (281, 457, 6, 1, '2022-03-31 10:34:39.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1178,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (281, 457, 9, 1, '2022-03-31 10:34:39.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1179,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (363, 457, 12, 'S', ' ', 'N', 'N', 90, 60, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1180,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (283, 459, 2, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1181,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (283, 459, 3, 'P')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1182,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (283, 459, 1, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1183,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (283, 459, 4, 'P')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1184,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (283, 459, 1, 2, '2022-03-31 10:35:02.000000', 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1185,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (283, 459, 2, 2, '2022-03-31 10:35:02.000000', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1186,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (283, 459, 4, 2, '2022-03-31 10:35:02.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1187,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (283, 459, 5, 2, '2022-03-31 10:35:02.000000', 4)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1188,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (283, 459, 6, 2, '2022-03-31 10:35:02.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1189,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (364, 459, 17, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1190,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=361",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1191,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=280 AND laboratorios_realizados.id_control=456 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1192,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=280 AND laboratorios_realizados.id_control=456 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1193,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=280 AND laboratorios_realizados.id_control=456 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1194,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=280 AND laboratorios_realizados.id_control=456 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1195,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='20' WHERE laboratorios_realizados.id_persona=280 AND laboratorios_realizados.id_control=456 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1196,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='0+' WHERE laboratorios_realizados.id_persona=280 AND laboratorios_realizados.id_control=456 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1197,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=363",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1198,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=281 AND laboratorios_realizados.id_control=457 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1199,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=281 AND laboratorios_realizados.id_control=457 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1200,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=281 AND laboratorios_realizados.id_control=457 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1201,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=281 AND laboratorios_realizados.id_control=457 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1202,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='11.3' WHERE laboratorios_realizados.id_persona=281 AND laboratorios_realizados.id_control=457 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1203,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='0+' WHERE laboratorios_realizados.id_persona=281 AND laboratorios_realizados.id_control=457 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1204,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=364",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1205,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=283 AND laboratorios_realizados.id_control=459 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1206,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=283 AND laboratorios_realizados.id_control=459 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1207,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=283 AND laboratorios_realizados.id_control=459 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1208,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='10' WHERE laboratorios_realizados.id_persona=283 AND laboratorios_realizados.id_control=459 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1209,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=362",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1210,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=282 AND laboratorios_realizados.id_control=458 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1211,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=282 AND laboratorios_realizados.id_control=458 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1212,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=282 AND laboratorios_realizados.id_control=458 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1213,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=282 AND laboratorios_realizados.id_control=458 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1214,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='8.3' WHERE laboratorios_realizados.id_persona=282 AND laboratorios_realizados.id_control=458 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1215,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='0+' WHERE laboratorios_realizados.id_persona=282 AND laboratorios_realizados.id_control=458 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1216,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=283 AND laboratorios_realizados.id_control=459 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1217,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (284, 'RICALDE', 'CARLA JANET', '44910047', '2003-05-05 00:00:00.000000', 2, 12, 'F')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1218,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (460, '2022-03-31 11:25:16.000000', 284, 1, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1219,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (284, 284, 7, 4, '00', '2022-03-31 11:25:16.000000', '', 12)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1220,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, planificado, fum, fpp) VALUES (243, 284, 460, 18, '2030-10-31 00:00:00.000000', 1, 1, '2020-02-22 00:00:00.000000', '2027-11-22 00:00:00.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1221,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (243, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1222,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (285, 'MORENO', 'NELDA', '44566224', '2004-11-06 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1223,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (461, '2022-03-31 11:39:12.000000', 285, 1, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1224,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (285, 285, 7, 4, '00', '2022-03-31 11:39:12.000000', '', 12)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1225,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (286, 'RICALDI', 'CARLA JANET', '449100047', '2003-05-05 00:00:00.000000', 2, 12, 'F')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1226,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (462, '2022-03-31 11:45:17.000000', 286, 1, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1227,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (286, 286, 7, 4, '00', '2022-03-31 11:45:17.000000', '', 12)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1228,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, planificado, fum, fpp) VALUES (244, 286, 462, 18, '2030-10-31 00:00:00.000000', 1, 1, '2022-02-20 00:00:00.000000', '2022-11-27 00:00:00.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1229,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (244, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1230,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (287, 'CATARDO', 'JULIETA BEATRIZ', '34312036', '1988-01-20 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1231,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (463, '2022-03-31 12:00:31.000000', 287, 1, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1232,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (287, 287, 7, 4, '00', '2022-03-31 12:00:31.000000', '', 12)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1233,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, cesareas, abortos, planificado, fum, fpp) VALUES (245, 287, 463, 21, '2013-08-27 00:00:00.000000', 4, 2, 1, 1, '2021-11-30 00:00:00.000000', '2022-09-06 00:00:00.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1234,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (245, 4)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1235,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (287, 463, 2, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1236,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (287, 463, 3, 'P')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1237,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (287, 463, 1, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1238,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (287, 463, 4, 'P')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1239,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (287, 463, 1, 2, '2022-03-31 12:05:42.000000', 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1240,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (287, 463, 2, 2, '2022-03-31 12:05:42.000000', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1241,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (287, 463, 4, 2, '2022-03-31 12:05:42.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1242,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (287, 463, 5, 2, '2022-03-31 12:05:42.000000', 4)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1243,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (287, 463, 6, 2, '2022-03-31 12:05:42.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1244,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (287, 463, 9, 2, '2022-03-31 12:05:42.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1245,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (365, 463, 17, 'S', ' ', 'N', 'N', 100, 70, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1246,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (246, 285, 461, 15, '2020-02-15 00:00:00.000000', 3, 2, 1, '2021-07-01 00:00:00.000000', '2022-04-07 00:00:00.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1247,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (285, 461, 2, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1248,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (285, 461, 3, 'C')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1249,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (285, 461, 1, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1250,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (285, 461, 4, 'P')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1251,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (285, 461, 1, 3, '2022-03-31 12:09:34.000000', 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1252,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (285, 461, 2, 3, '2022-03-31 12:09:34.000000', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1253,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (285, 461, 4, 3, '2022-03-31 12:09:34.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1254,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (285, 461, 5, 3, '2022-03-31 12:09:34.000000', 4)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1255,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (285, 461, 6, 3, '2022-03-31 12:09:34.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1256,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (285, 461, 9, 3, '2022-03-31 12:09:34.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1257,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (366, 461, 39, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1258,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (288, 'SANCHEZ', 'BORGELIA LEUCADIA', '46799610', '2006-07-21 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1259,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (464, '2022-03-31 12:11:47.000000', 288, 1, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1260,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (288, 288, 7, 4, '00', '2022-03-31 12:11:47.000000', '', 12)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1261,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, planificado, fum, fpp) VALUES (247, 288, 464, 15, '2030-10-31 00:00:00.000000', 1, 0, '2021-11-20 00:00:00.000000', '2022-08-27 00:00:00.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1262,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (288, 464, 2, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1263,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (288, 464, 3, 'C')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1264,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (288, 464, 1, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1265,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (288, 464, 4, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1266,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (288, 464, 1, 2, '2022-03-31 12:16:39.000000', 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1267,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (288, 464, 2, 2, '2022-03-31 12:16:39.000000', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1268,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (288, 464, 4, 2, '2022-03-31 12:16:39.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1269,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (288, 464, 5, 2, '2022-03-31 12:16:39.000000', 4)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1270,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (288, 464, 6, 2, '2022-03-31 12:16:39.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1271,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (288, 464, 7, 2, '2022-03-31 12:16:39.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1272,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (288, 464, 9, 2, '2022-03-31 12:16:39.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1273,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (367, 464, 18, 'S', ' ', 'N', 'N', 90, 60, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1274,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (289, 'SANCHEZ', 'MARCELINA', '39538662', '1996-05-18 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1275,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (465, '2022-03-31 12:22:31.000000', 289, 1, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1276,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (289, 289, 7, 4, '00', '2022-03-31 12:22:31.000000', '', 12)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1277,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (248, 289, 465, 16, '2020-09-29 00:00:00.000000', 5, 4, 1, '2021-12-22 00:00:00.000000', '2022-09-28 00:00:00.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1278,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (289, 465, 2, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1279,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (289, 465, 3, 'C')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1280,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (289, 465, 1, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1281,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (289, 465, 4, 'P')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1282,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (289, 465, 1, 2, '2022-03-31 12:26:52.000000', 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1283,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (289, 465, 2, 2, '2022-03-31 12:26:52.000000', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1284,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (289, 465, 4, 2, '2022-03-31 12:26:52.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1285,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (289, 465, 5, 2, '2022-03-31 12:26:52.000000', 4)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1286,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (289, 465, 6, 2, '2022-03-31 12:26:52.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1287,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (368, 465, 14, 'S', ' ', 'N', 'N', 120, 60, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1288,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (290, 'RICALDI', 'CARLA JANET', '44910047', '3003-05-05 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1289,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (466, '2022-03-31 12:39:59.000000', 290, 1, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1290,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (290, 290, 7, 4, '00', '2022-03-31 12:39:59.000000', '', 12)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1291,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, planificado, fum, fpp) VALUES (249, 290, 466, 18, '2030-10-31 00:00:00.000000', 1, 0, '2022-02-20 00:00:00.000000', '2022-11-27 00:00:00.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1292,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (249, 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1293,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (290, 466, 2, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1294,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (290, 466, 3, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1295,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (290, 466, 1, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1296,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (290, 466, 4, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1297,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (290, 466, 1, 1, '2022-03-31 12:45:21.000000', 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1298,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (290, 466, 2, 1, '2022-03-31 12:45:21.000000', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1299,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (290, 466, 4, 1, '2022-03-31 12:45:21.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1300,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (290, 466, 5, 1, '2022-03-31 12:45:21.000000', 4)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1301,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (290, 466, 6, 1, '2022-03-31 12:45:21.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1302,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (290, 466, 9, 1, '2022-03-31 12:45:21.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1303,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (369, 466, 5, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1304,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (291, 'CORREA', 'ENCARNACION ESTEFANIA', '42752440', '2001-04-03 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1305,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (467, '2022-03-31 15:00:16.000000', 291, 1, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1306,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (291, 291, 7, 4, '00', '2022-03-31 15:00:16.000000', '', 12)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1307,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, planificado, fum, fpp) VALUES (250, 291, 467, 20, '2030-10-31 00:00:00.000000', 1, 1, '2021-06-15 00:00:00.000000', '2022-03-22 00:00:00.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1308,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (291, 467, 2, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1309,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (291, 467, 3, 'P')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1310,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (291, 467, 1, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1311,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (291, 467, 4, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1312,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (291, 467, 1, 3, '2022-03-31 15:05:46.000000', 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1313,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (291, 467, 2, 3, '2022-03-31 15:05:46.000000', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1314,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (291, 467, 4, 3, '2022-03-31 15:05:46.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1315,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (291, 467, 5, 3, '2022-03-31 15:05:46.000000', 4)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1316,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (291, 467, 6, 3, '2022-03-31 15:05:46.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1317,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (291, 467, 9, 3, '2022-03-31 15:05:46.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1318,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (370, 467, 41, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1319,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (292, 'ARIAS', 'PRISCILA NATALIA', '50339961', '2005-01-31 00:00:00.000000', 2, 12, 'F')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1320,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (468, '2022-03-31 15:13:00.000000', 292, 1, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1321,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (292, 292, 7, 4, '00', '2022-03-31 15:13:00.000000', '', 12)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1322,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, planificado, fum, fpp) VALUES (251, 292, 468, 17, '2030-10-31 00:00:00.000000', 1, 0, '2021-11-25 00:00:00.000000', '2022-09-01 00:00:00.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1323,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (251, 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1324,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (292, 468, 2, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1325,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (292, 468, 3, 'C')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1326,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (292, 468, 1, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1327,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (292, 468, 4, 'C')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1328,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (292, 468, 1, 2, '2022-03-31 15:17:46.000000', 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1329,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (292, 468, 2, 2, '2022-03-31 15:17:46.000000', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1330,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (292, 468, 4, 2, '2022-03-31 15:17:46.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1331,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (292, 468, 5, 2, '2022-03-31 15:17:46.000000', 4)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1332,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (292, 468, 6, 2, '2022-03-31 15:17:46.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1333,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (292, 468, 9, 2, '2022-03-31 15:17:46.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1334,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (371, 468, 18, 'S', ' ', 'N', 'N', 100, 50, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1335,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (469, '2022-03-31 15:44:36.000000', 200016, 4, 2, '2022-02-27 00:00:00.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1336,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (22, 469)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1337,
                    "SELECT COUNT(*) FROM etmis_personas WHERE etmis_personas.confirmada=1 AND etmis_personas.id_persona=200016",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1338,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (470, '2022-03-31 15:47:18.000000', 200005, 3, 2, '2022-01-12 00:00:00.000000', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1339,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (23, 470)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1340,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=200005",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1341,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (471, '2022-03-31 15:49:15.000000', 100122, 2, 2, '2022-03-18 00:00:00.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1342,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (24, 471)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1343,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=100122",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1344,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (472, '2022-03-31 15:51:05.000000', 173, 2, 2, '2022-01-08 00:00:00.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1345,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (25, 472)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1346,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=173",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1347,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (473, '2022-03-31 15:58:25.000000', 240, 2, 2, '2022-02-27 00:00:00.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1348,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (26, 473)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1349,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=240",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1350,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (474, '2022-03-31 16:00:17.000000', 100124, 2, 2, '2022-01-23 00:00:00.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1351,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (27, 474)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1352,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=100124",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1353,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (475, '2022-03-31 16:01:32.000000', 100114, 3, 2, '2022-03-05 00:00:00.000000', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1354,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (28, 475)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1355,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=100114",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1356,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (476, '2022-03-31 16:03:08.000000', 100123, 4, 2, '2022-02-24 00:00:00.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1357,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control, patologico, observaciones, derivacion) VALUES (29, 476, 1, 'ECLAMPSIA', 'HOSPITAL TARTAGAL')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1358,
                    "SELECT COUNT(*) FROM etmis_personas WHERE etmis_personas.confirmada=1 AND etmis_personas.id_persona=100123",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1359,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (477, '2022-03-31 16:04:07.000000', 100111, 2, 2, '2022-03-16 00:00:00.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1360,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (30, 477)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1361,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=100111",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1362,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (478, '2022-03-31 16:04:53.000000', 171, 2, 2, '2022-02-04 00:00:00.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1363,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (31, 478)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1364,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=171",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1365,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (479, '2022-03-31 16:05:41.000000', 100128, 3, 2, '2022-02-03 00:00:00.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1366,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (32, 479)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1367,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=100128",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1368,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (480, '2022-03-31 16:07:43.000000', 100110, 3, 2, '2022-03-29 00:00:00.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1369,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (33, 480)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1370,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=100110",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1371,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (481, '2022-03-31 16:09:08.000000', 87, 3, 2, '2021-11-30 00:00:00.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1372,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (34, 481)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1373,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=87",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1374,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (482, '2022-03-31 16:10:02.000000', 100112, 4, 2, '2022-03-16 00:00:00.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1375,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (35, 482)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1376,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=100112",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1377,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (483, '2022-03-31 16:11:17.000000', 71, 4, 2, '2022-02-09 00:00:00.000000', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1378,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (36, 483)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1379,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=71",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1380,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (484, '2022-03-31 16:15:39.000000', 184, 2, 2, '2022-01-21 00:00:00.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1381,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control, patologico, observaciones, derivacion) VALUES (37, 484, 1, 'RUPTURA DE MEMBRANAS. SALMONELA. BEBE CON PATOLOGÌA.', 'SALTA')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1382,
                    "SELECT COUNT(*) FROM etmis_personas WHERE etmis_personas.confirmada=1 AND etmis_personas.id_persona=184",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1383,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (485, '2022-03-31 16:21:56.000000', 100103, 4, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1384,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100103, 485, 2, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1385,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100103, 485, 3, 'C')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1386,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100103, 485, 4, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1387,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100103, 485, 1, 3, '2022-03-31 16:24:46.000000', 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1388,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100103, 485, 2, 3, '2022-03-31 16:24:46.000000', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1389,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100103, 485, 6, 3, '2022-03-31 16:24:46.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1390,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (372, 485, 35, 'S', ' ', 'N', 'N', 110, 60, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1391,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (486, '2022-03-31 16:28:10.000000', 100104, 4, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1392,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100104, 486, 2, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1393,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100104, 486, 3, 'C')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1394,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100104, 486, 4, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1395,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100104, 486, 1, 3, '2022-03-31 16:29:26.000000', 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1396,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100104, 486, 2, 3, '2022-03-31 16:29:26.000000', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1397,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100104, 486, 6, 3, '2022-03-31 16:29:26.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1398,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (373, 486, 39, 'S', ' ', 'N', 'N', 90, 50, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1399,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (487, '2022-03-31 17:03:03.000000', 241, 3, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1400,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (241, 487, 2, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1401,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (241, 487, 3, 'C')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1402,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (241, 487, 4, 'C')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1403,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (374, 487, 30, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1404,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (293, 'FLORES', 'FIDELA', '35263099', '1990-08-07 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1405,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (488, '2022-03-31 17:18:41.000000', 293, 1, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1406,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (293, 293, 7, 4, '00', '2022-03-31 17:18:41.000000', '', 12)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1407,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (252, 293, 488, 24, '2019-05-08 00:00:00.000000', 4, 3, 1, '2021-12-15 00:00:00.000000', '2022-09-21 00:00:00.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1408,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (293, 488, 2, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1409,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (293, 488, 3, 'P')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1410,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (293, 488, 1, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1411,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (293, 488, 4, 'C')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1412,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (293, 488, 1, 2, '2022-03-31 17:28:12.000000', 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1413,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (293, 488, 2, 2, '2022-03-31 17:28:12.000000', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1414,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (293, 488, 4, 2, '2022-03-31 17:28:12.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1415,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (293, 488, 5, 2, '2022-03-31 17:28:12.000000', 4)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1416,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (293, 488, 6, 2, '2022-03-31 17:28:12.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1417,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (293, 488, 9, 2, '2022-03-31 17:28:12.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1418,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (375, 488, 15, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1419,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=374",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1420,
                    "UPDATE antecedentes SET planificado=0 WHERE antecedentes.id_antecedente=252",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1421,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (294, 'SORIA', 'CARLINA', '42752453', '2001-05-24 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1422,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (489, '2022-03-31 18:46:53.000000', 294, 1, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1423,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (294, 294, 7, 4, '00', '2022-03-31 18:46:53.000000', '', 12)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1424,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, planificado, fum, fpp) VALUES (253, 294, 489, 0, 2, 1, 0, '2021-10-14 00:00:00.000000', '2022-07-21 00:00:00.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1425,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (294, 489, 2, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1426,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (294, 489, 3, 'C')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1427,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (294, 489, 1, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1428,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (294, 489, 4, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1429,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (294, 489, 1, 2, '2022-03-31 18:51:22.000000', 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1430,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (294, 489, 2, 2, '2022-03-31 18:51:22.000000', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1431,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (294, 489, 4, 2, '2022-03-31 18:51:22.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1432,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (294, 489, 5, 2, '2022-03-31 18:51:22.000000', 4)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1433,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (294, 489, 6, 2, '2022-03-31 18:51:22.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1434,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (294, 489, 8, 2, '2022-03-31 18:51:22.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1435,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (376, 489, 24, 'S', ' ', 'N', 'N', 85, 50, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1436,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (295, 'HERRERA', 'APARICIA', '37601336', '1992-12-01 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1437,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (490, '2022-03-31 18:53:51.000000', 295, 1, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1438,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (295, 295, 7, 4, '00', '2022-03-31 18:53:51.000000', '', 12)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1439,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (254, 295, 490, 14, '2020-07-19 00:00:00.000000', 7, 6, 1, '2021-10-07 00:00:00.000000', '2022-07-14 00:00:00.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1440,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (295, 490, 2, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1441,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (295, 490, 3, 'P')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1442,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (295, 490, 1, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1443,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (295, 490, 4, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1444,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (295, 490, 1, 2, '2022-03-31 18:58:17.000000', 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1445,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (295, 490, 2, 2, '2022-03-31 18:58:17.000000', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1446,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (295, 490, 4, 2, '2022-03-31 18:58:17.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1447,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (295, 490, 5, 2, '2022-03-31 18:58:17.000000', 4)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1448,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (295, 490, 6, 2, '2022-03-31 18:58:17.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1449,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (295, 490, 8, 2, '2022-03-31 18:58:17.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1450,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (295, 490, 9, 2, '2022-03-31 18:58:17.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1451,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (377, 490, 25, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1452,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (296, 'SENTURION', 'NOEMA', '45116150', '1988-08-30 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1453,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (491, '2022-03-31 19:03:00.000000', 296, 1, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1454,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (296, 296, 7, 4, '00', '2022-03-31 19:03:00.000000', '', 12)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1455,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (255, 296, 491, 13, '2020-01-01 00:00:00.000000', 12, 11, 1, '2021-09-05 00:00:00.000000', '2022-06-12 00:00:00.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1456,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (296, 491, 2, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1457,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (296, 491, 3, 'C')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1458,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (296, 491, 1, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1459,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (296, 491, 4, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1460,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (296, 491, 1, 3, '2022-03-31 19:08:43.000000', 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1461,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (296, 491, 2, 3, '2022-03-31 19:08:43.000000', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1462,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (296, 491, 4, 3, '2022-03-31 19:08:43.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1463,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (296, 491, 5, 3, '2022-03-31 19:08:43.000000', 4)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1464,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (296, 491, 6, 3, '2022-03-31 19:08:43.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1465,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (296, 491, 8, 3, '2022-03-31 19:08:43.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1466,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (296, 491, 9, 3, '2022-03-31 19:08:43.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1467,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (378, 491, 29, 'S', ' ', 'N', 'N', 100, 50, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1468,
                    "UPDATE personas SET apellido='SINTURION' WHERE personas.id_persona=296",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1469,
                    "SELECT id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais FROM ubicaciones WHERE id_ubicacion = '296'",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1470,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (297, 'SALAS', 'FRANCISCA BALVINA', '40354846', '1990-04-02 00:00:00.000000', 2, 12, 'F')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1471,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (492, '2022-03-31 19:11:21.000000', 297, 1, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1472,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (297, 297, 7, 4, '00', '2022-03-31 19:11:21.000000', '', 12)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1473,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (256, 297, 492, 17, '2007-08-16 00:00:00.000000', 2, 1, 1, '2021-09-15 00:00:00.000000', '2022-06-22 00:00:00.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1474,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (297, 492, 2, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1475,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (297, 492, 3, 'P')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1476,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (297, 492, 1, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1477,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (297, 492, 4, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1478,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (297, 492, 1, 3, '2022-03-31 19:13:28.000000', 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1479,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (297, 492, 2, 3, '2022-03-31 19:13:28.000000', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1480,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (297, 492, 4, 3, '2022-03-31 19:13:28.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1481,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (297, 492, 5, 3, '2022-03-31 19:13:28.000000', 4)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1482,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (297, 492, 6, 3, '2022-03-31 19:13:28.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1483,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (297, 492, 8, 3, '2022-03-31 19:13:28.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1484,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (297, 492, 9, 3, '2022-03-31 19:13:28.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1485,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (379, 492, 28, 'S', ' ', 'N', 'N', 100, 50, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1486,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (493, '2022-03-31 19:20:51.000000', 176, 2, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1487,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (176, 493, 2, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1488,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (176, 493, 3, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1489,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (176, 493, 4, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1490,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (176, 493, 1, 3, '2022-03-31 19:24:41.000000', 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1491,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (176, 493, 2, 3, '2022-03-31 19:24:41.000000', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1492,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (176, 493, 4, 3, '2022-03-31 19:24:41.000000', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1493,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (176, 493, 5, 3, '2022-03-31 19:24:41.000000', 4)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1494,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (176, 493, 6, 3, '2022-03-31 19:24:41.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1495,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (176, 493, 8, 3, '2022-03-31 19:24:41.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1496,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (176, 493, 9, 3, '2022-03-31 19:24:41.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1497,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (380, 493, 28, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1498,
                    "UPDATE personas SET documento='26714494' WHERE personas.id_persona=183",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1499,
                    "SELECT id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais FROM ubicaciones WHERE id_ubicacion = '183'",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1500,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (494, '2022-03-31 19:31:57.000000', 183, 2, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1501,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (183, 494, 2, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1502,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (183, 494, 3, 'C')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1503,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (183, 494, 4, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1504,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (381, 494, 38, 'S', ' ', 'N', 'N', 130, 70, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1505,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (495, '2022-03-31 19:38:04.000000', 172, 2, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1506,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (172, 495, 2, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1507,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (172, 495, 3, 'C')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1508,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (172, 495, 4, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1509,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (172, 495, 6, 2, '2022-03-31 19:39:51.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1510,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (172, 495, 9, 2, '2022-03-31 19:39:51.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1511,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (382, 495, 25, 'S', ' ', 'N', 'N', 95, 60, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1512,
                    "UPDATE control_embarazo SET eco='P', detalle_eco='LIQUIDO AMNIOTICO AUMENTADO. BVM: 8.2 CM.GLUCEMIA 77.' WHERE control_embarazo.id_control_embarazo=365",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1513,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=287 AND laboratorios_realizados.id_control=463 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1514,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=287 AND laboratorios_realizados.id_control=463 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1515,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=287 AND laboratorios_realizados.id_control=463 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1516,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=287 AND laboratorios_realizados.id_control=463 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1517,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='11.6' WHERE laboratorios_realizados.id_persona=287 AND laboratorios_realizados.id_control=463 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1518,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='B+' WHERE laboratorios_realizados.id_persona=287 AND laboratorios_realizados.id_control=463 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1519,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=366",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1520,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=285 AND laboratorios_realizados.id_control=461 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1521,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=285 AND laboratorios_realizados.id_control=461 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1522,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=285 AND laboratorios_realizados.id_control=461 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1523,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=285 AND laboratorios_realizados.id_control=461 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1524,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='8' WHERE laboratorios_realizados.id_persona=285 AND laboratorios_realizados.id_control=461 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1525,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='0+' WHERE laboratorios_realizados.id_persona=285 AND laboratorios_realizados.id_control=461 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1526,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=367",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1527,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=288 AND laboratorios_realizados.id_control=464 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1528,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=288 AND laboratorios_realizados.id_control=464 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1529,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=288 AND laboratorios_realizados.id_control=464 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1530,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=288 AND laboratorios_realizados.id_control=464 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1531,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='8.6' WHERE laboratorios_realizados.id_persona=288 AND laboratorios_realizados.id_control=464 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1532,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='-' WHERE laboratorios_realizados.id_persona=288 AND laboratorios_realizados.id_control=464 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1533,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='0+' WHERE laboratorios_realizados.id_persona=288 AND laboratorios_realizados.id_control=464 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1534,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=368",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1535,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=289 AND laboratorios_realizados.id_control=465 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1536,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=289 AND laboratorios_realizados.id_control=465 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1537,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=289 AND laboratorios_realizados.id_control=465 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1538,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=289 AND laboratorios_realizados.id_control=465 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1539,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='11.3' WHERE laboratorios_realizados.id_persona=289 AND laboratorios_realizados.id_control=465 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1540,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=369",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1541,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=290 AND laboratorios_realizados.id_control=466 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1542,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=290 AND laboratorios_realizados.id_control=466 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1543,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=290 AND laboratorios_realizados.id_control=466 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1544,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=290 AND laboratorios_realizados.id_control=466 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1545,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='9.3' WHERE laboratorios_realizados.id_persona=290 AND laboratorios_realizados.id_control=466 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1546,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='B+' WHERE laboratorios_realizados.id_persona=290 AND laboratorios_realizados.id_control=466 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1547,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=372",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1548,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=100103 AND laboratorios_realizados.id_control=485 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1549,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=100103 AND laboratorios_realizados.id_control=485 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1550,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='8.3' WHERE laboratorios_realizados.id_persona=100103 AND laboratorios_realizados.id_control=485 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1551,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=373",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1552,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=100104 AND laboratorios_realizados.id_control=486 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1553,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=100104 AND laboratorios_realizados.id_control=486 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1554,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='8.6' WHERE laboratorios_realizados.id_persona=100104 AND laboratorios_realizados.id_control=486 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1555,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=376",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1556,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=294 AND laboratorios_realizados.id_control=489 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1557,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=294 AND laboratorios_realizados.id_control=489 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1558,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=294 AND laboratorios_realizados.id_control=489 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1559,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=294 AND laboratorios_realizados.id_control=489 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1560,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='10' WHERE laboratorios_realizados.id_persona=294 AND laboratorios_realizados.id_control=489 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1561,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=294 AND laboratorios_realizados.id_control=489 AND laboratorios_realizados.id_laboratorio=8 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1562,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (496, '2022-04-01 02:36:59.000000', 294, 2, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1563,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (298, 'SORIA', 'CARLINA', '42752453', '2001-05-24 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1564,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, georeferencia) VALUES (497, '2022-04-01 02:39:27.000000', 298, 1, 1, '-22.2725026,-62.7125106')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1565,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (298, 298, 7, 4, '0', '2022-04-01 02:39:27.000000', '-22.2725026,-62.7125106', 12)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1566,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (257, 298, 497, 0, 1, 1, '2021-10-14 00:00:00.000000', '2022-07-21 00:00:00.000000')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1567,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (257, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1568,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (298, 497, 2, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1569,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (298, 497, 3, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1570,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (298, 497, 1, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1571,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (298, 497, 4, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1572,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (298, 497, 1, 2, '2022-04-01 02:44:54.000000', '2022-04-01 02:44:54.000000', 'N', 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1573,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (298, 497, 2, 2, '2022-04-01 02:44:54.000000', '2022-04-01 02:44:54.000000', 'N', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1574,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (298, 497, 4, 2, '2022-04-01 02:44:54.000000', '2022-04-01 02:44:54.000000', 'N', 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1575,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (298, 497, 5, 2, '2022-04-01 02:44:54.000000', '2022-04-01 02:44:54.000000', 'N', 4)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1576,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (298, 497, 6, 2, '2022-04-01 02:44:54.000000', '2022-04-01 02:44:54.000000', '10')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1577,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (298, 497, 9, 2, '2022-04-01 02:44:54.000000', '2022-04-01 02:44:54.000000', '0+')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1578,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (383, 497, 24, 'R', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1579,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=382",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1580,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='11' WHERE laboratorios_realizados.id_persona=172 AND laboratorios_realizados.id_control=495 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1581,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='0+' WHERE laboratorios_realizados.id_persona=172 AND laboratorios_realizados.id_control=495 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1582,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (498, '2022-04-01 02:45:55.000000', 172, 3, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1583,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (172, 498, 2, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1584,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (172, 498, 3, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1585,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (172, 498, 4, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1586,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (172, 498, 1, 2, '2022-04-01 02:46:31.000000', '2022-04-01 02:46:31.000000', 'N', 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1587,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (172, 498, 2, 2, '2022-04-01 02:46:31.000000', '2022-04-01 02:46:31.000000', 'N', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1588,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (384, 498, 25, 'N', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1589,
                    "UPDATE controles SET georeferencia='-22.2728885,-62.7169967' WHERE controles.id_control=498",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1590,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=381",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1591,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (499, '2022-04-01 02:46:53.000000', 183, 3, 1)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1592,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (183, 499, 2, 'S')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1593,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (183, 499, 3, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1594,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (183, 499, 4, 'N')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1595,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (183, 499, 1, 3, '2022-04-01 02:47:36.000000', '2022-04-01 02:47:36.000000', 'N', 3)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1596,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (183, 499, 2, 3, '2022-04-01 02:47:36.000000', '2022-04-01 02:47:36.000000', 'N', 2)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1597,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (183, 499, 5, 3, '2022-04-01 02:47:36.000000', '2022-04-01 02:47:36.000000', 'N', 4)",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1598,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (183, 499, 6, 3, '2022-04-01 02:47:36.000000', '2022-04-01 02:47:36.000000', '10')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1599,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (385, 499, 38, 'N', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1600,
                    "UPDATE controles SET georeferencia='-22.2728885,-62.7169967' WHERE controles.id_control=499",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1601,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=380",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1602,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=176 AND laboratorios_realizados.id_control=493 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1603,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=176 AND laboratorios_realizados.id_control=493 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1604,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=176 AND laboratorios_realizados.id_control=493 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1605,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=176 AND laboratorios_realizados.id_control=493 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1606,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='8.3' WHERE laboratorios_realizados.id_persona=176 AND laboratorios_realizados.id_control=493 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1607,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=176 AND laboratorios_realizados.id_control=493 AND laboratorios_realizados.id_laboratorio=8 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1608,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='O+' WHERE laboratorios_realizados.id_persona=176 AND laboratorios_realizados.id_control=493 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1609,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=379",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1610,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=297 AND laboratorios_realizados.id_control=492 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1611,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=297 AND laboratorios_realizados.id_control=492 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1612,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=297 AND laboratorios_realizados.id_control=492 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1613,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=297 AND laboratorios_realizados.id_control=492 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1614,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='10.3' WHERE laboratorios_realizados.id_persona=297 AND laboratorios_realizados.id_control=492 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1615,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=297 AND laboratorios_realizados.id_control=492 AND laboratorios_realizados.id_laboratorio=8 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1616,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='A+' WHERE laboratorios_realizados.id_persona=297 AND laboratorios_realizados.id_control=492 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1617,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=378",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1618,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=296 AND laboratorios_realizados.id_control=491 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1619,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=296 AND laboratorios_realizados.id_control=491 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1620,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=296 AND laboratorios_realizados.id_control=491 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1621,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=296 AND laboratorios_realizados.id_control=491 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1622,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='11' WHERE laboratorios_realizados.id_persona=296 AND laboratorios_realizados.id_control=491 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1623,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=296 AND laboratorios_realizados.id_control=491 AND laboratorios_realizados.id_laboratorio=8 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1624,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='0+' WHERE laboratorios_realizados.id_persona=296 AND laboratorios_realizados.id_control=491 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1625,
                    "UPDATE control_embarazo SET eco='P', detalle_eco='SOSPECHA DE RCIU. BF 21.1 SEMANAS. DERIVACION PARA REALIZAR ECOGRAFIA OBSTETRICA CON DOPPLER.' WHERE control_embarazo.id_control_embarazo=377",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1626,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=295 AND laboratorios_realizados.id_control=490 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1627,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=295 AND laboratorios_realizados.id_control=490 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1628,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=295 AND laboratorios_realizados.id_control=490 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1629,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=295 AND laboratorios_realizados.id_control=490 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1630,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='11' WHERE laboratorios_realizados.id_persona=295 AND laboratorios_realizados.id_control=490 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1631,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=295 AND laboratorios_realizados.id_control=490 AND laboratorios_realizados.id_laboratorio=8 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1632,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='0+' WHERE laboratorios_realizados.id_persona=295 AND laboratorios_realizados.id_control=490 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1633,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=370",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1634,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=291 AND laboratorios_realizados.id_control=467 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1635,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=291 AND laboratorios_realizados.id_control=467 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1636,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=291 AND laboratorios_realizados.id_control=467 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1637,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=291 AND laboratorios_realizados.id_control=467 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1638,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='9.3' WHERE laboratorios_realizados.id_persona=291 AND laboratorios_realizados.id_control=467 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1639,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='A+' WHERE laboratorios_realizados.id_persona=291 AND laboratorios_realizados.id_control=467 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1640,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=293 AND laboratorios_realizados.id_control=488 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1641,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=293 AND laboratorios_realizados.id_control=488 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1642,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=293 AND laboratorios_realizados.id_control=488 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1643,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=293 AND laboratorios_realizados.id_control=488 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1644,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='9.6' WHERE laboratorios_realizados.id_persona=293 AND laboratorios_realizados.id_control=488 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1645,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='0+' WHERE laboratorios_realizados.id_persona=293 AND laboratorios_realizados.id_control=488 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1646,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=371",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1647,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=292 AND laboratorios_realizados.id_control=468 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1648,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=292 AND laboratorios_realizados.id_control=468 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1649,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=292 AND laboratorios_realizados.id_control=468 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1650,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='N' WHERE laboratorios_realizados.id_persona=292 AND laboratorios_realizados.id_control=468 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1651,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='8' WHERE laboratorios_realizados.id_persona=292 AND laboratorios_realizados.id_control=468 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1652,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-01', resultado='0+' WHERE laboratorios_realizados.id_persona=292 AND laboratorios_realizados.id_control=468 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:09:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1653,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200105, '2022-03-27 22:59:28.000000', 146, 2, 1)",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1654,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200106, '2022-03-28 13:37:00.000000', 300059, 3, 1)",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1655,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (300059, 200106, 2, 'S')",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1656,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (300059, 200106, 3, 'P')",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1657,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (300059, 200106, 4, 'C')",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1658,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (300059, 200106, 1, 3, '2022-03-28 13:38:01.000000', 3)",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1659,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (300059, 200106, 2, 3, '2022-03-28 13:38:01.000000', 2)",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1660,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (300059, 200106, 5, 3, '2022-03-28 13:38:01.000000', 4)",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1661,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (300059, 200106, 6, 3, '2022-03-28 13:38:01.000000')",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1662,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200097, 200106, 36, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1663,
                    "UPDATE controles SET georeferencia='-22.0225331,-62.8234358' WHERE controles.id_control=200106",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1664,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200107, '2022-03-28 13:43:06.000000', 200029, 2, 1)",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1665,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200029, 200107, 2, 'N')",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1666,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200029, 200107, 3, 'C')",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1667,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200029, 200107, 4, 'C')",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1668,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200029, 200107, 1, 3, '2022-03-28 13:44:26.000000', 3)",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1669,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200029, 200107, 2, 3, '2022-03-28 13:44:26.000000', 2)",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1670,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200029, 200107, 5, 3, '2022-03-28 13:44:26.000000', 4)",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1671,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200029, 200107, 6, 3, '2022-03-28 13:44:26.000000')",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1672,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200098, 200107, 33, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1673,
                    "UPDATE controles SET georeferencia='-22.0225331,-62.8234358' WHERE controles.id_control=200107",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1674,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200108, '2022-03-28 13:46:37.000000', 300060, 2, 1)",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1675,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (300060, 200108, 2, 'S')",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1676,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (300060, 200108, 3, 'P')",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1677,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (300060, 200108, 4, 'P')",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1678,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (300060, 200108, 1, 3, '2022-03-28 13:47:20.000000', 3)",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1679,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (300060, 200108, 2, 3, '2022-03-28 13:47:20.000000', 2)",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1680,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (300060, 200108, 5, 3, '2022-03-28 13:47:20.000000', 4)",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1681,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (300060, 200108, 6, 3, '2022-03-28 13:47:20.000000')",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1682,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200099, 200108, 30, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1683,
                    "UPDATE controles SET georeferencia='-22.0225331,-62.8234358' WHERE controles.id_control=200108",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1684,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200109, '2022-03-28 15:11:24.000000', 200023, 3, 1)",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1685,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200023, 200109, 2, 'S')",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1686,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200023, 200109, 3, 'P')",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1687,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200023, 200109, 4, 'P')",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1688,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200023, 200109, 1, 3, '2022-03-28 15:12:36.000000', 3)",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1689,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200023, 200109, 2, 3, '2022-03-28 15:12:36.000000', 2)",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1690,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200023, 200109, 5, 3, '2022-03-28 15:12:36.000000', 4)",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1691,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200023, 200109, 6, 3, '2022-03-28 15:12:36.000000')",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1692,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200100, 200109, 37, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1693,
                    "UPDATE controles SET georeferencia='-22.0225331,-62.8234358' WHERE controles.id_control=200109",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1694,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200110, '2022-03-28 18:36:45.000000', 100185, 2, 1)",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1695,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100185, 200110, 2, 'S')",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1696,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100185, 200110, 3, 'P')",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1697,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100185, 200110, 4, 'C')",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1698,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200101, 200110, 17, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1699,
                    "UPDATE controles SET georeferencia='-21.9989008,-62.8320788' WHERE controles.id_control=200110",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1700,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200111, '2022-03-28 18:42:32.000000', 100159, 2, 1)",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1701,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100159, 200111, 2, 'S')",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1702,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100159, 200111, 3, 'C')",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1703,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100159, 200111, 4, 'P')",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1704,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100159, 200111, 1, 3, '2022-03-28 18:43:48.000000', 3)",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1705,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100159, 200111, 2, 3, '2022-03-28 18:43:48.000000', 2)",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1706,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100159, 200111, 5, 3, '2022-03-28 18:43:48.000000', 4)",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1707,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100159, 200111, 6, 3, '2022-03-28 18:43:48.000000')",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1708,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200102, 200111, 34, 'S', ' ', 'N', 'N', 100, 70, 'N', '')",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1709,
                    "UPDATE controles SET georeferencia='-22.1534439,-62.809476' WHERE controles.id_control=200111",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1710,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200098",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1711,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=200029 AND laboratorios_realizados.id_control=200107 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1712,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=200029 AND laboratorios_realizados.id_control=200107 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1713,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=200029 AND laboratorios_realizados.id_control=200107 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1714,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='9.3' WHERE laboratorios_realizados.id_persona=200029 AND laboratorios_realizados.id_control=200107 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1715,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200100",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1716,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=200023 AND laboratorios_realizados.id_control=200109 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1717,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=200023 AND laboratorios_realizados.id_control=200109 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1718,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=200023 AND laboratorios_realizados.id_control=200109 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1719,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='10.3' WHERE laboratorios_realizados.id_persona=200023 AND laboratorios_realizados.id_control=200109 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1720,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200099",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1721,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=300060 AND laboratorios_realizados.id_control=200108 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1722,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=300060 AND laboratorios_realizados.id_control=200108 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1723,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=300060 AND laboratorios_realizados.id_control=200108 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1724,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='11' WHERE laboratorios_realizados.id_persona=300060 AND laboratorios_realizados.id_control=200108 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1725,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200097",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1726,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=300059 AND laboratorios_realizados.id_control=200106 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1727,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=300059 AND laboratorios_realizados.id_control=200106 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1728,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='N' WHERE laboratorios_realizados.id_persona=300059 AND laboratorios_realizados.id_control=200106 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1729,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-30', resultado='9' WHERE laboratorios_realizados.id_persona=300059 AND laboratorios_realizados.id_control=200106 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1730,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200101",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1731,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200112, '2022-03-31 00:10:18.000000', 100162, 3, 1)",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1732,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100162, 200112, 2, 'S')",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1733,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100162, 200112, 3, 'C')",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1734,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100162, 200112, 4, 'C')",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1735,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100162, 200112, 1, 3, '2022-03-31 00:11:24.000000', '2022-03-31 00:11:24.000000', 'N', 3)",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1736,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100162, 200112, 2, 3, '2022-03-31 00:11:24.000000', '2022-03-31 00:11:24.000000', 'N', 2)",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1737,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100162, 200112, 5, 3, '2022-03-31 00:11:24.000000', '2022-03-31 00:11:24.000000', 'N', 4)",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1738,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (100162, 200112, 6, 3, '2022-03-31 00:11:24.000000', '2022-03-31 00:11:24.000000', '10')",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1739,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200103, 200112, 31, 'R', ' ', 'N', 'N', 90, 60, 'N', '')",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1740,
                    "UPDATE controles SET georeferencia='-22.2727119,-62.7169767' WHERE controles.id_control=200112",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1741,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200113, '2022-03-31 00:11:54.000000', 100162, 4, 1)",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1742,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100162, 200113, 2, 'S')",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1743,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100162, 200113, 3, 'N')",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1744,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100162, 200113, 4, 'C')",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1745,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100162, 200113, 1, 3, '2022-03-31 00:12:59.000000', '2022-03-31 00:12:59.000000', 'N', 3)",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1746,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100162, 200113, 2, 3, '2022-03-31 00:12:59.000000', '2022-03-31 00:12:59.000000', 'N', 2)",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1747,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100162, 200113, 5, 3, '2022-03-31 00:12:59.000000', '2022-03-31 00:12:59.000000', 'N', 4)",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1748,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (100162, 200113, 6, 3, '2022-03-31 00:12:59.000000', '2022-03-31 00:12:59.000000', '10')",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1749,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200104, 200113, 31, 'R', ' ', 'N', 'N', 90, 60, 'N', '')",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1750,
                    "UPDATE controles SET georeferencia='-22.2729359,-62.7170528' WHERE controles.id_control=200113",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1751,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200102",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1752,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=100159 AND laboratorios_realizados.id_control=200111 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1753,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=100159 AND laboratorios_realizados.id_control=200111 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1754,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='N' WHERE laboratorios_realizados.id_persona=100159 AND laboratorios_realizados.id_control=200111 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    1755,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-03-31', resultado='8.7' WHERE laboratorios_realizados.id_persona=100159 AND laboratorios_realizados.id_control=200111 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-01 07:15:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    3651,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=360",
                    "2022-04-18 12:05:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    3652,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-11', resultado='N' WHERE laboratorios_realizados.id_persona=279 AND laboratorios_realizados.id_control=455 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-18 12:05:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    3653,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-11', resultado='N' WHERE laboratorios_realizados.id_persona=279 AND laboratorios_realizados.id_control=455 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-18 12:05:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    3654,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (279, 455, 19, 2, '2022-04-11 17:25:52.000000', 1)",
                    "2022-04-18 12:05:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    3655,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (279, 455, 20, 2, '2022-04-11 17:25:52.000000', 1)",
                    "2022-04-18 12:05:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    3656,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (279, 455, 23, 2, '2022-04-11 17:25:52.000000', 1)",
                    "2022-04-18 12:05:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    3657,
                    "INSERT INTO etmis_personas (id_persona, id_etmi, id_control) VALUES (279, 1, 455)",
                    "2022-04-18 12:05:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    3658,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-11', resultado='P' WHERE laboratorios_realizados.id_persona=279 AND laboratorios_realizados.id_control=455 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-18 12:05:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    3659,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-11', resultado='N' WHERE laboratorios_realizados.id_persona=279 AND laboratorios_realizados.id_control=455 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-18 12:05:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    3660,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-11', resultado='9' WHERE laboratorios_realizados.id_persona=279 AND laboratorios_realizados.id_control=455 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-18 12:05:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    3661,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-11', resultado='0+' WHERE laboratorios_realizados.id_persona=279 AND laboratorios_realizados.id_control=455 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-18 12:05:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4420,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (400088, 'SAMBO', 'JOSEFA', '44565545', '1993-09-24 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-18 19:04:48",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4421,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, georeferencia) VALUES (400245, '2022-04-18 18:56:24.000000', 400088, 1, 1, '-22.2725026,-62.7125106')",
                    "2022-04-18 19:04:48",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4422,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (400088, 400088, 17, 3, '00', '2022-04-18 18:56:24.000000', '-22.2725026,-62.7125106', 12)",
                    "2022-04-18 19:04:48",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4423,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (299, 'SAMBO', 'JOSEFA', '44565545', '1993-09-24 00:00:00.000000', 1, 12, 'F')",
                    "2022-04-18 19:16:43",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4424,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, georeferencia) VALUES (500, '2022-04-18 19:06:28.000000', 299, 1, 1, '-22.2729211,-62.7170391')",
                    "2022-04-18 19:16:43",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4425,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (299, 299, 17, 3, '00', '2022-04-18 19:06:28.000000', '-22.2729211,-62.7170391', 12)",
                    "2022-04-18 19:16:43",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4426,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, abortos, planificado, fum, fpp) VALUES (258, 299, 500, 0, 5, 3, 1, 0, '2021-10-10 00:00:00.000000', '2022-07-17 00:00:00.000000')",
                    "2022-04-18 19:16:43",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4427,
                    "INSERT INTO antecedentes_apps (id_antecedente, id_app) VALUES (258, 8)",
                    "2022-04-18 19:16:43",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4428,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (299, 500, 2, 'N')",
                    "2022-04-18 19:16:43",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4429,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (299, 500, 3, 'N')",
                    "2022-04-18 19:16:43",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4430,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (299, 500, 1, 'N')",
                    "2022-04-18 19:16:43",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4431,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (299, 500, 4, 'N')",
                    "2022-04-18 19:16:43",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4432,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (299, 500, 1, 2, '2022-04-18 19:13:23.000000', 3)",
                    "2022-04-18 19:16:43",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4433,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (299, 500, 2, 2, '2022-04-18 19:13:23.000000', 2)",
                    "2022-04-18 19:16:43",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4434,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (299, 500, 5, 2, '2022-04-18 19:13:23.000000', 4)",
                    "2022-04-18 19:16:43",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4435,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (299, 500, 6, 2, '2022-04-18 19:13:23.000000')",
                    "2022-04-18 19:16:43",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4436,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (299, 500, 7, 2, '2022-04-18 19:13:23.000000')",
                    "2022-04-18 19:16:43",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4437,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (299, 500, 9, 2, '2022-04-18 19:13:23.000000')",
                    "2022-04-18 19:16:43",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4438,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (386, 500, 27, 'S', ' ', 'N', 'N', 100, 60, 'N', 'Es el tercer embarazo que se lo controla, se la asistió en uno de los partos donde se le detecto el chagas. Se realizó tratamiento de chagas y tenia terminado cuando vivia en Puntana, ahora esta ubicada en la comunidad Ancestrales cerca de Magdalena. Antecedentes: falleció el ultimo bebé por deshidratación y desnutrición.')",
                    "2022-04-18 19:16:43",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4439,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-19', resultado='-' WHERE laboratorios_realizados.id_persona=400004 AND laboratorios_realizados.id_control=400034 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4440,
                    "DELETE FROM etmis_personas WHERE etmis_personas.id_persona=300069 AND etmis_personas.id_etmi=2 AND etmis_personas.id_control=300080",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4441,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-19', resultado='N' WHERE laboratorios_realizados.id_persona=300069 AND laboratorios_realizados.id_control=300080 AND laboratorios_realizados.id_laboratorio=20 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4442,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-19', resultado='N' WHERE laboratorios_realizados.id_persona=300069 AND laboratorios_realizados.id_control=300080 AND laboratorios_realizados.id_laboratorio=21 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4443,
                    "DELETE FROM etmis_personas WHERE etmis_personas.id_persona=200008 AND etmis_personas.id_etmi=2 AND etmis_personas.id_control=200018",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4444,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-19', resultado='N' WHERE laboratorios_realizados.id_persona=200008 AND laboratorios_realizados.id_control=200018 AND laboratorios_realizados.id_laboratorio=20 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4445,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-19', resultado='N' WHERE laboratorios_realizados.id_persona=200008 AND laboratorios_realizados.id_control=200018 AND laboratorios_realizados.id_laboratorio=21 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4446,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200053",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4447,
                    "DELETE FROM etmis_personas WHERE etmis_personas.id_persona=149 AND etmis_personas.id_etmi=4 AND etmis_personas.id_control=197",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4448,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-19', resultado='N' WHERE laboratorios_realizados.id_persona=149 AND laboratorios_realizados.id_control=197 AND laboratorios_realizados.id_laboratorio=15 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4449,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-19', resultado='N' WHERE laboratorios_realizados.id_persona=149 AND laboratorios_realizados.id_control=197 AND laboratorios_realizados.id_laboratorio=22 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4450,
                    "DELETE FROM etmis_personas WHERE etmis_personas.id_persona=100050 AND etmis_personas.id_etmi=3 AND etmis_personas.id_control=100050",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4451,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-19', resultado='N;' WHERE laboratorios_realizados.id_persona=100050 AND laboratorios_realizados.id_control=100050 AND laboratorios_realizados.id_laboratorio=13 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4452,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-19', resultado='N' WHERE laboratorios_realizados.id_persona=100050 AND laboratorios_realizados.id_control=100050 AND laboratorios_realizados.id_laboratorio=18 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4453,
                    "DELETE FROM etmis_personas WHERE etmis_personas.id_persona=100052 AND etmis_personas.id_etmi=3 AND etmis_personas.id_control=100052",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4454,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-19', resultado='N;' WHERE laboratorios_realizados.id_persona=100052 AND laboratorios_realizados.id_control=100052 AND laboratorios_realizados.id_laboratorio=13 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4455,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-19', resultado='N' WHERE laboratorios_realizados.id_persona=100052 AND laboratorios_realizados.id_control=100052 AND laboratorios_realizados.id_laboratorio=18 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4456,
                    "DELETE FROM etmis_personas WHERE etmis_personas.id_persona=241 AND etmis_personas.id_etmi=1 AND etmis_personas.id_control=400244",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4457,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-19', resultado='P' WHERE laboratorios_realizados.id_persona=241 AND laboratorios_realizados.id_control=400244 AND laboratorios_realizados.id_laboratorio=19 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4458,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-19', resultado='P' WHERE laboratorios_realizados.id_persona=241 AND laboratorios_realizados.id_control=400244 AND laboratorios_realizados.id_laboratorio=20 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4459,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-19', resultado='P' WHERE laboratorios_realizados.id_persona=241 AND laboratorios_realizados.id_control=400244 AND laboratorios_realizados.id_laboratorio=23 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4460,
                    "DELETE FROM etmis_personas WHERE etmis_personas.id_persona=100095 AND etmis_personas.id_etmi=1 AND etmis_personas.id_control=100117",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4461,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-19', resultado='P' WHERE laboratorios_realizados.id_persona=100095 AND laboratorios_realizados.id_control=100117 AND laboratorios_realizados.id_laboratorio=19 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4462,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-19', resultado='P' WHERE laboratorios_realizados.id_persona=100095 AND laboratorios_realizados.id_control=100117 AND laboratorios_realizados.id_laboratorio=20 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4463,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-19', resultado='P' WHERE laboratorios_realizados.id_persona=100095 AND laboratorios_realizados.id_control=100117 AND laboratorios_realizados.id_laboratorio=23 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4464,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (400246, '2022-04-19 00:50:42.000000', 200025, 3, 2, '2021-12-08 00:00:00.000000', 1)",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4465,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (400088, 400246)",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4466,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=200025",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4467,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=386",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4468,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (400247, '2022-04-22 16:02:13.000000', 99, 3, 1)",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4469,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (99, 400247, 2, 'S')",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4470,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (99, 400247, 3, 'C')",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4471,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (99, 400247, 1, 'N')",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4472,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (99, 400247, 4, 'P')",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4473,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (400068, 400247, 35, 'R', ' ', 'N', 'N', 110, 70, 'N', 'ESTE CONTROL CORRESPONDE AL 28/3/2022')",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4474,
                    "UPDATE controles SET georeferencia='-34.195598,-60.7364317' WHERE controles.id_control=400247",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4475,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (400248, '2022-04-22 16:05:15.000000', 215, 3, 1)",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4476,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (215, 400248, 2, 'S')",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4477,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (215, 400248, 3, 'C')",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4478,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (215, 400248, 1, 'N')",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4479,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (215, 400248, 4, 'P')",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4480,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (400069, 400248, 38, 'R', ' ', 'N', 'N', 100, 70, 'N', 'ESTE CONTROL CORRESPONDE AL 28/3/2022')",
                    "2022-04-24 13:12:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4481,
                    "UPDATE controles SET georeferencia='-34.1955872,-60.7364111' WHERE controles.id_control=400248",
                    "2022-04-24 13:12:27",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4482,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos, georeferencia) VALUES (400249, '2022-04-22 16:16:25.000000', 100028, 3, 2, '2022-03-08 00:00:00.000000', 1, '-34.390016,-60.5487104')",
                    "2022-04-24 13:12:27",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4483,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (400089, 400249)",
                    "2022-04-24 13:12:27",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4484,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=100028",
                    "2022-04-24 13:12:27",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4485,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-22', resultado='N' WHERE laboratorios_realizados.id_persona=100076 AND laboratorios_realizados.id_control=100259 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-04-24 13:12:27",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4486,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-22', resultado='N' WHERE laboratorios_realizados.id_persona=100076 AND laboratorios_realizados.id_control=100259 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-04-24 13:12:27",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4487,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-22', resultado='N' WHERE laboratorios_realizados.id_persona=100076 AND laboratorios_realizados.id_control=100259 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-04-24 13:12:27",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4488,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (400250, '2022-04-22 16:20:13.000000', 100076, 5, 1)",
                    "2022-04-24 13:12:27",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4489,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100076, 400250, 2, 'S')",
                    "2022-04-24 13:12:27",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4490,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100076, 400250, 3, 'P')",
                    "2022-04-24 13:12:27",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4491,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100076, 400250, 1, 'N')",
                    "2022-04-24 13:12:27",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4492,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100076, 400250, 4, 'P')",
                    "2022-04-24 13:12:27",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4493,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100076, 400250, 1, 3, '2022-04-22 16:22:01.000000', '2022-04-22 16:22:01.000000', 'N', 3)",
                    "2022-04-24 13:12:27",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4494,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100076, 400250, 2, 3, '2022-04-22 16:22:01.000000', '2022-04-22 16:22:01.000000', 'N', 2)",
                    "2022-04-24 13:12:27",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4495,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100076, 400250, 5, 3, '2022-04-22 16:22:01.000000', '2022-04-22 16:22:01.000000', 'N', 4)",
                    "2022-04-24 13:12:27",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4496,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (100076, 400250, 6, 3, '2022-04-22 16:22:01.000000', '2022-04-22 16:22:01.000000', '7')",
                    "2022-04-24 13:12:27",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4497,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (400070, 400250, 41, 'R', ' ', 'N', 'N', 100, 60, 'N', 'ESTE CONTROL CORRESPONDE AL 31/3/2022 EG 38 SEM')",
                    "2022-04-24 13:12:27",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4498,
                    "UPDATE controles SET georeferencia='-34.1955873,-60.7364111' WHERE controles.id_control=400250",
                    "2022-04-24 13:12:27",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4499,
                    "UPDATE personas SET fecha_nacimiento='2003-02-03' WHERE personas.id_persona=100130",
                    "2022-04-24 13:12:27",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4500,
                    "SELECT id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais FROM ubicaciones WHERE id_ubicacion = '100130'",
                    "2022-04-24 13:12:27",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4501,
                    "UPDATE antecedentes SET fum='2021-07-21', fpp='2022-04-20' WHERE antecedentes.id_antecedente=100126",
                    "2022-04-24 13:12:27",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4502,
                    "UPDATE personas SET documento='46231140' WHERE personas.id_persona=100173",
                    "2022-04-24 13:12:27",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4503,
                    "SELECT id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais FROM ubicaciones WHERE id_ubicacion = '100173'",
                    "2022-04-24 13:12:27",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4504,
                    "UPDATE personas SET documento='46055796' WHERE personas.id_persona=230",
                    "2022-04-24 13:12:27",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4505,
                    "SELECT id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais FROM ubicaciones WHERE id_ubicacion = '230'",
                    "2022-04-24 13:12:27",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4506,
                    "UPDATE personas SET apellido='ALEJO' WHERE personas.id_persona=231",
                    "2022-04-24 13:12:27",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4507,
                    "SELECT id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais FROM ubicaciones WHERE id_ubicacion = '231'",
                    "2022-04-24 13:12:27",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4508,
                    "UPDATE personas SET nombre='MARIA EMILIANA' WHERE personas.id_persona=100137",
                    "2022-04-24 13:12:27",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4509,
                    "SELECT id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais FROM ubicaciones WHERE id_ubicacion = '100137'",
                    "2022-04-24 13:12:27",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4510,
                    "UPDATE personas SET nombre='NORMA' WHERE personas.id_persona=296",
                    "2022-04-24 13:12:27",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4511,
                    "SELECT id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais FROM ubicaciones WHERE id_ubicacion = '296'",
                    "2022-04-24 13:12:27",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4512,
                    "UPDATE personas SET fecha_nacimiento='2003-05-05' WHERE personas.id_persona=290",
                    "2022-04-24 13:12:27",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4513,
                    "SELECT id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais FROM ubicaciones WHERE id_ubicacion = '290'",
                    "2022-04-24 13:12:27",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4514,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (400251, '2022-05-02 11:40:07.000000', 5, 5, 1)",
                    "2022-05-14 20:14:29",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4515,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=386",
                    "2022-05-17 22:57:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4516,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-24', resultado='N' WHERE laboratorios_realizados.id_persona=299 AND laboratorios_realizados.id_control=500 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-05-17 22:57:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4517,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-24', resultado='N' WHERE laboratorios_realizados.id_persona=299 AND laboratorios_realizados.id_control=500 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-05-17 22:57:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4518,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-24', resultado='N' WHERE laboratorios_realizados.id_persona=299 AND laboratorios_realizados.id_control=500 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-05-17 22:57:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4519,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-24', resultado='8.7' WHERE laboratorios_realizados.id_persona=299 AND laboratorios_realizados.id_control=500 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-05-17 22:57:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4520,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-24', resultado='95' WHERE laboratorios_realizados.id_persona=299 AND laboratorios_realizados.id_control=500 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-05-17 22:57:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4521,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-04-24', resultado='0+' WHERE laboratorios_realizados.id_persona=299 AND laboratorios_realizados.id_control=500 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-05-17 22:57:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4522,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (501, '2022-05-17 20:02:33.000000', 100120, 5, 2, '2022-04-19 00:00:00.000000', 1)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4523,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (300, 'AMAYA', 'RN', 'XXX', '2022-04-19 00:00:00.000000', 12, 'M', 100120, 1)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4524,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (300, 300, 24, 3, '0', '2022-05-17 20:02:33.000000', '', 12)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4525,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (502, '2022-05-17 20:02:33.000000', 300, 1, 3)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4526,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (12, 502, 42, 3.0, 1)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4527,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (38, 501)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4528,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=100120",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4529,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (503, '2022-05-17 20:06:46.000000', 8, 4, 2, '2022-02-22 00:00:00.000000', 1)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4530,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (301, 'GARCIA', 'FABIAN', 'XXX', '2022-02-22 00:00:00.000000', 12, 'M', 8, 1)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4531,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (301, 301, 24, 3, '7', '2022-05-17 20:06:46.000000', '', 12)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4532,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (504, '2022-05-17 20:06:46.000000', 301, 1, 3)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4533,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (13, 504, 50, 3.0, 1)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4534,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (39, 503)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4535,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=8",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4536,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (505, '2022-05-17 20:09:50.000000', 100119, 6, 2, '2022-05-02 00:00:00.000000', 2)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4537,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (302, 'ZARATE', 'RN', 'XXX', '2022-05-02 00:00:00.000000', 12, 'F', 100119, 1)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4538,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (302, 302, 24, 3, '0', '2022-05-17 20:09:50.000000', '', 12)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4539,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (506, '2022-05-17 20:09:50.000000', 302, 1, 3)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4540,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (14, 506, 39, 4.0, 1)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4541,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (40, 505)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4542,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=100119",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4543,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (507, '2022-05-17 20:11:54.000000', 9, 5, 2, '2022-02-05 00:00:00.000000', 1)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4544,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (303, 'PENA', 'ALEXIA ADRIANA', 'XXX', '2022-02-05 00:00:00.000000', 12, 'F', 9, 1)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4545,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (303, 303, 24, 3, '8', '2022-05-17 20:11:54.000000', '', 12)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4546,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (508, '2022-05-17 20:11:54.000000', 303, 1, 3)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4547,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (15, 508, 47, 4.0, 1)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4548,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (41, 507)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4549,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=9",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4550,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (509, '2022-05-17 20:14:27.000000', 400003, 2, 2, '2022-02-27 00:00:00.000000', 1)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4551,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (304, 'GOMEZ', 'DAMIAN ROGELIO', 'XXX', '2022-02-27 00:00:00.000000', 12, 'M', 400003, 1)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4552,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (304, 304, 24, 3, '2', '2022-05-17 20:14:27.000000', '', 12)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4553,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (510, '2022-05-17 20:14:27.000000', 304, 1, 3)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4554,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (16, 510, 48, 3.0, 1)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4555,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (42, 509)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4556,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=400003",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4557,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (511, '2022-05-17 20:16:34.000000', 100156, 3, 2, '2022-03-08 00:00:00.000000', 2)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4558,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (305, 'MIRANDA', 'RN', 'XXX', '2022-03-08 00:00:00.000000', 12, 'F', 100156, 1)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4559,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (305, 305, 24, 3, '0', '2022-05-17 20:16:34.000000', '', 12)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4560,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (512, '2022-05-17 20:16:34.000000', 305, 1, 3)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4561,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (17, 512, 44, 3.0, 1)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4562,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (43, 511)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4563,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=100156",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4564,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (513, '2022-05-17 20:41:52.000000', 100080, 4, 2, '2022-04-05 00:00:00.000000', 1)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4565,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (306, 'PAZ', 'RN', 'XXX', '2022-04-05 00:00:00.000000', 12, 'M', 100080, 1)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4566,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (306, 306, 24, 3, '0', '2022-05-17 20:41:52.000000', '', 12)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4567,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (514, '2022-05-17 20:41:52.000000', 306, 1, 3)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4568,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (18, 514, 45, 3.0, 1)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4569,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (44, 513)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4570,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=100080",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4571,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (515, '2022-05-17 20:44:13.000000', 100078, 3, 2, '2022-03-22 00:00:00.000000', 1)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4572,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (307, 'JUAREZ', 'TOMAS DARIO', 'XXX', '2022-03-22 00:00:00.000000', 12, 'M', 100078, 1)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4573,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (307, 307, 24, 3, '0', '2022-05-17 20:44:13.000000', '', 12)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4574,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (516, '2022-05-17 20:44:13.000000', 307, 1, 3)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4575,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (19, 516, 45, 3.0, 1)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4576,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (45, 515)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4577,
                    "SELECT COUNT(*) FROM etmis_personas WHERE etmis_personas.confirmada=1 AND etmis_personas.id_persona=100078",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4578,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (517, '2022-05-17 20:49:20.000000', 278, 2, 2, '2022-04-09 00:00:00.000000', 1)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4579,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (308, 'VACA', 'EMMA FRANCHESCA EMILIANA', 'XXX', '2022-04-09 00:00:00.000000', 12, 'F', 278, 1)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4580,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (308, 308, 24, 3, '0', '2022-05-17 20:49:20.000000', '', 12)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4581,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (518, '2022-05-17 20:49:20.000000', 308, 1, 3)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4582,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (20, 518, 45, 3.0, 1)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4583,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (46, 517)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4584,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=278",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4585,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (519, '2022-05-17 20:51:30.000000', 100161, 3, 2, '2022-04-25 00:00:00.000000', 1)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4586,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (309, 'MIRNADA', 'IRENE ROCIO', 'XXX', '2022-04-25 00:00:00.000000', 12, 'F', 100161, 1)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4587,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (309, 309, 24, 3, '0', '2022-05-17 20:51:30.000000', '', 12)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4588,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (520, '2022-05-17 20:51:30.000000', 309, 1, 3)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4589,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (21, 520, 41, 3.0, 1)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4590,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (47, 519)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4591,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=100161",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4592,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (521, '2022-05-17 20:52:57.000000', 100083, 3, 2, '2022-04-27 00:00:00.000000', 2)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4593,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (310, 'ROMERO', 'RN', 'XXX', '2022-04-27 00:00:00.000000', 12, 'M', 100083, 1)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4594,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (310, 310, 24, 3, '0', '2022-05-17 20:52:57.000000', '', 12)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4595,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (522, '2022-05-17 20:52:57.000000', 310, 1, 3)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4596,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (22, 522, 41, 3.0, 1)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4597,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (48, 521)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4598,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=100083",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4599,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (523, '2022-05-17 20:58:36.000000', 100181, 2, 2, '2022-03-26 00:00:00.000000', 2)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4600,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (311, 'TORRES', 'TISIANO GERARDO', 'XXX', '2022-03-26 00:00:00.000000', 12, 'M', 100181, 1)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4601,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (311, 311, 24, 3, '00', '2022-05-17 20:58:36.000000', '', 12)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4602,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (524, '2022-05-17 20:58:36.000000', 311, 1, 3)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4603,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (23, 524, 44, 2.0, 1)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4604,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (49, 523)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4605,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=100181",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4606,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (525, '2022-05-17 21:01:10.000000', 100079, 3, 2, '2022-04-06 00:00:00.000000', 1)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4607,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (312, 'TORRES', 'RN', 'XXX', '2022-04-06 00:00:00.000000', 12, 'F', 100079, 1)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4608,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (312, 312, 24, 3, '0', '2022-05-17 21:01:10.000000', '', 12)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4609,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (526, '2022-05-17 21:01:10.000000', 312, 1, 3)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4610,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (24, 526, 46, 3.0, 1)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4611,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (50, 525)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4612,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=100079",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4613,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (527, '2022-05-17 21:03:05.000000', 100157, 4, 2, '2022-02-10 00:00:00.000000', 2)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4614,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (313, 'CIRIACO', 'MARCOS GONZALO', 'XXX', '2022-02-10 00:00:00.000000', 12, 'M', 100157, 1)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4615,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (313, 313, 24, 3, '0', '2022-05-17 21:03:05.000000', '', 12)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4616,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (528, '2022-05-17 21:03:05.000000', 313, 1, 3)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4617,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (25, 528, 46, 2.0, 1)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4618,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (51, 527)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4619,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=100157",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4620,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (529, '2022-05-17 21:05:52.000000', 138, 4, 2, '2022-03-03 00:00:00.000000', 2)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4621,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (314, 'MENDEZ', 'MARIA ALEJANDRA', 'XXX', '2022-03-03 00:00:00.000000', 12, 'F', 138, 1)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4622,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (314, 314, 29, 3, '12', '2022-05-17 21:05:52.000000', '', 12)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4623,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (530, '2022-05-17 21:05:52.000000', 314, 1, 3)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4624,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (26, 530, 40, 2.0, 1)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4625,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (52, 529)",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4626,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=138",
                    "2022-05-17 22:57:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4627,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (531, '2022-05-17 21:07:38.000000', 96, 4, 2, '2022-02-26 00:00:00.000000', 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4628,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (315, 'SEGUNDO', 'EMANUEL AMBROSIO', 'XXX', '2022-02-26 00:00:00.000000', 12, 'M', 96, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4629,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (315, 315, 18, 3, '11', '2022-05-17 21:07:38.000000', '', 12)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4630,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (532, '2022-05-17 21:07:38.000000', 315, 1, 3)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4631,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (27, 532, 49, 3.0, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4632,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (53, 531)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4633,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=96",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4634,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (533, '2022-05-17 21:10:08.000000', 200029, 3, 2, '2022-04-27 00:00:00.000000', 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4635,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (316, 'SANDOVAL', 'HIDALGO', 'XXX', '2022-04-27 00:00:00.000000', 12, 'M', 200029, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4636,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (316, 316, 18, 3, '00', '2022-05-17 21:10:08.000000', '', 12)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4637,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (534, '2022-05-17 21:10:08.000000', 316, 1, 3)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4638,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (28, 534, 40, 3.0, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4639,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (54, 533)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4640,
                    "SELECT COUNT(*) FROM etmis_personas WHERE etmis_personas.confirmada=1 AND etmis_personas.id_persona=200029",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4641,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (535, '2022-05-17 21:12:34.000000', 300010, 2, 2, '2022-04-01 00:00:00.000000', 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4642,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (317, 'NOGAL', 'RN', 'XXX', '2022-04-01 00:00:00.000000', 12, 'M', 300010, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4643,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (317, 317, 18, 3, '00', '2022-05-17 21:12:34.000000', '', 12)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4644,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (536, '2022-05-17 21:12:34.000000', 317, 1, 3)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4645,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (29, 536, 46, 3.0, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4646,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (55, 535)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4647,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=300010",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4648,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (537, '2022-05-17 21:14:16.000000', 200024, 2, 2, '2022-03-08 00:00:00.000000', 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4649,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (318, 'PAEZ', 'RN', 'XXX', '2022-03-08 00:00:00.000000', 12, 'F', 200024, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4650,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (318, 318, 18, 3, '00', '2022-05-17 21:14:16.000000', '', 12)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4651,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (538, '2022-05-17 21:14:16.000000', 318, 1, 3)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4652,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (30, 538, 48, 2.0, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4653,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (56, 537)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4654,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=200024",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4655,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-05-17', resultado='N' WHERE laboratorios_realizados.id_persona=162 AND laboratorios_realizados.id_control=332 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4656,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-05-17', resultado='N' WHERE laboratorios_realizados.id_persona=162 AND laboratorios_realizados.id_control=332 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4657,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-05-17', resultado='96' WHERE laboratorios_realizados.id_persona=162 AND laboratorios_realizados.id_control=332 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4658,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (539, '2022-05-17 21:17:12.000000', 162, 3, 2, '2022-03-05 00:00:00.000000', 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4659,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (319, 'PEREZ', 'RN', 'XXX', '2022-03-05 00:00:00.000000', 12, 'M', 162, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4660,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (319, 319, 18, 3, '39', '2022-05-17 21:17:12.000000', '', 12)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4661,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (540, '2022-05-17 21:17:12.000000', 319, 1, 3)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4662,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (31, 540, 51, 2.0, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4663,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (57, 539)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4664,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=162",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4665,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (541, '2022-05-17 21:19:25.000000', 300058, 3, 2, '2022-04-07 00:00:00.000000', 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4666,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (320, 'PEREZ', 'RN', 'XXX', '2022-04-07 00:00:00.000000', 12, 'M', 300058, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4667,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (320, 320, 17, 3, '00', '2022-05-17 21:19:25.000000', '', 12)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4668,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (542, '2022-05-17 21:19:25.000000', 320, 1, 3)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4669,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (32, 542, 43, 3190.0, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4670,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (58, 541)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4671,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=300058",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4672,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (543, '2022-05-17 21:21:19.000000', 98, 3, 2, '2022-03-13 00:00:00.000000', 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4673,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (321, 'PEREZ', 'RN', 'XXX', '2022-03-13 00:00:00.000000', 12, 'M', 98, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4674,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (321, 321, 18, 3, '33', '2022-05-17 21:21:19.000000', '', 12)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4675,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (544, '2022-05-17 21:21:19.000000', 321, 1, 3)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4676,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (33, 544, 48, 2.0, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4677,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (59, 543)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4678,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=98",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4679,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (545, '2022-05-17 21:22:58.000000', 300019, 2, 2, '2022-03-17 00:00:00.000000', 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4680,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (322, 'ROLDAN', 'RN', 'XXX', '2022-03-17 00:00:00.000000', 12, 'F', 300019, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4681,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (322, 322, 18, 3, '00', '2022-05-17 21:22:58.000000', '', 12)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4682,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (546, '2022-05-17 21:22:58.000000', 322, 1, 3)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4683,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (34, 546, 49, 3.0, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4684,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (60, 545)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4685,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=300019",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4686,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (547, '2022-05-17 21:24:45.000000', 100, 2, 2, '2022-03-15 00:00:00.000000', 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4687,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (323, 'SANDOVAL', 'RN', 'XXX', '2022-03-15 00:00:00.000000', 12, 'F', 100, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4688,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (323, 323, 18, 3, '11', '2022-05-17 21:24:45.000000', '', 12)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4689,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (548, '2022-05-17 21:24:45.000000', 323, 1, 3)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4690,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (35, 548, 48, 2.0, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4691,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (61, 547)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4692,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=100",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4693,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (549, '2022-05-17 21:48:05.000000', 105, 3, 2, '2022-03-10 00:00:00.000000', 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4694,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (324, 'SEGUNDO', 'RN', 'XXX', '2022-03-10 00:00:00.000000', 12, 'M', 105, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4695,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (324, 324, 18, 3, '44', '2022-05-17 21:48:05.000000', '', 12)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4696,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (550, '2022-05-17 21:48:05.000000', 324, 1, 3)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4697,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (36, 550, 49, 3.0, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4698,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (62, 549)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4699,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=105",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4700,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (551, '2022-05-17 21:50:00.000000', 200023, 4, 2, '2022-04-01 00:00:00.000000', 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4701,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (325, 'SEGUNDO', 'RN', 'XXX', '2022-04-01 00:00:00.000000', 12, 'M', 200023, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4702,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (325, 325, 18, 3, '00', '2022-05-17 21:50:00.000000', '', 12)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4703,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (552, '2022-05-17 21:50:00.000000', 325, 1, 3)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4704,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (37, 552, 44, 0.0, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4705,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (63, 551)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4706,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=200023",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4707,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (553, '2022-05-17 21:52:07.000000', 163, 2, 2, '2022-05-10 00:00:00.000000', 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4708,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (326, 'TORREZ', 'RN', 'XXX', '2022-05-10 00:00:00.000000', 12, 'F', 163, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4709,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (326, 326, 18, 3, '39', '2022-05-17 21:52:07.000000', '', 12)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4710,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (554, '2022-05-17 21:52:07.000000', 326, 1, 3)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4711,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (38, 554, 41, 2.0, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4712,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (64, 553)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4713,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=163",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4714,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-05-17', resultado='0+' WHERE laboratorios_realizados.id_persona=100013 AND laboratorios_realizados.id_control=100013 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4715,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (555, '2022-05-17 21:56:24.000000', 100013, 3, 2, '2022-02-17 00:00:00.000000', 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4716,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (327, 'VILLAGRA', 'RN', 'XXX', '2022-02-17 00:00:00.000000', 12, 'M', 100013, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4717,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (327, 327, 18, 3, '0', '2022-05-17 21:56:24.000000', '', 12)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4718,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (556, '2022-05-17 21:56:24.000000', 327, 1, 3)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4719,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (39, 556, 50, 3.0, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4720,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (65, 555)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4721,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=100013",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4722,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (557, '2022-05-17 22:01:15.000000', 103, 4, 2, '2022-04-19 00:00:00.000000', 2)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4723,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (328, 'ZAMBO', 'RN', 'XXX', '2022-04-19 00:00:00.000000', 12, 'M', 103, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4724,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (328, 328, 18, 3, '333', '2022-05-17 22:01:15.000000', '', 12)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4725,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (558, '2022-05-17 22:01:15.000000', 328, 1, 3)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4726,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (40, 558, 43, 3.0, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4727,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (66, 557)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4728,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=103",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4729,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (559, '2022-05-17 22:12:42.000000', 100146, 3, 2, '2022-03-18 00:00:00.000000', 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4730,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (329, 'PEREZ', 'JERUSA', 'XXX', '2022-03-18 00:00:00.000000', 12, 'F', 100146, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4731,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (329, 329, 36, 3, '0', '2022-05-17 22:12:42.000000', '', 12)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4732,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (560, '2022-05-17 22:12:42.000000', 329, 1, 3)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4733,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (41, 560, 46, 3.0, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4734,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (67, 559)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4735,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=100146",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4736,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (561, '2022-05-17 22:14:54.000000', 100145, 3, 2, '2022-03-18 00:00:00.000000', 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4737,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (330, 'JUAREZ', 'RN', 'XXX', '2022-03-18 00:00:00.000000', 12, 'M', 100145, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4738,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (330, 330, 36, 3, '0', '2022-05-17 22:14:54.000000', '', 12)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4739,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (562, '2022-05-17 22:14:54.000000', 330, 1, 3)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4740,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (42, 562, 46, 3.0, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4741,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (68, 561)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4742,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=100145",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4743,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-05-17', resultado='11' WHERE laboratorios_realizados.id_persona=118 AND laboratorios_realizados.id_control=143 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4744,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (563, '2022-05-17 22:17:17.000000', 118, 4, 2, '2022-04-15 00:00:00.000000', 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4745,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (331, 'CAROSSA', 'RN', 'XXX', '2022-04-15 00:00:00.000000', 12, 'M', 118, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4746,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (331, 331, 37, 3, '22', '2022-05-17 22:17:17.000000', '', 12)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4747,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (564, '2022-05-17 22:17:17.000000', 331, 1, 3)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4748,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (43, 564, 45, 2.0, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4749,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (69, 563)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4750,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=118",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4751,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (565, '2022-05-17 22:20:52.000000', 100141, 5, 2, '2022-04-13 00:00:00.000000', 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4752,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (332, 'CARRIZO', 'RN', 'XXX', '2022-04-13 00:00:00.000000', 12, 'F', 100141, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4753,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (332, 332, 37, 3, '0', '2022-05-17 22:20:52.000000', '', 12)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4754,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (566, '2022-05-17 22:20:52.000000', 332, 1, 3)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4755,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (44, 566, 45, 3.0, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4756,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (70, 565)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4757,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=100141",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4758,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (567, '2022-05-17 22:24:38.000000', 100140, 3, 2, '2022-03-12 00:00:00.000000', 2)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4759,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (333, 'CARRIZO', 'GUINO ANDRES', 'XXX', '2022-03-12 00:00:00.000000', 12, 'M', 100140, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4760,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (333, 333, 37, 3, '0', '2022-05-17 22:24:38.000000', '', 12)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4761,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (568, '2022-05-17 22:24:38.000000', 333, 1, 3)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4762,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (45, 568, 48, 3.0, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4763,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (71, 567)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4764,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=100140",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4765,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (569, '2022-05-17 22:34:21.000000', 38, 5, 2, '2022-05-07 00:00:00.000000', 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4766,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (334, 'HERRERA', 'CRISTINA', 'XXX', '2022-05-07 00:00:00.000000', 12, 'F', 38, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4767,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (334, 334, 37, 3, '2', '2022-05-17 22:34:21.000000', '', 12)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4768,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (570, '2022-05-17 22:34:21.000000', 334, 1, 3)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4769,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (46, 570, 49, 2.0, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4770,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (72, 569)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4771,
                    "SELECT COUNT(*) FROM etmis_personas WHERE etmis_personas.confirmada=1 AND etmis_personas.id_persona=38",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4772,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (571, '2022-05-17 22:37:01.000000', 57, 4, 2, '2022-01-18 00:00:00.000000', 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4773,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (335, 'GONZALEZ', 'RN', 'XXX', '2022-01-18 00:00:00.000000', 12, 'M', 57, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4774,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (335, 335, 37, 3, '7', '2022-05-17 22:37:01.000000', '', 12)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4775,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (572, '2022-05-17 22:37:01.000000', 335, 1, 3)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4776,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (47, 572, 50, 3.0, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4777,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (73, 571)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4778,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=57",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4779,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200052",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4780,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-05-17', resultado='12' WHERE laboratorios_realizados.id_persona=57 AND laboratorios_realizados.id_control=57 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4781,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-05-17', resultado='N' WHERE laboratorios_realizados.id_persona=41 AND laboratorios_realizados.id_control=354 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4782,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-05-17', resultado='N' WHERE laboratorios_realizados.id_persona=41 AND laboratorios_realizados.id_control=354 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4783,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-05-17', resultado='11' WHERE laboratorios_realizados.id_persona=41 AND laboratorios_realizados.id_control=41 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4784,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=266",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4785,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-05-17', resultado='N' WHERE laboratorios_realizados.id_persona=109 AND laboratorios_realizados.id_control=359 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4786,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-05-17', resultado='N' WHERE laboratorios_realizados.id_persona=109 AND laboratorios_realizados.id_control=359 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4787,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-05-17', resultado='98' WHERE laboratorios_realizados.id_persona=109 AND laboratorios_realizados.id_control=359 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4788,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (573, '2022-05-17 22:45:12.000000', 109, 4, 2, '2022-03-01 00:00:00.000000', 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4789,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (336, 'MARTIN', 'RN', 'XXX', '2022-03-01 00:00:00.000000', 12, 'F', 109, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4790,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (336, 336, 37, 3, '11', '2022-05-17 22:45:12.000000', '', 12)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4791,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (574, '2022-05-17 22:45:12.000000', 336, 1, 3)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4792,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (48, 574, 49, 4.0, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4793,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (74, 573)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4794,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=109",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4795,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (575, '2022-05-17 22:47:44.000000', 100139, 2, 2, '2022-03-27 00:00:00.000000', 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4796,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (337, 'NATO', 'RN', 'XXX', '2022-03-27 00:00:00.000000', 12, 'F', 100139, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4797,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (337, 337, 37, 3, '0', '2022-05-17 22:47:44.000000', '', 12)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4798,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (576, '2022-05-17 22:47:44.000000', 337, 1, 3)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4799,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (49, 576, 50, 3.0, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4800,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (75, 575)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4801,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=100139",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4802,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-05-17', resultado='0+' WHERE laboratorios_realizados.id_persona=100144 AND laboratorios_realizados.id_control=100222 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4803,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (577, '2022-05-17 22:50:32.000000', 100144, 3, 2, '2022-03-29 00:00:00.000000', 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4804,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (338, 'ROMAN', 'GIANNA MONSERRAT', 'XXX', '2022-03-29 00:00:00.000000', 12, 'F', 100144, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4805,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (338, 338, 37, 3, '0', '2022-05-17 22:50:32.000000', '', 12)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4806,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (578, '2022-05-17 22:50:32.000000', 338, 1, 3)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4807,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (50, 578, 45, 2.0, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4808,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (76, 577)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4809,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=100144",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4810,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (579, '2022-05-17 22:52:30.000000', 111, 5, 2, '2022-04-18 00:00:00.000000', 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4811,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (339, 'GONZALEZ', 'ALEXA CRISTINA', 'XXX', '2022-04-18 00:00:00.000000', 12, 'F', 111, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4812,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (339, 339, 37, 3, '00', '2022-05-17 22:52:30.000000', '', 12)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4813,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (580, '2022-05-17 22:52:30.000000', 339, 1, 3)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4814,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (51, 580, 46, 4.0, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4815,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (77, 579)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4816,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=111",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4817,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=264",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4818,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-05-17', resultado='N' WHERE laboratorios_realizados.id_persona=200003 AND laboratorios_realizados.id_control=356 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4819,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-05-17', resultado='N' WHERE laboratorios_realizados.id_persona=200003 AND laboratorios_realizados.id_control=356 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4820,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-05-17', resultado='110' WHERE laboratorios_realizados.id_persona=200003 AND laboratorios_realizados.id_control=356 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4821,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos, georeferencia) VALUES (581, '2022-05-17 22:55:09.000000', 200003, 5, 2, '2022-03-07 00:00:00.000000', 1, '-22.2729469,-62.7170536')",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4822,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (340, 'XX', 'RN', 'XXX', '2022-03-07 00:00:00.000000', 12, 'F', 200003, 0)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4823,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (340, 340, 37, 3, '00', '2022-05-17 22:55:09.000000', '-22.2729469,-62.7170536', 12)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4824,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, georeferencia) VALUES (582, '2022-05-17 22:55:09.000000', 340, 1, 3, '-22.2729469,-62.7170536')",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4825,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (52, 582, 50, 2.0, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4826,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (78, 581)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4827,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=200003",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4828,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos, georeferencia) VALUES (583, '2022-05-17 22:57:08.000000', 114, 4, 2, '2022-03-08 00:00:00.000000', 2, '-22.2729458,-62.7170547')",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4829,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (341, 'SEGUNDO', 'RN', 'XXX', '2022-03-08 00:00:00.000000', 12, 'M', 114, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4830,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (341, 341, 37, 3, '00', '2022-05-17 22:57:08.000000', '-22.2729458,-62.7170547', 12)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4831,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, georeferencia) VALUES (584, '2022-05-17 22:57:08.000000', 341, 1, 3, '-22.2729458,-62.7170547')",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4832,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (53, 584, 49, 3.0, 1)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4833,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (79, 583)",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    4834,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=114",
                    "2022-05-17 22:57:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19237,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (200056, 'MENENDEZ', 'ELSA', '41939786', '1999-07-05 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19238,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200114, '2019-01-01 21:48:55.000000', 200056, 1, 1)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19239,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (200056, 200056, 24, 3, '00', '2019-01-01 21:48:55.000000', '', 12)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19240,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (200053, 200056, 200114, 13, '2018-01-01 00:00:00.000000', 4, 3, 1, '2022-03-08 00:00:00.000000', '2022-12-13 00:00:00.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19241,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (200053, 3)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19242,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200056, 200114, 2, 'S')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19243,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200056, 200114, 3, 'C')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19244,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200056, 200114, 1, 'S')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19245,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200056, 200114, 4, 'C')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19246,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200056, 200114, 1, 3, '2019-01-01 21:55:19.000000', 3)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19247,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200056, 200114, 2, 3, '2019-01-01 21:55:19.000000', 2)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19248,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200056, 200114, 4, 3, '2019-01-01 21:55:19.000000', 1)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19249,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200056, 200114, 5, 3, '2019-01-01 21:55:19.000000', 4)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19250,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200056, 200114, 6, 3, '2019-01-01 21:55:19.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19251,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200056, 200114, 7, 3, '2019-01-01 21:55:19.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19252,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200056, 200114, 8, 3, '2019-01-01 21:55:19.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19253,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200056, 200114, 9, 3, '2019-01-01 21:55:19.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19254,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200105, 200114, 165, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19255,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (200057, 'RODRIGUEZ', 'CEFERINA', '36544892', '1992-12-14 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19256,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200115, '2019-01-01 22:04:17.000000', 200057, 1, 1)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19257,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (200057, 200057, 24, 3, '00', '2019-01-01 22:04:17.000000', '', 12)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19258,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (200054, 200057, 200115, 28, '2020-09-01 00:00:00.000000', 6, 5, 0, '2018-12-21 00:00:00.000000', '2024-09-22 00:00:00.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19259,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200057, 200115, 2, 'S')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19260,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200057, 200115, 3, 'C')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19261,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200057, 200115, 1, 'S')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19262,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200057, 200115, 4, 'C')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19263,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200057, 200115, 1, 1, '2019-01-01 22:29:09.000000', 3)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19264,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200057, 200115, 2, 1, '2019-01-01 22:29:09.000000', 2)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19265,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200057, 200115, 4, 1, '2019-01-01 22:29:09.000000', 1)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19266,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200057, 200115, 5, 1, '2019-01-01 22:29:09.000000', 4)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19267,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200057, 200115, 6, 1, '2019-01-01 22:29:09.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19268,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200057, 200115, 7, 1, '2019-01-01 22:29:09.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19269,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200057, 200115, 8, 1, '2019-01-01 22:29:09.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19270,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200057, 200115, 9, 1, '2019-01-01 22:29:09.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19271,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200106, 200115, 1, 'S', ' ', 'N', 'N', 100, 70, 'N', '')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19272,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (200058, 'MIRANDA', 'CERFINA', '39215710', '1995-09-20 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19273,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200116, '2019-01-02 00:07:07.000000', 200058, 1, 1)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19274,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (200058, 200058, 24, 3, '00', '2019-01-02 00:07:07.000000', '', 12)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19275,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, cesareas, planificado, fum, fpp) VALUES (200055, 200058, 200116, 16, 6, 4, 1, 0, '2005-09-21 00:00:00.000000', '2012-06-22 00:00:00.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19276,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (200059, 'MIRANDA', 'SUSANA', '38651063', '1995-02-20 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19277,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200117, '2019-01-02 00:37:38.000000', 200059, 1, 1)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19278,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (200059, 200059, 24, 3, '00', '2019-01-02 00:37:38.000000', '', 12)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19279,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (200056, 200059, 200117, 13, '2018-05-11 00:00:00.000000', 4, 3, 1, '2021-10-20 00:00:00.000000', '2022-07-27 00:00:00.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19280,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (200056, 4)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19281,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200059, 200117, 2, 'N')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19282,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200059, 200117, 3, 'C')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19283,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200059, 200117, 1, 'S')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19284,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200059, 200117, 4, 'C')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19285,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200059, 200117, 1, 3, '2019-01-02 00:42:48.000000', 3)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19286,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200059, 200117, 2, 3, '2019-01-02 00:42:48.000000', 2)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19287,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200059, 200117, 4, 3, '2019-01-02 00:42:48.000000', 1)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19288,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200059, 200117, 5, 3, '2019-01-02 00:42:48.000000', 4)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19289,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200059, 200117, 6, 3, '2019-01-02 00:42:48.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19290,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200059, 200117, 7, 3, '2019-01-02 00:42:48.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19291,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200059, 200117, 9, 3, '2019-01-02 00:42:48.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19292,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200107, 200117, 145, 'S', ' ', 'N', 'N', 120, 80, 'N', '')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19293,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (200060, 'VARBON', 'GABRIELA', '18899655', '1993-03-05 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19294,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200118, '2019-01-02 00:49:43.000000', 200060, 1, 1)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19295,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (200060, 200060, 24, 3, '00', '2019-01-02 00:49:43.000000', '', 12)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19296,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (200057, 200060, 200118, 15, '2005-09-20 00:00:00.000000', 5, 4, 0, '2001-10-21 00:00:00.000000', '2008-07-22 00:00:00.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19297,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (200057, 4)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19298,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200060, 200118, 2, 'S')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19299,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200060, 200118, 3, 'C')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19300,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200060, 200118, 1, 'S')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19301,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200060, 200118, 4, 'C')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19302,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200060, 200118, 1, 3, '2019-01-02 00:53:22.000000', 3)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19303,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200060, 200118, 2, 3, '2019-01-02 00:53:22.000000', 2)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19304,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200060, 200118, 4, 3, '2019-01-02 00:53:22.000000', 1)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19305,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200060, 200118, 5, 3, '2019-01-02 00:53:22.000000', 4)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19306,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200060, 200118, 6, 3, '2019-01-02 00:53:22.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19307,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200060, 200118, 7, 3, '2019-01-02 00:53:22.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19308,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200060, 200118, 9, 3, '2019-01-02 00:53:22.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19309,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200108, 200118, 897, 'S', ' ', 'N', 'N', 110, 80, 'N', '')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19310,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (200061, 'CONSTANTINO', 'ELENA', '39781899', '1994-06-20 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19311,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200119, '2019-01-02 04:23:02.000000', 200061, 1, 1)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19312,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (200061, 200061, 22, 3, '00', '2019-01-02 04:23:02.000000', '', 12)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19313,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (200058, 200061, 200119, 16, '2019-01-01 00:00:00.000000', 6, 5, 0, '2024-12-21 00:00:00.000000', '2030-09-22 00:00:00.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19314,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200061, 200119, 2, 'S')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19315,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200061, 200119, 3, 'C')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19316,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200061, 200119, 1, 'S')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19317,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200061, 200119, 4, 'C')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19318,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200061, 200119, 1, 3, '2019-01-02 04:25:14.000000', 3)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19319,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200061, 200119, 2, 3, '2019-01-02 04:25:14.000000', 2)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19320,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200061, 200119, 4, 3, '2019-01-02 04:25:14.000000', 1)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19321,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200061, 200119, 5, 3, '2019-01-02 04:25:14.000000', 4)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19322,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200061, 200119, 6, 3, '2019-01-02 04:25:14.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19323,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200061, 200119, 7, 3, '2019-01-02 04:25:14.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19324,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200061, 200119, 8, 3, '2019-01-02 04:25:14.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19325,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200061, 200119, 9, 3, '2019-01-02 04:25:14.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19326,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200109, 200119, 311, 'S', ' ', 'N', 'N', 110, 80, 'N', '')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19327,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200120, '2019-01-02 05:09:42.000000', 267, 2, 1)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19328,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (267, 200120, 2, 'S')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19329,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (267, 200120, 3, 'C')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19330,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (267, 200120, 4, 'C')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19331,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (267, 200120, 1, 3, '2019-01-02 05:11:13.000000', 3)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19332,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (267, 200120, 2, 3, '2019-01-02 05:11:13.000000', 2)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19333,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (267, 200120, 4, 3, '2019-01-02 05:11:13.000000', 1)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19334,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (267, 200120, 5, 3, '2019-01-02 05:11:13.000000', 4)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19335,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (267, 200120, 6, 3, '2019-01-02 05:11:13.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19336,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (267, 200120, 7, 3, '2019-01-02 05:11:13.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19337,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (267, 200120, 8, 3, '2019-01-02 05:11:13.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19338,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (267, 200120, 9, 3, '2019-01-02 05:11:13.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19339,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200110, 200120, 144, 'S', ' ', 'N', 'N', 130, 85, 'N', '')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19340,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (200062, 'PEREZ', 'SOLEDAD', '45551817', '2004-08-28 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19341,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200121, '2019-01-02 05:23:26.000000', 200062, 1, 1)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19342,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (200062, 200062, 22, 3, '00', '2019-01-02 05:23:26.000000', '', 12)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19343,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (200059, 200062, 200121, 17, 1, 0, '2005-11-21 00:00:00.000000', '2012-08-22 00:00:00.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19344,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200062, 200121, 2, 'S')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19345,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200062, 200121, 3, 'C')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19346,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200062, 200121, 1, 'S')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19347,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200062, 200121, 4, 'C')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19348,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200062, 200121, 1, 3, '2019-01-02 05:25:12.000000', 3)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19349,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200062, 200121, 2, 3, '2019-01-02 05:25:12.000000', 2)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19350,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200062, 200121, 4, 3, '2019-01-02 05:25:12.000000', 1)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19351,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200062, 200121, 5, 3, '2019-01-02 05:25:12.000000', 4)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19352,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200062, 200121, 6, 3, '2019-01-02 05:25:12.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19353,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200062, 200121, 7, 3, '2019-01-02 05:25:12.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19354,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200062, 200121, 8, 3, '2019-01-02 05:25:12.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19355,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200111, 200121, 684, 'S', ' ', 'N', 'N', 110, 70, 'N', '')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19356,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (200063, 'SEGUNDO', 'ANDREA', '39781838', '1996-07-10 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19357,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200122, '2019-01-02 05:29:25.000000', 200063, 1, 1)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19358,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (200063, 200063, 22, 3, '00', '2019-01-02 05:29:25.000000', '', 12)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19359,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (200060, 200063, 200122, 19, '2015-12-20 00:00:00.000000', 3, 2, 0, '2005-03-22 00:00:00.000000', '2010-12-22 00:00:00.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19360,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200063, 200122, 2, 'S')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19361,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200063, 200122, 3, 'C')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19362,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200063, 200122, 1, 'S')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19363,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200063, 200122, 4, 'C')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19364,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200063, 200122, 1, 3, '2019-01-02 05:31:49.000000', 3)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19365,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200063, 200122, 2, 3, '2019-01-02 05:31:49.000000', 2)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19366,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200063, 200122, 4, 3, '2019-01-02 05:31:49.000000', 1)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19367,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200063, 200122, 5, 3, '2019-01-02 05:31:49.000000', 4)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19368,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200063, 200122, 6, 3, '2019-01-02 05:31:49.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19369,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200063, 200122, 7, 3, '2019-01-02 05:31:49.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19370,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200063, 200122, 8, 3, '2019-01-02 05:31:49.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19371,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200063, 200122, 9, 3, '2019-01-02 05:31:49.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19372,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200112, 200122, 719, 'S', ' ', 'N', 'N', 120, 80, 'N', '')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19373,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200123, '2019-01-02 21:37:56.000000', 263, 2, 1)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19374,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (263, 200123, 2, 'S')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19375,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (263, 200123, 3, 'C')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19376,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (263, 200123, 4, 'C')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19377,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (263, 200123, 6, 3, '2019-01-02 21:39:28.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19378,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (263, 200123, 7, 3, '2019-01-02 21:39:28.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19379,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (263, 200123, 9, 3, '2019-01-02 21:39:28.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19380,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200113, 200123, 148, 'S', ' ', 'N', 'N', 120, 60, 'N', '')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19381,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200124, '2019-01-02 21:44:16.000000', 100168, 2, 1)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19382,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100168, 200124, 2, 'S')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19383,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100168, 200124, 3, 'C')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19384,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100168, 200124, 4, 'C')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19385,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100168, 200124, 1, 3, '2019-01-02 21:45:29.000000', 3)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19386,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100168, 200124, 2, 3, '2019-01-02 21:45:29.000000', 2)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19387,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100168, 200124, 5, 3, '2019-01-02 21:45:29.000000', 4)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19388,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100168, 200124, 6, 3, '2019-01-02 21:45:29.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19389,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100168, 200124, 7, 3, '2019-01-02 21:45:29.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19390,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100168, 200124, 8, 3, '2019-01-02 21:45:29.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19391,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100168, 200124, 9, 3, '2019-01-02 21:45:29.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19392,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200114, 200124, 145, 'S', ' ', 'N', 'N', 120, 80, 'N', '')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19393,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (200064, 'TRINIDAD FRUTOS', 'LETICIA NOEMI', '5702051', '2007-01-21 00:00:00.000000', 2, 177, 'F')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19394,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200125, '2019-01-03 02:46:02.000000', 200064, 1, 1)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19395,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (200064, 200064, 4, 2, '00', '2019-01-03 02:46:02.000000', '', 177)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19396,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (200061, 200064, 200125, 15, 1, 0, '2022-03-15 00:00:00.000000', '2022-12-20 00:00:00.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19397,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200064, 200125, 2, 'S')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19398,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200064, 200125, 3, 'N')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19399,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200064, 200125, 1, 'S')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19400,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200064, 200125, 4, 'N')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19401,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200064, 200125, 4, 3, '2019-01-03 02:49:54.000000', 1)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19402,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200064, 200125, 6, 3, '2019-01-03 02:49:54.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19403,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200064, 200125, 7, 3, '2019-01-03 02:49:54.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19404,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200064, 200125, 8, 3, '2019-01-03 02:49:54.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19405,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200064, 200125, 9, 3, '2019-01-03 02:49:54.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19406,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200115, 200125, 166, 'S', ' ', 'N', 'N', 120, 60, 'N', '')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19407,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (200065, 'DIEGO', 'NOELIA MARILIN', '6556761', '2004-07-25 00:00:00.000000', 2, 177, 'F')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19408,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200126, '2019-01-03 02:53:34.000000', 200065, 1, 1)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19409,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (200065, 200065, 4, 2, '00', '2019-01-03 02:53:34.000000', '', 177)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19410,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (200062, 200065, 200126, 17, 1, 0, '2022-02-22 00:00:00.000000', '2022-11-29 00:00:00.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19411,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200065, 200126, 2, 'S')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19412,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200065, 200126, 3, 'N')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19413,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200065, 200126, 1, 'S')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19414,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200065, 200126, 4, 'N')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19415,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200065, 200126, 4, 3, '2019-01-03 02:55:40.000000', 1)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19416,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200065, 200126, 6, 3, '2019-01-03 02:55:40.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19417,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200065, 200126, 7, 3, '2019-01-03 02:55:40.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19418,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200065, 200126, 8, 3, '2019-01-03 02:55:40.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19419,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200065, 200126, 9, 3, '2019-01-03 02:55:40.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19420,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200116, 200126, 163, 'S', ' ', 'N', 'N', 90, 60, 'N', '')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19421,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (200066, 'FERREIRA', 'ESTELLA MARY', '4545923', '1987-03-01 00:00:00.000000', 1, 177, 'F')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19422,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200127, '2019-01-03 03:01:51.000000', 200066, 1, 1)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19423,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (200066, 200066, 4, 2, '00', '2019-01-03 03:01:51.000000', '', 177)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19424,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (200063, 200066, 200127, 17, '2007-05-12 00:00:00.000000', 3, 2, 1, '2022-01-27 00:00:00.000000', '2022-11-03 00:00:00.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19425,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200066, 200127, 2, 'S')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19426,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200066, 200127, 3, 'N')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19427,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200066, 200127, 1, 'S')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19428,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200066, 200127, 4, 'N')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19429,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (200066, 200127, 1, 3, '2019-01-03 03:07:23.000000', '2019-01-03 03:07:23.000000', 'N', 3)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19430,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (200066, 200127, 2, 3, '2019-01-03 03:07:23.000000', '2019-01-03 03:07:23.000000', 'N', 2)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19431,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (200066, 200127, 4, 3, '2019-01-03 03:07:23.000000', '2019-01-03 03:07:23.000000', 'N', 1)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19432,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (200066, 200127, 5, 3, '2019-01-03 03:07:23.000000', '2019-01-03 03:07:23.000000', 'N', 4)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19433,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (200066, 200127, 7, 3, '2019-01-03 03:07:23.000000', '2019-01-03 03:07:23.000000', '75')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19434,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (200066, 200127, 8, 3, '2019-01-03 03:07:23.000000', '2019-01-03 03:07:23.000000', 'N')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19435,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (200066, 200127, 9, 3, '2019-01-03 03:07:23.000000', '2019-01-03 03:07:23.000000', 'ab+')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19436,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200117, 200127, 159, 'R', ' ', 'N', 'N', 130, 70, 'N', 'Controles 11/05/2022')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19437,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (200067, 'DURAN', 'ROSA', '5198018', '1992-05-11 00:00:00.000000', 2, 177, 'F')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19438,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200128, '2019-01-03 03:11:48.000000', 200067, 1, 1)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19439,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (200067, 200067, 4, 2, '00', '2019-01-03 03:11:48.000000', '', 177)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19440,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (200064, 200067, 200128, 24, '2018-03-13 00:00:00.000000', 3, 2, 0, '2022-03-23 00:00:00.000000', '2022-12-28 00:00:00.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19441,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200067, 200128, 2, 'S')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19442,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200067, 200128, 3, 'N')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19443,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200067, 200128, 1, 'S')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19444,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200067, 200128, 4, 'N')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19445,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200067, 200128, 4, 3, '2019-01-03 03:14:34.000000', 1)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19446,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200067, 200128, 6, 3, '2019-01-03 03:14:34.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19447,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200067, 200128, 7, 3, '2019-01-03 03:14:34.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19448,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200067, 200128, 8, 3, '2019-01-03 03:14:34.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19449,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200067, 200128, 9, 3, '2019-01-03 03:14:34.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19450,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200118, 200128, 167, 'S', ' ', 'N', 'N', 120, 80, 'N', '')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19451,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (200068, 'TYMA', 'DALILA EPIFANIA', '6872628', '2006-01-02 00:00:00.000000', 2, 177, 'F')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19452,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200129, '2019-01-03 03:21:56.000000', 200068, 1, 1)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19453,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (200068, 200068, 4, 2, '00', '2019-01-03 03:21:56.000000', '', 177)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19454,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (200065, 200068, 200129, 16, 1, 0, '2021-12-22 00:00:00.000000', '2022-09-28 00:00:00.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19455,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200068, 200129, 2, 'S')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19456,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200068, 200129, 3, 'N')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19457,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200068, 200129, 1, 'N')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19458,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200068, 200129, 4, 'N')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19459,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200068, 200129, 4, 3, '2019-01-03 03:25:06.000000', 1)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19460,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200068, 200129, 6, 3, '2019-01-03 03:25:06.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19461,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200068, 200129, 7, 3, '2019-01-03 03:25:06.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19462,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200068, 200129, 8, 3, '2019-01-03 03:25:06.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19463,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200068, 200129, 9, 3, '2019-01-03 03:25:06.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19464,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200119, 200129, 154, 'S', ' ', 'N', 'N', 100, 50, 'N', '')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19465,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (200069, 'AUCA', 'SARA ANAHI', '7493939', '2002-04-28 00:00:00.000000', 2, 177, 'F')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19466,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200130, '2019-01-03 03:52:16.000000', 200069, 1, 1)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19467,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (200069, 200069, 4, 2, '00', '2019-01-03 03:52:16.000000', '', 177)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19468,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (200066, 200069, 200130, 19, 1, 0, '2021-12-06 00:00:00.000000', '2022-09-12 00:00:00.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19469,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200069, 200130, 2, 'S')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19470,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200069, 200130, 3, 'N')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19471,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200069, 200130, 1, 'S')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19472,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200069, 200130, 4, 'N')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19473,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200069, 200130, 1, 3, '2019-01-03 04:15:16.000000', 3)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19474,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200069, 200130, 2, 3, '2019-01-03 04:15:16.000000', 2)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19475,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200069, 200130, 4, 3, '2019-01-03 04:15:16.000000', 1)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19476,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200069, 200130, 5, 3, '2019-01-03 04:15:16.000000', 4)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19477,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200069, 200130, 6, 3, '2019-01-03 04:15:16.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19478,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200069, 200130, 7, 3, '2019-01-03 04:15:16.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19479,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200069, 200130, 8, 3, '2019-01-03 04:15:16.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19480,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200069, 200130, 9, 3, '2019-01-03 04:15:16.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19481,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200120, 200130, 152, 'S', ' ', 'N', 'N', 120, 80, 'N', '')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19482,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (200070, 'RODRIGUEZ', 'KELLY VENINA', '7403526', '2002-10-11 00:00:00.000000', 2, 177, 'F')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19483,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200131, '2019-01-03 04:17:41.000000', 200070, 1, 1)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19484,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (200070, 200070, 4, 2, '00', '2019-01-03 04:17:41.000000', '', 177)",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19485,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (200067, 200070, 200131, 17, '2020-08-20 00:00:00.000000', 2, 1, 0, '2021-12-20 00:00:00.000000', '2022-09-26 00:00:00.000000')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19486,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200070, 200131, 2, 'S')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19487,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200070, 200131, 3, 'N')",
                    "2022-06-16 23:32:25",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19488,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200070, 200131, 1, 'S')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19489,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200070, 200131, 4, 'N')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19490,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200070, 200131, 1, 3, '2019-01-03 04:19:58.000000', 3)",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19491,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200070, 200131, 2, 3, '2019-01-03 04:19:58.000000', 2)",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19492,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200070, 200131, 4, 3, '2019-01-03 04:19:58.000000', 1)",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19493,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200070, 200131, 5, 3, '2019-01-03 04:19:58.000000', 4)",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19494,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200070, 200131, 6, 3, '2019-01-03 04:19:58.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19495,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200070, 200131, 7, 3, '2019-01-03 04:19:58.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19496,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200070, 200131, 8, 3, '2019-01-03 04:19:58.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19497,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200070, 200131, 9, 3, '2019-01-03 04:19:58.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19498,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200121, 200131, 154, 'S', ' ', 'N', 'N', 120, 70, 'N', '')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19499,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (200071, 'FELIX', 'FANIA', '40147669', '1996-08-07 00:00:00.000000', 2, 177, 'F')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19500,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200132, '2019-01-03 04:22:23.000000', 200071, 1, 1)",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19501,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (200071, 200071, 4, 2, '00', '2019-01-03 04:22:23.000000', '', 177)",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19502,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, abortos, planificado, fum, fpp) VALUES (200068, 200071, 200132, 16, '2018-03-10 00:00:00.000000', 5, 2, 2, 0, '2022-03-10 00:00:00.000000', '2022-12-15 00:00:00.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19503,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200071, 200132, 2, 'S')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19504,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200071, 200132, 3, 'N')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19505,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200071, 200132, 1, 'S')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19506,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200071, 200132, 4, 'N')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19507,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200071, 200132, 4, 3, '2019-01-03 04:26:02.000000', 1)",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19508,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200071, 200132, 6, 3, '2019-01-03 04:26:02.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19509,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200071, 200132, 7, 3, '2019-01-03 04:26:02.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19510,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200071, 200132, 8, 3, '2019-01-03 04:26:02.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19511,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200071, 200132, 9, 3, '2019-01-03 04:26:02.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19512,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200122, 200132, 165, 'S', ' ', 'N', 'N', 110, 80, 'N', '')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19513,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (200072, 'PEREZ CASTRO', 'MARIA ELENA', '5197678', '1998-09-13 00:00:00.000000', 2, 177, 'F')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19514,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200133, '2019-01-03 04:28:10.000000', 200072, 1, 1)",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19515,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (200072, 200072, 4, 2, '00', '2019-01-03 04:28:10.000000', '', 177)",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19516,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (200069, 200072, 200133, 25, 1, 0, '2007-12-21 00:00:00.000000', '2013-09-22 00:00:00.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19517,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200072, 200133, 2, 'S')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19518,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200072, 200133, 3, 'N')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19519,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200072, 200133, 1, 'S')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19520,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200072, 200133, 4, 'N')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19521,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200072, 200133, 1, 3, '2019-01-03 04:30:03.000000', 3)",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19522,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200072, 200133, 2, 3, '2019-01-03 04:30:03.000000', 2)",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19523,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200072, 200133, 4, 3, '2019-01-03 04:30:03.000000', 1)",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19524,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200072, 200133, 5, 3, '2019-01-03 04:30:03.000000', 4)",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19525,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200072, 200133, 6, 3, '2019-01-03 04:30:03.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19526,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200072, 200133, 7, 3, '2019-01-03 04:30:03.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19527,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200072, 200133, 8, 3, '2019-01-03 04:30:03.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19528,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200072, 200133, 9, 3, '2019-01-03 04:30:03.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19529,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200123, 200133, 575, 'S', ' ', 'N', 'N', 110, 70, 'N', '')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19530,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (200073, 'SANTILLAN', 'ADRIANA', '43219178', '2001-04-16 00:00:00.000000', 2, 12, 'F')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19531,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200134, '2019-01-03 04:33:20.000000', 200073, 1, 1)",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19532,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (200073, 200073, 4, 2, '00', '2019-01-03 04:33:20.000000', '', 177)",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19533,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, abortos, planificado, fum, fpp) VALUES (200070, 200073, 200134, 20, 2, 1, 1, '2022-01-12 00:00:00.000000', '2022-10-19 00:00:00.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19534,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200073, 200134, 2, 'S')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19535,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200073, 200134, 3, 'N')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19536,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200073, 200134, 1, 'N')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19537,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200073, 200134, 4, 'N')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19538,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200073, 200134, 1, 3, '2019-01-03 04:35:12.000000', 3)",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19539,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200073, 200134, 2, 3, '2019-01-03 04:35:12.000000', 2)",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19540,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200073, 200134, 4, 3, '2019-01-03 04:35:12.000000', 1)",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19541,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200073, 200134, 5, 3, '2019-01-03 04:35:12.000000', 4)",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19542,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200073, 200134, 6, 3, '2019-01-03 04:35:12.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19543,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200073, 200134, 7, 3, '2019-01-03 04:35:12.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19544,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200073, 200134, 8, 3, '2019-01-03 04:35:12.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19545,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200073, 200134, 9, 3, '2019-01-03 04:35:12.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19546,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200124, 200134, 157, 'S', ' ', 'N', 'N', 100, 70, 'N', '')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19547,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (200074, 'SALVATIERRA', 'FRANCISCA', '7013101', '1992-12-03 00:00:00.000000', 2, 177, 'F')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19548,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200135, '2019-01-03 04:41:22.000000', 200074, 1, 1)",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19549,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (200074, 200074, 4, 2, '00', '2019-01-03 04:41:22.000000', '', 177)",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19550,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (200071, 200074, 200135, 24, '2020-08-01 00:00:00.000000', 4, 3, 1, '2022-01-06 00:00:00.000000', '2022-10-13 00:00:00.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19551,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200074, 200135, 2, 'S')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19552,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200074, 200135, 3, 'N')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19553,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200074, 200135, 1, 'S')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19554,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200074, 200135, 4, 'N')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19555,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200074, 200135, 4, 3, '2019-01-03 04:45:37.000000', 1)",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19556,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200074, 200135, 6, 3, '2019-01-03 04:45:37.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19557,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200074, 200135, 7, 3, '2019-01-03 04:45:37.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19558,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200074, 200135, 8, 3, '2019-01-03 04:45:37.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19559,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200074, 200135, 9, 3, '2019-01-03 04:45:37.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19560,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200125, 200135, 156, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19561,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (200075, 'PEREZ', 'CANDIDA', '5525299', '2000-06-19 00:00:00.000000', 2, 177, 'F')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19562,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200136, '2019-01-03 04:47:48.000000', 200075, 1, 1)",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19563,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (200075, 200075, 4, 2, '00', '2019-01-03 04:47:48.000000', '', 177)",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19564,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (200072, 200075, 200136, 18, '2019-09-14 00:00:00.000000', 2, 1, 1, '2021-11-14 00:00:00.000000', '2022-08-21 00:00:00.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19565,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200075, 200136, 2, 'N')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19566,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200075, 200136, 3, 'N')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19567,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200075, 200136, 1, 'N')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19568,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200075, 200136, 4, 'N')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19569,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200075, 200136, 1, 3, '2019-01-03 04:50:12.000000', 3)",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19570,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200075, 200136, 2, 3, '2019-01-03 04:50:12.000000', 2)",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19571,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200075, 200136, 4, 3, '2019-01-03 04:50:12.000000', 1)",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19572,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200075, 200136, 5, 3, '2019-01-03 04:50:12.000000', 4)",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19573,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200075, 200136, 6, 3, '2019-01-03 04:50:12.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19574,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200075, 200136, 7, 3, '2019-01-03 04:50:12.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19575,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200075, 200136, 8, 3, '2019-01-03 04:50:12.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19576,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200075, 200136, 9, 3, '2019-01-03 04:50:12.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19577,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200126, 200136, 149, 'S', ' ', 'N', 'N', 120, 80, 'N', '')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19578,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (200076, 'ARENAS FLORES', 'ZULMA', '5693122', '1994-07-04 00:00:00.000000', 2, 177, 'F')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19579,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200137, '2019-01-03 04:52:56.000000', 200076, 1, 1)",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19580,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (200076, 200076, 4, 2, '00', '2019-01-03 04:52:56.000000', '', 177)",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19581,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, planificado, fum, fpp) VALUES (200073, 200076, 200137, 20, 2, 1, 1, '2015-10-21 00:00:00.000000', '2022-07-22 00:00:00.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19582,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200076, 200137, 2, 'S')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19583,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200076, 200137, 3, 'C')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19584,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200076, 200137, 1, 'S')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19585,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200076, 200137, 4, 'N')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19586,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200076, 200137, 4, 3, '2019-01-03 04:54:13.000000', 1)",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19587,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200076, 200137, 6, 3, '2019-01-03 04:54:13.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19588,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200076, 200137, 7, 3, '2019-01-03 04:54:13.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19589,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200076, 200137, 8, 3, '2019-01-03 04:54:13.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19590,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200076, 200137, 9, 3, '2019-01-03 04:54:13.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19591,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200127, 200137, 167, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19592,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (200077, 'ORTIZ', 'SENAIDA', '6987306', '2006-02-04 00:00:00.000000', 2, 177, 'F')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19593,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200138, '2019-01-03 04:56:19.000000', 200077, 1, 1)",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19594,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (200077, 200077, 4, 2, '00', '2019-01-03 04:56:19.000000', '', 177)",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19595,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (200074, 200077, 200138, 16, '2020-02-14 00:00:00.000000', 2, 1, 1, '2021-10-01 00:00:00.000000', '2022-07-08 00:00:00.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19596,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200077, 200138, 2, 'S')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19597,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200077, 200138, 3, 'C')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19598,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200077, 200138, 1, 'S')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19599,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200077, 200138, 4, 'N')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19600,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200077, 200138, 4, 3, '2019-01-03 04:58:49.000000', 1)",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19601,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200077, 200138, 6, 3, '2019-01-03 04:58:49.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19602,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200077, 200138, 7, 3, '2019-01-03 04:58:49.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19603,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200077, 200138, 8, 3, '2019-01-03 04:58:49.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19604,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200077, 200138, 9, 3, '2019-01-03 04:58:49.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19605,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200128, 200138, 143, 'S', ' ', 'N', 'N', 90, 60, 'N', '')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19606,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (200078, 'ORTIZ FALCON', 'MARCIANA', '5524884', '1991-01-19 00:00:00.000000', 2, 177, 'F')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19607,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200139, '2019-01-03 05:01:29.000000', 200078, 1, 1)",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19608,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (200078, 200078, 4, 2, '00', '2019-01-03 05:01:29.000000', '', 177)",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19609,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, abortos, planificado, fum, fpp) VALUES (200075, 200078, 200139, 25, '2019-01-03 00:00:00.000000', 3, 1, 1, 1, '2022-01-02 00:00:00.000000', '2022-10-09 00:00:00.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19610,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200078, 200139, 2, 'S')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19611,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200078, 200139, 3, 'C')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19612,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200078, 200139, 1, 'S')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19613,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200078, 200139, 4, 'N')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19614,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200078, 200139, 4, 3, '2019-01-03 05:02:59.000000', 1)",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19615,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200078, 200139, 6, 3, '2019-01-03 05:02:59.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19616,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200078, 200139, 7, 3, '2019-01-03 05:02:59.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19617,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200078, 200139, 8, 3, '2019-01-03 05:02:59.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19618,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200078, 200139, 9, 3, '2019-01-03 05:02:59.000000')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    19619,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200129, 200139, 156, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-06-16 23:32:26",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    23410,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-16', resultado='N' WHERE laboratorios_realizados.id_persona=200056 AND laboratorios_realizados.id_control=200114 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-17 00:16:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    23411,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-16', resultado='N' WHERE laboratorios_realizados.id_persona=200056 AND laboratorios_realizados.id_control=200114 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-17 00:16:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    23412,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-16', resultado='N' WHERE laboratorios_realizados.id_persona=200056 AND laboratorios_realizados.id_control=200114 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-17 00:16:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    23413,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-16', resultado='N' WHERE laboratorios_realizados.id_persona=200056 AND laboratorios_realizados.id_control=200114 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-17 00:16:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    23414,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-16', resultado='11' WHERE laboratorios_realizados.id_persona=200056 AND laboratorios_realizados.id_control=200114 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 00:16:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    23415,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-16', resultado='107' WHERE laboratorios_realizados.id_persona=200056 AND laboratorios_realizados.id_control=200114 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 00:16:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    23416,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-16', resultado='0+' WHERE laboratorios_realizados.id_persona=200056 AND laboratorios_realizados.id_control=200114 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 00:16:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    23417,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200105",
                    "2022-06-17 00:16:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    23418,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200106",
                    "2022-06-17 00:16:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    23419,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=200057 AND laboratorios_realizados.id_control=200115 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-17 00:16:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    23420,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=200057 AND laboratorios_realizados.id_control=200115 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-17 00:16:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    23421,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=200057 AND laboratorios_realizados.id_control=200115 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-17 00:16:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    23422,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=200057 AND laboratorios_realizados.id_control=200115 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-17 00:16:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    23423,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='10' WHERE laboratorios_realizados.id_persona=200057 AND laboratorios_realizados.id_control=200115 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 00:16:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    23424,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='98' WHERE laboratorios_realizados.id_persona=200057 AND laboratorios_realizados.id_control=200115 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 00:16:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    23425,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='0+' WHERE laboratorios_realizados.id_persona=200057 AND laboratorios_realizados.id_control=200115 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 00:16:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    23426,
                    "SELECT id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo, madre, alta, nacido_vivo FROM personas WHERE id_persona = '100081'",
                    "2022-06-17 00:22:16",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    23427,
                    "SELECT id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais FROM ubicaciones WHERE id_ubicacion = '100081'",
                    "2022-06-17 00:22:16",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    23428,
                    "UPDATE antecedentes SET gestas=3, abortos=1, planificado=0, fum='2022-03-17', fpp='2022-12-22' WHERE antecedentes.id_antecedente=100079",
                    "2022-06-17 00:22:16",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    23429,
                    "DELETE FROM antecedentes_apps WHERE antecedentes_apps.id_antecedente=100079 AND antecedentes_apps.id_app=4",
                    "2022-06-17 00:22:16",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    23430,
                    "DELETE FROM antecedentes_macs WHERE antecedentes_macs.id_antecedente=100079 AND antecedentes_macs.id_mac=4",
                    "2022-06-17 00:22:16",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    32906,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200140, '2022-06-17 00:39:12.000000', 100178, 2, 1)",
                    "2022-06-17 01:26:10",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    32907,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100178, 200140, 2, 'S')",
                    "2022-06-17 01:26:10",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    32908,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100178, 200140, 3, 'C')",
                    "2022-06-17 01:26:10",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    32909,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100178, 200140, 4, 'C')",
                    "2022-06-17 01:26:10",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    32910,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100178, 200140, 1, 3, '2022-06-17 00:41:31.000000', 3)",
                    "2022-06-17 01:26:10",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    32911,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100178, 200140, 2, 3, '2022-06-17 00:41:31.000000', 2)",
                    "2022-06-17 01:26:10",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    32912,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100178, 200140, 5, 3, '2022-06-17 00:41:31.000000', 4)",
                    "2022-06-17 01:26:10",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    32913,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100178, 200140, 6, 3, '2022-06-17 00:41:31.000000')",
                    "2022-06-17 01:26:10",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    32914,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100178, 200140, 7, 3, '2022-06-17 00:41:31.000000')",
                    "2022-06-17 01:26:10",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    32915,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200130, 200140, 35, 'R', ' ', 'N', 'N', 130, 90, 'N', '')",
                    "2022-06-17 01:26:10",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    32916,
                    "UPDATE controles SET georeferencia='-22.2734827,-62.7173927' WHERE controles.id_control=200140",
                    "2022-06-17 01:26:10",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    32917,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=100178 AND laboratorios_realizados.id_control=200140 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-17 01:26:10",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    32918,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=100178 AND laboratorios_realizados.id_control=200140 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-17 01:26:10",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    32919,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=100178 AND laboratorios_realizados.id_control=200140 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-17 01:26:10",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    32920,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='11.3' WHERE laboratorios_realizados.id_persona=100178 AND laboratorios_realizados.id_control=200140 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 01:26:10",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    32921,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='93' WHERE laboratorios_realizados.id_persona=100178 AND laboratorios_realizados.id_control=200140 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 01:26:10",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    32922,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200107",
                    "2022-06-17 01:26:10",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    32923,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=200059 AND laboratorios_realizados.id_control=200117 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-17 01:26:10",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    32924,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=200059 AND laboratorios_realizados.id_control=200117 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-17 01:26:10",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    32925,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=200059 AND laboratorios_realizados.id_control=200117 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-17 01:26:10",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    32926,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=200059 AND laboratorios_realizados.id_control=200117 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-17 01:26:10",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    32927,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='9.6' WHERE laboratorios_realizados.id_persona=200059 AND laboratorios_realizados.id_control=200117 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 01:26:10",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    32928,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='90' WHERE laboratorios_realizados.id_persona=200059 AND laboratorios_realizados.id_control=200117 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 01:26:10",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    32929,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='0+' WHERE laboratorios_realizados.id_persona=200059 AND laboratorios_realizados.id_control=200117 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 01:26:10",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    32930,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200108",
                    "2022-06-17 01:26:10",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    32931,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=200060 AND laboratorios_realizados.id_control=200118 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-17 01:26:10",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    32932,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=200060 AND laboratorios_realizados.id_control=200118 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-17 01:26:10",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    32933,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=200060 AND laboratorios_realizados.id_control=200118 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-17 01:26:10",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    32934,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=200060 AND laboratorios_realizados.id_control=200118 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-17 01:26:10",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    32935,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='11.6' WHERE laboratorios_realizados.id_persona=200060 AND laboratorios_realizados.id_control=200118 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 01:26:10",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    32936,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='80' WHERE laboratorios_realizados.id_persona=200060 AND laboratorios_realizados.id_control=200118 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 01:26:10",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    32937,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='0+' WHERE laboratorios_realizados.id_persona=200060 AND laboratorios_realizados.id_control=200118 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 01:26:10",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    32938,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200141, '2022-06-17 01:22:44.000000', 271, 2, 1)",
                    "2022-06-17 01:26:10",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    32939,
                    "SELECT id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo, madre, alta, nacido_vivo FROM personas WHERE id_persona = '271'",
                    "2022-06-17 01:26:10",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    32940,
                    "SELECT id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais FROM ubicaciones WHERE id_ubicacion = '271'",
                    "2022-06-17 01:26:10",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    32941,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200142, '2022-06-17 01:25:16.000000', 200061, 2, 1)",
                    "2022-06-17 01:26:10",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33321,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200143, '2022-06-17 01:37:44.000000', 267, 2, 1)",
                    "2022-06-17 01:57:01",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33322,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (267, 200143, 2, 'S')",
                    "2022-06-17 01:57:01",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33323,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (267, 200143, 3, 'C')",
                    "2022-06-17 01:57:01",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33324,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (267, 200143, 4, 'C')",
                    "2022-06-17 01:57:01",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33325,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (267, 200143, 1, 3, '2022-06-17 01:39:43.000000', 3)",
                    "2022-06-17 01:57:01",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33326,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (267, 200143, 2, 3, '2022-06-17 01:39:43.000000', 2)",
                    "2022-06-17 01:57:01",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33327,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (267, 200143, 4, 3, '2022-06-17 01:39:43.000000', 1)",
                    "2022-06-17 01:57:01",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33328,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (267, 200143, 5, 3, '2022-06-17 01:39:43.000000', 4)",
                    "2022-06-17 01:57:01",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33329,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (267, 200143, 6, 3, '2022-06-17 01:39:43.000000')",
                    "2022-06-17 01:57:01",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33330,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (267, 200143, 7, 3, '2022-06-17 01:39:43.000000')",
                    "2022-06-17 01:57:01",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33331,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (267, 200143, 9, 3, '2022-06-17 01:39:43.000000')",
                    "2022-06-17 01:57:01",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33332,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200131, 200143, 35, 'S', ' ', 'N', 'N', 130, 85, 'N', '')",
                    "2022-06-17 01:57:01",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33333,
                    "UPDATE controles SET georeferencia='-22.2728133,-62.7171034' WHERE controles.id_control=200143",
                    "2022-06-17 01:57:01",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33334,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200131",
                    "2022-06-17 01:57:01",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33335,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=267 AND laboratorios_realizados.id_control=200143 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-17 01:57:01",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33336,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=267 AND laboratorios_realizados.id_control=200143 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-17 01:57:01",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33337,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=267 AND laboratorios_realizados.id_control=200143 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-17 01:57:01",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33338,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=267 AND laboratorios_realizados.id_control=200143 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-17 01:57:01",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33339,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='9.6' WHERE laboratorios_realizados.id_persona=267 AND laboratorios_realizados.id_control=200143 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 01:57:01",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33340,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='103' WHERE laboratorios_realizados.id_persona=267 AND laboratorios_realizados.id_control=200143 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 01:57:01",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33341,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='0+' WHERE laboratorios_realizados.id_persona=267 AND laboratorios_realizados.id_control=200143 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 01:57:01",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33342,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200144, '2022-06-17 01:47:23.000000', 200063, 2, 1)",
                    "2022-06-17 01:57:01",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33343,
                    "SELECT id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo, madre, alta, nacido_vivo FROM personas WHERE id_persona = '200063'",
                    "2022-06-17 01:57:01",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33344,
                    "SELECT id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais FROM ubicaciones WHERE id_ubicacion = '200063'",
                    "2022-06-17 01:57:01",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33345,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200145, '2022-06-17 01:56:22.000000', 200062, 2, 1)",
                    "2022-06-17 01:57:01",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33346,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200146, '2022-06-17 02:01:13.000000', 261, 2, 1)",
                    "2022-06-17 02:16:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33347,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (261, 200146, 2, 'S')",
                    "2022-06-17 02:16:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33348,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (261, 200146, 3, 'C')",
                    "2022-06-17 02:16:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33349,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (261, 200146, 4, 'C')",
                    "2022-06-17 02:16:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33350,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (261, 200146, 1, 3, '2022-06-17 02:02:20.000000', 3)",
                    "2022-06-17 02:16:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33351,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (261, 200146, 2, 3, '2022-06-17 02:02:20.000000', 2)",
                    "2022-06-17 02:16:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33352,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (261, 200146, 4, 3, '2022-06-17 02:02:20.000000', 1)",
                    "2022-06-17 02:16:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33353,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (261, 200146, 5, 3, '2022-06-17 02:02:20.000000', 4)",
                    "2022-06-17 02:16:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33354,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (261, 200146, 6, 3, '2022-06-17 02:02:20.000000')",
                    "2022-06-17 02:16:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33355,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (261, 200146, 7, 3, '2022-06-17 02:02:20.000000')",
                    "2022-06-17 02:16:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33356,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (261, 200146, 9, 3, '2022-06-17 02:02:20.000000')",
                    "2022-06-17 02:16:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33357,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200132, 200146, 31, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-06-17 02:16:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33358,
                    "UPDATE controles SET georeferencia='-22.2727714,-62.7170635' WHERE controles.id_control=200146",
                    "2022-06-17 02:16:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33359,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200132",
                    "2022-06-17 02:16:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33360,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=261 AND laboratorios_realizados.id_control=200146 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-17 02:16:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33361,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=261 AND laboratorios_realizados.id_control=200146 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-17 02:16:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33362,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=261 AND laboratorios_realizados.id_control=200146 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-17 02:16:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33363,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=261 AND laboratorios_realizados.id_control=200146 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-17 02:16:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33364,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='9.3' WHERE laboratorios_realizados.id_persona=261 AND laboratorios_realizados.id_control=200146 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 02:16:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33365,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='92' WHERE laboratorios_realizados.id_persona=261 AND laboratorios_realizados.id_control=200146 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 02:16:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33366,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='0+' WHERE laboratorios_realizados.id_persona=261 AND laboratorios_realizados.id_control=200146 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 02:16:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33367,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200147, '2022-06-17 02:13:45.000000', 263, 2, 1)",
                    "2022-06-17 02:16:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33368,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (263, 200147, 2, 'S')",
                    "2022-06-17 02:16:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33369,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (263, 200147, 3, 'C')",
                    "2022-06-17 02:16:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33370,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (263, 200147, 4, 'C')",
                    "2022-06-17 02:16:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33371,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (263, 200147, 6, 3, '2022-06-17 02:14:41.000000')",
                    "2022-06-17 02:16:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33372,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (263, 200147, 7, 3, '2022-06-17 02:14:41.000000')",
                    "2022-06-17 02:16:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33373,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (263, 200147, 9, 3, '2022-06-17 02:14:41.000000')",
                    "2022-06-17 02:16:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33374,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200133, 200147, 32, 'S', ' ', 'N', 'N', 120, 60, 'N', '')",
                    "2022-06-17 02:16:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33375,
                    "UPDATE controles SET georeferencia='-22.2729323,-62.7170488' WHERE controles.id_control=200147",
                    "2022-06-17 02:16:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33376,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200133",
                    "2022-06-17 02:16:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33377,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='11.3' WHERE laboratorios_realizados.id_persona=263 AND laboratorios_realizados.id_control=200147 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 02:16:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33378,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='129' WHERE laboratorios_realizados.id_persona=263 AND laboratorios_realizados.id_control=200147 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 02:16:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33379,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='0+' WHERE laboratorios_realizados.id_persona=263 AND laboratorios_realizados.id_control=200147 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 02:16:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33380,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200148, '2022-06-17 02:18:51.000000', 100168, 2, 1)",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33381,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100168, 200148, 2, 'S')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33382,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100168, 200148, 3, 'C')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33383,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100168, 200148, 4, 'C')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33384,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100168, 200148, 1, 3, '2022-06-17 02:19:36.000000', 3)",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33385,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100168, 200148, 2, 3, '2022-06-17 02:19:36.000000', 2)",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33386,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100168, 200148, 5, 3, '2022-06-17 02:19:36.000000', 4)",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33387,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100168, 200148, 6, 3, '2022-06-17 02:19:36.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33388,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100168, 200148, 7, 3, '2022-06-17 02:19:36.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33389,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100168, 200148, 9, 3, '2022-06-17 02:19:36.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33390,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200134, 200148, 34, 'S', ' ', 'N', 'N', 120, 80, 'N', '')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33391,
                    "UPDATE controles SET georeferencia='-22.2729323,-62.7170488' WHERE controles.id_control=200148",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33392,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200134",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33393,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=100168 AND laboratorios_realizados.id_control=200148 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33394,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=100168 AND laboratorios_realizados.id_control=200148 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33395,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=100168 AND laboratorios_realizados.id_control=200148 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33396,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='11.3' WHERE laboratorios_realizados.id_persona=100168 AND laboratorios_realizados.id_control=200148 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33397,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='94' WHERE laboratorios_realizados.id_persona=100168 AND laboratorios_realizados.id_control=200148 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33398,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='A+' WHERE laboratorios_realizados.id_persona=100168 AND laboratorios_realizados.id_control=200148 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33399,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200149, '2022-06-17 02:33:35.000000', 200064, 2, 1)",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33400,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200064, 200149, 2, 'S')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33401,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200064, 200149, 3, 'C')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33402,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200064, 200149, 4, 'C')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33403,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200064, 200149, 4, 1, '2022-06-17 02:34:26.000000', 1)",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33404,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200064, 200149, 6, 1, '2022-06-17 02:34:26.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33405,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200064, 200149, 7, 1, '2022-06-17 02:34:26.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33406,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200064, 200149, 9, 1, '2022-06-17 02:34:26.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33407,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200135, 200149, 13, 'S', ' ', 'N', 'N', 120, 60, 'N', '')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33408,
                    "UPDATE controles SET georeferencia='-22.2727922,-62.7170813' WHERE controles.id_control=200149",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33409,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200135",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33410,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=200064 AND laboratorios_realizados.id_control=200149 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33411,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='10' WHERE laboratorios_realizados.id_persona=200064 AND laboratorios_realizados.id_control=200149 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33412,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='101' WHERE laboratorios_realizados.id_persona=200064 AND laboratorios_realizados.id_control=200149 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33413,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='0+' WHERE laboratorios_realizados.id_persona=200064 AND laboratorios_realizados.id_control=200149 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33414,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200150, '2022-06-17 02:37:03.000000', 200065, 2, 1)",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33415,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200065, 200150, 2, 'S')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33416,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200065, 200150, 3, 'C')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33417,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200065, 200150, 4, 'C')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33418,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200065, 200150, 4, 2, '2022-06-17 02:37:37.000000', 1)",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33419,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200065, 200150, 6, 2, '2022-06-17 02:37:37.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33420,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200065, 200150, 7, 2, '2022-06-17 02:37:37.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33421,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200065, 200150, 9, 2, '2022-06-17 02:37:37.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33422,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200136, 200150, 16, 'S', ' ', 'N', 'N', 90, 60, 'N', '')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33423,
                    "UPDATE controles SET georeferencia='-22.27279,-62.7170256' WHERE controles.id_control=200150",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33424,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200136",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33425,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=200065 AND laboratorios_realizados.id_control=200150 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33426,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='10' WHERE laboratorios_realizados.id_persona=200065 AND laboratorios_realizados.id_control=200150 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33427,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='89' WHERE laboratorios_realizados.id_persona=200065 AND laboratorios_realizados.id_control=200150 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33428,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='0+' WHERE laboratorios_realizados.id_persona=200065 AND laboratorios_realizados.id_control=200150 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33429,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200151, '2022-06-17 02:38:51.000000', 200066, 2, 1)",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33430,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200066, 200151, 2, 'S')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33431,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200066, 200151, 3, 'C')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33432,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200066, 200151, 4, 'C')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33433,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200137, 200151, 20, 'S', ' ', 'N', 'N', 130, 70, 'N', '')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33434,
                    "UPDATE controles SET georeferencia='-22.2729337,-62.7170498' WHERE controles.id_control=200151",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33435,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200137",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33436,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200152, '2022-06-17 02:42:45.000000', 200067, 2, 1)",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33437,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200067, 200152, 2, 'S')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33438,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200067, 200152, 3, 'C')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33439,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200067, 200152, 4, 'C')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33440,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200067, 200152, 4, 1, '2022-06-17 02:43:12.000000', 1)",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33441,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200067, 200152, 6, 1, '2022-06-17 02:43:12.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33442,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200067, 200152, 7, 1, '2022-06-17 02:43:12.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33443,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200067, 200152, 9, 1, '2022-06-17 02:43:12.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33444,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200138, 200152, 12, 'S', ' ', 'N', 'N', 120, 80, 'N', '')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33445,
                    "UPDATE controles SET georeferencia='-22.2729333,-62.7170493' WHERE controles.id_control=200152",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33446,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200138",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33447,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=200067 AND laboratorios_realizados.id_control=200152 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33448,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='13.6' WHERE laboratorios_realizados.id_persona=200067 AND laboratorios_realizados.id_control=200152 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33449,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='344' WHERE laboratorios_realizados.id_persona=200067 AND laboratorios_realizados.id_control=200152 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33450,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='A+' WHERE laboratorios_realizados.id_persona=200067 AND laboratorios_realizados.id_control=200152 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33451,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200153, '2022-06-17 02:46:44.000000', 200068, 2, 1)",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33452,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200068, 200153, 2, 'S')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33453,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200068, 200153, 3, 'C')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33454,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200068, 200153, 4, 'C')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33455,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200068, 200153, 4, 2, '2022-06-17 02:47:29.000000', 1)",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33456,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200068, 200153, 6, 2, '2022-06-17 02:47:29.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33457,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200068, 200153, 7, 2, '2022-06-17 02:47:29.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33458,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200068, 200153, 9, 2, '2022-06-17 02:47:29.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33459,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200139, 200153, 25, 'S', ' ', 'N', 'N', 100, 50, 'N', '')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33460,
                    "UPDATE controles SET georeferencia='-22.2727597,-62.71705' WHERE controles.id_control=200153",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33461,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200139",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33462,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=200068 AND laboratorios_realizados.id_control=200153 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33463,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='10.6' WHERE laboratorios_realizados.id_persona=200068 AND laboratorios_realizados.id_control=200153 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33464,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='74' WHERE laboratorios_realizados.id_persona=200068 AND laboratorios_realizados.id_control=200153 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33465,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='0+' WHERE laboratorios_realizados.id_persona=200068 AND laboratorios_realizados.id_control=200153 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33466,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200154, '2022-06-17 02:48:55.000000', 200069, 2, 1)",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33467,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200069, 200154, 2, 'S')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33468,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200069, 200154, 3, 'C')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33469,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200069, 200154, 4, 'C')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33470,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200069, 200154, 1, 2, '2022-06-17 02:49:19.000000', 3)",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33471,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200069, 200154, 2, 2, '2022-06-17 02:49:19.000000', 2)",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33472,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200069, 200154, 4, 2, '2022-06-17 02:49:19.000000', 1)",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33473,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200069, 200154, 5, 2, '2022-06-17 02:49:19.000000', 4)",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33474,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200069, 200154, 6, 2, '2022-06-17 02:49:19.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33475,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200069, 200154, 7, 2, '2022-06-17 02:49:19.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33476,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200069, 200154, 9, 2, '2022-06-17 02:49:19.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33477,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200140, 200154, 27, 'S', ' ', 'N', 'N', 120, 80, 'N', '')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33478,
                    "UPDATE controles SET georeferencia='-22.2729287,-62.71705' WHERE controles.id_control=200154",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33479,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200140",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33480,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=200069 AND laboratorios_realizados.id_control=200154 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33481,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=200069 AND laboratorios_realizados.id_control=200154 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33482,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=200069 AND laboratorios_realizados.id_control=200154 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33483,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=200069 AND laboratorios_realizados.id_control=200154 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33484,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='11.6' WHERE laboratorios_realizados.id_persona=200069 AND laboratorios_realizados.id_control=200154 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33485,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='104' WHERE laboratorios_realizados.id_persona=200069 AND laboratorios_realizados.id_control=200154 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33486,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='A+' WHERE laboratorios_realizados.id_persona=200069 AND laboratorios_realizados.id_control=200154 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33487,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200155, '2022-06-17 02:57:05.000000', 200070, 2, 1)",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33488,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200070, 200155, 2, 'S')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33489,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200070, 200155, 3, 'C')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33490,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200070, 200155, 4, 'C')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33491,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200070, 200155, 1, 2, '2022-06-17 02:58:10.000000', 3)",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33492,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200070, 200155, 2, 2, '2022-06-17 02:58:10.000000', 2)",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33493,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200070, 200155, 4, 2, '2022-06-17 02:58:10.000000', 1)",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33494,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200070, 200155, 5, 2, '2022-06-17 02:58:10.000000', 4)",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33495,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200070, 200155, 6, 2, '2022-06-17 02:58:10.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33496,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200070, 200155, 7, 2, '2022-06-17 02:58:10.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33497,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200070, 200155, 9, 2, '2022-06-17 02:58:10.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33498,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200141, 200155, 25, 'S', ' ', 'N', 'N', 120, 70, 'N', '')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33499,
                    "UPDATE controles SET georeferencia='-22.2729267,-62.7170505' WHERE controles.id_control=200155",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33500,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200141",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33501,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=200070 AND laboratorios_realizados.id_control=200155 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33502,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=200070 AND laboratorios_realizados.id_control=200155 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33503,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=200070 AND laboratorios_realizados.id_control=200155 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33504,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=200070 AND laboratorios_realizados.id_control=200155 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33505,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='92' WHERE laboratorios_realizados.id_persona=200070 AND laboratorios_realizados.id_control=200155 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33506,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='0+' WHERE laboratorios_realizados.id_persona=200070 AND laboratorios_realizados.id_control=200155 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33507,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200156, '2022-06-17 03:00:01.000000', 200071, 2, 1)",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33508,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200071, 200156, 2, 'S')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33509,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200071, 200156, 3, 'C')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33510,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200071, 200156, 4, 'C')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33511,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200071, 200156, 4, 2, '2022-06-17 03:01:24.000000', 1)",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33512,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200071, 200156, 6, 2, '2022-06-17 03:01:24.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33513,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200071, 200156, 7, 2, '2022-06-17 03:01:24.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33514,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200071, 200156, 9, 2, '2022-06-17 03:01:24.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33515,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200142, 200156, 14, 'S', ' ', 'N', 'N', 110, 80, 'N', '')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33516,
                    "UPDATE controles SET georeferencia='-22.2727778,-62.7170688' WHERE controles.id_control=200156",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33517,
                    "UPDATE control_embarazo SET eco='P', detalle_eco='Endometrio 5mm, ginecorragia hace 5 dias' WHERE control_embarazo.id_control_embarazo=200142",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33518,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=200071 AND laboratorios_realizados.id_control=200156 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33519,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='10' WHERE laboratorios_realizados.id_persona=200071 AND laboratorios_realizados.id_control=200156 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33520,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='102' WHERE laboratorios_realizados.id_persona=200071 AND laboratorios_realizados.id_control=200156 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33521,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='0+' WHERE laboratorios_realizados.id_persona=200071 AND laboratorios_realizados.id_control=200156 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33522,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200157, '2022-06-17 03:04:10.000000', 200072, 2, 1)",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33523,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200072, 200157, 2, 'S')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33524,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200072, 200157, 3, 'C')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33525,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200072, 200157, 4, 'C')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33526,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200072, 200157, 4, 3, '2022-06-17 03:05:01.000000', 1)",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33527,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200072, 200157, 6, 3, '2022-06-17 03:05:01.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33528,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200072, 200157, 7, 3, '2022-06-17 03:05:01.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33529,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200072, 200157, 9, 3, '2022-06-17 03:05:01.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33530,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200143, 200157, 756, 'S', ' ', 'N', 'N', 110, 70, 'N', '')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33531,
                    "UPDATE controles SET georeferencia='-22.2727607,-62.7170515' WHERE controles.id_control=200157",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33532,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200143",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33533,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=200072 AND laboratorios_realizados.id_control=200157 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33534,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='11.3' WHERE laboratorios_realizados.id_persona=200072 AND laboratorios_realizados.id_control=200157 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33535,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='96' WHERE laboratorios_realizados.id_persona=200072 AND laboratorios_realizados.id_control=200157 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33536,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='0+' WHERE laboratorios_realizados.id_persona=200072 AND laboratorios_realizados.id_control=200157 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33537,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200158, '2022-06-17 03:07:18.000000', 200073, 2, 1)",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33538,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200073, 200158, 2, 'S')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33539,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200073, 200158, 3, 'C')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33540,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200073, 200158, 4, 'C')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33541,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200073, 200158, 4, 2, '2022-06-17 03:07:51.000000', 1)",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33542,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200073, 200158, 6, 2, '2022-06-17 03:07:51.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33543,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200073, 200158, 7, 2, '2022-06-17 03:07:51.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33544,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200073, 200158, 9, 2, '2022-06-17 03:07:51.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33545,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200144, 200158, 22, 'S', ' ', 'N', 'N', 100, 70, 'N', '')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33546,
                    "UPDATE controles SET georeferencia='-22.2727771,-62.717067' WHERE controles.id_control=200158",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33547,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200144",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33548,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=200073 AND laboratorios_realizados.id_control=200158 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33549,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='11' WHERE laboratorios_realizados.id_persona=200073 AND laboratorios_realizados.id_control=200158 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33550,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='104' WHERE laboratorios_realizados.id_persona=200073 AND laboratorios_realizados.id_control=200158 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33551,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='0+' WHERE laboratorios_realizados.id_persona=200073 AND laboratorios_realizados.id_control=200158 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33552,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200159, '2022-06-17 03:10:24.000000', 200074, 2, 1)",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33553,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200074, 200159, 2, 'S')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33554,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200074, 200159, 3, 'C')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33555,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200074, 200159, 4, 'C')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33556,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200074, 200159, 4, 2, '2022-06-17 03:11:12.000000', 1)",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33557,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200074, 200159, 6, 2, '2022-06-17 03:11:12.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33558,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200074, 200159, 7, 2, '2022-06-17 03:11:12.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33559,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200074, 200159, 9, 2, '2022-06-17 03:11:12.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33560,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200145, 200159, 23, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33561,
                    "UPDATE controles SET georeferencia='-22.272781,-62.717071' WHERE controles.id_control=200159",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33562,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200145",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33563,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=200074 AND laboratorios_realizados.id_control=200159 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33564,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='11' WHERE laboratorios_realizados.id_persona=200074 AND laboratorios_realizados.id_control=200159 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33565,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='121' WHERE laboratorios_realizados.id_persona=200074 AND laboratorios_realizados.id_control=200159 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33566,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='A+' WHERE laboratorios_realizados.id_persona=200074 AND laboratorios_realizados.id_control=200159 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33567,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200160, '2022-06-17 03:12:34.000000', 200075, 2, 1)",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33568,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200075, 200160, 2, 'S')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33569,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200075, 200160, 3, 'C')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33570,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200075, 200160, 4, 'C')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33571,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200075, 200160, 4, 3, '2022-06-17 03:13:03.000000', 1)",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33572,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200075, 200160, 6, 3, '2022-06-17 03:13:03.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33573,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200075, 200160, 7, 3, '2022-06-17 03:13:03.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33574,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200075, 200160, 9, 3, '2022-06-17 03:13:03.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33575,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200146, 200160, 30, 'S', ' ', 'N', 'N', 120, 80, 'N', '')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33576,
                    "UPDATE controles SET georeferencia='-22.2727762,-62.7170682' WHERE controles.id_control=200160",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33577,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200146",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33578,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=200075 AND laboratorios_realizados.id_control=200160 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33579,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='9.6' WHERE laboratorios_realizados.id_persona=200075 AND laboratorios_realizados.id_control=200160 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33580,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='108' WHERE laboratorios_realizados.id_persona=200075 AND laboratorios_realizados.id_control=200160 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33581,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='0+' WHERE laboratorios_realizados.id_persona=200075 AND laboratorios_realizados.id_control=200160 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33582,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200161, '2022-06-17 03:14:25.000000', 200076, 2, 1)",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33583,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200076, 200161, 2, 'S')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33584,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200076, 200161, 3, 'C')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33585,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200076, 200161, 4, 'C')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33586,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200076, 200161, 4, 3, '2022-06-17 03:14:46.000000', 1)",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33587,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200076, 200161, 6, 3, '2022-06-17 03:14:46.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33588,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200076, 200161, 7, 3, '2022-06-17 03:14:46.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33589,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200076, 200161, 9, 3, '2022-06-17 03:14:46.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33590,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200147, 200161, 347, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33591,
                    "UPDATE controles SET georeferencia='-22.2727778,-62.7170688' WHERE controles.id_control=200161",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33592,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200147",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33593,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=200076 AND laboratorios_realizados.id_control=200161 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33594,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='9.6' WHERE laboratorios_realizados.id_persona=200076 AND laboratorios_realizados.id_control=200161 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33595,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='92' WHERE laboratorios_realizados.id_persona=200076 AND laboratorios_realizados.id_control=200161 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33596,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='0+' WHERE laboratorios_realizados.id_persona=200076 AND laboratorios_realizados.id_control=200161 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33597,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200162, '2022-06-17 03:15:45.000000', 200077, 2, 1)",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33598,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200077, 200162, 2, 'S')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33599,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200077, 200162, 3, 'C')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33600,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200077, 200162, 4, 'C')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33601,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200077, 200162, 4, 3, '2022-06-17 03:16:12.000000', 1)",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33602,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200077, 200162, 6, 3, '2022-06-17 03:16:12.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33603,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200077, 200162, 7, 3, '2022-06-17 03:16:12.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33604,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200077, 200162, 9, 3, '2022-06-17 03:16:12.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33605,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200148, 200162, 37, 'S', ' ', 'N', 'N', 90, 60, 'N', '')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33606,
                    "UPDATE controles SET georeferencia='-22.2729268,-62.7170495' WHERE controles.id_control=200162",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33607,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200148",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33608,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=200077 AND laboratorios_realizados.id_control=200162 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33609,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='10' WHERE laboratorios_realizados.id_persona=200077 AND laboratorios_realizados.id_control=200162 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33610,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='93' WHERE laboratorios_realizados.id_persona=200077 AND laboratorios_realizados.id_control=200162 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33611,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='0+' WHERE laboratorios_realizados.id_persona=200077 AND laboratorios_realizados.id_control=200162 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33612,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200163, '2022-06-17 03:17:06.000000', 200078, 2, 1)",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33613,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200078, 200163, 2, 'S')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33614,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200078, 200163, 3, 'C')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33615,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200078, 200163, 4, 'C')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33616,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200078, 200163, 4, 2, '2022-06-17 03:17:52.000000', 1)",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33617,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200078, 200163, 6, 2, '2022-06-17 03:17:52.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33618,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200078, 200163, 7, 2, '2022-06-17 03:17:52.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33619,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200078, 200163, 9, 2, '2022-06-17 03:17:52.000000')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33620,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200149, 200163, 23, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33621,
                    "UPDATE controles SET georeferencia='-22.2729292,-62.7170514' WHERE controles.id_control=200163",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33622,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200149",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33623,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=200078 AND laboratorios_realizados.id_control=200163 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33624,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='13.6' WHERE laboratorios_realizados.id_persona=200078 AND laboratorios_realizados.id_control=200163 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33625,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='102' WHERE laboratorios_realizados.id_persona=200078 AND laboratorios_realizados.id_control=200163 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    33626,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='0+' WHERE laboratorios_realizados.id_persona=200078 AND laboratorios_realizados.id_control=200163 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 03:51:57",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35143,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200164, '2022-06-17 04:05:07.000000', 281, 2, 1)",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35144,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (281, 200164, 2, 'S')",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35145,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (281, 200164, 3, 'C')",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35146,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (281, 200164, 4, 'C')",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35147,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (281, 200164, 1, 2, '2022-06-17 04:05:54.000000', 3)",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35148,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (281, 200164, 2, 2, '2022-06-17 04:05:54.000000', 2)",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35149,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (281, 200164, 19, 2, '2022-06-17 04:05:54.000000', 1)",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35150,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (281, 200164, 20, 2, '2022-06-17 04:05:54.000000', 1)",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35151,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (281, 200164, 23, 2, '2022-06-17 04:05:54.000000', 1)",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35152,
                    "INSERT INTO etmis_personas (id_persona, id_etmi, id_control) VALUES (281, 1, 200164)",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35153,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (281, 200164, 4, 2, '2022-06-17 04:05:54.000000', '2022-06-17 04:05:54.000000', 'P', 1)",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35154,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (281, 200164, 6, 2, '2022-06-17 04:05:54.000000')",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35155,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (281, 200164, 7, 2, '2022-06-17 04:05:54.000000')",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35156,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200150, 200164, 23, 'S', ' ', 'N', 'N', 120, 70, 'N', '')",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35157,
                    "UPDATE controles SET georeferencia='-22.272771,-62.7170522' WHERE controles.id_control=200164",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35158,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200150",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35159,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=281 AND laboratorios_realizados.id_control=200164 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35160,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=281 AND laboratorios_realizados.id_control=200164 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35161,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='11.6' WHERE laboratorios_realizados.id_persona=281 AND laboratorios_realizados.id_control=200164 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35162,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='106' WHERE laboratorios_realizados.id_persona=281 AND laboratorios_realizados.id_control=200164 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35163,
                    "SELECT etmis_personas.id_persona, etmis_personas.id_etmi, etmis_personas.id_control, etmis_personas.confirmada FROM etmis_personas WHERE etmis_personas.id_etmi=1 AND etmis_personas.confirmada=0 AND etmis_personas.id_control='200164' LIMIT 1",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35164,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=281 AND laboratorios_realizados.id_control=200164 AND laboratorios_realizados.id_laboratorio=19 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35165,
                    "SELECT etmis_personas.id_persona, etmis_personas.id_etmi, etmis_personas.id_control, etmis_personas.confirmada FROM etmis_personas WHERE etmis_personas.id_etmi=1 AND etmis_personas.confirmada=0 AND etmis_personas.id_control='200164' LIMIT 1",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35166,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=281 AND laboratorios_realizados.id_control=200164 AND laboratorios_realizados.id_laboratorio=20 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35167,
                    "SELECT etmis_personas.id_persona, etmis_personas.id_etmi, etmis_personas.id_control, etmis_personas.confirmada FROM etmis_personas WHERE etmis_personas.id_etmi=1 AND etmis_personas.confirmada=0 AND etmis_personas.id_control='200164' LIMIT 1",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35168,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=281 AND laboratorios_realizados.id_control=200164 AND laboratorios_realizados.id_laboratorio=23 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35169,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (200165, '2022-06-17 04:38:18.000000', 242, 2, 1)",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35170,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (242, 200165, 2, 'S')",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35171,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (242, 200165, 3, 'C')",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35172,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (242, 200165, 4, 'C')",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35173,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (242, 200165, 1, 3, '2022-06-17 04:39:29.000000', 3)",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35174,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (242, 200165, 2, 3, '2022-06-17 04:39:29.000000', 2)",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35175,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (242, 200165, 6, 3, '2022-06-17 04:39:29.000000')",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35176,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (242, 200165, 7, 3, '2022-06-17 04:39:29.000000')",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35177,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (200151, 200165, 35, 'S', ' ', 'N', 'N', 100, 70, 'N', '')",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35178,
                    "UPDATE controles SET georeferencia='-22.2727775,-62.7170319' WHERE controles.id_control=200165",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35179,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200151",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35180,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=242 AND laboratorios_realizados.id_control=200165 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35181,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='N' WHERE laboratorios_realizados.id_persona=242 AND laboratorios_realizados.id_control=200165 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35182,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='11' WHERE laboratorios_realizados.id_persona=242 AND laboratorios_realizados.id_control=200165 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    35183,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-17', resultado='82' WHERE laboratorios_realizados.id_persona=242 AND laboratorios_realizados.id_control=200165 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-17 04:40:15",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40111,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (342, 'VEGA', 'LILIANA CLARISA', '36557151', '1992-04-03 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40112,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (585, '2022-06-13 10:39:05.000000', 342, 1, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40113,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (342, 342, 18, 3, '00', '2022-06-13 10:39:05.000000', '', 12)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40114,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, abortos, planificado, fum, fpp) VALUES (259, 342, 585, 16, '2020-12-15 00:00:00.000000', 8, 6, 1, 0, '2021-10-27 00:00:00.000000', '2022-08-03 00:00:00.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40115,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (342, 585, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40116,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (342, 585, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40117,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (342, 585, 1, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40118,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (342, 585, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40119,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (342, 585, 1, 3, '2022-06-13 10:43:36.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40120,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (342, 585, 2, 3, '2022-06-13 10:43:36.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40121,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (342, 585, 4, 3, '2022-06-13 10:43:36.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40122,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (342, 585, 5, 3, '2022-06-13 10:43:36.000000', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40123,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (342, 585, 6, 3, '2022-06-13 10:43:36.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40124,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (342, 585, 7, 3, '2022-06-13 10:43:36.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40125,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (342, 585, 9, 3, '2022-06-13 10:43:36.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40126,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (387, 585, 32, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40127,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (586, '2022-06-13 10:55:23.000000', 248, 2, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40128,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (248, 586, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40129,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (248, 586, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40130,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (248, 586, 4, 'C')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40131,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (248, 586, 5, 2, '2022-06-13 11:02:37.000000', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40132,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (248, 586, 6, 2, '2022-06-13 11:02:37.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40133,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (248, 586, 7, 2, '2022-06-13 11:02:37.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40134,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (388, 586, 24, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40135,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (343, 'CRISTOBAL', 'ELVIS RUTH', '45116235', '2000-08-06 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40136,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (587, '2022-06-13 11:06:20.000000', 343, 1, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40137,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (343, 343, 18, 3, '00', '2022-06-13 11:06:20.000000', '', 12)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40138,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (344, 'SEGUNDO', 'ARCILIA', '47451668', '2008-06-06 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40139,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (588, '2022-06-13 11:14:16.000000', 344, 1, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40140,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (344, 344, 18, 3, '00', '2022-06-13 11:14:16.000000', '', 12)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40141,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, planificado, fum, fpp) VALUES (260, 344, 588, 15, '2030-10-31 00:00:00.000000', 1, 0, '2022-02-15 00:00:00.000000', '2022-11-22 00:00:00.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40142,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (344, 588, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40143,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (344, 588, 3, 'C')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40144,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (344, 588, 1, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40145,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (344, 588, 4, 'C')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40146,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (344, 588, 1, 2, '2022-06-13 11:23:11.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40147,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (344, 588, 2, 2, '2022-06-13 11:23:11.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40148,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (344, 588, 4, 2, '2022-06-13 11:23:11.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40149,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (344, 588, 5, 2, '2022-06-13 11:23:11.000000', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40150,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (344, 588, 6, 2, '2022-06-13 11:23:11.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40151,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (344, 588, 7, 2, '2022-06-13 11:23:11.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40152,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (389, 588, 16, 'S', ' ', 'N', 'N', 80, 60, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40153,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (589, '2022-06-13 11:29:11.000000', 247, 2, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40154,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (247, 589, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40155,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (247, 589, 3, 'C')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40156,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (247, 589, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40157,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (247, 589, 5, 2, '2022-06-13 11:33:34.000000', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40158,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (247, 589, 6, 2, '2022-06-13 11:33:34.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40159,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (247, 589, 7, 2, '2022-06-13 11:33:34.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40160,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (390, 589, 24, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40161,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (590, '2022-06-13 11:36:46.000000', 251, 2, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40162,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (251, 590, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40163,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (251, 590, 3, 'C')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40164,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (251, 590, 4, 'C')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40165,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (251, 590, 5, 2, '2022-06-13 11:38:34.000000', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40166,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (251, 590, 6, 2, '2022-06-13 11:38:34.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40167,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (251, 590, 7, 2, '2022-06-13 11:38:34.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40168,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (391, 590, 24, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40169,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (345, 'LEZCANO', 'INES', '46666373', '2013-02-06 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40170,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (591, '2022-06-13 11:46:29.000000', 345, 1, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40171,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (345, 345, 18, 3, '00', '2022-06-13 11:46:29.000000', '', 12)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40172,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, cesareas, planificado, fum, fpp) VALUES (261, 345, 591, 13, '2020-11-28 00:00:00.000000', 2, 1, 0, '2022-02-03 00:00:00.000000', '2022-11-10 00:00:00.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40173,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (345, 591, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40174,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (345, 591, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40175,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (345, 591, 1, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40176,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (345, 591, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40177,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (345, 591, 1, 2, '2022-06-13 11:51:08.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40178,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (345, 591, 2, 2, '2022-06-13 11:51:08.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40179,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (345, 591, 4, 2, '2022-06-13 11:51:08.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40180,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (345, 591, 5, 2, '2022-06-13 11:51:08.000000', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40181,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (345, 591, 6, 2, '2022-06-13 11:51:08.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40182,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (345, 591, 7, 2, '2022-06-13 11:51:08.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40183,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (345, 591, 9, 2, '2022-06-13 11:51:08.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40184,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (392, 591, 18, 'S', ' ', 'N', 'N', 90, 60, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40185,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, abortos, planificado, fum, fpp) VALUES (262, 343, 587, 0, 6, 4, 1, 0, '2021-04-22 00:00:00.000000', '2026-01-23 00:00:00.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40186,
                    "INSERT INTO antecedentes_apps (id_antecedente, id_app) VALUES (262, 8)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40187,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (343, 587, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40188,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (343, 587, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40189,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (343, 587, 1, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40190,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (343, 587, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40191,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (343, 587, 1, 3, '2022-06-13 12:00:26.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40192,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (343, 587, 2, 3, '2022-06-13 12:00:26.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40193,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (343, 587, 19, 3, '2022-06-13 12:00:26.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40194,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (343, 587, 20, 3, '2022-06-13 12:00:26.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40195,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (343, 587, 23, 3, '2022-06-13 12:00:26.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40196,
                    "INSERT INTO etmis_personas (id_persona, id_etmi, id_control) VALUES (343, 1, 587)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40197,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (343, 587, 4, 3, '2022-06-13 12:00:26.000000', '2022-06-13 12:00:26.000000', 'P', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40198,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (343, 587, 5, 3, '2022-06-13 12:00:26.000000', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40199,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (343, 587, 6, 3, '2022-06-13 12:00:26.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40200,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (343, 587, 7, 3, '2022-06-13 12:00:26.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40201,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (343, 587, 9, 3, '2022-06-13 12:00:26.000000', '2022-06-13 12:00:26.000000', '0+')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40202,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (393, 587, 59, 'R', ' ', 'N', 'N', 110, 60, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40203,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (592, '2022-06-13 12:02:09.000000', 154, 3, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40204,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (154, 592, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40205,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (154, 592, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40206,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (154, 592, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40207,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (154, 592, 5, 3, '2022-06-13 12:05:10.000000', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40208,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (154, 592, 6, 3, '2022-06-13 12:05:10.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40209,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (154, 592, 7, 3, '2022-06-13 12:05:10.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40210,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (394, 592, 37, 'S', ' ', 'N', 'N', 110, 60, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40211,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (346, 'JUAREZ', 'ALICIA', '39401692', '1993-01-01 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40212,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (593, '2022-06-13 12:09:17.000000', 346, 1, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40213,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (346, 346, 18, 3, '00', '2022-06-13 12:09:17.000000', '', 12)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40214,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (347, 'DIAZ', 'ANA', '43219193', '2021-04-29 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40215,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (594, '2022-06-13 12:14:35.000000', 347, 1, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40216,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (347, 347, 18, 3, '0', '2022-06-13 12:14:35.000000', '', 12)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40217,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (263, 347, 594, 21, 1, 1, '2021-02-28 00:00:00.000000', '2021-12-05 00:00:00.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40218,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (347, 594, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40219,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (347, 594, 3, 'N')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40220,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (347, 594, 1, 'N')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40221,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (347, 594, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40222,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (347, 594, 1, 3, '2022-06-13 12:18:32.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40223,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (347, 594, 2, 3, '2022-06-13 12:18:32.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40224,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (347, 594, 4, 3, '2022-06-13 12:18:32.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40225,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (347, 594, 5, 3, '2022-06-13 12:18:32.000000', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40226,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (347, 594, 6, 3, '2022-06-13 12:18:32.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40227,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (347, 594, 7, 3, '2022-06-13 12:18:32.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40228,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (347, 594, 8, 3, '2022-06-13 12:18:32.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40229,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (347, 594, 9, 3, '2022-06-13 12:18:32.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40230,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (395, 594, 67, 'S', ' ', 'N', 'N', 80, 60, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40231,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (595, '2022-06-13 12:19:33.000000', 347, 2, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40232,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (347, 595, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40233,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (347, 595, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40234,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (347, 595, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40235,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (396, 595, 15, 'S', ' ', 'N', 'N', 100, 60, 'N', 'EDAD GESTACIONAL 15 POR ECO ANTEROR')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40236,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, planificado, fum, fpp) VALUES (264, 346, 593, 0, 3, 2, 0, '2021-11-09 00:00:00.000000', '2022-08-16 00:00:00.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40237,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (346, 593, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40238,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (346, 593, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40239,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (346, 593, 1, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40240,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (346, 593, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40241,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (346, 593, 1, 3, '2022-06-13 12:23:36.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40242,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (346, 593, 2, 3, '2022-06-13 12:23:36.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40243,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (346, 593, 4, 3, '2022-06-13 12:23:36.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40244,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (346, 593, 5, 3, '2022-06-13 12:23:36.000000', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40245,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (346, 593, 6, 3, '2022-06-13 12:23:36.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40246,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (346, 593, 7, 3, '2022-06-13 12:23:36.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40247,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (346, 593, 9, 3, '2022-06-13 12:23:36.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40248,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (397, 593, 30, 'S', ' ', 'N', 'N', 110, 70, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40249,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (596, '2022-06-13 12:36:18.000000', 249, 2, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40250,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (249, 596, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40251,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (249, 596, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40252,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (249, 596, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40253,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (249, 596, 1, 3, '2022-06-13 12:41:55.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40254,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (249, 596, 2, 3, '2022-06-13 12:41:55.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40255,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (249, 596, 5, 3, '2022-06-13 12:41:55.000000', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40256,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (249, 596, 7, 3, '2022-06-13 12:41:55.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40257,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (398, 596, 41, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40258,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (348, 'VEGA', 'LIDA', '44910993', '2004-03-25 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40259,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (597, '2022-06-13 12:46:21.000000', 348, 1, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40260,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (348, 348, 18, 3, '00', '2022-06-13 12:46:21.000000', '', 12)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40261,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, planificado, fum, fpp) VALUES (265, 348, 597, 18, '2030-10-31 00:00:00.000000', 1, 1, '2021-12-27 00:00:00.000000', '2022-10-03 00:00:00.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40262,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (348, 597, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40263,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (348, 597, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40264,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (348, 597, 1, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40265,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (348, 597, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40266,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (348, 597, 1, 2, '2022-06-13 12:51:15.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40267,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (348, 597, 2, 2, '2022-06-13 12:51:15.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40268,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (348, 597, 4, 2, '2022-06-13 12:51:15.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40269,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (348, 597, 5, 2, '2022-06-13 12:51:15.000000', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40270,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (348, 597, 6, 2, '2022-06-13 12:51:15.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40271,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (348, 597, 7, 2, '2022-06-13 12:51:15.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40272,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (348, 597, 9, 2, '2022-06-13 12:51:15.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40273,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (399, 597, 24, 'S', ' ', 'N', 'N', 80, 60, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40274,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (349, 'NOGAL', 'VANESA', '45056355', '2000-09-17 00:00:00.000000', 2, 12, 'F')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40275,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (598, '2022-06-13 12:57:38.000000', 349, 1, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40276,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (349, 349, 18, 3, '00', '2022-06-13 12:57:38.000000', '', 12)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40277,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, planificado, fum, fpp) VALUES (266, 349, 598, 14, 3, 2, 1, '2022-02-24 00:00:00.000000', '2022-12-01 00:00:00.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40278,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (349, 598, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40279,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (349, 598, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40280,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (349, 598, 1, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40281,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (349, 598, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40282,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (349, 598, 1, 2, '2022-06-13 12:59:48.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40283,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (349, 598, 2, 2, '2022-06-13 12:59:48.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40284,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (349, 598, 4, 2, '2022-06-13 12:59:48.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40285,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (349, 598, 5, 2, '2022-06-13 12:59:48.000000', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40286,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (349, 598, 6, 2, '2022-06-13 12:59:48.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40287,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (349, 598, 7, 2, '2022-06-13 12:59:48.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40288,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (349, 598, 9, 2, '2022-06-13 12:59:48.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40289,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (400, 598, 15, 'S', ' ', 'N', 'N', 90, 60, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40290,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (350, 'NOGAL', 'NELIDA', '45056352', '1992-04-12 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40291,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (599, '2022-06-13 13:03:17.000000', 350, 1, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40292,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (350, 350, 18, 3, '00', '2022-06-13 13:03:17.000000', '', 12)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40293,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, abortos, planificado, fum, fpp) VALUES (267, 350, 599, 0, 10, 6, 3, 0, '2025-02-22 00:00:00.000000', '2002-12-22 00:00:00.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40294,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (350, 599, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40295,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (350, 599, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40296,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (350, 599, 1, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40297,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (350, 599, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40298,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (350, 599, 1, 2, '2022-06-13 13:10:10.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40299,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (350, 599, 2, 2, '2022-06-13 13:10:10.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40300,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (350, 599, 4, 2, '2022-06-13 13:10:10.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40301,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (350, 599, 5, 2, '2022-06-13 13:10:10.000000', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40302,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (350, 599, 6, 2, '2022-06-13 13:10:10.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40303,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (350, 599, 7, 2, '2022-06-13 13:10:10.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40304,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (350, 599, 9, 2, '2022-06-13 13:10:10.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40305,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (401, 599, 15, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40306,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (351, 'SEGUNDO', 'MELBA', '45116194', '1999-04-18 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40307,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (600, '2022-06-13 13:15:39.000000', 351, 1, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40308,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (351, 351, 18, 3, '00', '2022-06-13 13:15:39.000000', '', 12)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40309,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, abortos, planificado, fum, fpp) VALUES (268, 351, 600, 0, 3, 1, 1, 1, '2022-01-06 00:00:00.000000', '2022-10-13 00:00:00.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40310,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (351, 600, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40311,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (351, 600, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40312,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (351, 600, 1, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40313,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (351, 600, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40314,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (351, 600, 1, 2, '2022-06-13 13:24:42.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40315,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (351, 600, 2, 2, '2022-06-13 13:24:42.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40316,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (351, 600, 4, 2, '2022-06-13 13:24:42.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40317,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (351, 600, 5, 2, '2022-06-13 13:24:42.000000', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40318,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (351, 600, 6, 2, '2022-06-13 13:24:42.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40319,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (351, 600, 7, 2, '2022-06-13 13:24:42.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40320,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (351, 600, 9, 2, '2022-06-13 13:24:42.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40321,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (402, 600, 22, 'S', ' ', 'N', 'N', 90, 60, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40322,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (352, 'ROJAS', 'MARIANA', '0000', '1990-06-02 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40323,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (601, '2022-06-13 13:27:58.000000', 352, 1, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40324,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (352, 352, 18, 3, '00', '2022-06-13 13:27:58.000000', '', 12)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40325,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, cesareas, planificado, fum, fpp) VALUES (269, 352, 601, 0, 3, 2, 1, '2022-02-07 00:00:00.000000', '2022-11-14 00:00:00.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40326,
                    "INSERT INTO antecedentes_apps (id_antecedente, id_app) VALUES (269, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40327,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (269, 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40328,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (352, 601, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40329,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (352, 601, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40330,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (352, 601, 1, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40331,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (352, 601, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40332,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (352, 601, 1, 2, '2022-06-13 13:31:01.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40333,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (352, 601, 2, 2, '2022-06-13 13:31:01.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40334,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (352, 601, 4, 2, '2022-06-13 13:31:01.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40335,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (352, 601, 5, 2, '2022-06-13 13:31:01.000000', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40336,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (352, 601, 6, 2, '2022-06-13 13:31:01.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40337,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (352, 601, 7, 2, '2022-06-13 13:31:01.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40338,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (352, 601, 9, 2, '2022-06-13 13:31:01.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40339,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (403, 601, 18, 'R', ' ', 'N', 'N', 110, 60, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40340,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (353, 'ALVAREZ', 'SOFIA', '38651038', '2026-01-21 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40341,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (602, '2022-06-13 13:53:03.000000', 353, 1, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40342,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (353, 353, 18, 3, '00', '2022-06-13 13:53:03.000000', '', 12)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40343,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, planificado, fum, fpp) VALUES (270, 353, 602, 0, 4, 3, 0, '2022-12-21 00:00:00.000000', '2028-09-22 00:00:00.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40344,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (603, '2022-06-13 13:56:37.000000', 200018, 3, 2, '2022-03-06 00:00:00.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40345,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (80, 603)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40346,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=200018",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40347,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (353, 602, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40348,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (353, 602, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40349,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (353, 602, 1, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40350,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (353, 602, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40351,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (353, 602, 1, 2, '2022-06-13 14:03:47.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40352,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (353, 602, 2, 2, '2022-06-13 14:03:47.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40353,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (353, 602, 4, 2, '2022-06-13 14:03:47.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40354,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (353, 602, 5, 2, '2022-06-13 14:03:47.000000', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40355,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (353, 602, 6, 2, '2022-06-13 14:03:47.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40356,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (353, 602, 7, 2, '2022-06-13 14:03:47.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40357,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (353, 602, 9, 2, '2022-06-13 14:03:47.000000', '2022-06-13 14:03:47.000000', '0+')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40358,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (404, 602, 27, 'S', ' ', 'N', 'N', 90, 60, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40359,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (354, 'TEJADA', 'EMILSA', '37164145', '1992-11-13 00:00:00.000000', 2, 12, 'F')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40360,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (604, '2022-06-13 14:11:54.000000', 354, 1, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40361,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (354, 354, 18, 3, '00', '2022-06-13 14:11:54.000000', '', 12)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40362,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, planificado, fum, fpp) VALUES (271, 354, 604, 0, 2, 1, 0, '2022-02-05 00:00:00.000000', '2022-11-12 00:00:00.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40363,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (354, 604, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40364,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (354, 604, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40365,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (354, 604, 1, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40366,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (354, 604, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40367,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (354, 604, 1, 2, '2022-06-13 14:15:14.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40368,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (354, 604, 2, 2, '2022-06-13 14:15:14.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40369,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (354, 604, 4, 2, '2022-06-13 14:15:14.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40370,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (354, 604, 5, 2, '2022-06-13 14:15:14.000000', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40371,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (354, 604, 6, 2, '2022-06-13 14:15:14.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40372,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (354, 604, 7, 2, '2022-06-13 14:15:14.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40373,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (354, 604, 9, 2, '2022-06-13 14:15:14.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40374,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (405, 604, 18, 'S', ' ', 'N', 'N', 90, 50, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40375,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (605, '2022-06-13 14:24:33.000000', 100016, 3, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40376,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100016, 605, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40377,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100016, 605, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40378,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100016, 605, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40379,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100016, 605, 1, 3, '2022-06-13 14:27:32.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40380,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100016, 605, 2, 3, '2022-06-13 14:27:32.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40381,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100016, 605, 5, 3, '2022-06-13 14:27:32.000000', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40382,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100016, 605, 6, 3, '2022-06-13 14:27:32.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40383,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100016, 605, 7, 3, '2022-06-13 14:27:32.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40384,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (406, 605, 36, 'S', ' ', 'N', 'N', 120, 70, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40385,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (355, 'CASIANO', 'ADELINA FANY', '40629136', '1991-03-16 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40386,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (606, '2022-06-13 14:33:19.000000', 355, 1, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40387,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (355, 355, 18, 3, '00', '2022-06-13 14:33:19.000000', '', 12)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40388,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (607, '2022-06-13 14:41:09.000000', 219, 2, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40389,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (219, 607, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40390,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (219, 607, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40391,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (219, 607, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40392,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (219, 607, 1, 3, '2022-06-13 14:43:03.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40393,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (219, 607, 2, 3, '2022-06-13 14:43:03.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40394,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (219, 607, 5, 3, '2022-06-13 14:43:03.000000', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40395,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (219, 607, 6, 3, '2022-06-13 14:43:03.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40396,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (219, 607, 7, 3, '2022-06-13 14:43:03.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40397,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (407, 607, 35, 'R', ' ', 'N', 'N', 110, 60, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40398,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (356, 'GALARZA', 'LETICIA', '43439060', '2001-08-10 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40399,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (608, '2022-06-13 14:46:52.000000', 356, 1, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40400,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (356, 356, 18, 3, '00', '2022-06-13 14:46:52.000000', '', 12)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40401,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, cesareas, planificado, fum, fpp) VALUES (272, 355, 606, 0, '2016-01-19 00:00:00.000000', 4, 2, 1, 0, '2021-11-01 00:00:00.000000', '2022-08-08 00:00:00.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40402,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (355, 606, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40403,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (355, 606, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40404,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (355, 606, 1, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40405,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (355, 606, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40406,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (355, 606, 1, 2, '2022-06-13 14:55:11.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40407,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (355, 606, 2, 2, '2022-06-13 14:55:11.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40408,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (355, 606, 4, 2, '2022-06-13 14:55:11.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40409,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (355, 606, 5, 2, '2022-06-13 14:55:11.000000', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40410,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (355, 606, 6, 2, '2022-06-13 14:55:11.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40411,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (355, 606, 7, 2, '2022-06-13 14:55:11.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40412,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (355, 606, 9, 2, '2022-06-13 14:55:11.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40413,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (408, 606, 26, 'S', ' ', 'N', 'N', 110, 60, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40414,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, cesareas, planificado, fum, fpp) VALUES (273, 356, 608, 0, 2, 1, 0, '2021-11-01 00:00:00.000000', '2022-08-08 00:00:00.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40415,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (356, 608, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40416,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (356, 608, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40417,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (356, 608, 1, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40418,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (356, 608, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40419,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (356, 608, 1, 3, '2022-06-13 14:57:54.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40420,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (356, 608, 2, 3, '2022-06-13 14:57:54.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40421,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (356, 608, 4, 3, '2022-06-13 14:57:54.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40422,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (356, 608, 5, 3, '2022-06-13 14:57:54.000000', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40423,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (356, 608, 6, 3, '2022-06-13 14:57:54.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40424,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (356, 608, 7, 3, '2022-06-13 14:57:54.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40425,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (356, 608, 9, 3, '2022-06-13 14:57:54.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40426,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (409, 608, 32, 'S', ' ', 'N', 'N', 110, 60, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40427,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (357, 'PEREZ', 'VERENICE', '44566172', '2003-08-17 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40428,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (609, '2022-06-13 15:01:26.000000', 357, 1, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40429,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (357, 357, 18, 3, '00', '2022-06-13 15:01:26.000000', '', 12)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40430,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, planificado, fum, fpp) VALUES (274, 357, 609, 0, 2, 1, 1, '2021-11-18 00:00:00.000000', '2022-08-25 00:00:00.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40431,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (357, 609, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40432,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (357, 609, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40433,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (357, 609, 1, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40434,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (357, 609, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40435,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (357, 609, 1, 3, '2022-06-13 15:05:28.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40436,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (357, 609, 2, 3, '2022-06-13 15:05:28.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40437,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (357, 609, 4, 3, '2022-06-13 15:05:28.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40438,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (357, 609, 5, 3, '2022-06-13 15:05:28.000000', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40439,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (357, 609, 6, 3, '2022-06-13 15:05:28.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40440,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (357, 609, 7, 3, '2022-06-13 15:05:28.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40441,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (357, 609, 9, 3, '2022-06-13 15:05:28.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40442,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (410, 609, 29, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40443,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (610, '2022-06-13 15:08:57.000000', 216, 2, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40444,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (216, 610, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40445,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (216, 610, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40446,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (216, 610, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40447,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (216, 610, 1, 3, '2022-06-13 15:12:31.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40448,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (216, 610, 2, 3, '2022-06-13 15:12:31.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40449,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (216, 610, 5, 3, '2022-06-13 15:12:31.000000', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40450,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (216, 610, 6, 3, '2022-06-13 15:12:31.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40451,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (216, 610, 7, 3, '2022-06-13 15:12:31.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40452,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (411, 610, 33, 'S', ' ', 'N', 'N', 80, 60, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40453,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (358, 'DIAZ', 'ANABEL', '58651053', '1995-08-17 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40454,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (611, '2022-06-13 15:38:22.000000', 358, 1, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40455,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (358, 358, 18, 3, '00', '2022-06-13 15:38:22.000000', '', 12)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40456,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, abortos, planificado, fum, fpp) VALUES (275, 358, 611, 0, 7, 3, 3, 1, '2021-12-17 00:00:00.000000', '2022-09-23 00:00:00.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40457,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (358, 611, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40458,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (358, 611, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40459,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (358, 611, 1, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40460,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (358, 611, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40461,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (358, 611, 5, 2, '2022-06-13 15:40:58.000000', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40462,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (358, 611, 6, 2, '2022-06-13 15:40:58.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40463,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (358, 611, 7, 2, '2022-06-13 15:40:58.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40464,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (412, 611, 25, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40465,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (612, '2022-06-13 18:23:15.000000', 165, 2, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40466,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (165, 612, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40467,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (165, 612, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40468,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (165, 612, 4, 'C')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40469,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (165, 612, 1, 3, '2022-06-13 18:30:34.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40470,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (165, 612, 2, 3, '2022-06-13 18:30:34.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40471,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (165, 612, 5, 3, '2022-06-13 18:30:34.000000', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40472,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (165, 612, 6, 3, '2022-06-13 18:30:34.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40473,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (165, 612, 7, 3, '2022-06-13 18:30:34.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40474,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (413, 612, 35, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40475,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (359, 'TOLEDO', 'CAMILA AZUCENA', '43438836', '2001-05-10 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40476,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (613, '2022-06-13 18:32:44.000000', 359, 1, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40477,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (359, 359, 21, 3, '00', '2022-06-13 18:32:44.000000', '', 12)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40478,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, cesareas, planificado, fum, fpp) VALUES (276, 359, 613, 0, 2, 1, 0, '2022-02-09 00:00:00.000000', '2022-11-16 00:00:00.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40479,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (276, 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40480,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (359, 613, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40481,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (359, 613, 3, 'C')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40482,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (359, 613, 1, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40483,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (359, 613, 4, 'C')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40484,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (359, 613, 1, 2, '2022-06-13 18:35:30.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40485,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (359, 613, 2, 2, '2022-06-13 18:35:30.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40486,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (359, 613, 4, 2, '2022-06-13 18:35:30.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40487,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (359, 613, 5, 2, '2022-06-13 18:35:30.000000', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40488,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (359, 613, 6, 2, '2022-06-13 18:35:30.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40489,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (359, 613, 7, 2, '2022-06-13 18:35:30.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40490,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (359, 613, 9, 2, '2022-06-13 18:35:30.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40491,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (414, 613, 17, 'S', ' ', 'N', 'N', 80, 50, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40492,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (360, 'SEGUNDO', 'CIRILA MALVINA', '30126944', '1984-03-26 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40493,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (614, '2022-06-13 18:51:31.000000', 360, 1, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40494,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (360, 360, 21, 3, '00', '2022-06-13 18:51:31.000000', '', 12)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40495,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, abortos, planificado, fum, fpp) VALUES (277, 360, 614, 14, '2013-04-17 00:00:00.000000', 6, 4, 1, 0, '2022-01-06 00:00:00.000000', '2022-10-13 00:00:00.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40496,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (360, 614, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40497,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (360, 614, 3, 'C')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40498,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (360, 614, 1, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40499,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (360, 614, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40500,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (360, 614, 1, 2, '2022-06-13 18:55:26.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40501,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (360, 614, 2, 2, '2022-06-13 18:55:26.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40502,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (360, 614, 4, 2, '2022-06-13 18:55:26.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40503,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (360, 614, 5, 2, '2022-06-13 18:55:26.000000', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40504,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (360, 614, 6, 2, '2022-06-13 18:55:26.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40505,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (360, 614, 7, 2, '2022-06-13 18:55:26.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40506,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (360, 614, 9, 2, '2022-06-13 18:55:26.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40507,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (415, 614, 22, 'S', ' ', 'N', 'N', 120, 80, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40508,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (361, 'SEGUNDO', 'NATALIA NOEMI', '45852031', '1995-05-06 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40509,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (615, '2022-06-13 19:00:49.000000', 361, 1, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40510,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (361, 361, 21, 3, '00', '2022-06-13 19:00:49.000000', '', 12)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40511,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (278, 361, 615, 0, '2019-09-22 00:00:00.000000', 5, 4, 1, '2022-03-06 00:00:00.000000', '2022-12-11 00:00:00.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40512,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (361, 615, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40513,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (361, 615, 3, 'C')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40514,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (361, 615, 1, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40515,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (361, 615, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40516,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (361, 615, 1, 2, '2022-06-13 19:03:28.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40517,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (361, 615, 2, 2, '2022-06-13 19:03:28.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40518,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (361, 615, 4, 2, '2022-06-13 19:03:28.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40519,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (361, 615, 5, 2, '2022-06-13 19:03:28.000000', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40520,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (361, 615, 6, 2, '2022-06-13 19:03:28.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40521,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (361, 615, 7, 2, '2022-06-13 19:03:28.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40522,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (361, 615, 9, 2, '2022-06-13 19:03:28.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40523,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (416, 615, 14, 'S', ' ', 'N', 'N', 110, 60, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40524,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (362, 'CEBALLOS', 'ERICA', '45116219', '1990-10-30 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40525,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (616, '2022-06-14 10:59:10.000000', 362, 1, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40526,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (362, 362, 39, 3, '00', '2022-06-14 10:59:10.000000', '', 12)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40527,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, planificado, fum, fpp) VALUES (279, 362, 616, 0, 8, 7, 0, '2021-11-25 00:00:00.000000', '2022-09-01 00:00:00.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40528,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (362, 616, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40529,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (362, 616, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40530,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (362, 616, 1, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40531,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (362, 616, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40532,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (362, 616, 1, 3, '2022-06-14 11:02:03.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40533,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (362, 616, 2, 3, '2022-06-14 11:02:03.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40534,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (362, 616, 4, 3, '2022-06-14 11:02:03.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40535,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (362, 616, 5, 3, '2022-06-14 11:02:03.000000', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40536,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (362, 616, 6, 3, '2022-06-14 11:02:03.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40537,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (362, 616, 7, 3, '2022-06-14 11:02:03.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40538,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (362, 616, 9, 3, '2022-06-14 11:02:03.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40539,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (417, 616, 28, 'S', ' ', 'N', 'N', 80, 60, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40540,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (617, '2022-06-14 11:05:55.000000', 300068, 2, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40541,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (300068, 617, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40542,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (300068, 617, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40543,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (300068, 617, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40544,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (300068, 617, 1, 3, '2022-06-14 11:08:15.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40545,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (300068, 617, 2, 3, '2022-06-14 11:08:15.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40546,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (300068, 617, 6, 3, '2022-06-14 11:08:15.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40547,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (300068, 617, 7, 3, '2022-06-14 11:08:15.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40548,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (418, 617, 38, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40549,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (363, 'PEREZ', 'SELSA', '34356230', '1989-10-17 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40550,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (618, '2022-06-14 11:28:19.000000', 363, 1, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40551,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (363, 363, 39, 3, '0', '2022-06-14 11:28:19.000000', '', 12)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40552,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, planificado, fum, fpp) VALUES (280, 363, 618, 0, 8, 7, 0, '2022-02-07 00:00:00.000000', '2022-11-14 00:00:00.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40553,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (363, 618, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40554,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (363, 618, 3, 'C')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40555,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (363, 618, 1, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40556,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (363, 618, 4, 'C')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40557,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (363, 618, 1, 2, '2022-06-14 11:45:48.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40558,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (363, 618, 2, 2, '2022-06-14 11:45:48.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40559,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (363, 618, 4, 2, '2022-06-14 11:45:48.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40560,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (363, 618, 5, 2, '2022-06-14 11:45:48.000000', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40561,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (363, 618, 6, 2, '2022-06-14 11:45:48.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40562,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (363, 618, 7, 2, '2022-06-14 11:45:48.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40563,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (363, 618, 9, 2, '2022-06-14 11:45:48.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40564,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (419, 618, 18, 'S', ' ', 'N', 'N', 100, 60, 'N', 'NO PERMITE QUE LE REALICEMOS LA EXTRACCIN DE SANGRE PARA ANALISIS DE LABORATORIO')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40565,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (364, 'FRIAS', 'ELVIRA', '42752395', '2002-10-17 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40566,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (619, '2022-06-14 12:23:07.000000', 364, 1, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40567,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (364, 364, 39, 3, '00', '2022-06-14 12:23:07.000000', '', 12)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40568,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, planificado, fum, fpp) VALUES (281, 364, 619, 0, 2, 1, 1, '2022-01-13 00:00:00.000000', '2022-10-20 00:00:00.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40569,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (364, 619, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40570,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (364, 619, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40571,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (364, 619, 1, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40572,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (364, 619, 4, 'C')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40573,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (364, 619, 1, 2, '2022-06-14 12:27:39.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40574,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (364, 619, 2, 2, '2022-06-14 12:27:39.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40575,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (364, 619, 4, 2, '2022-06-14 12:27:39.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40576,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (364, 619, 5, 2, '2022-06-14 12:27:39.000000', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40577,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (364, 619, 6, 2, '2022-06-14 12:27:39.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40578,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (364, 619, 7, 2, '2022-06-14 12:27:39.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40579,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (364, 619, 9, 2, '2022-06-14 12:27:39.000000', '2022-06-14 12:27:39.000000', '0+')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40580,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (420, 619, 21, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40581,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (365, 'PEREZ', 'MARILU', '43452625', '2021-09-30 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40582,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (620, '2022-06-14 13:30:31.000000', 365, 1, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40583,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (365, 365, 39, 3, '0', '2022-06-14 13:30:31.000000', '', 12)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40584,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, planificado, fum, fpp) VALUES (282, 365, 620, 0, 3, 2, 0, '2022-01-12 00:00:00.000000', '2022-10-19 00:00:00.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40585,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (365, 620, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40586,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (365, 620, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40587,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (365, 620, 1, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40588,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (365, 620, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40589,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (365, 620, 1, 2, '2022-06-14 13:36:47.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40590,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (365, 620, 6, 2, '2022-06-14 13:36:47.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40591,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (365, 620, 7, 2, '2022-06-14 13:36:47.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40592,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (421, 620, 21, 'S', ' ', 'N', 'N', 90, 60, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40593,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (621, '2022-06-14 15:19:09.000000', 230, 4, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40594,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (230, 621, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40595,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (230, 621, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40596,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (230, 621, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40597,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (230, 621, 1, 3, '2022-06-14 15:22:33.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40598,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (230, 621, 2, 3, '2022-06-14 15:22:33.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40599,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (230, 621, 7, 3, '2022-06-14 15:22:33.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40600,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (422, 621, 38, 'S', ' ', 'N', 'N', 100, 80, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40601,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (622, '2022-06-14 15:59:21.000000', 265, 2, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40602,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (265, 622, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40603,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (265, 622, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40604,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (265, 622, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40605,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (265, 622, 1, 3, '2022-06-14 16:01:27.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40606,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (265, 622, 2, 3, '2022-06-14 16:01:27.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40607,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (265, 622, 6, 3, '2022-06-14 16:01:27.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40608,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (265, 622, 7, 3, '2022-06-14 16:01:27.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40609,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (423, 622, 31, 'S', ' ', 'N', 'N', 90, 50, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40610,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (366, 'TORRES', 'JULIA', '46054629', '2005-03-31 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40611,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (623, '2022-06-14 16:04:27.000000', 366, 1, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40612,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (366, 366, 29, 3, '00', '2022-06-14 16:04:27.000000', '', 12)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40613,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (283, 366, 623, 17, 1, 0, '2022-04-05 00:00:00.000000', '2023-01-10 00:00:00.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40614,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (366, 623, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40615,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (366, 623, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40616,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (366, 623, 1, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40617,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (366, 623, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40618,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (366, 623, 1, 1, '2022-06-14 16:19:45.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40619,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (366, 623, 2, 1, '2022-06-14 16:19:45.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40620,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (366, 623, 4, 1, '2022-06-14 16:19:45.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40621,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (366, 623, 5, 1, '2022-06-14 16:19:45.000000', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40622,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (366, 623, 6, 1, '2022-06-14 16:19:45.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40623,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (366, 623, 7, 1, '2022-06-14 16:19:45.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40624,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (366, 623, 9, 1, '2022-06-14 16:19:45.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40625,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (424, 623, 10, 'R', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40626,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (367, 'ROMAN', 'ANTONIETA', '38651022', '1994-12-24 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40627,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (624, '2022-06-14 16:25:28.000000', 367, 1, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40628,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (367, 367, 29, 3, '00', '2022-06-14 16:25:28.000000', '', 12)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40629,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, planificado, fum, fpp) VALUES (284, 367, 624, 0, 2, 1, 1, '2021-10-29 00:00:00.000000', '2022-08-05 00:00:00.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40630,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (367, 624, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40631,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (367, 624, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40632,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (367, 624, 1, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40633,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (367, 624, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40634,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (367, 624, 1, 3, '2022-06-14 16:44:00.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40635,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (367, 624, 2, 3, '2022-06-14 16:44:00.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40636,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (367, 624, 4, 3, '2022-06-14 16:44:00.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40637,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (367, 624, 5, 3, '2022-06-14 16:44:00.000000', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40638,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (367, 624, 6, 3, '2022-06-14 16:44:00.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40639,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (367, 624, 7, 3, '2022-06-14 16:44:00.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40640,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (367, 624, 9, 3, '2022-06-14 16:44:00.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40641,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (425, 624, 32, 'S', ' ', 'N', 'N', 110, 70, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40642,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (368, 'TORRES', 'ANA ELENA', '31393849', '1985-02-08 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40643,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (625, '2022-06-14 17:15:15.000000', 368, 1, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40644,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (368, 368, 28, 3, '00', '2022-06-14 17:15:15.000000', '', 12)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40645,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, planificado, fum, fpp) VALUES (285, 368, 625, 0, 5, 4, 0, '2022-01-25 00:00:00.000000', '2022-11-01 00:00:00.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40646,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (368, 625, 2, 'N')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40647,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (368, 625, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40648,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (368, 625, 1, 'N')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40649,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (368, 625, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40650,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (368, 625, 1, 2, '2022-06-14 17:19:27.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40651,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (368, 625, 2, 2, '2022-06-14 17:19:27.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40652,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (368, 625, 4, 2, '2022-06-14 17:19:27.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40653,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (368, 625, 5, 2, '2022-06-14 17:19:27.000000', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40654,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (368, 625, 6, 2, '2022-06-14 17:19:27.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40655,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (368, 625, 7, 2, '2022-06-14 17:19:27.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40656,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (368, 625, 9, 2, '2022-06-14 17:19:27.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40657,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (426, 625, 20, 'S', ' ', 'N', 'N', 110, 80, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40658,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (369, 'LOPEZ', 'EMILCE', '36380036', '1991-06-28 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40659,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (626, '2022-06-14 17:22:07.000000', 369, 1, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40660,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (369, 369, 28, 3, '00', '2022-06-14 17:22:07.000000', '', 12)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40661,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, planificado, fum, fpp) VALUES (286, 369, 626, 0, 5, 4, 0, '2022-03-02 00:00:00.000000', '2022-12-07 00:00:00.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40662,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (369, 626, 2, 'N')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40663,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (369, 626, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40664,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (369, 626, 1, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40665,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (369, 626, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40666,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (369, 626, 1, 2, '2022-06-14 17:26:06.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40667,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (369, 626, 2, 2, '2022-06-14 17:26:06.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40668,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (369, 626, 4, 2, '2022-06-14 17:26:06.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40669,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (369, 626, 5, 2, '2022-06-14 17:26:06.000000', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40670,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (369, 626, 6, 2, '2022-06-14 17:26:06.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40671,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (369, 626, 7, 2, '2022-06-14 17:26:06.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40672,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (369, 626, 9, 2, '2022-06-14 17:26:06.000000', '2022-06-14 17:26:06.000000', '0+')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40673,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (427, 626, 14, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40674,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (627, '2022-06-15 09:37:19.000000', 119, 3, 2, '2022-02-10 00:00:00.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40675,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (81, 627)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40676,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=119",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40677,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (628, '2022-06-15 09:39:10.000000', 120, 3, 2, '2022-03-12 00:00:00.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40678,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (82, 628)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40679,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=120",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40680,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (629, '2022-06-15 09:39:57.000000', 100037, 4, 2, '2022-03-18 00:00:00.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40681,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (83, 629)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40682,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=100037",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40683,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100147",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40684,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (630, '2022-06-15 09:42:47.000000', 100131, 3, 2, '2022-03-18 00:00:00.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40685,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (84, 630)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40686,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=100131",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40687,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (631, '2022-06-15 09:43:38.000000', 100171, 2, 2, '2022-03-18 00:00:00.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40688,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (85, 631)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40689,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=100171",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40690,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (632, '2022-06-15 09:45:37.000000', 200049, 2, 2, '2022-03-08 00:00:00.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40691,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (86, 632)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40692,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=200049",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40693,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (633, '2022-06-15 09:46:21.000000', 200051, 2, 2, '2022-03-18 00:00:00.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40694,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (87, 633)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40695,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=200051",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40696,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (634, '2022-06-15 09:47:08.000000', 300032, 4, 2, '2022-03-08 00:00:00.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40697,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (88, 634)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40698,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=300032",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40699,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (635, '2022-06-15 09:47:56.000000', 300035, 2, 2, '2022-03-08 00:00:00.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40700,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (89, 635)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40701,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=300035",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40702,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (636, '2022-06-15 09:57:42.000000', 100130, 3, 2, '2022-04-07 00:00:00.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40703,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, nacionalidad, sexo, madre, nacido_vivo) VALUES (370, 'DOMINGUEZ', 'MOISES', 'XX', '2022-04-07 00:00:00.000000', 12, 'M', 100130, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40704,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (370, 370, 28, 3, '0', '2022-06-15 09:57:42.000000', '', 12)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40705,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (637, '2022-06-15 09:57:42.000000', 370, 1, 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40706,
                    "INSERT INTO control_rn (id_control_rn, id_control, edad_gestacional, peso, bajo_peso) VALUES (54, 637, 39, 3330.0, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40707,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (90, 636)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40708,
                    "SELECT COUNT(*) FROM etmis_personas WHERE etmis_personas.confirmada=1 AND etmis_personas.id_persona=100130",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40709,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (371, 'PEREZ', 'ELIANA', '48780711', '2008-04-18 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40710,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (638, '2022-06-15 11:06:14.000000', 371, 1, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40711,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (371, 371, 28, 3, '00', '2022-06-15 11:06:14.000000', '', 12)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40712,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (287, 371, 638, 14, 1, 1, '2022-12-29 00:00:00.000000', '2023-10-05 00:00:00.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40713,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (371, 638, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40714,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (371, 638, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40715,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (371, 638, 1, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40716,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (371, 638, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40717,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (371, 638, 1, 3, '2022-06-15 11:11:03.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40718,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (371, 638, 2, 3, '2022-06-15 11:11:03.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40719,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (371, 638, 4, 3, '2022-06-15 11:11:03.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40720,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (371, 638, 5, 3, '2022-06-15 11:11:03.000000', '2022-06-15 11:11:03.000000', 'N', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40721,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (371, 638, 6, 3, '2022-06-15 11:11:03.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40722,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (371, 638, 7, 3, '2022-06-15 11:11:03.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40723,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (371, 638, 9, 3, '2022-06-15 11:11:03.000000', '2022-06-15 11:11:03.000000', '0+')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40724,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (428, 638, 28, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40725,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (639, '2022-06-15 11:12:44.000000', 100174, 2, 2, '2022-06-10 00:00:00.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40726,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (91, 639)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40727,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=100174",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40728,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (640, '2022-06-15 11:15:21.000000', 100173, 2, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40729,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100173, 640, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40730,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100173, 640, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40731,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100173, 640, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40732,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100173, 640, 1, 3, '2022-06-15 11:24:45.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40733,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100173, 640, 2, 3, '2022-06-15 11:24:45.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40734,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100173, 640, 6, 3, '2022-06-15 11:24:45.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40735,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100173, 640, 7, 3, '2022-06-15 11:24:45.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40736,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (429, 640, 36, 'S', ' ', 'N', 'N', 100, 70, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40737,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100260",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40738,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-15', resultado='N' WHERE laboratorios_realizados.id_persona=100179 AND laboratorios_realizados.id_control=100309 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40739,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-15', resultado='N' WHERE laboratorios_realizados.id_persona=100179 AND laboratorios_realizados.id_control=100309 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40740,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-15', resultado='N' WHERE laboratorios_realizados.id_persona=100179 AND laboratorios_realizados.id_control=100309 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40741,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-15', resultado='N' WHERE laboratorios_realizados.id_persona=100179 AND laboratorios_realizados.id_control=100309 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40742,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-15', resultado='-' WHERE laboratorios_realizados.id_persona=100179 AND laboratorios_realizados.id_control=100309 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40743,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-15', resultado='0+' WHERE laboratorios_realizados.id_persona=100179 AND laboratorios_realizados.id_control=100309 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40744,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (641, '2022-06-15 12:10:10.000000', 100179, 2, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40745,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100179, 641, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40746,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100179, 641, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40747,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100179, 641, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40748,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100179, 641, 1, 3, '2022-06-15 12:15:52.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40749,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100179, 641, 2, 3, '2022-06-15 12:15:52.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40750,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (430, 641, 35, 'S', ' ', 'N', 'N', 90, 60, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40751,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (372, 'GARCIA', 'BEATRIZ', '58083991', '1994-07-21 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40752,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (642, '2022-06-15 12:19:50.000000', 372, 1, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40753,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (372, 372, 43, 3, '00', '2022-06-15 12:19:50.000000', '', 12)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40754,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, abortos, planificado, fum, fpp) VALUES (288, 372, 642, 0, 5, 3, 1, 1, '2021-12-29 00:00:00.000000', '2022-10-05 00:00:00.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40755,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (372, 642, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40756,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (372, 642, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40757,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (372, 642, 1, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40758,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (372, 642, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40759,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (372, 642, 1, 2, '2022-06-15 12:26:51.000000', '2022-06-15 12:26:51.000000', 'N', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40760,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (372, 642, 2, 2, '2022-06-15 12:26:51.000000', '2022-06-15 12:26:51.000000', 'N', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40761,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (372, 642, 4, 2, '2022-06-15 12:26:51.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40762,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (372, 642, 5, 2, '2022-06-15 12:26:51.000000', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40763,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (372, 642, 6, 2, '2022-06-15 12:26:51.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40764,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (372, 642, 7, 2, '2022-06-15 12:26:51.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40765,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (372, 642, 9, 2, '2022-06-15 12:26:51.000000', '2022-06-15 12:26:51.000000', '0+')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40766,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (431, 642, 24, 'S', ' ', 'N', 'N', 120, 80, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40767,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (373, 'SANCHEZ', 'BELIA MELCHORA', '36306441', '1991-12-06 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40768,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (643, '2022-06-15 12:32:45.000000', 373, 1, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40769,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (373, 373, 43, 3, '00', '2022-06-15 12:32:45.000000', '', 12)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40770,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, cesareas, planificado, fum, fpp) VALUES (289, 373, 643, 0, 2, 1, 1, '2022-03-24 00:00:00.000000', '2022-12-29 00:00:00.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40771,
                    "INSERT INTO antecedentes_apps (id_antecedente, id_app) VALUES (289, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40772,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (289, 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40773,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (373, 643, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40774,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (373, 643, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40775,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (373, 643, 1, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40776,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (373, 643, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40777,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (373, 643, 1, 1, '2022-06-15 12:47:11.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40778,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (373, 643, 2, 1, '2022-06-15 12:47:11.000000', '2022-06-15 12:47:11.000000', 'N', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40779,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (373, 643, 4, 1, '2022-06-15 12:47:11.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40780,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (373, 643, 5, 1, '2022-06-15 12:47:11.000000', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40781,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (373, 643, 6, 1, '2022-06-15 12:47:11.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40782,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (373, 643, 9, 1, '2022-06-15 12:47:11.000000', '2022-06-15 12:47:11.000000', '0+')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40783,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (432, 643, 12, 'P', 'GEMELAR 12,5 SEMANAS  BI AMNIOTICO MONOCORIAL, PLACENTA BJA ANTERIOR LIQUIDO NORMAL', 'N', 'N', 120, 70, 'N', 'EMBARAZO GEMELAR')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40784,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100261",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40785,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-15', resultado='N' WHERE laboratorios_realizados.id_persona=100180 AND laboratorios_realizados.id_control=100310 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40786,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-15', resultado='N' WHERE laboratorios_realizados.id_persona=100180 AND laboratorios_realizados.id_control=100310 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40787,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-15', resultado='N' WHERE laboratorios_realizados.id_persona=100180 AND laboratorios_realizados.id_control=100310 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40788,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-15', resultado='N' WHERE laboratorios_realizados.id_persona=100180 AND laboratorios_realizados.id_control=100310 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40789,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-15', resultado='-' WHERE laboratorios_realizados.id_persona=100180 AND laboratorios_realizados.id_control=100310 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40790,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-15', resultado='0+' WHERE laboratorios_realizados.id_persona=100180 AND laboratorios_realizados.id_control=100310 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40791,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (644, '2022-06-15 12:49:21.000000', 100180, 2, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40792,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100180, 644, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40793,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100180, 644, 3, 'C')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40794,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100180, 644, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40795,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100180, 644, 1, 3, '2022-06-15 12:52:43.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40796,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100180, 644, 2, 3, '2022-06-15 12:52:43.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40797,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100180, 644, 6, 3, '2022-06-15 12:52:43.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40798,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (100180, 644, 9, 3, '2022-06-15 12:52:43.000000', '2022-06-15 12:52:43.000000', '0+')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40799,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (433, 644, 37, 'S', ' ', 'N', 'N', 110, 70, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40800,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (645, '2022-06-15 16:47:46.000000', 276, 2, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40801,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (276, 645, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40802,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (276, 645, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40803,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (276, 645, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40804,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (276, 645, 1, 3, '2022-06-15 16:52:29.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40805,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (434, 645, 28, 'S', ' ', 'N', 'N', 90, 60, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40806,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (374, 'ARIAS', 'ERIKA NOELIA', '43438801', '2001-05-05 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40807,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (646, '2022-06-15 16:56:46.000000', 374, 1, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40808,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (374, 374, 31, 3, '00', '2022-06-15 16:56:46.000000', '', 12)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40809,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (290, 374, 646, 21, 1, 1, '2021-12-16 00:00:00.000000', '2022-09-22 00:00:00.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40810,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (374, 646, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40811,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (374, 646, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40812,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (374, 646, 1, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40813,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (374, 646, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40814,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (374, 646, 1, 2, '2022-06-15 17:00:07.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40815,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (374, 646, 2, 2, '2022-06-15 17:00:07.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40816,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (374, 646, 4, 2, '2022-06-15 17:00:07.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40817,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (374, 646, 5, 2, '2022-06-15 17:00:07.000000', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40818,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (374, 646, 6, 2, '2022-06-15 17:00:07.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40819,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (374, 646, 7, 2, '2022-06-15 17:00:07.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40820,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (374, 646, 9, 2, '2022-06-15 17:00:07.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40821,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (435, 646, 25, 'S', ' ', 'N', 'N', 80, 50, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40822,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (375, 'JUAREZ', 'JANETH ESTEFANIA', '43438801', '1999-11-08 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40823,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (647, '2022-06-15 17:02:12.000000', 375, 1, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40824,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (375, 375, 31, 3, '1', '2022-06-15 17:02:12.000000', '', 12)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40825,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, planificado, fum, fpp) VALUES (291, 375, 647, 0, 3, 2, 0, '2022-04-14 00:00:00.000000', '2023-01-19 00:00:00.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40826,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (375, 647, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40827,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (375, 647, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40828,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (375, 647, 1, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40829,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (375, 647, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40830,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (375, 647, 1, 1, '2022-06-15 17:04:50.000000', '2022-06-15 17:04:50.000000', 'N', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40831,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (375, 647, 2, 1, '2022-06-15 17:04:50.000000', '2022-06-15 17:04:50.000000', 'N', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40832,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (375, 647, 4, 1, '2022-06-15 17:04:50.000000', '2022-06-15 17:04:50.000000', 'N', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40833,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (375, 647, 5, 1, '2022-06-15 17:04:50.000000', '2022-06-15 17:04:50.000000', 'N', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40834,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (375, 647, 6, 1, '2022-06-15 17:04:50.000000', '2022-06-15 17:04:50.000000', '12')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40835,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (375, 647, 7, 1, '2022-06-15 17:04:50.000000', '2022-06-15 17:04:50.000000', '75')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40836,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (375, 647, 9, 1, '2022-06-15 17:04:50.000000', '2022-06-15 17:04:50.000000', '0+')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40837,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (436, 647, 8, 'R', ' ', 'N', 'N', 120, 80, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40838,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (376, 'SANCHEZ', 'JUANA', '45262522', '1998-09-23 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40839,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (648, '2022-06-15 17:06:01.000000', 376, 1, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40840,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (376, 376, 31, 3, '0', '2022-06-15 17:06:01.000000', '', 12)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40841,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, planificado, fum, fpp) VALUES (292, 376, 648, 0, 2, 1, 0, '2021-11-28 00:00:00.000000', '2022-09-04 00:00:00.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40842,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (376, 648, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40843,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (376, 648, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40844,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (376, 648, 1, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40845,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (376, 648, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40846,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (376, 648, 1, 3, '2022-06-15 17:09:00.000000', '2022-06-15 17:09:00.000000', 'N', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40847,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (376, 648, 2, 3, '2022-06-15 17:09:00.000000', '2022-06-15 17:09:00.000000', 'N', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40848,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (376, 648, 4, 3, '2022-06-15 17:09:00.000000', '2022-06-15 17:09:00.000000', 'N', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40849,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (376, 648, 5, 3, '2022-06-15 17:09:00.000000', '2022-06-15 17:09:00.000000', 'N', 4)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40850,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (376, 648, 6, 3, '2022-06-15 17:09:00.000000', '2022-06-15 17:09:00.000000', '12')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40851,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (376, 648, 7, 3, '2022-06-15 17:09:00.000000', '2022-06-15 17:09:00.000000', '66')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40852,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (376, 648, 9, 3, '2022-06-15 17:09:00.000000', '2022-06-15 17:09:00.000000', '0+')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40853,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (437, 648, 28, 'S', ' ', 'N', 'N', 110, 80, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40854,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (649, '2022-06-15 17:12:26.000000', 277, 2, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40855,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (277, 649, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40856,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (277, 649, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40857,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (277, 649, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40858,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (277, 649, 1, 3, '2022-06-15 17:18:22.000000', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40859,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (277, 649, 2, 3, '2022-06-15 17:18:22.000000', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40860,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (277, 649, 4, 3, '2022-06-15 17:18:22.000000', 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40861,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (438, 649, 28, 'S', ' ', 'N', 'N', 100, 80, 'N', '')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40862,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (377, 'BARBIER', 'FABIOLA', '36136251', '1991-02-27 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40863,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (650, '2022-06-15 17:39:12.000000', 377, 1, 1)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40864,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (377, 377, 31, 3, '00', '2022-06-15 17:39:12.000000', '', 12)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40865,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, planificado, fum, fpp) VALUES (293, 377, 650, 0, 7, 6, 0, '2021-12-05 00:00:00.000000', '2022-09-11 00:00:00.000000')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40866,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (377, 650, 2, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40867,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (377, 650, 3, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40868,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (377, 650, 1, 'S')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40869,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (377, 650, 4, 'P')",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40870,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (377, 650, 1, 2, '2022-06-15 17:44:47.000000', '2022-06-15 17:44:47.000000', 'N', 3)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40871,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (377, 650, 2, 2, '2022-06-15 17:44:47.000000', '2022-06-15 17:44:47.000000', 'N', 2)",
                    "2022-06-22 10:33:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40872,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (377, 650, 4, 2, '2022-06-15 17:44:47.000000', '2022-06-15 17:44:47.000000', 'N', 1)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40873,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (377, 650, 5, 2, '2022-06-15 17:44:47.000000', '2022-06-15 17:44:47.000000', 'N', 4)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40874,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (377, 650, 6, 2, '2022-06-15 17:44:47.000000', '2022-06-15 17:44:47.000000', '10')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40875,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (377, 650, 7, 2, '2022-06-15 17:44:47.000000', '2022-06-15 17:44:47.000000', '60')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40876,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (377, 650, 9, 2, '2022-06-15 17:44:47.000000', '2022-06-15 17:44:47.000000', '0+')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40877,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (439, 650, 27, 'R', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40878,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (378, 'PALMA', 'LAURA', '44017556', '2008-02-02 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40879,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (651, '2022-06-15 17:48:06.000000', 378, 1, 1)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40880,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (378, 378, 31, 3, '00', '2022-06-15 17:48:06.000000', '', 12)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40881,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (294, 378, 651, 0, 1, 0, '2022-02-26 00:00:00.000000', '2022-12-03 00:00:00.000000')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40882,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (378, 651, 2, 'S')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40883,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (378, 651, 3, 'P')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40884,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (378, 651, 1, 'S')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40885,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (378, 651, 4, 'P')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40886,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (378, 651, 1, 2, '2022-06-15 17:51:20.000000', '2022-06-15 17:51:20.000000', 'N', 3)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40887,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (378, 651, 2, 2, '2022-06-15 17:51:20.000000', '2022-06-15 17:51:20.000000', 'N', 2)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40888,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (378, 651, 4, 2, '2022-06-15 17:51:20.000000', '2022-06-15 17:51:20.000000', 'N', 1)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40889,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (378, 651, 5, 2, '2022-06-15 17:51:20.000000', 4)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40890,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (378, 651, 6, 2, '2022-06-15 17:51:20.000000', '2022-06-15 17:51:20.000000', '11')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40891,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (378, 651, 9, 2, '2022-06-15 17:51:20.000000', '2022-06-15 17:51:20.000000', '0+')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40892,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (440, 651, 15, 'S', ' ', 'N', 'N', 110, 60, 'N', '')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40893,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (379, 'TORRES', 'ROMINA', '44660475', '2003-01-28 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40894,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (652, '2022-06-15 17:52:53.000000', 379, 1, 1)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40895,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (379, 379, 31, 3, '00', '2022-06-15 17:52:53.000000', '', 12)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40896,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, cesareas, planificado, fum, fpp) VALUES (295, 379, 652, 0, '2016-01-29 00:00:00.000000', 2, 1, 1, '2022-03-05 00:00:00.000000', '2022-12-10 00:00:00.000000')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40897,
                    "INSERT INTO antecedentes_apps (id_antecedente, id_app) VALUES (295, 1)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40898,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (379, 652, 2, 'S')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40899,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (379, 652, 3, 'P')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40900,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (379, 652, 1, 'S')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40901,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (379, 652, 4, 'P')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40902,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (379, 652, 1, 2, '2022-06-15 17:55:36.000000', 3)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40903,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (379, 652, 2, 2, '2022-06-15 17:55:36.000000', 2)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40904,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (379, 652, 4, 2, '2022-06-15 17:55:36.000000', 1)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40905,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (379, 652, 5, 2, '2022-06-15 17:55:36.000000', 4)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40906,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (379, 652, 6, 2, '2022-06-15 17:55:36.000000')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40907,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (379, 652, 7, 2, '2022-06-15 17:55:36.000000')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40908,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (379, 652, 9, 2, '2022-06-15 17:55:36.000000')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40909,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (441, 652, 14, 'S', ' ', 'N', 'N', 80, 60, 'N', '')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40910,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (380, 'VELAZCO', 'ROXANA BEATRIZ', '45439841', '2001-05-10 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40911,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (653, '2022-06-15 17:57:08.000000', 380, 1, 1)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40912,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (380, 380, 31, 3, '00', '2022-06-15 17:57:08.000000', '', 12)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40913,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (296, 380, 653, 20, 1, 1, '2022-02-16 00:00:00.000000', '2022-11-23 00:00:00.000000')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40914,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (296, 1)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40915,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (380, 653, 2, 'S')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40916,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (380, 653, 3, 'P')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40917,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (380, 653, 1, 'S')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40918,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (380, 653, 4, 'P')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40919,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (380, 653, 1, 2, '2022-06-15 18:01:47.000000', '2022-06-15 18:01:47.000000', 'N', 3)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40920,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (380, 653, 2, 2, '2022-06-15 18:01:47.000000', '2022-06-15 18:01:47.000000', 'N', 2)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40921,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (380, 653, 4, 2, '2022-06-15 18:01:47.000000', '2022-06-15 18:01:47.000000', 'N', 1)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40922,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (380, 653, 5, 2, '2022-06-15 18:01:47.000000', '2022-06-15 18:01:47.000000', 'N', 4)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40923,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (380, 653, 6, 2, '2022-06-15 18:01:47.000000', '2022-06-15 18:01:47.000000', '11')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40924,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (380, 653, 7, 2, '2022-06-15 18:01:47.000000', '2022-06-15 18:01:47.000000', '89')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40925,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (380, 653, 9, 2, '2022-06-15 18:01:47.000000', '2022-06-15 18:01:47.000000', '0+')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40926,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (442, 653, 17, 'S', ' ', 'N', 'N', 90, 60, 'N', '')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40927,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (381, 'NEGRO', 'MALVINA', '38651098', '1993-06-11 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40928,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (654, '2022-06-16 09:52:50.000000', 381, 1, 1)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40929,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (381, 381, 41, 3, '00', '2022-06-16 09:52:50.000000', '', 12)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40930,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (297, 381, 654, 0, '2018-06-25 00:00:00.000000', 6, 5, 0, '2022-09-19 00:00:00.000000', '2023-06-26 00:00:00.000000')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40931,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (381, 654, 2, 'S')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40932,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (381, 654, 3, 'C')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40933,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (381, 654, 1, 'S')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40934,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (381, 654, 4, 'P')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40935,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (381, 654, 1, 3, '2022-06-16 09:56:27.000000', '2022-06-16 09:56:27.000000', 'N', 3)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40936,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (381, 654, 2, 3, '2022-06-16 09:56:27.000000', '2022-06-16 09:56:27.000000', 'N', 2)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40937,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (381, 654, 4, 3, '2022-06-16 09:56:27.000000', '2022-06-16 09:56:27.000000', 'N', 1)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40938,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (381, 654, 5, 3, '2022-06-16 09:56:27.000000', '2022-06-16 09:56:27.000000', 'N', 4)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40939,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (381, 654, 6, 3, '2022-06-16 09:56:27.000000')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40940,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (381, 654, 7, 3, '2022-06-16 09:56:27.000000')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40941,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (381, 654, 9, 3, '2022-06-16 09:56:27.000000')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40942,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (443, 654, 38, 'S', ' ', 'N', 'N', 110, 60, 'N', '')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40943,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (382, 'LORENZO', 'MARILIN', '40354880', '1996-08-25 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40944,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (655, '2022-06-16 10:19:28.000000', 382, 1, 1)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40945,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (382, 382, 41, 3, '00', '2022-06-16 10:19:28.000000', '', 12)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40946,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (383, 'FRIAS', 'NADIA', '44660485', '2003-01-10 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40947,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (656, '2022-06-16 10:22:11.000000', 383, 1, 1)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40948,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (383, 383, 41, 3, '00', '2022-06-16 10:22:11.000000', '', 12)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40949,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (298, 383, 656, 16, '2019-03-03 00:00:00.000000', 2, 1, 0, '2022-01-27 00:00:00.000000', '2022-11-03 00:00:00.000000')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40950,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (383, 656, 2, 'S')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40951,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (383, 656, 3, 'P')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40952,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (383, 656, 1, 'S')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40953,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (383, 656, 4, 'P')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40954,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (383, 656, 1, 2, '2022-06-16 10:28:13.000000', 3)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40955,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (383, 656, 2, 2, '2022-06-16 10:28:13.000000', 2)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40956,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (383, 656, 4, 2, '2022-06-16 10:28:13.000000', 1)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40957,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (383, 656, 5, 2, '2022-06-16 10:28:13.000000', 4)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40958,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (383, 656, 6, 2, '2022-06-16 10:28:13.000000')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40959,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (383, 656, 7, 2, '2022-06-16 10:28:13.000000')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40960,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (383, 656, 9, 2, '2022-06-16 10:28:13.000000')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40961,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (444, 656, 20, 'S', ' ', 'N', 'N', 130, 70, 'N', '')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40962,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (299, 382, 655, 15, '2020-07-12 00:00:00.000000', 5, 4, 0, '2021-11-25 00:00:00.000000', '2022-09-01 00:00:00.000000')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40963,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (382, 655, 2, 'S')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40964,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (382, 655, 3, 'P')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40965,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (382, 655, 1, 'S')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40966,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (382, 655, 4, 'P')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40967,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (382, 655, 1, 3, '2022-06-16 10:35:43.000000', 3)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40968,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (382, 655, 2, 3, '2022-06-16 10:35:43.000000', 2)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40969,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (382, 655, 4, 3, '2022-06-16 10:35:43.000000', 1)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40970,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (382, 655, 5, 3, '2022-06-16 10:35:43.000000', 4)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40971,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (382, 655, 6, 3, '2022-06-16 10:35:43.000000')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40972,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (382, 655, 7, 3, '2022-06-16 10:35:43.000000')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40973,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (382, 655, 9, 3, '2022-06-16 10:35:43.000000')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40974,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (445, 655, 29, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40975,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (384, 'MARTIN', 'CARMEN', '33668430', '1987-11-30 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40976,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (657, '2022-06-16 12:24:48.000000', 384, 1, 1)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40977,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (384, 384, 13, 4, '00', '2022-06-16 12:24:48.000000', '', 12)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40978,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (300, 384, 657, 0, '2020-03-14 00:00:00.000000', 7, 6, 0, '2021-12-23 00:00:00.000000', '2022-09-29 00:00:00.000000')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40979,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (384, 657, 2, 'S')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40980,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (384, 657, 3, 'P')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40981,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (384, 657, 1, 'S')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40982,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (384, 657, 4, 'P')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40983,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (384, 657, 1, 2, '2022-06-16 12:26:41.000000', 3)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40984,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (384, 657, 2, 2, '2022-06-16 12:26:41.000000', 2)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40985,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (384, 657, 4, 2, '2022-06-16 12:26:41.000000', 1)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40986,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (384, 657, 5, 2, '2022-06-16 12:26:41.000000', 4)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40987,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (384, 657, 6, 2, '2022-06-16 12:26:41.000000')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40988,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (384, 657, 7, 2, '2022-06-16 12:26:41.000000')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40989,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (384, 657, 9, 2, '2022-06-16 12:26:41.000000')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40990,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (446, 657, 25, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40991,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (385, 'FERNANDEZ', 'YUDITH BEATRIZ', '42752355', '2002-02-19 00:00:00.000000', 1, 12, 'F')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40992,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (658, '2022-06-16 18:50:45.000000', 385, 1, 1)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40993,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (385, 385, 7, 4, '00', '2022-06-16 18:50:45.000000', '', 12)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40994,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, planificado, fum, fpp) VALUES (301, 385, 658, 20, '2030-10-31 00:00:00.000000', 1, 1, '2021-12-25 00:00:00.000000', '2022-10-01 00:00:00.000000')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40995,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (301, 1)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40996,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (385, 658, 2, 'N')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40997,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (385, 658, 3, 'C')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40998,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (385, 658, 1, 'N')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    40999,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (385, 658, 4, 'N')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41000,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (385, 658, 1, 2, '2022-06-16 18:56:09.000000', 3)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41001,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (385, 658, 2, 2, '2022-06-16 18:56:09.000000', 2)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41002,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (385, 658, 4, 2, '2022-06-16 18:56:09.000000', 1)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41003,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (385, 658, 5, 2, '2022-06-16 18:56:09.000000', 4)",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41004,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (385, 658, 6, 2, '2022-06-16 18:56:09.000000')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41005,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (385, 658, 7, 2, '2022-06-16 18:56:09.000000')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41006,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (385, 658, 9, 2, '2022-06-16 18:56:09.000000')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41007,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (447, 658, 24, 'S', ' ', 'N', 'N', 90, 50, 'N', '')",
                    "2022-06-22 10:33:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41008,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=388",
                    "2022-06-22 18:48:38",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41009,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='N' WHERE laboratorios_realizados.id_persona=248 AND laboratorios_realizados.id_control=586 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-22 18:48:38",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41010,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='103' WHERE laboratorios_realizados.id_persona=248 AND laboratorios_realizados.id_control=586 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-22 18:48:38",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41011,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='79' WHERE laboratorios_realizados.id_persona=248 AND laboratorios_realizados.id_control=586 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-22 18:48:38",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41012,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=394",
                    "2022-06-24 23:30:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41013,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='N' WHERE laboratorios_realizados.id_persona=154 AND laboratorios_realizados.id_control=592 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-24 23:30:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41014,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='12.3' WHERE laboratorios_realizados.id_persona=154 AND laboratorios_realizados.id_control=592 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:30:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41015,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='103' WHERE laboratorios_realizados.id_persona=154 AND laboratorios_realizados.id_control=592 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:30:24",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41016,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=389",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41017,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='N' WHERE laboratorios_realizados.id_persona=344 AND laboratorios_realizados.id_control=588 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41018,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='N' WHERE laboratorios_realizados.id_persona=344 AND laboratorios_realizados.id_control=588 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41019,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='N' WHERE laboratorios_realizados.id_persona=344 AND laboratorios_realizados.id_control=588 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41020,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='N' WHERE laboratorios_realizados.id_persona=344 AND laboratorios_realizados.id_control=588 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41021,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='10.6' WHERE laboratorios_realizados.id_persona=344 AND laboratorios_realizados.id_control=588 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41022,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='79' WHERE laboratorios_realizados.id_persona=344 AND laboratorios_realizados.id_control=588 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41023,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=390",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41024,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='N' WHERE laboratorios_realizados.id_persona=247 AND laboratorios_realizados.id_control=589 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41025,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='12' WHERE laboratorios_realizados.id_persona=247 AND laboratorios_realizados.id_control=589 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41026,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='110' WHERE laboratorios_realizados.id_persona=247 AND laboratorios_realizados.id_control=589 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41027,
                    "SELECT etmis_personas.id_persona, etmis_personas.id_etmi, etmis_personas.id_control, etmis_personas.confirmada FROM etmis_personas WHERE etmis_personas.id_etmi=1 AND etmis_personas.confirmada=0 AND etmis_personas.id_control='389' LIMIT 1",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41028,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='P' WHERE laboratorios_realizados.id_persona=247 AND laboratorios_realizados.id_control=389 AND laboratorios_realizados.id_laboratorio=19 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41029,
                    "UPDATE etmis_personas SET confirmada=1 WHERE etmis_personas.id_persona=247 AND etmis_personas.id_etmi=1 AND etmis_personas.id_control=389",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41030,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='P' WHERE laboratorios_realizados.id_persona=247 AND laboratorios_realizados.id_control=389 AND laboratorios_realizados.id_laboratorio=20 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41031,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='P' WHERE laboratorios_realizados.id_persona=247 AND laboratorios_realizados.id_control=389 AND laboratorios_realizados.id_laboratorio=23 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41032,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=391",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41033,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='N' WHERE laboratorios_realizados.id_persona=251 AND laboratorios_realizados.id_control=590 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41034,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='11.6' WHERE laboratorios_realizados.id_persona=251 AND laboratorios_realizados.id_control=590 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41035,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='115' WHERE laboratorios_realizados.id_persona=251 AND laboratorios_realizados.id_control=590 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41036,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='N' WHERE laboratorios_realizados.id_persona=345 AND laboratorios_realizados.id_control=591 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41037,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='N' WHERE laboratorios_realizados.id_persona=345 AND laboratorios_realizados.id_control=591 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41038,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='N' WHERE laboratorios_realizados.id_persona=345 AND laboratorios_realizados.id_control=591 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41039,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='N' WHERE laboratorios_realizados.id_persona=345 AND laboratorios_realizados.id_control=591 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41040,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='12.3' WHERE laboratorios_realizados.id_persona=345 AND laboratorios_realizados.id_control=591 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41041,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='80' WHERE laboratorios_realizados.id_persona=345 AND laboratorios_realizados.id_control=591 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41042,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='0+' WHERE laboratorios_realizados.id_persona=345 AND laboratorios_realizados.id_control=591 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41043,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=394",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41044,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='N' WHERE laboratorios_realizados.id_persona=154 AND laboratorios_realizados.id_control=592 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41045,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='12.3' WHERE laboratorios_realizados.id_persona=154 AND laboratorios_realizados.id_control=592 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41046,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='103' WHERE laboratorios_realizados.id_persona=154 AND laboratorios_realizados.id_control=592 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41047,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=397",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41048,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='N' WHERE laboratorios_realizados.id_persona=346 AND laboratorios_realizados.id_control=593 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41049,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='N' WHERE laboratorios_realizados.id_persona=346 AND laboratorios_realizados.id_control=593 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41050,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='N' WHERE laboratorios_realizados.id_persona=346 AND laboratorios_realizados.id_control=593 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41051,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='N' WHERE laboratorios_realizados.id_persona=346 AND laboratorios_realizados.id_control=593 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41052,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='11' WHERE laboratorios_realizados.id_persona=346 AND laboratorios_realizados.id_control=593 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41053,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='118' WHERE laboratorios_realizados.id_persona=346 AND laboratorios_realizados.id_control=593 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41054,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='0+' WHERE laboratorios_realizados.id_persona=346 AND laboratorios_realizados.id_control=593 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41055,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=396",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41056,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=395",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41057,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='N' WHERE laboratorios_realizados.id_persona=347 AND laboratorios_realizados.id_control=594 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41058,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='N' WHERE laboratorios_realizados.id_persona=347 AND laboratorios_realizados.id_control=594 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41059,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='N' WHERE laboratorios_realizados.id_persona=347 AND laboratorios_realizados.id_control=594 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41060,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='N' WHERE laboratorios_realizados.id_persona=347 AND laboratorios_realizados.id_control=594 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41061,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='12' WHERE laboratorios_realizados.id_persona=347 AND laboratorios_realizados.id_control=594 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41062,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='93' WHERE laboratorios_realizados.id_persona=347 AND laboratorios_realizados.id_control=594 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41063,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-22', resultado='0+' WHERE laboratorios_realizados.id_persona=347 AND laboratorios_realizados.id_control=594 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41064,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=352 AND laboratorios_realizados.id_control=601 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41065,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=352 AND laboratorios_realizados.id_control=601 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41066,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=352 AND laboratorios_realizados.id_control=601 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41067,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=352 AND laboratorios_realizados.id_control=601 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41068,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='13' WHERE laboratorios_realizados.id_persona=352 AND laboratorios_realizados.id_control=601 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41069,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='117' WHERE laboratorios_realizados.id_persona=352 AND laboratorios_realizados.id_control=601 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41070,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='0+' WHERE laboratorios_realizados.id_persona=352 AND laboratorios_realizados.id_control=601 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41071,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=398",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41072,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=249 AND laboratorios_realizados.id_control=596 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41073,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=249 AND laboratorios_realizados.id_control=596 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41074,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=249 AND laboratorios_realizados.id_control=596 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41075,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='71' WHERE laboratorios_realizados.id_persona=249 AND laboratorios_realizados.id_control=596 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41076,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=399",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41077,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=348 AND laboratorios_realizados.id_control=597 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41078,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=348 AND laboratorios_realizados.id_control=597 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41079,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=348 AND laboratorios_realizados.id_control=597 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41080,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=348 AND laboratorios_realizados.id_control=597 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41081,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='10.6' WHERE laboratorios_realizados.id_persona=348 AND laboratorios_realizados.id_control=597 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41082,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='106' WHERE laboratorios_realizados.id_persona=348 AND laboratorios_realizados.id_control=597 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41083,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='0+' WHERE laboratorios_realizados.id_persona=348 AND laboratorios_realizados.id_control=597 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41084,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=401",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41085,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=350 AND laboratorios_realizados.id_control=599 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41086,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=350 AND laboratorios_realizados.id_control=599 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41087,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=350 AND laboratorios_realizados.id_control=599 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41088,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=350 AND laboratorios_realizados.id_control=599 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41089,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='11' WHERE laboratorios_realizados.id_persona=350 AND laboratorios_realizados.id_control=599 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41090,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='77' WHERE laboratorios_realizados.id_persona=350 AND laboratorios_realizados.id_control=599 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41091,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='0+' WHERE laboratorios_realizados.id_persona=350 AND laboratorios_realizados.id_control=599 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41092,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=402",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41093,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=351 AND laboratorios_realizados.id_control=600 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41094,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=351 AND laboratorios_realizados.id_control=600 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41095,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=351 AND laboratorios_realizados.id_control=600 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41096,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=351 AND laboratorios_realizados.id_control=600 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41097,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='11.6' WHERE laboratorios_realizados.id_persona=351 AND laboratorios_realizados.id_control=600 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41098,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='86' WHERE laboratorios_realizados.id_persona=351 AND laboratorios_realizados.id_control=600 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41099,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='0+' WHERE laboratorios_realizados.id_persona=351 AND laboratorios_realizados.id_control=600 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41100,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=404",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41101,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=353 AND laboratorios_realizados.id_control=602 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41102,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=353 AND laboratorios_realizados.id_control=602 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41103,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=353 AND laboratorios_realizados.id_control=602 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41104,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=353 AND laboratorios_realizados.id_control=602 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41105,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='11' WHERE laboratorios_realizados.id_persona=353 AND laboratorios_realizados.id_control=602 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41106,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='91' WHERE laboratorios_realizados.id_persona=353 AND laboratorios_realizados.id_control=602 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41107,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=405",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41108,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=354 AND laboratorios_realizados.id_control=604 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41109,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=354 AND laboratorios_realizados.id_control=604 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41110,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=354 AND laboratorios_realizados.id_control=604 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41111,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=354 AND laboratorios_realizados.id_control=604 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41112,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='11' WHERE laboratorios_realizados.id_persona=354 AND laboratorios_realizados.id_control=604 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41113,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='88' WHERE laboratorios_realizados.id_persona=354 AND laboratorios_realizados.id_control=604 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41114,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='0+' WHERE laboratorios_realizados.id_persona=354 AND laboratorios_realizados.id_control=604 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41115,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=408",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41116,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=355 AND laboratorios_realizados.id_control=606 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41117,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=355 AND laboratorios_realizados.id_control=606 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41118,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=355 AND laboratorios_realizados.id_control=606 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41119,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=355 AND laboratorios_realizados.id_control=606 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41120,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='9.6' WHERE laboratorios_realizados.id_persona=355 AND laboratorios_realizados.id_control=606 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41121,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='87' WHERE laboratorios_realizados.id_persona=355 AND laboratorios_realizados.id_control=606 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41122,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='0+' WHERE laboratorios_realizados.id_persona=355 AND laboratorios_realizados.id_control=606 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41123,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=219 AND laboratorios_realizados.id_control=607 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41124,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=219 AND laboratorios_realizados.id_control=607 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41125,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='13' WHERE laboratorios_realizados.id_persona=219 AND laboratorios_realizados.id_control=607 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41126,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='141' WHERE laboratorios_realizados.id_persona=219 AND laboratorios_realizados.id_control=607 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41127,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=219 AND laboratorios_realizados.id_control=607 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41128,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=219 AND laboratorios_realizados.id_control=337 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41129,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=219 AND laboratorios_realizados.id_control=337 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41130,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=219 AND laboratorios_realizados.id_control=337 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41131,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=219 AND laboratorios_realizados.id_control=337 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41132,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='98' WHERE laboratorios_realizados.id_persona=219 AND laboratorios_realizados.id_control=337 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41133,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=409",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41134,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=356 AND laboratorios_realizados.id_control=608 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41135,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=356 AND laboratorios_realizados.id_control=608 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41136,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=356 AND laboratorios_realizados.id_control=608 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41137,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=356 AND laboratorios_realizados.id_control=608 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41138,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='13' WHERE laboratorios_realizados.id_persona=356 AND laboratorios_realizados.id_control=608 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41139,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='99' WHERE laboratorios_realizados.id_persona=356 AND laboratorios_realizados.id_control=608 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41140,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='0+' WHERE laboratorios_realizados.id_persona=356 AND laboratorios_realizados.id_control=608 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41141,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=410",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41142,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=357 AND laboratorios_realizados.id_control=609 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41143,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=357 AND laboratorios_realizados.id_control=609 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41144,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=357 AND laboratorios_realizados.id_control=609 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41145,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=357 AND laboratorios_realizados.id_control=609 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41146,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='11' WHERE laboratorios_realizados.id_persona=357 AND laboratorios_realizados.id_control=609 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41147,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='103' WHERE laboratorios_realizados.id_persona=357 AND laboratorios_realizados.id_control=609 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41148,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='0+' WHERE laboratorios_realizados.id_persona=357 AND laboratorios_realizados.id_control=609 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41149,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=411",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41150,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=216 AND laboratorios_realizados.id_control=610 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41151,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=216 AND laboratorios_realizados.id_control=610 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41152,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=216 AND laboratorios_realizados.id_control=610 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41153,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='12' WHERE laboratorios_realizados.id_persona=216 AND laboratorios_realizados.id_control=610 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41154,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='93' WHERE laboratorios_realizados.id_persona=216 AND laboratorios_realizados.id_control=610 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41155,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=216 AND laboratorios_realizados.id_control=330 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41156,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=216 AND laboratorios_realizados.id_control=330 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41157,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=216 AND laboratorios_realizados.id_control=330 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41158,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=216 AND laboratorios_realizados.id_control=330 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41159,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='11' WHERE laboratorios_realizados.id_persona=216 AND laboratorios_realizados.id_control=330 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41160,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='95' WHERE laboratorios_realizados.id_persona=216 AND laboratorios_realizados.id_control=330 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41161,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=412",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41162,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=358 AND laboratorios_realizados.id_control=611 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41163,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='9' WHERE laboratorios_realizados.id_persona=358 AND laboratorios_realizados.id_control=611 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41164,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='103' WHERE laboratorios_realizados.id_persona=358 AND laboratorios_realizados.id_control=611 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41165,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=413",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41166,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=165 AND laboratorios_realizados.id_control=612 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41167,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=165 AND laboratorios_realizados.id_control=612 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41168,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=165 AND laboratorios_realizados.id_control=612 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41169,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='12.3' WHERE laboratorios_realizados.id_persona=165 AND laboratorios_realizados.id_control=612 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41170,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='98' WHERE laboratorios_realizados.id_persona=165 AND laboratorios_realizados.id_control=612 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41171,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=414",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41172,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=359 AND laboratorios_realizados.id_control=613 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41173,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=359 AND laboratorios_realizados.id_control=613 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41174,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=359 AND laboratorios_realizados.id_control=613 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41175,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=359 AND laboratorios_realizados.id_control=613 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41176,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='12.3' WHERE laboratorios_realizados.id_persona=359 AND laboratorios_realizados.id_control=613 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41177,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='98' WHERE laboratorios_realizados.id_persona=359 AND laboratorios_realizados.id_control=613 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41178,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='0+' WHERE laboratorios_realizados.id_persona=359 AND laboratorios_realizados.id_control=613 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41179,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=415",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41180,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=360 AND laboratorios_realizados.id_control=614 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41181,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=360 AND laboratorios_realizados.id_control=614 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41182,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=360 AND laboratorios_realizados.id_control=614 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41183,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=360 AND laboratorios_realizados.id_control=614 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41184,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='12.3' WHERE laboratorios_realizados.id_persona=360 AND laboratorios_realizados.id_control=614 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41185,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='98' WHERE laboratorios_realizados.id_persona=360 AND laboratorios_realizados.id_control=614 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41186,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='0+' WHERE laboratorios_realizados.id_persona=360 AND laboratorios_realizados.id_control=614 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41187,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='12.3' WHERE laboratorios_realizados.id_persona=361 AND laboratorios_realizados.id_control=615 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41188,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='97' WHERE laboratorios_realizados.id_persona=361 AND laboratorios_realizados.id_control=615 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41189,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='0+' WHERE laboratorios_realizados.id_persona=361 AND laboratorios_realizados.id_control=615 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41190,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=416",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41191,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=361 AND laboratorios_realizados.id_control=615 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41192,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=361 AND laboratorios_realizados.id_control=615 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41193,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=361 AND laboratorios_realizados.id_control=615 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41194,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=361 AND laboratorios_realizados.id_control=615 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41195,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=421",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41196,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=365 AND laboratorios_realizados.id_control=620 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41197,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='11' WHERE laboratorios_realizados.id_persona=365 AND laboratorios_realizados.id_control=620 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41198,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='83' WHERE laboratorios_realizados.id_persona=365 AND laboratorios_realizados.id_control=620 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41199,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=420",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41200,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=364 AND laboratorios_realizados.id_control=619 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41201,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=364 AND laboratorios_realizados.id_control=619 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41202,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=364 AND laboratorios_realizados.id_control=619 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41203,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=364 AND laboratorios_realizados.id_control=619 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41204,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='10.3' WHERE laboratorios_realizados.id_persona=364 AND laboratorios_realizados.id_control=619 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41205,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='81' WHERE laboratorios_realizados.id_persona=364 AND laboratorios_realizados.id_control=619 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41206,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=419",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41207,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=418",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41208,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=300068 AND laboratorios_realizados.id_control=617 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41209,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=300068 AND laboratorios_realizados.id_control=617 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41210,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='11.3' WHERE laboratorios_realizados.id_persona=300068 AND laboratorios_realizados.id_control=617 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41211,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='89' WHERE laboratorios_realizados.id_persona=300068 AND laboratorios_realizados.id_control=617 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41212,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=417",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41213,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=362 AND laboratorios_realizados.id_control=616 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41214,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=362 AND laboratorios_realizados.id_control=616 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41215,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=362 AND laboratorios_realizados.id_control=616 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41216,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=362 AND laboratorios_realizados.id_control=616 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41217,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='10.9' WHERE laboratorios_realizados.id_persona=362 AND laboratorios_realizados.id_control=616 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41218,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='80' WHERE laboratorios_realizados.id_persona=362 AND laboratorios_realizados.id_control=616 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41219,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='0+' WHERE laboratorios_realizados.id_persona=362 AND laboratorios_realizados.id_control=616 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41220,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=422",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41221,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=230 AND laboratorios_realizados.id_control=621 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41222,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=230 AND laboratorios_realizados.id_control=621 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41223,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='70' WHERE laboratorios_realizados.id_persona=230 AND laboratorios_realizados.id_control=621 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41224,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=423",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41225,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=265 AND laboratorios_realizados.id_control=622 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41226,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=265 AND laboratorios_realizados.id_control=622 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41227,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='11.3' WHERE laboratorios_realizados.id_persona=265 AND laboratorios_realizados.id_control=622 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41228,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='110' WHERE laboratorios_realizados.id_persona=265 AND laboratorios_realizados.id_control=622 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41229,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=366 AND laboratorios_realizados.id_control=623 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41230,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=366 AND laboratorios_realizados.id_control=623 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41231,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=366 AND laboratorios_realizados.id_control=623 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41232,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=366 AND laboratorios_realizados.id_control=623 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41233,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='12' WHERE laboratorios_realizados.id_persona=366 AND laboratorios_realizados.id_control=623 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41234,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='105' WHERE laboratorios_realizados.id_persona=366 AND laboratorios_realizados.id_control=623 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41235,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='0+' WHERE laboratorios_realizados.id_persona=366 AND laboratorios_realizados.id_control=623 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41236,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=425",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41237,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=367 AND laboratorios_realizados.id_control=624 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41238,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=367 AND laboratorios_realizados.id_control=624 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41239,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=367 AND laboratorios_realizados.id_control=624 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41240,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=367 AND laboratorios_realizados.id_control=624 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41241,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='11.3' WHERE laboratorios_realizados.id_persona=367 AND laboratorios_realizados.id_control=624 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41242,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='98' WHERE laboratorios_realizados.id_persona=367 AND laboratorios_realizados.id_control=624 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41243,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='0+' WHERE laboratorios_realizados.id_persona=367 AND laboratorios_realizados.id_control=624 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41244,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=426",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41245,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=368 AND laboratorios_realizados.id_control=625 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41246,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=368 AND laboratorios_realizados.id_control=625 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41247,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=368 AND laboratorios_realizados.id_control=625 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41248,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=368 AND laboratorios_realizados.id_control=625 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41249,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='10.6' WHERE laboratorios_realizados.id_persona=368 AND laboratorios_realizados.id_control=625 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41250,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='113' WHERE laboratorios_realizados.id_persona=368 AND laboratorios_realizados.id_control=625 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41251,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='0+' WHERE laboratorios_realizados.id_persona=368 AND laboratorios_realizados.id_control=625 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41252,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=427",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41253,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=369 AND laboratorios_realizados.id_control=626 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41254,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=369 AND laboratorios_realizados.id_control=626 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41255,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=369 AND laboratorios_realizados.id_control=626 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41256,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=369 AND laboratorios_realizados.id_control=626 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41257,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='10.3' WHERE laboratorios_realizados.id_persona=369 AND laboratorios_realizados.id_control=626 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41258,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='127' WHERE laboratorios_realizados.id_persona=369 AND laboratorios_realizados.id_control=626 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41259,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=429",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41260,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=100173 AND laboratorios_realizados.id_control=640 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41261,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=100173 AND laboratorios_realizados.id_control=640 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41262,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='9.6' WHERE laboratorios_realizados.id_persona=100173 AND laboratorios_realizados.id_control=640 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41263,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='77' WHERE laboratorios_realizados.id_persona=100173 AND laboratorios_realizados.id_control=640 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41264,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='11' WHERE laboratorios_realizados.id_persona=100173 AND laboratorios_realizados.id_control=100299 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41265,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=431",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41266,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='13' WHERE laboratorios_realizados.id_persona=372 AND laboratorios_realizados.id_control=642 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41267,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='87' WHERE laboratorios_realizados.id_persona=372 AND laboratorios_realizados.id_control=642 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41268,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=372 AND laboratorios_realizados.id_control=642 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41269,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=372 AND laboratorios_realizados.id_control=642 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41270,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=373 AND laboratorios_realizados.id_control=643 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41271,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=373 AND laboratorios_realizados.id_control=643 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41272,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=373 AND laboratorios_realizados.id_control=643 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41273,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='10.3' WHERE laboratorios_realizados.id_persona=373 AND laboratorios_realizados.id_control=643 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41274,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=434",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41275,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=276 AND laboratorios_realizados.id_control=645 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41276,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=435",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41277,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=374 AND laboratorios_realizados.id_control=646 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41278,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=374 AND laboratorios_realizados.id_control=646 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41279,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=374 AND laboratorios_realizados.id_control=646 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41280,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=374 AND laboratorios_realizados.id_control=646 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41281,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='7.6' WHERE laboratorios_realizados.id_persona=374 AND laboratorios_realizados.id_control=646 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41282,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='82' WHERE laboratorios_realizados.id_persona=374 AND laboratorios_realizados.id_control=646 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41283,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='0+' WHERE laboratorios_realizados.id_persona=374 AND laboratorios_realizados.id_control=646 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41284,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=438",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41285,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=277 AND laboratorios_realizados.id_control=649 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41286,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=277 AND laboratorios_realizados.id_control=649 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41287,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=277 AND laboratorios_realizados.id_control=649 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41288,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=440",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41289,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=378 AND laboratorios_realizados.id_control=651 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41290,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=441",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41291,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=379 AND laboratorios_realizados.id_control=652 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41292,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=379 AND laboratorios_realizados.id_control=652 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41293,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=379 AND laboratorios_realizados.id_control=652 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41294,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='N' WHERE laboratorios_realizados.id_persona=379 AND laboratorios_realizados.id_control=652 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41295,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='10' WHERE laboratorios_realizados.id_persona=379 AND laboratorios_realizados.id_control=652 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41296,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='107' WHERE laboratorios_realizados.id_persona=379 AND laboratorios_realizados.id_control=652 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    41297,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-06-24', resultado='0+' WHERE laboratorios_realizados.id_persona=379 AND laboratorios_realizados.id_control=652 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-06-24 23:38:49",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43193,
                    "DELETE FROM etmis_personas WHERE etmis_personas.id_persona=279 AND etmis_personas.id_etmi=1 AND etmis_personas.id_control=455",
                    "2022-08-17 19:43:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43194,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-16', resultado='N' WHERE laboratorios_realizados.id_persona=279 AND laboratorios_realizados.id_control=455 AND laboratorios_realizados.id_laboratorio=19 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-08-17 19:43:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43195,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-16', resultado='N' WHERE laboratorios_realizados.id_persona=279 AND laboratorios_realizados.id_control=455 AND laboratorios_realizados.id_laboratorio=20 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-08-17 19:43:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43196,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-16', resultado='N' WHERE laboratorios_realizados.id_persona=279 AND laboratorios_realizados.id_control=455 AND laboratorios_realizados.id_laboratorio=23 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-08-17 19:43:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43197,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-16', resultado='N' WHERE laboratorios_realizados.id_persona=343 AND laboratorios_realizados.id_control=587 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-08-17 19:43:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43198,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-16', resultado='N' WHERE laboratorios_realizados.id_persona=343 AND laboratorios_realizados.id_control=587 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-08-17 19:43:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43199,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-16', resultado='N' WHERE laboratorios_realizados.id_persona=343 AND laboratorios_realizados.id_control=587 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-08-17 19:43:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43200,
                    "DELETE FROM etmis_personas WHERE etmis_personas.id_persona=343 AND etmis_personas.id_etmi=1 AND etmis_personas.id_control=587",
                    "2022-08-17 19:43:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43201,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-16', resultado='P' WHERE laboratorios_realizados.id_persona=343 AND laboratorios_realizados.id_control=587 AND laboratorios_realizados.id_laboratorio=19 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-08-17 19:43:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43202,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-16', resultado='P' WHERE laboratorios_realizados.id_persona=343 AND laboratorios_realizados.id_control=587 AND laboratorios_realizados.id_laboratorio=20 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-08-17 19:43:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43203,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-16', resultado='P' WHERE laboratorios_realizados.id_persona=343 AND laboratorios_realizados.id_control=587 AND laboratorios_realizados.id_laboratorio=23 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-08-17 19:43:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43204,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (100327, '2022-08-29 10:04:23.000000', 100185, 3, 2, '2022-08-28 00:00:00.000000', 2)",
                    "2022-08-29 19:01:42",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43205,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control, patologico, observaciones, derivacion) VALUES (100028, 100327, 1, 'PREECAMPSIA', 'TARTAGAL')",
                    "2022-08-29 19:01:42",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43206,
                    "SELECT COUNT(*) FROM etmis_personas WHERE etmis_personas.confirmada=1 AND etmis_personas.id_persona=100185",
                    "2022-08-29 19:01:42",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43207,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100186, 'RODRIGUEZ', 'MARIA LUISA', '29979496', '1983-06-29 00:00:00.000000', 1, 12, 'F')",
                    "2022-08-29 19:01:42",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43208,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100328, '2022-08-29 10:08:32.000000', 100186, 1, 1)",
                    "2022-08-29 19:01:42",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43209,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100186, 100186, 24, 3, '00', '2022-08-29 10:08:32.000000', '', 12)",
                    "2022-08-29 19:01:43",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43210,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100187, 'MENENDEZ', 'MARIELA', '45056373', '2003-07-26 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-29 19:01:43",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43211,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100329, '2022-08-29 10:19:34.000000', 100187, 1, 1)",
                    "2022-08-29 19:01:43",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43212,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100187, 100187, 24, 3, '00', '2022-08-29 10:19:34.000000', '', 12)",
                    "2022-08-29 19:01:43",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43213,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100181, 100187, 100329, 15, '2005-11-19 00:00:00.000000', 2, 1, 1, '2003-06-22 00:00:00.000000', '2010-03-23 00:00:00.000000')",
                    "2022-08-29 19:01:43",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43214,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100188, 'MENENDEZ', 'MARIELA', '45056373', '2003-07-26 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-29 19:01:43",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43215,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100330, '2022-08-29 10:22:11.000000', 100188, 1, 1)",
                    "2022-08-29 19:01:43",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43216,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100188, 100188, 24, 3, '00', '2022-08-29 10:22:11.000000', '', 12)",
                    "2022-08-29 19:01:43",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43217,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100182, 100188, 100330, 15, '2019-11-05 00:00:00.000000', 2, 1, 0, '2003-06-22 00:00:00.000000', '2010-03-23 00:00:00.000000')",
                    "2022-08-29 19:01:43",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43218,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100188, 100330, 2, 'S')",
                    "2022-08-29 19:01:43",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43219,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100188, 100330, 3, 'N')",
                    "2022-08-29 19:01:43",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43220,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100188, 100330, 1, 'N')",
                    "2022-08-29 19:01:44",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43221,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100188, 100330, 4, 'N')",
                    "2022-08-29 19:01:44",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43222,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100188, 100330, 1, 1, '2022-08-29 10:26:38.000000', 3)",
                    "2022-08-29 19:01:44",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43223,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100188, 100330, 2, 1, '2022-08-29 10:26:38.000000', 2)",
                    "2022-08-29 19:01:44",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43224,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100188, 100330, 4, 1, '2022-08-29 10:26:38.000000', 1)",
                    "2022-08-29 19:01:44",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43225,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100188, 100330, 5, 1, '2022-08-29 10:26:38.000000', 4)",
                    "2022-08-29 19:01:44",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43226,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100188, 100330, 6, 1, '2022-08-29 10:26:38.000000')",
                    "2022-08-29 19:01:44",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43227,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100188, 100330, 7, 1, '2022-08-29 10:26:38.000000')",
                    "2022-08-29 19:01:44",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43228,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100188, 100330, 9, 1, '2022-08-29 10:26:38.000000')",
                    "2022-08-29 19:01:44",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43229,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100275, 100330, 10, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-08-29 19:01:44",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43230,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100189, 'RODRIGUEZ', 'LUCINDA', '44017589', '2002-03-02 00:00:00.000000', 1, 12, 'F')",
                    "2022-08-29 19:01:44",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43231,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100331, '2022-08-29 10:32:08.000000', 100189, 1, 1)",
                    "2022-08-29 19:01:44",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43232,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100189, 100189, 24, 3, '00', '2022-08-29 10:32:08.000000', '', 12)",
                    "2022-08-29 19:01:44",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43233,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, abortos, planificado, fum, fpp) VALUES (100183, 100189, 100331, 16, '2018-03-23 00:00:00.000000', 3, 1, 1, 0, '2012-12-21 00:00:00.000000', '2018-09-22 00:00:00.000000')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43234,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100189, 100331, 2, 'S')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43235,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100189, 100331, 3, 'P')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43236,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100189, 100331, 1, 'S')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43237,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100189, 100331, 4, 'C')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43238,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100189, 100331, 5, 3, '2022-08-29 10:37:12.000000', 4)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43239,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100189, 100331, 6, 3, '2022-08-29 10:37:12.000000')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43240,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100189, 100331, 7, 3, '2022-08-29 10:37:12.000000')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43241,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100276, 100331, 505, 'S', ' ', 'N', 'N', 100, 70, 'N', '')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43242,
                    "SELECT id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo, madre, alta, nacido_vivo FROM personas WHERE id_persona = '100081'",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43243,
                    "SELECT id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais FROM ubicaciones WHERE id_ubicacion = '100081'",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43244,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100190, 'PAZ', 'EMILIA', '44017562', '2002-03-02 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43245,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100332, '2022-08-29 10:42:33.000000', 100190, 1, 1)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43246,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100190, 100190, 24, 3, '00', '2022-08-29 10:42:33.000000', '', 12)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43247,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100184, 100190, 100332, 16, '2018-02-20 00:00:00.000000', 2, 1, 0, '2017-03-22 00:00:00.000000', '2022-12-22 00:00:00.000000')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43248,
                    "INSERT INTO antecedentes_apps (id_antecedente, id_app) VALUES (100184, 4)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43249,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100190, 100332, 2, 'S')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43250,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100190, 100332, 3, 'N')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43251,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100190, 100332, 1, 'N')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43252,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100190, 100332, 4, 'C')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43253,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100190, 100332, 5, 3, '2022-08-29 10:47:48.000000', 4)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43254,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100190, 100332, 6, 3, '2022-08-29 10:47:48.000000')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43255,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100190, 100332, 7, 3, '2022-08-29 10:47:48.000000')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43256,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100190, 100332, 9, 3, '2022-08-29 10:47:48.000000')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43257,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100277, 100332, 283, 'S', ' ', 'N', 'N', 120, 70, 'N', '')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43258,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100191, 'PEREZ', 'CEBERIANA', '43439033', '2001-06-30 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43259,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100333, '2022-08-29 10:50:03.000000', 100191, 1, 1)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43260,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100191, 100191, 24, 3, '00', '2022-08-29 10:50:03.000000', '', 12)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43261,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100185, 100191, 100333, 13, '2016-04-01 00:00:00.000000', 3, 2, 0, '2020-06-22 00:00:00.000000', '2027-03-23 00:00:00.000000')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43262,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100191, 100333, 2, 'N')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43263,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100191, 100333, 3, 'N')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43264,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100191, 100333, 1, 'N')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43265,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100191, 100333, 4, 'N')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43266,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100191, 100333, 1, 3, '2022-08-29 10:52:52.000000', 3)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43267,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100191, 100333, 2, 3, '2022-08-29 10:52:52.000000', 2)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43268,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100191, 100333, 4, 3, '2022-08-29 10:52:52.000000', 1)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43269,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100191, 100333, 5, 3, '2022-08-29 10:52:52.000000', 4)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43270,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100191, 100333, 6, 3, '2022-08-29 10:52:52.000000')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43271,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100191, 100333, 7, 3, '2022-08-29 10:52:52.000000')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43272,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100191, 100333, 9, 3, '2022-08-29 10:52:52.000000')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43273,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100278, 100333, 114, 'S', ' ', 'N', 'N', 110, 70, 'N', '')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43274,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100192, 'TORRES', 'KARINA', '18899653', '1990-08-01 00:00:00.000000', 1, 12, 'F')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43275,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100334, '2022-08-29 10:58:46.000000', 100192, 1, 1)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43276,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100192, 100192, 24, 3, '00', '2022-08-29 10:58:46.000000', '', 12)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43277,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100186, 100192, 100334, 15, '2001-01-22 00:00:00.000000', 6, 5, 0, '2022-04-01 00:00:00.000000', '2023-01-06 00:00:00.000000')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43278,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100192, 100334, 2, 'N')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43279,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100192, 100334, 3, 'N')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43280,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100192, 100334, 1, 'N')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43281,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100192, 100334, 4, 'N')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43282,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100192, 100334, 1, 2, '2022-08-29 11:01:48.000000', 3)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43283,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100192, 100334, 2, 2, '2022-08-29 11:01:48.000000', 2)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43284,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100192, 100334, 4, 2, '2022-08-29 11:01:48.000000', 1)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43285,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100192, 100334, 5, 2, '2022-08-29 11:01:48.000000', 4)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43286,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100192, 100334, 6, 2, '2022-08-29 11:01:48.000000')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43287,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100192, 100334, 7, 2, '2022-08-29 11:01:48.000000')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43288,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100192, 100334, 9, 2, '2022-08-29 11:01:48.000000')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43289,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100279, 100334, 21, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43290,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100193, 'CONSTANTINO', 'MAXIMILIANA', '41372445', '2001-01-08 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43291,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100335, '2022-08-29 11:08:27.000000', 100193, 1, 1)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43292,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100193, 100193, 24, 3, '00', '2022-08-29 11:08:27.000000', '', 12)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43293,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100187, 100193, 100335, 16, '2006-05-21 00:00:00.000000', 4, 3, 0, '2006-05-22 00:00:00.000000', '2010-02-23 00:00:00.000000')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43294,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100193, 100335, 2, 'N')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43295,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100193, 100335, 3, 'N')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43296,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100193, 100335, 1, 'N')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43297,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100193, 100335, 4, 'N')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43298,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100193, 100335, 1, 3, '2022-08-29 11:13:06.000000', 3)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43299,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100193, 100335, 2, 3, '2022-08-29 11:13:06.000000', 2)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43300,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100193, 100335, 4, 3, '2022-08-29 11:13:06.000000', 1)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43301,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100193, 100335, 5, 3, '2022-08-29 11:13:06.000000', 4)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43302,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100193, 100335, 6, 3, '2022-08-29 11:13:06.000000')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43303,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100193, 100335, 7, 3, '2022-08-29 11:13:06.000000')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43304,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100193, 100335, 9, 3, '2022-08-29 11:13:06.000000')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43305,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100280, 100335, 849, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43306,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, cesareas, planificado, fum, fpp) VALUES (100188, 100186, 100328, 16, '2013-01-01 00:00:00.000000', 8, 6, 1, 0, '2001-03-22 00:00:00.000000', '2006-12-22 00:00:00.000000')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43307,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100186, 100328, 2, 'N')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43308,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100186, 100328, 3, 'N')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43309,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100186, 100328, 1, 'N')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43310,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100186, 100328, 4, 'N')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43311,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100186, 100328, 1, 3, '2022-08-29 11:27:59.000000', 3)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43312,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100186, 100328, 2, 3, '2022-08-29 11:27:59.000000', 2)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43313,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100186, 100328, 4, 3, '2022-08-29 11:27:59.000000', 1)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43314,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100186, 100328, 5, 3, '2022-08-29 11:27:59.000000', 4)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43315,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100186, 100328, 6, 3, '2022-08-29 11:27:59.000000')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43316,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100186, 100328, 7, 3, '2022-08-29 11:27:59.000000')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43317,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100186, 100328, 9, 3, '2022-08-29 11:27:59.000000')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43318,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100281, 100328, 1118, 'S', ' ', 'N', 'N', 110, 70, 'N', '')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43319,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100336, '2022-08-29 11:29:06.000000', 200056, 2, 1)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43320,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200056, 100336, 2, 'S')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43321,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200056, 100336, 3, 'C')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43322,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200056, 100336, 4, 'C')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43323,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200056, 100336, 5, 2, '2022-08-29 11:31:33.000000', 4)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43324,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200056, 100336, 6, 2, '2022-08-29 11:31:33.000000')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43325,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200056, 100336, 7, 2, '2022-08-29 11:31:33.000000')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43326,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100282, 100336, 24, 'S', ' ', 'N', 'N', 130, 70, 'N', '')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43327,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100194, 'DEBORATH', 'FELIX', '38272930', '1993-08-30 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43328,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100337, '2022-08-29 11:40:02.000000', 100194, 1, 1)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43329,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100194, 100194, 24, 3, '00', '2022-08-29 11:40:02.000000', '', 12)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43330,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, abortos, planificado, fum, fpp) VALUES (100189, 100194, 100337, 24, '2005-02-22 00:00:00.000000', 4, 1, 2, 1, '2030-04-22 00:00:00.000000', '2004-02-23 00:00:00.000000')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43331,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100194, 100337, 2, 'N')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43332,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100194, 100337, 3, 'N')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43333,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100194, 100337, 1, 'N')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43334,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100194, 100337, 4, 'N')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43335,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100194, 100337, 1, 3, '2022-08-29 11:47:03.000000', 3)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43336,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100194, 100337, 2, 3, '2022-08-29 11:47:03.000000', 2)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43337,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100194, 100337, 4, 3, '2022-08-29 11:47:03.000000', 1)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43338,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100194, 100337, 5, 3, '2022-08-29 11:47:03.000000', 4)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43339,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100194, 100337, 6, 3, '2022-08-29 11:47:03.000000')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43340,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100194, 100337, 7, 3, '2022-08-29 11:47:03.000000')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43341,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100194, 100337, 9, 3, '2022-08-29 11:47:03.000000')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43342,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100283, 100337, 398, 'S', ' ', 'N', 'N', 100, 70, 'N', '')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43343,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100195, 'PEREZ', 'MARIA ELODIANA', '37602730', '1993-11-30 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43344,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100338, '2022-08-29 11:53:09.000000', 100195, 1, 1)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43345,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100195, 100195, 24, 3, '00', '2022-08-29 11:53:09.000000', '', 12)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43346,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, cesareas, planificado, fum, fpp) VALUES (100190, 100195, 100338, 15, '2019-08-04 00:00:00.000000', 9, 7, 1, 0, '2010-04-22 00:00:00.000000', '2015-01-23 00:00:00.000000')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43347,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100195, 100338, 2, 'S')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43348,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100195, 100338, 3, 'N')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43349,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100195, 100338, 1, 'N')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43350,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100195, 100338, 4, 'N')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43351,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100195, 100338, 1, 3, '2022-08-29 11:56:52.000000', 3)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43352,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100195, 100338, 2, 3, '2022-08-29 11:56:52.000000', 2)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43353,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100195, 100338, 4, 3, '2022-08-29 11:56:52.000000', 1)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43354,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100195, 100338, 5, 3, '2022-08-29 11:56:52.000000', 4)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43355,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100195, 100338, 6, 3, '2022-08-29 11:56:52.000000')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43356,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100195, 100338, 7, 3, '2022-08-29 11:56:52.000000')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43357,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100195, 100338, 9, 3, '2022-08-29 11:56:52.000000')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43358,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100284, 100338, 644, 'S', ' ', 'N', 'N', 120, 60, 'N', '')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43359,
                    "UPDATE personas SET apellido='ALVAREZ' WHERE personas.id_persona=100195",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43360,
                    "SELECT id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais FROM ubicaciones WHERE id_ubicacion = '100195'",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43361,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100196, 'FRIAS', 'MARISA', '44401967', '1994-08-28 00:00:00.000000', 1, 12, 'F')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43362,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100339, '2022-08-29 12:37:06.000000', 100196, 1, 1)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43363,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100196, 100196, 24, 3, '00', '2022-08-29 12:37:06.000000', '', 12)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43364,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, abortos, planificado, fum, fpp) VALUES (100191, 100196, 100339, 16, '2025-05-22 00:00:00.000000', 9, 3, 5, 0, '2028-05-22 00:00:00.000000', '2004-03-23 00:00:00.000000')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43365,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100196, 100339, 2, 'N')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43366,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100196, 100339, 3, 'N')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43367,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100196, 100339, 1, 'N')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43368,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100196, 100339, 4, 'N')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43369,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100196, 100339, 1, 3, '2022-08-29 12:41:33.000000', '2022-08-29 12:41:33.000000', 'N', 3)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43370,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100196, 100339, 2, 3, '2022-08-29 12:41:33.000000', '2022-08-29 12:41:33.000000', 'N', 2)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43371,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100196, 100339, 4, 3, '2022-08-29 12:41:33.000000', '2022-08-29 12:41:33.000000', 'N', 1)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43372,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100196, 100339, 5, 3, '2022-08-29 12:41:33.000000', 4)",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43373,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100196, 100339, 6, 3, '2022-08-29 12:41:33.000000')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43374,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100196, 100339, 7, 3, '2022-08-29 12:41:33.000000')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43375,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (100196, 100339, 9, 3, '2022-08-29 12:41:33.000000', '2022-08-29 12:41:33.000000', '0+')",
                    "2022-08-29 19:01:45",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43376,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100285, 100339, 298, 'S', ' ', 'N', 'N', 110, 60, 'N', '')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43377,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100197, 'LOPEZ', 'SILVINA', '45114385', '2001-10-30 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43378,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100340, '2022-08-29 12:45:33.000000', 100197, 1, 1)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43379,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100197, 100197, 24, 3, '00', '2022-08-29 12:45:33.000000', '', 12)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43380,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, abortos, planificado, fum, fpp) VALUES (100192, 100197, 100340, 16, '2003-06-18 00:00:00.000000', 3, 1, 1, 0, '2002-04-22 00:00:00.000000', '2007-01-23 00:00:00.000000')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43381,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100197, 100340, 2, 'S')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43382,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100197, 100340, 3, 'N')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43383,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100197, 100340, 1, 'N')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43384,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100197, 100340, 4, 'C')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43385,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100197, 100340, 1, 3, '2022-08-29 12:48:01.000000', 3)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43386,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100197, 100340, 2, 3, '2022-08-29 12:48:01.000000', 2)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43387,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100197, 100340, 4, 3, '2022-08-29 12:48:01.000000', 1)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43388,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100197, 100340, 5, 3, '2022-08-29 12:48:01.000000', 4)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43389,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100197, 100340, 6, 3, '2022-08-29 12:48:01.000000')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43390,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100197, 100340, 7, 3, '2022-08-29 12:48:01.000000')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43391,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100197, 100340, 9, 3, '2022-08-29 12:48:01.000000')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43392,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100286, 100340, 1062, 'S', ' ', 'N', 'N', 120, 60, 'N', '')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43393,
                    "SELECT id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo, madre, alta, nacido_vivo FROM personas WHERE id_persona = '100196'",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43394,
                    "SELECT id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais FROM ubicaciones WHERE id_ubicacion = '100196'",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43395,
                    "UPDATE antecedentes SET fecha_ultimo_embarazo='2022-05-22', fum='2022-05-22', fpp='2023-02-26' WHERE antecedentes.id_antecedente=100191",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43396,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100198, 'FERNANDEZ', 'MIRTA ROSENDA', '42488484', '2000-02-04 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43397,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100341, '2022-08-29 13:31:07.000000', 100198, 1, 1)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43398,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100198, 100198, 24, 3, '00', '2022-08-29 13:31:07.000000', '', 12)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43399,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100193, 100198, 100341, 18, '2018-10-31 00:00:00.000000', 2, 1, 0, '2011-03-22 00:00:00.000000', '2016-12-22 00:00:00.000000')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43400,
                    "INSERT INTO antecedentes_apps (id_antecedente, id_app) VALUES (100193, 4)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43401,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100198, 100341, 2, 'S')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43402,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100198, 100341, 3, 'C')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43403,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100198, 100341, 1, 'S')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43404,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100198, 100341, 4, 'C')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43405,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100198, 100341, 1, 3, '2022-08-29 13:36:31.000000', '2022-08-29 13:36:31.000000', 'N', 3)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43406,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100198, 100341, 2, 3, '2022-08-29 13:36:31.000000', '2022-08-29 13:36:31.000000', 'N', 2)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43407,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100198, 100341, 4, 3, '2022-08-29 13:36:31.000000', '2022-08-29 13:36:31.000000', 'N', 1)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43408,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100198, 100341, 5, 3, '2022-08-29 13:36:31.000000', 4)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43409,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100198, 100341, 6, 3, '2022-08-29 13:36:31.000000')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43410,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100198, 100341, 7, 3, '2022-08-29 13:36:31.000000')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43411,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (100198, 100341, 9, 3, '2022-08-29 13:36:31.000000', '2022-08-29 13:36:31.000000', '0+')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43412,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100287, 100341, 596, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43413,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100342, '2022-08-29 14:34:57.000000', 270, 2, 1)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43414,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (270, 100342, 2, 'S')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43415,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (270, 100342, 3, 'C')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43416,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (270, 100342, 4, 'C')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43417,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (270, 100342, 1, 3, '2022-08-29 14:37:59.000000', 3)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43418,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (270, 100342, 2, 3, '2022-08-29 14:37:59.000000', 2)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43419,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (270, 100342, 4, 3, '2022-08-29 14:37:59.000000', 1)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43420,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (270, 100342, 5, 3, '2022-08-29 14:37:59.000000', 4)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43421,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (270, 100342, 6, 3, '2022-08-29 14:37:59.000000')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43422,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (270, 100342, 7, 3, '2022-08-29 14:37:59.000000')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43423,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100288, 100342, 36, 'S', ' ', 'N', 'N', 100, 70, 'N', '')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43424,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100199, 'RICARDO', 'ANABEL', '41122194', '1996-05-18 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43425,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100343, '2022-08-29 15:08:07.000000', 100199, 1, 1)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43426,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100199, 100199, 22, 3, '00', '2022-08-29 15:08:07.000000', '', 12)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43427,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, cesareas, abortos, planificado, fum, fpp) VALUES (100194, 100199, 100343, 16, '2014-03-20 00:00:00.000000', 5, 2, 1, 1, 0, '2005-06-22 00:00:00.000000', '2012-03-23 00:00:00.000000')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43428,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100199, 100343, 2, 'S')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43429,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100199, 100343, 3, 'N')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43430,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100199, 100343, 1, 'N')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43431,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100199, 100343, 4, 'N')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43432,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100199, 100343, 1, 3, '2022-08-29 15:13:39.000000', '2022-08-29 15:13:39.000000', 'N', 3)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43433,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100199, 100343, 2, 3, '2022-08-29 15:13:39.000000', '2022-08-29 15:13:39.000000', 'N', 2)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43434,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100199, 100343, 4, 3, '2022-08-29 15:13:39.000000', '2022-08-29 15:13:39.000000', 'N', 1)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43435,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100199, 100343, 5, 3, '2022-08-29 15:13:39.000000', '2022-08-29 15:13:39.000000', 'N', 4)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43436,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100199, 100343, 6, 3, '2022-08-29 15:13:39.000000')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43437,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100199, 100343, 7, 3, '2022-08-29 15:13:39.000000')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43438,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (100199, 100343, 9, 3, '2022-08-29 15:13:39.000000', '2022-08-29 15:13:39.000000', '0+')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43439,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100289, 100343, 896, 'R', ' ', 'N', 'N', 120, 60, 'N', '')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43440,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100200, 'IBARRA', 'SOLEDAD', '45114355', '2003-12-02 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43441,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100344, '2022-08-29 15:29:14.000000', 100200, 1, 1)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43442,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100200, 100200, 22, 3, '00', '2022-08-29 15:29:14.000000', '', 12)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43443,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (100195, 100200, 100344, 19, 1, 0, '2020-05-22 00:00:00.000000', '2024-02-23 00:00:00.000000')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43444,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100200, 100344, 2, 'N')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43445,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100200, 100344, 3, 'N')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43446,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100200, 100344, 1, 'N')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43447,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100200, 100344, 4, 'N')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43448,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100200, 100344, 1, 3, '2022-08-29 15:33:19.000000', 3)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43449,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100200, 100344, 2, 3, '2022-08-29 15:33:19.000000', 2)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43450,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100200, 100344, 4, 3, '2022-08-29 15:33:19.000000', 1)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43451,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100200, 100344, 5, 3, '2022-08-29 15:33:19.000000', 4)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43452,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100200, 100344, 6, 3, '2022-08-29 15:33:19.000000')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43453,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100200, 100344, 7, 3, '2022-08-29 15:33:19.000000')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43454,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100200, 100344, 9, 3, '2022-08-29 15:33:19.000000')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43455,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100290, 100344, 118, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43456,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100201, 'IBARRA', 'CARLA', '43439059', '2001-08-20 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43457,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100345, '2022-08-29 15:36:32.000000', 100201, 1, 1)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43458,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100201, 100201, 22, 3, '00', '2022-08-29 15:36:32.000000', '', 12)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43459,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (100196, 100201, 100345, 20, 1, 1, '2030-03-22 00:00:00.000000', '2004-01-23 00:00:00.000000')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43460,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100201, 100345, 2, 'S')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43461,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100201, 100345, 3, 'N')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43462,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100201, 100345, 1, 'N')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43463,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100201, 100345, 4, 'N')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43464,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100201, 100345, 1, 3, '2022-08-29 15:45:17.000000', '2022-08-29 15:45:17.000000', 'N', 3)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43465,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100201, 100345, 2, 3, '2022-08-29 15:45:17.000000', '2022-08-29 15:45:17.000000', 'N', 2)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43466,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100201, 100345, 4, 3, '2022-08-29 15:45:17.000000', '2022-08-29 15:45:17.000000', 'N', 1)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43467,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100201, 100345, 5, 3, '2022-08-29 15:45:17.000000', '2022-08-29 15:45:17.000000', 'N', 4)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43468,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100201, 100345, 6, 3, '2022-08-29 15:45:17.000000')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43469,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100201, 100345, 7, 3, '2022-08-29 15:45:17.000000')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43470,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (100201, 100345, 9, 3, '2022-08-29 15:45:17.000000', '2022-08-29 15:45:17.000000', '0+')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43471,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100291, 100345, 394, 'S', ' ', 'N', 'N', 120, 60, 'N', '')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43472,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100202, 'CHAVEZ', 'ALEXIA', '45056342', '2001-04-10 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43473,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100346, '2022-08-29 15:46:55.000000', 100202, 1, 1)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43474,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100202, 100202, 22, 3, '00', '2022-08-29 15:46:55.000000', '', 12)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43475,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (100197, 100202, 100346, 20, 1, 1, '2009-12-21 00:00:00.000000', '2015-09-22 00:00:00.000000')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43476,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100202, 100346, 2, 'S')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43477,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100202, 100346, 3, 'C')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43478,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100202, 100346, 1, 'S')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43479,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100202, 100346, 4, 'C')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43480,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100202, 100346, 1, 3, '2022-08-29 15:50:12.000000', 3)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43481,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100202, 100346, 2, 3, '2022-08-29 15:50:12.000000', 2)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43482,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100202, 100346, 4, 3, '2022-08-29 15:50:12.000000', 1)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43483,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100202, 100346, 5, 3, '2022-08-29 15:50:12.000000', 4)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43484,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100202, 100346, 6, 3, '2022-08-29 15:50:12.000000')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43485,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100202, 100346, 7, 3, '2022-08-29 15:50:12.000000')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43486,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100202, 100346, 9, 3, '2022-08-29 15:50:12.000000')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43487,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100292, 100346, 662, 'R', ' ', 'N', 'N', 100, 70, 'N', '')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43488,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100203, 'ESQUIVEL', 'TANIA', '43949944', '2001-12-06 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43489,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100347, '2022-08-29 15:53:38.000000', 100203, 1, 1)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43490,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100203, 100203, 22, 3, '00', '2022-08-29 15:53:38.000000', '', 12)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43491,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, abortos, planificado, fum, fpp) VALUES (100198, 100203, 100347, 13, '2019-01-06 00:00:00.000000', 7, 2, 4, 0, '2011-06-22 00:00:00.000000', '2018-03-23 00:00:00.000000')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43492,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100203, 100347, 2, 'N')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43493,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100203, 100347, 3, 'N')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43494,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100203, 100347, 1, 'N')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43495,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100203, 100347, 4, 'N')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43496,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100203, 100347, 1, 3, '2022-08-29 15:58:04.000000', 3)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43497,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100203, 100347, 2, 3, '2022-08-29 15:58:04.000000', 2)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43498,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100203, 100347, 4, 3, '2022-08-29 15:58:04.000000', 1)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43499,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100203, 100347, 5, 3, '2022-08-29 15:58:04.000000', 4)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43500,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100203, 100347, 6, 3, '2022-08-29 15:58:04.000000')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43501,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100203, 100347, 7, 3, '2022-08-29 15:58:04.000000')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43502,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100203, 100347, 9, 3, '2022-08-29 15:58:04.000000')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43503,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100293, 100347, 583, 'R', ' ', 'N', 'N', 100, 70, 'N', '')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43504,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100204, 'FERREYRA', 'MICAELA', '45056345', '2002-08-21 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43505,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100348, '2022-08-29 16:08:00.000000', 100204, 1, 1)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43506,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100204, 100204, 22, 3, '00', '2022-08-29 16:08:00.000000', '', 12)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43507,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100199, 100204, 100348, 18, '2020-06-19 00:00:00.000000', 2, 1, 1, '2020-04-22 00:00:00.000000', '2025-01-23 00:00:00.000000')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43508,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100204, 100348, 2, 'S')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43509,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100204, 100348, 3, 'C')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43510,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100204, 100348, 1, 'S')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43511,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100204, 100348, 4, 'C')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43512,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100204, 100348, 1, 3, '2022-08-29 16:19:45.000000', 3)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43513,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100204, 100348, 2, 3, '2022-08-29 16:19:45.000000', 2)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43514,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100204, 100348, 4, 3, '2022-08-29 16:19:45.000000', 1)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43515,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100204, 100348, 5, 3, '2022-08-29 16:19:45.000000', 4)",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43516,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100204, 100348, 6, 3, '2022-08-29 16:19:45.000000')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43517,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100204, 100348, 7, 3, '2022-08-29 16:19:45.000000')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43518,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100204, 100348, 9, 3, '2022-08-29 16:19:45.000000')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43519,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100294, 100348, 122, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-08-29 19:01:46",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43520,
                    "UPDATE personas SET apellido='QUIROGA' WHERE personas.id_persona=100186",
                    "2022-08-29 23:14:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43521,
                    "SELECT id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais FROM ubicaciones WHERE id_ubicacion = '100186'",
                    "2022-08-29 23:14:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43522,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100205, 'APARICIO', 'CINTIA', '39892290', '1995-05-13 00:00:00.000000', 1, 12, 'F')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43523,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, georeferencia) VALUES (100349, '2022-08-29 23:28:58.000000', 100205, 1, 1, '-22.2725026,-62.7125106')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43524,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100205, 100205, 22, 3, '00', '2022-08-29 23:28:58.000000', '-22.2725026,-62.7125106', 12)",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43525,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (100200, 100205, 100349, 27, 1, 1, '2022-06-13 00:00:00.000000', '2023-03-20 00:00:00.000000')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43526,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100205, 100349, 2, 'S')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43527,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100205, 100349, 3, 'N')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43528,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100205, 100349, 1, 'N')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43529,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100205, 100349, 4, 'N')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43530,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100205, 100349, 1, 1, '2022-08-29 23:32:26.000000', '2022-08-29 23:32:26.000000', 'N', 3)",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43531,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100205, 100349, 2, 1, '2022-08-29 23:32:26.000000', '2022-08-29 23:32:26.000000', 'N', 2)",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43532,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100205, 100349, 4, 1, '2022-08-29 23:32:26.000000', '2022-08-29 23:32:26.000000', 'N', 1)",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43533,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100205, 100349, 5, 1, '2022-08-29 23:32:26.000000', '2022-08-29 23:32:26.000000', 'N', 4)",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43534,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100205, 100349, 6, 1, '2022-08-29 23:32:26.000000')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43535,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100205, 100349, 7, 1, '2022-08-29 23:32:26.000000')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43536,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (100205, 100349, 9, 1, '2022-08-29 23:32:26.000000', '2022-08-29 23:32:26.000000', '0+')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43537,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100295, 100349, 11, 'S', ' ', 'N', 'N', 120, 70, 'N', '')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43538,
                    "SELECT id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo, madre, alta, nacido_vivo FROM personas WHERE id_persona = '271'",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43539,
                    "SELECT id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais FROM ubicaciones WHERE id_ubicacion = '271'",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43540,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100206, 'ROMERO', 'PATRICIA', '18838809', '1978-10-24 00:00:00.000000', 1, 12, 'F')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43541,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, georeferencia) VALUES (100350, '2022-08-29 23:36:24.000000', 100206, 1, 1, '-22.272924,-62.7170235')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43542,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100206, 100206, 22, 3, '00', '2022-08-29 23:36:24.000000', '-22.272924,-62.7170235', 12)",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43543,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, abortos, planificado, fum, fpp) VALUES (100201, 100206, 100350, 16, '2019-01-01 00:00:00.000000', 15, 13, 1, 0, '2028-01-22 00:00:00.000000', '2004-11-22 00:00:00.000000')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43544,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100206, 100350, 2, 'S')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43545,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100206, 100350, 3, 'N')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43546,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100206, 100350, 1, 'N')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43547,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100206, 100350, 4, 'N')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43548,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100206, 100350, 1, 3, '2022-08-29 23:42:43.000000', 3)",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43549,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100206, 100350, 2, 3, '2022-08-29 23:42:43.000000', 2)",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43550,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100206, 100350, 4, 3, '2022-08-29 23:42:43.000000', 1)",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43551,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100206, 100350, 5, 3, '2022-08-29 23:42:43.000000', 4)",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43552,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100206, 100350, 6, 3, '2022-08-29 23:42:43.000000')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43553,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100206, 100350, 7, 3, '2022-08-29 23:42:43.000000')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43554,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100206, 100350, 9, 3, '2022-08-29 23:42:43.000000')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43555,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100296, 100350, 281, 'S', ' ', 'N', 'N', 120, 80, 'N', '')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43556,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100207, 'PEREZ', 'DOMINGA', '45056337', '1999-08-22 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43557,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, georeferencia) VALUES (100351, '2022-08-29 23:47:36.000000', 100207, 1, 1, '-22.2729242,-62.7170169')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43558,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100207, 100207, 22, 3, '00', '2022-08-29 23:47:36.000000', '-22.2729242,-62.7170169', 12)",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43559,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100202, 100207, 100351, 17, '2017-11-01 00:00:00.000000', 2, 1, 1, '2022-03-28 00:00:00.000000', '2023-01-02 00:00:00.000000')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43560,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100207, 100351, 2, 'S')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43561,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100207, 100351, 3, 'N')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43562,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100207, 100351, 1, 'N')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43563,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100207, 100351, 4, 'N')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43564,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100207, 100351, 1, 2, '2022-08-29 23:50:46.000000', '2022-08-29 23:50:46.000000', 'N', 3)",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43565,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100207, 100351, 2, 2, '2022-08-29 23:50:46.000000', '2022-08-29 23:50:46.000000', 'N', 2)",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43566,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100207, 100351, 4, 2, '2022-08-29 23:50:46.000000', '2022-08-29 23:50:46.000000', 'N', 1)",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43567,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100207, 100351, 5, 2, '2022-08-29 23:50:46.000000', '2022-08-29 23:50:46.000000', 'N', 4)",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43568,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100207, 100351, 6, 2, '2022-08-29 23:50:46.000000')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43569,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100207, 100351, 7, 2, '2022-08-29 23:50:46.000000')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43570,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (100207, 100351, 9, 2, '2022-08-29 23:50:46.000000', '2022-08-29 23:50:46.000000', '0+')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43571,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100297, 100351, 22, 'R', ' ', 'N', 'N', 100, 70, 'N', '')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43572,
                    "UPDATE personas SET id_origen=2 WHERE personas.id_persona=200063",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43573,
                    "SELECT id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais FROM ubicaciones WHERE id_ubicacion = '200063'",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43574,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100208, 'FERREYRA', 'AURELIA', '39892246', '1996-08-15 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43575,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, georeferencia) VALUES (100352, '2022-08-29 23:55:22.000000', 100208, 1, 1, '-22.2729278,-62.7170212')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43576,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100208, 100208, 22, 3, '00', '2022-08-29 23:55:22.000000', '-22.2729278,-62.7170212', 12)",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43577,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100209, 'PERALTA', 'GREGORIA', '35263091', '1991-04-18 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43578,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100353, '2022-08-30 09:13:46.000000', 100209, 1, 1)",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43579,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100209, 100209, 37, 3, '00', '2022-08-30 09:13:46.000000', '', 12)",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43580,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100210, 'BARRIOS CENTURION', 'ANALIS RAMONA', '7394954', '1998-08-24 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43581,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100354, '2022-08-30 09:28:23.000000', 100210, 1, 1)",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43582,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100210, 100210, 37, 3, '00', '2022-08-30 09:28:23.000000', '', 12)",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43583,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, cesareas, planificado, fum, fpp) VALUES (100203, 100210, 100354, 23, '2008-07-21 00:00:00.000000', 2, 1, 0, '2019-01-22 00:00:00.000000', '2026-10-22 00:00:00.000000')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43584,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100210, 100354, 2, 'S')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43585,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100210, 100354, 3, 'N')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43586,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100210, 100354, 1, 'N')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43587,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100210, 100354, 4, 'C')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43588,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100210, 100354, 1, 3, '2022-08-30 09:31:57.000000', 3)",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43589,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100210, 100354, 2, 3, '2022-08-30 09:31:57.000000', 2)",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43590,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100210, 100354, 4, 3, '2022-08-30 09:31:57.000000', 1)",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43591,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100210, 100354, 5, 3, '2022-08-30 09:31:57.000000', 4)",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43592,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100210, 100354, 6, 3, '2022-08-30 09:31:57.000000')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43593,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100210, 100354, 7, 3, '2022-08-30 09:31:57.000000')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43594,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100210, 100354, 9, 3, '2022-08-30 09:31:57.000000')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43595,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100298, 100354, 188, 'S', ' ', 'N', 'N', 110, 60, 'N', '')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43596,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100211, 'GAVI', 'MIRIAM', '34142149', '1988-02-24 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43597,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100355, '2022-08-30 09:35:33.000000', 100211, 1, 1)",
                    "2022-08-30 23:28:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43598,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100211, 100211, 37, 3, '00', '2022-08-30 09:35:33.000000', '', 12)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43599,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100212, 'GONZALEZ', 'ANDREA', '33659670', '1987-08-30 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43600,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100356, '2022-08-30 09:40:00.000000', 100212, 1, 1)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43601,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100212, 100212, 37, 3, '00', '2022-08-30 09:40:00.000000', '', 12)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43602,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (100204, 100212, 100356, 35, 1, 1, '2020-02-22 00:00:00.000000', '2027-11-22 00:00:00.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43603,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100212, 100356, 2, 'S')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43604,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100212, 100356, 3, 'N')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43605,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100212, 100356, 1, 'N')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43606,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100212, 100356, 4, 'C')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43607,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100212, 100356, 1, 3, '2022-08-30 09:43:27.000000', 3)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43608,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100212, 100356, 2, 3, '2022-08-30 09:43:27.000000', 2)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43609,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100212, 100356, 4, 3, '2022-08-30 09:43:27.000000', 1)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43610,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100212, 100356, 5, 3, '2022-08-30 09:43:27.000000', 4)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43611,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100212, 100356, 6, 3, '2022-08-30 09:43:27.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43612,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100212, 100356, 7, 3, '2022-08-30 09:43:27.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43613,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100212, 100356, 9, 3, '2022-08-30 09:43:27.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43614,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100299, 100356, 131, 'S', ' ', 'N', 'N', 120, 70, 'N', '')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43615,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100213, 'GALEANO ALVARADO', 'JUANA TOMASA', '4246174', '1982-06-22 00:00:00.000000', 2, 177, 'F')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43616,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100357, '2022-08-30 09:48:13.000000', 100213, 1, 1)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43617,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100213, 100213, 3, 2, '00', '2022-08-30 09:48:13.000000', '', 177)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43618,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, cesareas, planificado, fum, fpp) VALUES (100205, 100213, 100357, 22, '2018-10-21 00:00:00.000000', 5, 2, 2, 1, '2005-03-22 00:00:00.000000', '2010-12-22 00:00:00.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43619,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100206, 100211, 100355, 19, '2019-04-29 00:00:00.000000', 5, 4, 0, '2013-12-21 00:00:00.000000', '2019-09-22 00:00:00.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43620,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100211, 100355, 2, 'N')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43621,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100211, 100355, 3, 'N')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43622,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100211, 100355, 1, 'N')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43623,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100211, 100355, 4, 'N')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43624,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100211, 100355, 1, 3, '2022-08-30 09:53:58.000000', 3)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43625,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100211, 100355, 2, 3, '2022-08-30 09:53:58.000000', 2)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43626,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100211, 100355, 4, 3, '2022-08-30 09:53:58.000000', 1)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43627,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100211, 100355, 5, 3, '2022-08-30 09:53:58.000000', 4)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43628,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100211, 100355, 6, 3, '2022-08-30 09:53:58.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43629,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100211, 100355, 7, 3, '2022-08-30 09:53:58.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43630,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100211, 100355, 9, 3, '2022-08-30 09:53:58.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43631,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100300, 100355, 453, 'S', ' ', 'N', 'N', 110, 60, 'N', '')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43632,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100214, 'MEZA ALVARADO', 'LILIANA ELVA', '6339645', '1988-10-03 00:00:00.000000', 2, 177, 'F')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43633,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100358, '2022-08-30 10:01:04.000000', 100214, 1, 1)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43634,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100214, 100214, 3, 2, '00', '2022-08-30 10:01:04.000000', '', 177)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43635,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100207, 100214, 100358, 17, '2013-01-27 00:00:00.000000', 3, 2, 1, '2025-02-22 00:00:00.000000', '2002-12-22 00:00:00.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43636,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100214, 100358, 2, 'S')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43637,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100214, 100358, 3, 'N')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43638,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100214, 100358, 1, 'N')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43639,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100214, 100358, 4, 'C')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43640,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100214, 100358, 1, 3, '2022-08-30 10:06:22.000000', 3)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43641,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100214, 100358, 2, 3, '2022-08-30 10:06:22.000000', 2)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43642,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100214, 100358, 4, 3, '2022-08-30 10:06:22.000000', 1)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43643,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100214, 100358, 5, 3, '2022-08-30 10:06:22.000000', 4)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43644,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100214, 100358, 6, 3, '2022-08-30 10:06:22.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43645,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100214, 100358, 7, 3, '2022-08-30 10:06:22.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43646,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100214, 100358, 9, 3, '2022-08-30 10:06:22.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43647,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100301, 100358, 129, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43648,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100215, 'DEL VALLE MORENO JAIME', 'ANGELICA', '8095460', '2014-12-28 00:00:00.000000', 2, 177, 'F')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43649,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100359, '2022-08-30 10:10:27.000000', 100215, 1, 1)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43650,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100215, 100215, 3, 2, '00', '2022-08-30 10:10:27.000000', '', 177)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43651,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (100208, 100215, 100359, 17, 1, 1, '2021-01-22 00:00:00.000000', '2028-10-22 00:00:00.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43652,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100215, 100359, 2, 'S')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43653,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100215, 100359, 3, 'N')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43654,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100215, 100359, 1, 'N')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43655,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100215, 100359, 4, 'N')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43656,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100215, 100359, 1, 3, '2022-08-30 10:14:00.000000', 3)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43657,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100215, 100359, 2, 3, '2022-08-30 10:14:00.000000', 2)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43658,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100215, 100359, 4, 3, '2022-08-30 10:14:00.000000', 1)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43659,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100215, 100359, 5, 3, '2022-08-30 10:14:00.000000', 4)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43660,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100215, 100359, 6, 3, '2022-08-30 10:14:00.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43661,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100215, 100359, 7, 3, '2022-08-30 10:14:00.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43662,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100215, 100359, 9, 3, '2022-08-30 10:14:00.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43663,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100302, 100359, 83, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43664,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100216, 'GUERRERO GONZALEZ', 'REBECA', '7221446', '2007-01-29 00:00:00.000000', 2, 177, 'F')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43665,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100360, '2022-08-30 10:16:11.000000', 100216, 1, 1)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43666,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100216, 100216, 3, 2, '00', '2022-08-30 10:16:11.000000', '', 177)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43667,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (100209, 100216, 100360, 15, 1, 0, '2015-05-22 00:00:00.000000', '2019-02-23 00:00:00.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43668,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100216, 100360, 2, 'S')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43669,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100216, 100360, 3, 'N')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43670,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100216, 100360, 1, 'N')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43671,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100216, 100360, 4, 'N')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43672,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100216, 100360, 1, 3, '2022-08-30 10:18:17.000000', 3)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43673,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100216, 100360, 2, 3, '2022-08-30 10:18:17.000000', 2)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43674,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100216, 100360, 4, 3, '2022-08-30 10:18:17.000000', 1)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43675,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100216, 100360, 5, 3, '2022-08-30 10:18:17.000000', 4)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43676,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100216, 100360, 6, 3, '2022-08-30 10:18:17.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43677,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100216, 100360, 7, 3, '2022-08-30 10:18:17.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43678,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100216, 100360, 9, 3, '2022-08-30 10:18:17.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43679,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100303, 100360, 379, 'S', ' ', 'N', 'N', 110, 70, 'N', '')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43680,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100217, 'GUERRERO GONZALEZ', 'DOMINGA', '7221448', '2001-01-14 00:00:00.000000', 2, 177, 'F')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43681,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100361, '2022-08-30 10:24:23.000000', 100217, 1, 1)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43682,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100217, 100217, 3, 2, '00', '2022-08-30 10:24:23.000000', '', 177)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43683,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100210, 100217, 100361, 19, '2022-12-20 00:00:00.000000', 2, 1, 0, '2027-04-22 00:00:00.000000', '2001-02-23 00:00:00.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43684,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100217, 100361, 2, 'S')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43685,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100217, 100361, 3, 'C')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43686,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100217, 100361, 1, 'N')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43687,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100217, 100361, 4, 'N')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43688,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100217, 100361, 1, 3, '2022-08-30 10:26:47.000000', 3)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43689,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100217, 100361, 2, 3, '2022-08-30 10:26:47.000000', 2)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43690,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100217, 100361, 4, 3, '2022-08-30 10:26:47.000000', 1)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43691,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100217, 100361, 5, 3, '2022-08-30 10:26:47.000000', 4)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43692,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100217, 100361, 6, 3, '2022-08-30 10:26:47.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43693,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100217, 100361, 7, 3, '2022-08-30 10:26:47.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43694,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100217, 100361, 9, 3, '2022-08-30 10:26:47.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43695,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100304, 100361, 242, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43696,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100218, 'ABRAHAN RUIZ', 'MARIA SOLEDAD', '6872622', '1999-12-03 00:00:00.000000', 2, 177, 'F')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43697,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100362, '2022-08-30 10:32:03.000000', 100218, 1, 1)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43698,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100218, 100218, 3, 2, '00', '2022-08-30 10:32:03.000000', '', 177)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43699,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100211, 100218, 100362, 18, '2005-03-03 00:00:00.000000', 2, 1, 1, '2013-06-22 00:00:00.000000', '2020-03-23 00:00:00.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43700,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100218, 100362, 2, 'N')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43701,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100218, 100362, 3, 'N')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43702,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100218, 100362, 1, 'N')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43703,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100218, 100362, 4, 'N')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43704,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100218, 100362, 1, 3, '2022-08-30 10:34:36.000000', 3)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43705,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100218, 100362, 2, 3, '2022-08-30 10:34:36.000000', 2)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43706,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100218, 100362, 4, 3, '2022-08-30 10:34:36.000000', 1)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43707,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100218, 100362, 5, 3, '2022-08-30 10:34:36.000000', 4)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43708,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100218, 100362, 6, 3, '2022-08-30 10:34:36.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43709,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100218, 100362, 7, 3, '2022-08-30 10:34:36.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43710,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100218, 100362, 9, 3, '2022-08-30 10:34:36.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43711,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100305, 100362, 479, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43712,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100219, 'ROMERO', 'ARMINDA', '39536749', '1996-08-13 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43713,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100363, '2022-08-30 10:39:28.000000', 100219, 1, 1)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43714,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100219, 100219, 37, 3, '00', '2022-08-30 10:39:28.000000', '', 12)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43715,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, cesareas, planificado, fum, fpp) VALUES (100212, 100219, 100363, 22, '2019-05-01 00:00:00.000000', 3, 2, 1, '2001-05-22 00:00:00.000000', '2005-02-23 00:00:00.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43716,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100219, 100363, 2, 'S')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43717,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100219, 100363, 3, 'N')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43718,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100219, 100363, 1, 'N')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43719,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100219, 100363, 4, 'N')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43720,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100219, 100363, 1, 3, '2022-08-30 10:43:29.000000', 3)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43721,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100219, 100363, 2, 3, '2022-08-30 10:43:29.000000', 2)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43722,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100219, 100363, 4, 3, '2022-08-30 10:43:29.000000', 1)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43723,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100219, 100363, 5, 3, '2022-08-30 10:43:29.000000', 4)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43724,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100219, 100363, 6, 3, '2022-08-30 10:43:29.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43725,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100219, 100363, 7, 3, '2022-08-30 10:43:29.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43726,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100219, 100363, 9, 3, '2022-08-30 10:43:29.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43727,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100306, 100363, 1110, 'S', ' ', 'N', 'N', 110, 70, 'N', '')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43728,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100220, 'CAROSSA', 'NATALIA ROMINA', '39215783', '1996-01-25 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43729,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100364, '2022-08-30 10:48:13.000000', 100220, 1, 1)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43730,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100220, 100220, 37, 3, '00', '2022-08-30 10:48:13.000000', '', 12)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43731,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100213, 100220, 100364, 18, '2002-06-20 00:00:00.000000', 4, 3, 1, '2010-02-22 00:00:00.000000', '2017-11-22 00:00:00.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43732,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100220, 100364, 2, 'S')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43733,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100220, 100364, 3, 'N')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43734,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100220, 100364, 1, 'N')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43735,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100220, 100364, 4, 'N')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43736,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100220, 100364, 1, 3, '2022-08-30 10:50:42.000000', 3)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43737,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100220, 100364, 2, 3, '2022-08-30 10:50:42.000000', 2)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43738,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100220, 100364, 4, 3, '2022-08-30 10:50:42.000000', 1)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43739,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100220, 100364, 5, 3, '2022-08-30 10:50:42.000000', 4)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43740,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100220, 100364, 6, 3, '2022-08-30 10:50:42.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43741,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100220, 100364, 7, 3, '2022-08-30 10:50:42.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43742,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100220, 100364, 9, 3, '2022-08-30 10:50:42.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43743,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100307, 100364, 653, 'S', ' ', 'N', 'N', 120, 60, 'N', '')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43744,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100221, 'GONZALEZ', 'NILDA', '30595931', '1983-11-13 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43745,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100365, '2022-08-30 10:53:16.000000', 100221, 1, 1)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43746,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100221, 100221, 37, 3, '00', '2022-08-30 10:53:16.000000', '', 12)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43747,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, cesareas, planificado, fum, fpp) VALUES (100214, 100221, 100365, 18, '2007-05-20 00:00:00.000000', 7, 5, 1, 1, '2026-04-22 00:00:00.000000', '2031-01-23 00:00:00.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43748,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100221, 100365, 2, 'N')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43749,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100221, 100365, 3, 'N')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43750,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100221, 100365, 1, 'N')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43751,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100221, 100365, 4, 'N')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43752,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100221, 100365, 1, 3, '2022-08-30 10:55:50.000000', 3)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43753,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100221, 100365, 2, 3, '2022-08-30 10:55:50.000000', 2)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43754,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100221, 100365, 4, 3, '2022-08-30 10:55:50.000000', 1)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43755,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100221, 100365, 5, 3, '2022-08-30 10:55:50.000000', 4)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43756,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100221, 100365, 6, 3, '2022-08-30 10:55:50.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43757,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100221, 100365, 7, 3, '2022-08-30 10:55:50.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43758,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100221, 100365, 9, 3, '2022-08-30 10:55:50.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43759,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100308, 100365, 190, 'S', ' ', 'N', 'N', 120, 70, 'N', '')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43760,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100222, 'DAMIAN FLORES', 'MARIA ANGELA', '4680540', '1987-09-23 00:00:00.000000', 2, 177, 'F')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43761,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100366, '2022-08-30 10:59:54.000000', 100222, 1, 1)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43762,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100222, 100222, 3, 2, '00', '2022-08-30 10:59:54.000000', '', 177)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43763,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100215, 100222, 100366, 24, '2012-02-05 00:00:00.000000', 2, 1, 1, '2007-04-22 00:00:00.000000', '2012-01-23 00:00:00.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43764,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100222, 100366, 2, 'S')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43765,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100222, 100366, 3, 'N')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43766,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100222, 100366, 1, 'N')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43767,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100222, 100366, 4, 'N')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43768,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100222, 100366, 1, 3, '2022-08-30 11:01:55.000000', 3)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43769,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100222, 100366, 2, 3, '2022-08-30 11:01:55.000000', 2)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43770,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100222, 100366, 4, 3, '2022-08-30 11:01:55.000000', 1)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43771,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100222, 100366, 5, 3, '2022-08-30 11:01:55.000000', 4)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43772,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100222, 100366, 6, 3, '2022-08-30 11:01:55.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43773,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100222, 100366, 7, 3, '2022-08-30 11:01:55.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43774,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100222, 100366, 9, 3, '2022-08-30 11:01:55.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43775,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100309, 100366, 801, 'S', ' ', 'N', 'N', 110, 70, 'N', '')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43776,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100223, 'DIAZ', 'NILDA', '41939782', '1999-07-09 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43777,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100367, '2022-08-30 11:04:28.000000', 100223, 1, 1)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43778,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100223, 100223, 37, 3, '00', '2022-08-30 11:04:28.000000', '', 12)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43779,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (100216, 100223, 100367, 22, 1, 1, '2003-07-22 00:00:00.000000', '2009-04-23 00:00:00.000000')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43780,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100223, 100367, 2, 'N')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43781,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100223, 100367, 3, 'N')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43782,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100223, 100367, 1, 'N')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43783,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100223, 100367, 4, 'N')",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43784,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100223, 100367, 1, 3, '2022-08-30 11:07:42.000000', 3)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43785,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100223, 100367, 2, 3, '2022-08-30 11:07:42.000000', 2)",
                    "2022-08-30 23:28:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43786,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100223, 100367, 4, 3, '2022-08-30 11:07:42.000000', 1)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43787,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100223, 100367, 5, 3, '2022-08-30 11:07:42.000000', 4)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43788,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100223, 100367, 6, 3, '2022-08-30 11:07:42.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43789,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100223, 100367, 7, 3, '2022-08-30 11:07:42.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43790,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100223, 100367, 9, 3, '2022-08-30 11:07:42.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43791,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100310, 100367, 997, 'S', ' ', 'N', 'N', 120, 70, 'N', '')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43792,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100224, 'MARIBEL', 'GONZALEZ', '46666331', '2006-01-23 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43793,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100368, '2022-08-30 11:10:51.000000', 100224, 1, 1)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43794,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100224, 100224, 37, 3, '00', '2022-08-30 11:10:51.000000', '', 12)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43795,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (100217, 100224, 100368, 16, 1, 0, '2002-05-22 00:00:00.000000', '2006-02-23 00:00:00.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43796,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100224, 100368, 2, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43797,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100224, 100368, 3, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43798,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100224, 100368, 1, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43799,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100224, 100368, 4, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43800,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100224, 100368, 1, 3, '2022-08-30 11:13:04.000000', 3)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43801,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100224, 100368, 2, 3, '2022-08-30 11:13:04.000000', 2)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43802,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100224, 100368, 4, 3, '2022-08-30 11:13:04.000000', 1)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43803,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100224, 100368, 5, 3, '2022-08-30 11:13:04.000000', 4)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43804,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100224, 100368, 6, 3, '2022-08-30 11:13:04.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43805,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100224, 100368, 7, 3, '2022-08-30 11:13:04.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43806,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100224, 100368, 9, 3, '2022-08-30 11:13:04.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43807,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100311, 100368, 1057, 'S', ' ', 'N', 'N', 120, 70, 'N', '')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43808,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100225, 'GONZALEZ', 'GEMMA', '45114364', '2004-12-19 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43809,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100369, '2022-08-30 11:16:43.000000', 100225, 1, 1)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43810,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100225, 100225, 37, 3, '00', '2022-08-30 11:16:43.000000', '', 12)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43811,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (100218, 100225, 100369, 18, 1, 0, '2003-05-22 00:00:00.000000', '2007-02-23 00:00:00.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43812,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100225, 100369, 2, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43813,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100225, 100369, 3, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43814,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100225, 100369, 1, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43815,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100225, 100369, 4, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43816,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100225, 100369, 1, 3, '2022-08-30 11:18:32.000000', 3)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43817,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100225, 100369, 2, 3, '2022-08-30 11:18:32.000000', 2)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43818,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100225, 100369, 4, 3, '2022-08-30 11:18:32.000000', 1)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43819,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100225, 100369, 5, 3, '2022-08-30 11:18:32.000000', 4)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43820,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100225, 100369, 6, 3, '2022-08-30 11:18:32.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43821,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100225, 100369, 7, 3, '2022-08-30 11:18:32.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43822,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100225, 100369, 9, 3, '2022-08-30 11:18:32.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43823,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100312, 100369, 1005, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43824,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100226, 'ELIZABETH', 'LOPEZ', '44492922', '2003-08-01 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43825,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100370, '2022-08-30 11:21:21.000000', 100226, 1, 1)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43826,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100226, 100226, 37, 3, '00', '2022-08-30 11:21:21.000000', '', 12)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43827,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (100219, 100226, 100370, 18, 1, 1, '2015-12-21 00:00:00.000000', '2021-09-22 00:00:00.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43828,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100226, 100370, 2, 'S')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43829,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100226, 100370, 3, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43830,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100226, 100370, 1, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43831,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100226, 100370, 4, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43832,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100226, 100370, 1, 3, '2022-08-30 11:23:46.000000', 3)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43833,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100226, 100370, 2, 3, '2022-08-30 11:23:46.000000', 2)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43834,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100226, 100370, 4, 3, '2022-08-30 11:23:46.000000', 1)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43835,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100226, 100370, 5, 3, '2022-08-30 11:23:46.000000', 4)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43836,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100226, 100370, 6, 3, '2022-08-30 11:23:46.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43837,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100226, 100370, 7, 3, '2022-08-30 11:23:46.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43838,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100226, 100370, 9, 3, '2022-08-30 11:23:46.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43839,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100313, 100370, 349, 'S', ' ', 'N', 'N', 110, 70, 'N', '')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43840,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100227, 'FELIZ', 'SILVINA', '35263084', '1990-11-08 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43841,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100371, '2022-08-30 11:26:28.000000', 100227, 1, 1)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43842,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100227, 100227, 37, 3, '00', '2022-08-30 11:26:28.000000', '', 12)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43843,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100220, 100227, 100371, 15, '2017-01-01 00:00:00.000000', 5, 4, 0, '2004-02-22 00:00:00.000000', '2011-11-22 00:00:00.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43844,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100227, 100371, 2, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43845,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100227, 100371, 3, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43846,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100227, 100371, 1, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43847,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100227, 100371, 4, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43848,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100227, 100371, 1, 3, '2022-08-30 11:29:45.000000', 3)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43849,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100227, 100371, 2, 3, '2022-08-30 11:29:45.000000', 2)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43850,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100227, 100371, 4, 3, '2022-08-30 11:29:45.000000', 1)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43851,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100227, 100371, 5, 3, '2022-08-30 11:29:45.000000', 4)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43852,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100227, 100371, 6, 3, '2022-08-30 11:29:45.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43853,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100227, 100371, 7, 3, '2022-08-30 11:29:45.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43854,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100227, 100371, 9, 3, '2022-08-30 11:29:45.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43855,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100314, 100371, 966, 'S', ' ', 'N', 'N', 110, 60, 'N', '')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43856,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100228, 'GARCIA', 'TERESILDA', '30595975', '1984-08-05 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43857,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100372, '2022-08-30 11:33:15.000000', 100228, 1, 1)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43858,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100228, 100228, 37, 3, '00', '2022-08-30 11:33:15.000000', '', 12)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43859,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100221, 100228, 100372, 15, '2018-04-28 00:00:00.000000', 7, 6, 0, '2006-07-22 00:00:00.000000', '2012-04-23 00:00:00.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43860,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100228, 100372, 2, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43861,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100228, 100372, 3, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43862,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100228, 100372, 1, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43863,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100228, 100372, 4, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43864,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100228, 100372, 1, 3, '2022-08-30 11:35:53.000000', 3)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43865,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100228, 100372, 2, 3, '2022-08-30 11:35:53.000000', 2)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43866,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100228, 100372, 4, 3, '2022-08-30 11:35:53.000000', 1)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43867,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100228, 100372, 5, 3, '2022-08-30 11:35:53.000000', 4)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43868,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100228, 100372, 6, 3, '2022-08-30 11:35:53.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43869,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100228, 100372, 7, 3, '2022-08-30 11:35:53.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43870,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100228, 100372, 9, 3, '2022-08-30 11:35:53.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43871,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100315, 100372, 840, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43872,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100229, 'MARTINEZ', 'FLORENCIA', '29789193', '1981-12-24 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43873,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100373, '2022-08-30 11:38:12.000000', 100229, 1, 1)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43874,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100229, 100229, 37, 3, '00', '2022-08-30 11:38:12.000000', '', 12)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43875,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100222, 100229, 100373, 17, '2017-04-23 00:00:00.000000', 6, 5, 0, '2015-06-22 00:00:00.000000', '2022-03-23 00:00:00.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43876,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100229, 100373, 2, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43877,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100229, 100373, 3, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43878,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100229, 100373, 1, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43879,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100229, 100373, 4, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43880,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100229, 100373, 1, 3, '2022-08-30 11:40:32.000000', 3)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43881,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100229, 100373, 2, 3, '2022-08-30 11:40:32.000000', 2)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43882,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100229, 100373, 4, 3, '2022-08-30 11:40:32.000000', 1)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43883,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100229, 100373, 5, 3, '2022-08-30 11:40:32.000000', 4)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43884,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100229, 100373, 6, 3, '2022-08-30 11:40:32.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43885,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100229, 100373, 7, 3, '2022-08-30 11:40:32.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43886,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100229, 100373, 9, 3, '2022-08-30 11:40:32.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43887,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100316, 100373, 375, 'S', ' ', 'N', 'N', 120, 70, 'N', '')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43888,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100374, '2022-08-30 11:41:29.000000', 264, 2, 1)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43889,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (264, 100374, 2, 'S')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43890,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (264, 100374, 3, 'C')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43891,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (264, 100374, 4, 'C')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43892,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (264, 100374, 1, 3, '2022-08-30 11:45:32.000000', 3)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43893,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (264, 100374, 2, 3, '2022-08-30 11:45:32.000000', 2)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43894,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (264, 100374, 4, 3, '2022-08-30 11:45:32.000000', 1)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43895,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (264, 100374, 5, 3, '2022-08-30 11:45:32.000000', 4)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43896,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (264, 100374, 6, 3, '2022-08-30 11:45:32.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43897,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (264, 100374, 7, 3, '2022-08-30 11:45:32.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43898,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (264, 100374, 9, 3, '2022-08-30 11:45:32.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43899,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100317, 100374, 37, 'S', ' ', 'N', 'N', 100, 70, 'N', '')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43900,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100230, 'GONZALEZ', 'JOSEFINA', '44499412', '2004-11-23 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43901,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100375, '2022-08-30 11:47:26.000000', 100230, 1, 1)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43902,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100230, 100230, 37, 3, '00', '2022-08-30 11:47:26.000000', '', 12)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43903,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (100223, 100230, 100375, 17, 1, 0, '2022-07-17 00:00:00.000000', '2023-04-23 00:00:00.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43904,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100230, 100375, 2, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43905,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100230, 100375, 3, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43906,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100230, 100375, 1, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43907,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100230, 100375, 4, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43908,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100230, 100375, 1, 1, '2022-08-30 11:49:23.000000', 3)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43909,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100230, 100375, 2, 1, '2022-08-30 11:49:23.000000', 2)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43910,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100230, 100375, 4, 1, '2022-08-30 11:49:23.000000', 1)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43911,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100230, 100375, 5, 1, '2022-08-30 11:49:23.000000', 4)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43912,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100230, 100375, 6, 1, '2022-08-30 11:49:23.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43913,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100230, 100375, 7, 1, '2022-08-30 11:49:23.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43914,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100230, 100375, 9, 1, '2022-08-30 11:49:23.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43915,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100318, 100375, 6, 'S', ' ', 'N', 'N', 120, 70, 'N', '')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43916,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100231, 'CECILIO', 'ARMINDA DALINDA', '41370063', '2000-01-08 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43917,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100376, '2022-08-30 11:51:25.000000', 100231, 1, 1)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43918,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100231, 100231, 37, 3, '00', '2022-08-30 11:51:25.000000', '', 12)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43919,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100224, 100231, 100376, 19, '2028-01-19 00:00:00.000000', 2, 1, 1, '2017-04-22 00:00:00.000000', '2022-01-23 00:00:00.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43920,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100231, 100376, 2, 'S')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43921,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100231, 100376, 3, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43922,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100231, 100376, 1, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43923,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100231, 100376, 4, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43924,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100231, 100376, 6, 3, '2022-08-30 11:54:01.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43925,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100231, 100376, 7, 3, '2022-08-30 11:54:01.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43926,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100319, 100376, 279, 'S', ' ', 'N', 'N', 110, 70, 'N', '')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43927,
                    "SELECT id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo, madre, alta, nacido_vivo FROM personas WHERE id_persona = '100152'",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43928,
                    "SELECT id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais FROM ubicaciones WHERE id_ubicacion = '100152'",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43929,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100232, 'NATO', 'NURIA', '46666308', '2027-11-22 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43930,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100377, '2022-08-30 11:58:52.000000', 100232, 1, 1)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43931,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100232, 100232, 37, 3, '00', '2022-08-30 11:58:52.000000', '', 12)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43932,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100233, 'GOMEZ', 'MARIA ELENA', '45056304', '1996-11-05 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43933,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100378, '2022-08-30 12:02:10.000000', 100233, 1, 1)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43934,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100233, 100233, 37, 3, '00', '2022-08-30 12:02:10.000000', '', 12)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43935,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, abortos, planificado, fum, fpp) VALUES (100225, 100233, 100378, 17, '2022-08-30 00:00:00.000000', 4, 2, 1, 1, '2021-02-03 00:00:00.000000', '2021-11-10 00:00:00.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43936,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100233, 100378, 2, 'S')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43937,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100233, 100378, 3, 'C')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43938,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100233, 100378, 1, 'S')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43939,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100233, 100378, 4, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43940,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100233, 100378, 6, 3, '2022-08-30 12:05:01.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43941,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100233, 100378, 7, 3, '2022-08-30 12:05:01.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43942,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100320, 100378, 81, 'S', ' ', 'N', 'N', 100, 70, 'N', '')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43943,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100226, 100209, 100353, 16, '2016-07-29 00:00:00.000000', 7, 6, 1, '2001-06-22 00:00:00.000000', '2008-03-23 00:00:00.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43944,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100209, 100353, 2, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43945,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100209, 100353, 3, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43946,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100209, 100353, 1, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43947,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100209, 100353, 4, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43948,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100209, 100353, 1, 3, '2022-08-30 12:07:13.000000', 3)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43949,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100209, 100353, 2, 3, '2022-08-30 12:07:13.000000', 2)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43950,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100209, 100353, 4, 3, '2022-08-30 12:07:13.000000', 1)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43951,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100209, 100353, 5, 3, '2022-08-30 12:07:13.000000', 4)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43952,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100209, 100353, 6, 3, '2022-08-30 12:07:13.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43953,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100209, 100353, 7, 3, '2022-08-30 12:07:13.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43954,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100209, 100353, 9, 3, '2022-08-30 12:07:13.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43955,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100321, 100353, 1105, 'S', ' ', 'N', 'N', 120, 70, 'N', '')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43956,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100234, 'COLMAN', 'TEOFILA', '37747530', '1994-10-29 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43957,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100379, '2022-08-30 12:08:46.000000', 100234, 1, 1)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43958,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100234, 100234, 37, 3, '00', '2022-08-30 12:08:46.000000', '', 12)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43959,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100227, 100234, 100379, 18, '2018-07-01 00:00:00.000000', 4, 3, 1, '2028-05-22 00:00:00.000000', '2004-03-23 00:00:00.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43960,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100234, 100379, 2, 'S')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43961,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100234, 100379, 3, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43962,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100234, 100379, 1, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43963,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100234, 100379, 4, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43964,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100234, 100379, 1, 3, '2022-08-30 12:12:32.000000', 3)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43965,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100234, 100379, 2, 3, '2022-08-30 12:12:32.000000', 2)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43966,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100234, 100379, 4, 3, '2022-08-30 12:12:32.000000', 1)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43967,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100234, 100379, 5, 3, '2022-08-30 12:12:32.000000', 4)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43968,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100234, 100379, 6, 3, '2022-08-30 12:12:32.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43969,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100234, 100379, 7, 3, '2022-08-30 12:12:32.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43970,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100234, 100379, 9, 3, '2022-08-30 12:12:32.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43971,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100322, 100379, 298, 'S', ' ', 'N', 'N', 120, 80, 'N', '')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43972,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100235, 'COLMAN', 'FLAVIA', '41369647', '1998-10-20 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43973,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100380, '2022-08-30 12:14:29.000000', 100235, 1, 1)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43974,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100235, 100235, 37, 3, '00', '2022-08-30 12:14:29.000000', '', 12)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43975,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, cesareas, planificado, fum, fpp) VALUES (100228, 100235, 100380, 18, '2029-09-16 00:00:00.000000', 2, 1, 1, '2012-04-22 00:00:00.000000', '2017-01-23 00:00:00.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43976,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100235, 100380, 2, 'S')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43977,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100235, 100380, 3, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43978,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100235, 100380, 1, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43979,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100235, 100380, 4, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43980,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100235, 100380, 1, 3, '2022-08-30 12:17:38.000000', 3)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43981,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100235, 100380, 2, 3, '2022-08-30 12:17:38.000000', 2)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43982,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100235, 100380, 4, 3, '2022-08-30 12:17:38.000000', 1)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43983,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100235, 100380, 5, 3, '2022-08-30 12:17:38.000000', 4)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43984,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100235, 100380, 6, 3, '2022-08-30 12:17:38.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43985,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100235, 100380, 7, 3, '2022-08-30 12:17:38.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43986,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100235, 100380, 9, 3, '2022-08-30 12:17:38.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43987,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100323, 100380, 540, 'S', ' ', 'N', 'N', 100, 70, 'N', '')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43988,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100236, 'MARTINEZ', 'MARIA ELENA', '46055774', '2005-08-13 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43989,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100381, '2022-08-30 12:21:17.000000', 100236, 1, 1)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43990,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100236, 100236, 37, 3, '00', '2022-08-30 12:21:17.000000', '', 12)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43991,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, abortos, planificado, fum, fpp) VALUES (100229, 100236, 100381, 17, 2, 1, 1, '2001-05-22 00:00:00.000000', '2005-02-23 00:00:00.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43992,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100236, 100381, 2, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43993,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100236, 100381, 3, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43994,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100236, 100381, 1, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43995,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100236, 100381, 4, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43996,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100236, 100381, 1, 3, '2022-08-30 12:23:30.000000', 3)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43997,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100236, 100381, 2, 3, '2022-08-30 12:23:30.000000', 2)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43998,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100236, 100381, 4, 3, '2022-08-30 12:23:30.000000', 1)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    43999,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100236, 100381, 5, 3, '2022-08-30 12:23:30.000000', 4)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44000,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100236, 100381, 6, 3, '2022-08-30 12:23:30.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44001,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100236, 100381, 7, 3, '2022-08-30 12:23:30.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44002,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100236, 100381, 9, 3, '2022-08-30 12:23:30.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44003,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100324, 100381, 1110, 'S', ' ', 'N', 'N', 120, 60, 'N', '')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44004,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100237, 'MARTINEZ', 'GABRIELA', '44910918', '2013-04-18 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44005,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100382, '2022-08-30 12:25:09.000000', 100237, 1, 1)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44006,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100237, 100237, 37, 3, '00', '2022-08-30 12:25:09.000000', '', 12)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44007,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, cesareas, planificado, fum, fpp) VALUES (100230, 100237, 100382, 16, '2019-09-18 00:00:00.000000', 2, 1, 1, '2001-07-22 00:00:00.000000', '2007-04-23 00:00:00.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44008,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100237, 100382, 2, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44009,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100237, 100382, 3, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44010,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100237, 100382, 1, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44011,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100237, 100382, 4, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44012,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100237, 100382, 1, 3, '2022-08-30 12:27:59.000000', 3)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44013,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100237, 100382, 2, 3, '2022-08-30 12:27:59.000000', 2)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44014,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100237, 100382, 4, 3, '2022-08-30 12:27:59.000000', 1)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44015,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100237, 100382, 5, 3, '2022-08-30 12:27:59.000000', 4)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44016,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100237, 100382, 6, 3, '2022-08-30 12:27:59.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44017,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100237, 100382, 7, 3, '2022-08-30 12:27:59.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44018,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100237, 100382, 9, 3, '2022-08-30 12:27:59.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44019,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100325, 100382, 1101, 'S', ' ', 'N', 'N', 100, 70, 'N', '')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44020,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100238, 'CECILIO', 'ANAHI KAREN', '44499483', '2004-01-02 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44021,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100383, '2022-08-30 12:38:03.000000', 100238, 1, 1)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44022,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100238, 100238, 37, 3, '00', '2022-08-30 12:38:03.000000', '', 12)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44023,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, abortos, planificado, fum, fpp) VALUES (100231, 100232, 100377, 16, 2, 1, 1, '2019-03-22 00:00:00.000000', '2024-12-22 00:00:00.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44024,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100232, 100377, 2, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44025,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100232, 100377, 3, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44026,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100232, 100377, 1, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44027,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100232, 100377, 4, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44028,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100232, 100377, 1, 3, '2022-08-30 12:47:44.000000', 3)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44029,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100232, 100377, 2, 3, '2022-08-30 12:47:44.000000', 2)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44030,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100232, 100377, 4, 3, '2022-08-30 12:47:44.000000', 1)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44031,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100232, 100377, 5, 3, '2022-08-30 12:47:44.000000', 4)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44032,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100232, 100377, 6, 3, '2022-08-30 12:47:44.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44033,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100232, 100377, 7, 3, '2022-08-30 12:47:44.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44034,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100232, 100377, 9, 3, '2022-08-30 12:47:44.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44035,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100326, 100377, 179, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44036,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100232, 100238, 100383, 14, '2018-03-21 00:00:00.000000', 2, 1, 1, '2010-04-21 00:00:00.000000', '2015-01-22 00:00:00.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44037,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100238, 100383, 2, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44038,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100238, 100383, 3, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44039,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100238, 100383, 1, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44040,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100238, 100383, 4, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44041,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100238, 100383, 1, 3, '2022-08-30 13:03:45.000000', 3)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44042,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100238, 100383, 2, 3, '2022-08-30 13:03:45.000000', 2)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44043,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100238, 100383, 4, 3, '2022-08-30 13:03:45.000000', 1)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44044,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100238, 100383, 5, 3, '2022-08-30 13:03:45.000000', 4)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44045,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100238, 100383, 6, 3, '2022-08-30 13:03:45.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44046,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100238, 100383, 7, 3, '2022-08-30 13:03:45.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44047,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100238, 100383, 9, 3, '2022-08-30 13:03:45.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44048,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100327, 100383, 644, 'S', ' ', 'N', 'N', 110, 70, 'N', '')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44049,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100239, 'CARRIZO', 'NELCINA', '45056308', '1992-10-29 00:00:00.000000', 2, 12, 'F')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44050,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100384, '2022-08-30 13:13:58.000000', 100239, 1, 1)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44051,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100239, 100239, 37, 3, '00', '2022-08-30 13:13:58.000000', '', 12)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44052,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100233, 100239, 100384, 21, '2018-06-03 00:00:00.000000', 4, 3, 1, '2022-05-20 00:00:00.000000', '2023-02-24 00:00:00.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44053,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100239, 100384, 2, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44054,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100239, 100384, 3, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44055,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100239, 100384, 1, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44056,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100239, 100384, 4, 'N')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44057,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100239, 100384, 1, 2, '2022-08-30 13:16:22.000000', 3)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44058,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100239, 100384, 2, 2, '2022-08-30 13:16:22.000000', 2)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44059,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100239, 100384, 4, 2, '2022-08-30 13:16:22.000000', 1)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44060,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100239, 100384, 5, 2, '2022-08-30 13:16:22.000000', 4)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44061,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100239, 100384, 6, 2, '2022-08-30 13:16:22.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44062,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100239, 100384, 7, 2, '2022-08-30 13:16:22.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44063,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100239, 100384, 9, 2, '2022-08-30 13:16:22.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44064,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100328, 100384, 14, 'S', ' ', 'N', 'N', 100, 70, 'N', '')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44065,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100385, '2022-08-30 15:59:10.000000', 200066, 3, 1)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44066,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100240, 'GOMEZ', 'OTILIA', '5197673', '2002-01-24 00:00:00.000000', 2, 177, 'F')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44067,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100386, '2022-08-30 16:03:17.000000', 100240, 1, 1)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44068,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100240, 100240, 4, 2, '00', '2022-08-30 16:03:17.000000', '', 177)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44069,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (100234, 100240, 100386, 20, 1, 1, '2008-12-21 00:00:00.000000', '2014-09-22 00:00:00.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44070,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200066, 100385, 2, 'S')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44071,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200066, 100385, 3, 'C')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44072,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200066, 100385, 4, 'C')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44073,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200066, 100385, 13, 3, '2022-08-30 16:04:46.000000', 3)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44074,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200066, 100385, 18, 3, '2022-08-30 16:04:46.000000', 3)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44075,
                    "INSERT INTO etmis_personas (id_persona, id_etmi, id_control) VALUES (200066, 3, 100385)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44076,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (200066, 100385, 1, 3, '2022-08-30 16:04:46.000000', '2022-08-30 16:04:46.000000', 'P', 3)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44077,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200066, 100385, 2, 3, '2022-08-30 16:04:46.000000', 2)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44078,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200066, 100385, 4, 3, '2022-08-30 16:04:46.000000', 1)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44079,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200066, 100385, 5, 3, '2022-08-30 16:04:46.000000', 4)",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44080,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200066, 100385, 6, 3, '2022-08-30 16:04:46.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44081,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200066, 100385, 7, 3, '2022-08-30 16:04:46.000000')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44082,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100329, 100385, 30, 'S', ' ', 'N', 'N', 130, 80, 'N', '')",
                    "2022-08-30 23:28:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44083,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100240, 100386, 2, 'S')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44084,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100240, 100386, 3, 'C')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44085,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100240, 100386, 1, 'S')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44086,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100240, 100386, 4, 'C')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44087,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100240, 100386, 1, 3, '2022-08-30 16:05:49.000000', 3)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44088,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100240, 100386, 2, 3, '2022-08-30 16:05:49.000000', 2)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44089,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100240, 100386, 4, 3, '2022-08-30 16:05:49.000000', 1)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44090,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100240, 100386, 5, 3, '2022-08-30 16:05:49.000000', 4)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44091,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100240, 100386, 6, 3, '2022-08-30 16:05:49.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44092,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100240, 100386, 7, 3, '2022-08-30 16:05:49.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44093,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100240, 100386, 9, 3, '2022-08-30 16:05:49.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44094,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100330, 100386, 714, 'S', ' ', 'N', 'N', 100, 70, 'N', '')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44095,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100241, 'ROJAS', 'DAISY', '6580818', '2002-05-10 00:00:00.000000', 1, 177, 'F')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44096,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100387, '2022-08-30 16:10:54.000000', 100241, 1, 1)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44097,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100241, 100241, 4, 2, '00', '2022-08-30 16:10:54.000000', '', 177)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44098,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100242, 'SALVATIERRA', 'WILMA', '3934058', '1982-10-20 00:00:00.000000', 2, 177, 'F')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44099,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100388, '2022-08-30 16:14:27.000000', 100242, 1, 1)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44100,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100242, 100242, 3, 2, '00', '2022-08-30 16:14:27.000000', '', 177)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44101,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, cesareas, abortos, planificado, fum, fpp) VALUES (100235, 100242, 100388, 17, '2023-12-11 00:00:00.000000', 5, 1, 2, 1, 1, '2022-07-06 00:00:00.000000', '2023-04-12 00:00:00.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44102,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100242, 100388, 2, 'S')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44103,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100242, 100388, 3, 'C')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44104,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100242, 100388, 1, 'S')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44105,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100242, 100388, 4, 'C')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44106,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100242, 100388, 13, 1, '2022-08-30 16:23:11.000000', 3)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44107,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100242, 100388, 18, 1, '2022-08-30 16:23:11.000000', 3)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44108,
                    "INSERT INTO etmis_personas (id_persona, id_etmi, id_control) VALUES (100242, 3, 100388)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44109,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100242, 100388, 1, 1, '2022-08-30 16:23:11.000000', '2022-08-30 16:23:11.000000', 'P', 3)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44110,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100242, 100388, 2, 1, '2022-08-30 16:23:11.000000', 2)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44111,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100242, 100388, 4, 1, '2022-08-30 16:23:11.000000', 1)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44112,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100242, 100388, 5, 1, '2022-08-30 16:23:11.000000', 4)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44113,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100242, 100388, 6, 1, '2022-08-30 16:23:11.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44114,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100242, 100388, 7, 1, '2022-08-30 16:23:11.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44115,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100242, 100388, 9, 1, '2022-08-30 16:23:11.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44116,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100331, 100388, 7, 'S', ' ', 'N', 'N', 140, 100, 'N', '')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44117,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (100236, 100241, 100387, 20, 1, 1, '2001-04-22 00:00:00.000000', '2006-01-23 00:00:00.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44118,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100243, 'BARRIOS', 'GLORIA', '8187159', '2030-05-04 00:00:00.000000', 2, 177, 'F')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44119,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100389, '2022-08-30 16:27:53.000000', 100243, 1, 1)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44120,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100243, 100243, 4, 2, '00', '2022-08-30 16:27:53.000000', '', 177)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44121,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (100237, 100243, 100389, 18, 1, 1, '2005-04-22 00:00:00.000000', '2010-01-23 00:00:00.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44122,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100243, 100389, 2, 'S')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44123,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100243, 100389, 3, 'C')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44124,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100243, 100389, 1, 'S')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44125,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100243, 100389, 4, 'C')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44126,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100243, 100389, 1, 3, '2022-08-30 16:29:32.000000', 3)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44127,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100243, 100389, 2, 3, '2022-08-30 16:29:32.000000', 2)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44128,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100243, 100389, 4, 3, '2022-08-30 16:29:32.000000', 1)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44129,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100243, 100389, 5, 3, '2022-08-30 16:29:32.000000', 4)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44130,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100243, 100389, 6, 3, '2022-08-30 16:29:32.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44131,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100243, 100389, 7, 3, '2022-08-30 16:29:32.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44132,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100243, 100389, 9, 3, '2022-08-30 16:29:32.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44133,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100332, 100389, 905, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44134,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100244, 'PEREZ', 'MARIA NATIVIDAD', '4680575', '1991-09-08 00:00:00.000000', 2, 177, 'F')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44135,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100390, '2022-08-30 16:33:30.000000', 100244, 1, 1)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44136,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100244, 100244, 4, 2, '00', '2022-08-30 16:33:30.000000', '', 177)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44137,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100238, 100244, 100390, 0, '2013-03-21 00:00:00.000000', 5, 4, 1, '2020-01-22 00:00:00.000000', '2027-10-22 00:00:00.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44138,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100244, 100390, 2, 'S')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44139,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100244, 100390, 3, 'C')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44140,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100244, 100390, 1, 'S')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44141,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100244, 100390, 4, 'C')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44142,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100244, 100390, 1, 3, '2022-08-30 16:35:32.000000', 3)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44143,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100244, 100390, 2, 3, '2022-08-30 16:35:32.000000', 2)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44144,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100244, 100390, 4, 3, '2022-08-30 16:35:32.000000', 1)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44145,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100244, 100390, 5, 3, '2022-08-30 16:35:32.000000', 4)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44146,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100244, 100390, 6, 3, '2022-08-30 16:35:32.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44147,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100244, 100390, 7, 3, '2022-08-30 16:35:32.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44148,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100244, 100390, 9, 3, '2022-08-30 16:35:32.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44149,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100333, 100390, 135, 'S', ' ', 'N', 'N', 100, 70, 'N', '')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44150,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100245, 'TIMA', 'DALILA EPIFANIA', '6872628', '2006-01-02 00:00:00.000000', 2, 177, 'F')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44151,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100391, '2022-08-30 16:39:51.000000', 100245, 1, 1)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44152,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100245, 100245, 4, 2, '00', '2022-08-30 16:39:51.000000', '', 177)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44153,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (100239, 100245, 100391, 16, 1, 0, '2022-12-21 00:00:00.000000', '2028-09-22 00:00:00.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44154,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100245, 100391, 2, 'S')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44155,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100245, 100391, 3, 'C')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44156,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100245, 100391, 1, 'S')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44157,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100245, 100391, 4, 'C')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44158,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100245, 100391, 1, 2, '2022-08-30 16:41:34.000000', 3)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44159,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100245, 100391, 2, 2, '2022-08-30 16:41:34.000000', 2)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44160,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100245, 100391, 4, 2, '2022-08-30 16:41:34.000000', 1)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44161,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100245, 100391, 5, 2, '2022-08-30 16:41:34.000000', 4)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44162,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100245, 100391, 6, 2, '2022-08-30 16:41:34.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44163,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100245, 100391, 7, 2, '2022-08-30 16:41:34.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44164,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100334, 100391, 16, 'S', ' ', 'N', 'N', 120, 60, 'N', '')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44165,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100392, '2022-08-30 16:43:24.000000', 200065, 3, 1)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44166,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200065, 100392, 2, 'S')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44167,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200065, 100392, 3, 'C')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44168,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200065, 100392, 4, 'N')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44169,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200065, 100392, 6, 2, '2022-08-30 16:44:34.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44170,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200065, 100392, 7, 2, '2022-08-30 16:44:34.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44171,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100335, 100392, 27, 'S', ' ', 'N', 'N', 120, 60, 'N', '')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44172,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100246, 'DIAZ LOPEZ', 'MARIA LOURDES', '4680517', '1994-02-11 00:00:00.000000', 2, 177, 'F')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44173,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100393, '2022-08-30 16:47:17.000000', 100246, 1, 1)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44174,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100246, 100246, 4, 2, '00', '2022-08-30 16:47:17.000000', '', 177)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44175,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100240, 100246, 100393, 18, '2012-01-08 00:00:00.000000', 2, 1, 1, '2027-06-22 00:00:00.000000', '2003-04-23 00:00:00.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44176,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100246, 100393, 2, 'S')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44177,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100246, 100393, 3, 'N')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44178,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100246, 100393, 1, 'N')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44179,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100246, 100393, 4, 'N')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44180,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100246, 100393, 6, 3, '2022-08-30 16:50:35.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44181,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100246, 100393, 7, 3, '2022-08-30 16:50:35.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44182,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100246, 100393, 9, 3, '2022-08-30 16:50:35.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44183,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100336, 100393, 250, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44184,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100394, '2022-08-30 16:51:40.000000', 200069, 3, 1)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44185,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200069, 100394, 2, 'S')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44186,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200069, 100394, 3, 'C')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44187,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200069, 100394, 4, 'N')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44188,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200069, 100394, 1, 3, '2022-08-30 16:53:17.000000', 3)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44189,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200069, 100394, 2, 3, '2022-08-30 16:53:17.000000', 2)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44190,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200069, 100394, 4, 3, '2022-08-30 16:53:17.000000', 1)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44191,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200069, 100394, 5, 3, '2022-08-30 16:53:17.000000', 4)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44192,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200069, 100394, 6, 3, '2022-08-30 16:53:17.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44193,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200069, 100394, 7, 3, '2022-08-30 16:53:17.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44194,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100337, 100394, 38, 'S', ' ', 'N', 'N', 120, 80, 'N', '')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44195,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100395, '2022-08-30 16:54:00.000000', 200072, 3, 1)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44196,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200072, 100395, 2, 'S')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44197,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200072, 100395, 3, 'P')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44198,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200072, 100395, 4, 'N')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44199,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200072, 100395, 1, 3, '2022-08-30 16:56:32.000000', 3)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44200,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200072, 100395, 2, 3, '2022-08-30 16:56:32.000000', 2)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44201,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200072, 100395, 4, 3, '2022-08-30 16:56:32.000000', 1)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44202,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200072, 100395, 5, 3, '2022-08-30 16:56:32.000000', 4)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44203,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200072, 100395, 6, 3, '2022-08-30 16:56:32.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44204,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200072, 100395, 7, 3, '2022-08-30 16:56:32.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44205,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200072, 100395, 9, 3, '2022-08-30 16:56:32.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44206,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100338, 100395, 766, 'S', ' ', 'N', 'N', 110, 70, 'N', '')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44207,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100247, 'DURAN MIGUEL', 'PELAGIA', '4424317', '1981-03-22 00:00:00.000000', 2, 177, 'F')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44208,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100396, '2022-08-30 16:59:39.000000', 100247, 1, 1)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44209,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100247, 100247, 4, 2, '00', '2022-08-30 16:59:39.000000', '', 177)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44210,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100241, 100247, 100396, 0, '2014-03-08 00:00:00.000000', 4, 3, 1, '2022-06-22 00:00:00.000000', '2029-03-23 00:00:00.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44211,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100248, 'DURAN MIGUEL', 'PELAGIA', '4424317', '1981-03-22 00:00:00.000000', 2, 177, 'F')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44212,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100397, '2022-08-30 17:04:53.000000', 100248, 1, 1)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44213,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100248, 100248, 4, 2, '00', '2022-08-30 17:04:53.000000', '', 177)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44214,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100242, 100248, 100397, 21, '2014-03-08 00:00:00.000000', 4, 3, 1, '2022-06-22 00:00:00.000000', '2029-03-23 00:00:00.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44215,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100248, 100397, 2, 'N')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44216,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100248, 100397, 3, 'N')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44217,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100248, 100397, 1, 'N')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44218,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100248, 100397, 4, 'N')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44219,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100248, 100397, 1, 1, '2022-08-30 17:06:36.000000', 3)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44220,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100248, 100397, 2, 1, '2022-08-30 17:06:36.000000', 2)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44221,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100248, 100397, 4, 1, '2022-08-30 17:06:36.000000', 1)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44222,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100248, 100397, 5, 1, '2022-08-30 17:06:36.000000', 4)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44223,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100248, 100397, 6, 1, '2022-08-30 17:06:36.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44224,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100248, 100397, 7, 1, '2022-08-30 17:06:36.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44225,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100248, 100397, 9, 1, '2022-08-30 17:06:36.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44226,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100339, 100397, 9, 'S', ' ', 'N', 'N', 120, 80, 'N', '')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44227,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100398, '2022-08-30 17:08:16.000000', 200067, 3, 1)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44228,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200067, 100398, 2, 'S')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44229,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200067, 100398, 3, 'C')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44230,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200067, 100398, 4, 'C')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44231,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200067, 100398, 6, 2, '2022-08-30 17:11:13.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44232,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200067, 100398, 7, 2, '2022-08-30 17:11:13.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44233,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100340, 100398, 22, 'S', ' ', 'N', 'N', 120, 90, 'N', '')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44234,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100399, '2022-08-30 17:13:31.000000', 200076, 3, 1)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44235,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100400, '2022-08-30 17:17:31.000000', 200074, 3, 1)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44236,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200074, 100400, 2, 'S')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44237,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200074, 100400, 3, 'C')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44238,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200074, 100400, 4, 'C')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44239,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200074, 100400, 1, 3, '2022-08-30 17:19:29.000000', 3)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44240,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200074, 100400, 2, 3, '2022-08-30 17:19:29.000000', 2)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44241,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200074, 100400, 4, 3, '2022-08-30 17:19:29.000000', 1)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44242,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200074, 100400, 5, 3, '2022-08-30 17:19:29.000000', 4)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44243,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200074, 100400, 6, 3, '2022-08-30 17:19:29.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44244,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200074, 100400, 7, 3, '2022-08-30 17:19:29.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44245,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100341, 100400, 33, 'S', ' ', 'N', 'N', 120, 70, 'N', '')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44246,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100249, 'RODAS', 'GUILLERMINA', '7836179', '1997-06-22 00:00:00.000000', 2, 177, 'F')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44247,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100401, '2022-08-30 17:24:57.000000', 100249, 1, 1)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44248,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100249, 100249, 4, 2, '00', '2022-08-30 17:24:57.000000', '', 177)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44249,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100250, 'FLORES', 'VERONICA', '5197608', '1988-06-12 00:00:00.000000', 2, 177, 'F')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44250,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100402, '2022-08-30 17:29:51.000000', 100250, 1, 1)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44251,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100250, 100250, 4, 2, '00', '2022-08-30 17:29:51.000000', '', 177)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44252,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100243, 100250, 100402, 19, '2013-01-06 00:00:00.000000', 3, 2, 1, '2022-08-30 00:00:00.000000', '2023-06-06 00:00:00.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44253,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100250, 100402, 2, 'S')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44254,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100250, 100402, 3, 'N')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44255,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100250, 100402, 1, 'N')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44256,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100250, 100402, 4, 'N')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44257,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100250, 100402, 4, 1, '2022-08-30 17:33:31.000000', 1)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44258,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100250, 100402, 6, 1, '2022-08-30 17:33:31.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44259,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100250, 100402, 7, 1, '2022-08-30 17:33:31.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44260,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100250, 100402, 9, 1, '2022-08-30 17:33:31.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44261,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100342, 100402, 0, 'S', ' ', 'N', 'N', 130, 70, 'N', '')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44262,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100403, '2022-08-30 17:34:29.000000', 200078, 3, 1)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44263,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200078, 100403, 2, 'S')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44264,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200078, 100403, 3, 'C')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44265,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200078, 100403, 4, 'C')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44266,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200078, 100403, 1, 3, '2022-08-30 17:36:32.000000', 3)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44267,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200078, 100403, 2, 3, '2022-08-30 17:36:32.000000', 2)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44268,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200078, 100403, 4, 3, '2022-08-30 17:36:32.000000', 1)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44269,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (200078, 100403, 5, 3, '2022-08-30 17:36:32.000000', 4)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44270,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200078, 100403, 6, 3, '2022-08-30 17:36:32.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44271,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (200078, 100403, 7, 3, '2022-08-30 17:36:32.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44272,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100343, 100403, 34, 'S', ' ', 'N', 'N', 100, 70, 'N', '')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44273,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100251, 'IBANEZ ALVAREZ', 'NORMA', '3768933', '1986-02-28 00:00:00.000000', 2, 177, 'F')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44274,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100404, '2022-08-30 17:40:21.000000', 100251, 1, 1)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44275,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100251, 100251, 4, 2, '00', '2022-08-30 17:40:21.000000', '', 177)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44276,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, cesareas, planificado, fum, fpp) VALUES (100244, 100251, 100404, 0, '2012-01-12 00:00:00.000000', 5, 2, 2, 0, '2001-02-22 00:00:00.000000', '2008-11-22 00:00:00.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44277,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100252, 'IBANEZ ALVAREZ', 'NORMA', '3768933', '1986-02-28 00:00:00.000000', 2, 177, 'F')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44278,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100405, '2022-08-30 17:49:47.000000', 100252, 1, 1)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44279,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100252, 100252, 4, 2, '00', '2022-08-30 17:49:47.000000', '', 177)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44280,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, cesareas, planificado, fum, fpp) VALUES (100245, 100252, 100405, 19, '2012-01-12 00:00:00.000000', 5, 2, 2, 1, '2001-02-22 00:00:00.000000', '2008-11-22 00:00:00.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44281,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100252, 100405, 2, 'S')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44282,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100252, 100405, 3, 'C')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44283,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100252, 100405, 1, 'N')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44284,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100252, 100405, 4, 'N')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44285,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100252, 100405, 1, 3, '2022-08-30 17:51:40.000000', 3)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44286,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100252, 100405, 2, 3, '2022-08-30 17:51:40.000000', 2)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44287,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100252, 100405, 4, 3, '2022-08-30 17:51:40.000000', 1)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44288,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100252, 100405, 5, 3, '2022-08-30 17:51:40.000000', 4)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44289,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100252, 100405, 6, 3, '2022-08-30 17:51:40.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44290,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100252, 100405, 7, 3, '2022-08-30 17:51:40.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44291,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100252, 100405, 9, 3, '2022-08-30 17:51:40.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44292,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100344, 100405, 1122, 'S', ' ', 'N', 'N', 130, 80, 'N', '')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44293,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100253, 'PEREZ', 'MARIA CONCEPCION', '6895648', '1990-11-27 00:00:00.000000', 2, 177, 'F')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44294,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100406, '2022-08-30 18:07:43.000000', 100253, 1, 1)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44295,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100253, 100253, 4, 2, '00', '2022-08-30 18:07:43.000000', '', 177)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44296,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, cesareas, planificado, fum, fpp) VALUES (100246, 100253, 100406, 16, '2019-08-23 00:00:00.000000', 6, 4, 1, 1, '2019-06-09 00:00:00.000000', '2020-03-15 00:00:00.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44297,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100253, 100406, 2, 'S')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44298,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100253, 100406, 3, 'C')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44299,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100253, 100406, 1, 'S')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44300,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100253, 100406, 4, 'C')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44301,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100253, 100406, 1, 3, '2022-08-30 18:11:29.000000', 3)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44302,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100253, 100406, 2, 3, '2022-08-30 18:11:29.000000', 2)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44303,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100253, 100406, 4, 3, '2022-08-30 18:11:29.000000', 1)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44304,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100253, 100406, 5, 3, '2022-08-30 18:11:29.000000', 4)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44305,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100253, 100406, 6, 3, '2022-08-30 18:11:29.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44306,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100253, 100406, 7, 3, '2022-08-30 18:11:29.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44307,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100253, 100406, 9, 3, '2022-08-30 18:11:29.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44308,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100345, 100406, 168, 'S', ' ', 'N', 'N', 120, 70, 'N', '')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44309,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100254, 'DIONISIO', 'MARIA MABEL', '5197635', '2000-09-07 00:00:00.000000', 2, 177, 'F')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44310,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100407, '2022-08-30 18:12:37.000000', 100254, 1, 1)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44311,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100254, 100254, 4, 2, '00', '2022-08-30 18:12:37.000000', '', 177)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44312,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, cesareas, planificado, fum, fpp) VALUES (100247, 100254, 100407, 17, '2024-07-18 00:00:00.000000', 2, 1, 1, '2008-07-22 00:00:00.000000', '2014-04-23 00:00:00.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44313,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100254, 100407, 2, 'S')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44314,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100254, 100407, 3, 'C')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44315,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100254, 100407, 1, 'S')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44316,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100254, 100407, 4, 'C')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44317,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100254, 100407, 1, 3, '2022-08-30 18:14:37.000000', 3)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44318,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100254, 100407, 2, 3, '2022-08-30 18:14:37.000000', 2)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44319,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100254, 100407, 4, 3, '2022-08-30 18:14:37.000000', 1)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44320,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100254, 100407, 5, 3, '2022-08-30 18:14:37.000000', 4)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44321,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100254, 100407, 6, 3, '2022-08-30 18:14:37.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44322,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100254, 100407, 7, 3, '2022-08-30 18:14:37.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44323,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100254, 100407, 9, 3, '2022-08-30 18:14:37.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44324,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100346, 100407, 736, 'S', ' ', 'N', 'N', 120, 70, 'N', '')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44325,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100255, 'ROJAS', 'DAISY', '6580818', '2002-05-10 00:00:00.000000', 2, 177, 'F')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44326,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, georeferencia) VALUES (100408, '2022-08-30 23:15:01.000000', 100255, 1, 1, '-22.2729253,-62.7170203')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44327,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100255, 100255, 4, 2, '00', '2022-08-30 23:15:01.000000', '-22.2729253,-62.7170203', 177)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44328,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (100248, 100255, 100408, 20, 1, 1, '2022-04-22 00:00:00.000000', '2027-01-23 00:00:00.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44329,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100255, 100408, 2, 'N')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44330,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100255, 100408, 3, 'N')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44331,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100255, 100408, 1, 'N')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44332,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100255, 100408, 4, 'N')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44333,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100255, 100408, 1, 2, '2022-08-30 23:17:54.000000', 3)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44334,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100255, 100408, 2, 2, '2022-08-30 23:17:54.000000', 2)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44335,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100255, 100408, 4, 2, '2022-08-30 23:17:54.000000', 1)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44336,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100255, 100408, 5, 2, '2022-08-30 23:17:54.000000', 4)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44337,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100255, 100408, 6, 2, '2022-08-30 23:17:54.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44338,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100255, 100408, 7, 2, '2022-08-30 23:17:54.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44339,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100255, 100408, 9, 2, '2022-08-30 23:17:54.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44340,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100347, 100408, 18, 'S', ' ', 'N', 'N', 110, 60, 'N', '')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44341,
                    "SELECT id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo, madre, alta, nacido_vivo FROM personas WHERE id_persona = '100242'",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44342,
                    "SELECT id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais FROM ubicaciones WHERE id_ubicacion = '100242'",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44343,
                    "UPDATE antecedentes SET planificado=0 WHERE antecedentes.id_antecedente=100235",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44344,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100256, 'RODAS', 'GUILLERMINA', '7836179', '1997-06-22 00:00:00.000000', 2, 177, 'F')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44345,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, georeferencia) VALUES (100409, '2022-08-30 23:23:39.000000', 100256, 1, 1, '-22.2725026,-62.7125106')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44346,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100256, 100256, 4, 2, '00', '2022-08-30 23:23:39.000000', '-22.2725026,-62.7125106', 177)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44347,
                    "SELECT id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo, madre, alta, nacido_vivo FROM personas WHERE id_persona = '100249'",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44348,
                    "SELECT id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais FROM ubicaciones WHERE id_ubicacion = '100249'",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44349,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, cesareas, abortos, planificado, fum, fpp) VALUES (100249, 100256, 100409, 19, '2023-12-11 00:00:00.000000', 5, 1, 2, 1, 1, '2006-07-22 00:00:00.000000', '2012-04-23 00:00:00.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44350,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100256, 100409, 2, 'N')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44351,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100256, 100409, 3, 'N')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44352,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100256, 100409, 1, 'N')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44353,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100256, 100409, 4, 'N')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44354,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100256, 100409, 1, 3, '2022-08-30 23:27:05.000000', 3)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44355,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100256, 100409, 2, 3, '2022-08-30 23:27:05.000000', 2)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44356,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100256, 100409, 4, 3, '2022-08-30 23:27:05.000000', 1)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44357,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100256, 100409, 5, 3, '2022-08-30 23:27:05.000000', 4)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44358,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100256, 100409, 6, 3, '2022-08-30 23:27:05.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44359,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100256, 100409, 7, 3, '2022-08-30 23:27:05.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44360,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100256, 100409, 9, 3, '2022-08-30 23:27:05.000000')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44361,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100348, 100409, 840, 'S', ' ', 'N', 'N', 140, 100, 'N', '')",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44362,
                    "SELECT id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo, madre, alta, nacido_vivo FROM personas WHERE id_persona = 200067",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44363,
                    "INSERT INTO antecedentes_apps (id_antecedente, id_app) VALUES (200064, 2)",
                    "2022-08-30 23:28:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44364,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100257, 'GOMEZ FELIX', 'ANGELICA', '44328158', '1995-07-09 00:00:00.000000', 1, 12, 'F')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44365,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100410, '2022-08-31 09:54:30.000000', 100257, 1, 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44366,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100257, 100257, 31, 3, '00', '2022-08-31 09:54:30.000000', '', 12)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44367,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, abortos, planificado, fum, fpp) VALUES (100250, 100257, 100410, 17, '2019-12-20 00:00:00.000000', 9, 6, 2, 0, '2027-01-22 00:00:00.000000', '2003-11-22 00:00:00.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44368,
                    "INSERT INTO antecedentes_apps (id_antecedente, id_app) VALUES (100250, 4)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44369,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100258, 'PEREZ', 'GABRIELA', '45262354', '2004-02-05 00:00:00.000000', 1, 12, 'F')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44370,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100411, '2022-08-31 10:03:16.000000', 100258, 1, 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44371,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100258, 100258, 31, 3, '00', '2022-08-31 10:03:16.000000', '', 12)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44372,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100412, '2022-08-31 10:09:38.000000', 379, 2, 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44373,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100413, '2022-08-31 10:15:44.000000', 375, 2, 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44374,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100259, 'GARCIA', 'LOURDES', '45365545', '2004-08-12 00:00:00.000000', 1, 12, 'F')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44375,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100414, '2022-08-31 10:22:13.000000', 100259, 1, 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44376,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100259, 100259, 31, 3, '00', '2022-08-31 10:22:13.000000', '', 12)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44377,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (100251, 100259, 100414, 17, 1, 0, '2002-03-22 00:00:00.000000', '2007-12-22 00:00:00.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44378,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100260, 'RODRIGUEZ', 'REINALDA', '52346232', '2029-06-05 00:00:00.000000', 2, 12, 'F')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44379,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100415, '2022-08-31 10:37:26.000000', 100260, 1, 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44380,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100260, 100260, 31, 3, '00', '2022-08-31 10:37:26.000000', '', 12)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44381,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100261, 'ARIAS', 'MARCIANA', '42256860', '1999-12-17 00:00:00.000000', 2, 12, 'F')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44382,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100416, '2022-08-31 10:41:25.000000', 100261, 1, 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44383,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100261, 100261, 31, 3, '00', '2022-08-31 10:41:25.000000', '', 12)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44384,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, cesareas, planificado, fum, fpp) VALUES (100252, 100261, 100416, 17, '2014-03-21 00:00:00.000000', 3, 1, 1, 0, '2006-05-22 00:00:00.000000', '2010-02-23 00:00:00.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44385,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100417, '2022-08-31 10:47:04.000000', 368, 2, 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44386,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100257, 100410, 2, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44387,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100257, 100410, 3, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44388,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100257, 100410, 1, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44389,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100257, 100410, 4, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44390,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100257, 100410, 1, 3, '2022-08-31 10:49:44.000000', 3)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44391,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100257, 100410, 2, 3, '2022-08-31 10:49:44.000000', 2)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44392,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100257, 100410, 4, 3, '2022-08-31 10:49:44.000000', 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44393,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100257, 100410, 5, 3, '2022-08-31 10:49:44.000000', 4)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44394,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100257, 100410, 6, 3, '2022-08-31 10:49:44.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44395,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100257, 100410, 7, 3, '2022-08-31 10:49:44.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44396,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100257, 100410, 9, 3, '2022-08-31 10:49:44.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44397,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100349, 100410, 229, 'S', ' ', 'N', 'N', 100, 70, 'N', '')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44398,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100253, 100258, 100411, 16, '2017-01-22 00:00:00.000000', 3, 2, 0, '2031-05-22 00:00:00.000000', '2007-03-23 00:00:00.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44399,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100258, 100411, 2, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44400,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100258, 100411, 3, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44401,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100258, 100411, 1, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44402,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100258, 100411, 4, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44403,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100258, 100411, 6, 3, '2022-08-31 10:52:39.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44404,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100258, 100411, 7, 3, '2022-08-31 10:52:39.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44405,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100350, 100411, 455, 'S', ' ', 'N', 'N', 110, 60, 'N', '')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44406,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (379, 100412, 2, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44407,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (379, 100412, 3, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44408,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (379, 100412, 4, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44409,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (379, 100412, 6, 2, '2022-08-31 10:56:08.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44410,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (379, 100412, 7, 2, '2022-08-31 10:56:08.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44411,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100351, 100412, 25, 'S', ' ', 'N', 'N', 90, 60, 'N', '')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44412,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (375, 100413, 2, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44413,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (375, 100413, 3, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44414,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (375, 100413, 4, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44415,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (375, 100413, 1, 2, '2022-08-31 10:56:34.000000', 3)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44416,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (375, 100413, 2, 2, '2022-08-31 10:56:34.000000', 2)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44417,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (375, 100413, 4, 2, '2022-08-31 10:56:34.000000', 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44418,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (375, 100413, 5, 2, '2022-08-31 10:56:34.000000', 4)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44419,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (375, 100413, 6, 2, '2022-08-31 10:56:34.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44420,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (375, 100413, 7, 2, '2022-08-31 10:56:34.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44421,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100352, 100413, 19, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44422,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100418, '2022-08-31 11:07:13.000000', 373, 2, 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44423,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100419, '2022-08-31 11:18:30.000000', 372, 2, 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44424,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100420, '2022-08-31 11:26:34.000000', 380, 2, 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44425,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100259, 100414, 2, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44426,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100259, 100414, 3, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44427,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100259, 100414, 1, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44428,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100259, 100414, 4, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44429,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100259, 100414, 1, 3, '2022-08-31 11:41:50.000000', 3)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44430,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100259, 100414, 2, 3, '2022-08-31 11:41:50.000000', 2)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44431,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100259, 100414, 4, 3, '2022-08-31 11:41:50.000000', 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44432,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100259, 100414, 5, 3, '2022-08-31 11:41:50.000000', 4)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44433,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100259, 100414, 6, 3, '2022-08-31 11:41:50.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44434,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100259, 100414, 7, 3, '2022-08-31 11:41:50.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44435,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100259, 100414, 9, 3, '2022-08-31 11:41:50.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44436,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100353, 100414, 1066, 'S', ' ', 'N', 'N', 90, 60, 'N', '')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44437,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100254, 100260, 100415, 15, '2004-04-20 00:00:00.000000', 2, 1, 0, '2012-06-22 00:00:00.000000', '2019-03-23 00:00:00.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44438,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (380, 100420, 2, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44439,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (380, 100420, 3, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44440,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (380, 100420, 4, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44441,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (380, 100420, 6, 3, '2022-08-31 11:45:46.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44442,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (380, 100420, 7, 3, '2022-08-31 11:45:46.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44443,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100354, 100420, 28, 'S', ' ', 'N', 'N', 90, 50, 'N', '')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44444,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100421, '2022-08-31 11:50:43.000000', 369, 2, 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44445,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (369, 100421, 2, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44446,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (369, 100421, 3, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44447,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (369, 100421, 4, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44448,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (369, 100421, 6, 2, '2022-08-31 11:52:49.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44449,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (369, 100421, 7, 2, '2022-08-31 11:52:49.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44450,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100355, 100421, 26, 'S', ' ', 'N', 'N', 80, 50, 'N', '')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44451,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100262, 'RIVERO', 'MARCELINA', '31892554', '1986-08-18 00:00:00.000000', 1, 12, 'F')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44452,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100422, '2022-08-31 11:57:13.000000', 100262, 1, 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44453,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100262, 100262, 31, 3, '00', '2022-08-31 11:57:13.000000', '', 12)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44454,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100255, 100262, 100422, 16, '2011-06-17 00:00:00.000000', 5, 4, 0, '2005-12-21 00:00:00.000000', '2011-09-22 00:00:00.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44455,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100260, 100415, 2, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44456,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100260, 100415, 3, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44457,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100260, 100415, 1, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44458,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100260, 100415, 4, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44459,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100260, 100415, 1, 3, '2022-08-31 12:04:54.000000', 3)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44460,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100260, 100415, 2, 3, '2022-08-31 12:04:54.000000', 2)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44461,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100260, 100415, 4, 3, '2022-08-31 12:04:54.000000', 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44462,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100260, 100415, 5, 3, '2022-08-31 12:04:54.000000', 4)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44463,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100260, 100415, 6, 3, '2022-08-31 12:04:54.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44464,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100260, 100415, 7, 3, '2022-08-31 12:04:54.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44465,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100260, 100415, 9, 3, '2022-08-31 12:04:54.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44466,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100356, 100415, 531, 'S', ' ', 'N', 'N', 110, 70, 'N', '')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44467,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100261, 100416, 2, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44468,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100261, 100416, 3, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44469,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100261, 100416, 1, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44470,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100261, 100416, 4, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44471,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100261, 100416, 1, 3, '2022-08-31 12:05:37.000000', 3)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44472,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100261, 100416, 2, 3, '2022-08-31 12:05:37.000000', 2)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44473,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100261, 100416, 4, 3, '2022-08-31 12:05:37.000000', 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44474,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100261, 100416, 5, 3, '2022-08-31 12:05:37.000000', 4)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44475,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100261, 100416, 6, 3, '2022-08-31 12:05:37.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44476,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100261, 100416, 7, 3, '2022-08-31 12:05:37.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44477,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100261, 100416, 9, 3, '2022-08-31 12:05:37.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44478,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100357, 100416, 849, 'S', ' ', 'N', 'N', 110, 70, 'N', '')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44479,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (368, 100417, 2, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44480,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (368, 100417, 3, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44481,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (368, 100417, 4, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44482,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (368, 100417, 6, 3, '2022-08-31 12:06:32.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44483,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (368, 100417, 7, 3, '2022-08-31 12:06:32.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44484,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100358, 100417, 31, 'S', ' ', 'N', 'N', 110, 70, 'N', '')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44485,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100423, '2022-08-31 12:08:52.000000', 378, 2, 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44486,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (373, 100418, 2, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44487,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (373, 100418, 3, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44488,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (373, 100418, 4, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44489,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (373, 100418, 6, 2, '2022-08-31 12:16:09.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44490,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (373, 100418, 7, 2, '2022-08-31 12:16:09.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44491,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100359, 100418, 22, 'S', ' ', 'N', 'N', 90, 60, 'N', '')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44492,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (372, 100419, 2, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44493,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (372, 100419, 3, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44494,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (372, 100419, 4, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44495,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (372, 100419, 1, 3, '2022-08-31 12:31:43.000000', 3)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44496,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (372, 100419, 2, 3, '2022-08-31 12:31:43.000000', 2)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44497,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (372, 100419, 4, 3, '2022-08-31 12:31:43.000000', 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44498,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (372, 100419, 5, 3, '2022-08-31 12:31:43.000000', 4)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44499,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (372, 100419, 6, 3, '2022-08-31 12:31:43.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44500,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (372, 100419, 7, 3, '2022-08-31 12:31:43.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44501,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100360, 100419, 35, 'S', ' ', 'N', 'N', 90, 60, 'N', '')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44502,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100262, 100422, 2, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44503,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100262, 100422, 3, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44504,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100262, 100422, 1, 'N')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44505,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100262, 100422, 4, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44506,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100262, 100422, 1, 3, '2022-08-31 12:56:52.000000', 3)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44507,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100262, 100422, 2, 3, '2022-08-31 12:56:52.000000', 2)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44508,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100262, 100422, 4, 3, '2022-08-31 12:56:52.000000', 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44509,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100262, 100422, 5, 3, '2022-08-31 12:56:52.000000', 4)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44510,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100262, 100422, 6, 3, '2022-08-31 12:56:52.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44511,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100262, 100422, 7, 3, '2022-08-31 12:56:52.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44512,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100262, 100422, 9, 3, '2022-08-31 12:56:52.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44513,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100361, 100422, 871, 'S', ' ', 'N', 'N', 100, 70, 'N', '')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44514,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (378, 100423, 2, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44515,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (378, 100423, 3, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44516,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (378, 100423, 4, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44517,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (378, 100423, 6, 2, '2022-08-31 12:57:32.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44518,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (378, 100423, 7, 2, '2022-08-31 12:57:32.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44519,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100362, 100423, 26, 'S', ' ', 'N', 'N', 110, 70, 'N', '')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44520,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100263, 'GONZALEZ', 'NERY ARACELI', '44175393', '2002-05-15 00:00:00.000000', 2, 12, 'F')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44521,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100424, '2022-08-31 13:04:03.000000', 100263, 1, 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44522,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100263, 100263, 31, 3, '00', '2022-08-31 13:04:03.000000', '', 12)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44523,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, abortos, planificado, fum, fpp) VALUES (100256, 100263, 100424, 19, '2001-02-21 00:00:00.000000', 2, 1, 1, '2003-01-22 00:00:00.000000', '2010-10-22 00:00:00.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44524,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100263, 100424, 2, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44525,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100263, 100424, 3, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44526,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100263, 100424, 1, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44527,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100263, 100424, 4, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44528,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100263, 100424, 1, 3, '2022-08-31 13:09:11.000000', 3)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44529,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100263, 100424, 2, 3, '2022-08-31 13:09:11.000000', 2)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44530,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100263, 100424, 4, 3, '2022-08-31 13:09:11.000000', 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44531,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100263, 100424, 5, 3, '2022-08-31 13:09:11.000000', 4)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44532,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100263, 100424, 6, 3, '2022-08-31 13:09:11.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44533,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100263, 100424, 7, 3, '2022-08-31 13:09:11.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44534,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100263, 100424, 9, 3, '2022-08-31 13:09:11.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44535,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100363, 100424, 1023, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44536,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100425, '2022-08-31 13:12:20.000000', 371, 2, 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44537,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100264, 'TORRES', 'CINTIA', '37602746', '1993-12-06 00:00:00.000000', 1, 12, 'F')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44538,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100426, '2022-08-31 13:15:59.000000', 100264, 1, 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44539,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100264, 100264, 31, 3, '00', '2022-08-31 13:15:59.000000', '', 12)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44540,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100257, 100264, 100426, 17, '2014-07-01 00:00:00.000000', 5, 4, 1, '2003-06-22 00:00:00.000000', '2010-03-23 00:00:00.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44541,
                    "INSERT INTO antecedentes_apps (id_antecedente, id_app) VALUES (100257, 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44542,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (371, 100425, 2, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44543,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (371, 100425, 3, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44544,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (371, 100425, 4, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44545,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (371, 100425, 1, 2, '2022-08-31 13:22:13.000000', 3)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44546,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (371, 100425, 2, 2, '2022-08-31 13:22:13.000000', 2)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44547,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (371, 100425, 4, 2, '2022-08-31 13:22:13.000000', 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44548,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (371, 100425, 5, 2, '2022-08-31 13:22:13.000000', 4)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44549,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (371, 100425, 6, 2, '2022-08-31 13:22:13.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44550,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (371, 100425, 7, 2, '2022-08-31 13:22:13.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44551,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100364, 100425, 17, 'S', ' ', 'N', 'N', 90, 60, 'N', '')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44552,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100265, 'PALMA', 'MABEL', '38033931', '1994-01-26 00:00:00.000000', 2, 12, 'F')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44553,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100427, '2022-08-31 13:27:14.000000', 100265, 1, 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44554,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100265, 100265, 31, 3, '00', '2022-08-31 13:27:14.000000', '', 12)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44555,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100258, 100265, 100427, 18, '2014-09-22 00:00:00.000000', 3, 2, 1, '2019-05-22 00:00:00.000000', '2023-02-23 00:00:00.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44556,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100264, 100426, 2, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44557,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100264, 100426, 3, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44558,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100264, 100426, 1, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44559,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100264, 100426, 4, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44560,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100264, 100426, 1, 3, '2022-08-31 13:38:46.000000', 3)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44561,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100264, 100426, 2, 3, '2022-08-31 13:38:46.000000', 2)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44562,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100264, 100426, 4, 3, '2022-08-31 13:38:46.000000', 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44563,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100264, 100426, 5, 3, '2022-08-31 13:38:46.000000', 4)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44564,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100264, 100426, 6, 3, '2022-08-31 13:38:46.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44565,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100264, 100426, 7, 3, '2022-08-31 13:38:46.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44566,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100264, 100426, 9, 3, '2022-08-31 13:38:46.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44567,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100365, 100426, 1001, 'S', ' ', 'N', 'N', 110, 70, 'N', '')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44568,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100265, 100427, 2, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44569,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100265, 100427, 3, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44570,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100265, 100427, 1, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44571,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100265, 100427, 4, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44572,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100265, 100427, 1, 3, '2022-08-31 13:39:46.000000', 3)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44573,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100265, 100427, 2, 3, '2022-08-31 13:39:46.000000', 2)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44574,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100265, 100427, 4, 3, '2022-08-31 13:39:46.000000', 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44575,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100265, 100427, 5, 3, '2022-08-31 13:39:46.000000', 4)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44576,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100265, 100427, 6, 3, '2022-08-31 13:39:46.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44577,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100265, 100427, 7, 3, '2022-08-31 13:39:46.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44578,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100265, 100427, 9, 3, '2022-08-31 13:39:46.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44579,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100366, 100427, 171, 'S', ' ', 'N', 'N', 90, 60, 'N', '')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44580,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100266, 'PEREZ', 'YOHANA', '42752218', '2000-07-13 00:00:00.000000', 2, 12, 'F')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44581,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, georeferencia) VALUES (100428, '2022-08-31 20:09:37.000000', 100266, 1, 1, '-22.2729241,-62.7170204')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44582,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100266, 100266, 31, 3, '00', '2022-08-31 20:09:37.000000', '-22.2729241,-62.7170204', 12)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44583,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100259, 100266, 100428, 19, '2028-09-19 00:00:00.000000', 2, 1, 0, '2013-05-22 00:00:00.000000', '2017-02-23 00:00:00.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44584,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100266, 100428, 2, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44585,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100266, 100428, 3, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44586,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100266, 100428, 1, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44587,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100266, 100428, 4, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44588,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100266, 100428, 1, 3, '2022-08-31 20:13:59.000000', 3)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44589,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100266, 100428, 2, 3, '2022-08-31 20:13:59.000000', 2)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44590,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100266, 100428, 4, 3, '2022-08-31 20:13:59.000000', 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44591,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100266, 100428, 5, 3, '2022-08-31 20:13:59.000000', 4)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44592,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100266, 100428, 6, 3, '2022-08-31 20:13:59.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44593,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100266, 100428, 7, 3, '2022-08-31 20:13:59.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44594,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100266, 100428, 9, 3, '2022-08-31 20:13:59.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44595,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100367, 100428, 484, 'S', ' ', 'N', 'N', 110, 60, 'N', '')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44596,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100267, 'SEGUNDO', 'BRUNILDA', '38033964', '1988-05-03 00:00:00.000000', 2, 12, 'F')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44597,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, georeferencia) VALUES (100429, '2022-08-31 20:18:15.000000', 100267, 1, 1, '-22.2725026,-62.7125106')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44598,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100267, 100267, 31, 3, '00', '2022-08-31 20:18:15.000000', '-22.2725026,-62.7125106', 12)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44599,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100260, 100267, 100429, 14, '2025-12-21 00:00:00.000000', 10, 9, 0, '2003-05-22 00:00:00.000000', '2007-02-23 00:00:00.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44600,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100267, 100429, 2, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44601,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100267, 100429, 3, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44602,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100267, 100429, 1, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44603,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100267, 100429, 4, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44604,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100267, 100429, 1, 3, '2022-08-31 20:22:26.000000', 3)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44605,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100267, 100429, 2, 3, '2022-08-31 20:22:26.000000', 2)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44606,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100267, 100429, 4, 3, '2022-08-31 20:22:26.000000', 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44607,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100267, 100429, 5, 3, '2022-08-31 20:22:26.000000', 4)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44608,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100267, 100429, 6, 3, '2022-08-31 20:22:26.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44609,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100267, 100429, 7, 3, '2022-08-31 20:22:26.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44610,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100267, 100429, 9, 3, '2022-08-31 20:22:26.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44611,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100368, 100429, 1005, 'S', ' ', 'N', 'N', 90, 50, 'N', '')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44612,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100430, '2022-09-01 10:01:46.000000', 281, 3, 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44613,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (281, 100430, 2, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44614,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (281, 100430, 3, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44615,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (281, 100430, 4, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44616,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (281, 100430, 1, 3, '2022-09-01 10:05:02.000000', 3)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44617,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (281, 100430, 2, 3, '2022-09-01 10:05:02.000000', 2)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44618,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (281, 100430, 4, 3, '2022-09-01 10:05:02.000000', 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44619,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (281, 100430, 5, 3, '2022-09-01 10:05:02.000000', 4)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44620,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (281, 100430, 6, 3, '2022-09-01 10:05:02.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44621,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (281, 100430, 7, 3, '2022-09-01 10:05:02.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44622,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100369, 100430, 34, 'S', ' ', 'N', 'N', 110, 60, 'N', '')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44623,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100268, 'ALBORNOZ', 'JUANA', '38651142', '1995-06-24 00:00:00.000000', 2, 12, 'F')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44624,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100431, '2022-09-01 10:10:09.000000', 100268, 1, 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44625,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100268, 100268, 7, 4, '00', '2022-09-01 10:10:09.000000', '', 12)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44626,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100261, 100268, 100431, 22, '2018-09-22 00:00:00.000000', 2, 1, 0, '2022-06-22 00:00:00.000000', '2029-03-23 00:00:00.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44627,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (100261, 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44628,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100268, 100431, 2, 'N')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44629,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100268, 100431, 3, 'N')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44630,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100268, 100431, 1, 'N')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44631,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100268, 100431, 4, 'N')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44632,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100268, 100431, 1, 1, '2022-09-01 10:13:13.000000', 3)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44633,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100268, 100431, 2, 1, '2022-09-01 10:13:13.000000', 2)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44634,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100268, 100431, 4, 1, '2022-09-01 10:13:13.000000', 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44635,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100268, 100431, 5, 1, '2022-09-01 10:13:13.000000', 4)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44636,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100268, 100431, 6, 1, '2022-09-01 10:13:13.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44637,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100268, 100431, 7, 1, '2022-09-01 10:13:13.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44638,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100268, 100431, 9, 1, '2022-09-01 10:13:13.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44639,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100370, 100431, 10, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44640,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100269, 'MASA', 'GUADALUPE', '44566218', '2004-10-25 00:00:00.000000', 2, 12, 'F')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44641,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100432, '2022-09-01 10:17:11.000000', 100269, 1, 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44642,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100269, 100269, 7, 4, '00', '2022-09-01 10:17:11.000000', '', 12)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44643,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (100262, 100269, 100432, 17, 1, 0, '2006-04-22 00:00:00.000000', '2011-01-23 00:00:00.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44644,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100269, 100432, 2, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44645,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100269, 100432, 3, 'N')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44646,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100269, 100432, 1, 'N')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44647,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100269, 100432, 4, 'N')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44648,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100269, 100432, 1, 3, '2022-09-01 10:18:43.000000', 3)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44649,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100269, 100432, 2, 3, '2022-09-01 10:18:43.000000', 2)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44650,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100269, 100432, 4, 3, '2022-09-01 10:18:43.000000', 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44651,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100269, 100432, 5, 3, '2022-09-01 10:18:43.000000', 4)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44652,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100269, 100432, 6, 3, '2022-09-01 10:18:43.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44653,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100269, 100432, 7, 3, '2022-09-01 10:18:43.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44654,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100269, 100432, 9, 3, '2022-09-01 10:18:43.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44655,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100371, 100432, 853, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44656,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100270, 'RICAGNI', 'CARLA', '44910047', '2003-05-08 00:00:00.000000', 2, 12, 'F')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44657,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100433, '2022-09-01 10:26:12.000000', 100270, 1, 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44658,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100270, 100270, 7, 4, '00', '2022-09-01 10:26:12.000000', '', 12)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44659,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (100263, 100270, 100433, 19, 1, 0, '2020-02-22 00:00:00.000000', '2027-11-22 00:00:00.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44660,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (100263, 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44661,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100270, 100433, 2, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44662,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100270, 100433, 3, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44663,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100270, 100433, 1, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44664,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100270, 100433, 4, 'N')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44665,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100270, 100433, 5, 3, '2022-09-01 10:29:26.000000', 4)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44666,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100270, 100433, 6, 3, '2022-09-01 10:29:26.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44667,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100270, 100433, 7, 3, '2022-09-01 10:29:26.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44668,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (100270, 100433, 9, 3, '2022-09-01 10:29:26.000000', '2022-09-01 10:29:26.000000', 'B+')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44669,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100372, 100433, 131, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44670,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100271, 'PEREYRA', 'FERNANDA', '46799660', '2007-02-04 00:00:00.000000', 2, 12, 'F')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44671,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100434, '2022-09-01 10:34:22.000000', 100271, 1, 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44672,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100271, 100271, 7, 4, '00', '2022-09-01 10:34:22.000000', '', 12)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44673,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (100264, 100271, 100434, 15, 1, 0, '2027-11-21 00:00:00.000000', '2003-09-22 00:00:00.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44674,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (100264, 3)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44675,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100271, 100434, 2, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44676,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100271, 100434, 3, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44677,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100271, 100434, 1, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44678,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100271, 100434, 4, 'N')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44679,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100271, 100434, 1, 3, '2022-09-01 10:39:22.000000', 3)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44680,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100271, 100434, 2, 3, '2022-09-01 10:39:22.000000', 2)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44681,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100271, 100434, 4, 3, '2022-09-01 10:39:22.000000', 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44682,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100271, 100434, 5, 3, '2022-09-01 10:39:22.000000', 4)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44683,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100271, 100434, 6, 3, '2022-09-01 10:39:22.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44684,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100271, 100434, 7, 3, '2022-09-01 10:39:22.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44685,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (100271, 100434, 9, 3, '2022-09-01 10:39:22.000000', '2022-09-01 10:39:22.000000', '0+')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44686,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100373, 100434, 272, 'S', ' ', 'N', 'N', 120, 70, 'N', '')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44687,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100272, 'PEREZ', 'ORTENSIA', '46799641', '2006-10-29 00:00:00.000000', 2, 12, 'F')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44688,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100435, '2022-09-01 10:41:18.000000', 100272, 1, 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44689,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100272, 100272, 7, 4, '00', '2022-09-01 10:41:18.000000', '', 12)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44690,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (100265, 100272, 100435, 15, 1, 0, '2018-04-22 00:00:00.000000', '2023-01-23 00:00:00.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44691,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (100265, 3)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44692,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100272, 100435, 2, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44693,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100272, 100435, 3, 'N')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44694,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100272, 100435, 1, 'N')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44695,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100272, 100435, 4, 'N')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44696,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100272, 100435, 6, 3, '2022-09-01 10:43:18.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44697,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100272, 100435, 7, 3, '2022-09-01 10:43:18.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44698,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (100272, 100435, 9, 3, '2022-09-01 10:43:18.000000', '2022-09-01 10:43:18.000000', '0+')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44699,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100374, 100435, 227, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44700,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100273, 'PALOMO', 'DOMICIANA', '34291069', '1989-08-06 00:00:00.000000', 1, 12, 'F')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44701,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100436, '2022-09-01 10:50:24.000000', 100273, 1, 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44702,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100273, 100273, 7, 4, '00', '2022-09-01 10:50:24.000000', '', 12)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44703,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100266, 100273, 100436, 14, '2019-01-01 00:00:00.000000', 7, 6, 0, '2006-05-22 00:00:00.000000', '2010-02-23 00:00:00.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44704,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100273, 100436, 2, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44705,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100273, 100436, 3, 'N')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44706,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100273, 100436, 1, 'N')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44707,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100273, 100436, 4, 'N')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44708,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100273, 100436, 6, 3, '2022-09-01 10:52:37.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44709,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100273, 100436, 7, 3, '2022-09-01 10:52:37.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44710,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (100273, 100436, 9, 3, '2022-09-01 10:52:37.000000', '2022-09-01 10:52:37.000000', '0+')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44711,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100375, 100436, 849, 'S', ' ', 'N', 'N', 110, 70, 'N', '')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44712,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100274, 'HELGUERO', 'MAIRA ELIZABETH', '37747593', '1995-12-24 00:00:00.000000', 2, 12, 'F')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44713,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100437, '2022-09-01 10:57:33.000000', 100274, 1, 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44714,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100274, 100274, 7, 4, '00', '2022-09-01 10:57:33.000000', '', 12)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44715,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, cesareas, planificado, fum, fpp) VALUES (100267, 100274, 100437, 18, '2015-02-03 00:00:00.000000', 2, 1, 1, '2022-02-25 00:00:00.000000', '2022-12-02 00:00:00.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44716,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100274, 100437, 2, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44717,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100274, 100437, 3, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44718,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100274, 100437, 1, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44719,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100274, 100437, 4, 'N')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44720,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100274, 100437, 6, 2, '2022-09-01 11:03:52.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44721,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100274, 100437, 7, 2, '2022-09-01 11:03:52.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44722,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (100274, 100437, 9, 2, '2022-09-01 11:03:52.000000', '2022-09-01 11:03:52.000000', 'B+')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44723,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100376, 100437, 26, 'S', ' ', 'N', 'N', 120, 70, 'N', '')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44724,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100275, 'MIRANDA', 'JOSEFINA', '46747501', '2006-03-30 00:00:00.000000', 2, 12, 'F')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44725,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100438, '2022-09-01 11:08:26.000000', 100275, 1, 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44726,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100275, 100275, 7, 4, '00', '2022-09-01 11:08:26.000000', '', 12)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44727,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100268, 100275, 100438, 12, '2019-01-01 00:00:00.000000', 2, 1, 0, '2029-03-21 00:00:00.000000', '2003-01-22 00:00:00.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44728,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100275, 100438, 2, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44729,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100275, 100438, 3, 'N')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44730,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100275, 100438, 1, 'N')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44731,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100275, 100438, 4, 'N')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44732,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100275, 100438, 1, 3, '2022-09-01 11:12:00.000000', 3)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44733,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100275, 100438, 2, 3, '2022-09-01 11:12:00.000000', 2)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44734,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100275, 100438, 4, 3, '2022-09-01 11:12:00.000000', 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44735,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100275, 100438, 5, 3, '2022-09-01 11:12:00.000000', 4)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44736,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100275, 100438, 6, 3, '2022-09-01 11:12:00.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44737,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100275, 100438, 7, 3, '2022-09-01 11:12:00.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44738,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100275, 100438, 9, 3, '2022-09-01 11:12:00.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44739,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100377, 100438, 341, 'S', ' ', 'N', 'N', 90, 60, 'N', '')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44740,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100439, '2022-09-01 11:33:36.000000', 283, 2, 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44741,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (283, 100439, 2, 'S')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44742,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (283, 100439, 3, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44743,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (283, 100439, 4, 'C')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44744,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (283, 100439, 1, 3, '2022-09-01 11:35:03.000000', 3)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44745,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (283, 100439, 2, 3, '2022-09-01 11:35:03.000000', 2)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44746,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (283, 100439, 4, 3, '2022-09-01 11:35:03.000000', 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44747,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (283, 100439, 5, 3, '2022-09-01 11:35:03.000000', 4)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44748,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (283, 100439, 6, 3, '2022-09-01 11:35:03.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44749,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (283, 100439, 7, 3, '2022-09-01 11:35:03.000000')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44750,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (283, 100439, 9, 3, '2022-09-01 11:35:03.000000', '2022-09-01 11:35:03.000000', '0+')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44751,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100378, 100439, 39, 'S', ' ', 'N', 'N', 110, 70, 'N', '')",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44752,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (100440, '2022-09-01 11:44:14.000000', 200014, 3, 2, '2020-11-21 00:00:00.000000', 1)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44753,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (100029, 100440)",
                    "2022-09-03 11:22:32",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44754,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=200014",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44755,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100276, 'MIRANDA', 'FLORENTINA', '45116140', '2000-04-28 00:00:00.000000', 2, 12, 'F')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44756,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100441, '2022-09-01 11:49:35.000000', 100276, 1, 1)",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44757,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100276, 100276, 7, 4, '00', '2022-09-01 11:49:35.000000', '', 12)",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44758,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100269, 100276, 100441, 14, '2020-11-21 00:00:00.000000', 4, 3, 0, '2028-01-22 00:00:00.000000', '2004-11-22 00:00:00.000000')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44759,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100276, 100441, 2, 'S')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44760,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100276, 100441, 3, 'N')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44761,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100276, 100441, 1, 'N')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44762,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100276, 100441, 4, 'N')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44763,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100276, 100441, 6, 3, '2022-09-01 11:54:24.000000')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44764,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100276, 100441, 7, 3, '2022-09-01 11:54:24.000000')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44765,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (100276, 100441, 9, 3, '2022-09-01 11:54:24.000000', '2022-09-01 11:54:24.000000', '0+')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44766,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100379, 100441, 281, 'S', ' ', 'N', 'N', 110, 70, 'N', '')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44767,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100277, 'LOPEZ', 'MELINA', '43438576', '2002-02-06 00:00:00.000000', 2, 12, 'F')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44768,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100442, '2022-09-01 11:56:59.000000', 100277, 1, 1)",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44769,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100277, 100277, 7, 4, '00', '2022-09-01 11:56:59.000000', '', 12)",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44770,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, abortos, planificado, fum, fpp) VALUES (100270, 100277, 100442, 20, 2, 1, 0, '2017-04-22 00:00:00.000000', '2022-01-23 00:00:00.000000')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44771,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100277, 100442, 2, 'S')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44772,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100277, 100442, 3, 'C')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44773,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100277, 100442, 1, 'S')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44774,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100277, 100442, 4, 'C')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44775,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100277, 100442, 6, 3, '2022-09-01 11:58:40.000000')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44776,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100277, 100442, 7, 3, '2022-09-01 11:58:40.000000')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44777,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (100277, 100442, 9, 3, '2022-09-01 11:58:40.000000', '2022-09-01 11:58:40.000000', '0+')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44778,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100380, 100442, 279, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44779,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100278, 'DIAZ', 'ROMINA', '45116152', '2002-08-07 00:00:00.000000', 2, 12, 'F')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44780,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100443, '2022-09-01 12:02:07.000000', 100278, 1, 1)",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44781,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100278, 100278, 7, 4, '00', '2022-09-01 12:02:07.000000', '', 12)",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44782,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (100271, 100278, 100443, 18, 1, 0, '2010-05-22 00:00:00.000000', '2014-02-23 00:00:00.000000')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44783,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100278, 100443, 2, 'S')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44784,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100278, 100443, 3, 'N')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44785,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100278, 100443, 1, 'N')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44786,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100278, 100443, 4, 'N')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44787,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100278, 100443, 1, 3, '2022-09-01 12:04:34.000000', 3)",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44788,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100278, 100443, 2, 3, '2022-09-01 12:04:34.000000', 2)",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44789,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100278, 100443, 4, 3, '2022-09-01 12:04:34.000000', 1)",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44790,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100278, 100443, 5, 3, '2022-09-01 12:04:34.000000', 4)",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44791,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100278, 100443, 6, 3, '2022-09-01 12:04:34.000000')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44792,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100278, 100443, 7, 3, '2022-09-01 12:04:34.000000')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44793,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100278, 100443, 9, 3, '2022-09-01 12:04:34.000000')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44794,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100381, 100443, 640, 'S', ' ', 'N', 'N', 90, 60, 'N', '')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44795,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100279, 'HERRERA', 'NATALIA', '44499488', '2022-09-01 00:00:00.000000', 2, 12, 'F')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44796,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100444, '2022-09-01 12:24:27.000000', 100279, 1, 1)",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44797,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100279, 100279, 7, 4, '00', '2022-09-01 12:24:27.000000', '', 12)",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44798,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (100272, 100279, 100444, 18, 1, 1, '2013-07-22 00:00:00.000000', '2019-04-23 00:00:00.000000')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44799,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (100272, 3)",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44800,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100279, 100444, 2, 'N')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44801,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100279, 100444, 3, 'N')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44802,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100279, 100444, 1, 'N')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44803,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100279, 100444, 4, 'N')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44804,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100279, 100444, 1, 3, '2022-09-01 12:26:28.000000', 3)",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44805,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100279, 100444, 2, 3, '2022-09-01 12:26:28.000000', 2)",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44806,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100279, 100444, 4, 3, '2022-09-01 12:26:28.000000', 1)",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44807,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100279, 100444, 5, 3, '2022-09-01 12:26:28.000000', 4)",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44808,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100279, 100444, 6, 3, '2022-09-01 12:26:28.000000')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44809,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100279, 100444, 7, 3, '2022-09-01 12:26:28.000000')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44810,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100279, 100444, 9, 3, '2022-09-01 12:26:28.000000')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44811,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100382, 100444, 475, 'S', ' ', 'N', 'N', 120, 70, 'N', '')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44812,
                    "UPDATE personas SET fecha_nacimiento='2004-06-07' WHERE personas.id_persona=100279",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44813,
                    "SELECT id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais FROM ubicaciones WHERE id_ubicacion = '100279'",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44814,
                    "SELECT id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo, madre, alta, nacido_vivo FROM personas WHERE id_persona = '100279'",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44815,
                    "SELECT id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais FROM ubicaciones WHERE id_ubicacion = '100279'",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44816,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100280, 'LOBO', 'NOEMI EVANGELINA', '40354837', '1996-11-06 00:00:00.000000', 2, 12, 'F')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44817,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100445, '2022-09-01 12:59:26.000000', 100280, 1, 1)",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44818,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100280, 100280, 7, 4, '00', '2022-09-01 12:59:26.000000', '', 12)",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44819,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100273, 100280, 100445, 20, '2006-04-01 00:00:00.000000', 2, 1, 0, '2007-07-22 00:00:00.000000', '2013-04-23 00:00:00.000000')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44820,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100280, 100445, 2, 'S')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44821,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100280, 100445, 3, 'N')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44822,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100280, 100445, 1, 'N')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44823,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100280, 100445, 4, 'N')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44824,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100280, 100445, 1, 3, '2022-09-01 13:04:32.000000', 3)",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44825,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100280, 100445, 2, 3, '2022-09-01 13:04:32.000000', 2)",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44826,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100280, 100445, 4, 3, '2022-09-01 13:04:32.000000', 1)",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44827,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100280, 100445, 5, 3, '2022-09-01 13:04:32.000000', 4)",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44828,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100280, 100445, 6, 3, '2022-09-01 13:04:32.000000')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44829,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100280, 100445, 7, 3, '2022-09-01 13:04:32.000000')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44830,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100280, 100445, 9, 3, '2022-09-01 13:04:32.000000')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44831,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100383, 100445, 788, 'S', ' ', 'N', 'N', 120, 80, 'N', '')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44832,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100281, 'TORRES', 'BELINDA', '11530875', '2000-01-07 00:00:00.000000', 2, 12, 'F')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44833,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100446, '2022-09-01 15:53:44.000000', 100281, 1, 1)",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44834,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100281, 100281, 7, 4, '00', '2022-09-01 15:53:44.000000', '', 12)",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44835,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100274, 100281, 100446, 18, '2018-07-18 00:00:00.000000', 2, 1, 0, '2022-02-05 00:00:00.000000', '2022-11-12 00:00:00.000000')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44836,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100281, 100446, 2, 'S')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44837,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100281, 100446, 3, 'C')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44838,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100281, 100446, 1, 'S')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44839,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100281, 100446, 4, 'C')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44840,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100281, 100446, 6, 3, '2022-09-01 15:56:03.000000')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44841,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100281, 100446, 7, 3, '2022-09-01 15:56:03.000000')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44842,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (100281, 100446, 9, 3, '2022-09-01 15:56:03.000000', '2022-09-01 15:56:03.000000', '0+')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44843,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100384, 100446, 29, 'S', ' ', 'N', 'N', 90, 60, 'N', '')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44844,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100282, 'MAZA', 'GLORIA', '45969049', '2005-03-20 00:00:00.000000', 2, 12, 'F')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44845,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100447, '2022-09-01 16:27:21.000000', 100282, 1, 1)",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44846,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100282, 100282, 7, 4, '00', '2022-09-01 16:27:21.000000', '', 12)",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44847,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (100275, 100282, 100447, 17, 1, 1, '2028-01-22 00:00:00.000000', '2004-11-22 00:00:00.000000')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44848,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100282, 100447, 2, 'S')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44849,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100282, 100447, 3, 'N')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44850,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100282, 100447, 1, 'N')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44851,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100282, 100447, 4, 'N')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44852,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100282, 100447, 6, 3, '2022-09-01 16:29:25.000000')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44853,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100282, 100447, 7, 3, '2022-09-01 16:29:25.000000')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44854,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (100282, 100447, 9, 3, '2022-09-01 16:29:25.000000', '2022-09-01 16:29:25.000000', '0+')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44855,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100385, 100447, 281, 'S', ' ', 'N', 'N', 100, 70, 'N', '')",
                    "2022-09-03 11:22:33",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44856,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (659, '2022-08-29 10:07:37.000000', 344, 2, 1)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44857,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (344, 659, 2, 'S')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44858,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (344, 659, 3, 'C')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44859,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (344, 659, 4, 'N')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44860,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (344, 659, 5, 2, '2022-08-29 10:09:10.000000', 4)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44861,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (344, 659, 6, 2, '2022-08-29 10:09:10.000000')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44862,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (344, 659, 7, 2, '2022-08-29 10:09:10.000000')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44863,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (448, 659, 27, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44864,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (386, 'JURADO', 'ERICA VIVIANA', '40765130', '1994-12-12 00:00:00.000000', 1, 12, 'F')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44865,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (660, '2022-08-29 10:13:09.000000', 386, 1, 1)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44866,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (386, 386, 18, 3, '00', '2022-08-29 10:13:09.000000', '', 12)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44867,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, cesareas, planificado, fum, fpp) VALUES (302, 386, 660, 23, '2019-03-30 00:00:00.000000', 3, 2, 0, '2022-06-27 00:00:00.000000', '2023-04-03 00:00:00.000000')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44868,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (302, 3)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44869,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (386, 660, 2, 'N')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44870,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (386, 660, 3, 'N')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44871,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (386, 660, 1, 'N')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44872,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (386, 660, 4, 'N')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44873,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (386, 660, 1, 1, '2022-08-29 10:17:18.000000', 3)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44874,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (386, 660, 2, 1, '2022-08-29 10:17:18.000000', 2)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44875,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (386, 660, 4, 1, '2022-08-29 10:17:18.000000', 1)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44876,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (386, 660, 5, 1, '2022-08-29 10:17:18.000000', 4)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44877,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (386, 660, 6, 1, '2022-08-29 10:17:18.000000')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44878,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (386, 660, 7, 1, '2022-08-29 10:17:18.000000')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44879,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (386, 660, 9, 1, '2022-08-29 10:17:18.000000')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44880,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (449, 660, 9, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44881,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (661, '2022-08-29 10:20:11.000000', 351, 2, 1)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44882,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (351, 661, 2, 'S')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44883,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (351, 661, 3, 'P')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44884,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (351, 661, 4, 'C')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44885,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (351, 661, 5, 3, '2022-08-29 10:21:32.000000', 4)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44886,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (351, 661, 6, 3, '2022-08-29 10:21:32.000000')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44887,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (351, 661, 7, 3, '2022-08-29 10:21:32.000000')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44888,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (450, 661, 33, 'S', ' ', 'N', 'N', 90, 60, 'N', '')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44889,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=450",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44890,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-29', resultado='N' WHERE laboratorios_realizados.id_persona=351 AND laboratorios_realizados.id_control=661 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44891,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-29', resultado='96' WHERE laboratorios_realizados.id_persona=351 AND laboratorios_realizados.id_control=661 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44892,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (387, 'PEREZ', 'FRANCISCA', '27879709', '1981-06-26 00:00:00.000000', 1, 12, 'F')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44893,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (662, '2022-08-29 11:12:09.000000', 387, 1, 1)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44894,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (387, 387, 18, 3, '00', '2022-08-29 11:12:09.000000', '', 12)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44895,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, abortos, planificado, fum, fpp) VALUES (303, 387, 662, 14, '2015-04-01 00:00:00.000000', 10, 8, 1, 1, '2021-11-28 00:00:00.000000', '2022-09-04 00:00:00.000000')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44896,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (387, 662, 2, 'N')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44897,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (387, 662, 3, 'N')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44898,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (387, 662, 1, 'N')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44899,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (387, 662, 4, 'N')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44900,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (387, 662, 1, 3, '2022-08-29 11:16:36.000000', 3)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44901,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (387, 662, 2, 3, '2022-08-29 11:16:36.000000', 2)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44902,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (387, 662, 4, 3, '2022-08-29 11:16:36.000000', 1)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44903,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (387, 662, 5, 3, '2022-08-29 11:16:36.000000', 4)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44904,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (387, 662, 6, 3, '2022-08-29 11:16:36.000000')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44905,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (387, 662, 7, 3, '2022-08-29 11:16:36.000000')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44906,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (387, 662, 9, 3, '2022-08-29 11:16:36.000000')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44907,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (451, 662, 39, 'S', ' ', 'N', 'N', 120, 70, 'N', '')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44908,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (388, 'SOLAS', 'NATALIA', '45116190', '1998-05-02 00:00:00.000000', 1, 12, 'F')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44909,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (663, '2022-08-29 11:29:34.000000', 388, 1, 1)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44910,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (388, 388, 18, 3, '00', '2022-08-29 11:29:34.000000', '', 12)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44911,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, cesareas, planificado, fum, fpp) VALUES (304, 388, 663, 21, '2018-11-03 00:00:00.000000', 2, 1, 1, '2022-04-01 00:00:00.000000', '2023-01-06 00:00:00.000000')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44912,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (304, 4)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44913,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (388, 663, 2, 'S')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44914,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (388, 663, 3, 'C')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44915,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (388, 663, 1, 'N')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44916,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (388, 663, 4, 'C')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44917,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (388, 663, 1, 2, '2022-08-29 11:32:16.000000', 3)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44918,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (388, 663, 2, 2, '2022-08-29 11:32:16.000000', 2)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44919,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (388, 663, 4, 2, '2022-08-29 11:32:16.000000', 1)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44920,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (388, 663, 5, 2, '2022-08-29 11:32:16.000000', 4)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44921,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (388, 663, 6, 2, '2022-08-29 11:32:16.000000')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44922,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (388, 663, 7, 2, '2022-08-29 11:32:16.000000')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44923,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (452, 663, 21, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44924,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (389, 'GIGANTE', 'ANALIA', '41718981', '1999-04-25 00:00:00.000000', 1, 12, 'F')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44925,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (664, '2022-08-29 11:35:08.000000', 389, 1, 1)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44926,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (389, 389, 18, 3, '00', '2022-08-29 11:35:08.000000', '', 12)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44927,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (305, 389, 664, 16, '2019-06-05 00:00:00.000000', 4, 3, 1, '2022-03-28 00:00:00.000000', '2023-01-02 00:00:00.000000')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44928,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (389, 664, 2, 'S')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44929,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (389, 664, 3, 'C')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44930,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (389, 664, 1, 'S')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44931,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (389, 664, 4, 'C')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44932,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (389, 664, 1, 2, '2022-08-29 11:38:00.000000', 3)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44933,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (389, 664, 2, 2, '2022-08-29 11:38:00.000000', 2)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44934,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (389, 664, 4, 2, '2022-08-29 11:38:00.000000', 1)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44935,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (389, 664, 5, 2, '2022-08-29 11:38:00.000000', 4)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44936,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (389, 664, 6, 2, '2022-08-29 11:38:00.000000')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44937,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (389, 664, 7, 2, '2022-08-29 11:38:00.000000')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44938,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (389, 664, 9, 2, '2022-08-29 11:38:00.000000')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44939,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (453, 664, 22, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44940,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (390, 'GIGANTE', 'KARINA', '36544866', '1991-08-08 00:00:00.000000', 1, 12, 'F')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44941,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (665, '2022-08-29 11:41:53.000000', 390, 1, 1)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44942,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (390, 390, 18, 3, '00', '2022-08-29 11:41:53.000000', '', 12)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44943,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (306, 390, 665, 13, '2009-10-21 00:00:00.000000', 6, 5, 1, '2023-05-22 00:00:00.000000', '2027-02-23 00:00:00.000000')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44944,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (390, 665, 2, 'S')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44945,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (390, 665, 3, 'P')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44946,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (390, 665, 1, 'N')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44947,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (390, 665, 4, 'C')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44948,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (390, 665, 1, 3, '2022-08-29 11:44:54.000000', 3)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44949,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (390, 665, 2, 3, '2022-08-29 11:44:54.000000', 2)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44950,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (390, 665, 4, 3, '2022-08-29 11:44:54.000000', 1)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44951,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (390, 665, 5, 3, '2022-08-29 11:44:54.000000', 4)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44952,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (390, 665, 6, 3, '2022-08-29 11:44:54.000000')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44953,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (390, 665, 7, 3, '2022-08-29 11:44:54.000000')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44954,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (390, 665, 9, 3, '2022-08-29 11:44:54.000000')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44955,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (454, 665, 37, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44956,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (391, 'ORDONEZ', 'CARLA', '51354697', '1999-09-04 00:00:00.000000', 1, 12, 'F')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44957,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (666, '2022-08-29 11:47:22.000000', 391, 1, 1)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44958,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (391, 391, 18, 3, '00', '2022-08-29 11:47:22.000000', '', 12)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44959,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (307, 391, 666, 15, '2020-03-01 00:00:00.000000', 4, 3, 0, '2022-04-05 00:00:00.000000', '2023-01-10 00:00:00.000000')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44960,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (391, 666, 2, 'N')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44961,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (391, 666, 3, 'N')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44962,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (391, 666, 1, 'N')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44963,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (391, 666, 4, 'N')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44964,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (391, 666, 1, 2, '2022-08-29 11:50:39.000000', 3)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44965,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (391, 666, 2, 2, '2022-08-29 11:50:39.000000', 2)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44966,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (391, 666, 4, 2, '2022-08-29 11:50:39.000000', 1)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44967,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (391, 666, 5, 2, '2022-08-29 11:50:39.000000', 4)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44968,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (391, 666, 6, 2, '2022-08-29 11:50:39.000000')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44969,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (391, 666, 7, 2, '2022-08-29 11:50:39.000000')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44970,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (391, 666, 9, 2, '2022-08-29 11:50:39.000000')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44971,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (455, 666, 20, 'S', ' ', 'N', 'N', 110, 60, 'N', '')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44972,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (392, 'DIAZ', 'FERMINA ANGELICA', '50753857', '2008-05-25 00:00:00.000000', 1, 12, 'F')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44973,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (667, '2022-08-29 11:54:12.000000', 392, 1, 1)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44974,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (392, 392, 18, 3, '00', '2022-08-29 11:54:12.000000', '', 12)",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44975,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, planificado, fum, fpp) VALUES (308, 392, 667, 15, '2030-10-31 00:00:00.000000', 1, 0, '2015-06-22 00:00:00.000000', '2022-03-23 00:00:00.000000')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44976,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (392, 667, 2, 'N')",
                    "2022-09-03 15:24:35",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44977,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (392, 667, 3, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44978,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (392, 667, 1, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44979,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (392, 667, 4, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44980,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (392, 667, 1, 1, '2022-08-29 12:01:17.000000', 3)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44981,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (392, 667, 2, 1, '2022-08-29 12:01:17.000000', 2)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44982,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (392, 667, 4, 1, '2022-08-29 12:01:17.000000', 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44983,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (392, 667, 5, 1, '2022-08-29 12:01:17.000000', 4)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44984,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (392, 667, 6, 1, '2022-08-29 12:01:17.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44985,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (392, 667, 7, 1, '2022-08-29 12:01:17.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44986,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (392, 667, 9, 1, '2022-08-29 12:01:17.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44987,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (456, 667, 10, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44988,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (668, '2022-08-29 12:03:58.000000', 355, 2, 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44989,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (355, 668, 2, 'S')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44990,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (355, 668, 3, 'C')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44991,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (355, 668, 4, 'C')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44992,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (355, 668, 1, 3, '2022-08-29 12:05:08.000000', 3)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44993,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (355, 668, 2, 3, '2022-08-29 12:05:08.000000', 2)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44994,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (355, 668, 5, 3, '2022-08-29 12:05:08.000000', 4)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44995,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (355, 668, 6, 3, '2022-08-29 12:05:08.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44996,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (355, 668, 7, 3, '2022-08-29 12:05:08.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44997,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (457, 668, 43, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44998,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (669, '2022-08-29 12:08:43.000000', 347, 2, 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    44999,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (347, 669, 2, 'S')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45000,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (347, 669, 3, 'P')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45001,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (347, 669, 4, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45002,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (347, 669, 5, 2, '2022-08-29 12:10:23.000000', 4)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45003,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (347, 669, 6, 2, '2022-08-29 12:10:23.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45004,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (347, 669, 7, 2, '2022-08-29 12:10:23.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45005,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (458, 669, 26, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45006,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (393, 'ACEVEDO', 'MICAELA', '39892291', '1996-10-19 00:00:00.000000', 1, 12, 'F')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45007,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (670, '2022-08-29 12:14:00.000000', 393, 1, 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45008,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (393, 393, 18, 3, '00', '2022-08-29 12:14:00.000000', '', 12)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45009,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, abortos, planificado, fum, fpp) VALUES (309, 393, 670, 0, '2021-08-01 00:00:00.000000', 4, 3, 1, '2022-01-24 00:00:00.000000', '2022-10-31 00:00:00.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45010,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (309, 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45011,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (393, 670, 2, 'S')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45012,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (393, 670, 3, 'C')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45013,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (393, 670, 1, 'S')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45014,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (393, 670, 4, 'C')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45015,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (393, 670, 1, 3, '2022-08-29 12:18:50.000000', 3)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45016,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (393, 670, 2, 3, '2022-08-29 12:18:50.000000', 2)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45017,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (393, 670, 4, 3, '2022-08-29 12:18:50.000000', 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45018,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (393, 670, 5, 3, '2022-08-29 12:18:50.000000', 4)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45019,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (393, 670, 6, 3, '2022-08-29 12:18:50.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45020,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (393, 670, 7, 3, '2022-08-29 12:18:50.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45021,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (459, 670, 31, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45022,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (671, '2022-08-29 12:25:53.000000', 357, 2, 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45023,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (357, 671, 2, 'S')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45024,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (357, 671, 3, 'C')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45025,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (357, 671, 4, 'C')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45026,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (357, 671, 1, 3, '2022-08-29 12:27:36.000000', 3)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45027,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (357, 671, 2, 3, '2022-08-29 12:27:36.000000', 2)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45028,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (357, 671, 5, 3, '2022-08-29 12:27:36.000000', 4)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45029,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (357, 671, 6, 3, '2022-08-29 12:27:36.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45030,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (357, 671, 7, 3, '2022-08-29 12:27:36.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45031,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (460, 671, 40, 'S', ' ', 'N', 'N', 130, 80, 'N', '')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45032,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (672, '2022-08-29 12:30:08.000000', 352, 2, 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45033,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (352, 672, 2, 'S')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45034,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (352, 672, 3, 'P')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45035,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (352, 672, 4, 'C')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45036,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (352, 672, 5, 3, '2022-08-29 12:31:29.000000', '2022-08-29 12:31:29.000000', 'N', 4)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45037,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (352, 672, 6, 3, '2022-08-29 12:31:29.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45038,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (352, 672, 7, 3, '2022-08-29 12:31:29.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45039,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (461, 672, 29, 'S', ' ', 'N', 'N', 110, 60, 'N', '')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45040,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (394, 'MEDINA', 'ALCINDA', '513919093', '1998-07-02 00:00:00.000000', 1, 12, 'F')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45041,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (673, '2022-08-29 12:34:58.000000', 394, 1, 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45042,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (394, 394, 18, 3, '00', '2022-08-29 12:34:58.000000', '', 12)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45043,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, planificado, fum, fpp) VALUES (310, 394, 673, 0, 4, 3, 0, '2022-03-25 00:00:00.000000', '2022-12-30 00:00:00.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45044,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (394, 673, 2, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45045,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (394, 673, 3, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45046,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (394, 673, 1, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45047,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (394, 673, 4, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45048,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (394, 673, 1, 2, '2022-08-29 12:38:13.000000', 3)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45049,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (394, 673, 2, 2, '2022-08-29 12:38:13.000000', 2)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45050,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (394, 673, 4, 2, '2022-08-29 12:38:13.000000', 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45051,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (394, 673, 5, 2, '2022-08-29 12:38:13.000000', 4)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45052,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (394, 673, 6, 2, '2022-08-29 12:38:13.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45053,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (394, 673, 7, 2, '2022-08-29 12:38:13.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45054,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (394, 673, 9, 2, '2022-08-29 12:38:13.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45055,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (462, 673, 22, 'S', ' ', 'N', 'N', 110, 60, 'N', '')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45056,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (395, 'SAMBO', 'PATRICIA', '40629151', '1988-01-15 00:00:00.000000', 1, 12, 'F')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45057,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (674, '2022-08-29 12:41:08.000000', 395, 1, 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45058,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (395, 395, 18, 3, '00', '2022-08-29 12:41:08.000000', '', 12)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45059,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, abortos, planificado, fum, fpp) VALUES (311, 395, 674, 28, 9, 7, 1, 1, '2028-02-22 00:00:00.000000', '2005-12-22 00:00:00.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45060,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (395, 674, 2, 'S')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45061,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (395, 674, 3, 'C')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45062,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (395, 674, 1, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45063,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (395, 674, 4, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45064,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (395, 674, 1, 3, '2022-08-29 12:44:40.000000', 3)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45065,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (395, 674, 2, 3, '2022-08-29 12:44:40.000000', 2)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45066,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (395, 674, 4, 3, '2022-08-29 12:44:40.000000', 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45067,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (395, 674, 5, 3, '2022-08-29 12:44:40.000000', 4)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45068,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (395, 674, 6, 3, '2022-08-29 12:44:40.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45069,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (395, 674, 7, 3, '2022-08-29 12:44:40.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45070,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (395, 674, 9, 3, '2022-08-29 12:44:40.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45071,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (463, 674, 28, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45072,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (675, '2022-08-29 12:45:52.000000', 348, 2, 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45073,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (348, 675, 2, 'S')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45074,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (348, 675, 3, 'P')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45075,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (348, 675, 4, 'C')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45076,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (348, 675, 1, 3, '2022-08-29 12:46:49.000000', 3)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45077,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (348, 675, 2, 3, '2022-08-29 12:46:49.000000', 2)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45078,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (348, 675, 5, 3, '2022-08-29 12:46:49.000000', 4)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45079,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (348, 675, 6, 3, '2022-08-29 12:46:49.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45080,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (348, 675, 7, 3, '2022-08-29 12:46:49.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45081,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (464, 675, 35, 'S', ' ', 'N', 'N', 112, 60, 'N', '')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45082,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (396, 'ALBARRACIN', 'GLADYS', '19070598', '1990-03-12 00:00:00.000000', 1, 12, 'F')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45083,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (676, '2022-08-29 12:58:11.000000', 396, 1, 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45084,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (396, 396, 18, 3, '00', '2022-08-29 12:58:11.000000', '', 12)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45085,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, cesareas, planificado, fum, fpp) VALUES (312, 396, 676, 19, '2019-04-26 00:00:00.000000', 4, 1, 2, 1, '2022-02-10 00:00:00.000000', '2022-11-17 00:00:00.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45086,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (396, 676, 2, 'S')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45087,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (396, 676, 3, 'C')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45088,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (396, 676, 1, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45089,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (396, 676, 4, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45090,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (396, 676, 1, 3, '2022-08-29 13:00:57.000000', 3)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45091,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (396, 676, 2, 3, '2022-08-29 13:00:57.000000', 2)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45092,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (396, 676, 4, 3, '2022-08-29 13:00:57.000000', 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45093,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (396, 676, 5, 3, '2022-08-29 13:00:57.000000', 4)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45094,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (396, 676, 6, 3, '2022-08-29 13:00:57.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45095,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (396, 676, 7, 3, '2022-08-29 13:00:57.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45096,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (396, 676, 9, 3, '2022-08-29 13:00:57.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45097,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (465, 676, 28, 'S', ' ', 'N', 'N', 112, 60, 'N', '')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45098,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (397, 'PAREDES', 'ANA', '43219102', '2000-11-19 00:00:00.000000', 1, 12, 'F')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45099,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (677, '2022-08-29 13:39:49.000000', 397, 1, 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45100,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (397, 397, 18, 3, '0', '2022-08-29 13:39:49.000000', '', 12)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45101,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (313, 397, 677, 21, 1, 1, '2022-03-23 00:00:00.000000', '2022-12-28 00:00:00.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45102,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (397, 677, 2, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45103,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (397, 677, 3, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45104,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (397, 677, 1, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45105,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (397, 677, 4, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45106,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (397, 677, 1, 2, '2022-08-29 13:43:55.000000', 3)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45107,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (397, 677, 2, 2, '2022-08-29 13:43:55.000000', 2)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45108,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (397, 677, 4, 2, '2022-08-29 13:43:55.000000', 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45109,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (397, 677, 5, 2, '2022-08-29 13:43:55.000000', 4)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45110,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (397, 677, 6, 2, '2022-08-29 13:43:55.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45111,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (397, 677, 7, 2, '2022-08-29 13:43:55.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45112,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (397, 677, 9, 2, '2022-08-29 13:43:55.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45113,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (466, 677, 22, 'S', ' ', 'N', 'N', 120, 70, 'N', '')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45114,
                    "SELECT id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo, madre, alta, nacido_vivo FROM personas WHERE id_persona = '347'",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45115,
                    "SELECT id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais FROM ubicaciones WHERE id_ubicacion = '347'",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45116,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (678, '2022-08-29 14:17:38.000000', 248, 3, 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45117,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (248, 678, 2, 'S')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45118,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (248, 678, 3, 'P')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45119,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (248, 678, 4, 'C')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45120,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (248, 678, 1, 3, '2022-08-29 14:18:36.000000', 3)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45121,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (248, 678, 2, 3, '2022-08-29 14:18:36.000000', 2)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45122,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (248, 678, 5, 3, '2022-08-29 14:18:36.000000', 4)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45123,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (248, 678, 6, 3, '2022-08-29 14:18:36.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45124,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (248, 678, 7, 3, '2022-08-29 14:18:36.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45125,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (467, 678, 35, 'S', ' ', 'N', 'N', 130, 80, 'N', '')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45126,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (398, 'DUARTE', 'RONILDA MARGARITA', '45056384', '2003-07-22 00:00:00.000000', 1, 12, 'F')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45127,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (679, '2022-08-29 14:25:31.000000', 398, 1, 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45128,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (398, 398, 18, 3, '00', '2022-08-29 14:25:31.000000', '', 12)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45129,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, planificado, fum, fpp) VALUES (314, 398, 679, 0, 2, 1, 1, '2030-05-22 00:00:00.000000', '2006-03-23 00:00:00.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45130,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (314, 3)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45131,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (398, 679, 2, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45132,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (398, 679, 3, 'C')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45133,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (398, 679, 1, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45134,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (398, 679, 4, 'C')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45135,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (398, 679, 1, 3, '2022-08-29 14:30:26.000000', 3)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45136,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (398, 679, 2, 3, '2022-08-29 14:30:26.000000', 2)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45137,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (398, 679, 4, 3, '2022-08-29 14:30:26.000000', 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45138,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (398, 679, 5, 3, '2022-08-29 14:30:26.000000', 4)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45139,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (398, 679, 6, 3, '2022-08-29 14:30:26.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45140,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (398, 679, 7, 3, '2022-08-29 14:30:26.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45141,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (398, 679, 9, 3, '2022-08-29 14:30:26.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45142,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (468, 679, 40, 'S', ' ', 'N', 'N', 110, 60, 'N', '')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45143,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (399, 'FELIX', 'LUCIA ELENA', '36557153', '1992-09-07 00:00:00.000000', 1, 12, 'F')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45144,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (680, '2022-08-29 16:36:16.000000', 399, 1, 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45145,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (399, 399, 18, 3, '00', '2022-08-29 16:36:16.000000', '', 12)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45146,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (315, 399, 680, 17, '2021-01-18 00:00:00.000000', 6, 5, 1, '2002-07-22 00:00:00.000000', '2008-04-23 00:00:00.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45147,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (399, 680, 2, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45148,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (399, 680, 3, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45149,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (399, 680, 1, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45150,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (399, 680, 4, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45151,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (399, 680, 1, 1, '2022-08-29 16:44:05.000000', 3)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45152,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (399, 680, 2, 1, '2022-08-29 16:44:05.000000', 2)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45153,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (399, 680, 4, 1, '2022-08-29 16:44:05.000000', 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45154,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (399, 680, 5, 1, '2022-08-29 16:44:05.000000', 4)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45155,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (399, 680, 6, 1, '2022-08-29 16:44:05.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45156,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (399, 680, 7, 1, '2022-08-29 16:44:05.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45157,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (399, 680, 9, 1, '2022-08-29 16:44:05.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45158,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (469, 680, 8, 'S', ' ', 'N', 'N', 110, 70, 'N', '')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45159,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (400, 'SEGUNDO', 'LUCRECIA', '36557052', '1991-10-10 00:00:00.000000', 1, 12, 'F')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45160,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (681, '2022-08-29 16:51:08.000000', 400, 1, 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45161,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (400, 400, 21, 3, '00', '2022-08-29 16:51:08.000000', '', 12)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45162,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (316, 400, 681, 16, '2021-01-05 00:00:00.000000', 7, 6, 1, '2022-04-17 00:00:00.000000', '2023-01-22 00:00:00.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45163,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (316, 3)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45164,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (400, 681, 2, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45165,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (400, 681, 3, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45166,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (400, 681, 1, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45167,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (400, 681, 4, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45168,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (400, 681, 1, 2, '2022-08-29 17:02:30.000000', 3)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45169,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (400, 681, 2, 2, '2022-08-29 17:02:30.000000', 2)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45170,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (400, 681, 4, 2, '2022-08-29 17:02:30.000000', 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45171,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (400, 681, 5, 2, '2022-08-29 17:02:30.000000', 4)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45172,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (400, 681, 6, 2, '2022-08-29 17:02:30.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45173,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (400, 681, 7, 2, '2022-08-29 17:02:30.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45174,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (400, 681, 9, 2, '2022-08-29 17:02:30.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45175,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (470, 681, 19, 'S', ' ', 'N', 'N', 120, 70, 'N', '')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45176,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (682, '2022-08-29 17:40:21.000000', 360, 2, 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45177,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (360, 682, 2, 'S')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45178,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (360, 682, 3, 'C')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45179,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (360, 682, 4, 'C')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45180,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (360, 682, 1, 3, '2022-08-29 17:41:33.000000', 3)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45181,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (360, 682, 2, 3, '2022-08-29 17:41:33.000000', 2)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45182,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (360, 682, 5, 3, '2022-08-29 17:41:33.000000', 4)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45183,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (360, 682, 6, 3, '2022-08-29 17:41:33.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45184,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (360, 682, 7, 3, '2022-08-29 17:41:33.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45185,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (471, 682, 33, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45186,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (683, '2022-08-29 17:44:32.000000', 361, 2, 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45187,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (361, 683, 2, 'S')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45188,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (361, 683, 3, 'C')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45189,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (361, 683, 4, 'C')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45190,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (361, 683, 5, 2, '2022-08-29 17:46:15.000000', 4)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45191,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (361, 683, 6, 2, '2022-08-29 17:46:15.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45192,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (361, 683, 7, 2, '2022-08-29 17:46:15.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45193,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (472, 683, 25, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45194,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (401, 'BARRIOS', 'MARIA', '45116262', '1992-08-26 00:00:00.000000', 1, 12, 'F')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45195,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (684, '2022-08-29 18:35:54.000000', 401, 1, 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45196,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (401, 401, 18, 3, '00', '2022-08-29 18:35:54.000000', '', 12)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45197,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, planificado, fum, fpp) VALUES (317, 401, 684, 0, 3, 2, 1, '2022-04-27 00:00:00.000000', '2023-02-01 00:00:00.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45198,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (401, 684, 2, 'S')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45199,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (401, 684, 3, 'C')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45200,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (401, 684, 1, 'S')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45201,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (401, 684, 4, 'C')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45202,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (401, 684, 1, 2, '2022-08-29 18:38:56.000000', 3)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45203,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (401, 684, 2, 2, '2022-08-29 18:38:56.000000', 2)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45204,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (401, 684, 4, 2, '2022-08-29 18:38:56.000000', 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45205,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (401, 684, 5, 2, '2022-08-29 18:38:56.000000', 4)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45206,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (401, 684, 6, 2, '2022-08-29 18:38:56.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45207,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (401, 684, 7, 2, '2022-08-29 18:38:56.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45208,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (401, 684, 9, 2, '2022-08-29 18:38:56.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45209,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (473, 684, 17, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45210,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (402, 'MENDEZ', 'NIDIA', '41233314', '1998-08-15 00:00:00.000000', 1, 12, 'F')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45211,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (685, '2022-08-30 10:18:24.000000', 402, 1, 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45212,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (402, 402, 39, 3, '00', '2022-08-30 10:18:24.000000', '', 12)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45213,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, planificado, fum, fpp) VALUES (318, 402, 685, 14, 6, 5, 0, '2022-07-01 00:00:00.000000', '2023-04-07 00:00:00.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45214,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (403, 'CEBALLOS', 'SILVINA', '42752342', '2001-09-15 00:00:00.000000', 1, 12, 'F')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45215,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (686, '2022-08-30 10:44:22.000000', 403, 1, 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45216,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (403, 403, 39, 3, '00', '2022-08-30 10:44:22.000000', '', 12)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45217,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (319, 403, 686, 20, 1, 0, '2022-05-01 00:00:00.000000', '2023-02-05 00:00:00.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45218,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (687, '2022-08-30 11:06:49.000000', 363, 2, 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45219,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (403, 686, 2, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45220,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (403, 686, 3, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45221,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (403, 686, 1, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45222,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (403, 686, 4, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45223,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (403, 686, 1, 2, '2022-08-30 11:11:24.000000', 3)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45224,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (403, 686, 2, 2, '2022-08-30 11:11:24.000000', 2)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45225,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (403, 686, 4, 2, '2022-08-30 11:11:24.000000', 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45226,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (403, 686, 5, 2, '2022-08-30 11:11:24.000000', 4)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45227,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (403, 686, 6, 2, '2022-08-30 11:11:24.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45228,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (403, 686, 7, 2, '2022-08-30 11:11:24.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45229,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (403, 686, 9, 2, '2022-08-30 11:11:24.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45230,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (474, 686, 17, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45231,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (363, 687, 2, 'S')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45232,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (363, 687, 3, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45233,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (363, 687, 4, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45234,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (363, 687, 1, 3, '2022-08-30 11:13:50.000000', 3)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45235,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (363, 687, 2, 3, '2022-08-30 11:13:50.000000', 2)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45236,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (363, 687, 4, 3, '2022-08-30 11:13:50.000000', 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45237,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (363, 687, 5, 3, '2022-08-30 11:13:50.000000', 4)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45238,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (363, 687, 6, 3, '2022-08-30 11:13:50.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45239,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (363, 687, 7, 3, '2022-08-30 11:13:50.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45240,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (363, 687, 9, 3, '2022-08-30 11:13:50.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45241,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (475, 687, 29, 'S', ' ', 'N', 'N', 120, 70, 'N', '')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45242,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (688, '2022-08-30 11:17:32.000000', 365, 2, 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45243,
                    "UPDATE personas SET fecha_nacimiento='2001-09-30' WHERE personas.id_persona=365",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45244,
                    "SELECT id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais FROM ubicaciones WHERE id_ubicacion = '365'",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45245,
                    "SELECT id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo, madre, alta, nacido_vivo FROM personas WHERE id_persona = '365'",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45246,
                    "SELECT id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais FROM ubicaciones WHERE id_ubicacion = '365'",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45247,
                    "SELECT id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo, madre, alta, nacido_vivo FROM personas WHERE id_persona = '365'",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45248,
                    "UPDATE ubicaciones SET num_vivienda='00' WHERE ubicaciones.id_ubicacion=365",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45249,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, fecha_fin_embarazo, id_tipos_fin_embarazos) VALUES (689, '2022-08-30 11:40:39.000000', 362, 2, 2, '2022-08-25 00:00:00.000000', 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45250,
                    "INSERT INTO control_puerperio (id_control_puerperio, id_control) VALUES (92, 689)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45251,
                    "UPDATE personas SET alta=1 WHERE personas.id_persona=362",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45252,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (402, 685, 2, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45253,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (402, 685, 3, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45254,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (402, 685, 1, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45255,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (402, 685, 4, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45256,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (402, 685, 1, 1, '2022-08-30 11:46:50.000000', 3)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45257,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (402, 685, 2, 1, '2022-08-30 11:46:50.000000', 2)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45258,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (402, 685, 4, 1, '2022-08-30 11:46:50.000000', 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45259,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (402, 685, 5, 1, '2022-08-30 11:46:50.000000', 4)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45260,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (402, 685, 6, 1, '2022-08-30 11:46:50.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45261,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (402, 685, 7, 1, '2022-08-30 11:46:50.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45262,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (402, 685, 9, 1, '2022-08-30 11:46:50.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45263,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (476, 685, 8, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45264,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (404, 'GARCIA', 'PAULA', '45055717', '2005-06-11 00:00:00.000000', 1, 12, 'F')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45265,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (690, '2022-08-30 16:03:46.000000', 404, 1, 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45266,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (404, 404, 29, 3, '0', '2022-08-30 16:03:46.000000', '', 12)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45267,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, abortos, planificado, fum, fpp) VALUES (320, 404, 690, 18, 2, 1, 0, '2024-05-22 00:00:00.000000', '2028-02-23 00:00:00.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45268,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (320, 3)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45269,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (691, '2022-08-30 16:37:36.000000', 366, 2, 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45270,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (366, 691, 2, 'S')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45271,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (366, 691, 3, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45272,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (366, 691, 4, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45273,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (366, 691, 6, 2, '2022-08-30 16:40:49.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45274,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (366, 691, 7, 2, '2022-08-30 16:40:49.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45275,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (477, 691, 21, 'S', ' ', 'N', 'N', 120, 60, 'N', '')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45276,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (405, 'CEBALLOS', 'PATRICIA', '37418553', '1993-07-24 00:00:00.000000', 1, 12, 'F')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45277,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (692, '2022-08-31 09:46:43.000000', 405, 1, 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45278,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (405, 405, 41, 3, '00', '2022-08-31 09:46:43.000000', '', 12)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45279,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, planificado, fum, fpp) VALUES (321, 405, 692, 0, 5, 4, 1, '2022-03-01 00:00:00.000000', '2022-12-06 00:00:00.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45280,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (321, 4)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45281,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (405, 692, 2, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45282,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (405, 692, 3, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45283,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (405, 692, 1, 'S')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45284,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (405, 692, 4, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45285,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (405, 692, 1, 2, '2022-08-31 09:49:26.000000', 3)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45286,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (405, 692, 2, 2, '2022-08-31 09:49:26.000000', 2)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45287,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (405, 692, 4, 2, '2022-08-31 09:49:26.000000', 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45288,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (405, 692, 5, 2, '2022-08-31 09:49:26.000000', 4)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45289,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (405, 692, 6, 2, '2022-08-31 09:49:26.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45290,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (405, 692, 7, 2, '2022-08-31 09:49:26.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45291,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (405, 692, 9, 2, '2022-08-31 09:49:26.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45292,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (478, 692, 26, 'S', ' ', 'N', 'N', 90, 60, 'N', '')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45293,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (693, '2022-08-31 10:04:31.000000', 383, 2, 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45294,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (383, 693, 2, 'S')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45295,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (383, 693, 3, 'C')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45296,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (383, 693, 4, 'P')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45297,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (383, 693, 2, 3, '2022-08-31 10:10:13.000000', 2)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45298,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (383, 693, 5, 3, '2022-08-31 10:10:13.000000', 4)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45299,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (383, 693, 6, 3, '2022-08-31 10:10:13.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45300,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (383, 693, 7, 3, '2022-08-31 10:10:13.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45301,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (479, 693, 30, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45302,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (694, '2022-08-31 10:14:42.000000', 382, 2, 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45303,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (382, 694, 2, 'S')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45304,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (382, 694, 3, 'P')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45305,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (382, 694, 4, 'C')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45306,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (382, 694, 1, 3, '2022-08-31 10:17:14.000000', 3)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45307,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (382, 694, 2, 3, '2022-08-31 10:17:14.000000', 2)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45308,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (382, 694, 5, 3, '2022-08-31 10:17:14.000000', 4)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45309,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (382, 694, 6, 3, '2022-08-31 10:17:14.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45310,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (382, 694, 7, 3, '2022-08-31 10:17:14.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45311,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (382, 694, 9, 3, '2022-08-31 10:17:14.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45312,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (480, 694, 39, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45313,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (406, 'LORENZO', 'MARTA', '', '1987-06-18 00:00:00.000000', 1, 12, 'F')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45314,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (695, '2022-08-31 10:31:01.000000', 406, 1, 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45315,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (406, 406, 41, 3, '00', '2022-08-31 10:31:01.000000', '', 12)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45316,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, planificado, fum, fpp) VALUES (322, 406, 695, 0, 7, 6, 0, '2022-06-07 00:00:00.000000', '2023-03-14 00:00:00.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45317,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (406, 695, 2, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45318,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (406, 695, 3, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45319,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (406, 695, 1, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45320,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (406, 695, 4, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45321,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (406, 695, 1, 1, '2022-08-31 10:34:32.000000', 3)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45322,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (406, 695, 2, 1, '2022-08-31 10:34:32.000000', 2)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45323,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (406, 695, 4, 1, '2022-08-31 10:34:32.000000', 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45324,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (406, 695, 5, 1, '2022-08-31 10:34:32.000000', 4)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45325,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (406, 695, 6, 1, '2022-08-31 10:34:32.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45326,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (406, 695, 7, 1, '2022-08-31 10:34:32.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45327,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (406, 695, 9, 1, '2022-08-31 10:34:32.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45328,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (481, 695, 12, 'S', ' ', 'N', 'N', 120, 60, 'N', '')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45329,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (407, 'AVALLAY', 'MARCELA', '18899620', '1994-09-22 00:00:00.000000', 1, 12, 'F')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45330,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (696, '2022-08-31 11:00:15.000000', 407, 1, 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45331,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (407, 407, 41, 3, '00', '2022-08-31 11:00:15.000000', '', 12)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45332,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, planificado, fum, fpp) VALUES (323, 407, 696, 0, 5, 4, 0, '2022-03-30 00:00:00.000000', '2023-01-04 00:00:00.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45333,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (407, 696, 2, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45334,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (407, 696, 3, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45335,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (407, 696, 1, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45336,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (407, 696, 4, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45337,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (407, 696, 1, 2, '2022-08-31 11:04:52.000000', 3)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45338,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (407, 696, 2, 2, '2022-08-31 11:04:52.000000', 2)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45339,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (407, 696, 4, 2, '2022-08-31 11:04:52.000000', 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45340,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (407, 696, 5, 2, '2022-08-31 11:04:52.000000', 4)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45341,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (407, 696, 6, 2, '2022-08-31 11:04:52.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45342,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (407, 696, 7, 2, '2022-08-31 11:04:52.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45343,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (407, 696, 9, 2, '2022-08-31 11:04:52.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45344,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (482, 696, 22, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45345,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=482",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45346,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-31', resultado='N' WHERE laboratorios_realizados.id_persona=407 AND laboratorios_realizados.id_control=696 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45347,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-31', resultado='N' WHERE laboratorios_realizados.id_persona=407 AND laboratorios_realizados.id_control=696 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45348,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-31', resultado='N' WHERE laboratorios_realizados.id_persona=407 AND laboratorios_realizados.id_control=696 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45349,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-31', resultado='N' WHERE laboratorios_realizados.id_persona=407 AND laboratorios_realizados.id_control=696 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45350,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-31', resultado='79.0' WHERE laboratorios_realizados.id_persona=407 AND laboratorios_realizados.id_control=696 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45351,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-31', resultado='O+' WHERE laboratorios_realizados.id_persona=407 AND laboratorios_realizados.id_control=696 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45352,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=479",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45353,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-31', resultado='N' WHERE laboratorios_realizados.id_persona=383 AND laboratorios_realizados.id_control=693 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45354,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-31', resultado='97.0' WHERE laboratorios_realizados.id_persona=383 AND laboratorios_realizados.id_control=693 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45355,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=480",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45356,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-31', resultado='N' WHERE laboratorios_realizados.id_persona=382 AND laboratorios_realizados.id_control=694 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45357,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-31', resultado='N' WHERE laboratorios_realizados.id_persona=382 AND laboratorios_realizados.id_control=694 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45358,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-31', resultado='76.0' WHERE laboratorios_realizados.id_persona=382 AND laboratorios_realizados.id_control=694 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45359,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-31', resultado='O+' WHERE laboratorios_realizados.id_persona=382 AND laboratorios_realizados.id_control=694 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45360,
                    "UPDATE control_embarazo SET eco='P', detalle_eco='Liquido amniótico disminuido' WHERE control_embarazo.id_control_embarazo=478",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45361,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-31', resultado='N' WHERE laboratorios_realizados.id_persona=405 AND laboratorios_realizados.id_control=692 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45362,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-31', resultado='N' WHERE laboratorios_realizados.id_persona=405 AND laboratorios_realizados.id_control=692 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45363,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-31', resultado='N' WHERE laboratorios_realizados.id_persona=405 AND laboratorios_realizados.id_control=692 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45364,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-31', resultado='N' WHERE laboratorios_realizados.id_persona=405 AND laboratorios_realizados.id_control=692 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45365,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-31', resultado='94.0' WHERE laboratorios_realizados.id_persona=405 AND laboratorios_realizados.id_control=692 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45366,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-31', resultado='O+' WHERE laboratorios_realizados.id_persona=405 AND laboratorios_realizados.id_control=692 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45367,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=481",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45368,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-31', resultado='N' WHERE laboratorios_realizados.id_persona=406 AND laboratorios_realizados.id_control=695 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45369,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-31', resultado='N' WHERE laboratorios_realizados.id_persona=406 AND laboratorios_realizados.id_control=695 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45370,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-31', resultado='N' WHERE laboratorios_realizados.id_persona=406 AND laboratorios_realizados.id_control=695 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45371,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-31', resultado='N' WHERE laboratorios_realizados.id_persona=406 AND laboratorios_realizados.id_control=695 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45372,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-31', resultado='99.0' WHERE laboratorios_realizados.id_persona=406 AND laboratorios_realizados.id_control=695 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45373,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-08-31', resultado='O+' WHERE laboratorios_realizados.id_persona=406 AND laboratorios_realizados.id_control=695 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45374,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (408, 'DIAZ', 'ELIDA DALILA', '40147604', '1997-02-28 00:00:00.000000', 1, 12, 'F')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45375,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (697, '2022-08-31 15:09:40.000000', 408, 1, 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45376,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (408, 408, 27, 3, '00', '2022-08-31 15:09:40.000000', '', 12)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45377,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, cesareas, planificado, fum, fpp) VALUES (324, 408, 697, 0, 2, 1, 1, '2021-12-31 00:00:00.000000', '2022-10-07 00:00:00.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45378,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (408, 697, 2, 'S')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45379,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (408, 697, 3, 'C')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45380,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (408, 697, 1, 'S')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45381,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (408, 697, 4, 'P')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45382,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (408, 697, 1, 3, '2022-08-31 15:12:57.000000', 3)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45383,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (408, 697, 2, 3, '2022-08-31 15:12:57.000000', 2)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45384,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (408, 697, 4, 3, '2022-08-31 15:12:57.000000', 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45385,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (408, 697, 5, 3, '2022-08-31 15:12:57.000000', 4)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45386,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (408, 697, 6, 3, '2022-08-31 15:12:57.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45387,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (408, 697, 7, 3, '2022-08-31 15:12:57.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45388,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (408, 697, 9, 3, '2022-08-31 15:12:57.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45389,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (483, 697, 34, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45390,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (409, 'CEPILLO', 'LEILA EUGENIA', '42521293', '2000-04-30 00:00:00.000000', 1, 12, 'F')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45391,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (698, '2022-08-31 15:29:36.000000', 409, 1, 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45392,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (409, 409, 27, 3, '00', '2022-08-31 15:29:36.000000', '', 12)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45393,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, planificado, fum, fpp) VALUES (325, 409, 698, 0, 2, 1, 1, '2022-01-19 00:00:00.000000', '2022-10-26 00:00:00.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45394,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (409, 698, 2, 'S')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45395,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (409, 698, 3, 'C')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45396,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (409, 698, 1, 'S')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45397,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (409, 698, 4, 'C')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45398,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (409, 698, 1, 3, '2022-08-31 15:33:10.000000', 3)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45399,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (409, 698, 2, 3, '2022-08-31 15:33:10.000000', 2)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45400,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (409, 698, 4, 3, '2022-08-31 15:33:10.000000', 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45401,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (409, 698, 5, 3, '2022-08-31 15:33:10.000000', 4)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45402,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (409, 698, 6, 3, '2022-08-31 15:33:10.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45403,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (409, 698, 7, 3, '2022-08-31 15:33:10.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45404,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (409, 698, 9, 3, '2022-08-31 15:33:10.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45405,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (484, 698, 32, 'S', ' ', 'N', 'N', 120, 70, 'N', '')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45406,
                    "SELECT id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo, madre, alta, nacido_vivo FROM personas WHERE id_persona = '365'",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45407,
                    "SELECT id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais FROM ubicaciones WHERE id_ubicacion = '365'",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45408,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (410, 'PEREZ', 'YAMILA YESICA', '44660488', '2003-01-30 00:00:00.000000', 1, 12, 'F')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45409,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, georeferencia) VALUES (699, '2022-08-31 16:47:01.000000', 410, 1, 1, '-22.2729233,-62.717022')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45410,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (410, 410, 29, 3, '00', '2022-08-31 16:47:01.000000', '-22.2729233,-62.717022', 12)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45411,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (326, 410, 699, 16, '2019-12-01 00:00:00.000000', 2, 1, 0, '2022-04-01 00:00:00.000000', '2023-01-06 00:00:00.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45412,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (410, 699, 2, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45413,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (410, 699, 3, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45414,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (410, 699, 1, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45415,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (410, 699, 4, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45416,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (410, 699, 1, 2, '2022-08-31 16:50:38.000000', 3)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45417,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (410, 699, 2, 2, '2022-08-31 16:50:38.000000', 2)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45418,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (410, 699, 4, 2, '2022-08-31 16:50:38.000000', 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45419,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (410, 699, 5, 2, '2022-08-31 16:50:38.000000', 4)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45420,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (410, 699, 6, 2, '2022-08-31 16:50:38.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45421,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (410, 699, 7, 2, '2022-08-31 16:50:38.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45422,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (410, 699, 9, 2, '2022-08-31 16:50:38.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45423,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (485, 699, 21, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45424,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (411, 'GARCIA', 'PAULA', '45055717', '2005-06-11 00:00:00.000000', 1, 12, 'F')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45425,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, georeferencia) VALUES (700, '2022-08-31 16:51:55.000000', 411, 1, 1, '-22.2729258,-62.7170218')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45426,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (411, 411, 29, 3, '00', '2022-08-31 16:51:55.000000', '-22.2729258,-62.7170218', 12)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45427,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, abortos, planificado, fum, fpp) VALUES (327, 411, 700, 0, 2, 1, 0, '2022-05-23 00:00:00.000000', '2023-02-27 00:00:00.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45428,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (411, 700, 2, 'S')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45429,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (411, 700, 3, 'C')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45430,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (411, 700, 1, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45431,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (411, 700, 4, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45432,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (411, 700, 1, 2, '2022-08-31 16:55:44.000000', 3)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45433,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (411, 700, 2, 2, '2022-08-31 16:55:44.000000', 2)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45434,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (411, 700, 4, 2, '2022-08-31 16:55:44.000000', 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45435,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (411, 700, 5, 2, '2022-08-31 16:55:44.000000', 4)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45436,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (411, 700, 6, 2, '2022-08-31 16:55:44.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45437,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (411, 700, 7, 2, '2022-08-31 16:55:44.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45438,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (411, 700, 9, 2, '2022-08-31 16:55:44.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45439,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (486, 700, 14, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45440,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (412, 'ZAMORA', 'ERINA', '45818373', '2004-08-18 00:00:00.000000', 1, 12, 'F')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45441,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, georeferencia) VALUES (701, '2022-08-31 16:57:38.000000', 412, 1, 1, '-22.2729222,-62.7170155')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45442,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (412, 412, 29, 3, '00', '2022-08-31 16:57:38.000000', '-22.2729222,-62.7170155', 12)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45443,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, planificado, fum, fpp) VALUES (328, 412, 701, 0, 3, 2, 1, '2023-05-07 00:00:00.000000', '2024-02-11 00:00:00.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45444,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (412, 701, 2, 'S')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45445,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (412, 701, 3, 'C')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45446,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (412, 701, 1, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45447,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (412, 701, 4, 'C')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45448,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (412, 701, 1, 3, '2022-08-31 17:02:46.000000', 3)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45449,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (412, 701, 2, 3, '2022-08-31 17:02:46.000000', 2)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45450,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (412, 701, 4, 3, '2022-08-31 17:02:46.000000', 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45451,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (412, 701, 5, 3, '2022-08-31 17:02:46.000000', 4)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45452,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (412, 701, 6, 3, '2022-08-31 17:02:46.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45453,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (412, 701, 7, 3, '2022-08-31 17:02:46.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45454,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (412, 701, 9, 3, '2022-08-31 17:02:46.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45455,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (487, 701, 35, 'S', ' ', 'N', 'N', 120, 70, 'N', '')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45456,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (413, 'PEREZ', 'MARILU', '43452625', '2001-09-30 00:00:00.000000', 1, 12, 'F')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45457,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, georeferencia) VALUES (702, '2022-08-31 17:05:30.000000', 413, 1, 1, '-22.2729253,-62.717021')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45458,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (413, 413, 39, 3, '00', '2022-08-31 17:05:30.000000', '-22.2729253,-62.717021', 12)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45459,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (329, 413, 702, 0, '2020-03-29 00:00:00.000000', 3, 2, 0, '2022-02-14 00:00:00.000000', '2022-11-21 00:00:00.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45460,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (413, 702, 2, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45461,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (413, 702, 3, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45462,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (413, 702, 1, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45463,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (413, 702, 4, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45464,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (413, 702, 5, 3, '2022-08-31 17:09:50.000000', 4)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45465,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (413, 702, 6, 3, '2022-08-31 17:09:50.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45466,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (413, 702, 7, 3, '2022-08-31 17:09:50.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45467,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (488, 702, 28, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45468,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (414, 'SANDOVAL', 'HILDA', '34324751', '1986-12-29 00:00:00.000000', 1, 12, 'F')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45469,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, georeferencia) VALUES (703, '2022-08-31 17:12:18.000000', 414, 1, 1, '-22.2729258,-62.7170218')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45470,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (414, 414, 39, 3, '00', '2022-08-31 17:12:18.000000', '-22.2729258,-62.7170218', 12)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45471,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (415, 'ZAMORA', 'ERINA', '45818373', '2004-08-18 00:00:00.000000', 1, 12, 'F')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45472,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, georeferencia) VALUES (704, '2022-08-31 17:21:31.000000', 415, 1, 1, '-22.2729299,-62.717023')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45473,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (415, 415, 29, 3, '00', '2022-08-31 17:21:31.000000', '-22.2729299,-62.717023', 12)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45474,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, planificado, fum, fpp) VALUES (330, 415, 704, 0, 2, 1, 0, '2023-05-07 00:00:00.000000', '2024-02-11 00:00:00.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45475,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (415, 704, 2, 'S')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45476,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (415, 704, 3, 'C')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45477,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (415, 704, 1, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45478,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (415, 704, 4, 'N')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45479,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (415, 704, 1, 3, '2022-08-31 17:24:14.000000', 3)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45480,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (415, 704, 2, 3, '2022-08-31 17:24:14.000000', 2)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45481,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (415, 704, 4, 3, '2022-08-31 17:24:14.000000', 1)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45482,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (415, 704, 5, 3, '2022-08-31 17:24:14.000000', 4)",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45483,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (415, 704, 6, 3, '2022-08-31 17:24:14.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45484,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (415, 704, 7, 3, '2022-08-31 17:24:14.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45485,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (415, 704, 9, 3, '2022-08-31 17:24:14.000000')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45486,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (489, 704, 35, 'S', ' ', 'N', 'N', 120, 70, 'N', '')",
                    "2022-09-03 15:24:36",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45487,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=448",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45488,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=344 AND laboratorios_realizados.id_control=659 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45489,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='10.3' WHERE laboratorios_realizados.id_persona=344 AND laboratorios_realizados.id_control=659 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45490,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='65' WHERE laboratorios_realizados.id_persona=344 AND laboratorios_realizados.id_control=659 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45491,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=449",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45492,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=386 AND laboratorios_realizados.id_control=660 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45493,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=386 AND laboratorios_realizados.id_control=660 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45494,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=386 AND laboratorios_realizados.id_control=660 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45495,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=386 AND laboratorios_realizados.id_control=660 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45496,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='8.6' WHERE laboratorios_realizados.id_persona=386 AND laboratorios_realizados.id_control=660 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45497,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='109' WHERE laboratorios_realizados.id_persona=386 AND laboratorios_realizados.id_control=660 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45498,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='0+' WHERE laboratorios_realizados.id_persona=386 AND laboratorios_realizados.id_control=660 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45499,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='12' WHERE laboratorios_realizados.id_persona=351 AND laboratorios_realizados.id_control=661 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45500,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=451",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45501,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=387 AND laboratorios_realizados.id_control=662 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45502,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=387 AND laboratorios_realizados.id_control=662 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45503,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=387 AND laboratorios_realizados.id_control=662 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45504,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=387 AND laboratorios_realizados.id_control=662 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45505,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='9.6' WHERE laboratorios_realizados.id_persona=387 AND laboratorios_realizados.id_control=662 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45506,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='91' WHERE laboratorios_realizados.id_persona=387 AND laboratorios_realizados.id_control=662 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45507,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='0+' WHERE laboratorios_realizados.id_persona=387 AND laboratorios_realizados.id_control=662 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45508,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=452",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45509,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=388 AND laboratorios_realizados.id_control=663 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45510,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=388 AND laboratorios_realizados.id_control=663 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45511,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=388 AND laboratorios_realizados.id_control=663 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45512,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=388 AND laboratorios_realizados.id_control=663 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45513,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='11.3' WHERE laboratorios_realizados.id_persona=388 AND laboratorios_realizados.id_control=663 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45514,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='88' WHERE laboratorios_realizados.id_persona=388 AND laboratorios_realizados.id_control=663 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45515,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=453",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45516,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=389 AND laboratorios_realizados.id_control=664 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45517,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=389 AND laboratorios_realizados.id_control=664 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45518,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=389 AND laboratorios_realizados.id_control=664 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45519,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=389 AND laboratorios_realizados.id_control=664 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45520,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='9.6' WHERE laboratorios_realizados.id_persona=389 AND laboratorios_realizados.id_control=664 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45521,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='70' WHERE laboratorios_realizados.id_persona=389 AND laboratorios_realizados.id_control=664 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45522,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='0+' WHERE laboratorios_realizados.id_persona=389 AND laboratorios_realizados.id_control=664 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45523,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=454",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45524,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=390 AND laboratorios_realizados.id_control=665 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45525,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=390 AND laboratorios_realizados.id_control=665 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45526,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=390 AND laboratorios_realizados.id_control=665 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45527,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=390 AND laboratorios_realizados.id_control=665 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45528,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='10' WHERE laboratorios_realizados.id_persona=390 AND laboratorios_realizados.id_control=665 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45529,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='98' WHERE laboratorios_realizados.id_persona=390 AND laboratorios_realizados.id_control=665 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45530,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='0+' WHERE laboratorios_realizados.id_persona=390 AND laboratorios_realizados.id_control=665 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45531,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=455",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45532,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=391 AND laboratorios_realizados.id_control=666 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45533,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=391 AND laboratorios_realizados.id_control=666 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45534,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=391 AND laboratorios_realizados.id_control=666 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45535,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=391 AND laboratorios_realizados.id_control=666 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45536,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='11.6' WHERE laboratorios_realizados.id_persona=391 AND laboratorios_realizados.id_control=666 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45537,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='86' WHERE laboratorios_realizados.id_persona=391 AND laboratorios_realizados.id_control=666 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45538,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='0+' WHERE laboratorios_realizados.id_persona=391 AND laboratorios_realizados.id_control=666 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45539,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=456",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45540,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=392 AND laboratorios_realizados.id_control=667 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45541,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=392 AND laboratorios_realizados.id_control=667 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45542,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=392 AND laboratorios_realizados.id_control=667 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45543,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=392 AND laboratorios_realizados.id_control=667 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45544,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='11.6' WHERE laboratorios_realizados.id_persona=392 AND laboratorios_realizados.id_control=667 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45545,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='87' WHERE laboratorios_realizados.id_persona=392 AND laboratorios_realizados.id_control=667 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45546,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='0+' WHERE laboratorios_realizados.id_persona=392 AND laboratorios_realizados.id_control=667 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45547,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=457",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45548,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=355 AND laboratorios_realizados.id_control=668 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45549,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=355 AND laboratorios_realizados.id_control=668 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45550,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=355 AND laboratorios_realizados.id_control=668 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45551,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='9.6' WHERE laboratorios_realizados.id_persona=355 AND laboratorios_realizados.id_control=668 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45552,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='108' WHERE laboratorios_realizados.id_persona=355 AND laboratorios_realizados.id_control=668 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45553,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=347 AND laboratorios_realizados.id_control=594 AND laboratorios_realizados.id_laboratorio=8 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45554,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=458",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45555,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=347 AND laboratorios_realizados.id_control=669 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45556,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='10.6' WHERE laboratorios_realizados.id_persona=347 AND laboratorios_realizados.id_control=669 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45557,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='95' WHERE laboratorios_realizados.id_persona=347 AND laboratorios_realizados.id_control=669 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45558,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=459",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45559,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=393 AND laboratorios_realizados.id_control=670 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45560,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=393 AND laboratorios_realizados.id_control=670 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45561,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=393 AND laboratorios_realizados.id_control=670 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45562,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=393 AND laboratorios_realizados.id_control=670 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45563,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='10.6' WHERE laboratorios_realizados.id_persona=393 AND laboratorios_realizados.id_control=670 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45564,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='65' WHERE laboratorios_realizados.id_persona=393 AND laboratorios_realizados.id_control=670 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45565,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=460",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45566,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=357 AND laboratorios_realizados.id_control=671 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45567,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=357 AND laboratorios_realizados.id_control=671 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45568,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=357 AND laboratorios_realizados.id_control=671 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45569,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='10.3' WHERE laboratorios_realizados.id_persona=357 AND laboratorios_realizados.id_control=671 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45570,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='87' WHERE laboratorios_realizados.id_persona=357 AND laboratorios_realizados.id_control=671 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45571,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=461",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45572,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='12' WHERE laboratorios_realizados.id_persona=352 AND laboratorios_realizados.id_control=672 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45573,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='94' WHERE laboratorios_realizados.id_persona=352 AND laboratorios_realizados.id_control=672 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45574,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=462",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45575,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=394 AND laboratorios_realizados.id_control=673 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45576,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=394 AND laboratorios_realizados.id_control=673 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45577,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=394 AND laboratorios_realizados.id_control=673 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45578,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=394 AND laboratorios_realizados.id_control=673 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45579,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='11' WHERE laboratorios_realizados.id_persona=394 AND laboratorios_realizados.id_control=673 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45580,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='76' WHERE laboratorios_realizados.id_persona=394 AND laboratorios_realizados.id_control=673 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45581,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='0+' WHERE laboratorios_realizados.id_persona=394 AND laboratorios_realizados.id_control=673 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45582,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=463",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45583,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=395 AND laboratorios_realizados.id_control=674 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45584,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=395 AND laboratorios_realizados.id_control=674 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45585,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=395 AND laboratorios_realizados.id_control=674 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45586,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=395 AND laboratorios_realizados.id_control=674 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45587,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='11' WHERE laboratorios_realizados.id_persona=395 AND laboratorios_realizados.id_control=674 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45588,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='114' WHERE laboratorios_realizados.id_persona=395 AND laboratorios_realizados.id_control=674 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45589,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='0+' WHERE laboratorios_realizados.id_persona=395 AND laboratorios_realizados.id_control=674 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45590,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=464",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45591,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=348 AND laboratorios_realizados.id_control=675 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45592,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=348 AND laboratorios_realizados.id_control=675 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45593,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=348 AND laboratorios_realizados.id_control=675 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45594,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='10' WHERE laboratorios_realizados.id_persona=348 AND laboratorios_realizados.id_control=675 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45595,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='70' WHERE laboratorios_realizados.id_persona=348 AND laboratorios_realizados.id_control=675 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45596,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=465",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45597,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=396 AND laboratorios_realizados.id_control=676 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45598,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=396 AND laboratorios_realizados.id_control=676 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45599,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=396 AND laboratorios_realizados.id_control=676 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45600,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=396 AND laboratorios_realizados.id_control=676 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45601,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='12.3' WHERE laboratorios_realizados.id_persona=396 AND laboratorios_realizados.id_control=676 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45602,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='99' WHERE laboratorios_realizados.id_persona=396 AND laboratorios_realizados.id_control=676 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45603,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='0+' WHERE laboratorios_realizados.id_persona=396 AND laboratorios_realizados.id_control=676 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45604,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=466",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45605,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=397 AND laboratorios_realizados.id_control=677 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45606,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=397 AND laboratorios_realizados.id_control=677 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45607,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=397 AND laboratorios_realizados.id_control=677 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45608,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=397 AND laboratorios_realizados.id_control=677 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45609,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='11' WHERE laboratorios_realizados.id_persona=397 AND laboratorios_realizados.id_control=677 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45610,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='90' WHERE laboratorios_realizados.id_persona=397 AND laboratorios_realizados.id_control=677 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45611,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='0+' WHERE laboratorios_realizados.id_persona=397 AND laboratorios_realizados.id_control=677 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45612,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=467",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45613,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=248 AND laboratorios_realizados.id_control=678 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45614,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=248 AND laboratorios_realizados.id_control=678 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45615,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=248 AND laboratorios_realizados.id_control=678 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45616,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='10' WHERE laboratorios_realizados.id_persona=248 AND laboratorios_realizados.id_control=678 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45617,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='73' WHERE laboratorios_realizados.id_persona=248 AND laboratorios_realizados.id_control=678 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45618,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=468",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45619,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=398 AND laboratorios_realizados.id_control=679 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45620,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=398 AND laboratorios_realizados.id_control=679 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45621,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=398 AND laboratorios_realizados.id_control=679 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45622,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=398 AND laboratorios_realizados.id_control=679 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45623,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='11' WHERE laboratorios_realizados.id_persona=398 AND laboratorios_realizados.id_control=679 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45624,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='74' WHERE laboratorios_realizados.id_persona=398 AND laboratorios_realizados.id_control=679 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45625,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='0+' WHERE laboratorios_realizados.id_persona=398 AND laboratorios_realizados.id_control=679 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45626,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=469",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45627,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=399 AND laboratorios_realizados.id_control=680 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45628,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=399 AND laboratorios_realizados.id_control=680 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45629,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=399 AND laboratorios_realizados.id_control=680 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45630,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='N' WHERE laboratorios_realizados.id_persona=399 AND laboratorios_realizados.id_control=680 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45631,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='12.3' WHERE laboratorios_realizados.id_persona=399 AND laboratorios_realizados.id_control=680 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45632,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='81' WHERE laboratorios_realizados.id_persona=399 AND laboratorios_realizados.id_control=680 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45633,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-22', resultado='0+' WHERE laboratorios_realizados.id_persona=399 AND laboratorios_realizados.id_control=680 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45634,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=470",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45635,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=400 AND laboratorios_realizados.id_control=681 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45636,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=400 AND laboratorios_realizados.id_control=681 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45637,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=400 AND laboratorios_realizados.id_control=681 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45638,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=400 AND laboratorios_realizados.id_control=681 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45639,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='11' WHERE laboratorios_realizados.id_persona=400 AND laboratorios_realizados.id_control=681 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45640,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='86' WHERE laboratorios_realizados.id_persona=400 AND laboratorios_realizados.id_control=681 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45641,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=400 AND laboratorios_realizados.id_control=681 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45642,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=471",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45643,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=360 AND laboratorios_realizados.id_control=682 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45644,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=360 AND laboratorios_realizados.id_control=682 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45645,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=360 AND laboratorios_realizados.id_control=682 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45646,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='11.3' WHERE laboratorios_realizados.id_persona=360 AND laboratorios_realizados.id_control=682 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45647,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='87' WHERE laboratorios_realizados.id_persona=360 AND laboratorios_realizados.id_control=682 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45648,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=472",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45649,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=361 AND laboratorios_realizados.id_control=683 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45650,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='9' WHERE laboratorios_realizados.id_persona=361 AND laboratorios_realizados.id_control=683 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45651,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='88' WHERE laboratorios_realizados.id_persona=361 AND laboratorios_realizados.id_control=683 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45652,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=473",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45653,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=401 AND laboratorios_realizados.id_control=684 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45654,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=401 AND laboratorios_realizados.id_control=684 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45655,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=401 AND laboratorios_realizados.id_control=684 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45656,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=401 AND laboratorios_realizados.id_control=684 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45657,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='11' WHERE laboratorios_realizados.id_persona=401 AND laboratorios_realizados.id_control=684 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45658,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='79' WHERE laboratorios_realizados.id_persona=401 AND laboratorios_realizados.id_control=684 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45659,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=401 AND laboratorios_realizados.id_control=684 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45660,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100321",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45661,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100209 AND laboratorios_realizados.id_control=100353 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45662,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100209 AND laboratorios_realizados.id_control=100353 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45663,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100209 AND laboratorios_realizados.id_control=100353 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45664,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100209 AND laboratorios_realizados.id_control=100353 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45665,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='10.3' WHERE laboratorios_realizados.id_persona=100209 AND laboratorios_realizados.id_control=100353 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45666,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='89' WHERE laboratorios_realizados.id_persona=100209 AND laboratorios_realizados.id_control=100353 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45667,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100209 AND laboratorios_realizados.id_control=100353 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45668,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100300",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45669,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100211 AND laboratorios_realizados.id_control=100355 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45670,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100211 AND laboratorios_realizados.id_control=100355 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45671,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100211 AND laboratorios_realizados.id_control=100355 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45672,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100211 AND laboratorios_realizados.id_control=100355 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45673,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='11.6' WHERE laboratorios_realizados.id_persona=100211 AND laboratorios_realizados.id_control=100355 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45674,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='105' WHERE laboratorios_realizados.id_persona=100211 AND laboratorios_realizados.id_control=100355 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45675,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100211 AND laboratorios_realizados.id_control=100355 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45676,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100298",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45677,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100210 AND laboratorios_realizados.id_control=100354 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45678,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100210 AND laboratorios_realizados.id_control=100354 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45679,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100210 AND laboratorios_realizados.id_control=100354 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45680,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100210 AND laboratorios_realizados.id_control=100354 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45681,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='10' WHERE laboratorios_realizados.id_persona=100210 AND laboratorios_realizados.id_control=100354 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45682,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='70' WHERE laboratorios_realizados.id_persona=100210 AND laboratorios_realizados.id_control=100354 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45683,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100210 AND laboratorios_realizados.id_control=100354 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45684,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100299",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45685,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100212 AND laboratorios_realizados.id_control=100356 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45686,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100212 AND laboratorios_realizados.id_control=100356 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45687,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100212 AND laboratorios_realizados.id_control=100356 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45688,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100212 AND laboratorios_realizados.id_control=100356 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45689,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='13' WHERE laboratorios_realizados.id_persona=100212 AND laboratorios_realizados.id_control=100356 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45690,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='84' WHERE laboratorios_realizados.id_persona=100212 AND laboratorios_realizados.id_control=100356 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45691,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100212 AND laboratorios_realizados.id_control=100356 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45692,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100301",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45693,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100214 AND laboratorios_realizados.id_control=100358 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45694,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100214 AND laboratorios_realizados.id_control=100358 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45695,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100214 AND laboratorios_realizados.id_control=100358 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45696,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100214 AND laboratorios_realizados.id_control=100358 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45697,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='10' WHERE laboratorios_realizados.id_persona=100214 AND laboratorios_realizados.id_control=100358 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45698,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='79' WHERE laboratorios_realizados.id_persona=100214 AND laboratorios_realizados.id_control=100358 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45699,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0-' WHERE laboratorios_realizados.id_persona=100214 AND laboratorios_realizados.id_control=100358 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45700,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100302",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45701,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100215 AND laboratorios_realizados.id_control=100359 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45702,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100215 AND laboratorios_realizados.id_control=100359 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45703,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100215 AND laboratorios_realizados.id_control=100359 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45704,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100215 AND laboratorios_realizados.id_control=100359 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45705,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='10' WHERE laboratorios_realizados.id_persona=100215 AND laboratorios_realizados.id_control=100359 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45706,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='70' WHERE laboratorios_realizados.id_persona=100215 AND laboratorios_realizados.id_control=100359 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45707,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='A+' WHERE laboratorios_realizados.id_persona=100215 AND laboratorios_realizados.id_control=100359 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45708,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100303",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45709,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100216 AND laboratorios_realizados.id_control=100360 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45710,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100216 AND laboratorios_realizados.id_control=100360 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45711,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100216 AND laboratorios_realizados.id_control=100360 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45712,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100216 AND laboratorios_realizados.id_control=100360 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45713,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='10.6' WHERE laboratorios_realizados.id_persona=100216 AND laboratorios_realizados.id_control=100360 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45714,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='75' WHERE laboratorios_realizados.id_persona=100216 AND laboratorios_realizados.id_control=100360 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45715,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100216 AND laboratorios_realizados.id_control=100360 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45716,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100304",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45717,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100217 AND laboratorios_realizados.id_control=100361 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45718,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100217 AND laboratorios_realizados.id_control=100361 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45719,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100217 AND laboratorios_realizados.id_control=100361 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45720,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100217 AND laboratorios_realizados.id_control=100361 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45721,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='10.6' WHERE laboratorios_realizados.id_persona=100217 AND laboratorios_realizados.id_control=100361 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45722,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='75' WHERE laboratorios_realizados.id_persona=100217 AND laboratorios_realizados.id_control=100361 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45723,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100217 AND laboratorios_realizados.id_control=100361 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45724,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100305",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45725,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100218 AND laboratorios_realizados.id_control=100362 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45726,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100218 AND laboratorios_realizados.id_control=100362 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45727,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100218 AND laboratorios_realizados.id_control=100362 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45728,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100218 AND laboratorios_realizados.id_control=100362 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45729,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='8.3' WHERE laboratorios_realizados.id_persona=100218 AND laboratorios_realizados.id_control=100362 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45730,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='85' WHERE laboratorios_realizados.id_persona=100218 AND laboratorios_realizados.id_control=100362 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45731,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='A+' WHERE laboratorios_realizados.id_persona=100218 AND laboratorios_realizados.id_control=100362 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45732,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100306",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45733,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100219 AND laboratorios_realizados.id_control=100363 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45734,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100219 AND laboratorios_realizados.id_control=100363 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45735,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100219 AND laboratorios_realizados.id_control=100363 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45736,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100219 AND laboratorios_realizados.id_control=100363 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45737,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='10' WHERE laboratorios_realizados.id_persona=100219 AND laboratorios_realizados.id_control=100363 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45738,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='85' WHERE laboratorios_realizados.id_persona=100219 AND laboratorios_realizados.id_control=100363 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45739,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100219 AND laboratorios_realizados.id_control=100363 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45740,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100309",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45741,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100222 AND laboratorios_realizados.id_control=100366 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45742,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100222 AND laboratorios_realizados.id_control=100366 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45743,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100222 AND laboratorios_realizados.id_control=100366 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45744,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100222 AND laboratorios_realizados.id_control=100366 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45745,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='12.3' WHERE laboratorios_realizados.id_persona=100222 AND laboratorios_realizados.id_control=100366 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45746,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='93' WHERE laboratorios_realizados.id_persona=100222 AND laboratorios_realizados.id_control=100366 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45747,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100222 AND laboratorios_realizados.id_control=100366 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45748,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100308",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45749,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100221 AND laboratorios_realizados.id_control=100365 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45750,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100221 AND laboratorios_realizados.id_control=100365 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45751,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100221 AND laboratorios_realizados.id_control=100365 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45752,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100221 AND laboratorios_realizados.id_control=100365 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45753,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='9' WHERE laboratorios_realizados.id_persona=100221 AND laboratorios_realizados.id_control=100365 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45754,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='84' WHERE laboratorios_realizados.id_persona=100221 AND laboratorios_realizados.id_control=100365 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45755,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100221 AND laboratorios_realizados.id_control=100365 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45756,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100307",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45757,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100220 AND laboratorios_realizados.id_control=100364 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45758,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100220 AND laboratorios_realizados.id_control=100364 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45759,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100220 AND laboratorios_realizados.id_control=100364 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45760,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100220 AND laboratorios_realizados.id_control=100364 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45761,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='10' WHERE laboratorios_realizados.id_persona=100220 AND laboratorios_realizados.id_control=100364 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45762,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='72' WHERE laboratorios_realizados.id_persona=100220 AND laboratorios_realizados.id_control=100364 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45763,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100220 AND laboratorios_realizados.id_control=100364 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45764,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100311",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45765,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100224 AND laboratorios_realizados.id_control=100368 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45766,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100224 AND laboratorios_realizados.id_control=100368 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45767,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100224 AND laboratorios_realizados.id_control=100368 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45768,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100224 AND laboratorios_realizados.id_control=100368 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45769,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='10.6' WHERE laboratorios_realizados.id_persona=100224 AND laboratorios_realizados.id_control=100368 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45770,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='106' WHERE laboratorios_realizados.id_persona=100224 AND laboratorios_realizados.id_control=100368 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45771,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100224 AND laboratorios_realizados.id_control=100368 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45772,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100310",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45773,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100223 AND laboratorios_realizados.id_control=100367 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45774,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100223 AND laboratorios_realizados.id_control=100367 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45775,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100223 AND laboratorios_realizados.id_control=100367 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45776,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100223 AND laboratorios_realizados.id_control=100367 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45777,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='10.3' WHERE laboratorios_realizados.id_persona=100223 AND laboratorios_realizados.id_control=100367 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45778,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='105' WHERE laboratorios_realizados.id_persona=100223 AND laboratorios_realizados.id_control=100367 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45779,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100223 AND laboratorios_realizados.id_control=100367 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45780,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100312",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45781,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100225 AND laboratorios_realizados.id_control=100369 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45782,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100225 AND laboratorios_realizados.id_control=100369 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45783,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100225 AND laboratorios_realizados.id_control=100369 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45784,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100225 AND laboratorios_realizados.id_control=100369 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45785,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='13' WHERE laboratorios_realizados.id_persona=100225 AND laboratorios_realizados.id_control=100369 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45786,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='75' WHERE laboratorios_realizados.id_persona=100225 AND laboratorios_realizados.id_control=100369 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45787,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100225 AND laboratorios_realizados.id_control=100369 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45788,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100313",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45789,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100226 AND laboratorios_realizados.id_control=100370 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45790,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100226 AND laboratorios_realizados.id_control=100370 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45791,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100226 AND laboratorios_realizados.id_control=100370 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45792,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100226 AND laboratorios_realizados.id_control=100370 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45793,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='8.6' WHERE laboratorios_realizados.id_persona=100226 AND laboratorios_realizados.id_control=100370 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45794,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='83' WHERE laboratorios_realizados.id_persona=100226 AND laboratorios_realizados.id_control=100370 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45795,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100226 AND laboratorios_realizados.id_control=100370 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45796,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100314",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45797,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100227 AND laboratorios_realizados.id_control=100371 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45798,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100227 AND laboratorios_realizados.id_control=100371 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45799,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100227 AND laboratorios_realizados.id_control=100371 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45800,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100227 AND laboratorios_realizados.id_control=100371 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45801,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='9.3' WHERE laboratorios_realizados.id_persona=100227 AND laboratorios_realizados.id_control=100371 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45802,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='70' WHERE laboratorios_realizados.id_persona=100227 AND laboratorios_realizados.id_control=100371 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45803,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100227 AND laboratorios_realizados.id_control=100371 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45804,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100315",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45805,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100228 AND laboratorios_realizados.id_control=100372 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45806,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100228 AND laboratorios_realizados.id_control=100372 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45807,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100228 AND laboratorios_realizados.id_control=100372 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45808,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100228 AND laboratorios_realizados.id_control=100372 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45809,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='11' WHERE laboratorios_realizados.id_persona=100228 AND laboratorios_realizados.id_control=100372 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45810,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='72' WHERE laboratorios_realizados.id_persona=100228 AND laboratorios_realizados.id_control=100372 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45811,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100228 AND laboratorios_realizados.id_control=100372 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45812,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100317",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45813,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=264 AND laboratorios_realizados.id_control=100374 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45814,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=264 AND laboratorios_realizados.id_control=100374 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45815,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=264 AND laboratorios_realizados.id_control=100374 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45816,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=264 AND laboratorios_realizados.id_control=100374 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45817,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='10.6' WHERE laboratorios_realizados.id_persona=264 AND laboratorios_realizados.id_control=100374 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45818,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='76' WHERE laboratorios_realizados.id_persona=264 AND laboratorios_realizados.id_control=100374 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45819,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=264 AND laboratorios_realizados.id_control=100374 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45820,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100316",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45821,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100229 AND laboratorios_realizados.id_control=100373 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45822,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100229 AND laboratorios_realizados.id_control=100373 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45823,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100229 AND laboratorios_realizados.id_control=100373 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45824,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100229 AND laboratorios_realizados.id_control=100373 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45825,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='10' WHERE laboratorios_realizados.id_persona=100229 AND laboratorios_realizados.id_control=100373 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45826,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='77' WHERE laboratorios_realizados.id_persona=100229 AND laboratorios_realizados.id_control=100373 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45827,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100229 AND laboratorios_realizados.id_control=100373 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45828,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100318",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45829,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100230 AND laboratorios_realizados.id_control=100375 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45830,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100230 AND laboratorios_realizados.id_control=100375 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45831,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100230 AND laboratorios_realizados.id_control=100375 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45832,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100230 AND laboratorios_realizados.id_control=100375 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45833,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='9.6' WHERE laboratorios_realizados.id_persona=100230 AND laboratorios_realizados.id_control=100375 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45834,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='79' WHERE laboratorios_realizados.id_persona=100230 AND laboratorios_realizados.id_control=100375 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45835,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100230 AND laboratorios_realizados.id_control=100375 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45836,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100319",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45837,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='11.3' WHERE laboratorios_realizados.id_persona=100231 AND laboratorios_realizados.id_control=100376 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45838,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='69' WHERE laboratorios_realizados.id_persona=100231 AND laboratorios_realizados.id_control=100376 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45839,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100326",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45840,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100232 AND laboratorios_realizados.id_control=100377 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45841,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100232 AND laboratorios_realizados.id_control=100377 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45842,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100232 AND laboratorios_realizados.id_control=100377 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45843,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100232 AND laboratorios_realizados.id_control=100377 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45844,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='10' WHERE laboratorios_realizados.id_persona=100232 AND laboratorios_realizados.id_control=100377 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45845,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='77' WHERE laboratorios_realizados.id_persona=100232 AND laboratorios_realizados.id_control=100377 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45846,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100232 AND laboratorios_realizados.id_control=100377 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45847,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100322",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45848,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100234 AND laboratorios_realizados.id_control=100379 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45849,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100234 AND laboratorios_realizados.id_control=100379 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45850,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100234 AND laboratorios_realizados.id_control=100379 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45851,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100234 AND laboratorios_realizados.id_control=100379 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45852,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='10' WHERE laboratorios_realizados.id_persona=100234 AND laboratorios_realizados.id_control=100379 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45853,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='78' WHERE laboratorios_realizados.id_persona=100234 AND laboratorios_realizados.id_control=100379 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45854,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100234 AND laboratorios_realizados.id_control=100379 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45855,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100323",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45856,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100235 AND laboratorios_realizados.id_control=100380 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45857,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100235 AND laboratorios_realizados.id_control=100380 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45858,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100235 AND laboratorios_realizados.id_control=100380 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45859,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100235 AND laboratorios_realizados.id_control=100380 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45860,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='10.6' WHERE laboratorios_realizados.id_persona=100235 AND laboratorios_realizados.id_control=100380 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45861,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='70' WHERE laboratorios_realizados.id_persona=100235 AND laboratorios_realizados.id_control=100380 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45862,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100235 AND laboratorios_realizados.id_control=100380 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45863,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100320",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45864,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='9.3' WHERE laboratorios_realizados.id_persona=100233 AND laboratorios_realizados.id_control=100378 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:03",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45865,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='74' WHERE laboratorios_realizados.id_persona=100233 AND laboratorios_realizados.id_control=100378 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45866,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100324",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45867,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100236 AND laboratorios_realizados.id_control=100381 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45868,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100236 AND laboratorios_realizados.id_control=100381 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45869,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100236 AND laboratorios_realizados.id_control=100381 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45870,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100236 AND laboratorios_realizados.id_control=100381 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45871,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='9' WHERE laboratorios_realizados.id_persona=100236 AND laboratorios_realizados.id_control=100381 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45872,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='82' WHERE laboratorios_realizados.id_persona=100236 AND laboratorios_realizados.id_control=100381 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45873,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100236 AND laboratorios_realizados.id_control=100381 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45874,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100325",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45875,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100237 AND laboratorios_realizados.id_control=100382 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45876,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100237 AND laboratorios_realizados.id_control=100382 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45877,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100237 AND laboratorios_realizados.id_control=100382 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45878,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100237 AND laboratorios_realizados.id_control=100382 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45879,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='9' WHERE laboratorios_realizados.id_persona=100237 AND laboratorios_realizados.id_control=100382 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45880,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='81' WHERE laboratorios_realizados.id_persona=100237 AND laboratorios_realizados.id_control=100382 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45881,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100237 AND laboratorios_realizados.id_control=100382 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45882,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100327",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45883,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100238 AND laboratorios_realizados.id_control=100383 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45884,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100238 AND laboratorios_realizados.id_control=100383 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45885,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100238 AND laboratorios_realizados.id_control=100383 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45886,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100238 AND laboratorios_realizados.id_control=100383 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45887,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='11' WHERE laboratorios_realizados.id_persona=100238 AND laboratorios_realizados.id_control=100383 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45888,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='79' WHERE laboratorios_realizados.id_persona=100238 AND laboratorios_realizados.id_control=100383 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45889,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100238 AND laboratorios_realizados.id_control=100383 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45890,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100328",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45891,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100239 AND laboratorios_realizados.id_control=100384 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45892,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100239 AND laboratorios_realizados.id_control=100384 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45893,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100239 AND laboratorios_realizados.id_control=100384 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45894,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='N' WHERE laboratorios_realizados.id_persona=100239 AND laboratorios_realizados.id_control=100384 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45895,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='10.6' WHERE laboratorios_realizados.id_persona=100239 AND laboratorios_realizados.id_control=100384 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45896,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='76' WHERE laboratorios_realizados.id_persona=100239 AND laboratorios_realizados.id_control=100384 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45897,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-23', resultado='0+' WHERE laboratorios_realizados.id_persona=100239 AND laboratorios_realizados.id_control=100384 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45898,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100330",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45899,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100240 AND laboratorios_realizados.id_control=100386 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45900,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100240 AND laboratorios_realizados.id_control=100386 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45901,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100240 AND laboratorios_realizados.id_control=100386 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45902,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100240 AND laboratorios_realizados.id_control=100386 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45903,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='11.3' WHERE laboratorios_realizados.id_persona=100240 AND laboratorios_realizados.id_control=100386 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45904,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='99' WHERE laboratorios_realizados.id_persona=100240 AND laboratorios_realizados.id_control=100386 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45905,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='0+' WHERE laboratorios_realizados.id_persona=100240 AND laboratorios_realizados.id_control=100386 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45906,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100347",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45907,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100255 AND laboratorios_realizados.id_control=100408 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45908,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100255 AND laboratorios_realizados.id_control=100408 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45909,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100255 AND laboratorios_realizados.id_control=100408 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45910,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100255 AND laboratorios_realizados.id_control=100408 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45911,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='12.6' WHERE laboratorios_realizados.id_persona=100255 AND laboratorios_realizados.id_control=100408 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45912,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='76' WHERE laboratorios_realizados.id_persona=100255 AND laboratorios_realizados.id_control=100408 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45913,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='0+' WHERE laboratorios_realizados.id_persona=100255 AND laboratorios_realizados.id_control=100408 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45914,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100331",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45915,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100242 AND laboratorios_realizados.id_control=100388 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45916,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100242 AND laboratorios_realizados.id_control=100388 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45917,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100242 AND laboratorios_realizados.id_control=100388 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45918,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='12' WHERE laboratorios_realizados.id_persona=100242 AND laboratorios_realizados.id_control=100388 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45919,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='90' WHERE laboratorios_realizados.id_persona=100242 AND laboratorios_realizados.id_control=100388 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45920,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='B+' WHERE laboratorios_realizados.id_persona=100242 AND laboratorios_realizados.id_control=100388 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45921,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100332",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45922,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100243 AND laboratorios_realizados.id_control=100389 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45923,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100243 AND laboratorios_realizados.id_control=100389 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45924,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100243 AND laboratorios_realizados.id_control=100389 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45925,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100243 AND laboratorios_realizados.id_control=100389 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45926,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='11' WHERE laboratorios_realizados.id_persona=100243 AND laboratorios_realizados.id_control=100389 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45927,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='74' WHERE laboratorios_realizados.id_persona=100243 AND laboratorios_realizados.id_control=100389 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45928,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='A+' WHERE laboratorios_realizados.id_persona=100243 AND laboratorios_realizados.id_control=100389 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45929,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100333",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45930,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100244 AND laboratorios_realizados.id_control=100390 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45931,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100244 AND laboratorios_realizados.id_control=100390 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45932,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100244 AND laboratorios_realizados.id_control=100390 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45933,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100244 AND laboratorios_realizados.id_control=100390 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45934,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='11' WHERE laboratorios_realizados.id_persona=100244 AND laboratorios_realizados.id_control=100390 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45935,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='88' WHERE laboratorios_realizados.id_persona=100244 AND laboratorios_realizados.id_control=100390 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45936,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='0+' WHERE laboratorios_realizados.id_persona=100244 AND laboratorios_realizados.id_control=100390 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45937,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100334",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45938,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100245 AND laboratorios_realizados.id_control=100391 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45939,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100245 AND laboratorios_realizados.id_control=100391 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45940,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100245 AND laboratorios_realizados.id_control=100391 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45941,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100245 AND laboratorios_realizados.id_control=100391 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45942,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='11.6' WHERE laboratorios_realizados.id_persona=100245 AND laboratorios_realizados.id_control=100391 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45943,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='70' WHERE laboratorios_realizados.id_persona=100245 AND laboratorios_realizados.id_control=100391 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45944,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200116",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45945,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200065 AND laboratorios_realizados.id_control=200126 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45946,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='11.3' WHERE laboratorios_realizados.id_persona=200065 AND laboratorios_realizados.id_control=200126 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45947,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='99' WHERE laboratorios_realizados.id_persona=200065 AND laboratorios_realizados.id_control=200126 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45948,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200065 AND laboratorios_realizados.id_control=200126 AND laboratorios_realizados.id_laboratorio=8 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45949,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='0+' WHERE laboratorios_realizados.id_persona=200065 AND laboratorios_realizados.id_control=200126 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45950,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100335",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45951,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='12' WHERE laboratorios_realizados.id_persona=200065 AND laboratorios_realizados.id_control=100392 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45952,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='81' WHERE laboratorios_realizados.id_persona=200065 AND laboratorios_realizados.id_control=100392 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45953,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100336",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45954,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='12' WHERE laboratorios_realizados.id_persona=100246 AND laboratorios_realizados.id_control=100393 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45955,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='88' WHERE laboratorios_realizados.id_persona=100246 AND laboratorios_realizados.id_control=100393 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45956,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='0+' WHERE laboratorios_realizados.id_persona=100246 AND laboratorios_realizados.id_control=100393 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45957,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200120",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45958,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200069 AND laboratorios_realizados.id_control=200130 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45959,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200069 AND laboratorios_realizados.id_control=200130 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45960,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200069 AND laboratorios_realizados.id_control=200130 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45961,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200069 AND laboratorios_realizados.id_control=200130 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45962,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='11.6' WHERE laboratorios_realizados.id_persona=200069 AND laboratorios_realizados.id_control=200130 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45963,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='104' WHERE laboratorios_realizados.id_persona=200069 AND laboratorios_realizados.id_control=200130 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45964,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200069 AND laboratorios_realizados.id_control=200130 AND laboratorios_realizados.id_laboratorio=8 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45965,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='A+' WHERE laboratorios_realizados.id_persona=200069 AND laboratorios_realizados.id_control=200130 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45966,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100337",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45967,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200069 AND laboratorios_realizados.id_control=100394 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45968,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200069 AND laboratorios_realizados.id_control=100394 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45969,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200069 AND laboratorios_realizados.id_control=100394 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45970,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200069 AND laboratorios_realizados.id_control=100394 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45971,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='12' WHERE laboratorios_realizados.id_persona=200069 AND laboratorios_realizados.id_control=100394 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45972,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='107' WHERE laboratorios_realizados.id_persona=200069 AND laboratorios_realizados.id_control=100394 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45973,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200123",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45974,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200072 AND laboratorios_realizados.id_control=200133 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45975,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200072 AND laboratorios_realizados.id_control=200133 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45976,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200072 AND laboratorios_realizados.id_control=200133 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45977,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200072 AND laboratorios_realizados.id_control=200133 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45978,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='11.3' WHERE laboratorios_realizados.id_persona=200072 AND laboratorios_realizados.id_control=200133 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45979,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='96' WHERE laboratorios_realizados.id_persona=200072 AND laboratorios_realizados.id_control=200133 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45980,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200072 AND laboratorios_realizados.id_control=200133 AND laboratorios_realizados.id_laboratorio=8 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45981,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='0+' WHERE laboratorios_realizados.id_persona=200072 AND laboratorios_realizados.id_control=200133 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45982,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100338",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45983,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200072 AND laboratorios_realizados.id_control=100395 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45984,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200072 AND laboratorios_realizados.id_control=100395 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45985,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200072 AND laboratorios_realizados.id_control=100395 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45986,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200072 AND laboratorios_realizados.id_control=100395 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45987,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='12' WHERE laboratorios_realizados.id_persona=200072 AND laboratorios_realizados.id_control=100395 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45988,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='96' WHERE laboratorios_realizados.id_persona=200072 AND laboratorios_realizados.id_control=100395 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45989,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='0+' WHERE laboratorios_realizados.id_persona=200072 AND laboratorios_realizados.id_control=100395 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45990,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100339",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45991,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100248 AND laboratorios_realizados.id_control=100397 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45992,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100248 AND laboratorios_realizados.id_control=100397 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45993,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100248 AND laboratorios_realizados.id_control=100397 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45994,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100248 AND laboratorios_realizados.id_control=100397 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45995,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='12.3' WHERE laboratorios_realizados.id_persona=100248 AND laboratorios_realizados.id_control=100397 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45996,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='119' WHERE laboratorios_realizados.id_persona=100248 AND laboratorios_realizados.id_control=100397 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45997,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='0+' WHERE laboratorios_realizados.id_persona=100248 AND laboratorios_realizados.id_control=100397 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45998,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200118",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    45999,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200067 AND laboratorios_realizados.id_control=200128 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46000,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='13.6' WHERE laboratorios_realizados.id_persona=200067 AND laboratorios_realizados.id_control=200128 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46001,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='344' WHERE laboratorios_realizados.id_persona=200067 AND laboratorios_realizados.id_control=200128 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46002,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200067 AND laboratorios_realizados.id_control=200128 AND laboratorios_realizados.id_laboratorio=8 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46003,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='A+' WHERE laboratorios_realizados.id_persona=200067 AND laboratorios_realizados.id_control=200128 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46004,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100340",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46005,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='12.6' WHERE laboratorios_realizados.id_persona=200067 AND laboratorios_realizados.id_control=100398 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46006,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='170' WHERE laboratorios_realizados.id_persona=200067 AND laboratorios_realizados.id_control=100398 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46007,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200125",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46008,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200074 AND laboratorios_realizados.id_control=200135 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46009,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='11' WHERE laboratorios_realizados.id_persona=200074 AND laboratorios_realizados.id_control=200135 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46010,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='121' WHERE laboratorios_realizados.id_persona=200074 AND laboratorios_realizados.id_control=200135 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46011,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200074 AND laboratorios_realizados.id_control=200135 AND laboratorios_realizados.id_laboratorio=8 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46012,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='A+' WHERE laboratorios_realizados.id_persona=200074 AND laboratorios_realizados.id_control=200135 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46013,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100341",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46014,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200074 AND laboratorios_realizados.id_control=100400 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46015,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200074 AND laboratorios_realizados.id_control=100400 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46016,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200074 AND laboratorios_realizados.id_control=100400 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46017,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200074 AND laboratorios_realizados.id_control=100400 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46018,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='11' WHERE laboratorios_realizados.id_persona=200074 AND laboratorios_realizados.id_control=100400 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46019,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='97' WHERE laboratorios_realizados.id_persona=200074 AND laboratorios_realizados.id_control=100400 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46020,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100348",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46021,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100256 AND laboratorios_realizados.id_control=100409 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46022,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100256 AND laboratorios_realizados.id_control=100409 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46023,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100256 AND laboratorios_realizados.id_control=100409 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46024,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100256 AND laboratorios_realizados.id_control=100409 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46025,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='11.6' WHERE laboratorios_realizados.id_persona=100256 AND laboratorios_realizados.id_control=100409 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46026,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='119' WHERE laboratorios_realizados.id_persona=100256 AND laboratorios_realizados.id_control=100409 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46027,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='0+' WHERE laboratorios_realizados.id_persona=100256 AND laboratorios_realizados.id_control=100409 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46028,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100342",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46029,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100250 AND laboratorios_realizados.id_control=100402 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46030,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='13' WHERE laboratorios_realizados.id_persona=100250 AND laboratorios_realizados.id_control=100402 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46031,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='136' WHERE laboratorios_realizados.id_persona=100250 AND laboratorios_realizados.id_control=100402 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46032,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='0+' WHERE laboratorios_realizados.id_persona=100250 AND laboratorios_realizados.id_control=100402 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46033,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=200129",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46034,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200078 AND laboratorios_realizados.id_control=200139 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46035,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='13.6' WHERE laboratorios_realizados.id_persona=200078 AND laboratorios_realizados.id_control=200139 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46036,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='102' WHERE laboratorios_realizados.id_persona=200078 AND laboratorios_realizados.id_control=200139 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46037,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200078 AND laboratorios_realizados.id_control=200139 AND laboratorios_realizados.id_laboratorio=8 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46038,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='0+' WHERE laboratorios_realizados.id_persona=200078 AND laboratorios_realizados.id_control=200139 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46039,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100343",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46040,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200078 AND laboratorios_realizados.id_control=100403 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46041,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200078 AND laboratorios_realizados.id_control=100403 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46042,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200078 AND laboratorios_realizados.id_control=100403 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46043,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=200078 AND laboratorios_realizados.id_control=100403 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46044,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='12.6' WHERE laboratorios_realizados.id_persona=200078 AND laboratorios_realizados.id_control=100403 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46045,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='94' WHERE laboratorios_realizados.id_persona=200078 AND laboratorios_realizados.id_control=100403 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46046,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100345",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46047,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100253 AND laboratorios_realizados.id_control=100406 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46048,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100253 AND laboratorios_realizados.id_control=100406 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46049,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100253 AND laboratorios_realizados.id_control=100406 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46050,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100253 AND laboratorios_realizados.id_control=100406 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46051,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='12.6' WHERE laboratorios_realizados.id_persona=100253 AND laboratorios_realizados.id_control=100406 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46052,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='89' WHERE laboratorios_realizados.id_persona=100253 AND laboratorios_realizados.id_control=100406 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46053,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='0+' WHERE laboratorios_realizados.id_persona=100253 AND laboratorios_realizados.id_control=100406 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46054,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100346",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46055,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100254 AND laboratorios_realizados.id_control=100407 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46056,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100254 AND laboratorios_realizados.id_control=100407 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46057,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100254 AND laboratorios_realizados.id_control=100407 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46058,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='N' WHERE laboratorios_realizados.id_persona=100254 AND laboratorios_realizados.id_control=100407 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46059,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='13' WHERE laboratorios_realizados.id_persona=100254 AND laboratorios_realizados.id_control=100407 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46060,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='86' WHERE laboratorios_realizados.id_persona=100254 AND laboratorios_realizados.id_control=100407 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46061,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-26', resultado='0+' WHERE laboratorios_realizados.id_persona=100254 AND laboratorios_realizados.id_control=100407 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46062,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100349",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46063,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100257 AND laboratorios_realizados.id_control=100410 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46064,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100257 AND laboratorios_realizados.id_control=100410 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46065,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100257 AND laboratorios_realizados.id_control=100410 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46066,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100257 AND laboratorios_realizados.id_control=100410 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46067,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='10.3' WHERE laboratorios_realizados.id_persona=100257 AND laboratorios_realizados.id_control=100410 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46068,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='125' WHERE laboratorios_realizados.id_persona=100257 AND laboratorios_realizados.id_control=100410 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46069,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='0+' WHERE laboratorios_realizados.id_persona=100257 AND laboratorios_realizados.id_control=100410 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46070,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100350",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46071,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='13' WHERE laboratorios_realizados.id_persona=100258 AND laboratorios_realizados.id_control=100411 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46072,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='94' WHERE laboratorios_realizados.id_persona=100258 AND laboratorios_realizados.id_control=100411 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46073,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100351",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46074,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='10.3' WHERE laboratorios_realizados.id_persona=379 AND laboratorios_realizados.id_control=100412 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46075,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='107' WHERE laboratorios_realizados.id_persona=379 AND laboratorios_realizados.id_control=100412 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46076,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100352",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46077,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=375 AND laboratorios_realizados.id_control=100413 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46078,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=375 AND laboratorios_realizados.id_control=100413 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46079,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=375 AND laboratorios_realizados.id_control=100413 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46080,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=375 AND laboratorios_realizados.id_control=100413 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46081,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='11' WHERE laboratorios_realizados.id_persona=375 AND laboratorios_realizados.id_control=100413 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46082,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='99' WHERE laboratorios_realizados.id_persona=375 AND laboratorios_realizados.id_control=100413 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46083,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100353",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46084,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100259 AND laboratorios_realizados.id_control=100414 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46085,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100259 AND laboratorios_realizados.id_control=100414 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46086,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100259 AND laboratorios_realizados.id_control=100414 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46087,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100259 AND laboratorios_realizados.id_control=100414 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46088,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='12.3' WHERE laboratorios_realizados.id_persona=100259 AND laboratorios_realizados.id_control=100414 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46089,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='95' WHERE laboratorios_realizados.id_persona=100259 AND laboratorios_realizados.id_control=100414 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46090,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='0+' WHERE laboratorios_realizados.id_persona=100259 AND laboratorios_realizados.id_control=100414 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46091,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100356",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46092,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100260 AND laboratorios_realizados.id_control=100415 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46093,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100260 AND laboratorios_realizados.id_control=100415 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46094,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100260 AND laboratorios_realizados.id_control=100415 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46095,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100260 AND laboratorios_realizados.id_control=100415 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46096,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='12' WHERE laboratorios_realizados.id_persona=100260 AND laboratorios_realizados.id_control=100415 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46097,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='85' WHERE laboratorios_realizados.id_persona=100260 AND laboratorios_realizados.id_control=100415 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46098,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='0+' WHERE laboratorios_realizados.id_persona=100260 AND laboratorios_realizados.id_control=100415 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46099,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100357",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46100,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100261 AND laboratorios_realizados.id_control=100416 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46101,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100261 AND laboratorios_realizados.id_control=100416 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46102,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100261 AND laboratorios_realizados.id_control=100416 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46103,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100261 AND laboratorios_realizados.id_control=100416 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46104,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='10.6' WHERE laboratorios_realizados.id_persona=100261 AND laboratorios_realizados.id_control=100416 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46105,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='85' WHERE laboratorios_realizados.id_persona=100261 AND laboratorios_realizados.id_control=100416 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46106,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='0+' WHERE laboratorios_realizados.id_persona=100261 AND laboratorios_realizados.id_control=100416 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46107,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100358",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46108,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='10.6' WHERE laboratorios_realizados.id_persona=368 AND laboratorios_realizados.id_control=100417 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46109,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='81' WHERE laboratorios_realizados.id_persona=368 AND laboratorios_realizados.id_control=100417 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46110,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100359",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46111,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='11.3' WHERE laboratorios_realizados.id_persona=373 AND laboratorios_realizados.id_control=100418 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46112,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='89' WHERE laboratorios_realizados.id_persona=373 AND laboratorios_realizados.id_control=100418 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46113,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100360",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46114,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=372 AND laboratorios_realizados.id_control=100419 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46115,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=372 AND laboratorios_realizados.id_control=100419 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46116,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=372 AND laboratorios_realizados.id_control=100419 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46117,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=372 AND laboratorios_realizados.id_control=100419 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46118,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='13' WHERE laboratorios_realizados.id_persona=372 AND laboratorios_realizados.id_control=100419 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46119,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='79' WHERE laboratorios_realizados.id_persona=372 AND laboratorios_realizados.id_control=100419 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46120,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100354",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46121,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='11.3' WHERE laboratorios_realizados.id_persona=380 AND laboratorios_realizados.id_control=100420 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46122,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='70' WHERE laboratorios_realizados.id_persona=380 AND laboratorios_realizados.id_control=100420 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46123,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100355",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46124,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='12' WHERE laboratorios_realizados.id_persona=369 AND laboratorios_realizados.id_control=100421 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46125,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='80' WHERE laboratorios_realizados.id_persona=369 AND laboratorios_realizados.id_control=100421 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46126,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100362",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46127,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='10.3' WHERE laboratorios_realizados.id_persona=378 AND laboratorios_realizados.id_control=100423 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46128,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='85' WHERE laboratorios_realizados.id_persona=378 AND laboratorios_realizados.id_control=100423 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46129,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100363",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46130,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100263 AND laboratorios_realizados.id_control=100424 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46131,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100263 AND laboratorios_realizados.id_control=100424 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46132,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100263 AND laboratorios_realizados.id_control=100424 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46133,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100263 AND laboratorios_realizados.id_control=100424 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46134,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='11' WHERE laboratorios_realizados.id_persona=100263 AND laboratorios_realizados.id_control=100424 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46135,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='70' WHERE laboratorios_realizados.id_persona=100263 AND laboratorios_realizados.id_control=100424 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46136,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='A+' WHERE laboratorios_realizados.id_persona=100263 AND laboratorios_realizados.id_control=100424 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46137,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=428",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46138,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=371 AND laboratorios_realizados.id_control=638 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46139,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=371 AND laboratorios_realizados.id_control=638 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46140,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=371 AND laboratorios_realizados.id_control=638 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46141,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='11' WHERE laboratorios_realizados.id_persona=371 AND laboratorios_realizados.id_control=638 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46142,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='80' WHERE laboratorios_realizados.id_persona=371 AND laboratorios_realizados.id_control=638 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46143,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100364",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46144,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=371 AND laboratorios_realizados.id_control=100425 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46145,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=371 AND laboratorios_realizados.id_control=100425 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46146,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=371 AND laboratorios_realizados.id_control=100425 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46147,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=371 AND laboratorios_realizados.id_control=100425 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46148,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='10' WHERE laboratorios_realizados.id_persona=371 AND laboratorios_realizados.id_control=100425 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46149,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='86' WHERE laboratorios_realizados.id_persona=371 AND laboratorios_realizados.id_control=100425 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46150,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100367",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46151,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100266 AND laboratorios_realizados.id_control=100428 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46152,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100266 AND laboratorios_realizados.id_control=100428 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46153,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100266 AND laboratorios_realizados.id_control=100428 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46154,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100266 AND laboratorios_realizados.id_control=100428 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46155,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='10.3' WHERE laboratorios_realizados.id_persona=100266 AND laboratorios_realizados.id_control=100428 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46156,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='101' WHERE laboratorios_realizados.id_persona=100266 AND laboratorios_realizados.id_control=100428 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46157,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='0+' WHERE laboratorios_realizados.id_persona=100266 AND laboratorios_realizados.id_control=100428 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46158,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100368",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46159,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100267 AND laboratorios_realizados.id_control=100429 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46160,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100267 AND laboratorios_realizados.id_control=100429 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46161,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100267 AND laboratorios_realizados.id_control=100429 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46162,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='N' WHERE laboratorios_realizados.id_persona=100267 AND laboratorios_realizados.id_control=100429 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46163,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='10.3' WHERE laboratorios_realizados.id_persona=100267 AND laboratorios_realizados.id_control=100429 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46164,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='108' WHERE laboratorios_realizados.id_persona=100267 AND laboratorios_realizados.id_control=100429 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46165,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='0+' WHERE laboratorios_realizados.id_persona=100267 AND laboratorios_realizados.id_control=100429 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46166,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100384",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46167,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='9' WHERE laboratorios_realizados.id_persona=100281 AND laboratorios_realizados.id_control=100446 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46168,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-09-27', resultado='89' WHERE laboratorios_realizados.id_persona=100281 AND laboratorios_realizados.id_control=100446 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-09-28 10:34:04",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46169,
                    "UPDATE antecedentes SET fecha_ultimo_embarazo='2022-10-04', planificado=0, fum='2022-02-20', fpp='2022-11-27' WHERE antecedentes.id_antecedente=100229",
                    "2022-10-10 22:34:29",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46170,
                    "SELECT id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo, madre, alta, nacido_vivo FROM personas WHERE id_persona = '100236'",
                    "2022-10-10 22:34:29",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46171,
                    "SELECT id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais FROM ubicaciones WHERE id_ubicacion = '100236'",
                    "2022-10-10 22:34:29",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46172,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100448, '2022-10-10 22:48:28.000000', 344, 2, 1)",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46173,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (344, 100448, 2, 'S')",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46174,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (344, 100448, 3, 'C')",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46175,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (344, 100448, 4, 'C')",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46176,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (344, 100448, 6, 3, '2022-10-10 22:52:51.000000', '2022-10-10 22:52:51.000000', '10')",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46177,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (344, 100448, 7, 3, '2022-10-10 22:52:51.000000', '2022-10-10 22:52:51.000000', '65')",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46178,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100386, 100448, 33, 'R', ' ', 'N', 'N', 120, 90, 'N', '')",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46179,
                    "UPDATE controles SET georeferencia='-22.2729077,-62.7170054' WHERE controles.id_control=100448",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46180,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100209 AND laboratorios_realizados.id_control=100353 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46181,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100209 AND laboratorios_realizados.id_control=100353 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46182,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100209 AND laboratorios_realizados.id_control=100353 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46183,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100209 AND laboratorios_realizados.id_control=100353 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46184,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='10' WHERE laboratorios_realizados.id_persona=100209 AND laboratorios_realizados.id_control=100353 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46185,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='89' WHERE laboratorios_realizados.id_persona=100209 AND laboratorios_realizados.id_control=100353 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46186,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='0+' WHERE laboratorios_realizados.id_persona=100209 AND laboratorios_realizados.id_control=100353 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46187,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100300",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46188,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100211 AND laboratorios_realizados.id_control=100355 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46189,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100211 AND laboratorios_realizados.id_control=100355 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46190,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100211 AND laboratorios_realizados.id_control=100355 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46191,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100211 AND laboratorios_realizados.id_control=100355 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46192,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='12' WHERE laboratorios_realizados.id_persona=100211 AND laboratorios_realizados.id_control=100355 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46193,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='35' WHERE laboratorios_realizados.id_persona=100211 AND laboratorios_realizados.id_control=100355 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46194,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='0+' WHERE laboratorios_realizados.id_persona=100211 AND laboratorios_realizados.id_control=100355 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46195,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100321",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46196,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100298",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46197,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100210 AND laboratorios_realizados.id_control=100354 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46198,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100210 AND laboratorios_realizados.id_control=100354 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46199,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100210 AND laboratorios_realizados.id_control=100354 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46200,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100210 AND laboratorios_realizados.id_control=100354 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46201,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='10' WHERE laboratorios_realizados.id_persona=100210 AND laboratorios_realizados.id_control=100354 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46202,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='70' WHERE laboratorios_realizados.id_persona=100210 AND laboratorios_realizados.id_control=100354 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46203,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='0+' WHERE laboratorios_realizados.id_persona=100210 AND laboratorios_realizados.id_control=100354 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46204,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100299",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46205,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100212 AND laboratorios_realizados.id_control=100356 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46206,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100212 AND laboratorios_realizados.id_control=100356 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46207,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100212 AND laboratorios_realizados.id_control=100356 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46208,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100212 AND laboratorios_realizados.id_control=100356 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46209,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='13' WHERE laboratorios_realizados.id_persona=100212 AND laboratorios_realizados.id_control=100356 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46210,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='84' WHERE laboratorios_realizados.id_persona=100212 AND laboratorios_realizados.id_control=100356 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46211,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='0+' WHERE laboratorios_realizados.id_persona=100212 AND laboratorios_realizados.id_control=100356 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46212,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100306",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46213,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100219 AND laboratorios_realizados.id_control=100363 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46214,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100219 AND laboratorios_realizados.id_control=100363 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46215,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100219 AND laboratorios_realizados.id_control=100363 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46216,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100219 AND laboratorios_realizados.id_control=100363 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46217,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='10' WHERE laboratorios_realizados.id_persona=100219 AND laboratorios_realizados.id_control=100363 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46218,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='85' WHERE laboratorios_realizados.id_persona=100219 AND laboratorios_realizados.id_control=100363 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46219,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='0+' WHERE laboratorios_realizados.id_persona=100219 AND laboratorios_realizados.id_control=100363 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46220,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100308",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46221,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100221 AND laboratorios_realizados.id_control=100365 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46222,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100221 AND laboratorios_realizados.id_control=100365 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46223,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100221 AND laboratorios_realizados.id_control=100365 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46224,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100221 AND laboratorios_realizados.id_control=100365 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46225,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='9' WHERE laboratorios_realizados.id_persona=100221 AND laboratorios_realizados.id_control=100365 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46226,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='84' WHERE laboratorios_realizados.id_persona=100221 AND laboratorios_realizados.id_control=100365 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46227,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='0+' WHERE laboratorios_realizados.id_persona=100221 AND laboratorios_realizados.id_control=100365 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46228,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100307",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46229,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100220 AND laboratorios_realizados.id_control=100364 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46230,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100220 AND laboratorios_realizados.id_control=100364 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46231,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100220 AND laboratorios_realizados.id_control=100364 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46232,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100220 AND laboratorios_realizados.id_control=100364 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46233,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='10' WHERE laboratorios_realizados.id_persona=100220 AND laboratorios_realizados.id_control=100364 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46234,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='72' WHERE laboratorios_realizados.id_persona=100220 AND laboratorios_realizados.id_control=100364 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46235,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='0+' WHERE laboratorios_realizados.id_persona=100220 AND laboratorios_realizados.id_control=100364 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46236,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100311",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46237,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100224 AND laboratorios_realizados.id_control=100368 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46238,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100224 AND laboratorios_realizados.id_control=100368 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46239,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100224 AND laboratorios_realizados.id_control=100368 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46240,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100224 AND laboratorios_realizados.id_control=100368 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46241,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='10' WHERE laboratorios_realizados.id_persona=100224 AND laboratorios_realizados.id_control=100368 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46242,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='106' WHERE laboratorios_realizados.id_persona=100224 AND laboratorios_realizados.id_control=100368 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46243,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='0+' WHERE laboratorios_realizados.id_persona=100224 AND laboratorios_realizados.id_control=100368 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46244,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100310",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46245,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100223 AND laboratorios_realizados.id_control=100367 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46246,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100223 AND laboratorios_realizados.id_control=100367 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46247,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100223 AND laboratorios_realizados.id_control=100367 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46248,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100223 AND laboratorios_realizados.id_control=100367 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46249,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='10' WHERE laboratorios_realizados.id_persona=100223 AND laboratorios_realizados.id_control=100367 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46250,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='105' WHERE laboratorios_realizados.id_persona=100223 AND laboratorios_realizados.id_control=100367 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46251,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='0+' WHERE laboratorios_realizados.id_persona=100223 AND laboratorios_realizados.id_control=100367 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46252,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100312",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46253,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100225 AND laboratorios_realizados.id_control=100369 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46254,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100225 AND laboratorios_realizados.id_control=100369 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-10-10 23:34:22",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46255,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100225 AND laboratorios_realizados.id_control=100369 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46256,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100225 AND laboratorios_realizados.id_control=100369 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46257,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='13' WHERE laboratorios_realizados.id_persona=100225 AND laboratorios_realizados.id_control=100369 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46258,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='75' WHERE laboratorios_realizados.id_persona=100225 AND laboratorios_realizados.id_control=100369 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46259,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='0+' WHERE laboratorios_realizados.id_persona=100225 AND laboratorios_realizados.id_control=100369 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46260,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100313",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46261,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100226 AND laboratorios_realizados.id_control=100370 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46262,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100226 AND laboratorios_realizados.id_control=100370 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46263,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100226 AND laboratorios_realizados.id_control=100370 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46264,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100226 AND laboratorios_realizados.id_control=100370 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46265,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='9' WHERE laboratorios_realizados.id_persona=100226 AND laboratorios_realizados.id_control=100370 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46266,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='85' WHERE laboratorios_realizados.id_persona=100226 AND laboratorios_realizados.id_control=100370 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46267,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='0+' WHERE laboratorios_realizados.id_persona=100226 AND laboratorios_realizados.id_control=100370 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46268,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100314",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46269,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100227 AND laboratorios_realizados.id_control=100371 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46270,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100227 AND laboratorios_realizados.id_control=100371 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46271,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100227 AND laboratorios_realizados.id_control=100371 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46272,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100227 AND laboratorios_realizados.id_control=100371 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46273,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='9' WHERE laboratorios_realizados.id_persona=100227 AND laboratorios_realizados.id_control=100371 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46274,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='70' WHERE laboratorios_realizados.id_persona=100227 AND laboratorios_realizados.id_control=100371 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46275,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='0+' WHERE laboratorios_realizados.id_persona=100227 AND laboratorios_realizados.id_control=100371 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46276,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100315",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46277,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100228 AND laboratorios_realizados.id_control=100372 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46278,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100228 AND laboratorios_realizados.id_control=100372 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46279,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100228 AND laboratorios_realizados.id_control=100372 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46280,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100228 AND laboratorios_realizados.id_control=100372 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46281,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='11' WHERE laboratorios_realizados.id_persona=100228 AND laboratorios_realizados.id_control=100372 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46282,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='72' WHERE laboratorios_realizados.id_persona=100228 AND laboratorios_realizados.id_control=100372 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46283,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='0+' WHERE laboratorios_realizados.id_persona=100228 AND laboratorios_realizados.id_control=100372 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46284,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100317",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46285,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=264 AND laboratorios_realizados.id_control=100374 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46286,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=264 AND laboratorios_realizados.id_control=100374 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46287,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=264 AND laboratorios_realizados.id_control=100374 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46288,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=264 AND laboratorios_realizados.id_control=100374 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46289,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='11' WHERE laboratorios_realizados.id_persona=264 AND laboratorios_realizados.id_control=100374 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46290,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='76' WHERE laboratorios_realizados.id_persona=264 AND laboratorios_realizados.id_control=100374 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46291,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='0+' WHERE laboratorios_realizados.id_persona=264 AND laboratorios_realizados.id_control=100374 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46292,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100316",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46293,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100229 AND laboratorios_realizados.id_control=100373 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46294,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100229 AND laboratorios_realizados.id_control=100373 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46295,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100229 AND laboratorios_realizados.id_control=100373 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46296,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100229 AND laboratorios_realizados.id_control=100373 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46297,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='10' WHERE laboratorios_realizados.id_persona=100229 AND laboratorios_realizados.id_control=100373 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46298,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='77' WHERE laboratorios_realizados.id_persona=100229 AND laboratorios_realizados.id_control=100373 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46299,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='0+' WHERE laboratorios_realizados.id_persona=100229 AND laboratorios_realizados.id_control=100373 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46300,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100318",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46301,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100230 AND laboratorios_realizados.id_control=100375 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46302,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100230 AND laboratorios_realizados.id_control=100375 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46303,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100230 AND laboratorios_realizados.id_control=100375 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46304,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='N' WHERE laboratorios_realizados.id_persona=100230 AND laboratorios_realizados.id_control=100375 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46305,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='10' WHERE laboratorios_realizados.id_persona=100230 AND laboratorios_realizados.id_control=100375 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46306,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='79' WHERE laboratorios_realizados.id_persona=100230 AND laboratorios_realizados.id_control=100375 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46307,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-10', resultado='0+' WHERE laboratorios_realizados.id_persona=100230 AND laboratorios_realizados.id_control=100375 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-10-10 23:34:23",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46308,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (400252, '2022-11-23 00:00:00.000000', 26, 3, 2)",
                    "2022-11-28 16:20:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46309,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (400253, '2022-11-23 00:00:07.000000', 26, 4, 2)",
                    "2022-11-28 16:20:30",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46310,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (400254, '2022-12-05 11:43:54.000000', 4, 3, 2)",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46311,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (400255, '2022-12-05 11:43:59.000000', 4, 4, 2)",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46312,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (400256, '2022-12-05 19:23:28.000000', 5, 6, 1)",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46313,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (400088, 'PRUEBA', 'PRUEBA', '333333333', '2009-11-24 00:00:00.000000', 1, 12, 'F')",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46314,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, georeferencia) VALUES (400257, '2022-12-12 11:42:50.000000', 400088, 1, 1, '-34.4457216,-59.441152')",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46315,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (400088, 400088, 15, 4, '12', '2022-12-12 11:42:50.000000', '-34.4457216,-59.441152', 12)",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46316,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (400089, 'JACINTA', 'SUSANA', '44555888', '2022-12-20 00:00:00.000000', 2, 243, 'F')",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46317,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, georeferencia) VALUES (400258, '2022-12-15 19:06:10.000000', 400089, 1, 1, '-34.2618767,-59.4750746')",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46318,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (400089, 400089, 33, 3, '12', '2022-12-15 19:06:10.000000', '-34.2618767,-59.4750746', 12)",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46319,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (400090, 'PRUEBA', 'PRUEBA', '22666999', '2009-11-24 00:00:00.000000', 2, 12, 'F')",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46320,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, georeferencia) VALUES (400259, '2022-12-16 10:10:24.000000', 400090, 1, 1, '-34.2619016,-59.4750161')",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46321,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (400090, 400090, 36, 3, '12', '2022-12-16 10:10:24.000000', '-34.2619016,-59.4750161', 12)",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46322,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (400091, 'PRUEBA', 'PRUEBA', '55666333', '2009-11-25 00:00:00.000000', 1, 12, 'F')",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46323,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, georeferencia) VALUES (400257, '2022-12-16 11:53:25.000000', 400091, 1, 1, '-34.2618941,-59.4749918')",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46324,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (400091, 400091, 31, 3, '12', '2022-12-16 11:53:25.000000', '-34.2618941,-59.4749918', 12)",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46325,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (400092, 'PRUEBA', 'PRUEBA', '22666555', '2009-01-06 00:00:00.000000', 2, 12, 'F')",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46326,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, georeferencia) VALUES (400258, '2022-12-16 18:09:47.000000', 400092, 1, 1, '-34.439168,-59.4378752')",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46327,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (400092, 400092, 17, 3, '12', '2022-12-16 18:09:47.000000', '-34.439168,-59.4378752', 12)",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46328,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (400007, 400092, 400258, 0, 1, 1, '2022-12-06 00:00:00.000000', '2023-09-12 00:00:00.000000')",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46329,
                    "INSERT INTO antecedentes_apps (id_antecedente, id_app) VALUES (400007, 1)",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46330,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (400007, 2)",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46331,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (400093, 'IGLESIAS', 'GUSTAVO', '44555888', '2022-12-13 00:00:00.000000', 1, 12, 'F')",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46332,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, georeferencia) VALUES (400259, '2022-12-19 10:17:33.000000', 400093, 1, 1, '-34.2555428,-59.4625749')",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46333,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (400093, 400093, 29, 3, '12', '2022-12-19 10:17:33.000000', '-34.2555428,-59.4625749', 12)",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46334,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (400008, 400093, 400259, 0, 1, 0, '2022-12-02 00:00:00.000000', '2023-09-08 00:00:00.000000')",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46335,
                    "INSERT INTO antecedentes_apps (id_antecedente, id_app) VALUES (400008, 8)",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46336,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (400008, 4)",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46337,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (400093, 400259, 2, 'S')",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46338,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (400093, 400259, 3, 'P')",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46339,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (400093, 400259, 1, 'S')",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46340,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (400093, 400259, 4, 'P')",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46341,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (400093, 400259, 19, 1, '2022-12-19 10:21:35.000000', 1)",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46342,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (400093, 400259, 20, 1, '2022-12-19 10:21:35.000000', 1)",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46343,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (400093, 400259, 23, 1, '2022-12-19 10:21:35.000000', 1)",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46344,
                    "INSERT INTO etmis_personas (id_persona, id_etmi, id_control) VALUES (400093, 1, 400259)",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46345,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (400093, 400259, 4, 1, '2022-12-19 10:21:35.000000', '2022-12-19 10:21:35.000000', 'P', 1)",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46346,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (400071, 400259, 2, 'R', ' ', 'N', 'N', 120, 90, 'N', '')",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46347,
                    "DELETE FROM etmis_personas WHERE etmis_personas.id_persona=400093 AND etmis_personas.id_etmi=1 AND etmis_personas.id_control=400259",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46348,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-12-19', resultado='P' WHERE laboratorios_realizados.id_persona=400093 AND laboratorios_realizados.id_control=400259 AND laboratorios_realizados.id_laboratorio=19 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46349,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-12-19', resultado='P' WHERE laboratorios_realizados.id_persona=400093 AND laboratorios_realizados.id_control=400259 AND laboratorios_realizados.id_laboratorio=20 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46350,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-12-19', resultado='P' WHERE laboratorios_realizados.id_persona=400093 AND laboratorios_realizados.id_control=400259 AND laboratorios_realizados.id_laboratorio=23 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46351,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (400260, '2022-12-19 10:29:21.000000', 38, 6, 2)",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46352,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (400261, '2022-12-19 10:29:26.000000', 38, 7, 2)",
                    "2022-12-27 09:58:31",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46353,
                    "SELECT id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo, madre, alta, nacido_vivo FROM personas WHERE id_persona = 100236",
                    "2022-12-27 16:44:16",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46354,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100283, 'CAMPOS', 'JULIA', '46666394', '2006-02-22 00:00:00.000000', 1, 12, 'F')",
                    "2022-12-27 16:44:16",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46355,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100449, '2022-10-11 15:26:32.000000', 100283, 1, 1)",
                    "2022-12-27 16:44:16",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46356,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100283, 100283, 22, 3, '1', '2022-10-11 15:26:32.000000', '', 12)",
                    "2022-12-27 16:44:16",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46357,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (100276, 100283, 100449, 0, 1, 1, '2022-07-20 00:00:00.000000', '2023-04-26 00:00:00.000000')",
                    "2022-12-27 16:44:16",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46358,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100283, 100449, 2, 'S')",
                    "2022-12-27 16:44:16",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46359,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100283, 100449, 3, 'N')",
                    "2022-12-27 16:44:16",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46360,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100283, 100449, 1, 'N')",
                    "2022-12-27 16:44:16",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46361,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100283, 100449, 4, 'N')",
                    "2022-12-27 16:44:16",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46362,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100283, 100449, 1, 1, '2022-10-11 15:32:34.000000', 3)",
                    "2022-12-27 16:44:16",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46363,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100283, 100449, 2, 1, '2022-10-11 15:32:34.000000', 2)",
                    "2022-12-27 16:44:16",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46364,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100283, 100449, 4, 1, '2022-10-11 15:32:34.000000', 1)",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46365,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100283, 100449, 5, 1, '2022-10-11 15:32:34.000000', 4)",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46366,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100283, 100449, 6, 1, '2022-10-11 15:32:34.000000')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46367,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100283, 100449, 7, 1, '2022-10-11 15:32:34.000000')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46368,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100283, 100449, 9, 1, '2022-10-11 15:32:34.000000')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46369,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100387, 100449, 11, 'S', ' ', 'N', 'N', 130, 80, 'N', '')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46370,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100450, '2022-10-11 15:36:40.000000', 100205, 2, 1)",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46371,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100205, 100450, 2, 'S')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46372,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100205, 100450, 3, 'N')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46373,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100205, 100450, 4, 'N')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46374,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100388, 100450, 17, 'S', ' ', 'N', 'N', 120, 90, 'N', '')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46375,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100284, 'SEGUNDO', 'ANGELA', '41372436', '2000-11-26 00:00:00.000000', 1, 12, 'F')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46376,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100451, '2022-10-11 15:46:20.000000', 100284, 1, 1)",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46377,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100284, 100284, 22, 3, '2', '2022-10-11 15:46:20.000000', '', 12)",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46378,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100277, 100284, 100451, 0, '2021-06-16 00:00:00.000000', 2, 1, 1, '2022-05-05 00:00:00.000000', '2023-02-09 00:00:00.000000')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46379,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (100277, 3)",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46380,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100284, 100451, 2, 'S')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46381,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100284, 100451, 3, 'N')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46382,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100284, 100451, 1, 'S')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46383,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100284, 100451, 4, 'N')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46384,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100284, 100451, 1, 2, '2022-10-11 15:55:46.000000', 3)",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46385,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100284, 100451, 2, 2, '2022-10-11 15:55:46.000000', 2)",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46386,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100284, 100451, 4, 2, '2022-10-11 15:55:46.000000', 1)",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46387,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100284, 100451, 5, 2, '2022-10-11 15:55:46.000000', 4)",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46388,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100284, 100451, 6, 2, '2022-10-11 15:55:46.000000')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46389,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100284, 100451, 7, 2, '2022-10-11 15:55:46.000000')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46390,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100284, 100451, 9, 2, '2022-10-11 15:55:46.000000')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46391,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100389, 100451, 22, 'S', ' ', 'N', 'N', 115, 75, 'N', 'EMBARAZO ANTERIOR- RN FALLECIDO')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46392,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-11', resultado='N' WHERE laboratorios_realizados.id_persona=100203 AND laboratorios_realizados.id_control=100347 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46393,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-11', resultado='N' WHERE laboratorios_realizados.id_persona=100203 AND laboratorios_realizados.id_control=100347 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46394,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-11', resultado='N' WHERE laboratorios_realizados.id_persona=100203 AND laboratorios_realizados.id_control=100347 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46395,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-11', resultado='N' WHERE laboratorios_realizados.id_persona=100203 AND laboratorios_realizados.id_control=100347 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46396,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-11', resultado='-' WHERE laboratorios_realizados.id_persona=100203 AND laboratorios_realizados.id_control=100347 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46397,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-11', resultado='81' WHERE laboratorios_realizados.id_persona=100203 AND laboratorios_realizados.id_control=100347 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46398,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-11', resultado='0+' WHERE laboratorios_realizados.id_persona=100203 AND laboratorios_realizados.id_control=100347 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46399,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100452, '2022-10-11 15:58:58.000000', 100203, 2, 1)",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46400,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100203, 100452, 2, 'S')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46401,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100203, 100452, 3, 'N')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46402,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100203, 100452, 4, 'N')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46403,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100390, 100452, 589, 'S', ' ', 'N', 'N', 100, 66, 'N', '')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46404,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100285, 'LORENCIA', 'CONTANTINO', '39781900', '1995-08-17 00:00:00.000000', 1, 12, 'F')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46405,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100453, '2022-10-11 16:10:39.000000', 100285, 1, 1)",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46406,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100285, 100285, 22, 3, '3', '2022-10-11 16:10:39.000000', '', 12)",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46407,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100278, 100285, 100453, 19, '2015-09-15 00:00:00.000000', 2, 1, 0, '2022-08-10 00:00:00.000000', '2023-05-17 00:00:00.000000')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46408,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100286, 'MARAZ', 'DANIELA', '40709408', '1997-09-22 00:00:00.000000', 1, 12, 'F')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46409,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100454, '2022-10-11 16:22:28.000000', 100286, 1, 1)",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46410,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100286, 100286, 22, 3, '4', '2022-10-11 16:22:28.000000', '', 12)",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46411,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, cesareas, abortos, planificado, fum, fpp) VALUES (100279, 100286, 100454, 14, '2015-03-03 00:00:00.000000', 4, 2, 1, 0, '2022-08-02 00:00:00.000000', '2023-05-09 00:00:00.000000')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46412,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100286, 100454, 2, 'N')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46413,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100286, 100454, 3, 'N')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46414,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100286, 100454, 1, 'N')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46415,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100286, 100454, 4, 'N')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46416,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100286, 100454, 1, 1, '2022-10-11 16:26:37.000000', 3)",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46417,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100286, 100454, 2, 1, '2022-10-11 16:26:37.000000', 2)",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46418,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100286, 100454, 4, 1, '2022-10-11 16:26:37.000000', 1)",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46419,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100286, 100454, 5, 1, '2022-10-11 16:26:37.000000', 4)",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46420,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100286, 100454, 6, 1, '2022-10-11 16:26:37.000000')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46421,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100286, 100454, 7, 1, '2022-10-11 16:26:37.000000')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46422,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100286, 100454, 9, 1, '2022-10-11 16:26:37.000000')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46423,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100391, 100454, 10, 'S', ' ', 'N', 'N', 120, 70, 'N', '')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46424,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100287, 'LUNA', 'NOELIA', '34229607', '1988-09-07 00:00:00.000000', 1, 12, 'F')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46425,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100455, '2022-10-11 16:29:45.000000', 100287, 1, 1)",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46426,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100287, 100287, 22, 3, '6', '2022-10-11 16:29:45.000000', '', 12)",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46427,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100280, 100287, 100455, 15, '2019-01-20 00:00:00.000000', 5, 4, 0, '2012-01-20 00:00:00.000000', '2012-10-26 00:00:00.000000')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46428,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (100280, 3)",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46429,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100287, 100455, 2, 'S')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46430,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100287, 100455, 3, 'N')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46431,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100287, 100455, 1, 'S')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46432,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100287, 100455, 4, 'P')",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46433,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100287, 100455, 1, 3, '2022-10-11 16:36:35.000000', 3)",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46434,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100287, 100455, 2, 3, '2022-10-11 16:36:35.000000', 2)",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46435,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100287, 100455, 4, 3, '2022-10-11 16:36:35.000000', 1)",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46436,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100287, 100455, 5, 3, '2022-10-11 16:36:35.000000', 4)",
                    "2022-12-27 16:44:17",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46437,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100287, 100455, 6, 3, '2022-10-11 16:36:35.000000')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46438,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100287, 100455, 7, 3, '2022-10-11 16:36:35.000000')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46439,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100287, 100455, 9, 3, '2022-10-11 16:36:35.000000')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46440,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100392, 100455, 559, 'S', ' ', 'N', 'N', 160, 100, 'A', 'HTA')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46441,
                    "SELECT id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo, madre, alta, nacido_vivo FROM personas WHERE id_persona = '200063'",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46442,
                    "SELECT id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais FROM ubicaciones WHERE id_ubicacion = '200063'",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46443,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100288, 'SEGUNDO', 'ANDREA', '39781838', '2020-07-10 00:00:00.000000', 1, 12, 'F')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46444,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100456, '2022-10-11 16:42:18.000000', 100288, 1, 1)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46445,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100288, 100288, 22, 3, '7', '2022-10-11 16:42:18.000000', '', 12)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46446,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100281, 100288, 100456, 20, '2020-12-15 00:00:00.000000', 3, 2, 0, '2022-03-05 00:00:00.000000', '2022-12-10 00:00:00.000000')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46447,
                    "INSERT INTO antecedentes_apps (id_antecedente, id_app) VALUES (100281, 1)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46448,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100288, 100456, 2, 'S')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46449,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100288, 100456, 3, 'N')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46450,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100288, 100456, 1, 'S')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46451,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100288, 100456, 4, 'P')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46452,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100288, 100456, 1, 3, '2022-10-11 16:49:47.000000', 3)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46453,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100288, 100456, 2, 3, '2022-10-11 16:49:47.000000', 2)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46454,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100288, 100456, 4, 3, '2022-10-11 16:49:47.000000', 1)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46455,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100288, 100456, 5, 3, '2022-10-11 16:49:47.000000', 4)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46456,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100288, 100456, 6, 3, '2022-10-11 16:49:47.000000')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46457,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100288, 100456, 7, 3, '2022-10-11 16:49:47.000000')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46458,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100288, 100456, 9, 3, '2022-10-11 16:49:47.000000')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46459,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100393, 100456, 31, 'S', ' ', 'N', 'N', 140, 94, 'A', 'HTA EMBARAZO ANTERIOR')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46460,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100289, 'FERREYRA', 'AURELIA', '39892246', '1996-08-15 00:00:00.000000', 1, 12, 'F')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46461,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100457, '2022-10-11 16:56:25.000000', 100289, 1, 1)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46462,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100289, 100289, 22, 3, '8', '2022-10-11 16:56:25.000000', '', 12)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46463,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, abortos, planificado, fum, fpp) VALUES (100282, 100289, 100457, 20, '2021-02-12 00:00:00.000000', 4, 2, 1, 0, '2022-02-12 00:00:00.000000', '2022-11-19 00:00:00.000000')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46464,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100289, 100457, 2, 'S')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46465,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100289, 100457, 3, 'N')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46466,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100289, 100457, 1, 'S')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46467,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100289, 100457, 4, 'P')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46468,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100289, 100457, 1, 3, '2022-10-11 17:02:29.000000', 3)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46469,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100289, 100457, 2, 3, '2022-10-11 17:02:29.000000', 2)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46470,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100289, 100457, 4, 3, '2022-10-11 17:02:29.000000', '2022-10-11 17:02:29.000000', 'N', 1)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46471,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100289, 100457, 5, 3, '2022-10-11 17:02:29.000000', '2022-10-11 17:02:29.000000', 'N', 4)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46472,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100289, 100457, 6, 3, '2022-10-11 17:02:29.000000')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46473,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100289, 100457, 7, 3, '2022-10-11 17:02:29.000000')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46474,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (100289, 100457, 9, 3, '2022-10-11 17:02:29.000000', '2022-10-11 17:02:29.000000', '0+')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46475,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100394, 100457, 34, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46476,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100458, '2022-10-11 17:05:24.000000', 100207, 2, 1)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46477,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100207, 100458, 2, 'S')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46478,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100207, 100458, 3, 'N')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46479,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100207, 100458, 4, 'P')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46480,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100395, 100458, 28, 'S', ' ', 'N', 'N', 120, 74, 'N', '')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46481,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100290, 'PEREZ', 'RAQUEL', '41718982', '1998-03-04 00:00:00.000000', 1, 12, 'F')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46482,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100459, '2022-10-11 17:14:45.000000', 100290, 1, 1)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46483,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100290, 100290, 22, 3, '10', '2022-10-11 17:14:45.000000', '', 12)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46484,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100283, 100290, 100459, 16, '2019-08-21 00:00:00.000000', 4, 3, 0, '2022-06-24 00:00:00.000000', '2023-03-31 00:00:00.000000')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46485,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (100283, 3)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46486,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100290, 100459, 2, 'N')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46487,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100290, 100459, 3, 'N')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46488,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100290, 100459, 1, 'N')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46489,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100290, 100459, 4, 'N')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46490,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100290, 100459, 1, 2, '2022-10-11 17:22:34.000000', 3)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46491,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100290, 100459, 2, 2, '2022-10-11 17:22:34.000000', 2)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46492,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100290, 100459, 4, 2, '2022-10-11 17:22:34.000000', 1)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46493,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100290, 100459, 5, 2, '2022-10-11 17:22:34.000000', 4)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46494,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100290, 100459, 6, 2, '2022-10-11 17:22:34.000000')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46495,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100290, 100459, 7, 2, '2022-10-11 17:22:34.000000')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46496,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100290, 100459, 9, 2, '2022-10-11 17:22:34.000000')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46497,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100396, 100459, 15, 'S', ' ', 'N', 'N', 120, 70, 'N', '')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46498,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100291, 'CAMPOS', 'MARCELINA', '40523547', '1997-07-04 00:00:00.000000', 1, 12, 'F')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46499,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100460, '2022-10-11 17:34:13.000000', 100291, 1, 1)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46500,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100291, 100291, 22, 3, '11', '2022-10-11 17:34:13.000000', '', 12)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46501,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100284, 100291, 100460, 20, '2021-12-01 00:00:00.000000', 3, 2, 0, '2022-08-15 00:00:00.000000', '2023-05-22 00:00:00.000000')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46502,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (100284, 3)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46503,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100291, 100460, 2, 'N')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46504,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100291, 100460, 3, 'N')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46505,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100291, 100460, 1, 'N')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46506,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100291, 100460, 4, 'N')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46507,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100291, 100460, 1, 1, '2022-10-11 17:40:16.000000', 3)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46508,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100291, 100460, 2, 1, '2022-10-11 17:40:16.000000', 2)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46509,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100291, 100460, 4, 1, '2022-10-11 17:40:16.000000', 1)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46510,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100291, 100460, 5, 1, '2022-10-11 17:40:16.000000', 4)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46511,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100291, 100460, 6, 1, '2022-10-11 17:40:16.000000')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46512,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100291, 100460, 7, 1, '2022-10-11 17:40:16.000000')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46513,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100291, 100460, 9, 1, '2022-10-11 17:40:16.000000')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46514,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100397, 100460, 8, 'S', ' ', 'N', 'N', 130, 90, 'N', '')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46515,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100461, '2022-10-11 17:54:32.000000', 100200, 2, 1)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46516,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100200, 100461, 2, 'S')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46517,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100200, 100461, 3, 'N')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46518,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100200, 100461, 4, 'N')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46519,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100398, 100461, 124, 'S', ' ', 'N', 'N', 116, 70, 'N', '')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46520,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100292, 'ORTIZ', 'ZULMA', '11111111', '1988-10-16 00:00:00.000000', 2, 12, 'F')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46521,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100462, '2022-10-11 18:01:45.000000', 100292, 1, 1)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46522,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100292, 100292, 22, 3, '17', '2022-10-11 18:01:45.000000', '', 12)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46523,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, abortos, planificado, fum, fpp) VALUES (100285, 100292, 100462, 0, 9, 7, 1, 1, '2022-06-06 00:00:00.000000', '2023-03-13 00:00:00.000000')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46524,
                    "INSERT INTO antecedentes_apps (id_antecedente, id_app) VALUES (100285, 8)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46525,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (100285, 3)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46526,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100292, 100462, 2, 'N')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46527,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100292, 100462, 3, 'N')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46528,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100292, 100462, 1, 'N')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46529,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100292, 100462, 4, 'N')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46530,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100292, 100462, 1, 2, '2022-10-11 18:09:19.000000', 3)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46531,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100292, 100462, 2, 2, '2022-10-11 18:09:19.000000', 2)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46532,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100292, 100462, 4, 2, '2022-10-11 18:09:19.000000', 1)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46533,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100292, 100462, 5, 2, '2022-10-11 18:09:19.000000', 4)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46534,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100292, 100462, 6, 2, '2022-10-11 18:09:19.000000')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46535,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100292, 100462, 7, 2, '2022-10-11 18:09:19.000000')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46536,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100292, 100462, 9, 2, '2022-10-11 18:09:19.000000')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46537,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100399, 100462, 18, 'S', ' ', 'N', 'N', 130, 70, 'N', 'EMBARAZO ANTERIOR CHAGAS +. REALIZÒ TRATAMIENTO EN 2019. REFIERE QUE SE TESTEARON DOS HIJAS. EL RESTO NO VIVEN CON ELLA SON MAYORES DE 19 AÑOS')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46538,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100293, 'FAIAD', 'GRISELDA', '51391819', '2002-05-16 00:00:00.000000', 1, 12, 'F')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46539,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100463, '2022-10-11 18:14:50.000000', 100293, 1, 1)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46540,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100293, 100293, 22, 3, '19', '2022-10-11 18:14:50.000000', '', 12)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46541,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, cesareas, planificado, fum, fpp) VALUES (100286, 100293, 100463, 17, '2021-01-05 00:00:00.000000', 2, 1, 0, '2022-03-03 00:00:00.000000', '2022-12-08 00:00:00.000000')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46542,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100293, 100463, 2, 'S')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46543,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100293, 100463, 3, 'N')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46544,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100293, 100463, 1, 'N')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46545,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100293, 100463, 4, 'P')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46546,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100293, 100463, 1, 3, '2022-10-11 18:20:17.000000', 3)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46547,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100293, 100463, 2, 3, '2022-10-11 18:20:17.000000', 2)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46548,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100293, 100463, 4, 3, '2022-10-11 18:20:17.000000', 1)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46549,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100293, 100463, 5, 3, '2022-10-11 18:20:17.000000', 4)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46550,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100293, 100463, 6, 3, '2022-10-11 18:20:17.000000')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46551,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100293, 100463, 7, 3, '2022-10-11 18:20:17.000000')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46552,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100293, 100463, 9, 3, '2022-10-11 18:20:17.000000')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46553,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100400, 100463, 31, 'S', ' ', 'N', 'N', 140, 80, 'N', '')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46554,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100285, 100453, 2, 'N')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46555,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100285, 100453, 3, 'N')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46556,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100285, 100453, 1, 'N')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46557,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100285, 100453, 4, 'N')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46558,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100285, 100453, 1, 1, '2022-10-11 18:23:11.000000', 3)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46559,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100285, 100453, 2, 1, '2022-10-11 18:23:11.000000', 2)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46560,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100285, 100453, 4, 1, '2022-10-11 18:23:11.000000', 1)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46561,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100285, 100453, 5, 1, '2022-10-11 18:23:11.000000', 4)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46562,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100285, 100453, 6, 1, '2022-10-11 18:23:11.000000')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46563,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100285, 100453, 7, 1, '2022-10-11 18:23:11.000000')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46564,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100285, 100453, 9, 1, '2022-10-11 18:23:11.000000')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46565,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100401, 100453, 8, 'S', ' ', 'N', 'N', 120, 80, 'N', '')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46566,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=474",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46567,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-12', resultado='N' WHERE laboratorios_realizados.id_persona=403 AND laboratorios_realizados.id_control=686 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46568,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-12', resultado='N' WHERE laboratorios_realizados.id_persona=403 AND laboratorios_realizados.id_control=686 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46569,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-12', resultado='N' WHERE laboratorios_realizados.id_persona=403 AND laboratorios_realizados.id_control=686 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46570,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-12', resultado='N' WHERE laboratorios_realizados.id_persona=403 AND laboratorios_realizados.id_control=686 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46571,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-12', resultado='93' WHERE laboratorios_realizados.id_persona=403 AND laboratorios_realizados.id_control=686 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46572,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-12', resultado='0+' WHERE laboratorios_realizados.id_persona=403 AND laboratorios_realizados.id_control=686 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46573,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100464, '2022-10-12 09:50:24.000000', 403, 2, 1)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46574,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (403, 100464, 2, 'S')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46575,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (403, 100464, 3, 'N')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46576,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (403, 100464, 4, 'P')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46577,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100402, 100464, 23, 'S', ' ', 'N', 'N', 120, 70, 'N', '')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46578,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100294, 'PEREZ', 'FILOMENA', '42752314', '2000-10-28 00:00:00.000000', 1, 12, 'F')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46579,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100465, '2022-10-12 10:13:13.000000', 100294, 1, 1)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46580,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100294, 100294, 39, 3, '2', '2022-10-12 10:13:13.000000', '', 12)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46581,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, abortos, planificado, fum, fpp) VALUES (100287, 100294, 100465, 0, '2021-10-20 00:00:00.000000', 4, 1, 2, 0, '2022-08-08 00:00:00.000000', '2023-05-15 00:00:00.000000')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46582,
                    "INSERT INTO antecedentes_apps (id_antecedente, id_app) VALUES (100287, 4)",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46583,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100294, 100465, 2, 'S')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46584,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100294, 100465, 3, 'P')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46585,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100294, 100465, 1, 'S')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46586,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100294, 100465, 4, 'P')",
                    "2022-12-27 16:44:18",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46587,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100294, 100465, 1, 1, '2022-10-12 10:25:36.000000', 3)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46588,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100294, 100465, 2, 1, '2022-10-12 10:25:36.000000', 2)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46589,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100294, 100465, 4, 1, '2022-10-12 10:25:36.000000', 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46590,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100294, 100465, 5, 1, '2022-10-12 10:25:36.000000', 4)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46591,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100294, 100465, 6, 1, '2022-10-12 10:25:36.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46592,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100294, 100465, 7, 1, '2022-10-12 10:25:36.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46593,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100294, 100465, 9, 1, '2022-10-12 10:25:36.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46594,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100403, 100465, 9, 'R', ' ', 'N', 'N', 110, 70, 'N', '')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46595,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-12', resultado='N' WHERE laboratorios_realizados.id_persona=402 AND laboratorios_realizados.id_control=685 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46596,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-12', resultado='N' WHERE laboratorios_realizados.id_persona=402 AND laboratorios_realizados.id_control=685 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46597,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-12', resultado='N' WHERE laboratorios_realizados.id_persona=402 AND laboratorios_realizados.id_control=685 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46598,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-12', resultado='N' WHERE laboratorios_realizados.id_persona=402 AND laboratorios_realizados.id_control=685 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46599,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-12', resultado='76' WHERE laboratorios_realizados.id_persona=402 AND laboratorios_realizados.id_control=685 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46600,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-12', resultado='O+' WHERE laboratorios_realizados.id_persona=402 AND laboratorios_realizados.id_control=685 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46601,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=476",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46602,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100466, '2022-10-12 10:32:43.000000', 402, 2, 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46603,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (402, 100466, 2, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46604,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (402, 100466, 3, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46605,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (402, 100466, 4, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46606,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100404, 100466, 14, 'S', ' ', 'N', 'N', 110, 70, 'N', '')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46607,
                    "UPDATE controles SET georeferencia='-22.4744463,-62.3919621' WHERE controles.id_control=100466",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46608,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100295, 'PEREZ', 'GILDA', '40487622', '1998-08-08 00:00:00.000000', 1, 12, 'F')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46609,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, georeferencia) VALUES (100467, '2022-10-12 10:37:26.000000', 100295, 1, 1, '-22.2725026,-62.7125106')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46610,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100295, 100295, 39, 3, '0', '2022-10-12 10:37:26.000000', '-22.2725026,-62.7125106', 12)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46611,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100288, 100295, 100467, 0, 4, 3, 0, '2022-06-17 00:00:00.000000', '2023-03-24 00:00:00.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46612,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100295, 100467, 2, 'N')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46613,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100295, 100467, 3, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46614,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100295, 100467, 1, 'N')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46615,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100295, 100467, 4, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46616,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100295, 100467, 1, 2, '2022-10-12 10:42:11.000000', 3)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46617,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100295, 100467, 2, 2, '2022-10-12 10:42:11.000000', 2)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46618,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100295, 100467, 4, 2, '2022-10-12 10:42:11.000000', 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46619,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100295, 100467, 5, 2, '2022-10-12 10:42:11.000000', 4)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46620,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100295, 100467, 6, 2, '2022-10-12 10:42:11.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46621,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100295, 100467, 7, 2, '2022-10-12 10:42:11.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46622,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100295, 100467, 9, 2, '2022-10-12 10:42:11.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46623,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100405, 100467, 16, 'R', ' ', 'N', 'N', 100, 50, 'N', '')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46624,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100404",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46625,
                    "SELECT id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo, madre, alta, nacido_vivo FROM personas WHERE id_persona = '414'",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46626,
                    "SELECT id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais FROM ubicaciones WHERE id_ubicacion = '414'",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46627,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100296, 'SANDOVAL', 'HILDA', '34324351', '1986-03-29 00:00:00.000000', 1, 12, 'F')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46628,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, georeferencia) VALUES (100468, '2022-10-12 11:07:11.000000', 100296, 1, 1, '-22.2725026,-62.7125106')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46629,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100296, 100296, 39, 3, '1', '2022-10-12 11:07:11.000000', '-22.2725026,-62.7125106', 12)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46630,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100289, 100296, 100468, 0, 8, 7, 0, '2022-01-13 00:00:00.000000', '2022-10-20 00:00:00.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46631,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100296, 100468, 2, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46632,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100296, 100468, 3, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46633,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100296, 100468, 1, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46634,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100296, 100468, 4, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46635,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100296, 100468, 1, 3, '2022-10-12 11:09:54.000000', 3)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46636,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100296, 100468, 2, 3, '2022-10-12 11:09:54.000000', 2)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46637,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100296, 100468, 4, 3, '2022-10-12 11:09:54.000000', '2022-10-12 11:09:54.000000', 'N', 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46638,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100296, 100468, 5, 3, '2022-10-12 11:09:54.000000', '2022-10-12 11:09:54.000000', 'N', 4)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46639,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (100296, 100468, 9, 3, '2022-10-12 11:09:54.000000', '2022-10-12 11:09:54.000000', '0+')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46640,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100406, 100468, 38, 'R', ' ', 'N', 'N', 10, 70, 'N', '')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46641,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100469, '2022-10-12 11:18:15.000000', 364, 2, 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46642,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (364, 100469, 2, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46643,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (364, 100469, 3, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46644,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (364, 100469, 4, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46645,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (364, 100469, 1, 3, '2022-10-12 11:19:35.000000', 3)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46646,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (364, 100469, 2, 3, '2022-10-12 11:19:35.000000', 2)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46647,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100407, 100469, 38, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46648,
                    "UPDATE controles SET georeferencia='-22.4744463,-62.3919621' WHERE controles.id_control=100469",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46649,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100297, 'RIOS', 'BRUNA', '5136341', '1989-10-06 00:00:00.000000', 1, 177, 'F')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46650,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100470, '2022-10-12 14:54:23.000000', 100297, 1, 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46651,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100297, 100297, 3, 2, '1', '2022-10-12 14:54:23.000000', '', 177)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46652,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100290, 100297, 100470, 0, 4, 3, 0, '2022-08-10 00:00:00.000000', '2023-05-17 00:00:00.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46653,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100297, 100470, 2, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46654,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100297, 100470, 3, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46655,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100297, 100470, 1, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46656,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100297, 100470, 4, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46657,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100297, 100470, 1, 1, '2022-10-12 14:56:21.000000', 3)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46658,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100297, 100470, 2, 1, '2022-10-12 14:56:21.000000', 2)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46659,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100297, 100470, 4, 1, '2022-10-12 14:56:21.000000', 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46660,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100297, 100470, 5, 1, '2022-10-12 14:56:21.000000', 4)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46661,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100297, 100470, 6, 1, '2022-10-12 14:56:21.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46662,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100297, 100470, 7, 1, '2022-10-12 14:56:21.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46663,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100297, 100470, 9, 1, '2022-10-12 14:56:21.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46664,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100408, 100470, 9, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46665,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100471, '2022-10-12 14:57:49.000000', 100222, 2, 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46666,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100222, 100471, 2, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46667,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100222, 100471, 3, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46668,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100222, 100471, 4, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46669,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100222, 100471, 1, 2, '2022-10-12 15:00:50.000000', 3)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46670,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100222, 100471, 2, 2, '2022-10-12 15:00:50.000000', 2)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46671,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100409, 100471, 26, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46672,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100472, '2022-10-12 15:06:06.000000', 100215, 2, 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46673,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100215, 100472, 2, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46674,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100215, 100472, 3, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46675,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100215, 100472, 4, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46676,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100215, 100472, 1, 3, '2022-10-12 15:22:25.000000', 3)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46677,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100215, 100472, 2, 3, '2022-10-12 15:22:25.000000', 2)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46678,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100410, 100472, 34, 'P', 'FORMACIONES QUISTICAS, EN CAVIDAD ABDOMINAL COMPATIBLE CON POLIQUISTOSIS RENAL, SE VE UN RIÑON NORMAL, .SE INFORMA PARA  EVALUACION ECOGRAFICA DE ALTO RIESGO', 'N', 'N', 110, 60, 'N', '')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46679,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100473, '2022-10-12 15:24:13.000000', 100217, 2, 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46680,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100217, 100473, 2, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46681,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100217, 100473, 3, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46682,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100217, 100473, 4, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46683,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100411, 100473, 30, 'R', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46684,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100474, '2022-10-12 15:27:10.000000', 100216, 2, 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46685,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100216, 100474, 2, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46686,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100216, 100474, 3, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46687,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100216, 100474, 4, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46688,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100216, 100474, 1, 2, '2022-10-12 15:29:43.000000', 3)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46689,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100216, 100474, 2, 2, '2022-10-12 15:29:43.000000', 2)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46690,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100412, 100474, 26, 'R', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46691,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100298, 'BARRIOS CENTURION', 'ISABEL REYNALDA', '6043148', '1993-09-19 00:00:00.000000', 2, 177, 'F')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46692,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100475, '2022-10-12 15:33:23.000000', 100298, 1, 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46693,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100298, 100298, 3, 2, '1', '2022-10-12 15:33:23.000000', '', 177)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46694,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100291, 100298, 100475, 0, 4, 3, 0, '2022-08-09 00:00:00.000000', '2023-05-16 00:00:00.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46695,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100298, 100475, 2, 'N')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46696,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100298, 100475, 3, 'N')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46697,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100298, 100475, 1, 'N')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46698,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100298, 100475, 4, 'N')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46699,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100298, 100475, 1, 1, '2022-10-12 15:38:00.000000', 3)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46700,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100298, 100475, 2, 1, '2022-10-12 15:38:00.000000', 2)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46701,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100298, 100475, 4, 1, '2022-10-12 15:38:00.000000', 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46702,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100298, 100475, 5, 1, '2022-10-12 15:38:00.000000', 4)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46703,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100298, 100475, 7, 1, '2022-10-12 15:38:00.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46704,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100298, 100475, 9, 1, '2022-10-12 15:38:00.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46705,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100413, 100475, 9, 'R', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46706,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100299, 'PALOMO', 'NOELIA', '31587190', '1985-04-23 00:00:00.000000', 2, 177, 'F')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46707,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100476, '2022-10-12 15:40:02.000000', 100299, 1, 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46708,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100299, 100299, 3, 2, '1', '2022-10-12 15:40:02.000000', '', 177)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46709,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100292, 100299, 100476, 0, 5, 4, 0, '2022-06-04 00:00:00.000000', '2023-03-11 00:00:00.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46710,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (100292, 3)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46711,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100299, 100476, 2, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46712,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100299, 100476, 3, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46713,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100299, 100476, 1, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46714,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100299, 100476, 4, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46715,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100299, 100476, 1, 2, '2022-10-12 15:48:17.000000', 3)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46716,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100299, 100476, 2, 2, '2022-10-12 15:48:17.000000', 2)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46717,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100299, 100476, 4, 2, '2022-10-12 15:48:17.000000', 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46718,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100299, 100476, 5, 2, '2022-10-12 15:48:17.000000', 4)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46719,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100299, 100476, 7, 2, '2022-10-12 15:48:17.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46720,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100299, 100476, 9, 2, '2022-10-12 15:48:17.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46721,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100414, 100476, 18, 'R', ' ', 'N', 'N', 110, 60, 'N', '')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46722,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100477, '2022-10-12 15:48:45.000000', 100218, 2, 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46723,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100218, 100477, 2, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46724,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100218, 100477, 3, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46725,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100218, 100477, 4, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46726,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100415, 100477, 18, 'R', ' ', 'N', 'N', 110, 70, 'N', '')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46727,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100300, 'DEL VALLE', 'JAMALET IRMA', '7561210', '2000-07-24 00:00:00.000000', 2, 177, 'F')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46728,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100478, '2022-10-12 17:17:31.000000', 100300, 1, 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46729,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100300, 100300, 4, 2, '1', '2022-10-12 17:17:31.000000', '', 177)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46730,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (100293, 100300, 100478, 0, 1, 1, '2022-06-12 00:00:00.000000', '2023-03-19 00:00:00.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46731,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100300, 100478, 2, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46732,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100300, 100478, 3, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46733,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100300, 100478, 1, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46734,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100300, 100478, 4, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46735,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100300, 100478, 1, 2, '2022-10-12 17:22:57.000000', '2022-10-12 17:22:57.000000', 'N', 3)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46736,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100300, 100478, 2, 2, '2022-10-12 17:22:57.000000', '2022-10-12 17:22:57.000000', 'N', 2)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46737,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100300, 100478, 4, 2, '2022-10-12 17:22:57.000000', '2022-10-12 17:22:57.000000', 'N', 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46738,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100300, 100478, 5, 2, '2022-10-12 17:22:57.000000', 4)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46739,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (100300, 100478, 7, 2, '2022-10-12 17:22:57.000000', '2022-10-12 17:22:57.000000', '89')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46740,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (100300, 100478, 9, 2, '2022-10-12 17:22:57.000000', '2022-10-12 17:22:57.000000', 'A+')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46741,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100416, 100478, 17, 'S', ' ', 'N', 'N', 100, 50, 'N', '')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46742,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100479, '2022-10-12 17:26:15.000000', 100242, 2, 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46743,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100242, 100479, 2, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46744,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100242, 100479, 3, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46745,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100242, 100479, 4, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46746,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100417, 100479, 14, 'P', 'AREAS DE DESPRENDIMIENTO DE 1,7 ML, INDICACION DE REPOSO', 'N', 'N', 150, 100, 'A', 'CON ALFAMETILDOPA CADA 8 HORAS. EN CONTROL ESTRICTO DE TA. SIN AUMENTO EXCESIVO DE PESO NO EDEMAS. CONTROL ESTRICTO E INDICACION DE REPOSO, POR UN AREA DE DESPRENDIMIENTO PLACENTARIO, PAUTAS DE ALARMA Y CONTROL')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46747,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100480, '2022-10-12 17:36:00.000000', 100246, 2, 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46748,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100246, 100480, 2, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46749,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100246, 100480, 3, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46750,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100246, 100480, 4, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46751,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100418, 100480, 15, 'R', ' ', 'N', 'N', 110, 60, 'N', '')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46752,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100481, '2022-10-12 17:40:42.000000', 100246, 3, 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46753,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-12', resultado='N' WHERE laboratorios_realizados.id_persona=100300 AND laboratorios_realizados.id_control=100478 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46754,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100482, '2022-10-12 17:45:23.000000', 200067, 4, 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46755,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100246, 100481, 2, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46756,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100246, 100481, 3, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46757,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100246, 100481, 4, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46758,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100246, 100481, 1, 2, '2022-10-12 17:49:49.000000', '2022-10-12 17:49:49.000000', 'N', 3)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46759,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100246, 100481, 2, 2, '2022-10-12 17:49:49.000000', '2022-10-12 17:49:49.000000', 'N', 2)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46760,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100246, 100481, 4, 2, '2022-10-12 17:49:49.000000', '2022-10-12 17:49:49.000000', 'N', 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46761,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100246, 100481, 5, 2, '2022-10-12 17:49:49.000000', '2022-10-12 17:49:49.000000', 'N', 4)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46762,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100419, 100481, 15, 'R', ' ', 'N', 'N', 110, 60, 'N', '')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46763,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100483, '2022-10-12 17:50:40.000000', 100253, 2, 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46764,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100253, 100483, 2, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46765,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100253, 100483, 3, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46766,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100253, 100483, 4, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46767,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100420, 100483, 20, 'R', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46768,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100484, '2022-10-12 18:01:52.000000', 200065, 4, 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46769,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200067, 100482, 2, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46770,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200067, 100482, 3, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46771,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200067, 100482, 4, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46772,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (200067, 100482, 1, 3, '2022-10-12 18:02:48.000000', '2022-10-12 18:02:48.000000', 'N', 3)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46773,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (200067, 100482, 2, 3, '2022-10-12 18:02:48.000000', '2022-10-12 18:02:48.000000', 'N', 2)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46774,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100421, 100482, 29, 'R', ' ', 'N', 'N', 120, 70, 'N', '')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46775,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100485, '2022-10-12 18:06:45.000000', 100250, 2, 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46776,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100250, 100485, 2, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46777,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100250, 100485, 3, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46778,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100250, 100485, 4, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46779,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100250, 100485, 1, 2, '2022-10-12 18:11:41.000000', '2022-10-12 18:11:41.000000', 'N', 3)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46780,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100250, 100485, 2, 2, '2022-10-12 18:11:41.000000', '2022-10-12 18:11:41.000000', 'N', 2)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46781,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100250, 100485, 4, 2, '2022-10-12 18:11:41.000000', '2022-10-12 18:11:41.000000', 'N', 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46782,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100250, 100485, 5, 2, '2022-10-12 18:11:41.000000', '2022-10-12 18:11:41.000000', 'N', 4)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46783,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (100250, 100485, 9, 2, '2022-10-12 18:11:41.000000', '2022-10-12 18:11:41.000000', '0+')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46784,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100422, 100485, 18, 'R', ' ', 'N', 'N', 120, 80, 'N', '')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46785,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200065, 100484, 2, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46786,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200065, 100484, 3, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46787,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (200065, 100484, 4, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46788,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (200065, 100484, 1, 3, '2022-10-12 18:12:10.000000', '2022-10-12 18:12:10.000000', 'N', 3)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46789,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (200065, 100484, 2, 3, '2022-10-12 18:12:10.000000', '2022-10-12 18:12:10.000000', 'N', 2)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46790,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100423, 100484, 33, 'R', ' ', 'N', 'N', 110, 60, 'N', '')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46791,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (383, 693, 22, 3, '2022-10-13 08:28:23.000000', 4)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46792,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (383, 693, 15, 3, '2022-10-13 08:28:23.000000', 4)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46793,
                    "INSERT INTO etmis_personas (id_persona, id_etmi, id_control) VALUES (383, 4, 693)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46794,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-13', resultado='P' WHERE laboratorios_realizados.id_persona=383 AND laboratorios_realizados.id_control=693 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46795,
                    "SELECT etmis_personas.id_persona, etmis_personas.id_etmi, etmis_personas.id_control, etmis_personas.confirmada FROM etmis_personas WHERE etmis_personas.id_etmi=4 AND etmis_personas.confirmada=0 AND etmis_personas.id_control='693' LIMIT 1",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46796,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-13', resultado='N' WHERE laboratorios_realizados.id_persona=383 AND laboratorios_realizados.id_control=693 AND laboratorios_realizados.id_laboratorio=15 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46797,
                    "SELECT etmis_personas.id_persona, etmis_personas.id_etmi, etmis_personas.id_control, etmis_personas.confirmada FROM etmis_personas WHERE etmis_personas.id_etmi=4 AND etmis_personas.confirmada=0 AND etmis_personas.id_control='693' LIMIT 1",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46798,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-13', resultado='N' WHERE laboratorios_realizados.id_persona=383 AND laboratorios_realizados.id_control=693 AND laboratorios_realizados.id_laboratorio=22 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46799,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-13', resultado='-' WHERE laboratorios_realizados.id_persona=383 AND laboratorios_realizados.id_control=693 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46800,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100486, '2022-10-13 08:29:31.000000', 383, 3, 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46801,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100487, '2022-10-13 08:35:32.000000', 406, 2, 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46802,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (406, 100487, 2, 'N')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46803,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (406, 100487, 3, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46804,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (406, 100487, 4, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46805,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100424, 100487, 18, 'R', ' ', 'N', 'N', 100, 50, 'N', '')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46806,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (383, 100486, 2, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46807,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (383, 100486, 3, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46808,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (383, 100486, 4, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46809,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (383, 100486, 1, 3, '2022-10-13 08:50:15.000000', '2022-10-13 08:50:15.000000', 'N', 3)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46810,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (383, 100486, 2, 3, '2022-10-13 08:50:15.000000', '2022-10-13 08:50:15.000000', 'N', 2)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46811,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100425, 100486, 37, 'R', ' ', 'N', 'N', 110, 70, 'N', '')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46812,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100488, '2022-10-13 09:07:26.000000', 405, 2, 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46813,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (405, 100488, 2, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46814,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (405, 100488, 3, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46815,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (405, 100488, 4, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46816,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (405, 100488, 1, 3, '2022-10-13 10:55:18.000000', '2022-10-13 10:55:18.000000', 'N', 3)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46817,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (405, 100488, 2, 3, '2022-10-13 10:55:18.000000', '2022-10-13 10:55:18.000000', 'N', 2)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46818,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (405, 100488, 4, 3, '2022-10-13 10:55:18.000000', '2022-10-13 10:55:18.000000', 'N', 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46819,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (405, 100488, 5, 3, '2022-10-13 10:55:18.000000', '2022-10-13 10:55:18.000000', 'N', 4)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46820,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (405, 100488, 6, 3, '2022-10-13 10:55:18.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46821,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (405, 100488, 7, 3, '2022-10-13 10:55:18.000000', '2022-10-13 10:55:18.000000', '70')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46822,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100426, 100488, 32, 'P', 'RCIU, OLIGOAMNIOS NO SE VISUALIZAN CUATROCAMARAS CARDIACAS YA DERIVADA A TARTAGAL EN AGOSTO, REALIZAN ECODOPLER Y DAN ALTA CON CONTROLES MENSUALES. AHORA SE AVISA A HTAL DE TARTAGAL..', 'N', 'N', 90, 50, 'A', 'RCIU CON OLIGOAMNIOS POR FUM 32 SEM POR ECO 26 SEMANAS, YA EVALUADA EN ANTERIOR CONTROL A TARTAGAL CON ECO DOPLER, Y SE DIO ALTA CON CONTROLES MENSUALES. PERSISTE EL RCIU OLIGOAMNIOS MODERADO, SE AVISA A HOSPITAL CENTRAL PARA REALIZAR MADURACIÓN PULONAR Y ECO DOPLER.')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46823,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100301, 'PEREZ', 'ERCILIA', '33584953', '1988-10-23 00:00:00.000000', 1, 12, 'F')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46824,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100489, '2022-10-13 15:24:57.000000', 100301, 1, 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46825,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100301, 100301, 11, 4, '2', '2022-10-13 15:24:57.000000', '', 12)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46826,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, abortos, planificado, fum, fpp) VALUES (100294, 100301, 100489, 0, '2014-01-01 00:00:00.000000', 9, 7, 1, 0, '2022-05-08 00:00:00.000000', '2023-02-12 00:00:00.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46827,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (100294, 2)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46828,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100301, 100489, 2, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46829,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100301, 100489, 3, 'N')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46830,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100301, 100489, 1, 'N')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46831,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100301, 100489, 4, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46832,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100301, 100489, 1, 2, '2022-10-13 15:34:41.000000', 3)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46833,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100301, 100489, 2, 2, '2022-10-13 15:34:41.000000', 2)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46834,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100301, 100489, 4, 2, '2022-10-13 15:34:41.000000', 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46835,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100301, 100489, 5, 2, '2022-10-13 15:34:41.000000', 4)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46836,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100301, 100489, 6, 2, '2022-10-13 15:34:41.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46837,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (100301, 100489, 7, 2, '2022-10-13 15:34:41.000000', '2022-10-13 15:34:41.000000', '79')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46838,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (100301, 100489, 9, 2, '2022-10-13 15:34:41.000000', '2022-10-13 15:34:41.000000', '0+')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46839,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100427, 100489, 22, 'R', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46840,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100382",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46841,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-13', resultado='N' WHERE laboratorios_realizados.id_persona=100279 AND laboratorios_realizados.id_control=100444 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46842,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-13', resultado='N' WHERE laboratorios_realizados.id_persona=100279 AND laboratorios_realizados.id_control=100444 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46843,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-13', resultado='N' WHERE laboratorios_realizados.id_persona=100279 AND laboratorios_realizados.id_control=100444 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46844,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-13', resultado='N' WHERE laboratorios_realizados.id_persona=100279 AND laboratorios_realizados.id_control=100444 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46845,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-13', resultado='12' WHERE laboratorios_realizados.id_persona=100279 AND laboratorios_realizados.id_control=100444 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46846,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-13', resultado='87' WHERE laboratorios_realizados.id_persona=100279 AND laboratorios_realizados.id_control=100444 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46847,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2022-10-13', resultado='O+' WHERE laboratorios_realizados.id_persona=100279 AND laboratorios_realizados.id_control=100444 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46848,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100490, '2022-10-13 15:39:29.000000', 100279, 2, 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46849,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100279, 100490, 2, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46850,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100279, 100490, 3, 'N')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46851,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100279, 100490, 4, 'N')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46852,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100428, 100490, 481, 'R', ' ', 'N', 'N', 130, 70, 'N', '')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46853,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100302, 'LOPEZ', 'VENICIA', '46065392', '2006-08-28 00:00:00.000000', 1, 12, 'F')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46854,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100491, '2022-10-13 15:53:47.000000', 100302, 1, 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46855,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100302, 100302, 11, 4, '3', '2022-10-13 15:53:47.000000', '', 12)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46856,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100295, 100302, 100491, 14, 2, 1, 0, '2022-04-15 00:00:00.000000', '2023-01-20 00:00:00.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46857,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100302, 100491, 2, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46858,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100302, 100491, 3, 'N')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46859,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100302, 100491, 1, 'N')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46860,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100302, 100491, 4, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46861,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100302, 100491, 1, 2, '2022-10-13 16:03:39.000000', '2022-10-13 16:03:39.000000', 'N', 3)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46862,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100302, 100491, 2, 2, '2022-10-13 16:03:39.000000', '2022-10-13 16:03:39.000000', 'N', 2)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46863,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100302, 100491, 4, 2, '2022-10-13 16:03:39.000000', '2022-10-13 16:03:39.000000', 'N', 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46864,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100302, 100491, 5, 2, '2022-10-13 16:03:39.000000', '2022-10-13 16:03:39.000000', 'N', 4)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46865,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (100302, 100491, 7, 2, '2022-10-13 16:03:39.000000', '2022-10-13 16:03:39.000000', '64')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46866,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado) VALUES (100302, 100491, 9, 2, '2022-10-13 16:03:39.000000', '2022-10-13 16:03:39.000000', '0+')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46867,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100429, 100491, 25, 'R', ' ', 'N', 'N', 100, 70, 'N', 'CORRESPONDE AL CONTROL DEL 1/9/22 QUE REALIZÒ ADESAR')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46868,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100492, '2022-10-13 16:03:52.000000', 100302, 2, 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46869,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100302, 100492, 2, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46870,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100302, 100492, 3, 'N')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46871,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100302, 100492, 4, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46872,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100302, 100492, 1, 2, '2022-10-13 16:04:44.000000', '2022-10-13 16:04:44.000000', 'N', 3)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46873,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100302, 100492, 2, 2, '2022-10-13 16:04:44.000000', '2022-10-13 16:04:44.000000', 'N', 2)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46874,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100430, 100492, 25, 'R', ' ', 'N', 'N', 130, 80, 'N', '')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46875,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100303, 'TORRES', 'MARICEL', '44328772', '2003-06-02 00:00:00.000000', 1, 12, 'F')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46876,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100493, '2022-10-13 16:10:49.000000', 100303, 1, 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46877,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100303, 100303, 11, 4, '6', '2022-10-13 16:10:49.000000', '', 12)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46878,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, planificado, fum, fpp) VALUES (100296, 100303, 100493, 0, '2017-12-19 00:00:00.000000', 3, 2, 0, '2022-06-08 00:00:00.000000', '2023-03-15 00:00:00.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46879,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100303, 100493, 2, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46880,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100303, 100493, 3, 'N')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46881,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100303, 100493, 1, 'N')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46882,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100303, 100493, 4, 'N')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46883,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100303, 100493, 1, 2, '2022-10-13 20:49:59.000000', '2022-10-13 20:49:59.000000', 'N', 3)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46884,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100303, 100493, 2, 2, '2022-10-13 20:49:59.000000', '2022-10-13 20:49:59.000000', 'N', 2)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46885,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, fecha_resultados, resultado, id_etmi) VALUES (100303, 100493, 5, 2, '2022-10-13 20:49:59.000000', '2022-10-13 20:49:59.000000', 'N', 4)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46886,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100431, 100493, 18, 'R', ' ', 'N', 'N', 110, 60, 'N', '')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46887,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100304, 'PALMA', 'CAROLINA', '11111111', '1994-01-20 00:00:00.000000', 1, 12, 'F')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46888,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100494, '2022-10-14 10:00:45.000000', 100304, 1, 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46889,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100304, 100304, 31, 3, '00', '2022-10-14 10:00:45.000000', '', 12)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46890,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100495, '2022-10-14 10:22:59.000000', 100260, 2, 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46891,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100496, '2022-10-14 10:29:07.000000', 100259, 2, 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46892,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100497, '2022-10-14 10:34:51.000000', 379, 3, 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46893,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100498, '2022-10-14 10:39:42.000000', 380, 3, 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46894,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100305, 'MENDOZA', 'VIVIANA', '42079358', '1999-09-14 00:00:00.000000', 2, 12, 'F')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46895,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100499, '2022-10-14 10:43:32.000000', 100305, 1, 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46896,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100305, 100305, 31, 3, '00', '2022-10-14 10:43:32.000000', '', 12)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46897,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, abortos, planificado, fum, fpp) VALUES (100297, 100305, 100499, 15, '2019-03-26 00:00:00.000000', 4, 2, 1, 0, '2022-05-17 00:00:00.000000', '2023-02-21 00:00:00.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46898,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (100297, 3)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46899,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100500, '2022-10-14 11:01:00.000000', 409, 2, 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46900,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100501, '2022-10-14 11:04:37.000000', 375, 3, 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46901,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100259, 100496, 2, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46902,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100259, 100496, 3, 'C')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46903,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100259, 100496, 4, 'C')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46904,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100259, 100496, 1, 3, '2022-10-14 11:13:28.000000', 3)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46905,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100259, 100496, 2, 3, '2022-10-14 11:13:28.000000', 2)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46906,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100259, 100496, 4, 3, '2022-10-14 11:13:28.000000', 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46907,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100259, 100496, 5, 3, '2022-10-14 11:13:28.000000', 4)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46908,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100259, 100496, 6, 3, '2022-10-14 11:13:28.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46909,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100259, 100496, 7, 3, '2022-10-14 11:13:28.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46910,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100259, 100496, 9, 3, '2022-10-14 11:13:28.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46911,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100432, 100496, 1073, 'S', ' ', 'N', 'N', 120, 60, 'N', '')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46912,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (380, 100498, 2, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46913,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (380, 100498, 3, 'C')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46914,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (380, 100498, 4, 'C')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46915,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (380, 100498, 1, 3, '2022-10-14 11:13:42.000000', 3)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46916,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (380, 100498, 2, 3, '2022-10-14 11:13:42.000000', 2)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46917,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (380, 100498, 4, 3, '2022-10-14 11:13:42.000000', 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46918,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (380, 100498, 5, 3, '2022-10-14 11:13:42.000000', 4)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46919,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (380, 100498, 6, 3, '2022-10-14 11:13:42.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46920,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (380, 100498, 7, 3, '2022-10-14 11:13:42.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46921,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100433, 100498, 34, 'S', ' ', 'N', 'N', 110, 70, 'N', '')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46922,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100502, '2022-10-14 11:22:27.000000', 100258, 2, 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46923,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (100306, 'PEREZ', 'ELENA', '37164138', '1992-09-11 00:00:00.000000', 1, 12, 'F')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46924,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (100503, '2022-10-14 11:27:42.000000', 100306, 1, 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46925,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (100306, 100306, 31, 3, '00', '2022-10-14 11:27:42.000000', '', 12)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46926,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, partos, abortos, planificado, fum, fpp) VALUES (100298, 100306, 100503, 23, '2017-01-01 00:00:00.000000', 4, 2, 1, 0, '2002-08-22 00:00:00.000000', '2009-05-23 00:00:00.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46927,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100260, 100495, 2, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46928,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100260, 100495, 3, 'C')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46929,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100260, 100495, 4, 'C')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46930,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100260, 100495, 6, 3, '2022-10-14 11:37:06.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46931,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100260, 100495, 7, 3, '2022-10-14 11:37:06.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46932,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100434, 100495, 538, 'S', ' ', 'N', 'N', 100, 50, 'N', '')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46933,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100305, 100499, 2, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46934,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100305, 100499, 3, 'C')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46935,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100305, 100499, 1, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46936,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100305, 100499, 4, 'C')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46937,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100305, 100499, 5, 2, '2022-10-14 11:38:52.000000', 4)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46938,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100305, 100499, 6, 2, '2022-10-14 11:38:52.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46939,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100305, 100499, 7, 2, '2022-10-14 11:38:52.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46940,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100435, 100499, 21, 'S', ' ', 'N', 'N', 90, 50, 'N', '')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46941,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100258, 100502, 2, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46942,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100258, 100502, 3, 'C')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46943,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100258, 100502, 4, 'C')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46944,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100258, 100502, 6, 3, '2022-10-14 12:06:05.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46945,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100258, 100502, 7, 3, '2022-10-14 12:06:05.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46946,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100436, 100502, 448, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46947,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (409, 100500, 2, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46948,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (409, 100500, 3, 'C')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46949,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (409, 100500, 4, 'P')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46950,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (409, 100500, 1, 3, '2022-10-14 12:07:24.000000', 3)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46951,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (409, 100500, 2, 3, '2022-10-14 12:07:24.000000', 2)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46952,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (409, 100500, 4, 3, '2022-10-14 12:07:24.000000', 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46953,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (409, 100500, 5, 3, '2022-10-14 12:07:24.000000', 4)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46954,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (409, 100500, 6, 3, '2022-10-14 12:07:24.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46955,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (409, 100500, 7, 3, '2022-10-14 12:07:24.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46956,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100437, 100500, 38, 'S', ' ', 'N', 'N', 120, 80, 'N', '')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46957,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (379, 100497, 2, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46958,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (379, 100497, 3, 'C')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46959,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (379, 100497, 4, 'C')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46960,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (379, 100497, 1, 3, '2022-10-14 12:08:02.000000', 3)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46961,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (379, 100497, 2, 3, '2022-10-14 12:08:02.000000', 2)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46962,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (379, 100497, 4, 3, '2022-10-14 12:08:02.000000', 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46963,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (379, 100497, 5, 3, '2022-10-14 12:08:02.000000', 4)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46964,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (379, 100497, 6, 3, '2022-10-14 12:08:02.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46965,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (379, 100497, 7, 3, '2022-10-14 12:08:02.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46966,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (379, 100497, 9, 3, '2022-10-14 12:08:02.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46967,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100438, 100497, 31, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46968,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100306, 100503, 2, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46969,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100306, 100503, 3, 'C')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46970,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100306, 100503, 1, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46971,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (100306, 100503, 4, 'C')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46972,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100306, 100503, 1, 3, '2022-10-14 12:33:01.000000', 3)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46973,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100306, 100503, 2, 3, '2022-10-14 12:33:01.000000', 2)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46974,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100306, 100503, 4, 3, '2022-10-14 12:33:01.000000', 1)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46975,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100306, 100503, 5, 3, '2022-10-14 12:33:01.000000', 4)",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46976,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100306, 100503, 6, 3, '2022-10-14 12:33:01.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46977,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100306, 100503, 7, 3, '2022-10-14 12:33:01.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46978,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (100306, 100503, 9, 3, '2022-10-14 12:33:01.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46979,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100439, 100503, 1051, 'S', ' ', 'N', 'N', 100, 60, 'N', '')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46980,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (375, 100501, 2, 'S')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46981,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (375, 100501, 3, 'C')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46982,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (375, 100501, 4, 'C')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46983,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (375, 100501, 6, 2, '2022-10-14 12:33:36.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46984,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado) VALUES (375, 100501, 7, 2, '2022-10-14 12:33:36.000000')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    46985,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (100440, 100501, 26, 'S', ' ', 'N', 'N', 120, 80, 'N', '')",
                    "2022-12-27 16:44:19",
                    1,
                    1,
                    1,
                    0,
                    0,
                    1681779052
                ],
                [
                    54777,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=535",
                    "2023-01-04 11:13:55",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54778,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-04', resultado='N' WHERE laboratorios_realizados.id_persona=438 AND laboratorios_realizados.id_control=755 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-04 11:13:55",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54779,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-04', resultado='N' WHERE laboratorios_realizados.id_persona=438 AND laboratorios_realizados.id_control=755 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-04 11:13:55",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54780,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (438, 755, 19, 2, '2023-01-04 10:55:56.000000', 1)",
                    "2023-01-04 11:13:55",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54781,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (438, 755, 20, 2, '2023-01-04 10:55:56.000000', 1)",
                    "2023-01-04 11:13:55",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54782,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (438, 755, 23, 2, '2023-01-04 10:55:56.000000', 1)",
                    "2023-01-04 11:13:55",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54783,
                    "INSERT INTO etmis_personas (id_persona, id_etmi, id_control) VALUES (438, 1, 755)",
                    "2023-01-04 11:13:55",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54784,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-04', resultado='P' WHERE laboratorios_realizados.id_persona=438 AND laboratorios_realizados.id_control=755 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-04 11:13:55",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54785,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-04', resultado='N' WHERE laboratorios_realizados.id_persona=438 AND laboratorios_realizados.id_control=755 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-04 11:13:55",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54786,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-04', resultado='0+' WHERE laboratorios_realizados.id_persona=438 AND laboratorios_realizados.id_control=755 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-04 11:13:55",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54787,
                    "DELETE FROM etmis_personas WHERE etmis_personas.id_persona=438 AND etmis_personas.id_etmi=1 AND etmis_personas.id_control=755",
                    "2023-01-04 11:13:55",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54788,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-04', resultado='P' WHERE laboratorios_realizados.id_persona=438 AND laboratorios_realizados.id_control=755 AND laboratorios_realizados.id_laboratorio=19 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-04 11:13:55",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54789,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-04', resultado='P' WHERE laboratorios_realizados.id_persona=438 AND laboratorios_realizados.id_control=755 AND laboratorios_realizados.id_laboratorio=20 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-04 11:13:55",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54790,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-04', resultado='P' WHERE laboratorios_realizados.id_persona=438 AND laboratorios_realizados.id_control=755 AND laboratorios_realizados.id_laboratorio=23 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-04 11:13:55",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54791,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (400262, '2023-01-04 10:59:31.000000', 100236, 2, 1)",
                    "2023-01-04 11:13:55",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54792,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=491",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54793,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-04', resultado='N' WHERE laboratorios_realizados.id_persona=416 AND laboratorios_realizados.id_control=705 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54794,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-04', resultado='N' WHERE laboratorios_realizados.id_persona=416 AND laboratorios_realizados.id_control=705 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54795,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-04', resultado='N' WHERE laboratorios_realizados.id_persona=416 AND laboratorios_realizados.id_control=705 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54796,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-04', resultado='N' WHERE laboratorios_realizados.id_persona=416 AND laboratorios_realizados.id_control=705 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54797,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-04', resultado='13' WHERE laboratorios_realizados.id_persona=416 AND laboratorios_realizados.id_control=705 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54798,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-04', resultado='112' WHERE laboratorios_realizados.id_persona=416 AND laboratorios_realizados.id_control=705 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54799,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-04', resultado='0+' WHERE laboratorios_realizados.id_persona=416 AND laboratorios_realizados.id_control=705 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54800,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=490",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54801,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-04', resultado='10' WHERE laboratorios_realizados.id_persona=393 AND laboratorios_realizados.id_control=706 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54802,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-04', resultado='95' WHERE laboratorios_realizados.id_persona=393 AND laboratorios_realizados.id_control=706 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54803,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=492",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54804,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=345 AND laboratorios_realizados.id_control=707 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54805,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=345 AND laboratorios_realizados.id_control=707 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54806,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='11' WHERE laboratorios_realizados.id_persona=345 AND laboratorios_realizados.id_control=707 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54807,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='77' WHERE laboratorios_realizados.id_persona=345 AND laboratorios_realizados.id_control=707 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54808,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=493",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54809,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='10' WHERE laboratorios_realizados.id_persona=386 AND laboratorios_realizados.id_control=708 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54810,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='154' WHERE laboratorios_realizados.id_persona=386 AND laboratorios_realizados.id_control=708 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54811,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=495",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54812,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='11' WHERE laboratorios_realizados.id_persona=392 AND laboratorios_realizados.id_control=710 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54813,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='88' WHERE laboratorios_realizados.id_persona=392 AND laboratorios_realizados.id_control=710 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54814,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='0+' WHERE laboratorios_realizados.id_persona=392 AND laboratorios_realizados.id_control=710 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54815,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=494",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54816,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=391 AND laboratorios_realizados.id_control=709 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54817,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=391 AND laboratorios_realizados.id_control=709 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54818,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=391 AND laboratorios_realizados.id_control=709 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54819,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='11' WHERE laboratorios_realizados.id_persona=391 AND laboratorios_realizados.id_control=709 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54820,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='75' WHERE laboratorios_realizados.id_persona=391 AND laboratorios_realizados.id_control=709 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54821,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='0+' WHERE laboratorios_realizados.id_persona=391 AND laboratorios_realizados.id_control=709 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54822,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=496",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54823,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=351 AND laboratorios_realizados.id_control=711 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54824,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=351 AND laboratorios_realizados.id_control=711 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54825,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=351 AND laboratorios_realizados.id_control=711 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54826,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='11' WHERE laboratorios_realizados.id_persona=351 AND laboratorios_realizados.id_control=711 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54827,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='108' WHERE laboratorios_realizados.id_persona=351 AND laboratorios_realizados.id_control=711 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54828,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='0+' WHERE laboratorios_realizados.id_persona=351 AND laboratorios_realizados.id_control=711 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54829,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=498",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54830,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=417 AND laboratorios_realizados.id_control=712 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54831,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=417 AND laboratorios_realizados.id_control=712 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54832,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=417 AND laboratorios_realizados.id_control=712 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54833,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=417 AND laboratorios_realizados.id_control=712 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54834,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='11' WHERE laboratorios_realizados.id_persona=417 AND laboratorios_realizados.id_control=712 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54835,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='90' WHERE laboratorios_realizados.id_persona=417 AND laboratorios_realizados.id_control=712 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54836,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='0+' WHERE laboratorios_realizados.id_persona=417 AND laboratorios_realizados.id_control=712 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54837,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=497",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54838,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=418 AND laboratorios_realizados.id_control=713 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54839,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=418 AND laboratorios_realizados.id_control=713 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54840,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=418 AND laboratorios_realizados.id_control=713 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54841,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=418 AND laboratorios_realizados.id_control=713 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54842,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='13' WHERE laboratorios_realizados.id_persona=418 AND laboratorios_realizados.id_control=713 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54843,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='98' WHERE laboratorios_realizados.id_persona=418 AND laboratorios_realizados.id_control=713 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54844,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='0+' WHERE laboratorios_realizados.id_persona=418 AND laboratorios_realizados.id_control=713 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54845,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=500",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54846,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=354 AND laboratorios_realizados.id_control=716 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54847,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=354 AND laboratorios_realizados.id_control=716 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54848,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=354 AND laboratorios_realizados.id_control=716 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54849,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='13' WHERE laboratorios_realizados.id_persona=354 AND laboratorios_realizados.id_control=716 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54850,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='92' WHERE laboratorios_realizados.id_persona=354 AND laboratorios_realizados.id_control=716 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54851,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=503",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54852,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=419 AND laboratorios_realizados.id_control=719 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54853,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=419 AND laboratorios_realizados.id_control=719 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54854,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=419 AND laboratorios_realizados.id_control=719 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54855,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=419 AND laboratorios_realizados.id_control=719 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54856,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='13' WHERE laboratorios_realizados.id_persona=419 AND laboratorios_realizados.id_control=719 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54857,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='111' WHERE laboratorios_realizados.id_persona=419 AND laboratorios_realizados.id_control=719 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54858,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='0+' WHERE laboratorios_realizados.id_persona=419 AND laboratorios_realizados.id_control=719 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54859,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=501",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54860,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=388 AND laboratorios_realizados.id_control=717 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54861,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='10' WHERE laboratorios_realizados.id_persona=388 AND laboratorios_realizados.id_control=717 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54862,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='115' WHERE laboratorios_realizados.id_persona=388 AND laboratorios_realizados.id_control=717 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54863,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=499",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54864,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=352 AND laboratorios_realizados.id_control=715 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54865,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=352 AND laboratorios_realizados.id_control=715 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54866,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=352 AND laboratorios_realizados.id_control=715 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54867,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='11' WHERE laboratorios_realizados.id_persona=352 AND laboratorios_realizados.id_control=715 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54868,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='107' WHERE laboratorios_realizados.id_persona=352 AND laboratorios_realizados.id_control=715 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54869,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=502",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54870,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=344 AND laboratorios_realizados.id_control=718 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54871,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=344 AND laboratorios_realizados.id_control=718 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54872,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=344 AND laboratorios_realizados.id_control=718 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54873,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='10' WHERE laboratorios_realizados.id_persona=344 AND laboratorios_realizados.id_control=718 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54874,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='85' WHERE laboratorios_realizados.id_persona=344 AND laboratorios_realizados.id_control=718 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54875,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=505",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54876,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=343 AND laboratorios_realizados.id_control=721 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54877,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='10' WHERE laboratorios_realizados.id_persona=343 AND laboratorios_realizados.id_control=721 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54878,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='101' WHERE laboratorios_realizados.id_persona=343 AND laboratorios_realizados.id_control=721 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54879,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=504",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54880,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=420 AND laboratorios_realizados.id_control=720 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54881,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=420 AND laboratorios_realizados.id_control=720 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54882,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=420 AND laboratorios_realizados.id_control=720 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54883,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=420 AND laboratorios_realizados.id_control=720 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54884,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='9' WHERE laboratorios_realizados.id_persona=420 AND laboratorios_realizados.id_control=720 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54885,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='81' WHERE laboratorios_realizados.id_persona=420 AND laboratorios_realizados.id_control=720 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54886,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='0+' WHERE laboratorios_realizados.id_persona=420 AND laboratorios_realizados.id_control=720 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54887,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=506",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54888,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=421 AND laboratorios_realizados.id_control=722 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54889,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=421 AND laboratorios_realizados.id_control=722 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54890,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=421 AND laboratorios_realizados.id_control=722 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54891,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=421 AND laboratorios_realizados.id_control=722 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54892,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='11' WHERE laboratorios_realizados.id_persona=421 AND laboratorios_realizados.id_control=722 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54893,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='92' WHERE laboratorios_realizados.id_persona=421 AND laboratorios_realizados.id_control=722 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54894,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='0+' WHERE laboratorios_realizados.id_persona=421 AND laboratorios_realizados.id_control=722 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54895,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=507",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54896,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=389 AND laboratorios_realizados.id_control=723 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54897,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='9' WHERE laboratorios_realizados.id_persona=389 AND laboratorios_realizados.id_control=723 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54898,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='81' WHERE laboratorios_realizados.id_persona=389 AND laboratorios_realizados.id_control=723 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54899,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='0+' WHERE laboratorios_realizados.id_persona=389 AND laboratorios_realizados.id_control=723 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54900,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=509",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54901,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=422 AND laboratorios_realizados.id_control=725 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54902,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=422 AND laboratorios_realizados.id_control=725 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54903,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=422 AND laboratorios_realizados.id_control=725 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54904,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=422 AND laboratorios_realizados.id_control=725 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54905,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='11' WHERE laboratorios_realizados.id_persona=422 AND laboratorios_realizados.id_control=725 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54906,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='96' WHERE laboratorios_realizados.id_persona=422 AND laboratorios_realizados.id_control=725 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54907,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='0+' WHERE laboratorios_realizados.id_persona=422 AND laboratorios_realizados.id_control=725 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54908,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=510",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54909,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='13' WHERE laboratorios_realizados.id_persona=398 AND laboratorios_realizados.id_control=726 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54910,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='89' WHERE laboratorios_realizados.id_persona=398 AND laboratorios_realizados.id_control=726 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54911,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=511",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54912,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=423 AND laboratorios_realizados.id_control=727 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54913,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=423 AND laboratorios_realizados.id_control=727 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54914,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=423 AND laboratorios_realizados.id_control=727 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54915,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=423 AND laboratorios_realizados.id_control=727 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54916,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='11' WHERE laboratorios_realizados.id_persona=423 AND laboratorios_realizados.id_control=727 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54917,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='102' WHERE laboratorios_realizados.id_persona=423 AND laboratorios_realizados.id_control=727 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54918,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='0+' WHERE laboratorios_realizados.id_persona=423 AND laboratorios_realizados.id_control=727 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54919,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=424 AND laboratorios_realizados.id_control=729 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54920,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='9' WHERE laboratorios_realizados.id_persona=424 AND laboratorios_realizados.id_control=729 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54921,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='97' WHERE laboratorios_realizados.id_persona=424 AND laboratorios_realizados.id_control=729 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54922,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=513",
                    "2023-01-07 11:06:11",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54923,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=100197 AND laboratorios_realizados.id_control=731 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54924,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='100' WHERE laboratorios_realizados.id_persona=100197 AND laboratorios_realizados.id_control=731 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54925,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=514",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54926,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=427 AND laboratorios_realizados.id_control=733 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54927,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=427 AND laboratorios_realizados.id_control=733 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54928,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=427 AND laboratorios_realizados.id_control=733 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54929,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=427 AND laboratorios_realizados.id_control=733 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54930,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='11' WHERE laboratorios_realizados.id_persona=427 AND laboratorios_realizados.id_control=733 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54931,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='113' WHERE laboratorios_realizados.id_persona=427 AND laboratorios_realizados.id_control=733 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54932,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='0+' WHERE laboratorios_realizados.id_persona=427 AND laboratorios_realizados.id_control=733 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54933,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=515",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54934,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=428 AND laboratorios_realizados.id_control=734 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54935,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=428 AND laboratorios_realizados.id_control=734 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54936,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=428 AND laboratorios_realizados.id_control=734 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54937,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=428 AND laboratorios_realizados.id_control=734 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54938,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='12' WHERE laboratorios_realizados.id_persona=428 AND laboratorios_realizados.id_control=734 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54939,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='117' WHERE laboratorios_realizados.id_persona=428 AND laboratorios_realizados.id_control=734 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54940,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='0+' WHERE laboratorios_realizados.id_persona=428 AND laboratorios_realizados.id_control=734 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54941,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=516",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54942,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=429 AND laboratorios_realizados.id_control=735 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54943,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=429 AND laboratorios_realizados.id_control=735 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54944,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='13' WHERE laboratorios_realizados.id_persona=429 AND laboratorios_realizados.id_control=735 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54945,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=100198 AND laboratorios_realizados.id_control=736 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54946,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='91' WHERE laboratorios_realizados.id_persona=100198 AND laboratorios_realizados.id_control=736 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54947,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=518",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54948,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=200056 AND laboratorios_realizados.id_control=737 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54949,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-05', resultado='N' WHERE laboratorios_realizados.id_persona=200056 AND laboratorios_realizados.id_control=737 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54950,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=520",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54951,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=430 AND laboratorios_realizados.id_control=739 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54952,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=430 AND laboratorios_realizados.id_control=739 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54953,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=430 AND laboratorios_realizados.id_control=739 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54954,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=430 AND laboratorios_realizados.id_control=739 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54955,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='9' WHERE laboratorios_realizados.id_persona=430 AND laboratorios_realizados.id_control=739 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54956,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='99' WHERE laboratorios_realizados.id_persona=430 AND laboratorios_realizados.id_control=739 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54957,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='0+' WHERE laboratorios_realizados.id_persona=430 AND laboratorios_realizados.id_control=739 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54958,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=521",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54959,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=431 AND laboratorios_realizados.id_control=740 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54960,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=431 AND laboratorios_realizados.id_control=740 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54961,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=522",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54962,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100192 AND laboratorios_realizados.id_control=741 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54963,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100192 AND laboratorios_realizados.id_control=741 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54964,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='100' WHERE laboratorios_realizados.id_persona=100192 AND laboratorios_realizados.id_control=741 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54965,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=523",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54966,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100190 AND laboratorios_realizados.id_control=742 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54967,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100190 AND laboratorios_realizados.id_control=742 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54968,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=524",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54969,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=432 AND laboratorios_realizados.id_control=743 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54970,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=432 AND laboratorios_realizados.id_control=743 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54971,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=432 AND laboratorios_realizados.id_control=743 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54972,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=432 AND laboratorios_realizados.id_control=743 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54973,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='13' WHERE laboratorios_realizados.id_persona=432 AND laboratorios_realizados.id_control=743 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54974,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='89' WHERE laboratorios_realizados.id_persona=432 AND laboratorios_realizados.id_control=743 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54975,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='0+' WHERE laboratorios_realizados.id_persona=432 AND laboratorios_realizados.id_control=743 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54976,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=433 AND laboratorios_realizados.id_control=745 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54977,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=433 AND laboratorios_realizados.id_control=745 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54978,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=433 AND laboratorios_realizados.id_control=745 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54979,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=433 AND laboratorios_realizados.id_control=745 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54980,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='12' WHERE laboratorios_realizados.id_persona=433 AND laboratorios_realizados.id_control=745 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54981,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='87' WHERE laboratorios_realizados.id_persona=433 AND laboratorios_realizados.id_control=745 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54982,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='0+' WHERE laboratorios_realizados.id_persona=433 AND laboratorios_realizados.id_control=745 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54983,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=525",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54984,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100186 AND laboratorios_realizados.id_control=744 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54985,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100186 AND laboratorios_realizados.id_control=744 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54986,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=434 AND laboratorios_realizados.id_control=746 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54987,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=434 AND laboratorios_realizados.id_control=746 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54988,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=434 AND laboratorios_realizados.id_control=746 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54989,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=434 AND laboratorios_realizados.id_control=746 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54990,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='11' WHERE laboratorios_realizados.id_persona=434 AND laboratorios_realizados.id_control=746 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54991,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='114' WHERE laboratorios_realizados.id_persona=434 AND laboratorios_realizados.id_control=746 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54992,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='0+' WHERE laboratorios_realizados.id_persona=434 AND laboratorios_realizados.id_control=746 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54993,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=530",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54994,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='13' WHERE laboratorios_realizados.id_persona=100225 AND laboratorios_realizados.id_control=749 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54995,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=546",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54996,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=436 AND laboratorios_realizados.id_control=751 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54997,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=436 AND laboratorios_realizados.id_control=751 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54998,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=436 AND laboratorios_realizados.id_control=751 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    54999,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=436 AND laboratorios_realizados.id_control=751 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55000,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='13' WHERE laboratorios_realizados.id_persona=436 AND laboratorios_realizados.id_control=751 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55001,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='89' WHERE laboratorios_realizados.id_persona=436 AND laboratorios_realizados.id_control=751 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55002,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='0+' WHERE laboratorios_realizados.id_persona=436 AND laboratorios_realizados.id_control=751 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55003,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=533",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55004,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='11' WHERE laboratorios_realizados.id_persona=100223 AND laboratorios_realizados.id_control=753 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55005,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='118' WHERE laboratorios_realizados.id_persona=100223 AND laboratorios_realizados.id_control=753 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55006,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=534",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55007,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=437 AND laboratorios_realizados.id_control=754 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55008,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=437 AND laboratorios_realizados.id_control=754 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55009,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=437 AND laboratorios_realizados.id_control=754 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55010,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=437 AND laboratorios_realizados.id_control=754 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55011,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='13' WHERE laboratorios_realizados.id_persona=437 AND laboratorios_realizados.id_control=754 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55012,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='83' WHERE laboratorios_realizados.id_persona=437 AND laboratorios_realizados.id_control=754 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55013,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='0+' WHERE laboratorios_realizados.id_persona=437 AND laboratorios_realizados.id_control=754 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55014,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='12' WHERE laboratorios_realizados.id_persona=438 AND laboratorios_realizados.id_control=755 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55015,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='117' WHERE laboratorios_realizados.id_persona=438 AND laboratorios_realizados.id_control=755 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55016,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=536",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55017,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=439 AND laboratorios_realizados.id_control=756 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55018,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=439 AND laboratorios_realizados.id_control=756 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55019,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=439 AND laboratorios_realizados.id_control=756 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55020,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=439 AND laboratorios_realizados.id_control=756 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55021,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='13' WHERE laboratorios_realizados.id_persona=439 AND laboratorios_realizados.id_control=756 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55022,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='98' WHERE laboratorios_realizados.id_persona=439 AND laboratorios_realizados.id_control=756 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55023,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='A+' WHERE laboratorios_realizados.id_persona=439 AND laboratorios_realizados.id_control=756 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55024,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=537",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55025,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=440 AND laboratorios_realizados.id_control=757 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55026,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='13' WHERE laboratorios_realizados.id_persona=440 AND laboratorios_realizados.id_control=757 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55027,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='90' WHERE laboratorios_realizados.id_persona=440 AND laboratorios_realizados.id_control=757 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55028,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=538",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55029,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=441 AND laboratorios_realizados.id_control=758 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55030,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=441 AND laboratorios_realizados.id_control=758 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55031,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=441 AND laboratorios_realizados.id_control=758 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55032,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=441 AND laboratorios_realizados.id_control=758 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55033,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='13' WHERE laboratorios_realizados.id_persona=441 AND laboratorios_realizados.id_control=758 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55034,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='97' WHERE laboratorios_realizados.id_persona=441 AND laboratorios_realizados.id_control=758 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55035,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='0+' WHERE laboratorios_realizados.id_persona=441 AND laboratorios_realizados.id_control=758 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55036,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=539",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55037,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=442 AND laboratorios_realizados.id_control=759 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55038,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='10' WHERE laboratorios_realizados.id_persona=442 AND laboratorios_realizados.id_control=759 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55039,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='108' WHERE laboratorios_realizados.id_persona=442 AND laboratorios_realizados.id_control=759 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55040,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=542",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55041,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100210 AND laboratorios_realizados.id_control=763 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55042,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100210 AND laboratorios_realizados.id_control=763 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55043,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='13' WHERE laboratorios_realizados.id_persona=100210 AND laboratorios_realizados.id_control=763 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55044,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='98' WHERE laboratorios_realizados.id_persona=100210 AND laboratorios_realizados.id_control=763 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55045,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=547",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55046,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=445 AND laboratorios_realizados.id_control=764 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55047,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=445 AND laboratorios_realizados.id_control=764 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55048,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=445 AND laboratorios_realizados.id_control=764 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55049,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=445 AND laboratorios_realizados.id_control=764 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55050,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='13' WHERE laboratorios_realizados.id_persona=445 AND laboratorios_realizados.id_control=764 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55051,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='104' WHERE laboratorios_realizados.id_persona=445 AND laboratorios_realizados.id_control=764 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55052,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='0+' WHERE laboratorios_realizados.id_persona=445 AND laboratorios_realizados.id_control=764 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55053,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=544",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55054,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=446 AND laboratorios_realizados.id_control=766 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55055,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=446 AND laboratorios_realizados.id_control=766 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55056,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=446 AND laboratorios_realizados.id_control=766 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55057,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=446 AND laboratorios_realizados.id_control=766 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55058,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='13' WHERE laboratorios_realizados.id_persona=446 AND laboratorios_realizados.id_control=766 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55059,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='103' WHERE laboratorios_realizados.id_persona=446 AND laboratorios_realizados.id_control=766 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55060,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='0+' WHERE laboratorios_realizados.id_persona=446 AND laboratorios_realizados.id_control=766 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55061,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=548",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55062,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=448 AND laboratorios_realizados.id_control=768 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55063,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=448 AND laboratorios_realizados.id_control=768 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55064,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=448 AND laboratorios_realizados.id_control=768 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55065,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='12' WHERE laboratorios_realizados.id_persona=448 AND laboratorios_realizados.id_control=768 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55066,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='79' WHERE laboratorios_realizados.id_persona=448 AND laboratorios_realizados.id_control=768 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55067,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='11' WHERE laboratorios_realizados.id_persona=366 AND laboratorios_realizados.id_control=769 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55068,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='91' WHERE laboratorios_realizados.id_persona=366 AND laboratorios_realizados.id_control=769 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55069,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=449 AND laboratorios_realizados.id_control=771 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55070,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=449 AND laboratorios_realizados.id_control=771 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55071,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=552",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55072,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=450 AND laboratorios_realizados.id_control=772 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55073,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=450 AND laboratorios_realizados.id_control=772 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55074,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=450 AND laboratorios_realizados.id_control=772 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55075,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=450 AND laboratorios_realizados.id_control=772 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55076,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='12' WHERE laboratorios_realizados.id_persona=450 AND laboratorios_realizados.id_control=772 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55077,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='103' WHERE laboratorios_realizados.id_persona=450 AND laboratorios_realizados.id_control=772 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55078,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='0+' WHERE laboratorios_realizados.id_persona=450 AND laboratorios_realizados.id_control=772 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55079,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100434",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55080,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='10' WHERE laboratorios_realizados.id_persona=100260 AND laboratorios_realizados.id_control=100495 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55081,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='73' WHERE laboratorios_realizados.id_persona=100260 AND laboratorios_realizados.id_control=100495 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55082,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100432",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55083,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100259 AND laboratorios_realizados.id_control=100496 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55084,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100259 AND laboratorios_realizados.id_control=100496 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55085,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100259 AND laboratorios_realizados.id_control=100496 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55086,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100259 AND laboratorios_realizados.id_control=100496 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55087,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='12' WHERE laboratorios_realizados.id_persona=100259 AND laboratorios_realizados.id_control=100496 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55088,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='77' WHERE laboratorios_realizados.id_persona=100259 AND laboratorios_realizados.id_control=100496 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55089,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='0+' WHERE laboratorios_realizados.id_persona=100259 AND laboratorios_realizados.id_control=100496 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55090,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100438",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55091,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=379 AND laboratorios_realizados.id_control=100497 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55092,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=379 AND laboratorios_realizados.id_control=100497 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55093,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=379 AND laboratorios_realizados.id_control=100497 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55094,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=379 AND laboratorios_realizados.id_control=100497 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55095,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='10' WHERE laboratorios_realizados.id_persona=379 AND laboratorios_realizados.id_control=100497 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55096,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='105' WHERE laboratorios_realizados.id_persona=379 AND laboratorios_realizados.id_control=100497 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55097,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='0+' WHERE laboratorios_realizados.id_persona=379 AND laboratorios_realizados.id_control=100497 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55098,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100433",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55099,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=380 AND laboratorios_realizados.id_control=100498 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55100,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=380 AND laboratorios_realizados.id_control=100498 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55101,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=380 AND laboratorios_realizados.id_control=100498 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55102,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=380 AND laboratorios_realizados.id_control=100498 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55103,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='11' WHERE laboratorios_realizados.id_persona=380 AND laboratorios_realizados.id_control=100498 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55104,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='91' WHERE laboratorios_realizados.id_persona=380 AND laboratorios_realizados.id_control=100498 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55105,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100435",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55106,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100305 AND laboratorios_realizados.id_control=100499 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55107,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='10' WHERE laboratorios_realizados.id_persona=100305 AND laboratorios_realizados.id_control=100499 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55108,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='89' WHERE laboratorios_realizados.id_persona=100305 AND laboratorios_realizados.id_control=100499 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55109,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100437",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55110,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=409 AND laboratorios_realizados.id_control=100500 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55111,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=409 AND laboratorios_realizados.id_control=100500 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55112,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=409 AND laboratorios_realizados.id_control=100500 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55113,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=409 AND laboratorios_realizados.id_control=100500 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55114,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='11' WHERE laboratorios_realizados.id_persona=409 AND laboratorios_realizados.id_control=100500 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55115,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='94' WHERE laboratorios_realizados.id_persona=409 AND laboratorios_realizados.id_control=100500 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55116,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100440",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55117,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='11' WHERE laboratorios_realizados.id_persona=375 AND laboratorios_realizados.id_control=100501 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55118,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='101' WHERE laboratorios_realizados.id_persona=375 AND laboratorios_realizados.id_control=100501 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55119,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='11' WHERE laboratorios_realizados.id_persona=100258 AND laboratorios_realizados.id_control=100502 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55120,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='113' WHERE laboratorios_realizados.id_persona=100258 AND laboratorios_realizados.id_control=100502 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55121,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100439",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55122,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100306 AND laboratorios_realizados.id_control=100503 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55123,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100306 AND laboratorios_realizados.id_control=100503 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55124,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100306 AND laboratorios_realizados.id_control=100503 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55125,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100306 AND laboratorios_realizados.id_control=100503 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55126,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='10' WHERE laboratorios_realizados.id_persona=100306 AND laboratorios_realizados.id_control=100503 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55127,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='83' WHERE laboratorios_realizados.id_persona=100306 AND laboratorios_realizados.id_control=100503 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55128,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='0+' WHERE laboratorios_realizados.id_persona=100306 AND laboratorios_realizados.id_control=100503 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55129,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100387",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55130,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100283 AND laboratorios_realizados.id_control=100449 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55131,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100283 AND laboratorios_realizados.id_control=100449 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55132,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100283 AND laboratorios_realizados.id_control=100449 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55133,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100283 AND laboratorios_realizados.id_control=100449 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55134,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='12' WHERE laboratorios_realizados.id_persona=100283 AND laboratorios_realizados.id_control=100449 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55135,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='98' WHERE laboratorios_realizados.id_persona=100283 AND laboratorios_realizados.id_control=100449 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55136,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='0+' WHERE laboratorios_realizados.id_persona=100283 AND laboratorios_realizados.id_control=100449 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55137,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100389",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55138,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100284 AND laboratorios_realizados.id_control=100451 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55139,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100284 AND laboratorios_realizados.id_control=100451 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55140,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100284 AND laboratorios_realizados.id_control=100451 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55141,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100284 AND laboratorios_realizados.id_control=100451 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55142,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='12' WHERE laboratorios_realizados.id_persona=100284 AND laboratorios_realizados.id_control=100451 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55143,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='97' WHERE laboratorios_realizados.id_persona=100284 AND laboratorios_realizados.id_control=100451 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55144,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='0+' WHERE laboratorios_realizados.id_persona=100284 AND laboratorios_realizados.id_control=100451 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55145,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100401",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55146,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100285 AND laboratorios_realizados.id_control=100453 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55147,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100285 AND laboratorios_realizados.id_control=100453 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55148,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100285 AND laboratorios_realizados.id_control=100453 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55149,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100285 AND laboratorios_realizados.id_control=100453 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55150,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='-' WHERE laboratorios_realizados.id_persona=100285 AND laboratorios_realizados.id_control=100453 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55151,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='88' WHERE laboratorios_realizados.id_persona=100285 AND laboratorios_realizados.id_control=100453 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55152,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='0+' WHERE laboratorios_realizados.id_persona=100285 AND laboratorios_realizados.id_control=100453 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55153,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100391",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55154,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100286 AND laboratorios_realizados.id_control=100454 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55155,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100286 AND laboratorios_realizados.id_control=100454 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55156,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100286, 100454, 19, 1, '2023-01-06 18:54:15.000000', 1)",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55157,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100286, 100454, 20, 1, '2023-01-06 18:54:15.000000', 1)",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55158,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100286, 100454, 23, 1, '2023-01-06 18:54:15.000000', 1)",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55159,
                    "INSERT INTO etmis_personas (id_persona, id_etmi, id_control) VALUES (100286, 1, 100454)",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55160,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='P' WHERE laboratorios_realizados.id_persona=100286 AND laboratorios_realizados.id_control=100454 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55161,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100286 AND laboratorios_realizados.id_control=100454 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55162,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='13' WHERE laboratorios_realizados.id_persona=100286 AND laboratorios_realizados.id_control=100454 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55163,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='100' WHERE laboratorios_realizados.id_persona=100286 AND laboratorios_realizados.id_control=100454 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55164,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='0+' WHERE laboratorios_realizados.id_persona=100286 AND laboratorios_realizados.id_control=100454 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55165,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100392",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55166,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100287 AND laboratorios_realizados.id_control=100455 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55167,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100287 AND laboratorios_realizados.id_control=100455 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55168,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100287 AND laboratorios_realizados.id_control=100455 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55169,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100287 AND laboratorios_realizados.id_control=100455 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55170,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='13' WHERE laboratorios_realizados.id_persona=100287 AND laboratorios_realizados.id_control=100455 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55171,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='87' WHERE laboratorios_realizados.id_persona=100287 AND laboratorios_realizados.id_control=100455 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55172,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='0+' WHERE laboratorios_realizados.id_persona=100287 AND laboratorios_realizados.id_control=100455 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55173,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100393",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55174,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100288 AND laboratorios_realizados.id_control=100456 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55175,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100288 AND laboratorios_realizados.id_control=100456 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55176,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100288 AND laboratorios_realizados.id_control=100456 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55177,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100288 AND laboratorios_realizados.id_control=100456 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55178,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='12' WHERE laboratorios_realizados.id_persona=100288 AND laboratorios_realizados.id_control=100456 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55179,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='88' WHERE laboratorios_realizados.id_persona=100288 AND laboratorios_realizados.id_control=100456 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55180,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='0+' WHERE laboratorios_realizados.id_persona=100288 AND laboratorios_realizados.id_control=100456 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55181,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100394",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55182,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100289 AND laboratorios_realizados.id_control=100457 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55183,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100289 AND laboratorios_realizados.id_control=100457 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55184,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='-' WHERE laboratorios_realizados.id_persona=100289 AND laboratorios_realizados.id_control=100457 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55185,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='89' WHERE laboratorios_realizados.id_persona=100289 AND laboratorios_realizados.id_control=100457 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55186,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100396",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55187,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100290 AND laboratorios_realizados.id_control=100459 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55188,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100290 AND laboratorios_realizados.id_control=100459 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55189,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100290 AND laboratorios_realizados.id_control=100459 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55190,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100290 AND laboratorios_realizados.id_control=100459 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55191,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='14' WHERE laboratorios_realizados.id_persona=100290 AND laboratorios_realizados.id_control=100459 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55192,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='104' WHERE laboratorios_realizados.id_persona=100290 AND laboratorios_realizados.id_control=100459 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55193,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='0+' WHERE laboratorios_realizados.id_persona=100290 AND laboratorios_realizados.id_control=100459 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55194,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100397",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55195,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100291 AND laboratorios_realizados.id_control=100460 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55196,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100291 AND laboratorios_realizados.id_control=100460 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55197,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100291 AND laboratorios_realizados.id_control=100460 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55198,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100291 AND laboratorios_realizados.id_control=100460 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55199,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='13' WHERE laboratorios_realizados.id_persona=100291 AND laboratorios_realizados.id_control=100460 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55200,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='105' WHERE laboratorios_realizados.id_persona=100291 AND laboratorios_realizados.id_control=100460 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55201,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='0+' WHERE laboratorios_realizados.id_persona=100291 AND laboratorios_realizados.id_control=100460 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55202,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100399",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55203,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100292 AND laboratorios_realizados.id_control=100462 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55204,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100292 AND laboratorios_realizados.id_control=100462 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55205,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100292 AND laboratorios_realizados.id_control=100462 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55206,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100292, 100462, 22, 2, '2023-01-06 19:07:03.000000', 4)",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55207,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100292, 100462, 15, 2, '2023-01-06 19:07:03.000000', 4)",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55208,
                    "INSERT INTO etmis_personas (id_persona, id_etmi, id_control) VALUES (100292, 4, 100462)",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55209,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='P' WHERE laboratorios_realizados.id_persona=100292 AND laboratorios_realizados.id_control=100462 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55210,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='13' WHERE laboratorios_realizados.id_persona=100292 AND laboratorios_realizados.id_control=100462 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55211,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='83' WHERE laboratorios_realizados.id_persona=100292 AND laboratorios_realizados.id_control=100462 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55212,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='0+' WHERE laboratorios_realizados.id_persona=100292 AND laboratorios_realizados.id_control=100462 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55213,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100400",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55214,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100293 AND laboratorios_realizados.id_control=100463 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55215,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100293 AND laboratorios_realizados.id_control=100463 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55216,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100293 AND laboratorios_realizados.id_control=100463 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55217,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100293 AND laboratorios_realizados.id_control=100463 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55218,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='11' WHERE laboratorios_realizados.id_persona=100293 AND laboratorios_realizados.id_control=100463 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55219,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='97' WHERE laboratorios_realizados.id_persona=100293 AND laboratorios_realizados.id_control=100463 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55220,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='0+' WHERE laboratorios_realizados.id_persona=100293 AND laboratorios_realizados.id_control=100463 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55221,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100294 AND laboratorios_realizados.id_control=100465 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55222,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100294 AND laboratorios_realizados.id_control=100465 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55223,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100294 AND laboratorios_realizados.id_control=100465 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55224,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100294 AND laboratorios_realizados.id_control=100465 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55225,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='13' WHERE laboratorios_realizados.id_persona=100294 AND laboratorios_realizados.id_control=100465 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55226,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='96' WHERE laboratorios_realizados.id_persona=100294 AND laboratorios_realizados.id_control=100465 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55227,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='0+' WHERE laboratorios_realizados.id_persona=100294 AND laboratorios_realizados.id_control=100465 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55228,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100295 AND laboratorios_realizados.id_control=100467 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55229,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100295 AND laboratorios_realizados.id_control=100467 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55230,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100295 AND laboratorios_realizados.id_control=100467 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55231,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100295 AND laboratorios_realizados.id_control=100467 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55232,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='12' WHERE laboratorios_realizados.id_persona=100295 AND laboratorios_realizados.id_control=100467 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55233,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='100' WHERE laboratorios_realizados.id_persona=100295 AND laboratorios_realizados.id_control=100467 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55234,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='0+' WHERE laboratorios_realizados.id_persona=100295 AND laboratorios_realizados.id_control=100467 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55235,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100296 AND laboratorios_realizados.id_control=100468 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55236,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=100296 AND laboratorios_realizados.id_control=100468 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55237,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100407",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55238,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=364 AND laboratorios_realizados.id_control=100469 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55239,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-06', resultado='N' WHERE laboratorios_realizados.id_persona=364 AND laboratorios_realizados.id_control=100469 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:06:12",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55240,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100408",
                    "2023-01-07 11:23:24",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55241,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-07', resultado='N' WHERE laboratorios_realizados.id_persona=100297 AND laboratorios_realizados.id_control=100470 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:23:24",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55242,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-07', resultado='N' WHERE laboratorios_realizados.id_persona=100297 AND laboratorios_realizados.id_control=100470 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:23:24",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55243,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-07', resultado='N' WHERE laboratorios_realizados.id_persona=100297 AND laboratorios_realizados.id_control=100470 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:23:24",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55244,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-07', resultado='N' WHERE laboratorios_realizados.id_persona=100297 AND laboratorios_realizados.id_control=100470 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:23:24",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55245,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-07', resultado='11' WHERE laboratorios_realizados.id_persona=100297 AND laboratorios_realizados.id_control=100470 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:23:24",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55246,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-07', resultado='92' WHERE laboratorios_realizados.id_persona=100297 AND laboratorios_realizados.id_control=100470 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:23:24",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55247,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-07', resultado='0+' WHERE laboratorios_realizados.id_persona=100297 AND laboratorios_realizados.id_control=100470 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:23:24",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55248,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=100409",
                    "2023-01-07 11:23:24",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55249,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-07', resultado='N' WHERE laboratorios_realizados.id_persona=100222 AND laboratorios_realizados.id_control=100471 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:23:24",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55250,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-07', resultado='N' WHERE laboratorios_realizados.id_persona=100222 AND laboratorios_realizados.id_control=100471 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:23:24",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55251,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-07', resultado='N' WHERE laboratorios_realizados.id_persona=100215 AND laboratorios_realizados.id_control=100472 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:23:24",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55252,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-07', resultado='N' WHERE laboratorios_realizados.id_persona=100215 AND laboratorios_realizados.id_control=100472 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:23:24",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55253,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100216, 100474, 13, 2, '2023-01-07 11:13:39.000000', 3)",
                    "2023-01-07 11:23:24",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55254,
                    "INSERT INTO laboratorios_realizados (id_persona, id_control, id_laboratorio, trimestre, fecha_realizado, id_etmi) VALUES (100216, 100474, 18, 2, '2023-01-07 11:13:39.000000', 3)",
                    "2023-01-07 11:23:24",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55255,
                    "INSERT INTO etmis_personas (id_persona, id_etmi, id_control) VALUES (100216, 3, 100474)",
                    "2023-01-07 11:23:24",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55256,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-07', resultado='P' WHERE laboratorios_realizados.id_persona=100216 AND laboratorios_realizados.id_control=100474 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:23:24",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55257,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-07', resultado='N' WHERE laboratorios_realizados.id_persona=100216 AND laboratorios_realizados.id_control=100474 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:23:24",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55258,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-07', resultado='N' WHERE laboratorios_realizados.id_persona=100298 AND laboratorios_realizados.id_control=100475 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:23:24",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55259,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-07', resultado='N' WHERE laboratorios_realizados.id_persona=100298 AND laboratorios_realizados.id_control=100475 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:23:24",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55260,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-07', resultado='N' WHERE laboratorios_realizados.id_persona=100298 AND laboratorios_realizados.id_control=100475 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:23:24",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55261,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-07', resultado='N' WHERE laboratorios_realizados.id_persona=100298 AND laboratorios_realizados.id_control=100475 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:23:24",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55262,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-07', resultado='98' WHERE laboratorios_realizados.id_persona=100298 AND laboratorios_realizados.id_control=100475 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:23:24",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55263,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-07', resultado='0+' WHERE laboratorios_realizados.id_persona=100298 AND laboratorios_realizados.id_control=100475 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:23:24",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55264,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-07', resultado='N' WHERE laboratorios_realizados.id_persona=100299 AND laboratorios_realizados.id_control=100476 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:23:24",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55265,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-07', resultado='N' WHERE laboratorios_realizados.id_persona=100299 AND laboratorios_realizados.id_control=100476 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:23:24",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55266,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-07', resultado='N' WHERE laboratorios_realizados.id_persona=100299 AND laboratorios_realizados.id_control=100476 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:23:24",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55267,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-07', resultado='N' WHERE laboratorios_realizados.id_persona=100299 AND laboratorios_realizados.id_control=100476 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:23:24",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55268,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-07', resultado='108' WHERE laboratorios_realizados.id_persona=100299 AND laboratorios_realizados.id_control=100476 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:23:24",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55269,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-07', resultado='0+' WHERE laboratorios_realizados.id_persona=100299 AND laboratorios_realizados.id_control=100476 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:23:24",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55270,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-07', resultado='11' WHERE laboratorios_realizados.id_persona=451 AND laboratorios_realizados.id_control=775 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:42:19",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55271,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-07', resultado='N' WHERE laboratorios_realizados.id_persona=100301 AND laboratorios_realizados.id_control=100489 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:42:19",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55272,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-07', resultado='N' WHERE laboratorios_realizados.id_persona=100301 AND laboratorios_realizados.id_control=100489 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:42:19",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55273,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-07', resultado='N' WHERE laboratorios_realizados.id_persona=100301 AND laboratorios_realizados.id_control=100489 AND laboratorios_realizados.id_laboratorio=4 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=1",
                    "2023-01-07 11:42:19",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55274,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-07', resultado='N' WHERE laboratorios_realizados.id_persona=100301 AND laboratorios_realizados.id_control=100489 AND laboratorios_realizados.id_laboratorio=5 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=4",
                    "2023-01-07 11:42:19",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55275,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-07', resultado='12' WHERE laboratorios_realizados.id_persona=100301 AND laboratorios_realizados.id_control=100489 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=2 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:42:19",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55276,
                    "UPDATE control_embarazo SET eco='R' WHERE control_embarazo.id_control_embarazo=558",
                    "2023-01-07 11:42:19",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55277,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-07', resultado='N' WHERE laboratorios_realizados.id_persona=281 AND laboratorios_realizados.id_control=779 AND laboratorios_realizados.id_laboratorio=1 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=3",
                    "2023-01-07 11:42:19",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55278,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-07', resultado='N' WHERE laboratorios_realizados.id_persona=281 AND laboratorios_realizados.id_control=779 AND laboratorios_realizados.id_laboratorio=2 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=2",
                    "2023-01-07 11:42:19",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55279,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-07', resultado='133' WHERE laboratorios_realizados.id_persona=456 AND laboratorios_realizados.id_control=783 AND laboratorios_realizados.id_laboratorio=7 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:42:19",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55280,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-07', resultado='0+' WHERE laboratorios_realizados.id_persona=456 AND laboratorios_realizados.id_control=783 AND laboratorios_realizados.id_laboratorio=9 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:42:19",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55281,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-07', resultado='12' WHERE laboratorios_realizados.id_persona=460 AND laboratorios_realizados.id_control=787 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=3 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:42:19",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55282,
                    "UPDATE laboratorios_realizados SET fecha_resultados='2023-01-07', resultado='11' WHERE laboratorios_realizados.id_persona=463 AND laboratorios_realizados.id_control=790 AND laboratorios_realizados.id_laboratorio=6 AND laboratorios_realizados.trimestre=1 AND laboratorios_realizados.id_etmi=0",
                    "2023-01-07 11:42:19",
                    1,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55283,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (400088, 'IGLESIAS', 'GUSTAVA', '22666999', '2006-02-01 00:00:00.000000', 1, 12, 'F')",
                    "2023-03-02 11:17:31",
                    0,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55284,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, georeferencia) VALUES (400263, '2023-01-25 08:38:24.000000', 400088, 1, 1, '-34.2428212,-59.4742354')",
                    "2023-03-02 11:17:31",
                    0,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55285,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (400088, 400088, 24, 3, '12', '2023-01-25 08:38:24.000000', '-34.2428212,-59.4742354', 12)",
                    "2023-03-02 11:17:31",
                    0,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55286,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, fecha_ultimo_embarazo, gestas, planificado, fum, fpp) VALUES (400007, 400088, 400263, 23, '2022-04-27 00:00:00.000000', 1, 1, '2023-01-04 00:00:00.000000', '2023-10-11 00:00:00.000000')",
                    "2023-03-02 11:17:31",
                    0,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55287,
                    "INSERT INTO antecedentes_apps (id_antecedente, id_app) VALUES (400007, 2)",
                    "2023-03-02 11:17:31",
                    0,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55288,
                    "INSERT INTO antecedentes_macs (id_antecedente, id_mac) VALUES (400007, 1)",
                    "2023-03-02 11:17:31",
                    0,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55289,
                    "INSERT INTO personas (id_persona, apellido, nombre, documento, fecha_nacimiento, id_origen, nacionalidad, sexo) VALUES (400089, 'MOLINA', 'JUANA', '44555888', '2000-01-01 00:00:00.000000', 2, 12, 'F')",
                    "2023-03-02 11:17:31",
                    0,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55290,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado, georeferencia) VALUES (400264, '2023-01-31 18:24:11.000000', 400089, 1, 1, '-34.439168,-59.4444288')",
                    "2023-03-02 11:17:31",
                    0,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55291,
                    "INSERT INTO ubicaciones (id_ubicacion, id_persona, id_paraje, id_area, num_vivienda, fecha, georeferencia, id_pais) VALUES (400089, 400089, 25, 3, '00', '2023-01-31 18:24:11.000000', '-34.439168,-59.4444288', 12)",
                    "2023-03-02 11:17:31",
                    0,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55292,
                    "INSERT INTO antecedentes (id_antecedente, id_persona, id_control, edad_primer_embarazo, gestas, planificado, fum, fpp) VALUES (400008, 400089, 400264, 0, 1, 0, '2022-12-05 00:00:00.000000', '2023-09-11 00:00:00.000000')",
                    "2023-03-02 11:17:31",
                    0,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55293,
                    "INSERT INTO antecedentes_apps (id_antecedente, id_app) VALUES (400008, 7)",
                    "2023-03-02 11:17:31",
                    0,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55294,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (400089, 400264, 2, 'N')",
                    "2023-03-02 11:17:31",
                    0,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55295,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (400089, 400264, 3, 'N')",
                    "2023-03-02 11:17:31",
                    0,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55296,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (400089, 400264, 1, 'N')",
                    "2023-03-02 11:17:31",
                    0,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55297,
                    "INSERT INTO inmunizaciones_control (id_persona, id_control, id_inmunizacion, estado) VALUES (400089, 400264, 4, 'N')",
                    "2023-03-02 11:17:31",
                    0,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55298,
                    "INSERT INTO control_embarazo (id_control_embarazo, id_control, edad_gestacional, eco, detalle_eco, hpv, pap, sistolica, diastolica, clinico, observaciones) VALUES (400071, 400264, 8, 'P', 'PRUEBA', 'N', 'N', 130, 60, 'N', '')",
                    "2023-03-02 11:17:31",
                    0,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55299,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (400265, '2023-01-31 18:36:29.000000', 400088, 2, 1)",
                    "2023-03-02 11:17:31",
                    0,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55300,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (400266, '2023-02-01 15:12:51.000000', 4, 4, 2)",
                    "2023-03-02 11:17:31",
                    0,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55301,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (400267, '2023-02-01 15:12:55.000000', 4, 5, 2)",
                    "2023-03-02 11:17:31",
                    0,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55302,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (400268, '2023-02-01 20:30:28.000000', 18, 2, 1)",
                    "2023-03-02 11:17:31",
                    0,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55303,
                    "INSERT INTO controles (id_control, fecha, id_persona, control_numero, id_estado) VALUES (400269, '2023-02-02 09:19:13.000000', 18, 3, 1)",
                    "2023-03-02 11:17:31",
                    0,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ],
                [
                    55304,
                    "UPDATE antecedentes SET fpp='2021-08-22' WHERE antecedentes.id_antecedente=11",
                    "2023-03-02 11:17:31",
                    0,
                    0,
                    0,
                    0,
                    0,
                    1681779052
                ]
            ]
        },
        {
            "name": "embarazos",
            "schema": [
                {
                    "column": "id_persona",
                    "value": "INT  NOT NULL"
                },
                {
                    "column": "id_control",
                    "value": "INT  NOT NULL"
                },
                {
                    "column": "id_tipo_embarazo",
                    "value": "INT  NOT NULL"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                },
                {
                    "constraint": "CPK_id_persona_id_control_id_tipo_embarazo",
                    "value": "PRIMARY KEY (id_persona,id_control,id_tipo_embarazo)"
                },
                {
                    "constraint": "`fk_embarazos_controles`",
                    "value": "FOREIGN KEY (`id_control`) REFERENCES controles (`id_control`) ON DELETE NO ACTION ON UPDATE NO ACTION"
                },
                {
                    "constraint": "`fk_embarazos_personas`",
                    "value": "FOREIGN KEY (`id_persona`) REFERENCES personas (`id_persona`) ON DELETE NO ACTION ON UPDATE NO ACTION"
                },
                {
                    "constraint": "`fk_embarazos_tipo_embarazo`",
                    "value": "FOREIGN KEY (`id_tipo_embarazo`) REFERENCES tipos_embarazos (`id_tipo_embarazo`) ON DELETE NO ACTION ON UPDATE NO ACTION"
                }
            ],
            "indexes": [
                {
                    "name": "fk_persona_idx",
                    "value": "`id_persona` DESC"
                },
                {
                    "name": "fk_control_embarazos_idx",
                    "value": "`id_control` DESC"
                },
                {
                    "name": "fk_tipo_embarazo_idx",
                    "value": "`id_tipo_embarazo` DESC"
                }
            ],
            "triggers": [
                {
                    "name": "embarazos_trigger_last_modified",
                    "logic": "BEGIN      UPDATE embarazos SET last_modified= (strftime('%s', 'now')) WHERE id_persona=OLD.id_persona;  END",
                    "condition": "FOR EACH ROW WHEN NEW.last_modified < OLD.last_modified",
                    "timeevent": "AFTER UPDATE ON"
                }
            ]
        },
        {
            "name": "embarazos_patologias",
            "schema": [
                {
                    "column": "id_control_embarazo_patologico",
                    "value": "INT  NOT NULL"
                },
                {
                    "column": "id_patologia_embarazo",
                    "value": "INT  NOT NULL"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                },
                {
                    "constraint": "CPK_id_control_embarazo_patologico_id_patologia_embarazo",
                    "value": "PRIMARY KEY (id_control_embarazo_patologico,id_patologia_embarazo)"
                },
                {
                    "constraint": "`fk_control_emba_pat`",
                    "value": "FOREIGN KEY (`id_control_embarazo_patologico`) REFERENCES control_emb_patologico (`id_control_emb_patologico`) ON DELETE RESTRICT ON UPDATE RESTRICT"
                },
                {
                    "constraint": "`fk_pat_embs`",
                    "value": "FOREIGN KEY (`id_patologia_embarazo`) REFERENCES patologias_embarazos (`id_patologia_embarazo`) ON DELETE RESTRICT ON UPDATE RESTRICT"
                }
            ],
            "indexes": [
                {
                    "name": "fk_pat_embs",
                    "value": "`id_patologia_embarazo` DESC"
                }
            ],
            "triggers": [
                {
                    "name": "embarazos_patologias_trigger_last_modified",
                    "logic": "BEGIN      UPDATE embarazos_patologias SET last_modified= (strftime('%s', 'now')) WHERE id_control_emb_patologico=OLD.id_control_emb_patologico;  END",
                    "condition": "FOR EACH ROW WHEN NEW.last_modified < OLD.last_modified",
                    "timeevent": "AFTER UPDATE ON"
                }
            ]
        },
        {
            "name": "etmis_personas",
            "schema": [
                {
                    "column": "id_persona",
                    "value": "INT  NOT NULL"
                },
                {
                    "column": "id_etmi",
                    "value": "INT  NOT NULL"
                },
                {
                    "column": "id_control",
                    "value": "INT  NOT NULL"
                },
                {
                    "column": "confirmada",
                    "value": "SMALLINT  NOT NULL DEFAULT 0"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                },
                {
                    "constraint": "CPK_id_persona_id_etmi_id_control",
                    "value": "PRIMARY KEY (id_persona,id_etmi,id_control)"
                },
                {
                    "constraint": "`fk_etmis_personas_control`",
                    "value": "FOREIGN KEY (`id_control`) REFERENCES controles (`id_control`) ON DELETE NO ACTION ON UPDATE NO ACTION"
                },
                {
                    "constraint": "`fk_etmis_personas_etmi`",
                    "value": "FOREIGN KEY (`id_etmi`) REFERENCES etmis (`id_etmi`) ON DELETE NO ACTION ON UPDATE NO ACTION"
                },
                {
                    "constraint": "`fk_etmis_personas_personas`",
                    "value": "FOREIGN KEY (`id_persona`) REFERENCES personas (`id_persona`) ON DELETE NO ACTION ON UPDATE NO ACTION"
                }
            ],
            "indexes": [
                {
                    "name": "fk_etmi_idx",
                    "value": "`id_etmi` DESC"
                },
                {
                    "name": "fk_control_ep_idx",
                    "value": "`id_control` DESC"
                }
            ],
            "triggers": [
                {
                    "name": "etmis_personas_trigger_last_modified",
                    "logic": "BEGIN      UPDATE etmis_personas SET last_modified= (strftime('%s', 'now')) WHERE id_persona=OLD.id_persona;  END",
                    "condition": "FOR EACH ROW WHEN NEW.last_modified < OLD.last_modified",
                    "timeevent": "AFTER UPDATE ON"
                }
            ],
           
        },
        {
           
            "name": "inmunizaciones_control",
            "schema": [
                
                {
                    "column": "id_persona",
                    "value": "INT  NOT NULL"
                },
                {
                    "column": "id_control",
                    "value": "INT  NOT NULL"
                },
                {
                    "column": "id_inmunizacion",
                    "value": "INT  NOT NULL"
                },
                {
                    "column": "estado",
                    "value": "CHAR(1)  NOT NULL  COLLATE NOCASE"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                },

                {
                    "constraint": "CPK_id_persona_id_control_id_inmunizacion",
                    "value": "PRIMARY KEY (id_persona,id_control,id_inmunizacion)"
                },
                {
                    "constraint": "`fk_inmunicaciones_control_control`",
                    "value": "FOREIGN KEY (`id_control`) REFERENCES controles (`id_control`) ON DELETE NO ACTION ON UPDATE NO ACTION"
                },
                {
                    "constraint": "`fk_inmunicaciones_control_inmunizacion`",
                    "value": "FOREIGN KEY (`id_inmunizacion`) REFERENCES inmunizaciones (`id_inmunizacion`) ON DELETE NO ACTION ON UPDATE NO ACTION"
                },
                {
                    "constraint": "`fk_inmunicaciones_control_persona`",
                    "value": "FOREIGN KEY (`id_persona`) REFERENCES personas (`id_persona`) ON DELETE NO ACTION ON UPDATE NO ACTION"
                }
            ],
           
            "indexes": [
                {
                    "name": "fk_inmunizaciones_control_control",
                    "value": "`id_control` DESC"
                },
                {
                    "name": "fk_inmunizaciones_control_inmunizacion",
                    "value": "`id_inmunizacion` DESC"
                },
                {
                    "name": "fk_inmunizaciones_control_persona",
                    "value": "`id_persona` DESC"
                }
            ],
            "triggers": [
                {
                    "name": "inmunizaciones_control_trigger_last_modified",
                    "logic": "BEGIN      UPDATE inmunizaciones_control SET last_modified= (strftime('%s', 'now')) WHERE id_persona=OLD.id_persona;  END",
                    "condition": "WHEN 1",
                    "timeevent": "AFTER UPDATE ON"
                }
            ],
           
        },
        {
            "name": "laboratorios_realizados",
            "schema": [
                {
                    "column": "id_persona",
                    "value": "INT  NOT NULL"
                },
                {
                    "column": "id_control",
                    "value": "INT  NOT NULL"
                },
                {
                    "column": "id_laboratorio",
                    "value": "INT  NOT NULL"
                },
                {
                    "column": "trimestre",
                    "value": "SMALLINT  NOT NULL"
                },
                {
                    "column": "fecha_realizado",
                    "value": "DATE  NULL DEFAULT NULL"
                },
                {
                    "column": "fecha_resultados",
                    "value": "DATE  NULL DEFAULT NULL"
                },
                {
                    "column": "resultado",
                    "value": "VARCHAR(45)  NULL DEFAULT NULL COLLATE NOCASE"
                },
                {
                    "column": "id_etmi",
                    "value": "INT  NOT NULL DEFAULT 0"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
               
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                },
                {
                    "constraint": "CPK_id_persona_id_control_id_laboratorio_trimestre_id_etmi",
                    "value": "PRIMARY KEY (id_persona,id_control,id_laboratorio,trimestre,id_etmi)"
                },
                 
                {
                    "constraint": "`fk_laboratorios_realizados_control`",
                    "value": "FOREIGN KEY (`id_control`) REFERENCES controles (`id_control`) ON DELETE NO ACTION ON UPDATE NO ACTION"
                },
                {
                    "constraint": "`fk_laboratorios_realizados_laboratorio`",
                    "value": "FOREIGN KEY (`id_laboratorio`) REFERENCES laboratorios (`id_laboratorio`) ON DELETE NO ACTION ON UPDATE NO ACTION"
                },
                {
                    "constraint": "`fk_laboratorios_realizados_etmis`",
                    "value": "FOREIGN KEY (`id_etmi`) REFERENCES etmis (`id_etmi`) ON DELETE NO ACTION ON UPDATE NO ACTION"
                }
            ],
            "indexes": [
                {
                    "name": "fk_control_idx",
                    "value": "`id_control` DESC"
                },
                {
                    "name": "fk_laboratorio_idx",
                    "value": "`id_laboratorio` DESC"
                },
                {
                    "name": "fk_etmis_idx",
                    "value": "`id_etmi` DESC"
                }
            ],
            "triggers": [
                {
                    "name": "laboratorios_realizados_trigger_last_modified",
                    "logic": "BEGIN  UPDATE laboratorios_realizados SET last_modified= strftime('%s', 'now') WHERE id_laboratorio=OLD.id_laboratorio ; END",
                    "condition": "WHEN 1",
                    "timeevent": "AFTER UPDATE ON"
                }
            ],
          
        },
        {
            "name": "tratchagas_eventosadv",
            "schema": [
                {
                    "column": "id_tratamiento_chagas",
                    "value": "INT  NOT NULL"
                },
                {
                    "column": "id_evento_adverso",
                    "value": "INT  NOT NULL"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                }
            ],
            "indexes": [
                {
                    "name": "id_tratamiento_chagas",
                    "value": "`id_tratamiento_chagas` DESC"
                },
                {
                    "name": "id_evento_adverso",
                    "value": "`id_evento_adverso` DESC"
                }
            ]
        },
        {
            "name": "ubicaciones",
            "schema": [
                {
                    "column": "id_ubicacion",
                    "value": "INT PRIMARY KEY NOT NULL"
                },
                {
                    "column": "id_persona",
                    "value": "INT  NOT NULL"
                },
                {
                    "column": "id_paraje",
                    "value": "INT  NULL DEFAULT NULL"
                },
                {
                    "column": "id_area",
                    "value": "INT  NULL DEFAULT NULL"
                },
                {
                    "column": "num_vivienda",
                    "value": "VARCHAR(10)  NOT NULL  COLLATE NOCASE"
                },
                {
                    "column": "fecha",
                    "value": "DATE  NOT NULL"
                },
                {
                    "column": "georeferencia",
                    "value": "VARCHAR(30)  NOT NULL  COLLATE NOCASE"
                },
                {
                    "column": "id_pais",
                    "value": "INT  NULL DEFAULT NULL"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                }
            ],
            "indexes": [
                {
                    "name": "ck",
                    "value": "`id_persona` DESC,`id_paraje` DESC,`id_area` DESC,`num_vivienda` DESC,`fecha` DESC,`id_pais` DESC",
                    "mode": "UNIQUE"
                },
                {
                    "name": "fk_paises_idx",
                    "value": "`id_pais` DESC"
                },
                {
                    "name": "fk_ubicaciones_areas",
                    "value": "`id_area` DESC"
                },
                {
                    "name": "fk_ubicaciones_parajes",
                    "value": "`id_paraje` DESC"
                }
            ],
            "triggers": [
                {
                    "name": "ubicaciones_trigger_last_modified",
                    "logic": "BEGIN     UPDATE ubicaciones SET last_modified= (strftime('%s', 'now')) WHERE id_ubicacion=OLD.id_ubicacion;  END",
                    "condition": "WHEN 1",
                    "timeevent": "AFTER UPDATE ON"
                }
            ],
         
        },
        {
            "name": "usuarios",
            "schema": [
                {
                    "column": "id_usuario",
                    "value": "INT PRIMARY KEY NOT NULL"
                },
                {
                    "column": "usuario",
                    "value": "VARCHAR(50)  NOT NULL  COLLATE NOCASE"
                },
                {
                    "column": "password",
                    "value": "VARCHAR(128)  NOT NULL  COLLATE NOCASE"
                },
                {
                    "column": "email",
                    "value": "VARCHAR(150)  NOT NULL  COLLATE NOCASE"
                },
                {
                    "column": "nombre",
                    "value": "VARCHAR(100)  NOT NULL  COLLATE NOCASE"
                },
                {
                    "column": "nivel_acceso",
                    "value": "INT  NOT NULL"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK (sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                }
            ],
            "indexes": [
                {
                    "name": "usuario",
                    "value": "`usuario` DESC",
                    "mode": "UNIQUE"
                },
                {
                    "name": "nivel_acceso",
                    "value": "`nivel_acceso` DESC"
                }
            ],
            "values": [
                [
                    1,
                    "adesar",
                    "b9c3b366242d777dfeea8c4a8621bf3c",
                    "adesar@adesar.org.ar",
                    "ADESAR",
                    2,
                    0,
                    1681779229
                ],
                [
                    2,
                    "marcela",
                    "25bf2c365f27d025963c112076ed7a87",
                    "-",
                    "Marcela Quispe",
                    2,
                    0,
                    1681779229
                ],
                [
                    3,
                    "marisa",
                    "e438d4969c839b982c4fcd32e7e97d45",
                    "-",
                    "Marisa Alvarez",
                    2,
                    0,
                    1681779229
                ],
                [
                    4,
                    "sandra",
                    "d2f0c294711426f440af6c188232e774",
                    "-",
                    "Sandra Martinez",
                    2,
                    0,
                    1681779229
                ],
                [
                    5,
                    "luis",
                    "9942646b0aa3851e149a1a0cddadf9f9",
                    "-",
                    "Luis Fochi",
                    2,
                    0,
                    1681779229
                ],
                [
                    6,
                    "ppiorno",
                    "f5d75b525eeda5f12e01af7258686985",
                    "",
                    "Pablo Piorno",
                    1,
                    0,
                    1681779229
                ],
                [
                    7,
                    "mfernandez",
                    "5936442fd1dd38b200f1f69811f33b66",
                    "",
                    "Mariana Fernandez",
                    1,
                    0,
                    1681779229
                ],
                [
                    8,
                    "fcrudo",
                    "58266a6ed9386318a4afdf356696fcaf",
                    "",
                    "Favio Crudo",
                    1,
                    0,
                    1681779229
                ],
                [
                    9,
                    "aguilera",
                    "b9c3b366242d777dfeea8c4a8621bf3c",
                    "anasolguilera@yahoo.com.ar",
                    "Analía Guilera",
                    1,
                    0,
                    1681779229
                ],
                [
                    10,
                    "amanterola",
                    "b602837f4a8f34e073fe27c574f73c38",
                    "",
                    "Alfredo Manterola",
                    1,
                    0,
                    1681779229
                ],
                [
                    11,
                    "sfernandez",
                    "5936442fd1dd38b200f1f69811f33b66",
                    "",
                    "Silvina Fernández",
                    1,
                    0,
                    1681779229
                ],
                [
                    12,
                    "savila",
                    "c915d24cb8da0b2c14e76213d27cf631",
                    "-",
                    "Susana Ávila",
                    1,
                    0,
                    1681779229
                ],
                [
                    14,
                    "gustavo",
                    "3b1e446b97ff04b8a6d0a537f9a188fa",
                    "gustavo.iglesias40@gmail.com",
                    "Iglesias",
                    1,
                    0,
                    1681779229
                ],
                [
                    15,
                    "mariana",
                    "c34784e0c11541b7fc631ebaeebe7e62",
                    "",
                    "Mariana Ocampo",
                    1,
                    0,
                    1681779229
                ],
                [
                    16,
                    "karina",
                    "0c96dd925dff593027e325fe45203055",
                    "",
                    "Karina Cardone",
                    1,
                    0,
                    1681779229
                ]
            ]
        },
        {
            "name": "antecedentes",
            "schema": [
                {
                    "column": "id_antecedente",
                    "value": "INT NOT NULL"
                },
                {
                    "column": "id_persona",
                    "value": "INT NOT NULL"
                },
                {
                    "column": "id_control",
                    "value": "INT NOT NULL"
                },
                {
                    "column": "edad_primer_embarazo",
                    "value": "INT DEFAULT NULL"
                },
                {
                    "column": "fecha_ultimo_embarazo",
                    "value": "DATE DEFAULT NULL"
                },
                {
                    "column": "gestas",
                    "value": "INT DEFAULT 0"
                },
                {
                    "column": "partos",
                    "value": "INT DEFAULT 0"
                },
                {
                    "column": "cesareas",
                    "value": "INT DEFAULT 0"
                },
                {
                    "column": "abortos",
                    "value": "INT DEFAULT 0"
                },
                {
                    "column": "planificado",
                    "value": "SMALLINT DEFAULT NULL"
                },
                {
                    "column": "fum",
                    "value": "DATE DEFAULT NULL"
                },
                {
                    "column": "fpp",
                    "value": "DATE DEFAULT NULL"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK(sql_deleted IN (0, 1))"
                },
                {
                    "constraint": "fk_antecedentes_persona",
                    "value": "FOREIGN KEY(id_persona) REFERENCES personas(id_persona) ON DELETE NO ACTION ON UPDATE NO ACTION"
                },
                {
                    "constraint": "fk_antecedentes_control",
                    "value": "FOREIGN KEY(id_control) REFERENCES controles(id_control) ON DELETE NO ACTION ON UPDATE NO ACTION"
                },
                {
                    "constraint": "CPK_id_antecedente",
                    "value": "PRIMARY KEY(id_antecedente)"
                }
            ],
            "indexes": [
                {
                    "name": "ck_ante",
                    "value": "id_persona DESC",
                    "mode": "UNIQUE"
                },
                {
                    "name": "fk_control_ante_idx",
                    "value": "id_control DESC"
                }
            ],
            "triggers": [
                {
                    "name": "antecedentes_trigger_last_modified",
                    "logic": "BEGIN     UPDATE antecedentes SET last_modified= (strftime('%s', 'now')) WHERE id_antecedente=OLD.id_antecedente;  END",
                    "condition": "WHEN 1",
                    "timeevent": "AFTER UPDATE ON"
                }
            ],
          
        },
        {
            "name": "antecedentes_apps",
            "schema": [
                {
                    "column": "id_antecedente",
                    "value": "INT NOT NULL"
                },
                {
                    "column": "id_app ",
                    "value": "INT NOT NULL"
                },
                {
                    "column": "last_modified ",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK(sql_deleted IN (0, 1))"
                },
                {
                    "constraint": "fk_antecedentes_apps_apps",
                    "value": "FOREIGN KEY(id_app) REFERENCES apps(id_app) ON DELETE NO ACTION ON UPDATE NO ACTION"
                },
                {
                    "constraint": "fk_antecedentes_apps_antecedentes",
                    "value": "FOREIGN KEY(id_antecedente) REFERENCES antecedentes(id_antecedente) ON DELETE NO ACTION ON UPDATE NO ACTION"
                },
                {
                    "constraint": "CPK_id_antecedente_id_app",
                    "value": "PRIMARY KEY(id_antecedente,id_app)"
                }
            ],
            "indexes": [
                {
                    "name": "fk_apps_idx",
                    "value": "id_app DESC"
                }
            ],
            "triggers": [
                {
                    "name": "antecedentes_apps_trigger_last_modified",
                    "logic": "BEGIN      UPDATE antecedentes_apps SET last_modified= (strftime('%s', 'now')) WHERE id_antecedente=OLD.id_antecedente;  END",
                    "condition": "WHEN 1",
                    "timeevent": "AFTER UPDATE ON"
                }
            ],
           
        },
        {
            "name": "antecedentes_macs",
            "schema": [
                {
                    "column": "id_antecedente",
                    "value": "INT NOT NULL"
                },
                {
                    "column": "id_mac",
                    "value": "INT NOT NULL"
                },
                {
                    "column": "sql_deleted",
                    "value": "BOOLEAN DEFAULT 0 CHECK(sql_deleted IN (0, 1))"
                },
                {
                    "column": "last_modified",
                    "value": "INTEGER DEFAULT (strftime('%s', 'now'))"
                },
                {
                    "constraint": "fk_antecedentes_macs_antecedentes",
                    "value": "FOREIGN KEY(id_antecedente) REFERENCES antecedentes(id_antecedente) ON DELETE NO ACTION ON UPDATE NO ACTION"
                },
                {
                    "constraint": "fk_antecedentes_macs_macs",
                    "value": "FOREIGN KEY(id_mac) REFERENCES macs(id_mac) ON DELETE NO ACTION ON UPDATE NO ACTION"
                },
                {
                    "constraint": "CPK_id_antecedente_id_mac",
                    "value": "PRIMARY KEY(id_antecedente,id_mac)"
                }
            ],
            "indexes": [
                {
                    "name": "fk_macs_idx",
                    "value": "id_mac DESC"
                }
            ],
            "triggers": [
                {
                    "name": "antecedentes_macs_trigger_last_modified",
                    "logic": "BEGIN      UPDATE antecedentes_macs SET last_modified= (strftime('%s', 'now')) WHERE id_antecedente=OLD.id_antecedente;  END",
                    "condition": "WHEN 1",
                    "timeevent": "AFTER UPDATE ON"
                }
            ],
           
        }
    ]
}